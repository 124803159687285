import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";
import {
  ITraderMarkOrderTeamplate,
  ITraderMarkOrderTeamplateDetails,
  INodeTree,
} from "../../../models/workflow/response";
import { ITraderMarkOrderTeamplateQuery } from "../../../models/workflow/query";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import {
  ICopyTrademarkOrderTemplate,
  ICreateTraderMarkOrderTeamplate,
  IExportTraderMarkOrderTeamplateExcel,
  IUpdateTraderMarkOrderTeamplate,
} from "../../../models/workflow/request";
import axios from "axios";

class TraderMarkOrderTeamplateService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllWorkFlow = (
    query: ITraderMarkOrderTeamplateQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ITraderMarkOrderTeamplate>>({
      path: `/workflows`,
      method: "GET",
      query,
      ...params,
    });

  getTraderMarkOrderTeamplates = (id: number, params?: RequestParams) =>
    this.http.request<ITraderMarkOrderTeamplate>({
      path: `/workflows/${id}`,
      method: "GET",
      ...params,
    });

  createTraderMarkOrderTeamplates = (
    data: ICreateTraderMarkOrderTeamplate,
    params?: RequestParams
  ) =>
    this.http.request<ITraderMarkOrderTeamplate>({
      path: "/workflows",
      method: "POST",
      body: data,
      ...params,
    });

  updateTraderMarkOrderTeamplates = (
    id: number,
    data: IUpdateTraderMarkOrderTeamplate,
    params?: RequestParams
  ) =>
    this.http.request<ITraderMarkOrderTeamplateDetails>({
      path: `/workflows/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteTraderMarkOrderTeamplates = (id: number, params?: RequestParams) =>
    this.http.request<ITraderMarkOrderTeamplate>({
      path: `/workflows/${id}`,
      method: "DELETE",
      ...params,
    });
  getTree = (id: number, params?: RequestParams) =>
    this.http.request<INodeTree>({
      path: `/workflows/get-workflow-tree/${id}`,
      method: "GET",
      ...params,
    });
  copyTraderMarkOrderTeamplates = (
    id: number,
    data: ICopyTrademarkOrderTemplate
  ) =>
    this.http.request<ICopyTrademarkOrderTemplate>({
      path: `/workflows/copy-workflow-tree/${id}`,
      method: "POST",
      body: data,
    });
  exportExcel = (
    data: IExportTraderMarkOrderTeamplateExcel,
    query: ITraderMarkOrderTeamplateQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/workflows/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default TraderMarkOrderTeamplateService;
