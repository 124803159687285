import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { ColumnProps } from "antd/es/table";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DrawingRequestContext from "../../../../../context/drawing-collection/drawing-request/context";
import {
  getBooleanDrawingReservation,
  getDrawingReservationColor,
} from "../../../../../models/drawing-collection/drawing-reservation/enum";
import EndPoints from "../../../../../services/end-points";
import Tag from "../../../../general/antd/tag";
import Table from "../../../../general/table-components/table";
import { IDrawingReservationDetails } from "../../../../../models/drawing-collection/drawing-reservation/response";
interface IProps {
  id?: number;
}

const DrawingReservation: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [loadingData, setloadingData] = useState(true);
  const { details, actions, loading } = useContext(DrawingRequestContext);
  const [selectedRow, setSelectedRow] = useState<any>(
    details?.drawingReservations?.map((a) => a.id)
  );

  useEffect(() => {
    const getTableData = async () => {
      try {
        const data = await EndPoints.drawingRequest.getDrawingReservations(
          props.id
        );
        if (data.data) {
          setTableData(data.data.data);
        } else {
          setTableData([]);
        }
      } catch (error) {
      } finally {
        setloadingData(false);
      }
    };

    getTableData();
  }, []);

  const columns: ColumnProps<IDrawingReservationDetails>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "s",
      align: "center",

      key: "s",
      render: (_, record) => {
        return <div>{record?.drawing?.name}</div>;
      },
    },

    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",

      key: "renewalCounts",
    },
    {
      title: t("status"),
      dataIndex: "status",
      align: "center",

      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanDrawingReservation(Number(record))}
            color={getDrawingReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return <p> {depositDate ? depositDate.slice(0, 10) : "-"} </p>;
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p> {registrationDate ? registrationDate.slice(0, 10) : "-"} </p>
        );
      },
    },
    {
      title: t("last_renewal_date"),
      dataIndex: "lastRenewalDate",
      align: "center",

      key: "lastRenewalDate",
      render: (_, { lastRenewalDate }) => {
        return <p> {lastRenewalDate ? lastRenewalDate.slice(0, 10) : "-"} </p>;
      },
    },
    {
      title: t("last_renewal_number"),
      dataIndex: "lastRenewalNumber",
      align: "center",
      key: "lastRenewalNumber",
    },
  ];

  const OnClick = async () => {
    await actions.addReservationToDrawing(props.id, {
      drawingReservations: selectedRow,
    });

    setloadingData(true);
    await actions.getDetails(props.id);
    await EndPoints.drawingRequest.getDrawingReservations(props.id);

    setloadingData(false);
  };

  return (
    <>
      <Row gutter={[6, 6]}>
        <Col span={24}>
          <Table
            rowKey="id"
            loading={loadingData || loading.includes("create")}
            dataSource={tableData}
            columns={columns}
            size="small"
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRow,
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRow(
                  selectedRows?.map((selectedRow) => selectedRow.id)
                );
              },
              getCheckboxProps: (selectedRowKeys) => ({
                disabled: details?.drawingReservations?.some(
                  (a) => a.id === selectedRowKeys.id
                ),
                id: selectedRowKeys.id,
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <Button
            loading={loadingData}
            onClick={OnClick}
            type="primary"
            disabled={
              tableData.length === 0 || loading.includes("create")
                ? true
                : false
            }
            icon={<PlusOutlined />}
          >
            {t("add_reservations")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default DrawingReservation;
