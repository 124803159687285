import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { IClient } from "../../../../models/client/response";
import { ICountry } from "../../../../models/managent-collection/user/response";
import EndPoints from "../../../../services/end-points";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import GeneralForm from "../../../form-components/general-form";
import RichEditor from "../../../form-components/rich-text-editor";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import DisclosureTrademarkRequestContextProvider from "../../../../context/independent_requests-collection/disclosure-trademark-request/provider";
import DisclosureTrademarkRequestContext from "../../../../context/independent_requests-collection/disclosure-trademark-request/context";
import { IUpdateDisclosureTrademarkRequest } from "../../../../models/independent-requests-collection/disclosure-trademark-request/request";
interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(
    DisclosureTrademarkRequestContext
  );

  const { t } = useTranslation();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all CLients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  return (
    <GeneralForm
      onSubmit={async (data) => {
        details
          ? await actions.updateDisclosureTrademarkRequest(Number(id), data)
          : await actions.createDisclosureTrademarkRequest(data);
      }}
      title={
        details
          ? t("update_disclosure_trademark_request")
          : t("add_disclosure_trademark_request")
      }
      defaultValues={
        {
          clientId: details?.client?.id,
          countryId: details?.country?.id,
          dateFinancialReceipt: details?.dateFinancialReceipt ?? undefined,
          depositNumber: details?.depositNumber,
          disclosureResult: details?.disclosureResult,
          financialReceiptNumber: details?.financialReceiptNumber ?? undefined,
          note: details?.note,
          officialDate: details?.officialDate ?? undefined,
          orderValue: details?.orderValue,
          // clientPayments: details?.clientPayments,
        } as IUpdateDisclosureTrademarkRequest
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="clientId"
                label={`${t("client")}`}
                rules={{ required: true }}
                width="large"
                input={{
                  type: "select",
                  loading: clientsLoading,
                  allowSearch: true,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryId"
                label={`${t("country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="depositNumber"
                label={`${t("depositNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="officialDate"
                label={`${t("official_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="financialReceiptNumber"
                label={`${t("financialReceiptNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="dateFinancialReceipt"
                label={`${t("dateFinancialReceipt")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="orderValue"
                label={`${t("order_value")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            {/*<Col span={8}>
              <FieldBuilder
                name="clientPayments"
                label={`${t("client_payments")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>*/}
            <Col span={24}>
              <FieldBuilder
                name="disclosureResult"
                label={`${t("disclosureResult")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const DisclosureTrademarkRequestForm = () => {
  return (
    <DisclosureTrademarkRequestContextProvider>
      <Form />
    </DisclosureTrademarkRequestContextProvider>
  );
};

export default DisclosureTrademarkRequestForm;
