import { ColumnProps } from "antd/es/table";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../../general/table-components/table";

import moment from "moment";
import IncidentsClientsContext from "../../../../../../context/incidents-collection/incidents-clients-reqests/clients-incidents/context";
import {
  getBooleanDrawingReservation,
  getDrawingReservationColor,
} from "../../../../../../models/drawing-collection/drawing-reservation/enum";
import Tag from "../../../../../general/antd/tag";
import { IDrawingReservation } from "../../../../../../models/drawing-collection/drawing-reservation/response";
import IncidentsWaiversContext from "../../../../../../context/incidents-collection/incidents-waiver-requests/waiver-incidents/context";
import { getCopyrightColor, getCopyrightType } from "../../../../../../models/copyright-collection/copyright/enum";
import { ICopyrightReservation } from "../../../../../../models/copyright-collection/copyright-reservation/response";
interface Props {}
const CopyrightTable: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details } = useContext(IncidentsWaiversContext);


  const columns: ColumnProps<ICopyrightReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { copyright }) => {
        return <p> {copyright?.name} </p>;
      },
    },
    {
      title: t("copyright_type"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.copyright ? (
              <Tag
                color={getCopyrightColor(record.copyright?.type)}
                title={t(getCopyrightType(record?.copyright.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },

    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanDrawingReservation(Number(record))}
            color={getDrawingReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_date"),
      dataIndex: "lastRenewalDate",
      align: "center",

      key: "lastRenewalDate",
      render: (_, { lastRenewalDate }) => {
        return (
          <p>
            {" "}
            {lastRenewalDate
              ? moment(lastRenewalDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_number"),
      dataIndex: "lastRenewalNumber",
      align: "center",
      key: "lastRenewalNumber",
    },
  ];

  return (
    <Table<any>
      rowKey="id"
      style={{ height: "100%" }}
      columns={columns}
      size="small"
      dataSource={details?.copyrightReservations ?? []}
    />
  );
};

export default CopyrightTable;
