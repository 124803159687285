import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import { IAgentChange } from "../../../../models/independent-requests-collection/agent-change/response";
import AgentChangeContext from "../../../../context/independent_requests-collection/agent-change/context";
import styles from "./styles.module.scss";
interface IProps {}

const AgentChangeRequestTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } =
    useContext(AgentChangeContext);

  const [infoId, setInfoId] = useState(undefined);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const columns: ColumnProps<IAgentChange>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },

    {
      title: t("country"),
      dataIndex: "country",
      align: "center",
      key: "country",
      render: (_, record) => {
        return <div>{record?.country?.name ?? "-"}</div>;
      },
    },
    {
      title: t("client"),
      dataIndex: "clients",
      align: "center",
      key: "country",

      render: (_, record) => {
        return <div>{record?.client?.name}</div>;
      },
    },

    {
      title: t("nameOldAgent"),
      dataIndex: "nameOldAgent",
      key: "nameOldAgent",
      sorter: {
        multiple: 5,
      },
      align: "center",
    },

    {
      title: t("nameNewAgent"),
      dataIndex: "nameNewAgent",
      key: "nameNewAgent",
      sorter: {
        multiple: 6,
      },
      align: "center",
    },

    {
      title: t("approvingNewAgentDate"),
      dataIndex: "approvingNewAgentDate",
      key: "approvingNewAgentDate",
      // sorter: {
      //   multiple: 2,
      // },
      width: 250,
      align: "center",
      render: (approvingNewAgentDate: Date) =>
        approvingNewAgentDate
          ? moment(approvingNewAgentDate).format("DD/MM/YYYY")
          : "-",
    },

    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      // sorter: {
      //   multiple: 1,
      // },
      align: "center",
    },
    {
      title: t("financialReceiptNumber"),
      dataIndex: "financialReceiptNumber",
      key: "financialReceiptNumber",
      // sorter: {
      //   multiple: 1,
      // },
      align: "center",
    },

    {
      title: t("dateFinancialReceipt"),
      dataIndex: "dateFinancialReceipt",
      key: "dateFinancialReceipt",
      // sorter: {
      //   multiple: 2,
      // },
      width: 150,
      align: "center",
      render: (dateFinancialReceipt: Date) =>
        dateFinancialReceipt
          ? moment(dateFinancialReceipt).format("DD/MM/YYYY")
          : "-",
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      key: "officialDate",
      sorter: {
        multiple: 2,
      },
      align: "center",
      render: (officialDate: Date) =>
        officialDate ? moment(officialDate).format("DD/MM/YYYY") : "-",
    },
    {
      title: t("order_value"),
      dataIndex: "orderValue",
      align: "center",
      key: "orderValue",
    },
    {
      title: t("client_payments"),
      dataIndex: "sumPayments",
      align: "center",
      key: "country",
    },
    {
      title: t("sum_costs"),
      dataIndex: "sumCosts",
      align: "center",
      key: "sumCosts",
    },
    {
      title: t("actions"),
      width: "18%",
      align: "center",
      key: "x",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />

          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteAgentChange(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        className={styles.customTable}
        pageSize={query.perPage}
        scroll={{ x: 1700 }}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default AgentChangeRequestTable;
