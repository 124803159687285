import { useReducer } from "react";
import { ICopyrightReservationQuery } from "../../../models/copyright-collection/copyright-reservation/query";
import {
  ICreateCopyrightReservation,
  IUpdateCopyrightReservation,
} from "../../../models/copyright-collection/copyright-reservation/request";
import { ICopyrightReservationDetails } from "../../../models/copyright-collection/copyright-reservation/response";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import CopyrightReservationContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const CopyrightReservationContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.copyrightReservation.getAllCopyrightsReservation({
            ...state.query,
            filters: [
              {
                name: "copyrightID",
                value: id,
                operation: "eq",
              },
            ],
          });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.copyrightReservation.getCopyrightReservation(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const getHistory = async (idReservation: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "history" } });

        const { data } = await EndPoints.copyrightReservation.getHistory({
          ...state.query,
          filters: [
            {
              name: "copyrightReservationID",
              value: idReservation,
              operation: "eq",
            },
          ],
        });

        dispatch({ type: "SET_HISTORY", payload: { history: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "history" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICopyrightReservationDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createCopyrightReservation = async (
    request: ICreateCopyrightReservation
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.copyrightReservation.createCopyrightReservation(
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateCopyrightReservation = async (
    id: number,
    request: IUpdateCopyrightReservation
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.copyrightReservation.updateCopyrightReservation(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCopyrightReservation = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.copyrightReservation.deleteCopyrightReservation(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ICopyrightReservationQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <CopyrightReservationContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          getHistory,
          setDetails,

          createCopyrightReservation,
          updateCopyrightReservation,
          deleteCopyrightReservation,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CopyrightReservationContext.Provider>
  );
};

export default CopyrightReservationContextProvider;
