import React, { useState } from "react";
import { Spin, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { isImageTypeSupport } from "../../../utils/helpers/support-images";
import {
  errorNotification,
  successNotification,
} from "../../../utils/helpers/notification";
import { MediumFor, MediumType } from "../../../models/medium/enum";
import {
  InboxOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ACCESS_TOKEN } from "../../../utils/helpers/constants";
import { compressImage } from "../../../utils/helpers/image-compression";
import { IMedium } from "../../../models/medium/response";

const { Dragger } = Upload;

interface IProps {
  imageFor: MediumFor;
  onChange: (response: IMedium | null) => any;
  type?: "DRAGGER" | "BUTTON";
  children?: React.ReactNode;
  multiple?: boolean;
}

const ImageUploader: React.FC<IProps> = ({
  imageFor,
  onChange,
  type,
  multiple,

  children,
}) => {
  const { t } = useTranslation();

  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);

  return (
    <>
      {type === "DRAGGER" ? (
        multiple ? (
          <>
            <Spin spinning={uploading}>
              <Dragger
                accept="image/*"
                name="medium"
                listType="picture"
                showUploadList={false}
                multiple={false}
                onRemove={(file) => {
                  return true;
                }}
                beforeUpload={(file) => {
                  setUploading(true);

                  // Check File Type.
                  const isImageSupport = isImageTypeSupport(file.type);

                  if (!isImageSupport) {
                    errorNotification(t("you_can_only_upload_images"));
                    setUploading(false);
                    return false;
                  }

                  // Compress Image
                  setFileList([...fileList, file]);
                  return compressImage(file);
                }}
                onChange={({ file }) => {
                  if (file.status === "error") {
                    setUploading(false);
                    errorNotification(t("upload_failed"));
                  }
                  if (file.status === "done") {
                    onChange(file?.response);

                    successNotification(t("upload_success"));
                    setUploading(false);
                  }
                }}
                fileList={[...fileList]}
                action={process.env.REACT_APP_UPLOAD_FILE_URL}
                headers={{
                  Authorization: `Bearer ${
                    localStorage.getItem(ACCESS_TOKEN) ??
                    sessionStorage.getItem(ACCESS_TOKEN)
                  }`,
                }}
                data={() => {
                  return {
                    type: MediumType.Image,
                    for: imageFor,
                  };
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("select_or_drag_image")}</p>
              </Dragger>
            </Spin>
          </>
        ) : (
          <>
            <Spin spinning={uploading}>
              <Dragger
                accept="image/*"
                name="medium"
                listType="text"
                multiple={false}
                onRemove={() => {
                  setFileList([]);
                  onChange(null);
                }}
                beforeUpload={(file) => {
                  setUploading(true);
                  onChange(null);
                  // Check File Type.
                  const isImageSupport = isImageTypeSupport(file.type);
                  if (!isImageSupport) {
                    errorNotification(t("you_can_only_upload_images"));
                    setUploading(false);
                    return false;
                  }

                  // Compress Image
                  setFileList([file]);
                  return compressImage(file);
                }}
                onChange={({ file }) => {
                  if (file.status === "error") {
                    setUploading(false);
                    errorNotification(t("upload_failed"));
                  }
                  if (file.status === "done") {
                    onChange(file?.response?.id);
                    successNotification(t("upload_success"));
                    setUploading(false);
                  }
                }}
                fileList={[...fileList]}
                action={process.env.REACT_APP_UPLOAD_FILE_URL}
                headers={{
                  Authorization: `Bearer ${
                    localStorage.getItem(ACCESS_TOKEN) ??
                    sessionStorage.getItem(ACCESS_TOKEN)
                  }`,
                }}
                data={() => {
                  return {
                    type: MediumType.Image,
                    for: imageFor,
                  };
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">{t("select_or_drag_image")}</p>
              </Dragger>
            </Spin>
          </>
        )
      ) : (
        <>
          <Upload
            showUploadList={false}
            accept="image/*"
            name="medium"
            listType="picture"
            multiple={false}
            onRemove={() => {
              setFileList([]);
            }}
            beforeUpload={(file) => {
              setUploading(true);

              // Check File Type.
              const isImageSupport = isImageTypeSupport(file.type);

              if (!isImageSupport) {
                errorNotification(t("you_can_only_upload_images"));
                setUploading(false);
                return false;
              }

              // Compress Image
              setFileList([file]);
              return compressImage(file);
            }}
            onChange={async ({ file }) => {
              if (file.status === "error") {
                setUploading(false);
                errorNotification(t("upload_failed"));
              }

              if (file.status === "done") {
                await onChange(file?.response);
                successNotification(t("upload_success"));
                setUploading(false);
              }
            }}
            fileList={[...fileList]}
            action={process.env.REACT_APP_UPLOAD_FILE_URL}
            headers={{
              Authorization: `Bearer ${
                localStorage.getItem(ACCESS_TOKEN) ??
                sessionStorage.getItem(ACCESS_TOKEN)
              }`,
            }}
            data={() => {
              return {
                type: MediumType.Image,
                for: imageFor,
              };
            }}
          >
            {uploading ? (
              <div>{uploading ? <LoadingOutlined /> : <PlusOutlined />}</div>
            ) : (
              children
            )}
          </Upload>
        </>
      )}
    </>
  );
};

export default ImageUploader;
