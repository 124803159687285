import { ColumnProps } from "antd/es/table";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../../general/table-components/table";

import moment from "moment";
import { ImCheckmark, ImCross } from "react-icons/im";
import IncidentsLicensingsContext from "../../../../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/context";
import {
  getBooleanTrademarkReservation,
  getTrademarkReservationColor,
} from "../../../../../../models/trademark-collection/trademark-reservation/enum";
import { ITrademarkReservation } from "../../../../../../models/trademark-collection/trademark-reservation/response";
import Tag from "../../../../../general/antd/tag";
interface Props {}
const TrademarkTable: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details } = useContext(IncidentsLicensingsContext);

  const columns: ColumnProps<ITrademarkReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",

      align: "center",
      render: (_, { trademark }) => {
        return <p> {trademark?.name} </p>;
      },
    },
    {
      title: t("main_category"),
      dataIndex: "mainCategory",
      align: "center",
      key: "mainCategory",
      render: (_, { mainCategory }) => {
        return <p> {mainCategory?.number} </p>;
      },
    },
    {
      title: t("all_categories"),
      align: "center",
      dataIndex: "allCategories",
      key: "allCategories",
      render: (_, { allCategories }) => (
        <>
          {allCategories ? (
            <ImCheckmark color="green" />
          ) : (
            <ImCross color="red" />
          )}
        </>
      ),
    },
    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",
      key: "renewalCounts",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanTrademarkReservation(Number(record))}
            color={getTrademarkReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      key: "country",
      align: "center",
      render: (_, record) => {
        return <p> {record?.registrationNumber ?? "-"} </p>;
      },
    },
  ];

  return (
    <Table<any>
      rowKey="id"
      style={{ height: "100%" }}
      columns={columns}
      size="small"
      dataSource={details?.trademarkReservations ?? []}
    />
  );
};

export default TrademarkTable;
