import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { IClientPaymentsQuery } from "../../../models/trademark-collection/client-payments/query";
import {
  ICreateClientPayment,
  IUpdateClientPayment,
} from "../../../models/trademark-collection/client-payments/request";
import {
  IClientPayment,
  IClientPaymentDetails,
} from "../../../models/trademark-collection/client-payments/response";
import { IRequestPaymentQuery } from "../../../models/independent-requests-collection/request-payment/query";
import { IRequestPayment, IRequestPaymentDetails } from "../../../models/independent-requests-collection/request-payment/response";
import { ICreateRequestPayment, IUpdateRequestPayment } from "../../../models/independent-requests-collection/request-payment/request";

class RequestPaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPayments = (
    query: IRequestPaymentQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IRequestPayment>>({
      path: `/independent-request-payments`,
      method: "GET",
      query,
      ...params,
    });

  getPayment = (id: number, params?: RequestParams) =>
    this.http.request<IRequestPaymentDetails>({
      path: `/independent-request-payments/${id}`,
      method: "GET",
      ...params,
    });

  createPayment = (data: ICreateRequestPayment, params?: RequestParams) =>
    this.http.request<IRequestPayment>({
      path: "/independent-request-payments",
      method: "POST",
      body: data,
      ...params,
    });

  updatePayment = (
    id: number,
    data: IUpdateRequestPayment,
    params?: RequestParams
  ) =>
    this.http.request<IRequestPayment>({
      path: `/independent-request-payments/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePayment = (id: number, params?: RequestParams) =>
    this.http.request<IRequestPayment>({
      path: `/independent-request-payments/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default RequestPaymentService;
