import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";

import styles from "./style.module.scss";
import { Input } from "antd";
import FormItem from "../../general/form-item";

const ChangePasswordForm: React.FC = () => {
  const {
    control,
    formState: { errors },
    getValues,
  } = useFormContext();

  const { t } = useTranslation();

  return (
    <>
      <FormItem
        key={2}
        // label={`${t('name')} (${lang.name})`}
        label={`${t("old_password")}`}
        required
      >
        <Controller
          name="oldPassword"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <>
                <Input.Password {...field} />
              </>
            );
          }}
        />
      </FormItem>
      <FormItem
        key={2}
        // label={`${t('name')} (${lang.name})`}
        label={`${t("new_password")}`}
        required
      >
        <Controller
          name="password"
          control={control}
          rules={{
            required: `${t("field_is_required_message")}`,
            minLength: {
              value: 8,
              message: t("invalid_password"),
            },
            maxLength: {
              value: 32,
              message: t("invalid_password"),
            },
          }}
          render={({ field }) => {
            return (
              <>
                <Input.Password {...field} />
              </>
            );
          }}
        />
      </FormItem>

      {/* Confirm New Password */}
      {/* <FormItem label={`${t('confirm_new_password')}`}>
        <Controller
          name='confirmPassword'
          control={control}
          rules={{
            required: `${t('field_is_required_message')}`,
            minLength: {
              value: 8,
              message: t('invalid_password'),
            },
            maxLength: {
              value: 32,
              message: t('invalid_password'),
            },
            validate: (value) => {
              return (
                value === getValues('password') ||
                `${t('passwords_mismatched')}`
              )
            },
          }}
          render ={({field})=>{
            return <Input type='password' {...field} />
          }}
        />

        <ErrorMessage
          errors={errors}
          name='confirmPassword'
          render={({ message }) => <p className={styles.alert}>{message}</p>}
        />
      </FormItem> */}
    </>
  );
};

export default ChangePasswordForm;
