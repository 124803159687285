import { Layout } from "antd";
import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import AppContext from "../../../../context/app/context";
import LayoutContent from "../layout-content";
import NavBar from "../nav-bar";
import SideBar from "../side-bar";

const BaseLayout: React.FC = () => {
  const { direction } = useContext(AppContext);

  return (
    <>
      {/* Layout */}
      <Layout dir={direction}>
        <SideBar />
        <Layout>
          <NavBar />
          <LayoutContent>
            <Outlet />
          </LayoutContent>
        </Layout>
      </Layout>
    </>
  );
};

export default BaseLayout;
