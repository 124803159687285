import { createContext } from "react";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IIncidentsClients,
  IIncidentsClientsDetails,
} from "../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/response";
import { IIncidentsClientsQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/query";
import {
  ICreateIncidentsClients,
  IUpdateIncidentsClients,
} from "../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/request";

export type incidentsClientsLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "word"

export interface IInternalState {
  loading: incidentsClientsLoading[];

  list?: IBaseListingResponse<IIncidentsClients>;
  query: IIncidentsClientsQuery;

  details?: IIncidentsClientsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsClientsDetails) => void;

    createIncidentsClient: (request: ICreateIncidentsClients) => void;
    updateIncidentsClient: (
      id: number,
      request: IUpdateIncidentsClients
    ) => void;
    deleteIncidentsClient: (id: number) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    exportWord: (id: number , type:number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsClientsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createIncidentsClient: DEFAULT_FUNCTION,
    updateIncidentsClient: DEFAULT_FUNCTION,
    deleteIncidentsClient: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    exportWord: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsClientsContext = createContext(externalState);

export default IncidentsClientsContext;
