import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import { IAgentChangeQuery } from "../../../models/independent-requests-collection/agent-change/query";
import {
  ICreateAgentChange,
  IUpdateAgentChange,
} from "../../../models/independent-requests-collection/agent-change/request";
import {
  IAgentChange,
  IAgentChangeDetails,
} from "../../../models/independent-requests-collection/agent-change/response";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";

export type AgentChangeLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: AgentChangeLoading[];

  list?: IBaseListingResponse<IAgentChange>;
  query: IAgentChangeQuery;

  details?: IAgentChangeDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IAgentChangeDetails) => void;

    createAgentChange: (request: ICreateAgentChange) => void;
    updateAgentChange: (id: number, request: IUpdateAgentChange) => void;
    deleteAgentChange: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IAgentChangeQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createAgentChange: DEFAULT_FUNCTION,
    updateAgentChange: DEFAULT_FUNCTION,
    deleteAgentChange: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const AgentChangeContext = createContext(externalState);

export default AgentChangeContext;
