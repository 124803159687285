import {
  Descriptions as AntdDescriptions,
  Col,
  Collapse,
  Divider,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TrademarkRequestContext from "../../../../../context/trademark-collection/trademark-request/context";
import TrademarkRequestContextProvider from "../../../../../context/trademark-collection/trademark-request/provider";
import {
  getRequestStatusColor,
  getReservationStatus,
} from "../../../../../models/node/enum";
import { getTrademarkRequestStatusString } from "../../../../../models/trademark-collection/trademark-request/enum";
import { INodeCanMove } from "../../../../../models/trademark-collection/trademark-request/response";

import EndPoints from "../../../../../services/end-points";
import DefoultImage from "../../../../../assets/images/user.jpg";
import FieldBuilder from "../../../../form-components/field-builder";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";
import FormItem from "../../../../general/form-item";
import DrawingRequestContextProvider from "../../../../../context/drawing-collection/drawing-request/provider";
import DrawingRequestContext from "../../../../../context/drawing-collection/drawing-request/context";
import PatnetsRequestContextProvider from "../../../../../context/patents-collection/patents-request/provider";
import PatnetsRequestContext from "../../../../../context/patents-collection/patents-request/context";
import {
  getForColor,
  getForStatus,
} from "../../../../../models/workflow/enms-workflow/all-enums";
interface IProps {
  id: number;
}
const { Panel } = Collapse;
const Option = Select.Option;
const A: React.FC<IProps> = (props) => {
  const { actions, loading, details } = useContext(PatnetsRequestContext);
  const [nodes, setNodes] = useState<INodeCanMove[]>([]);
  const [loadingNodes, setLoadingNodes] = useState(true);
  const [statusSeletedNode, setstatusSeletedNode] = useState<any>();

  const { t } = useTranslation();
  useEffect(() => {
    props.id && actions.getDetails(props.id);
  }, [props.id]);

  useEffect(() => {
    const getNodesCanMoveRequest = async () => {
      try {
        const data = await EndPoints.patentRequest.getNodesUserCanMoveRequest(
          props.id
        );
        setNodes(data?.data?.data);
      } catch (error) {
      } finally {
        setLoadingNodes(false);
      }
    };
    getNodesCanMoveRequest();
  }, []);

  const { control } = useFormContext();

  const NextNodeId = useWatch({
    control,
    name: "NextNodeId",
  });

  useEffect(() => {
    const nodesId = nodes?.map((a) => a.id);
    const aa = nodesId?.indexOf(NextNodeId);
    const cc = nodes[aa]?.RequestStatus;
    setstatusSeletedNode(cc);
  }, [NextNodeId]);

  return (
    <>
      {loading.includes("details") ? (
        <div style={{ height: "60vh" }}>
          <Spin />
        </div>
      ) : (
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["2"]}>
              <Panel header={t("curreont_node_details")} key="1">
                <Image
                  width={100}
                  height={100}
                  style={{ borderRadius: "50%", padding: "4px" }}
                  src={details?.currentNode?.icon?.url ?? DefoultImage}
                />
                <Descriptions
                  size="middle"
                  column={1}
                  bordered
                  style={{ marginTop: "5px" }}
                >
                  <AntdDescriptions.Item label={t("id")}>
                    {details?.id}
                  </AntdDescriptions.Item>
                  <AntdDescriptions.Item label={t("name_patents")}>
                    {details?.patent?.name ? (
                      <Tag title={details?.patent?.name} />
                    ) : (
                      "-"
                    )}
                  </AntdDescriptions.Item>
                  <AntdDescriptions.Item label={t("type_requset")}>
                    <Tag
                      title={getForStatus(Number(details?.workflow?.type))}
                      color={getForColor(Number(details?.workflow?.type))}
                    />
                  </AntdDescriptions.Item>
                  <AntdDescriptions.Item label={t("current_node")}>
                    {details?.currentNode?.name?.name}
                  </AntdDescriptions.Item>

                  <AntdDescriptions.Item label={t("new_registration_status")}>
                    {details?.currentNode?.reservationStatus ? (
                      <Tag
                        title={getReservationStatus(
                          details?.currentNode?.reservationStatus
                        )}
                        color={getRequestStatusColor(
                          details?.currentNode?.reservationStatus
                        )}
                      />
                    ) : (
                      <Tag title="not_found" color="red" />
                    )}
                  </AntdDescriptions.Item>
                  {/* enum حالة التسحيل القديمة */}
                  <AntdDescriptions.Item label={t("old_registration_status")}>
                    {details?.Reservations?.length > 0 ? (
                      <Tag
                        title={getReservationStatus(
                          details?.Reservations[0]?.status
                        )}
                        color={getRequestStatusColor(
                          details?.Reservations[0]?.status
                        )}
                      />
                    ) : (
                      <Tag title="not_found" color="red" />
                    )}

                    {details?.Reservations?.length > 0}
                  </AntdDescriptions.Item>
                </Descriptions>
              </Panel>
              <Panel header={t("new_data")} key="2">
                <Row gutter={[8, 8]}>
                  <Col span={8}>
                    <FormItem label={t("next_node")}>
                      <Controller
                        name="NextNodeId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              {...field}
                              loading={loadingNodes}
                              style={{ width: "100%" }}
                              allowClear
                              showSearch
                              placeholder={t("next_node")}
                            >
                              {nodes?.map((tr, index) => {
                                return (
                                  <Option key={index} value={tr.id}>
                                    {tr?.name?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={8}>
                    <FormItem label={t("stauts_request")}>
                      <Input
                        disabled
                        value={getTrademarkRequestStatusString(
                          statusSeletedNode
                        )}
                      />
                    </FormItem>
                  </Col>

                  {/* <Col span={8}>
                    <FieldBuilder
                      name="NextNodeId"
                      label={`${t("next_node")}`}
                      width="large"
                      rules={{ required: true }}
                      input={{
                        type: "select",
                        loading: loadingNodes,
                        options: nodes?.map((node, index) => {
                          return {
                            label: node?.name?.name,
                            value: node?.id,
                          };
                        }),
                      }}
                    />
                  </Col> */}
                  <Col span={8}>
                    <FieldBuilder
                      name="officialDate"
                      label={`${t("official_date")}`}
                      input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                      rules={{ required: true }}
                    />
                  </Col>

                  <Col span={12}>
                    <FieldBuilder
                      name="clientMessage"
                      label={`${t("client_message")}`}
                      input={{ type: "text-area" }}
                      rules={{ required: false }}
                    />
                  </Col>
                  <Col span={12}>
                    <FieldBuilder
                      name="note"
                      label={`${t("note")}`}
                      input={{ type: "text-area" }}
                      rules={{ required: false }}
                    />
                  </Col>
                  <Col span={24}>
                    <Divider style={{ margin: "1px 0 1px 0" }} />
                  </Col>

                  <Col span={16}>
                    <Row gutter={[8, 8]}>
                      {details?.currentNode?.changeDepositDate && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="depositNumber"
                              label={`${t("deposit_number")}`}
                              input={{ type: "text" }}
                              rules={{ required: true }}
                            />
                          </Col>
                          <Col span={12}>
                            <FieldBuilder
                              name="depositDate"
                              label={`${t("deposit_date")}`}
                              input={{
                                type: "date-picker",
                                format: "YYYY-MM-DD",
                              }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )}

                      {details?.currentNode?.changeRegistrationDate && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="registrationNumber"
                              label={`${t("registration_number")}`}
                              input={{ type: "text" }}
                              rules={{ required: true }}
                            />
                          </Col>
                          <Col span={12}>
                            <FieldBuilder
                              name="registrationDate"
                              label={`${t("registration_date")}`}
                              input={{
                                type: "date-picker",
                                format: "YYYY-MM-DD",
                              }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )}

                      {details?.currentNode
                        ?.changeFirstPublishDateAndNumber && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="firstPublishNumber"
                              label={`${t("first_publish_number")}`}
                              input={{ type: "text" }}
                              rules={{ required: true }}
                            />
                          </Col>
                          <Col span={12}>
                            <FieldBuilder
                              name="firstPublishDate"
                              label={`${t("first_publish_date")}`}
                              input={{
                                type: "date-picker",
                                format: "YYYY-MM-DD",
                              }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )}

                      {details?.currentNode
                        ?.changeSecondPublishDateAndNumber && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="secondPublishNumber"
                              label={`${t("second_publish_number")}`}
                              input={{ type: "text" }}
                              rules={{ required: true }}
                            />
                          </Col>
                          <Col span={12}>
                            <FieldBuilder
                              name="secondPublishDate"
                              label={`${t("second_publish_date")}`}
                              input={{
                                type: "date-picker",
                                format: "YYYY-MM-DD",
                              }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )}

                      {details?.currentNode?.changeLastRenewalDate && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="lastRenewalNumber"
                              label={`${t("last_renewal_number")}`}
                              input={{ type: "text" }}
                              rules={{ required: true }}
                            />
                          </Col>
                          <Col span={12}>
                            <FieldBuilder
                              name="lastRenewalDate"
                              label={`${t("last_renewal_date")}`}
                              input={{
                                type: "date-picker",
                                format: "YYYY-MM-DD",
                              }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )}

                      {details?.currentNode?.changeNextRenewalDate && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="nextRenewalDate"
                              label={`${t("next_renewal_date")}`}
                              input={{
                                type: "date-picker",
                                format: "YYYY-MM-DD",
                              }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )}
                      {/* {details?.currentNode?.changeRenewalsCount && (
                        <>
                          <Col span={12}>
                            <FieldBuilder
                              name="s"
                              label={`${t("official_date")}`}
                              input={{ type: "number" }}
                              rules={{ required: true }}
                            />
                          </Col>
                        </>
                      )} */}
                    </Row>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </>
  );
};

const MovePatentsRequest: React.FC<IProps> = (props) => {
  return (
    <PatnetsRequestContextProvider>
      <A {...props} />
    </PatnetsRequestContextProvider>
  );
};
export default MovePatentsRequest;
