import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../antd/button'
import { CopyOutlined  } from '@ant-design/icons'

interface Props extends ButtonProps {}

const ClientBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('copy')}>
      <Button type='primary' shape='circle' icon={<CopyOutlined/>}  {...props} />
    </Tooltip>
  )
}

export default ClientBtn
