import { Tooltip } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Button, { ButtonProps } from "../../../antd/button";

interface Props extends ButtonProps {
  title?: string;
}

const AddBtnTable: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(props.title)}>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        {...props}
      />
    </Tooltip>
  );
};

export default AddBtnTable;
