import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DrawingRequestsTable from "../../../components/drawing-managmet/drawing-requests/drawing-request/drawing-requests-table";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import PatentsRequestFilter from "../../../components/patents-managment/patents-requests/patents-request/patents-request-filter";
import AppContext from "../../../context/app/context";
import PatnetsRequestContext from "../../../context/patents-collection/patents-request/context";
import PatnetsRequestContextProvider from "../../../context/patents-collection/patents-request/provider";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
import PatentsRequestsTable from "../../../components/patents-managment/patents-requests/patents-request/patents-requests-table";
import {
  IPatentsRequestExportExcel,
  namesOfColumnsPatentsRequest,
} from "../../../models/patents-collection/patents-request/request";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";

interface IProps {
  children?: React.ReactNode;
}

const PatentsRequests: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { direction } = useContext(AppContext);

  const { actions, loading, query } = useContext(PatnetsRequestContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getList();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("patents_orders")}
        subTitle={t("patents_orders_mangaments")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getList()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/patents-requests/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_patents_orders")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export_trademarks_requests_to_excel"
        title={t("export_trademarks_requests_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="70vw"
        defaultValues={
          {
            clients: false,
            Country: false,
            CreatedDate: false,
            currentNode: false,
            depositNumber: false,
            lastUpdatedDate: false,
            mainCategories: false,
            officialDate: false,
            status: false,
            patent: false,
            typeWorkflow: false,
            clientCost: false,
            clientPayment: false,
            fine: false,
            firstPublishDate: false,
            firstPublishNumber: false,
            officeCosts: false,
            secondPublishDate: false,
            secondPublishNumber: false,
            totalCost: false,
          } as IPatentsRequestExportExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={namesOfColumnsPatentsRequest} />
      </ExportToExcelModal>

      <SearchFilterCard
        formId="trademark-request-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <PatentsRequestFilter />
      </SearchFilterCard>

      <PatentsRequestsTable />
    </div>
  );
};

const PatentsRequestsPage = () => {
  return (
    <PatnetsRequestContextProvider>
      <PatentsRequests />
    </PatnetsRequestContextProvider>
  );
};
export default PatentsRequestsPage;
