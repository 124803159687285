import React, { useContext } from 'react'
import { isArray } from 'lodash'
import Spinner from '../../components/general/atoms/spinner'
import AuthContext from '../../context/auth/context'

export const check = (
  grantedPermissions: string[],
  action: string | string[]
) => {
  if (grantedPermissions.length === 0) return false

  if (isArray(action)) {
    if (grantedPermissions.find((p) => action.includes(p))) return true
  } else {
    if (grantedPermissions.find((p) => p === action)) return true
  }

  return false
}

interface IProps {
  perform: string | string[]
  cannot?: () => JSX.Element
  children: React.ReactNode
}

const Can: React.FC<IProps> = ({ perform, cannot, children }) => {
  const { grantedPermissions } = useContext(AuthContext)

  if (!grantedPermissions) return <Spinner />

  if (check([...grantedPermissions], perform)) {
    return <>{children}</>
  } else {
    return cannot ? cannot() : null
  }
}

export default Can
