import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

import {
  ICreateDrawingReservation,
  IUpdateDrawingReservation,
} from "../../../models/drawing-collection/drawing-reservation/request";
import {
  ICopyrightReservation,
  ICopyrightReservationDetails,
  ICopyrightReservationHistory,
} from "../../../models/copyright-collection/copyright-reservation/response";
import { ICopyrightReservationQuery } from "../../../models/copyright-collection/copyright-reservation/query";
import {
  ICreateCopyrightReservation,
  IUpdateCopyrightReservation,
} from "../../../models/copyright-collection/copyright-reservation/request";

export type CopyrightReservationLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "history";

export interface IInternalState {
  loading: CopyrightReservationLoading[];

  list?: IBaseListingResponse<ICopyrightReservation>;
  query: ICopyrightReservationQuery;

  details?: ICopyrightReservationDetails;
  history?: IBaseListingResponse<ICopyrightReservationHistory>;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    getHistory: (idReservation: number) => void;
    setDetails: (data?: ICopyrightReservationDetails) => void;

    createCopyrightReservation: (request: ICreateCopyrightReservation) => void;
    updateCopyrightReservation: (
      id: number,

      request: IUpdateCopyrightReservation
    ) => void;
    deleteCopyrightReservation: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ICopyrightReservationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    getHistory: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCopyrightReservation: DEFAULT_FUNCTION,
    updateCopyrightReservation: DEFAULT_FUNCTION,
    deleteCopyrightReservation: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CopyrightReservationContext = createContext(externalState);

export default CopyrightReservationContext;
