export enum types {
  DRAWING = 1,
  SAMPLE = 2,
}

export const getDrawingType = (type: types) => {
  switch (type) {
    case types.DRAWING:
      return "drawing";
    case types.SAMPLE:
      return "sample";
    default:
      return "drawing";
  }
};

export const getDrawingColor = (type: types) => {
  switch (type) {
    case types.DRAWING:
      return "orange";
    case types.SAMPLE:
      return "green";
    default:
      return "red";
  }
};

export enum typesCountry {
  LOCAL = 1,
  FOREIGN = 2,
}

export const getDrawingCountryType = (type: typesCountry) => {
  switch (type) {
    case typesCountry.LOCAL:
      return "local";
    case typesCountry.FOREIGN:
      return "foreign";
    default:
      return "local";
  }
};

export const getDrawingCountryColor = (type: typesCountry) => {
  switch (type) {
    case typesCountry.LOCAL:
      return "blue";
    case typesCountry.FOREIGN:
      return "red";
    default:
      return "local";
  }
};
