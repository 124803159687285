import { ICommercialPaymentsQuery } from "../../../../models/lawswits-collection/commercial/client-payments/query";
import {
  ICreateCommercialPayment,
  IUpdateCommercialPayment,
} from "../../../../models/lawswits-collection/commercial/client-payments/request";
import {
  ICommercialPayment,
  ICommercialPaymentDetails,
} from "../../../../models/lawswits-collection/commercial/client-payments/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class CommercialPaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClientPayments = (
    query: ICommercialPaymentsQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ICommercialPayment>>({
      path: `/commercial-suits-client-payment`,
      method: "GET",
      query,
      ...params,
    });

  getClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<ICommercialPaymentDetails>({
      path: `/commercial-suits-client-payment/${id}`,
      method: "GET",
      ...params,
    });

  createClientPayment = (
    data: ICreateCommercialPayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreateCommercialPayment>({
      path: "/commercial-suits-client-payment",
      method: "POST",
      body: data,
      ...params,
    });

  updateClientPayment = (
    id: number,
    data: IUpdateCommercialPayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreateCommercialPayment>({
      path: `/commercial-suits-client-payment/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<ICreateCommercialPayment>({
      path: `/commercial-suits-client-payment/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default CommercialPaymentService;
