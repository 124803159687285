import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  IDrawingRequesFiles,
  IDrawingRequesFilesDetails,
} from "../../../models/drawing-collection/drawing-requset-files/response";
import { IDrawingRequestFilesQuery } from "../../../models/drawing-collection/drawing-requset-files/query";
import {
  ICreateDrawingRequestFiles,
  IUpdateDrawingRequestFiles,
} from "../../../models/drawing-collection/drawing-requset-files/request";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<IDrawingRequesFiles>;
  query: IDrawingRequestFilesQuery;

  details?: IDrawingRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDrawingRequesFilesDetails) => void;

    createDrawingRequsetFile: (request: ICreateDrawingRequestFiles) => void;
    updateDrawingRequsetFile: (
      id: number,
      request: IUpdateDrawingRequestFiles
    ) => void;
    deleteDrawingRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createDrawingRequsetFile: DEFAULT_FUNCTION,
    updateDrawingRequsetFile: DEFAULT_FUNCTION,
    deleteDrawingRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DrawingRequestFilesContext = createContext(externalState);

export default DrawingRequestFilesContext;
