import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import AppContext from "../../../../context/app/context";

import { Col, Typography } from "antd";
import PatentsReservationContext from "../../../../context/patents-collection/patents-reservation/context";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../models/patents-collection/patents-reservation/enum";
import { IUpdatePatensReservation } from "../../../../models/patents-collection/patents-reservation/request";
import { IPatentsReservation } from "../../../../models/patents-collection/patents-reservation/response";
import { IPatents } from "../../../../models/patents-collection/patents/response";
import EndPoints from "../../../../services/end-points";
import FieldBuilder from "../../../form-components/field-builder";
import FormHookModal from "../../../general/modals/form-hook-modal";
import TransferBtn from "../../../general/table-components/actions/archive-btn";
import PatentsReservationForm from "../patents-reservation-form";

interface IProps {}
const { Title } = Typography;

const PatentsReservationTabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [idReservetion, setIdReservetion] = useState<number>();
  const { list, details, actions, loading, query } = useContext(
    PatentsReservationContext
  );

  const [infoId, setInfId] = useState<number>();
  const { id } = useParams();

  const { direction } = useContext(AppContext);

  useEffect(() => {
    actions.getData(Number(id));
  }, [direction, query]);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={
          loading.includes("create") ||
          loading.includes("details") ||
          loading.includes("update")
        }
        title={
          !details
            ? t("add_patents_and_utility_models_reservation")
            : t("update_patents_and_utility_models_reservation")
        }
        hideModal={() => {
          setInfId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        width={"60vw"}
        style={{ marginTop: "1px" }}
        onSubmit={async (data) => {
          details
            ? await actions.updatePatentsReservation(infoId, data)
            : await actions.createPatentsReservation(data);
          setInfId(undefined);
          actions.setDetails(undefined);
          actions.getData(Number(id));
        }}
        defaultValues={
          {
            patentId: Number(id),
            countryId: details?.country?.id,
            depositDate: details?.depositDate,
            depositNumber: details?.depositNumber,
            lastAnnualFeeDate: details?.lastAnnualFeeDate,
            lastAnnualFeeNumber: details?.lastAnnualFeeNumber,
            registrationDate: details?.registrationDate,
            registrationNumber: details?.registrationNumber,
            status: details?.status,
          } as IUpdatePatensReservation
        }
      >
        <PatentsReservationForm />
      </FormHookModal>
    ),
    [loading, details, infoId]
  );

  // archive reservation
  const [patents, setPatents] = useState<IPatents[]>([]);
  const [patentsLoading, setPatentsLoading] = useState(false);
  useEffect(() => {
    const getPatents = async () => {
      try {
        setPatentsLoading(true);

        const { data } = await EndPoints.patents.getAllPatents({ perPage: -1 });
        const patents = data?.data?.filter((tr) => tr?.id !== Number(id));
        setPatents(patents);
        setPatentsLoading(false);
      } catch (error) {
      } finally {
        setPatentsLoading(false);
      }
    };
    getPatents();
  }, []);

  const [showFormTransferModal, hideTransferFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={false}
        title={t("transfer_reservation_patent")}
        style={{ marginTop: "2rem" }}
        hideModal={() => {
          hideTransferFormodal();
          // setIdReservetion(undefined);
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          await EndPoints.patentsReservation.transferPatentReservation(
            idReservetion,
            {
              patentId: data?.patentId,
            }
          );
          // setIdReservetion(undefined);

          actions.getData(Number(id));
        }}
        defaultValues={{} as any}
      >
        <Title level={5}>
          {t(`${"transfer_reservation_patent_content"}`)}:
        </Title>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="patentId"
              label={`${t("patents_and_utility_models")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",

                loading: patentsLoading,
                options: patents.map((draw, index) => {
                  return {
                    label: draw.name as string,
                    value: draw.id as number,
                  };
                }),
              }}
            />
          </Col>
        </Row>
      </FormHookModal>
    ),
    [idReservetion]
  );

  const columns: ColumnProps<IPatentsReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },

    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getPatentsReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },

    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("last_annual_fee_date"),
      dataIndex: "lastAnnualFeeDate",
      align: "center",

      key: "lastAnnualFeeDate",
      render: (_, { lastAnnualFeeDate }) => {
        return (
          <p>
            {" "}
            {lastAnnualFeeDate
              ? moment(lastAnnualFeeDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_annual_fee_number"),
      dataIndex: "lastAnnualFeeNumber",
      align: "center",
      key: "lastAnnualFeeNumber",
    },

    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      align: "center",
      key: "registrationNumber",
    },
    {
      title: t("actions"),
      dataIndex: "a",

      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`resevation/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              setInfId(record?.id);
              showFormModal();
            }}
          />
          <TransferBtn
            onClick={async () => {
              setIdReservetion(record?.id);
              showFormTransferModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deletePatentsReservation(record?.id);
              eventManager.emit(EVENT_SUCCESS);
              actions.getData(Number(id));
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Button
          onClick={() => {
            setInfId(undefined);
            showFormModal();
          }}
          icon={<PlusOutlined />}
          type="primary"
          title={`${t("add_patents_and_utility_models_reservation")}`}
        >
          {t("add_patents_and_utility_models_reservation")}
        </Button>
      </Row>
      <Table<any>
        rowKey="id"
        size="small"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
        scroll={{ x: 400 }}
      />
    </>
  );
};

export default PatentsReservationTabel;
