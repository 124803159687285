import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import {
  IDrawing,
  IDrawingDetails,
} from "../../../models/drawing-collection/drawing/response";
import { IDrawingQuery } from "../../../models/drawing-collection/drawing/query";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import {
  ICreateDrawing,
  IUpdateDrawing,
} from "../../../models/drawing-collection/drawing/request";

export type DrawingLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: DrawingLoading[];

  list?: IBaseListingResponse<IDrawing>;
  query: IDrawingQuery;

  details?: IDrawingDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDrawingDetails) => void;

    createDrawing: (request: ICreateDrawing) => void;
    updateDrawing: (id: number, request: IUpdateDrawing) => void;
    deleteDrawing: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createDrawing: DEFAULT_FUNCTION,
    updateDrawing: DEFAULT_FUNCTION,
    deleteDrawing: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DrawingContext = createContext(externalState);

export default DrawingContext;
