import { Descriptions as AntdDescriptions, Collapse, Row, Space } from "antd";
import moment from "moment";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import DetailsPage from "../../../form-components/view-details";
import DeleteButton from "../../../general/antd/delete-button";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import styles from "./styles.module.scss";
import PatentsReservationContext from "../../../../context/patents-collection/patents-reservation/context";
import PatentsReservationHistoryTabel from "../patents-reservation-history-table";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../models/patents-collection/patents-reservation/enum";
import PatentsReservationContextProvider from "../../../../context/patents-collection/patents-reservation/provider";
import PatentReservationTrademarkRequestTabel from "../patents-reservation-order-table";
import PatentRecordTabel from "../patents-record/table";
import RecordPatentsContextProvider from "../../../../context/patents-collection/patents-record/provider";

interface Props { }
const { Panel } = Collapse;

const PatentsReservation: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(PatentsReservationContext);

  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // const [showFormModal, hideFormodal] = useModal(
  //   () => (
  //     <FormHookModal
  //       id="1"
  //       confirmButtonText={t("save")}
  //       loading={loading.includes("create")}
  //       title={t("copy_reservation_trademark")}
  //       hideModal={() => {
  //         hideFormodal();
  //       }}
  //       width={"40vw"}
  //       onSubmit={async (data) => {
  //         await actions.createDrawingReservation(data, Id);
  //         window.location.reload();
  //       }}
  //       defaultValues={
  //         {
  //           trademarkId: Id,
  //           allCategories: false,
  //         } as ICreateTrademarkReservation
  //       }
  //     >
  //       <Title level={5}> {t(`${"copy_trademark_reservation"}`)}: </Title>
  //       <Row gutter={[16, 8]}>
  //         <Col span={16}>
  //           <FieldBuilder
  //             name="trademark"
  //             label={`${t("trademark")}`}
  //             width="large"
  //             rules={{ required: true }}
  //             input={{
  //               type: "select",
  //               loading: trademarkLoading,
  //               options: trademark.map((trademark, index) => {
  //                 return {
  //                   label: trademark.name as string,
  //                   value: trademark.id as number,
  //                 };
  //               }),
  //             }}
  //           />
  //         </Col>
  //       </Row>
  //     </FormHookModal>
  //   ),
  //   [loading]
  // );

  return (
    <>
      <DetailsPage
        title={t(`${"details_patents_and_utility_models_reservation"}`)}
      >
        <div className={styles.container}>
          <Space direction="vertical">
            <Collapse>
              <Panel
                header={t("details_patents_and_utility_models_reservation")}
                key="1"
              >
                <Row>
                  <DeleteButton
                    onConfirm={async () => {
                      {
                        await actions.deletePatentsReservation(details.id);
                      }
                      eventManager.emit(EVENT_SUCCESS);
                      navigate(-1);
                    }}
                    loading={loading.includes("delete")}
                    className={styles.buttonDelete}
                  >
                    {t(`${"delete_patents_and_utility_models_reservation"}`)}
                  </DeleteButton>
                  {/* <CopyBtn
                    className={styles.button}
                    onClick={async () => {
                      setId(details?.id);
                      showFormModal();
                    }}
                  />
                  <CopyBtn
                    className={styles.button}
                    onClick={async () => {
                      setId(details?.id);
                      showFormModal();
                    }}
                  /> */}
                </Row>
                {loading.includes("details") ? (
                  <Spin />
                ) : (
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("country_name")}>
                      {details?.country?.name ? details?.country?.name : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("types")}>
                      <Tag
                        title={getBooleanPatensReservation(
                          Number(details?.status)
                        )}
                        color={getPatentsReservationColor(
                          Number(details?.status)
                        )}
                      />
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("deposit_date")}>
                      {details?.depositDate
                        ? moment(details.depositDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.depositNumber ? details.depositNumber : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_date")}>
                      {details?.registrationDate
                        ? moment(details.registrationDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_number")}>
                      {details?.registrationNumber
                        ? details.registrationNumber
                        : "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("last_annual_fee_date")}>
                      {details?.lastAnnualFeeDate
                        ? moment(details.lastAnnualFeeDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("last_annual_fee_number")}>
                      {details?.lastAnnualFeeNumber
                        ? details.lastAnnualFeeNumber
                        : "-"}
                    </AntdDescriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={1}>
              <Panel header={t("drawing_reservations_change_status")} key="1">
                <PatentsReservationHistoryTabel />
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("drawing_reservation_request")} key="1">
                <PatentReservationTrademarkRequestTabel />
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("record")} key="1">
                <PatentRecordTabel idPatentReservation={details?.id} />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </DetailsPage>
    </>
  );
};

const PatentsReservationDetailesPage = () => {
  return (
    <PatentsReservationContextProvider>
      <RecordPatentsContextProvider>
        <PatentsReservation />
      </RecordPatentsContextProvider>
    </PatentsReservationContextProvider>
  );
};
export default PatentsReservationDetailesPage;
