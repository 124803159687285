import React, { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext, { internalState } from "./context";
import reducer from "./reducer";

import { message } from "antd";
import { useTranslation } from "react-i18next";
import { IChangePassword } from "../../models/auth/request";
import EndPoints from "../../services/end-points";
import { httpclient } from "../../services/http-client";
import { ACCESS_TOKEN } from "../../utils/costants";
import { execute } from "../../utils/helpers/execute";
import { successNotification } from "../../utils/helpers/notification";

interface AuxProps {
  children: React.ReactNode;
}

const AuthContextProvider: React.FC<AuxProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const { t } = useTranslation();
  const navigate = useNavigate();
  /**
   * Check if there is access token
   */

  useEffect(() => {
    const token =
      localStorage.getItem(ACCESS_TOKEN) ||
      sessionStorage.getItem(ACCESS_TOKEN);
    if (token) {
      httpclient.setSecurityData({
        token: token,
      });
      me();
    } else {
      navigate("/login");
    }
  }, []);

  // Me
  const me = async () => {
    await execute({
      callback: async () => {
        const { data } = await EndPoints.auth.me();

        dispatch({
          type: "LOGIN_SUCCESS",
          payload: { user: data },
        });

        dispatch({
          type: "SET_PERMISSIONS",
          payload: { permissions: data?.permissions },
        });
      },

      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: () => { },
      throwException: false,
    });
  };

  // Login
  const login = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "login" } });

        const { data } = await EndPoints.auth.login(request);

        dispatch({ type: "LOGIN_SUCCESS", payload: { user: data.user } });
        dispatch({
          type: "SET_PERMISSIONS",
          payload: { permissions: data?.user?.permissions },
        });
        let permisions = JSON.stringify(data?.user?.permissions);
        localStorage.setItem("permisionStoroge", permisions);
        localStorage.setItem(ACCESS_TOKEN, data?.token)
       
        request.remember
          ? localStorage.setItem(ACCESS_TOKEN, data.token)
          : sessionStorage.setItem(ACCESS_TOKEN, data.token);
        httpclient.setSecurityData({
          token: data.token,
        });
        successNotification(
          t("loggedIn"),
          `${t("welcome")} ${data?.user?.name} ${t("message")}`
        );
        navigate("/");
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      },
      finallyCallback: async () => {
        await location.reload();
        dispatch({ type: "LOADING", payload: { loading: "login" } });
      },
      throwException: false,
    });
  };

  const changePassword = async (request: IChangePassword) => {
    await execute({
      callback: async () => {
        await EndPoints.auth.changePassword(request);

        navigate("/login");
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });
      },
      throwException: false,
    });
  };

  // Logout
  const logout = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });

        await EndPoints.auth.logout();

        dispatch({ type: "LOGOUT_SUCCESS" });
        localStorage.removeItem(ACCESS_TOKEN);
        sessionStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem("permisionStoroge");
        navigate("/login");
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "logout" } });
      },
      throwException: false,
    });
  };

  // Reset Code
  const resetCodeUser = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });

        const { data } = await EndPoints.auth.changePassword(request);
        message.success(t("success"));
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        throw error;
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "change_password" } });
      },
      throwException: false,
    });
  };

  const resetPassword = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "reset_password" } });

        const { data } = await EndPoints.auth.resetPassword(request);
        message.success(t("success"));
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        throw error;
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "reset_password" } });
      },
      throwException: false,
    });
  };

  const forgetPassword = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "forget_password" } });

        const { data } = await EndPoints.auth.forgetPassword(request);
        message.success(t("success"));
      },
      fallback: (error) => {
        dispatch({ type: "LOGOUT_SUCCESS" });
        throw error;
      },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "forget_password" } });
      },
      throwException: false,
    });
  };
  return (
    <AuthContext.Provider
      value={{
        ...state,
        actions: {
          login,
          logout,
          changePassword,
          resetCodeUser,
          resetPassword,
          forgetPassword
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
