import { ITranslationRequest } from "../../base/translation";
export interface ICreateAgentRegistration {
  officialDate: string;
  dateFinancialReceipt: string;
  depositNumber: string;
  financialReceiptNumber: string;
  legalFormPerson: string;
  companyManagerName: string;
  privateOfficeAddress: string;
  address: string;
  note: string;
  criminalDocumentOrUnjudged: number;
  restrictionOrPersonalPhoto: number;
  commercialRegister: number;
  universityQualification: number;
  clientId: number;
  countryId: number;
  orderValue: number;
  clientPayments: number;
}

export interface IUpdateAgentRegistration extends ICreateAgentRegistration {}

// to do
export interface IExportAgentRegistrationExcel {
  depositNumber: boolean;
  country: boolean;
  client: boolean;
  legalFormPerson: boolean;
  companyManagerName: boolean;
  createdAt: boolean;
  officialDate: boolean;
  dateFinancialReceipt: boolean;
  financialReceiptNumber: boolean;
}

export const AgentRegistrationCloumms = [
  "depositNumber",
  "country",
  "client",
  "legalFormPerson",
  "companyManagerName",
  "createdAt",
  "officialDate",
  "dateFinancialReceipt",
  "financialReceiptNumber",
];
