import { createContext } from "react";
import { IClientPaymentsQuery } from "../../../models/trademark-collection/client-payments/query";

import { IDrawingClientPaymentsQuery } from "../../../models/drawing-collection/client-payments/query";
import {
  ICreateDrawingClientPayment,
  IUpdateDrawingClientPayment,
} from "../../../models/drawing-collection/client-payments/request";
import {
  IDrawingClientPayment,
  IDrawingClientPaymentDetails,
} from "../../../models/drawing-collection/client-payments/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type ClientPaymentLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ClientPaymentLoading[];

  list?: IBaseListingResponse<IDrawingClientPayment>;
  query: IDrawingClientPaymentsQuery;

  details?: IDrawingClientPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDrawingClientPaymentDetails) => void;

    createClientPayment: (request: ICreateDrawingClientPayment) => void;
    updateClientPayment: (
      id: number,
      request: IUpdateDrawingClientPayment
    ) => void;
    deleteCleintPayment: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IClientPaymentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClientPayment: DEFAULT_FUNCTION,
    updateClientPayment: DEFAULT_FUNCTION,
    deleteCleintPayment: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DrawingClientPaymentContext = createContext(externalState);

export default DrawingClientPaymentContext;
