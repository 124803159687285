export enum DrawingStatus {
  renewal = 2,
  registration = 1,
  annual_fee = 4,
}

// drawing orderTemplate enum
export const getBooleanDrawinOrderTemplate = (status: DrawingStatus) => {
  switch (status) {
    case DrawingStatus.registration:
      return "registration";
    case DrawingStatus.annual_fee:
      return "annual_fee";
    case DrawingStatus.renewal:
      return "renewal";
  }
};

export const getDrawingOrderTemplateColor = (status: DrawingStatus) => {
  switch (status) {
    case DrawingStatus.registration:
      return "blue";
    case DrawingStatus.annual_fee:
      return "orange";
    case DrawingStatus.renewal:
      return "green";
  }
};
