export const STATIC_PERMISSIONS = [
  "can-view-home-page",
  "can-view-role",
  "can-create-role",
  "can-update-role",
  "can-delete-role",
  "can-export-role",
  "can-view-permissions",
  "can-view-users",
  "can-create-user",
  "can-update-user",
  "can-delete-user",
  "can-export-user",
  "can-view-clients",
  "can-create-client",
  "can-update-client",
  "can-delete-client",
  "can-export-client",
  "can-copy-client",
  "can-view-workflows",
  "can-create-workflow",
  "can-update-workflow",
  "can-delete-workflow",
  "can-export-workflow",
  "can-copy-workflow",
  "can-view-nodes",
  "can-create-node",
  "can-update-node",
  "can-delete-node",
  "can-child-node",
  "can-parent-node",
  "can-remove-node",
  "can-view-trademark-requests",
  "can-create-trademark-request",
  "can-update-trademark-request",
  "can-delete-trademark-request",
  "can-export-trademark-request",
  "can-move-trademark-request",
  "can-manage-reservation-in-trademark-request",
  "can-view-trademarks",
  "can-create-trademark",
  "can-update-trademark",
  "can-delete-trademark",
  "can-export-trademark",
  "can-view-trademark-reservations",
  "can-create-trademark-reservation",
  "can-update-trademark-reservation",
  "can-delete-trademark-reservation",
  "can-transfer-trademark-reservation",
  "can-archive-trademark-reservation",
  "can-view-record-trademark",
  "can-create-record-trademark",
  "can-update-record-trademark",
  "can-delete-record-trademark",
  "can-view-trademark-request-client-payment",
  "can-create-trademark-request-client-payment",
  "can-update-trademark-request-client-payment",
  "can-delete-trademark-request-client-payment",
  "can-view-trademark-request-file",
  "can-create-trademark-request-file",
  "can-update-trademark-request-file",
  "can-delete-trademark-request-file",
  "can-view-trademark-request-file-with-trashed",
  "can-restore-trademark-request-file",
  "can-view-trademark-request-cost",
  "can-create-trademark-request-cost",
  "can-update-trademark-request-cost",
  "can-delete-trademark-request-cost",
  "can-view-trademark-request-cost-with-trashed",
  "can-restore-trademark-request-cost",
  "can-view-drawings",
  "can-create-drawing",
  "can-update-drawing",
  "can-delete-drawing",
  "can-export-drawing",
  "can-view-drawing-reservations",
  "can-create-drawing-reservation",
  "can-update-drawing-reservation",
  "can-delete-drawing-reservation",
  "can-transfer-drawing-reservation",
  "can-view-drawing-reservation-history",
  "can-create-drawing-reservation-history",
  "can-update-drawing-reservation-history",
  "can-delete-drawing-reservation-history",
  "can-view-record-drawing",
  "can-create-record-drawing",
  "can-update-record-drawing",
  "can-delete-record-drawing",
  "can-view-drawing-requests",
  "can-create-drawing-request",
  "can-update-drawing-request",
  "can-delete-drawing-request",
  "can-export-drawing-request",
  "can-move-drawing-request",
  "can-manage-reservation-in-drawing-request",
  "can-view-drawing-request-client-payment",
  "can-create-drawing-request-client-payment",
  "can-update-drawing-request-client-payment",
  "can-delete-drawing-request-client-payment",
  "can-view-drawing-request-file",
  "can-create-drawing-request-file",
  "can-update-drawing-request-file",
  "can-delete-drawing-request-file",
  "can-view-drawing-request-cost",
  "can-create-drawing-request-cost",
  "can-update-drawing-request-cost",
  "can-delete-drawing-request-cost",
  "can-view-drawing-request-history",
  "can-create-drawing-request-history",
  "can-update-drawing-request-history",
  "can-delete-drawing-request-history",
  "can-view-patents",
  "can-create-patent",
  "can-update-patent",
  "can-delete-patent",
  "can-export-patent",
  "can-view-patent-reservations",
  "can-create-patent-reservation",
  "can-update-patent-reservation",
  "can-delete-patent-reservation",
  "can-view-patent-reservation-history",
  "can-create-patent-reservation-history",
  "can-update-patent-reservation-history",
  "can-delete-patent-reservation-history",
  "can-view-record-patent",
  "can-create-record-patent",
  "can-update-record-patent",
  "can-delete-record-patent",
  "can-view-patent-requests",
  "can-create-patent-request",
  "can-update-patent-request",
  "can-delete-patent-request",
  "can-export-patent-request",
  "can-move-patent-request",
  "can-manage-reservation-in-patent-request",
  "can-view-patent-request-client-payment",
  "can-create-patent-request-client-payment",
  "can-update-patent-request-client-payment",
  "can-delete-patent-request-client-payment",
  "can-view-patent-request-client-payment-with-trashed",
  "can-restore-patent-request-client-payment",
  "can-view-patent-request-file",
  "can-create-patent-request-file",
  "can-update-patent-request-file",
  "can-delete-patent-request-file",
  "can-view-patent-request-cost",
  "can-create-patent-request-cost",
  "can-update-patent-request-cost",
  "can-delete-patent-request-cost",
  "can-view-agent-change-requests",
  "can-create-agent-change-request",
  "can-update-agent-change-request",
  "can-delete-agent-change-request",
  "can-export-agent-change-request",
  "can-view-agent-registration-requests",
  "can-create-agent-registration-request",
  "can-update-agent-registration-request",
  "can-delete-agent-registration-request",
  "can-export-agent-registration-request",
  "can-view-certified-copy-requests",
  "can-create-certified-copy-request",
  "can-update-certified-copy-request",
  "can-delete-certified-copy-request",
  "can-export-certified-copy-request",
  "can-view-category",
  "can-create-category",
  "can-update-category",
  "can-delete-category",
  "can-export-category",
  "can-view-node-name",
  "can-create-node-name",
  "can-update-node-name",
  "can-delete-node-name",
  "can-view-job-title",
  "can-create-job-title",
  "can-update-job-title",
  "can-delete-job-title",
  "can-view-country",
  "can-create-country",
  "can-update-country",
  "can-delete-country",
  "can-export-country",
  "can-view-cost-type",
  "can-create-cost-type",
  "can-update-cost-type",
  "can-delete-cost-type",
  "can-view-commercial-lawsuits-request-client",
  "can-create-commercial-lawsuits-request-client",
  "can-update-commercial-lawsuits-request-client",
  "can-delete-commercial-lawsuits-request-client",
  "can-view-commercial-lawsuits-request-cost",
  "can-create-commercial-lawsuits-request-cost",
  "can-update-commercial-lawsuits-request-cost",
  "can-delete-commercial-lawsuits-request-cost",
  "can-view-commercial-lawsuits-request-file",
  "can-create-commercial-lawsuits-request-file",
  "can-update-commercial-lawsuits-request-file",
  "can-delete-commercial-lawsuits-request-file",
  "can-view-commercial-lawsuits-request",
  "can-create-commercial-lawsuits-request",
  "can-update-commercial-lawsuits-request",
  "can-delete-commercial-lawsuits-request",
  "can-export-commercial-lawsuits-request",
  "can-move-commercial-lawsuits-request",
  "can-view-copyright",
  "can-create-copyright",
  "can-update-copyright",
  "can-delete-copyright",
  "can-export-copyright",
  "can-view-copyright-request-client-payment",
  "can-create-copyright-request-client-payment",
  "can-update-copyright-request-client-payment",
  "can-delete-copyright-request-client-payment",
  "can-view-copyright-request-cost",
  "can-create-copyright-request-cost",
  "can-update-copyright-request-cost",
  "can-delete-copyright-request-cost",
  "can-view-copyright-request-file",
  "can-create-copyright-request-file",
  "can-update-copyright-request-file",
  "can-delete-copyright-request-file",
  "can-view-copyright-request-history",
  "can-create-copyright-request-history",
  "can-update-copyright-request-history",
  "can-delete-copyright-request-history",
  "can-view-copyright-request",
  "can-create-copyright-request",
  "can-update-copyright-request",
  "can-delete-copyright-request",
  "can-export-copyright-request",
  "can-move-copyright-request",
  "can-manage-reservation-in-copyright-request",
  "can-view-copyright-reservation-history",
  "can-create-copyright-reservation-history",
  "can-update-copyright-reservation-history",
  "can-delete-copyright-reservation-history",
  "can-view-copyright-reservation",
  "can-create-copyright-reservation",
  "can-update-copyright-reservation",
  "can-delete-copyright-reservation",
  "can-transfer-copyright-reservation",
  "can-view-record-copyright",
  "can-create-record-copyright",
  "can-update-record-copyright",
  "can-delete-record-copyright",
  "can-view-data-request",
  "can-create-data-request",
  "can-update-data-request",
  "can-delete-data-request",
  "can-export-data-request",
  "can-view-disclosure-trademark",
  "can-create-disclosure-trademark",
  "can-update-disclosure-trademark",
  "can-delete-disclosure-trademark",
  "can-export-disclosure-trademark",
  "can-view-disclosure-trademark-request",
  "can-create-disclosure-trademark-request",
  "can-update-disclosure-trademark-request",
  "can-delete-disclosure-trademark-request",
  "can-export-disclosure-trademark-request",
  "can-view-grievance-lawsuits-request",
  "can-create-grievance-lawsuits-request",
  "can-update-grievance-lawsuits-request",
  "can-delete-grievance-lawsuits-request",
  "can-export-grievance-lawsuits-request",
  "can-move-grievance-lawsuits-request",
  "can-view-grievance-lawsuits-request-history",
  "can-create-grievance-lawsuits-request-history",
  "can-update-grievance-lawsuits-request-history",
  "can-delete-grievance-lawsuits-request-history",
  "can-view-grievance-lawsuits-request-cost",
  "can-create-grievance-lawsuits-request-cost",
  "can-update-grievance-lawsuits-request-cost",
  "can-delete-grievance-lawsuits-request-cost",
  "can-view-grievance-lawsuits-request-file",
  "can-create-grievance-lawsuits-request-file",
  "can-update-grievance-lawsuits-request-file",
  "can-delete-grievance-lawsuits-request-file",
  "can-view-grievance-lawsuits-request-client-payment",
  "can-create-grievance-lawsuits-request-client-payment",
  "can-update-grievance-lawsuits-request-client-payment",
  "can-delete-grievance-lawsuits-request-client-payment",
  "can-view-incident-client-request",
  "can-create-incident-client-request",
  "can-update-incident-client-request",
  "can-delete-incident-client-request",
  "can-export-incident-client-request",
  "can-move-incident-client-request",
  "can-view-incident-client-request-history",
  "can-create-incident-client-request-history",
  "can-update-incident-client-request-history",
  "can-delete-incident-client-request-history",
  "can-view-incident-client-request-cost",
  "can-create-incident-client-request-cost",
  "can-update-incident-client-request-cost",
  "can-delete-incident-client-request-cost",
  "can-view-incident-client-request-file",
  "can-create-incident-client-request-file",
  "can-update-incident-client-request-file",
  "can-delete-incident-client-request-file",
  "can-view-incident-client-request-client-payment",
  "can-create-incident-client-request-client-payment",
  "can-update-incident-client-request-client-payment",
  "can-delete-incident-client-request-client-payment",
  "can-view-incident-licensing-request",
  "can-create-incident-licensing-request",
  "can-update-incident-licensing-request",
  "can-delete-incident-licensing-request",
  "can-export-incident-licensing-request",
  "can-move-incident-licensing-request",
  "can-view-incident-licensing-request-history",
  "can-create-incident-licensing-request-history",
  "can-update-incident-licensing-request-history",
  "can-delete-incident-licensing-request-history",
  "can-view-incident-licensing-request-cost",
  "can-create-incident-licensing-request-cost",
  "can-update-incident-licensing-request-cost",
  "can-delete-incident-licensing-request-cost",
  "can-view-incident-licensing-request-file",
  "can-create-incident-licensing-request-file",
  "can-update-incident-licensing-request-file",
  "can-delete-incident-licensing-request-file",
  "can-view-incident-licensing-request-client-payment",
  "can-create-incident-licensing-request-client-payment",
  "can-update-incident-licensing-request-client-payment",
  "can-delete-incident-licensing-request-client-payment",
  "can-view-incident-waiver-request",
  "can-create-incident-waiver-request",
  "can-update-incident-waiver-request",
  "can-delete-incident-waiver-request",
  "can-export-incident-waiver-request",
  "can-view-incident-waiver-request-history",
  "can-create-incident-waiver-request-history",
  "can-update-incident-waiver-request-history",
  "can-delete-incident-waiver-request-history",
  "can-view-incident-waiver-request-cost",
  "can-create-incident-waiver-request-cost",
  "can-update-incident-waiver-request-cost",
  "can-delete-incident-waiver-request-cost",
  "can-view-incident-waiver-request-file",
  "can-create-incident-waiver-request-file",
  "can-update-incident-waiver-request-file",
  "can-delete-incident-waiver-request-file",
  "can-view-incident-waiver-request-client-payment",
  "can-create-incident-waiver-request-client-payment",
  "can-update-incident-waiver-request-client-payment",
  "can-delete-incident-waiver-request-client-payment",
  "can-view-waiver-request",
  "can-create-waiver-request",
  "can-update-waiver-request",
  "can-delete-waiver-request",
  "can-export-waiver-request",
  "can-view-nationality",
  "can-create-nationality",
  "can-update-nationality",
  "can-delete-nationality",
  "can-export-nationality",
  "can-view-objection-request",
  "can-create-objection-request",
  "can-update-objection-request",
  "can-delete-objection-request",
  "can-export-objection-request",
  "can-move-incident-waiver-request",
  "can-view-all-Reports",
  "can-view-Report",
  "can-create-Report",
  "can-delete-Report",
  "can-create-Report-trademark-reservation-in-renewal",
  "can-create-Report-trademark-reservation-in-renewal-with-fee",
  "can-create-Report-trademark-reservation-in-registration",
  "can-create-Report-trademark-reservation-entered-addition-renewal",
  "can-create-Report-trademark-reservation-entered-renewal",
  "can-create-Report-trademark-reservation-from-protected-to-not-protected",
  "can-create-Report-trademark-reservation-from-negative-to-not-protected",
  "can-create-Report-trademark-reservation-from-protected-to-negative",
  "can-view-all-commercial-records",
  "can-view-commercial-record",
  "can-create-commercial-record",
  "can-update-commercial-record",
  "can-delete-commercial-record",
  "can-export-trademark-request-word",
  "can-export-trademark-request-deposit-word",
  "can-export-drawing-request-word",
  "can-export-drawing-request-deposit-word",
  "can-export-incident-waiver-request-word",
  "can-export-incident-client-request-word",
  "can-export-incident-licensing-request-word",
  "can-move-objection-request",
  "can-view-independent-request-payment-request",
  "can-create-independent-request-payment-request",
  "can-update-independent-request-payment-request",
  "can-delete-independent-request-payment-request",
  "can-export-independent-request-payment-request",
  "can-view-all-independent-request-Cost-request",
  "can-view-independent-request-Cost-request",
  "can-create-independent-request-Cost-request",
  "can-update-independent-request-Cost-request",
  "can-delete-independent-request-Cost-request",
  "can-export-independent-request-Cost-request",
];

// add permissins to side bar
export const CAN_VIEW_WAIVER_REQUEST = "can-view-waiver-request";
export const CAN_VIEW_DATA_REQUEST = "can-view-data-request";
export const CAN_VIEW_DISCLOSURE_TRADEMARK = "can-view-disclosure-trademark";
export const CAN_VIEW_HOME_PAGE = "can-view-home-page";
export const CAN_VIEW_NATIONALITY = "can-view-nationality";
export const CAN_VIEW_USERS = "can-view-users";
export const CAN_VIEW_JOB_TITLE = "can-view-job-title";
export const CAN_VIEW_COST_TYPE = "can-view-cost-type";
export const CAN_VIEW_CATEGORY = "can-view-category";
export const CAN_VIEW_ROLE = "can-view-role";
export const CAN_VIEW_COUNTRY = "can-view-country";
export const CAN_VIEW_NODES = "can-view-nodes";
export const CAN_VIEW_CLIENTS = "can-view-clients";
export const CAN_VIEW_TRADEMARKS = "can-view-trademarks";
export const CAN_VIEW_DRAWINGS = "can-view-drawings";
export const CAN_VIEW_PATENTS = "can-view-patents";
export const CAN_VIEW_COPYRIGHT = "can-view-copyright";
export const CAN_VIEW_WORKFLOWS = "can-view-workflows";
export const CAN_VIEW_TRADEMARK_REQUESTS = "can-view-trademark-requests";
export const CAN_VIEW_DRAWING_REQUESTS = "can-view-drawing-requests";
export const CAN_VIEW_PATENT_REQUESTS = "can-view-patent-requests";
export const CAN_VIEW_COPYRIGHT_REQUEST = "can-view-copyright-request";
export const CAN_VIEW_INCIDENT_CLIENT_REQUEST =
  "can-view-incident-client-request";
export const CAN_VIEW_INCIDENT_WAIVER_REQUEST =
  "can-view-incident-waiver-request";
export const CAN_VIEW_INCIDENT_LICENSING_REQUEST =
  "can-view-incident-licensing-request";
export const CAN_VIEW_GRIEVANCE_LAWSUITS_REQUEST =
  "can-view-grievance-lawsuits-request";
export const CAN_VIEW_COMMERCIAL_LAWSUITS_REQUEST_CLIENT =
  "can-view-commercial-lawsuits-request-client";
export const CAN_VIEW_CERTIFIED_COPY_REQUESTS =
  "can-view-certified-copy-requests";
export const CAN_VIEW_AGENT_CHANGE_REQUESTS = "can-view-agent-change-requests";
export const CAN_VIEW_AGENT_REGISTRATION_REQUESTS =
  "can-view-agent-registration-requests";
export const CAN_VIEW_NODE_NAME = "can-view-node-name";
export const CAN_VIEW_OBJECTION_REQUEST = "can-view-objection-request";
export const CAN_VIEW_REPORTS = "can-view-reports";
export const CAN_VIEW_COMMERCIAL_RECORD = "can-view-commercial-record";
export const CAN_VIEW_REPORT = "can-view-Report";
