import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { ITrademarkQuery } from "../../../models/trademark-collection/trademark/query";
import {
  ITrademark,
  ITrademarkDetails,
} from "../../../models/trademark-collection/trademark/response";
import {
  ICreateTrademark,
  IExportTrademarkExcel,
  IUpdateTrademark,
} from "../../../models/trademark-collection/trademark/request";
import axios from "axios";

class TrademarkService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllTrademarks = (query: ITrademarkQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ITrademark>>({
      path: `/trademarks`,
      method: "GET",
      query,
      ...params,
    });

  getTrademark = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkDetails>({
      path: `/trademarks/${id}`,
      method: "GET",
      ...params,
    });

  createTrademark = (data: ICreateTrademark, params?: RequestParams) =>
    this.http.request<ITrademarkDetails>({
      path: "/trademarks",
      method: "POST",
      body: data,
      ...params,
    });

  updateTrademark = (
    id: number,
    data: IUpdateTrademark,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkDetails>({
      path: `/trademarks/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteTrademark = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkDetails>({
      path: `/trademarks/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportTrademarkExcel, query: ITrademarkQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/trademarks/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default TrademarkService;
