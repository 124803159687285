import { Descriptions as AntdDescriptions, Collapse } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../../context/app/context";
import CountryContext from "../../../../context/management-collection/country/context";
import CountryContextProvider from "../../../../context/management-collection/country/provider";
import { TypeTrademarkRenewalonsiderationDate } from "../../../../models/managent-collection/country/enum";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import PageDetails from "../../../general/details";
interface Props {}
const CountryDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CountryContext);
  const { direction } = useContext(AppContext);

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("country")}`}
        buttonName={`${t("edite_country")}`}
        onClick={() => navigate(`/countries/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Collapse defaultActiveKey={3}>
            <Panel header={t("country_details")} key="1">
              <Descriptions size="middle" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("name")}>
                  {details?.name}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("currency_name")}>
                  {details?.currencyName}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("currency_code")}>
                  {details?.currencyCode}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("genaral_follow_up _role")}>
                  {details?.roles?.map((role, index) => {
                    return <Tag title={role?.name} />;
                  })}
                </AntdDescriptions.Item>
              </Descriptions>
            </Panel>
            <Panel header={t("trade_marks")} key="2">
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item
                  label={`${t("registration_protection_period")} ${t("years")}`}
                >
                  {details?.trademarkRegistrationProtectionPeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("renewal_protection_period")} ${t("years")}`}
                >
                  {details?.trademarkRenewalProtectionPeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("basic_renewal_period")} ${t("months")}`}
                >
                  {details?.trademarkRenewalPeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("up_to_end_of_month_in_basic_period")}`}
                >
                  {details?.completionLastMonthRenewalPeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("thereRenewalWithFinePeriod")}`}
                >
                  {details?.thereRenewalWithFinePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("additional_allow_period")}  ${t("months")}`}
                >
                  {details?.trademarkRenewalWithFinePeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("there_is_a_fine_in_the_additional_period")}`}
                >
                  {details?.thereFineForRenewalWithFinePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={`${t("passive_protection")}`}>
                  {details?.thereNegativePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("passive_protection_period")} ${t("months")}`}
                >
                  {details?.trademarkNegativeProtectionPeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("trade_mark_multiple_reservation_in_request")}`}
                >
                  {details?.trademarkMultipleReservationInRequest ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("up_to_end_of_month_in_additional_period")}`}
                >
                  {details?.completionLastMonthRenewalWithFinePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("max_reservations_in_request")}`}
                >
                  {details?.trademarkMaxReservationCountNnRequest ?? "-"}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={`${t("base_date_type")}`}>
                  <Tag
                    title={t(
                      TypeTrademarkRenewalonsiderationDate(
                        Number(details?.trademarkRenewalConsiderationDate)
                      )
                    )}
                  />
                </AntdDescriptions.Item>
              </Descriptions>
            </Panel>
            <Panel header={t("industrial_designs")} key="3">
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item
                  label={`${t("up_to_end_of_month_in_basic_period")}`}
                >
                  {details?.drawingCompletionLastMonthRenewalPeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={`${t("base_date_type")}`}>
                  <Tag
                    title={t(
                      TypeTrademarkRenewalonsiderationDate(
                        Number(details?.drawingConsiderationDate)
                      )
                    )}
                  />
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("basic_renewal_period")} ${t("months")}`}
                >
                  {details?.drawingRenewalPeriod}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("up_to_end_of_month_in_additional_period")}`}
                >
                  {details?.drawingCompletionLastMonthAdditionalRenewalPeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("there_is_a_fine_in_the_additional_period")}`}
                >
                  {details?.drawingThereFineForAdditionalRenewalPeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingConsiderationPeriod")} ${t("months")}`}
                >
                  {details?.drawingConsiderationPeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingProtectionPeriod")} ${t("years")}`}
                >
                  {details?.drawingProtectionPeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingAdditionalRenewalPeriod")} ${t(
                    "months"
                  )}`}
                >
                  {details?.drawingAdditionalRenewalPeriod}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("drawingThereAdditionalRenewalPeriod")}`}
                >
                  {details?.drawingThereAdditionalRenewalPeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("drawingMultipleReservationInRequest")}`}
                >
                  {details?.drawingMultipleReservationInRequest ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("drawingMaxReservationCountInRequest")} ${t(
                    "months"
                  )}`}
                >
                  {details?.drawingMaxReservationCountInRequest}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingMaxRenewalCount")} ${t("months")}`}
                >
                  {details?.drawingMaxRenewalCount}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingCompletionLastMonthAnnualFeePeriod")}`}
                >
                  {details?.drawingCompletionLastMonthAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t(
                    "drawingCompletionLastMonthAdditionalAnnualFeePeriod"
                  )}`}
                >
                  {details?.drawingCompletionLastMonthAdditionalAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingThereFineForAdditionalAnnualFeePeriod")}`}
                >
                  {details?.drawingThereFineForAdditionalAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingThereAdditionalAnnualFeePeriod")}`}
                >
                  {details?.drawingThereAdditionalAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingAnnualFeePeriod")} ${t("months")}`}
                >
                  {details?.drawingAnnualFeePeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingAdditionalAnnualFeePeriod")} ${t(
                    "months"
                  )}`}
                >
                  {details?.drawingAdditionalAnnualFeePeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("drawingProtectionAnnualFeePeriod")} ${t(
                    "months"
                  )}`}
                >
                  {details?.drawingProtectionAnnualFeePeriod}
                </AntdDescriptions.Item>
              </Descriptions>
            </Panel>
            <Panel header={t("patents_and_utility_models")} key="4">
              <Descriptions size="small" column={1} bordered>
                <AntdDescriptions.Item label={`${t("base_date_type")}`}>
                  <Tag
                    title={t(
                      TypeTrademarkRenewalonsiderationDate(
                        Number(details?.patentConsiderationDate)
                      )
                    )}
                  />
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("patentProtectionPeriod")} ${t("years")}`}
                >
                  {details?.patentProtectionPeriod}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("utilityModelProtectionPeriod")} ${t("years")}`}
                >
                  {details?.utilityModelProtectionPeriod}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("patentCompletionLastMonthAnnualFeePeriod")}`}
                >
                  {details?.patentCompletionLastMonthAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t(
                    "patentCompletionLastMonthAdditionalAnnualFeePeriod"
                  )}`}
                >
                  {details?.patentCompletionLastMonthAdditionalAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("patentThereFineForAdditionalAnnualFeePeriod")}`}
                >
                  {details?.patentThereFineForAdditionalAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item
                  label={`${t("patentThereAdditionalAnnualFeePeriod")}`}
                >
                  {details?.patentThereAdditionalAnnualFeePeriod ? (
                    <ImCheckmark color="green" />
                  ) : (
                    <ImCross color="red" />
                  )}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("patentAnnualFeePeriod")} ${t("months")}`}
                >
                  {details?.patentAnnualFeePeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("patentAdditionalAnnualFeePeriod")} ${t(
                    "months"
                  )}`}
                >
                  {details?.patentAdditionalAnnualFeePeriod}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item
                  label={`${t("patentProtectionAnnualFeePeriod")} ${t(
                    "months"
                  )}`}
                >
                  {details?.patentProtectionAnnualFeePeriod}
                </AntdDescriptions.Item>
              </Descriptions>
            </Panel>
          </Collapse>
        )}
      </PageDetails>
    </>
  );
};

const CountryDetailsPage = () => {
  return (
    <CountryContextProvider>
      <CountryDetails />
    </CountryContextProvider>
  );
};
export default CountryDetailsPage;
