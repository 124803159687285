import { Col, Popover, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IClient } from "../../../../models/client/response";
import { IUpdateCertifiedCopy } from "../../../../models/independent-requests-collection/certified-copy/request";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { MediumFor } from "../../../../models/medium/enum";
import EndPoints from "../../../../services/end-points";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import FileUploader from "../../../form-components/file-uploader";
import GeneralForm from "../../../form-components/general-form";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import AgentChangeContextProvider from "../../../../context/independent_requests-collection/agent-change/provider";
import AgentChangeContext from "../../../../context/independent_requests-collection/agent-change/context";
import { IUpdateAgentChange } from "../../../../models/independent-requests-collection/agent-change/request";
import Image from "../../../general/antd/image";
import styles from "./style.module.scss";
import { FaTrash } from "react-icons/fa";
import { getUrlImageFile } from "../../../../utils/helpers/check-type-file";
import { IMedium } from "../../../../models/medium/response";
interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(AgentChangeContext);

  const { t } = useTranslation();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [file, setFile] = useState<IMedium>(null);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all CLients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    details && setFile(details?.file);
  }, [details]);

  return (
    <GeneralForm
      values={[{ key: "fileId", value: file?.id ?? null }]}
      onSubmit={async (data) => {
        details
          ? await actions.updateAgentChange(details?.id, data)
          : await actions.createAgentChange(data);
      }}
      title={
        details
          ? t("update_agent_change_request")
          : t("add_agent_change_request")
      }
      defaultValues={
        {
          // fileId: file?.id ?? null,
          note: details?.note,
          approvingNewAgentDate: details?.approvingNewAgentDate,
          clientId: details?.client?.id,
          countryId: details?.country?.id,
          dateFinancialReceipt: details?.dateFinancialReceipt,
          depositNumber: details?.depositNumber,
          financialReceiptNumber: details?.financialReceiptNumber,
          nameNewAgent: details?.nameNewAgent,
          nameOldAgent: details?.nameOldAgent,
          newAgentAddress: details?.newAgentAddress,
          officialDate: details?.officialDate,
          oldAgentAddress: details?.oldAgentAddress,
          residenceNewAgent: details?.residenceNewAgent,
          residenceOldAgent: details?.residenceOldAgent,
          orderValue: details?.orderValue,
          // clientPayments: details?.clientPayments,
        } as IUpdateAgentChange
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="clientId"
                label={`${t("client")}`}
                width="large"
                input={{
                  type: "select",
                  loading: clientsLoading,
                  allowSearch: true,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryId"
                label={`${t("country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="depositNumber"
                label={`${t("depositNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="officialDate"
                label={`${t("official_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="financialReceiptNumber"
                label={`${t("financialReceiptNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="dateFinancialReceipt"
                label={`${t("dateFinancialReceipt")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>
            {/* ---------------------------------------------------------- */}
            <Col span={8}>
              <FieldBuilder
                name="nameOldAgent"
                label={`${t("nameOldAgent")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="oldAgentAddress"
                label={`${t("oldAgentAddress")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="residenceOldAgent"
                label={`${t("residenceOldAgent")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="nameNewAgent"
                label={`${t("nameNewAgent")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="newAgentAddress"
                label={`${t("newAgentAddress")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="residenceNewAgent"
                label={`${t("residenceNewAgent")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="approvingNewAgentDate"
                label={`${t("approvingNewAgentDate")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="orderValue"
                label={`${t("order_value")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            {/* 
            <Col span={8}>
              <FieldBuilder
                name="clientPayments"
                label={`${t("client_payments")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>*/}
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            {/* ....................... */}
            <Col span={24}>
              <div>
                <FormItem label={t("file")}>
                  <Controller
                    name="file"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          onChange={(response) => {
                            setFile(response);
                            // field.onChange(response?.id);
                          }}
                          fileFor={MediumFor.COPYRIGHT_FILE}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            {file && (
              <Col span={5}>
                <Popover
                  content={<div>{t("click_here_to_download_file")}</div>}
                >
                  <a href={details?.file?.url}>
                    <div className={styles.fileCardContainer}>
                      <Image
                        src={getUrlImageFile(file?.extension)}
                        className={styles?.imgFile}
                        alt={details?.file?.extension}
                      />
                    </div>
                  </a>
                </Popover>
                <FaTrash
                  onClick={() => setFile(null)}
                  className={styles.trash}
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const AgentChageRequestForm = () => {
  return (
    <AgentChangeContextProvider>
      <Form />
    </AgentChangeContextProvider>
  );
};

export default AgentChageRequestForm;
