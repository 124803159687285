import {
  ICreateDrawingRequestFiles,
  IUpdateDrawingRequestFiles,
} from "../../../models/drawing-collection/drawing-requset-files/request";
import {
  IDrawingRequesFiles,
  IDrawingRequesFilesDetails,
} from "../../../models/drawing-collection/drawing-requset-files/response";
import { ITrademarkRequestFilesQuery } from "../../../models/trademark-collection/trademark-requset-files/query";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class DrawingRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDrawingRequsetFiles = (
    query: ITrademarkRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDrawingRequesFiles>>({
      path: `/drawing-request-files`,
      method: "GET",
      query,
      ...params,
    });

  getDrawingRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingRequesFilesDetails>({
      path: `/drawing-request-files/${id}`,
      method: "GET",
      ...params,
    });

  createDrawingRequsetFile = (
    data: ICreateDrawingRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IDrawingRequesFiles>({
      path: "/drawing-request-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateDrawingRequsetFile = (
    id: number,
    data: IUpdateDrawingRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IDrawingRequesFiles>({
      path: `/drawing-request-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDrawingRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingRequesFiles>({
      path: `/drawing-request-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default DrawingRequsetfileService;
