export enum BooleanType {
    Personal = 1,
    Company = 2,
  }
  
  export const getTypesColor = (status: BooleanType) => {
    switch (status) {
      case  BooleanType.Personal:
        return 'blue'
        case BooleanType.Company:
        return 'orange'
      default:
        return 'blue'
    }
  }
  
  // Status Boolean stuts
  export const getBooleanTypes = (status: BooleanType) => {
    switch (status) {
      case  BooleanType.Personal:
        return "personal"
      case BooleanType.Company:
        return "company"
      default:
        return 'personal'
    }
  }