import { Button, Tag, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useModal } from "react-modal-hook";

import CopyrightRequestContext from "../../../../context/copyright-collection/copyright-request/context";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import {
  CopyrighyRequestStatus,
  CopyrighyRequestStatusColor,
  CopyrighyRequestStatusString,
} from "../../../../models/copyright-collection/copyright-request/enum";
import { ICopyrightRequest } from "../../../../models/copyright-collection/copyright-request/response";
import { IMoveTrademrk } from "../../../../models/trademark-collection/move-trademark-request/request";
import EndPoints from "../../../../services/end-points";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import FormHookModal from "../../../general/modals/form-hook-modal";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import MoveBtn from "../../../general/table-components/actions/move-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import MoveCopyrightRequest from "../move-patents/form";
import { IWorkflowLight } from "../../../../models/workflow/response";
import { FilePdfOutlined } from "@ant-design/icons";

const CopyrightRequestsTable: React.FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } = useContext(
    CopyrightRequestContext
  );
  const [infoId, setInfoId] = useState<number>(undefined);
  const [loadingMove, setLoadingMove] = useState(false);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ss"
        confirmButtonText={t("save")}
        loading={loadingMove}
        title={t("move_copyrignt_request_to_another_node")}
        hideModal={() => {
          setInfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "-4rem", maxHeight: "90vh", overflowY: "scroll" }}
        defaultValues={
          {
            officialDate: null,
          } as IMoveTrademrk
        }
        width={"90vw"}
        onSubmit={async (data) => {
          setLoadingMove(true);
          try {
          await EndPoints.copyrightRequest.moveCopyrightReseqestNode(
            infoId,
            data
          );
          setInfoId(undefined);
          actions.getList();
        } catch (err) {
        } finally {
          setLoadingMove(false);
        }
        }}
      >
        {infoId && <MoveCopyrightRequest id={infoId} />}
      </FormHookModal>
    ),
    [details, loadingMove, infoId]
  );

  const columns: ColumnProps<ICopyrightRequest>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 80,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      width: 80,

      align: "center",
      render: (_, record) => record?.depositNumber ?? "-",
    },

    {
      title: t("order_template"),
      dataIndex: "workflow",
      width: 150,
      key: "requestTemplate",
      align: "center",
      // sorter: {
      //   multiple: 2,
      // },
      render: (workflow: IWorkflowLight) => workflow?.name,
    },

    {
      title: t("copyright"),
      dataIndex: "dd",
      key: "d",
      width: 160,
      align: "center",
      render: (_, record) => record?.copyright?.name ?? "-",
    },
    {
      title: t("country"),
      key: "country",
      align: "center",
      render: (_, record) => record?.workflow?.country?.name ?? "-",
    },

    {
      title: t("current_node"),
      dataIndex: "currentNode",
      key: "currentNode",
      width: 180,
      align: "center",
      render: (_, record) => record?.currentNode?.name?.name,
    },
    {
      title: t("request_status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status: CopyrighyRequestStatus) => (
        <Tag color={CopyrighyRequestStatusColor(status)}>
          {t(CopyrighyRequestStatusString(status))}
        </Tag>
      ),
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      key: "officialDate",
      align: "center",
      sorter: {
        multiple: 2,
      },
      render: (officialDate: Date) => moment(officialDate).format("DD/MM/YYYY"),
    },
    {
      title: t("creation_date"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 3,
      },
      align: "center",
      render: (createdAt: Date) => moment(createdAt).format("DD/MM/YYYY"),
    },
    // {
    //   title: t("update_date"),
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    //   sorter: {
    //     multiple: 4,
    //   },
    //   align: "center",
    //   render: (updatedAt: Date) => moment(updatedAt).format("DD/MM/YYYY"),
    // },
    {
      title: t("actions"),
      width: 250,
      align: "center",
      key: "x",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          {/* <Tooltip title={t("export_pdf")}>
            <Button
              type="dashed"
              shape="circle"
              loading={loading.includes("create")}
              onClick={() => actions.exportPdf(record?.id)}
              // onClick={() => download(record.id)}
              icon={<FilePdfOutlined style={{ color: "#ff3f3f" }} />}
            />
          </Tooltip> */}
          <MoveBtn
            onClick={async () => {
              await setInfoId(record.id);
              await showFormModal();
            }}
          />

          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteRequest(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        total={list?.total}
        pageSize={query.perPage}
        scroll={{ x: 1300 }}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default CopyrightRequestsTable;
