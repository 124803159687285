import { useReducer } from "react";
import { INodeQuery } from "../../models/node/query";
import { ICreateNode, IUpdateNode } from "../../models/node/request";
import { INode, INodeDetails, INodeLight } from "../../models/node/response";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import NodeContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const NodeContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.node.getAllNode(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.node.getNode(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: INodeDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createNode = async (request: ICreateNode) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.node.createNode(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateNode = async (id: number, request: IUpdateNode) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.node.updateNode(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteNode = async (id: number, data: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
        await EndPoints.node.deleteNode(id, data);
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setHoverNodeId = (id: number) => {
    dispatch({ type: "SET_HOVER_NODE_ID", payload: { hoverNodeId: id } });
  };
  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: INodeQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <NodeContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          setHoverNodeId,

          createNode,
          deleteNode,
          updateNode,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </NodeContext.Provider>
  );
};

export default NodeContextProvider;
