import { Button, Divider } from 'antd'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { execute } from '../../../utils/helpers/execute'
import styles from './style.module.scss'

interface IProps<T> {
  children?: React.ReactNode
  title: string | React.ReactNode
}

function DetailsPage<T>(props: IProps<T>) {

  const { children, title } = props



  const navigate = useNavigate()
  const { t } = useTranslation()
 
 

  return (
   <div  className={styles.container} >
        {/* Header */}
        <div>
          <span className={styles.title}>
            {title}
          </span>
          <Divider className={styles.divider} />
          <div className={styles.children}>
            {children}
          </div>
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button type='primary' onClick={() => { navigate(-1) }}>{t('ok')}</Button>
          </div>
        </div>
   </div>
  )
}

export default DetailsPage
