import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import PageDetails from "../../../general/details";
import moment from "moment";
import ObjectionRequestContextProvider from "../../../../context/independent_requests-collection/objection-request/provider";
import ObjectionRequestContext from "../../../../context/independent_requests-collection/objection-request/context";
import PaymentRequestTable from "../../clients-payments/client-payment-table";
import PaymentRequestContextProvider from "../../../../context/independent_requests-collection/request-payments/provider";
import CostRequesTable from "../../office-cost/office-cost-table";
import CostRequestContextProvider from "../../../../context/independent_requests-collection/request-costs/provider";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
} from "../../../../models/workflow/enms-workflow/all-enums";
import ObjectionTimeLine from "../time-line";

interface Props {}
const ObjectionRequestDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { id } = useParams();
  const { details, actions, loading } = useContext(ObjectionRequestContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && actions.getDetails(Number(id));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"objection_request"}`}
        buttonName={`${"update_objection_request"}`}
        onClick={() => navigate(`/objection-request/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("details_objection_request")} key="1">
                    <Descriptions size="small" column={1} bordered>
                      <AntdDescriptions.Item label={t("id")}>
                        {details?.id}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.depositNumber}
                    </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client_objecting")}>
                        {details?.clients?.length > 0
                          ? details?.clients?.map((client, index) => {
                              return <Tag key={index} title={client?.name} />;
                            })
                          : "-"}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("objecting_party")}>
                        {details?.objectingParty}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("objecting_request")}>
                        {details?.objectingRequest}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("subject")}>
                        {details?.subject}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("description")}>
                        {details?.description ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: details?.description,
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("order_value")}>
                        {details?.orderValue}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client_payments")}>
                        {details?.sumPayments}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("sum_costs")}>
                        {details?.sumCosts}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item
                        label={t("trademark_orders_templates")}
                      >
                        {details?.workflow?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("type_oreder_template")}>
                        {details?.workflow?.type ? (
                          <Tag
                            title={getAllBooleanOrderTemplate(
                              details?.workflow?.type
                            )}
                            color={getAllOrderTemplateColor(
                              details?.workflow?.type
                            )}
                          />
                        ) : (
                          "-"
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("country")}>
                        <Tag title={details?.workflow?.country?.name ?? "-"} />
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("current_node")}>
                        {details?.currentNode?.name?.name ?? (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item
                        label={t("request_submitting_date")}
                      >
                        {moment(details?.officialDate).format("DD/MM/YYYY")}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("created_at")}>
                        {moment(details?.createdAt).format("DD/MM/YYYY")}
                      </AntdDescriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={8}>
                <Collapse>
                  <Panel header={t("time_line_request")} key={1}>
                    <ObjectionTimeLine />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={16}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("client_payments")} key={1}>
                        <PaymentRequestTable requestType="App\Models\ObjectionRequest" />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("office_cost")} key={1}>
                        <CostRequesTable requestType="App\Models\ObjectionRequest" />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </PageDetails>
    </>
  );
};

const ObjectionRequestDetailsPage = () => {
  return (
    <ObjectionRequestContextProvider>
      <PaymentRequestContextProvider>
        <CostRequestContextProvider>
          <ObjectionRequestDetails />
        </CostRequestContextProvider>
      </PaymentRequestContextProvider>
    </ObjectionRequestContextProvider>
  );
};
export default ObjectionRequestDetailsPage;
