import { Row, Col } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import TraderMarkOrderTeamplateContext from "../../../context/workflow/context";
import TraderMarkOrderTeamplateContextProvider from "../../../context/workflow/provider";

import { IUpdateTraderMarkOrderTeamplate } from "../../../models/workflow/request";
import { ICountry } from "../../../models/managent-collection/user/response";
import EndPoints from "../../../services/end-points";
import { languages } from "../../../utils/helpers/constants";
import { getTranslations } from "../../../utils/helpers/translations";
import FieldBuilder from "../../form-components/field-builder";
import MainForm from "../../form-components/main-form";
import Spin from "../../general/antd/spin";
import { getBooleanTrademarkOrderTemplate } from "../../../models/workflow/enms-workflow/trademark";
import { getForStatus } from "../../../models/workflow/enms-workflow/all-enums";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(
    TraderMarkOrderTeamplateContext
  );
  // const [users, setUsers] = useState<IUser[]>([])
  // const [usersLoading, setUsersLoading] = useState(true)
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [contryLoading, setCountryLoading] = useState(true);

  const { id } = useParams();
  const types = [1, 2, 3, 4];
  const fors = [1, 2, 3, 4];

  // get details depends On id (getting from url)
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  // get all Role from api
  useEffect(() => {
    const getContrieslight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountryLoading(false);
      } catch (error) {
      } finally {
        setCountryLoading(false);
      }
    };
    getContrieslight();
  }, []);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateTraderMarkOrderTeamplate(details?.id, data)
          : await actions.createTraderMarkOrderTeamplate(data);
      }}
      title={
        details
          ? t("update_trademark_orders_templates")
          : t("add_trademark_orders_templates")
      }
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          countryId: details?.country?.id,
          extraWordsCount: details?.extraWordsCount,
          extraWordsPrice: details?.extraWordsPrice,
          mainPrice: details?.mainPrice,
          mainWordsCount: details?.mainWordsCount,
          officeCost: details?.officeCost,
          type: details?.type,
          for: details?.for,
          // rootNodeId: null,
        } as IUpdateTraderMarkOrderTeamplate
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <Row gutter={[16, 8]}>
          {languages?.map((lang, index) => {
            return (
              <Col span={8}>
                <FieldBuilder
                  key={index}
                  name={`name[${lang?.code}]`}
                  label={`${t("name")} (${lang?.name})`}
                  input={{ type: "text" }}
                  rules={{ required: true }}
                />
              </Col>
            );
          })}
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: types.map((type, index) => {
                  return {
                    label: t(getBooleanTrademarkOrderTemplate(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              name="for"
              label={`${t("for")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: fors.map((type, index) => {
                  return {
                    label: t(getForStatus(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
          <Col span={8}>
            <div>
              <FieldBuilder
                name="countryId"
                label={`${t("country_order_template")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: contryLoading,
                  allowSearch: true,
                  options: countries.map((contry, index) => {
                    return {
                      label: contry.name as string,
                      value: contry.id as number,
                    };
                  }),
                }}
              />
            </div>
          </Col>
          <Col span={8}>
            <FieldBuilder
              rules={{ required: false }}
              name="mainPrice"
              label={`${t("main_price_customer")}`}
              input={{ type: "number" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              rules={{ required: false }}
              name="mainWordsCount"
              label={`${t("main_words_count")}`}
              input={{ type: "number" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              rules={{ required: false }}
              name="extraWordsCount"
              label={`${t("extra_words_count")}`}
              input={{ type: "number" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              rules={{ required: false }}
              name="extraWordsPrice"
              label={`${t("extra_words_price")}`}
              input={{ type: "number" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              rules={{ required: false }}
              name="officeCost"
              label={`${t("office_cost")}`}
              input={{ type: "number" }}
            />
          </Col>
        </Row>
      )}
    </MainForm>
  );
};

const TraderMarkOrderTeamplateForm = () => {
  return (
    <TraderMarkOrderTeamplateContextProvider>
      <Form />
    </TraderMarkOrderTeamplateContextProvider>
  );
};
export default TraderMarkOrderTeamplateForm;
