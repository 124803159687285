import { ITranslationRequest } from "../base/translation";
export interface ICreateClient {
  fatherName?: string;
  motherName?: string;
  nationalNumber?: string;
  countryOfResidence?: ITranslationRequest;
  cityOfResidence?: ITranslationRequest;
  name?: ITranslationRequest;
  responsiblePersonName?: string;
  address?: string;
  email: string;
  landline?: string;
  mobile: string;
  note?: string;
  //commercialRrecord?: string;
  authorization?: string;
  telegramId:number;
  // To do
 // commercialRecordId?: number;
  authorizationId?: number;
  type?: number;
  identityOfNumber?: string;
  honesty?: string;
  dateOfBirth?: string;
  dateOfIdentity?: string;
  legalForm?: string;
  nationalityId: number;
}

export interface IUpdateClient extends ICreateClient {}

export interface IExportClinetExcel {
  name: boolean;
  responsiblePersonName: boolean;
  job: boolean;
  mobile: boolean;
  type: boolean;
  id: boolean;
}

export const ClinetsColumns = [
  "id",
  "name",
  "responsiblePersonName",
  "job",
  "mobile",
  "type",
];
