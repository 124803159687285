import axios from "axios";
import { IRoleQuery } from "../../../models/managent-collection/role/query";
import {
  ICreateRole,
  IUpdateRole,
} from "../../../models/managent-collection/role/request";
import { IRole } from "../../../models/managent-collection/role/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { IExportRoleExcel } from "../../../models/managent-collection/role/request";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";

class RoleService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllroles = (query: IRoleQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IRole>>({
      path: `/roles`,
      method: "GET",
      query,
      ...params,
    });

  getRole = (id: number, params?: RequestParams) =>
    this.http.request<IRole>({
      path: `/roles/${id}`,
      method: "GET",
      ...params,
    });

  createRole = (data: ICreateRole, params?: RequestParams) =>
    this.http.request<IRole>({
      path: "/roles",
      method: "POST",
      body: data,
      ...params,
    });

  updateRole = (id: number, data: IUpdateRole, params?: RequestParams) =>
    this.http.request<IRole>({
      path: `/roles/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteRole = (id: number, params?: RequestParams) =>
    this.http.request<IRole>({
      path: `/roles/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportRoleExcel) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/roles/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },

      responseType: "arraybuffer",
    });
}

export default RoleService;
