import { IIncidentsClientsQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/query";
import {
  IIncidentsClients,
  IIncidentsClientsDetails,
} from "../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/response";
import { IIncidentsWaiverQuery } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/query";
import {
  IIncidentsWaiver,
  IIncidentsWaiverDetails,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../../base/base-reducer";
import { IInternalState, incidentsWaiverLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: incidentsWaiverLoading | incidentsWaiverLoading[] };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IIncidentsWaiver> };
    }
  | { type: "SET_DETAILS"; payload: { details?: IIncidentsWaiverDetails } }
  | QueryAction<IIncidentsWaiverQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }

    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
