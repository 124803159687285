export enum types {
  CROSS_OUT_TRADEMARK = 1,
  IMITATION = 2,
  COMMEND_OWNERSHIP = 3,
}

export const getLawsuitsTypeColor = (status: types) => {
  switch (status) {
    case types.COMMEND_OWNERSHIP:
      return "blue";
    case types.CROSS_OUT_TRADEMARK:
      return "red";
    case types.IMITATION:
      return "green";
  }
};

// Status Boolean stuts
export const getLawsuitsType = (status: types) => {
  switch (status) {
    case types.COMMEND_OWNERSHIP:
      return "COMMEND_OWNERSHIP";
    case types.CROSS_OUT_TRADEMARK:
      return "CROSS_OUT_TRADEMARK";
    case types.IMITATION:
      return "IMITATION";
  }
};

// claim types
export enum ClaimType {
  PLAINTIFF = 1,
  RESPONDENT = 2,
}
export const getClaimColor = (status: ClaimType) => {
  switch (status) {
    case ClaimType.PLAINTIFF:
      return "blue";
    case ClaimType.RESPONDENT:
      return "green";
  }
};
export const getClaim = (status: ClaimType) => {
  switch (status) {
    case ClaimType.PLAINTIFF:
      return "PLAINTIFF";
    case ClaimType.RESPONDENT:
      return "RESPONDENT";
  }
};
