import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";

import { ICertifiedCopyQuery } from "../../../models/independent-requests-collection/certified-copy/query";
import {
  ICreateCertifiedCopy,
  IUpdateCertifiedCopy,
} from "../../../models/independent-requests-collection/certified-copy/request";
import {
  ICertifiedCopy,
  ICertifiedCopyDetails,
} from "../../../models/independent-requests-collection/certified-copy/response";

export type CertifiedCopyLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CertifiedCopyLoading[];

  list?: IBaseListingResponse<ICertifiedCopy>;
  query: ICertifiedCopyQuery;

  details?: ICertifiedCopyDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICertifiedCopyDetails) => void;

    createCertifiedCopy: (request: ICreateCertifiedCopy) => void;
    updateCertifiedCopy: (id: number, request: IUpdateCertifiedCopy) => void;
    deleteCertifiedCopy: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ICertifiedCopyQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createCertifiedCopy: DEFAULT_FUNCTION,
    updateCertifiedCopy: DEFAULT_FUNCTION,
    deleteCertifiedCopy: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CertifiedCopyContext = createContext(externalState);

export default CertifiedCopyContext;
