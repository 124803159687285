import { createContext } from "react";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { ICommercialRecords, ICommercialRecordsDetails } from "../../models/commercial-records/response";
import { ICommercialQuery } from "../../models/lawswits-collection/commercial/commercial-requests/query";
import { DEFAULT_QUERY } from "../../utils/helpers/constants";
import { ICreateCommercialRecords, IUpdateCommercialRecords } from "../../models/commercial-records/request";
import { DEFAULT_FUNCTION } from "../../utils/costants";

export type CommercialRecordLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CommercialRecordLoading[];

  list?: IBaseListingResponse<ICommercialRecords>;
  query: ICommercialQuery;
  light?: IBaseListingResponse<ICommercialRecords>;

  details?: ICommercialRecordsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICommercialRecordsDetails) => void;

    createCommercialRecord: (request: ICreateCommercialRecords) => void;
    updateCommercialRecord: (id: number, request: IUpdateCommercialRecords) => void;
    deleteCommercialRecord: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ICommercialQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
   
    createCommercialRecord: DEFAULT_FUNCTION,
    updateCommercialRecord: DEFAULT_FUNCTION,
    deleteCommercialRecord: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CommercialRecordContext = createContext(externalState);

export default CommercialRecordContext;
