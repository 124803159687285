import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import { IRequestCost, IRequestCostDetails } from "../../../models/independent-requests-collection/request-cost/response";
import { IRequestCostQuery } from "../../../models/independent-requests-collection/request-cost/query";
import { ICreateRequestCost, IUpdateRequestCost } from "../../../models/independent-requests-collection/request-cost/request";

export type CostRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CostRequestLoading[];

  list?: IBaseListingResponse<IRequestCost>;
  query: IRequestCostQuery;

  details?: IRequestCostDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id?: number , type?:string) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IRequestCostDetails) => void;

    createCostRequest: (request: ICreateRequestCost) => void;
    updateCostRequest: (id: number, request: IUpdateRequestCost) => void;
    deleteCostRequest: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: any) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCostRequest: DEFAULT_FUNCTION,
    updateCostRequest: DEFAULT_FUNCTION,
    deleteCostRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CostRequestContext = createContext(externalState);

export default CostRequestContext;
