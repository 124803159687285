import { Tooltip } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../antd/button'

interface Props extends ButtonProps {
    label?: string
  }

const ResevationBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Button
      icon={<PlusOutlined />}
      type='primary'
      title={`${t('add_new_reservation')}`}
      {...props}
    >
      {props.label ? t(props.label) : t('add_new_reservation')}
    </Button>
  )
}

export default ResevationBtn
