import { createContext } from "react";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IIncidentsLicensing,
  IIncidentsLicensingDetails,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/response";
import { IIncidentsLicensingQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/query";
import {
  ICreateIncidentsLicensing,
  IUpdateIncidentsLicensing,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/request";

export type incidentsLicensingLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "word";

export interface IInternalState {
  loading: incidentsLicensingLoading[];

  list?: IBaseListingResponse<IIncidentsLicensing>;
  query: IIncidentsLicensingQuery;

  details?: IIncidentsLicensingDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsLicensingDetails) => void;

    createIncidentsLicensing: (request: ICreateIncidentsLicensing) => void;
    updateIncidentsLicensing: (
      id: number,
      request: IUpdateIncidentsLicensing
    ) => void;
    deleteIncidentsLicensing: (id: number) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    exportWord: (id: number , type:number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsLicensingQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createIncidentsLicensing: DEFAULT_FUNCTION,
    updateIncidentsLicensing: DEFAULT_FUNCTION,
    deleteIncidentsLicensing: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    exportWord: DEFAULT_FUNCTION,
    
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsLicensingsContext = createContext(externalState);

export default IncidentsLicensingsContext;
