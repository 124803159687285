import excel from "../../assets/images/icon-files/office.jpg";
import pdf from "../../assets/images/icon-files/pdf.png";
let extension = ["jpeg", "jpg", "png", "gif", "bmp"];

export const checkType = (type: string) => {
  if (extension.includes(type)) {
    return true;
  }
  return false;
};

export const getUrlImageFile = (type: string) => {
  switch (type) {
    case "docx":
      return excel;
    case "pdf":
      return pdf;

    default:
      return null;
  }
};
