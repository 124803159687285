export enum getAllStatusIncidentClient {
  incidentClient = 5,
}

export const getBooleanIncidentClientTrademarkOrderTemplate = (
  status: getAllStatusIncidentClient
) => {
  switch (status) {
    case getAllStatusIncidentClient.incidentClient:
      return "incident";
  }
};
