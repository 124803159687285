import { ITranslationRequest } from "../base/translation";
export interface ICreateTraderMarkOrderTeamplate {
  name?: ITranslationRequest;
  mainPrice: number;
  mainWordsCount: number;
  extraWordsCount: number;
  extraWordsPrice: number;
  officeCost: number;
  for: number;
  type: number;
  countryId: number;
  rootNodeId?: any;
}

export interface IUpdateTraderMarkOrderTeamplate
  extends ICreateTraderMarkOrderTeamplate {}

export interface ICopyTrademarkOrderTemplate {
  name?: ITranslationRequest;
  type: number;
  countryId: number;
  for: number;
}

export interface IExportTraderMarkOrderTeamplateExcel {
  nameEn: boolean;
  nameAr: boolean;
  Country: boolean;
  type: boolean;
}

export const trademarkOrderTemplateColoumn = [
  "nameEn",
  "nameAr",
  "Country",
  "type",
];
