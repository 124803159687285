import { Col, Descriptions as AntdDescriptions, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../general/antd/descriptions";
import PageDetails from "../../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import CategoryContextProvider from "../../../../context/management-collection/category/provider";
import CategoryContext from "../../../../context/management-collection/category/context";
import { ImCheckmark, ImCross } from "react-icons/im";
import { ICategory } from "../../../../models/managent-collection/category/response";
import { ColumnProps } from "antd/lib/table";
import Table from "../../../general/table-components/table";
import styles from "./style.module.scss";
interface Props {}

const CategoryDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CategoryContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const columns: ColumnProps<ICategory>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: t("is_breif"),
      dataIndex: "isBreif",
      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.isBreif ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("category")}`}
        buttonName={`${t("update_category")}`}
        onClick={() => navigate(`/categories/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Descriptions size="middle" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("name")}>
                  {details?.name}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("number")}>
                  {details?.number ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("note")}>
                  {details?.description ? (
                    details?.description
                  ) : (
                    <Tag title="not_found" color="red" />
                  )}
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} style={{ marginBottom: ".4rem" }}>
              <div className={styles.subCategoryTitle}>
                {t("all_sub_categories")}
              </div>
              <Table<any>
                rowKey="id"
                style={{ height: "100%" }}
                columns={columns}
                size="small"
                dataSource={details?.subCategories ?? []}
                loading={loading.includes("details")}
              />
            </Col>
          </Row>
        )}
      </PageDetails>
    </>
  );
};

const CategoryDetailsPage = () => {
  return (
    <CategoryContextProvider>
      <CategoryDetails />
    </CategoryContextProvider>
  );
};
export default CategoryDetailsPage;
