import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AgentChangeContext from "../../../../context/independent_requests-collection/agent-change/context";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import PageDetails from "../../../general/details";
import AgentChangeContextProvider from "../../../../context/independent_requests-collection/agent-change/provider";
import { getUrlImageFile } from "../../../../utils/helpers/check-type-file";
import Image from "../../../general/antd/image";
import styles from "./styles.module.scss";
import PaymentRequestTable from "../../clients-payments/client-payment-table";
import PaymentRequestContextProvider from "../../../../context/independent_requests-collection/request-payments/provider";
import CostRequesTable from "../../office-cost/office-cost-table";
import CostRequestContextProvider from "../../../../context/independent_requests-collection/request-costs/provider";

interface Props {}
const AgentChangeRequestDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(AgentChangeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"agent_change_request"}`}
        buttonName={`${"update_agent_change_request"}`}
        onClick={() => navigate(`/agent-change-request/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("details_agent_change_request")} key="1">
                    <Descriptions size="small" column={1} bordered>
                      <AntdDescriptions.Item label={t("id")}>
                        {details?.id}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client")}>
                        {details?.client?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("country")}>
                        {details?.country?.name}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("nameOldAgent")}>
                        {details?.nameOldAgent ? details?.nameOldAgent : "-"}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("oldAgentAddress")}>
                        {details?.oldAgentAddress
                          ? details?.oldAgentAddress
                          : "-"}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("residenceOldAgent")}>
                        {details?.residenceOldAgent
                          ? details?.residenceOldAgent
                          : "-"}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("nameNewAgent")}>
                        {details?.nameNewAgent ? details?.nameNewAgent : "-"}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("newAgentAddress")}>
                        {details?.newAgentAddress
                          ? details?.newAgentAddress
                          : "-"}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("residenceNewAgent")}>
                        {details?.residenceNewAgent
                          ? details?.residenceNewAgent
                          : "-"}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("approvingNewAgentDate")}>
                        {details?.approvingNewAgentDate
                          ? details?.approvingNewAgentDate
                          : "-"}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("depositNumber")}>
                        {details?.depositNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("official_date")}>
                        {details?.officialDate}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("financialReceiptNumber")}
                      >
                        {details?.financialReceiptNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("dateFinancialReceipt")}>
                        {details?.dateFinancialReceipt}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("order_value")}>
                        {details?.orderValue}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client_payments")}>
                        {details?.sumPayments}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("sum_costs")}>
                        {details?.sumCosts}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("note")}>
                        {details?.note ? (
                          details?.note
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("file")}>
                        {details?.file ? (
                          <a href={details?.file?.url}>
                            <div className={styles.fileCardContainer}>
                              <Image
                                src={getUrlImageFile(details?.file?.extension)}
                                className={styles?.imgFile}
                              />
                            </div>
                          </a>
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("client_payments")} key={1}>
                    <PaymentRequestTable requestType="App\Models\AgentChangeRequest" />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("office_cost")} key={1}>
                    <CostRequesTable requestType="App\Models\AgentChangeRequest" />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </PageDetails>
    </>
  );
};

const AgentChangeRequestDetailsPage = () => {
  return (
    <AgentChangeContextProvider>
      <PaymentRequestContextProvider>
        <CostRequestContextProvider>
          <AgentChangeRequestDetails />
        </CostRequestContextProvider>
      </PaymentRequestContextProvider>
    </AgentChangeContextProvider>
  );
};
export default AgentChangeRequestDetailsPage;
