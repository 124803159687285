export interface ICreatePatentRequest {
  officialDate: string;
  clientCost: number;
  depositNumber: string;
  status: number;
  patentId: number;
  workflowId: number;
  registrationDate: string;
  registrationNumber: string;
  note: string;
}

export interface IUpdatePatentRequest extends ICreatePatentRequest {}

export interface IPatentsRequestExportExcel {
  depositNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  mainCategories: boolean;
  patent: boolean;
  clients: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  officialDate: boolean;
  clientCost: boolean;
  fine: boolean;
  firstPublishDate: boolean;
  firstPublishNumber: boolean;
  secondPublishDate: boolean;
  secondPublishNumber: boolean;
  officeCosts: boolean;
  clientPayment: boolean;
  totalCost: boolean;
}

export const namesOfColumnsPatentsRequest = [
  "depositNumber",
  "Country",
  "typeWorkflow",
  "mainCategories",
  "patent",
  "clients",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "officialDate",
  "clientCost",
  "fine",
  "firstPublishDate",
  "firstPublishNumber",
  "secondPublishDate",
  "secondPublishNumber",
  "officeCosts",
  "clientPayment",
  "totalCost",
];
