import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import JopTitleTable from "../../../components/managment-collection/jop-title/jop-title-table";
import AppContext from "../../../context/app/context";
import JopTitleContext from "../../../context/management-collection/jop-title/context";
import JopTitleContextProvider from "../../../context/management-collection/jop-title/provider";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../../utils/hooks/is-mount";

interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const JopTile: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();



  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(JopTitleContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);


  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("jop_title")}
        subTitle={t("jop_title_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/job_titles/create`);
                // actions.setDetails(undefined)
                // setInfoId(undefined)
                // showFormModal()
              }}
              icon={<PlusOutlined />}
            >
              {t("add_job_title")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="jop-title-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          // filterMethods.reset({ status: undefined, jobId: null })
          // actions.setParams({
          //   page: params?.page,
          //   pageSize: params?.pageSize,
          // })
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        {/* <FormProvider {...filterMethods}>
          <UserFilter />
        </FormProvider> */}
      </SearchFilterCard>

      {/* <Outlet /> */}
      <JopTitleTable />
    </div>
  );
};

const JopTitlePage = () => {
  return (
    // ContextProvider
    <JopTitleContextProvider>
      <JopTile />
    </JopTitleContextProvider>
  );
};
export default JopTitlePage;
