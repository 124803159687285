export interface ICreateWiverRequest {
  officialDate: string;
  dateFinancialReceipt: string;
  depositNumber: string;
  financialReceiptNumber: string;
  details: string;
  address: string;
  legalFormPerson: string;
  note: string;
  type: number;
  drawingRequestId: any;
  trademarkRequestId: any;
  clientFor: number;
  clientId: number;
  countryId: number;
  trademark: string;
  products: string;
  status: number;
  orderValue: number;
  clientPayments: number;
}

export interface IUpdateWiverRequest extends ICreateWiverRequest {}

// to do
export interface IExportWiverRequestExcel {
  depositNumber: boolean;
  country: boolean;
  client: boolean;
  type: boolean;
  clientFor: boolean;
  requestOfficialDate: boolean;
  createdAt: boolean;
  officialDate: boolean;
  dateFinancialReceipt: boolean;
  financialReceiptNumber: boolean;
}

export const WiverRequestCloumms = [
  "depositNumber",
  "country",
  "client",
  "type",
  "clientFor",
  "createdAt",
  "requestOfficialDate",
  "createdAt",
  "officialDate",
  "dateFinancialReceipt",
  "financialReceiptNumber",
];
