import { createContext } from "react";
import { IRoleQuery } from "../../../models/managent-collection/role/query";
import {
  ICreateRole,
  IUpdateRole,
} from "../../../models/managent-collection/role/request";
import {
  IRole,
  IRoleDetails,
} from "../../../models/managent-collection/role/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type RoleLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: RoleLoading[];

  list?: IBaseListingResponse<IRole>;
  query: IRoleQuery;

  details?: IRoleDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IRoleDetails) => void;
    exportExcel: (data: any) => any;

    createRole: (request: ICreateRole) => void;
    updateRole: (id: number, request: IUpdateRole) => void;
    deleteRole: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IRoleQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createRole: DEFAULT_FUNCTION,
    updateRole: DEFAULT_FUNCTION,
    deleteRole: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const RoleContext = createContext(externalState);

export default RoleContext;
