import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

import {
  ICopyrightRequest,
  ICopyrightRequestDetails,
} from "../../../models/copyright-collection/copyright-request/response";
import { ICopyrightRequestQuery } from "../../../models/copyright-collection/copyright-request/query";
import {
  ICreateCopyrightRequest,
  IUpdateCopyrightRequest,
} from "../../../models/copyright-collection/copyright-request/request";

export type CopyrightRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CopyrightRequestLoading[];

  list?: IBaseListingResponse<ICopyrightRequest>;
  query: ICopyrightRequestQuery;

  details?: ICopyrightRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICopyrightRequestDetails) => void;

    createCopyrightsRequest: (request: ICreateCopyrightRequest) => void;
    updateCopyrightsRequest: (
      id: number,
      request: IUpdateCopyrightRequest
    ) => void;
    deleteRequest: (id: number) => void;

    addReservationToCopyrights: (id: number, request: any) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: ICopyrightRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCopyrightsRequest: DEFAULT_FUNCTION,
    updateCopyrightsRequest: DEFAULT_FUNCTION,
    deleteRequest: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    addReservationToCopyrights: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CopyrightRequestContext = createContext(externalState);

export default CopyrightRequestContext;
