import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import UserContext from '../../../contexts/user/context'
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import TraderMarkOrderTeamplateContext from "../../../context/workflow/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";

import { ITraderMarkOrderTeamplate } from "../../../models/workflow/response";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import FormHookModal from "../../general/modals/form-hook-modal";
import CopyButton from "../../general/table-components/actions/copy-button";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import TreeBtn from "../../general/table-components/actions/tree-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import CopyTrademarkTeamplate from "../copy-trademark-order-template/form";
import { getTranslations } from "../../../utils/helpers/translations";
import { IUpdateTraderMarkOrderTeamplate } from "../../../models/workflow/request";
import CreateWorkFlowForm from "../workflow-form";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
  getForColor,
  getForStatus,
} from "../../../models/workflow/enms-workflow/all-enums";
interface IProps {}

const TraderMarkOrderTeamplateTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query, details } = useContext(
    TraderMarkOrderTeamplateContext
  );
  const [infoId, setinfoId] = useState(undefined);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ass"
        confirmButtonText={t("save")}
        loading={loading.includes("create")}
        title={t("copy_trademark_order_template")}
        hideModal={() => {
          setinfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "3rem" }}
        defaultValues={{}}
        width={"60vw"}
        onSubmit={async (data) => {
          await actions.CopyTraderMarkOrderTeamplate(infoId, data);
          eventManager.emit(EVENT_SUCCESS);
          setinfoId(undefined);
          actions.setDetails(undefined);
          actions.getData();
        }}
      >
        <CopyTrademarkTeamplate infoId={infoId} />
      </FormHookModal>
    ),
    [infoId, loading]
  );
  const [showWorkFlowFormModal, hideWorkFlowFormodal] = useModal(
    () => (
      <FormHookModal
        id="as"
        confirmButtonText={t("save")}
        loading={loading.includes("create") || loading.includes("details")}
        title={
          details
            ? t("update_trademark_orders_templates")
            : t("add_trademark_orders_templates")
        }
        hideModal={() => {
          setinfoId(undefined);
          actions.setDetails(undefined);
          hideWorkFlowFormodal();
        }}
        style={{ marginTop: "-2rem", height: "10vh" }}
        defaultValues={
          {
            name: getTranslations(details?.translations?.name),
            countryId: details?.country?.id,
            extraWordsCount: details?.extraWordsCount,
            extraWordsPrice: details?.extraWordsPrice,
            mainPrice: details?.mainPrice,
            mainWordsCount: details?.mainWordsCount,
            officeCost: details?.officeCost,
            type: details?.type,
            for: details?.for,
            // rootNodeId: null,
          } as IUpdateTraderMarkOrderTeamplate
        }
        width={"75vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updateTraderMarkOrderTeamplate(details?.id, data)
            : await actions.createTraderMarkOrderTeamplate(data);

          setinfoId(undefined);
          actions.setDetails(undefined);
        }}
      >
        <CreateWorkFlowForm />
      </FormHookModal>
    ),
    [details, infoId, loading]
  );
  const columns: ColumnProps<ITraderMarkOrderTeamplate>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t("name_ar"),
      dataIndex: "name",
      align: "center",
      sorter: {
        multiple: 2,
      },
      key: "name",
      render: (_, record) => {
        return <div>{record.translations?.name[0]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("name_en"),
      dataIndex: "name",
      align: "center",
      sorter: {
        multiple: 3,
      },
      key: "name",
      render: (_, record) => {
        return <div>{record.translations?.name[1]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("for"),
      dataIndex: "for",
      align: "center",
      key: "for",
      render: (record) => {
        return (
          <Tag
            title={getForStatus(Number(record))}
            color={getForColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("type_order_template"),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (record) => {
        return (
          <Tag
            title={getAllBooleanOrderTemplate(Number(record))}
            color={getAllOrderTemplateColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("country_order_template"),
      dataIndex: "country",
      align: "center",
      key: "country",
      render: (_, record) => {
        return <div>{record.country?.name}</div>;
      },
    },
    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <TreeBtn
            onClick={() => {
              navigate(`tree/${record?.id}`);
            }}
          />
          <CopyButton
            onClick={async () => {
              await setinfoId(record.id);
              await showFormModal();
            }}
          />
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />

          <EditBtn
            onClick={async () => {
              setinfoId(record.id);
              showWorkFlowFormModal();
              // navigate(`update/${record?.id}`);
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteTraderMarkOrderTeamplate(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        size="small"
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default TraderMarkOrderTeamplateTable;
