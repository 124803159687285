import { createContext } from "react";

import { IPatentsQuery } from "../../../models/patents-collection/patents/query";
import {
  ICreatePatents,
  IUpdatePatents,
} from "../../../models/patents-collection/patents/request";
import {
  IPatents,
  IPatentsDetails,
} from "../../../models/patents-collection/patents/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";

export type PatentsLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: PatentsLoading[];

  list?: IBaseListingResponse<IPatents>;
  query: IPatentsQuery;

  details?: IPatentsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IPatentsDetails) => void;

    createPatents: (request: ICreatePatents) => void;
    updatePatents: (id: number, request: IUpdatePatents) => void;
    deletePatents: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IPatentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createPatents: DEFAULT_FUNCTION,
    updatePatents: DEFAULT_FUNCTION,
    deletePatents: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PatentsContext = createContext(externalState);

export default PatentsContext;
