export interface ICreateIncidentsClients {
  officialDate: string;
  clientCost: number;
  depositNumber: string;
  description: string;
  address: string;
  changeCompany: boolean;
  changeAddress: boolean;
  changeName: boolean;
  incident: string[];
  status: number;
  workflowId: number;
  applicantClient: number;
  newClient: number;
  oldClient: number;
  subject?: string;
  secondDescription?: string;
}

export interface IUpdateIncidentsClients extends ICreateIncidentsClients {
  country: number;
}

// to do
export interface IExportIncidentsClientsExcel {
  depositNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  officialDate: boolean;
  clientCost: boolean;
  applicantClient: boolean;
  newClient: boolean;
  oldClient: boolean;
  officeCosts: boolean;
  clientPayment: boolean;
}

export const IncidentsClientsCloumms = [
  "depositNumber",
  "Country",
  "typeWorkflow",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "officialDate",
  "clientCost",
  "applicantClient",
  "newClient",
  "oldClient",
  "officeCosts",
  "clientPayment",
];
