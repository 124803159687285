import { ContentType } from "../../../utils/api/http-client";
import axios from "axios";
import { ITrademarkRequestQuery } from "../../../models/trademark-collection/trademark-request/query";
import {
  ICreateTrademarkRequest,
  ITrademarkRequestExportExcel,
} from "../../../models/trademark-collection/trademark-request/request";
import {
  INodeCanMove,
  ITrademarkRequest,
  ITrademarkRequestDetails,
} from "../../../models/trademark-collection/trademark-request/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { IUpdateTrademarkRequest } from "../../../models/trademark-collection/trademark-request/request";
import { ITrademarkReservationDetails } from "../../../models/trademark-collection/trademark-reservation/response";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class TrademarkRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRequests = (query: ITrademarkRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ITrademarkRequest>>({
      path: `/trademark-requests`,
      method: "GET",
      query,
      ...params,
    });

  createTradermarkRequests = (
    data: ICreateTrademarkRequest,
    params?: RequestParams
  ) =>
    this.http.request<ICreateTrademarkRequest>({
      path: "/trademark-requests",
      method: "POST",
      body: data,
      ...params,
    });

  deleteRequest = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/trademark-requests/${id}`,
      method: "DELETE",
      ...params,
    });

  updateTreademarkRequest = (
    id: number,
    data: IUpdateTrademarkRequest,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkRequest>({
      path: `/trademark-requests/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getTreademarkRequest = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkRequestDetails>({
      path: `/trademark-requests/${id}`,
      method: "GET",
      ...params,
    });

  getTrademarkReservations = (
    id: number,
    query: ITrademarkRequestQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ITrademarkReservationDetails>>({
      path: `/trademark-requests/get-Reservations/${id}`,
      method: "GET",
      query,
      ...params,
    });

  addTrademarkReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/trademark-requests/add-Reservations/${id}`,
      method: "PUT",
      body: data,
    });

  moveTrademarkReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/trademark-requests/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });

  deleteTrademarkReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `trademark-requests/deattach-Reservations/${id}`,
      method: "DELETE",
      body: data,
    });

  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/trademark-requests/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (
    data: ITrademarkRequestExportExcel,
    query: ITrademarkRequestQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/trademark-requests/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/trademark-requests/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
  exportPdfExternal = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/trademark-requests/export-template-PDF/external/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportDepositTemplatePdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/trademark-requests/deposit/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportDepositTemplatePdfExternal = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/trademark-requests/deposit/external/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportWord = (id: number , type:number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/trademark-requests/export-word/${id}/${type}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportDepositWord = (id: number, type: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/trademark-requests/export-deposit-word/${id}/${type}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
}

export default TrademarkRequestService;
