import { createContext } from "react";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  ICommercialRequesFiles,
  ICommercialRequesFilesDetails,
} from "../../../../models/lawswits-collection/commercial/commercial-requset-files/response";
import { IcommercialRequestFilesQuery } from "../../../../models/lawswits-collection/commercial/commercial-requset-files/query";
import {
  ICreateCommercialRequestFiles,
  IUpdateCommercialRequestFiles,
} from "../../../../models/lawswits-collection/commercial/commercial-requset-files/request";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<ICommercialRequesFiles>;
  query: IcommercialRequestFilesQuery;

  details?: ICommercialRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICommercialRequesFilesDetails) => void;

    createCommercialRequsetFile: (
      request: ICreateCommercialRequestFiles
    ) => void;
    updateCommercialRequsetFile: (
      id: number,
      request: IUpdateCommercialRequestFiles
    ) => void;
    deleteCommercialRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IcommercialRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCommercialRequsetFile: DEFAULT_FUNCTION,
    updateCommercialRequsetFile: DEFAULT_FUNCTION,
    deleteCommercialRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CommercialRequestFilesContext = createContext(externalState);

export default CommercialRequestFilesContext;
