import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import Button, { ButtonProps } from '../../../antd/button'
import { FaSitemap } from 'react-icons/fa'

interface Props extends ButtonProps {}

const TreeBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('edit')}>
      <Button type='primary' shape='circle' icon={<FaSitemap />} {...props} />
    </Tooltip>
  )
}

export default TreeBtn
