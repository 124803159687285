import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../general/antd/descriptions";
import PageDetails from "../../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../../general/antd/spin";
import JopTitleContext from "../../../../context/management-collection/jop-title/context";
import JopTitleContextProvider from "../../../../context/management-collection/jop-title/provider";
import AppContext from "../../../../context/app/context";

interface Props {}

const JopTitle: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(JopTitleContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"jop_title"}`}
        buttonName={`${"edite_jop_title"}`}
        onClick={() => navigate(`/job_titles/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="default" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const JopTitleDetailesPage = () => {
  return (
    <JopTitleContextProvider>
      <JopTitle />
    </JopTitleContextProvider>
  );
};
export default JopTitleDetailesPage;
