import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../../../context/management-collection/user/context";
import { INITIAL_PAGE } from "../../../../utils/helpers/constants";
import Form from "../../../form-components/from";
import FieldBuilder from "../../../form-components/field-builder";
import { getBooleanStatus } from "../../../../models/managent-collection/user/enum";
import EndPoints from "../../../../services/end-points";
import { IJopTitle } from "../../../../models/managent-collection/jop-title/response";
import AppContext from "../../../../context/app/context";
import { IRole } from "../../../../models/managent-collection/role/response";
import { INationality } from "../../../../models/managent-collection/nationality/response";

interface IProps {}

const UserFilters: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(UserContext);
  const { direction } = useContext(AppContext);
  const [jopTitles, setJopTitles] = useState<IJopTitle[]>([]);
  const [jopTitleLoading, setJopTitleLoading] = useState(true);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesLoading, setRolesLoading] = useState(true);

  const statuses = [1, 2];

  // get all jopTitle from api
  useEffect(() => {
    const getJopTitles = async () => {
      try {
        const { data } = await EndPoints.jopTitle.getAllJopTitles({});
        setJopTitles(data.data);
        setJopTitleLoading(false);
      } catch (error) {
      } finally {
        setJopTitleLoading(false);
      }
    };
    getJopTitles();
  }, [direction]);

  // get all Role from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.role.getAllroles({});
        setRoles(data.data);
        setRolesLoading(false);
      } catch (error) {
      } finally {
        setRolesLoading(false);
      }
    };
    getRoles();
  }, [direction]);

  return (
    <Form
      formId="user-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.jobTitle
              ? {
                  name: "jobTitle",
                  value: data.jobTitle,
                  operation: "eq",
                }
              : undefined,

            data?.roles
              ? {
                  name: "role",
                  value: data.roles,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("status")}`}
            width="large"
            input={{
              type: "select",
              options: statuses.map((status, index) => {
                return {
                  label: t(getBooleanStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="jobTitle"
            label={`${t("job_title")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: jopTitleLoading,
              options: jopTitles.map((jop, index) => {
                return {
                  label: jop.name,
                  value: jop.name,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="roles"
            label={`${t("roles")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: rolesLoading,
              options: roles?.map((role, index) => {
                return {
                  label: role.name,
                  value: role.name,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default UserFilters;
