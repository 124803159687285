import { createContext } from "react";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  ICommercial,
  ICommercialDetails,
} from "../../../../models/lawswits-collection/commercial/commercial-requests/response";
import { ICommercialQuery } from "../../../../models/lawswits-collection/commercial/commercial-requests/query";
import {
  ICreateCommercial,
  IUpdateCommercial,
} from "../../../../models/lawswits-collection/commercial/commercial-requests/request";

export type CommercialLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CommercialLoading[];

  list?: IBaseListingResponse<ICommercial>;
  query: ICommercialQuery;

  details?: ICommercialDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICommercialDetails) => void;

    createCommercial: (request: ICreateCommercial) => void;
    updateCommercial: (id: number, request: IUpdateCommercial) => void;
    deleteCommercial: (id: number) => void;

    exportExcel: (data: any) => any;
    // exportPdf: (id: number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: ICommercialQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCommercial: DEFAULT_FUNCTION,
    updateCommercial: DEFAULT_FUNCTION,
    deleteCommercial: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    // exportPdf: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CommercialsContext = createContext(externalState);

export default CommercialsContext;
