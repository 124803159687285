import { Row, Col, Divider, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import JopTitleContext from "../../../../context/management-collection/jop-title/context";
import JopTitleContextProvider from "../../../../context/management-collection/jop-title/provider";
import UserContext from "../../../../context/management-collection/user/context";
import UserContextProvider from "../../../../context/management-collection/user/provider";
import { ICountry } from "../../../../models/managent-collection/country/response";
import { IJopTitle } from "../../../../models/managent-collection/jop-title/response";
import { IRole } from "../../../../models/managent-collection/role/response";
import { getBooleanStatus } from "../../../../models/managent-collection/user/enum";
import { IUpdateUser } from "../../../../models/managent-collection/user/request";
import { IUserDetails } from "../../../../models/managent-collection/user/response";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";
import GeneralForm from "../../../form-components/general-form";

const Form = () => {
  const { t } = useTranslation();
  const { actions, loading } = useContext(UserContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [jopTitles, setJopTitles] = useState<IJopTitle[]>([]);
  const [jopTitleLoading, setJopTitleLoading] = useState(true);
  const { id } = useParams();
  const [details, setDetails] = useState<IUserDetails>();
  const [userLoading, setUserLoading] = useState(true);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  // get all jopTitle from api
  useEffect(() => {
    const getJopTitles = async () => {
      try {
        const { data } = await EndPoints.jopTitle.getAllJopTitles({});
        setJopTitles(data.data);
        setJopTitleLoading(false);
      } catch (error) {
      } finally {
        setJopTitleLoading(false);
      }
    };
    getJopTitles();
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      try {
        id &&
          (await EndPoints.user
            .getUser(Number(id))
            .then((res) => {
              setDetails(res.data);
              res.data.allCountries && setIsChecked(res.data.allCountries);
            })
            .then(() => setUserLoading(false)));
      } catch (error) {
      } finally {
        setUserLoading(false);
      }
    };
    getDetails();
  }, []);

  // get all Role from api
  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.role.getAllroles({
          perPage: -1,
        });
        setRoles(data.data);
        setRolesLoading(false);
      } catch (error) {
      } finally {
        setRolesLoading(false);
      }
    };
    getRoles();
  }, []);

  const statuses = [1, 2];
  const [isChecked, setIsChecked] = useState<boolean>(details?.allCountries);

  return (
    <GeneralForm
      values={[
        {
          key: "allCountries",
          value: isChecked ?? false,
        },
      ]}
      onSubmit={async (data) => {
        details
          ? await actions.updateUser(details?.id, data)
          : await actions.createUser(data);
      }}
      title={details ? t("update_user") : t("create_user")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          email: details?.email,
          gsm: details?.gsm,
          note: details?.note,
          status: details?.status,
          address: getTranslations(details?.translations?.address),
          landline: details?.landline,
          allCountries: isChecked ?? false,
          jobTitleId: details?.jobTitle?.id,
          roles: details?.roles?.map((role) => role?.name),
          countries: details?.countries?.map((country) => country.id),
          telegramId: details?.telegramId,
        } as IUpdateUser
      }
    >
      {userLoading ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={`${t("status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: statuses.map((status, index) => {
                    return {
                      label: t(getBooleanStatus(status) as string),
                      value: status as number,
                    };
                  }),
                }}
              />
            </Col>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`address[${lang?.code}]`}
                    label={`${t("address")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="email"
                label={`${t("email")}`}
                input={{ type: "email" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                rules={{ required: details ? false : true, minLength: 8 }}
                name="password"
                label="password"
                input={{ type: "password" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="landline"
                label={`${t("landline")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="gsm"
                label={`${t("gsm")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="telegramId"
                label={`${t("telegram")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="countries"
                label={`${t("countries")}`}
                width="large"
                disabled={isChecked ? true : false}
                rules={{ required: isChecked ? false : true }}
                input={{
                  type: "multiSelect",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="jobTitleId"
                label={`${t("job_title")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: jopTitleLoading,
                  options: jopTitles.map((jop, index) => {
                    return {
                      label: jop.name,
                      value: jop.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="roles"
                label={`${t("roles")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "multiSelect",
                  loading: rolesLoading,
                  options: roles?.map((jop, index) => {
                    return {
                      label: jop.name,
                      value: jop.name,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <Controller
                name="allCountries"
                control={control}
                rules={{ required: false }}
                render={({ field: { ...field } }) => {
                  return (
                    <>
                      <Checkbox
                        {...field}
                        checked={isChecked}
                        value={isChecked}
                        onChange={(e: CheckboxChangeEvent) => {
                          setIsChecked(e.target.checked);
                        }}
                        className="shadow"
                      >
                        {t("all_countries")}
                      </Checkbox>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const UserForm = () => {
  return (
    <UserContextProvider>
      <Form />
    </UserContextProvider>
  );
};
export default UserForm;
