import { IDrawingReservationQuery } from "../../../models/drawing-collection/drawing-reservation/query";
import {
  IDrawingReservation,
  IDrawingReservationDetails,
  IDrawingReservationHistory,
} from "../../../models/drawing-collection/drawing-reservation/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { IInternalState, DrawingReservationLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: {
        loading: DrawingReservationLoading | DrawingReservationLoading[];
      };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IDrawingReservation> };
    }
  | {
      type: "SET_DETAILS";
      payload: { details?: IDrawingReservationDetails };
    }
  | {
      type: "SET_HISTORY";
      payload: { history: IBaseListingResponse<IDrawingReservationHistory> };
    }
  | QueryAction<IDrawingReservationQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }

    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    case "SET_HISTORY": {
      return {
        ...state,
        history: action.payload.history,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
