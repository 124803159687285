import { Descriptions as AntdDescriptions, Col, Row } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../../general/antd/descriptions";

import moment from "moment";
import OfficeCostContext from "../../../../../context/trademark-collection/office-cost/context";
import Spin from "../../../../general/antd/spin";
interface IProps {}
const OfficeCostDetails: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { details, loading } = useContext(OfficeCostContext);

  return (
    <>
      {loading.includes("details") ? (
        <div
          style={{
            height: "15rem",
            justifyContent: "center",
            display: "flex",
            alignContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Descriptions size="small" column={1} bordered>
          <AntdDescriptions.Item label={t("id")}>
            {details?.id}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("value")}>
            {details?.value}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("receipt_number")}>
            {details?.receiptNumber}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("cost_type")}>
            {details?.costType?.name}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("date_payment")}>
            {moment(details?.date).format("DD/MM/YYYY")}
          </AntdDescriptions.Item>

          <AntdDescriptions.Item label={t("note")}>
            {details?.note ?? "-"}
          </AntdDescriptions.Item>
        </Descriptions>
      )}
    </>
  );
};
export default OfficeCostDetails;
