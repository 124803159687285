import axios from "axios";
import { INodeCanMove } from "../../../../models/trademark-collection/trademark-request/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../../utils/costants";
import { LANGUAGE_CODE } from "../../../../utils/helpers/constants";
import { IGrievanceQuery } from "../../../../models/lawswits-collection/grievance/grievance-requests/query";
import {
  IGrievance,
  IGrievanceDetails,
} from "../../../../models/lawswits-collection/grievance/grievance-requests/response";
import {
  ICreateGrievance,
  IExportGrievanceExcel,
  IUpdateGrievance,
} from "../../../../models/lawswits-collection/grievance/grievance-requests/request";

class GrievanceService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllGrievance = (query: IGrievanceQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IGrievance>>({
      path: "/grievance-lawsuits",
      method: "GET",
      query,
      ...params,
    });

  createGrievance = (data: ICreateGrievance, params?: RequestParams) =>
    this.http.request<ICreateGrievance>({
      path: "/grievance-lawsuits",
      method: "POST",
      body: data,
      ...params,
    });

  deleteGrievance = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/grievance-lawsuits/${id}`,
      method: "DELETE",
      ...params,
    });

  updateGrievance = (
    id: number,
    data: IUpdateGrievance,
    params?: RequestParams
  ) =>
    this.http.request<IGrievance>({
      path: `/grievance-lawsuits/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getGrievance = (id: number, params?: RequestParams) =>
    this.http.request<IGrievanceDetails>({
      path: `/grievance-lawsuits/${id}`,
      method: "GET",
      ...params,
    });

  moveGrievanceReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/grievance-lawsuits/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });
  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/grievance-lawsuits/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (data: IExportGrievanceExcel, query: IGrievanceQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/grievance-lawsuits/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  // exportPdf = (id: number) =>
  //   axios.get(
  //     `${process.env.REACT_APP_BASE_API_URL}/grievance-lawsuits/export-template-PDF/${id}`,
  //     {
  //       headers: {
  //         authorization: `Bearer ${
  //           localStorage.getItem(ACCESS_TOKEN) ||
  //           sessionStorage.getItem(ACCESS_TOKEN)
  //         }`,
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Headers": "*",
  //         "Access-Control-Allow-Credentials": "true",
  //       },
  //       responseType: "arraybuffer",
  //     }
  //   );
}

export default GrievanceService;
