import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import {
  IDisclosureTrademarkRequest,
  IDisclosureTrademarkRequestDetails,
} from "../../../models/independent-requests-collection/disclosure-trademark-request/response";
import {
  ICreateDisclosureTrademarkRequest,
  IUpdateDisclosureTrademarkRequest,
} from "../../../models/independent-requests-collection/disclosure-trademark-request/request";
import { IDisclosureTrademarkRequestQuery } from "../../../models/independent-requests-collection/disclosure-trademark-request/query";

export type DisclosureTrademarkRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: DisclosureTrademarkRequestLoading[];

  list?: IBaseListingResponse<IDisclosureTrademarkRequest>;
  query: IDisclosureTrademarkRequestQuery;

  details?: IDisclosureTrademarkRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDisclosureTrademarkRequestDetails) => void;

    createDisclosureTrademarkRequest: (
      request: ICreateDisclosureTrademarkRequest
    ) => void;
    updateDisclosureTrademarkRequest: (
      id: number,
      request: IUpdateDisclosureTrademarkRequest
    ) => void;
    deleteDisclosureTrademarkRequest: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDisclosureTrademarkRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createDisclosureTrademarkRequest: DEFAULT_FUNCTION,
    updateDisclosureTrademarkRequest: DEFAULT_FUNCTION,
    deleteDisclosureTrademarkRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DisclosureTrademarkRequestContext = createContext(externalState);

export default DisclosureTrademarkRequestContext;
