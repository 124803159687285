import axios from "axios";
import {
  ICreateCopyright,
  IExportCopyrightToExcel,
  IUpdateCopyright,
} from "../../../models/copyright-collection/copyright/request";
import {
  ICopyright,
  ICopyrightDetails,
} from "../../../models/copyright-collection/copyright/response";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { ICopyrightQuery } from "../../../models/copyright-collection/copyright/query";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class CopyrightService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCopyright = (query: ICopyrightQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICopyright>>({
      path: `/copyrights`,
      method: "GET",
      query,
      ...params,
    });

  getCopyright = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightDetails>({
      path: `/copyrights/${id}`,
      method: "GET",
      ...params,
    });

  createCopyright = (data: ICreateCopyright, params?: RequestParams) =>
    this.http.request<ICopyright>({
      path: "/copyrights",
      method: "POST",
      body: data,
      ...params,
    });

  updateCopyright = (
    id: number,
    data: IUpdateCopyright,
    params?: RequestParams
  ) =>
    this.http.request<ICopyright>({
      path: `/copyrights/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCopyright = (id: number, params?: RequestParams) =>
    this.http.request<ICopyright>({
      path: `/copyrights/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportCopyrightToExcel, query: ICopyrightQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/copyrights/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/copyrights/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
}

export default CopyrightService;
