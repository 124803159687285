import { ICostType } from "../../../models/managent-collection/cost-types/response";
import { ICostTypesQuery } from "../../../models/managent-collection/cost-types/query";
import { ICreateCostType } from "../../../models/managent-collection/cost-types/request";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { IUpdateCostType } from "../../../models/managent-collection/cost-types/request";

class CostTypeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCostTypes = (query: ICostTypesQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICostType>>({
      path: `/cost-types`,
      method: "GET",
      query,
      ...params,
    });

  getCostTypes = (id: number, params?: RequestParams) =>
    this.http.request<ICostType>({
      path: `/cost-types/${id}`,
      method: "GET",
      ...params,
    });

  createCostTypes = (data: ICreateCostType, params?: RequestParams) =>
    this.http.request<ICostType>({
      path: "/cost-types",
      method: "POST",
      body: data,
      ...params,
    });

  updateCostTypes = (
    id: number,
    data: IUpdateCostType,
    params?: RequestParams
  ) =>
    this.http.request<ICostType>({
      path: `/cost-types/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCostTypes = (id: number, params?: RequestParams) =>
    this.http.request<ICostType>({
      path: `/cost-types/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default CostTypeService;
