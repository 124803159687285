import { IExportClinetExcel } from "./../../models/client/request";
import { ACCESS_TOKEN } from "./../../utils/helpers/constants";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { ICreateClient, IUpdateClient } from "../../models/client/request";
import { IClient, IClientDetails } from "../../models/client/response";
import { IBaseQuery } from "../../models/base/base-query";
import axios from "axios";

class ClientService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClients = (query?: IBaseQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IClient>>({
      path: `/clients`,
      method: "GET",
      ...params,
      query,
    });

  getClient = (id: number, params?: RequestParams) =>
    this.http.request<IClientDetails>({
      path: `/clients/${id}`,
      method: "GET",
      ...params,
    });

  createClient = (data: ICreateClient, params?: RequestParams) =>
    this.http.request<IClientDetails>({
      path: "/clients",
      method: "POST",
      body: data,
      ...params,
    });

  updateClient = (id: number, data: IUpdateClient, params?: RequestParams) =>
    this.http.request<IClientDetails>({
      path: `/clients/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClient = (id: number, params?: RequestParams) =>
    this.http.request<IClientDetails>({
      path: `/clients/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportClinetExcel, query: any) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/clients/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
      },
      params: query,
      responseType: "arraybuffer",
    });
  copyClient = (id: number, params?: RequestParams) =>
    this.http.request<IClientDetails>({
      path: `/clients/copy/${id}`,
      method: "GET",
      ...params,
    });
}

export default ClientService;
