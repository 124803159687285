import { ITranslationRequest } from "../../base/translation";
import { IRole } from "./response";
export interface ICreateUser {
  name: ITranslationRequest;
  email: string;
  password?: string;
  address: ITranslationRequest;
  gsm: string;
  status: number;
  note?: string;
  roles: string[];
  allCountries: boolean;
  landline: string;
  countries: number[];
  jobTitleId: number;
  telegramId?: number;
}

export interface IUpdateUser extends ICreateUser {}

export interface IExportUserExcel {
  name: boolean;
  status: boolean;
  job: boolean;
  mobile: boolean;
  role: boolean;
}

export const colomnsExportUserExcel = [
  "name",
  "status",
  "job",
  "mobile",
  "role",
];
