import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import Tag from "../../../../general/antd/tag";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import Table from "../../../../general/table-components/table";
import { ClearOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import AppContext from "../../../../../context/app/context";
import { IRecordTrademark } from "../../../../../models/trademark-collection/trademark-reservation/response";
import { getIRecordType, getIRecordTypeColor } from "../../../../../models/trademark-collection/trademark-reservation/enum";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import IRecordPatentsContext from "../../../../../context/patents-collection/patents-record/context";
import RecordPatentsFilter from "../filter";
import RecordPatentsForm from "../form";
interface IProps {
  idPatentReservation: number;
}
const PatentRecordTabel: React.FC<IProps> = (props) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [Id, setId] = useState<number>();
  const { list, actions, loading, query, details } = useContext(IRecordPatentsContext);

  const { direction } = useContext(AppContext);

  useEffect(() => {
    actions.getData(props.idPatentReservation);
  }, [direction, props.idPatentReservation, query]);


  const columns: ColumnProps<IRecordTrademark>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("description"),
      dataIndex: "description",
      align: "center",
      key: "description",
    },
    {
      title: t("depositNumber"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },
    {
      title: t("type"),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (record) => {
        return (
          <Tag
            title={getIRecordType(Number(record))}
            color={getIRecordTypeColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      align: "center",
      key: "officialDate",
      render: (_, { officialDate }) => {
        return (
          <p>
            {" "}
            {officialDate ? moment(officialDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      align: "center",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return (
          <p>
            {" "}
            {createdAt ? moment(createdAt).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("actions"),
      dataIndex: "",

      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <EditBtn
            onClick={async () => {
              await actions.getDetails(record?.id)
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteRecord(record?.id, props.idPatentReservation);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        style={{ marginTop: "2rem" }}
        loading={
          loading.includes("create") ||
          loading.includes("update")
        }
        title={
          !details
            ? t("add_record")
            : t("update_record")
        }
        hideModal={() => {
          hideFormodal();
        }}
        width={"60vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updateRecord(
              details.id,
              data
            )
            : await actions.createRecord(
              data,
            );
        }}
        defaultValues={
          {
            reservationId: props?.idPatentReservation,
            type: details?.type,
            officialDate: details?.officialDate ? moment(details.officialDate).format("YYYY-MM-DD") : '',
            description: details?.description,
            depositNumber: details?.depositNumber,
          } as any
        }
      >
        <RecordPatentsForm />
      </FormHookModal>
    ),
    [details, props.idPatentReservation]
  );

  return (
    <>
      {" "}
      <Row >
        <Col style={{ width: "calc(100% - 112px)" }}>
          <Button
            icon={<PlusOutlined />}
            type='primary'
            onClick={async () => {
              showFormModal();
            }}
          >{t('add_record')}</Button>
        </Col>
        <Col style={{ width: "100px" }}>
          <Button
            danger
            type="default"
            loading={loading.includes("list")}
            icon={<ClearOutlined />}
            onClick={(e) => {
              actions.getData(props.idPatentReservation);
            }}
          >
            {t("reset")}
          </Button>
        </Col>
      </Row>
      <Row>
        <RecordPatentsFilter></RecordPatentsFilter>
      </Row>
      <Table<any>
        rowKey="id"
        size="small"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
        scroll={{ x: 400 }}
      />
    </>
  );
};

export default PatentRecordTabel;
