import axios from "axios";
import { IPatentsQuery } from "../../../models/patents-collection/patents/query";
import {
  ICreatePatents,
  IExportPatentsExcel,
  IUpdatePatents,
} from "../../../models/patents-collection/patents/request";
import {
  IPatents,
  IPatentsDetails,
} from "../../../models/patents-collection/patents/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class Patentservice<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPatents = (query: IPatentsQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPatents>>({
      path: `/patents`,
      method: "GET",
      query,
      ...params,
    });

  getPatents = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsDetails>({
      path: `/patents/${id}`,
      method: "GET",
      ...params,
    });

  createPatents = (data: ICreatePatents, params?: RequestParams) =>
    this.http.request<IPatents>({
      path: "/patents",
      method: "POST",
      body: data,
      ...params,
    });

  updatePatents = (id: number, data: IUpdatePatents, params?: RequestParams) =>
    this.http.request<IPatents>({
      path: `/patents/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePatents = (id: number, params?: RequestParams) =>
    this.http.request<IPatents>({
      path: `/patents/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportPatentsExcel, query: IPatentsQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/patents/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default Patentservice;
