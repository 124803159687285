import { createContext } from "react";

import { IPatnetsRequestCostQuery } from "../../../models/patents-collection/patents-request-costs/query";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  IPatentsRequestCosts,
  IPatentsRequestCostsDetails,
} from "../../../models/patents-collection/patents-request-costs/response";
import {
  ICreatePatentsRequestCosts,
  IUpdatePatentsRequestCosts,
} from "../../../models/patents-collection/patents-request-costs/request";
import { IDrawingOfficeCostQuery } from "../../../models/drawing-collection/office-cost/query";

export type OfficeCostLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OfficeCostLoading[];

  list?: IBaseListingResponse<IPatentsRequestCosts>;
  query: IPatnetsRequestCostQuery;

  details?: IPatentsRequestCostsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IPatentsRequestCostsDetails) => void;

    createOfficeCost: (request: ICreatePatentsRequestCosts) => void;
    updateOfficeCost: (id: number, request: IUpdatePatentsRequestCosts) => void;
    deleteOfficeCost: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingOfficeCostQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createOfficeCost: DEFAULT_FUNCTION,
    updateOfficeCost: DEFAULT_FUNCTION,
    deleteOfficeCost: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PatentsOfficeCostContext = createContext(externalState);

export default PatentsOfficeCostContext;
