import { Col, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import RoleContext from "../../../../context/management-collection/role/context";
import RoleContextProvider from "../../../../context/management-collection/role/provider";
import { STATIC_PERMISSIONS } from "../../../../utils/rbac/permissions";
import FieldBuilder from "../../../form-components/field-builder";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";
import React, { useState } from "react";
import { Select, Checkbox } from "antd";
import GeneralForm from "../../../form-components/general-form";
const { Option } = Select;
import Controller from "../../../form-components/controller";
import { set, useForm } from "react-hook-form";
import { IRoleDetails } from "../../../../models/managent-collection/role/response";
import EndPoints from "../../../../services/end-points";

const Form = () => {
  const { t } = useTranslation();
  const { actions, loading } = useContext(RoleContext);
  const [selectedValues, setSelectedValues] = useState<string[]>();
  const [selectAll, setSelectAll] = useState(false);
  const { id } = useParams();

  const [details, setDetails] = useState<IRoleDetails>();
  const [detailsLoading, setdetailsLoading] = useState(false);

  // useEffect(() => {
  //   const getDetails = async () => {};
  //   getDetails();
  // }, []);

  useEffect(() => {
    const getDetails = () => {
      setdetailsLoading(true);

      EndPoints.role
        .getRole(Number(id))
        .then((data) => {
          setDetails(data?.data);
          setSelectedValues(data?.data?.permissions?.map((per) => per?.name));
        })
        .finally(() => {
          setdetailsLoading(false);
        });
    };
    id && getDetails();
  }, []);

  // details && setSelectedValues(details?.permissions?.map((per) => per?.name));
  // useEffect(() => {
  //   console.log("details", details);

  //   id
  //     ? setSelectedValues(details?.permissions?.map((per) => per?.name))
  //     : setSelectedValues([
  //         "can-export-role",
  //         "can-view-permissions",
  //         "can-view-users",
  //         "can-create-user",
  //         "can-export-role",
  //         "can-view-permissions",
  //         "can-view-users",
  //         "can-create-user",
  //         "can-export-role",
  //         "can-view-permissions",
  //         "can-view-users",
  //         "can-create-user",
  //         "can-export-role",
  //         "can-view-permissions",
  //         "can-view-users",
  //         "can-create-user",
  //       ]);
  //   // details &&
  //   //   details?.permissions?.map((m) => {
  //   //     setSelectedValues([...selectedValues, m?.name]);
  //   //   });
  // }, [id]);

  const handleSelectChange = (value) => {
    setSelectedValues(value);
    setSelectAll(value.length === STATIC_PERMISSIONS.length);
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    setSelectAll(checked);
    setSelectedValues(
      checked ? STATIC_PERMISSIONS.map((option) => option) : []
    );
  };

  const {
    control,
    formState: { errors },
  } = useForm();
  return (
    <GeneralForm
      values={[
        {
          key: "permissions",
          value: selectedValues ?? [],
        },
      ]}
      onSubmit={async (data) => {
        details
          ? await actions.updateRole(details?.id, data)
          : await actions.createRole(data);
      }}
      title={details ? t("update_role") : t("create_role")}
      defaultValues={
        {
          name: details?.name,
          permissions: details?.permissions.map((p) => p.name),
        } as any
      }
    >
      {loading.includes("update") || detailsLoading ? (
        <Spin />
      ) : (
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="name"
              label={`${t("name")}`}
              rules={{ required: true }}
              input={{ type: "text" }}
            />
          </Col>

          <Col span={24}>
            <Controller
              name="permissions"
              control={control}
              rules={{ required: false }}
              render={({ field: { ...field } }) => {
                return (
                  <>
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder={`${t("permissions")}`}
                      value={selectedValues}
                      onChange={handleSelectChange}
                      optionFilterProp="children"
                     // onSearch={(e) => console.log(e)}
                      dropdownRender={(menu) => (
                        <div>
                          <Checkbox
                            onChange={handleSelectAllChange}
                            checked={selectAll}
                          >
                            {t("select_all")}
                          </Checkbox>
                          {menu}
                        </div>
                      )}
                    
                    >
                      {STATIC_PERMISSIONS.map((option) => (
                        <Option key={option} value={option}>
                          {t(option)}
                        </Option>
                      ))}
                    </Select>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      )}
    </GeneralForm>
  );
};

const RoleForm = () => {
  return (
    <RoleContextProvider>
      <Form />
    </RoleContextProvider>
  );
};
export default RoleForm;
