import { ITranslationRequest } from "../../base/translation";
export interface ICreatePatents {
  name: ITranslationRequest;
  description: string;
  explanation: string;
  fileId: number;
  type: number;
  countryType: number;
  clients: number[];
  mainCountryId: number;
}

export interface IUpdatePatents extends ICreatePatents {}

export interface IExportPatentsExcel {
  nameEn: boolean;
  nameAr: boolean;
  type: boolean;
  country: boolean;
}

export const PatentsCloumms = ["nameEn", "nameAr", "type", "country"];
