import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import WiverRequestContext from "../../../../context/independent_requests-collection/waiver-request/context";
import { IWiverRequest } from "../../../../models/independent-requests-collection/waiver-request/response";
import {
  getBooleanWaiverRequestType,
  getWaiverRequestTypeColor,
  getWaiverStatusColor,
  getWaiverStatusType,
} from "../../../../models/independent-requests-collection/waiver-request/enum";

interface IProps {}

const WiverRequestTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } =
    useContext(WiverRequestContext);

  const [infoId, setInfoId] = useState(undefined);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const columns: ColumnProps<IWiverRequest>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 32,
      },
      width: 100,

      align: "center",
    },
    {
      title: t("client"),
      dataIndex: "clientId",
      align: "center",
      width: 150,
      sorter: {
        multiple: 19,
      },
      key: "client",
      render: (_, record) => {
        return (
          <div>
            {record?.client ? <Tag title={record?.client?.name} /> : "-"}
          </div>
        );
      },
    },
    {
      title: t("country"),
      dataIndex: "countryId",
      sorter: {
        multiple: 19,
      },
      align: "center",
      key: "country",
      render: (_, record) => {
        return <div>{record?.country?.name ?? "-"}</div>;
      },
    },

    {
      title: t("clientFor"),
      dataIndex: "clientFor",
      width: 150,
      align: "center",

      key: "clientFor",
      render: (_, record) => {
        return (
          <div>
            {record?.clientFor ? <Tag title={record?.clientFor?.name} /> : "-"}
          </div>
        );
      },
    },
    // {
    //   title: t("type_opreation"),
    //   dataIndex: "type_opreation",
    //   align: "center",
    //   sorter: {
    //     multiple: 20,
    //   },
    //   key: "type_opreation",
    //   render: (_, record) => {
    //     return (
    //       <div>
    //         {record?.type ? (
    //           <Tag
    //             title={getBooleanWaiverRequestType(record?.type)}
    //             color={getWaiverRequestTypeColor(record?.type)}
    //           />
    //         ) : (
    //           "-"
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      title: t("type_waiver"),
      dataIndex: "type_waiver",
      align: "center",
      sorter: {
        multiple: 20,
      },
      key: "type_waiver",
      width: 150,
      render: (_, record) => {
        return (
          <div>
            {record?.status ? (
              <Tag
                title={getWaiverStatusType(record?.status)}
                color={getWaiverStatusColor(record?.status)}
              />
            ) : (
              "-"
            )}
          </div>
        );
      },
    },

    {
      title: t("depositNumber"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("financialReceiptNumber"),
      dataIndex: "financialReceiptNumber",
      key: "financialReceiptNumber",

      sorter: {
        multiple: 2,
      },
      align: "center",
    },

    {
      title: t("dateFinancialReceipt"),
      dataIndex: "dateFinancialReceipt",
      key: "dateFinancialReceipt",

      // sorter: {
      //   multiple: 2,
      // },
      align: "center",
      render: (dateFinancialReceipt: Date) =>
        dateFinancialReceipt
          ? moment(dateFinancialReceipt).format("DD/MM/YYYY")
          : "-",
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",

      key: "officialDate",
      sorter: {
        multiple: 2,
      },
      align: "center",
      render: (officialDate: Date) =>
        officialDate ? moment(officialDate).format("DD/MM/YYYY") : "-",
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",

      key: "createdAt",
      sorter: {
        multiple: 8,
      },
      align: "center",
      render: (createdAt: Date) =>
        createdAt ? moment(createdAt).format("DD/MM/YYYY") : "-",
    },
    {
      title: t("order_value"),
      dataIndex: "orderValue",
      align: "center",
      key: "orderValue",
    },
    {
      title: t("client_payments"),
      dataIndex: "sumPayments",
      align: "center",
      key: "country",
    },
    {
      title: t("sum_costs"),
      dataIndex: "sumCosts",
      align: "center",
      key: "sumCosts",
    },
    {
      title: t("actions"),
      width: "15%",
      align: "center",
      key: "x",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />

          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteWiverRequest(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        scroll={{ x: 1700 }}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default WiverRequestTable;
