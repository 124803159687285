import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TrademarkReservationContext from "../../../../../context/trademark-collection/trademark-reservation/context";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import Tag from "../../../../general/antd/tag";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import TrademarkReservationFilter from "../trademark-reservation-filter";

import { ClearOutlined, QuestionCircleTwoTone } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useModal } from "react-modal-hook";
import AppContext from "../../../../../context/app/context";
import TrademarkContext from "../../../../../context/trademark-collection/trademark/context";
import {
  getBooleanTrademarkReservation,
  getTrademarkReservationColor,
} from "../../../../../models/trademark-collection/trademark-reservation/enum";
import { ICreateTrademarkReservation } from "../../../../../models/trademark-collection/trademark-reservation/request";
import { ITrademarkReservation } from "../../../../../models/trademark-collection/trademark-reservation/response";
import { ITrademark } from "../../../../../models/trademark-collection/trademark/response";
import EndPoints from "../../../../../services/end-points";
import FieldBuilder from "../../../../form-components/field-builder";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import TransferBtn from "../../../../general/table-components/actions/archive-btn";
import ResevationBtn from "../../../../general/table-components/actions/resevation-btn";
import ArchiveBtn from "../../../../general/table-components/actions/transfer-btn";
import TrademarkReservationForm from "../trademark-reservation-form";
import SpecialBtn from "../../../../general/table-components/actions/special-btn";
interface IProps {
  idTrademark: number;
}
const TrademarkReservationTabel: React.FC<IProps> = (props) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [infoResId, setInfoResId] = useState<number>();
  const [Id, setId] = useState<number>();
  const { query } = useContext(TrademarkContext);
  const {
    list: listReservation,
    actions: actionReservation,
    loading: loadigReservation,
    query: queryReservation,
  } = useContext(TrademarkReservationContext);
  const [infoId, setInfoioId] = useState<number>();
  const [detailsTrademarkReservation, setDetailsTrademarkReservation] =
    useState<any>();
  const [
    loadingdetailsTrademarkReservation,
    setLoadingDetailsTrademarkReservation,
  ] = useState(true);
  const [mainCategory, setMainCategory] = useState<number>();
  const [Category, setCategories] = useState<number[]>([]);
  const [country, setCountry] = useState<number>();

  const { direction } = useContext(AppContext);

  useEffect(() => {
    actionReservation.getData(props.idTrademark, country);
  }, [queryReservation, direction, props.idTrademark]);

  useEffect(() => {
    const getDetailsTrademarkReservation = async (id: number) => {
      try {
        const data =
          await EndPoints.trademarkReservation.getTrademarkReservation(id);
        setDetailsTrademarkReservation(data.data);
        {
          data.data?.mainCategory?.id
            ? setMainCategory(data.data?.mainCategory?.id)
            : setMainCategory(null);
        }
        {
          data.data?.categories
            ? setCategories(data.data?.categories)
            : setCategories([]);
        }
        setLoadingDetailsTrademarkReservation(false);
      } catch (error) {
      } finally {
        setLoadingDetailsTrademarkReservation(false);
      }
    };
    infoId && getDetailsTrademarkReservation(infoId);
  }, [infoId]);

  const changeCategoryId = async (id) => {
    setMainCategory(id);
  };
  const changeSubCategoryId = async (id: number[]) => {
    setCategories(id);
  };

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={
          loadigReservation.includes("create") ||
          loadigReservation.includes("update") ||
          loadigReservation.includes("details") 
        }
        title={
          !detailsTrademarkReservation
            ? t("add_reservation_trademark")
            : t("update_reservation_trademark")
        }
        hideModal={() => {
          hideFormodal();
          setInfoioId(undefined);
          setMainCategory(undefined);
          setCategories(undefined);
          setDetailsTrademarkReservation(undefined);
        }}
        width={"60vw"}
        onSubmit={async (data) => {
          detailsTrademarkReservation
            ? await actionReservation.updateTrademarkReservation(
                detailsTrademarkReservation.id,
                props.idTrademark,
                data
              )
            : await actionReservation.createTrademarkReservation(
                data,
                props.idTrademark
              );
          setInfoioId(undefined);
          setMainCategory(undefined);
          setCategories(undefined);
          setDetailsTrademarkReservation(undefined);
        }}
        defaultValues={
          {
            trademarkId: props.idTrademark,
            registrationDate: detailsTrademarkReservation?.registrationDate,
            depositDate: detailsTrademarkReservation?.depositDate,
            status: detailsTrademarkReservation?.status,
            depositNumber: detailsTrademarkReservation?.depositNumber,
            registrationNumber: detailsTrademarkReservation?.registrationNumber,
            renewalCounts: detailsTrademarkReservation?.renewalCounts,
            lastRenewalNumber: detailsTrademarkReservation?.lastRenewalNumber,
            lastRenewalDate: detailsTrademarkReservation?.lastRenewalDate,
            nextRenewalDate: detailsTrademarkReservation?.nextRenewalDate,
            countryId: detailsTrademarkReservation?.country?.id,
            mainCategoryId: mainCategory,
            // categories: detailsTrademarkReservation?.categories.map((item) => item.id),
            allCategories: detailsTrademarkReservation?.allCategories ?? false,
            basicDate: detailsTrademarkReservation?.basicDate,
            rightPriority: detailsTrademarkReservation?.rightPriority ?? false,
            basicNumber: detailsTrademarkReservation?.basicNumber,
            basicDateExisting:
              detailsTrademarkReservation?.basicDateExisting ?? false,
            extraWordsForCategories:
              detailsTrademarkReservation?.extraWordsForCategories,
          } as ICreateTrademarkReservation
        }
      >
        <TrademarkReservationForm
          categoryId={detailsTrademarkReservation?.mainCategory?.id}
          subCategories={detailsTrademarkReservation?.categories}
          setCategory={changeCategoryId}
          SubCategory={changeSubCategoryId}
        />
      </FormHookModal>
    ),
    [Id, detailsTrademarkReservation, infoId, mainCategory ,loadigReservation]
  );

  // archive reservation
  const [trademark, setTrademark] = useState<ITrademark[]>([]);
  const [trademarkLoading, setTrademarkLoading] = useState(false);
  useEffect(() => {
    const getTrademarks = async () => {
      try {
        setTrademarkLoading(true);

        const { data } = await EndPoints.trademark.getAllTrademarks({
          perPage: -1,
        });
        const trademark = data?.data?.filter(
          (tr) => tr?.id !== props?.idTrademark
        );
        setTrademark(trademark);
        setTrademarkLoading(false);
      } catch (error) {
      } finally {
        setTrademarkLoading(false);
      }
    };
    getTrademarks();
  }, [infoResId]);

  const [showFormArchiveModal, hideArchiveFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={false}
        title={t("copy_reservation_trademark")}
        style={{ marginTop: "2rem" }}
        hideModal={() => {
          hideArchiveFormodal();
          setInfoResId(undefined);
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          await EndPoints.trademarkReservation.copyTrademarkReservation(
            infoResId,
            {
              trademarkId: data?.trademarkId,
            }
          );
          setInfoResId(undefined);

          actionReservation.getData(props.idTrademark, country);
        }}
        defaultValues={{} as ICreateTrademarkReservation}
      >
        <Title level={5}> {t(`${"copy_trademark_reservation"}`)}: </Title>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="trademarkId"
              label={`${t("trademark")} (${t("client")})`}
              width="large"
              disabled={trademarkLoading ? true : false}
              rules={{ required: true }}
              input={{
                type: "select",
                allowSearch: true,
                loading: trademarkLoading,
                options: trademark.map((trademark, index) => {
                  return {
                    label: `${trademark?.name} ( ${
                      trademark?.clients?.length == 1
                        ? trademark?.clients[0]?.name
                        : trademark?.clients[0]?.name
                    } ${trademark?.clients?.length == 2 ? "," : ""}  ${
                      trademark?.clients?.length == 2
                        ? trademark?.clients[1]?.name
                        : ""
                    })` as string,
                    value: trademark.id as number,
                  };
                }),
              }}
            />
          </Col>
        </Row>
      </FormHookModal>
    ),
    [infoResId]
  );

  const [showFormTransferModal, hideTransferFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={false}
        title={t("transver_reservation_trademark")}
        style={{ marginTop: "2rem" }}
        hideModal={() => {
          hideTransferFormodal();
          setInfoResId(undefined);
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          await EndPoints.trademarkReservation.transferTrademarkReservation(
            infoResId,
            {
              trademarkId: data?.trademarkId,
            }
          );
          setInfoResId(undefined);

          actionReservation.getData(props.idTrademark, country);
        }}
        defaultValues={{} as ICreateTrademarkReservation}
      >
        <Title level={5}> {t(`${"transfer_trademark_reservation"}`)}: </Title>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="trademarkId"
              label={`${t("trademark")} (${t("client")})`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                allowSearch: true,
                loading: trademarkLoading,
                options: trademark.map((trademark, index) => {
                  return {
                    label: `${trademark?.name} ( ${
                      trademark?.clients?.length == 1
                        ? trademark?.clients[0]?.name
                        : trademark?.clients[0]?.name
                    } ${trademark?.clients?.length == 2 ? "," : ""}  ${
                      trademark?.clients?.length == 2
                        ? trademark?.clients[1]?.name
                        : ""
                    })` as string,
                    value: trademark.id as number,
                  };
                }),
              }}
            />
          </Col>
        </Row>
      </FormHookModal>
    ),
    [infoResId]
  );

  const [showFormSpecialModal, hideSpecialFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={false}
        title={t("special_trademark_reservation")}
        style={{ marginTop: "2rem" }}
        hideModal={() => {
          hideSpecialFormodal();
          setInfoResId(undefined);
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          await EndPoints.trademarkReservation.specialTrademarkReservation(
            infoResId,
            {
              trademarkId: data?.trademarkId,
            }
          );
          setInfoResId(undefined);

          actionReservation.getData(props.idTrademark, country);
        }}
        defaultValues={{} as ICreateTrademarkReservation}
      >
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="trademarkId"
              label={`${t("trademark")} (${t("client")})`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                allowSearch: true,
                loading: trademarkLoading,
                options: trademark.map((trademark, index) => {
                  return {
                    label: `${trademark?.name} ( ${
                      trademark?.clients?.length == 1
                        ? trademark?.clients[0]?.name
                        : trademark?.clients[0]?.name
                    } ${trademark?.clients?.length == 2 ? "," : ""}  ${
                      trademark?.clients?.length == 2
                        ? trademark?.clients[1]?.name
                        : ""
                    })` as string,
                    value: trademark.id as number,
                  };
                }),
              }}
            />
          </Col>
        </Row>
      </FormHookModal>
    ),
    [infoResId]
  );

  const columns: ColumnProps<ITrademarkReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("main_category"),
      dataIndex: "mainCategory",
      align: "center",
      key: "mainCategory",
      render: (_, { mainCategory }) => {
        return <p> {mainCategory?.number} </p>;
      },
    },
    {
      title: t("all_categories"),
      align: "center",
      dataIndex: "allCategories",
      key: "allCategories",
      render: (_, { allCategories }) => (
        <>
          {allCategories ? (
            <ImCheckmark color="green" />
          ) : (
            <ImCross color="red" />
          )}
        </>
      ),
    },
    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",
      key: "renewalCounts",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanTrademarkReservation(Number(record))}
            color={getTrademarkReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      key: "country",
      align: "center",
      render: (_, record) => {
        return <p> {record?.registrationNumber ?? "-"} </p>;
      },
    },
    {
      title: t("special_trademark"),
      dataIndex: "specialTrademark",
      key: "country",
      align: "center",
      render: (_, record) => {
        return <p> {record?.specialTrademark?.name ?? "-"} </p>;
      },
    },

    {
      title: t("actions"),
      width: 250,
      align: "center",
      key: "x",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`resevation/${record?.id}`, {
                state: {
                  trademarkId: props?.idTrademark,
                },
              });
            }}
          />
          <ArchiveBtn
            disabled={record?.status == 4 ? true : false}
            onClick={async () => {
              setInfoResId(record?.id);
              showFormArchiveModal();
            }}
          />
          <TransferBtn
            disabled={record?.status == 4 ? true : false}
            onClick={async () => {
              setInfoResId(record?.id);
              showFormTransferModal();
            }}
          />

          <SpecialBtn
            onClick={async () => {
              setInfoResId(record?.id);
              showFormSpecialModal();
            }}
          />

          <EditBtn
            disabled={record?.status == 4 ? true : false}
            onClick={async () => {
              setInfoioId(record?.id);
              showFormModal();
              actionReservation.getDetails(record.id);
            }}
          />

          <DeleteBtn
            disabled={record?.status == 4 ? true : false}
            onConfirm={async () => {
              await actionReservation.deleteTrademarkReservation(
                record?.id,
                props.idTrademark
              );
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loadigReservation.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actionReservation.setQuery({
        ...queryReservation,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      {" "}
      <Row>
        <Col style={{ width: "calc(100% - 112px)" }}>
          <ResevationBtn
            onClick={async () => {
              setId(props.idTrademark);
              showFormModal();
            }}
          />
        </Col>
        <Col style={{ width: "100px" }}>
          <Button
            danger
            type="default"
            loading={loadigReservation.includes("list")}
            icon={<ClearOutlined />}
            onClick={(e) => {
              setCountry(null);
              actionReservation.getData(props.idTrademark, null);
            }}
          >
            {t("reset")}
          </Button>
        </Col>
      </Row>
      <Row>
        <TrademarkReservationFilter setCountr={setCountry} />
      </Row>
      <Table<any>
        rowKey="id"
        size="small"
        showSorterTooltip
        tableLayout="auto"
        showPagination={true}
        columns={columns}
        dataSource={listReservation?.data ?? []}
        loading={loadigReservation.includes("list")}
        scroll={{ x: 500 }}
        total={listReservation?.total}
        pageSize={queryReservation.perPage}
        page={queryReservation.page}
        onPaginationChange={(page, pageSize) => {
          actionReservation.setQuery({
            ...queryReservation,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default TrademarkReservationTabel;
