import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import AppContext from "../../../../../context/app/context";
import PatentsRequestFilesContext from "../../../../../context/patents-collection/patnets-request-files/context";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import { IUpdatePatentsRequestFiles } from "../../../../../models/patents-collection/patents-requset-files/request";
import { ITrademarkRequesFiles } from "../../../../../models/trademark-collection/trademark-requset-files/response";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import PatentsRequestFilesDetails from "../details";
import PatentsRequsetFilesForm from "../form";

interface IProps {}

const PatentsFilesTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoId, setInfoId] = useState(undefined);
  const { list, actions, loading, query, details } = useContext(
    PatentsRequestFilesContext
  );
  const { direction } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    actions.getData(Number(id));
  }, [direction, query]);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const showModalInfo = () => {
    setIsInfoModalVisible(true);
  };
  const handleCancelInfo = () => {
    setInfoId(undefined);
    actions.setDetails(undefined);
    setIsInfoModalVisible(false);
  };

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ass"
        confirmButtonText={t("save")}
        loading={
          loading.includes("details") ||
          loading.includes("create") ||
          loading.includes("update")
        }
        title={!details ? t("add_requset_file") : t("update_requset_file")}
        hideModal={() => {
          setInfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "" }}
        defaultValues={
          {
            patentRequestId: details?.patentRequest?.id ?? Number(id),
            note: details?.note,
            fileId: details?.file?.id,
            name: details?.name,
          } as IUpdatePatentsRequestFiles
        }
        width={"50vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updatePatentsRequsetFile(details?.id, data)
            : await actions.createPatentsRequsetFile(data);
          setInfoId(undefined);
          actions.getData(Number(id));
        }}
      >
        <PatentsRequsetFilesForm file={details?.file} name={details?.name} />
      </FormHookModal>
    ),
    [details, loading]
  );
  const columns: ColumnProps<ITrademarkRequesFiles>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 100,
      align: "center",
    },

    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",

      render: (_, record) => {
        return <div>{record.name ?? "-"}</div>;
      },
    },
    {
      title: t("type_file"),
      dataIndex: "file",
      align: "center",
      key: "type_file",

      render: (_, record) => {
        return <div>{record?.file?.extension ?? "-"}</div>;
      },
    },
    {
      title: t("date_upload_file"),
      dataIndex: "file",
      align: "center",
      key: "type_file",

      render: (_, record) => {
        return moment(record?.createdAt).format("DD/MM/YYYY");
      },
    },

    {
      title: t("note"),
      dataIndex: "note",
      align: "center",
      key: "note",

      render: (_, record) => {
        return <div>{record.note ?? "-"}</div>;
      },
    },
    {
      title: t("actions"),
      width: 120,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              await setInfoId(record?.id);
              await showModalInfo();
            }}
          />
          <EditBtn
            onClick={() => {
              setInfoId(record?.id);
              showFormModal();
            }}
          />
          <Tooltip title={t("download_file")}>
            <a href={record?.file?.url} style={{ all: "unset" }}>
              <Button type="text" shape="circle" icon={<DownloadOutlined />} />
            </a>
          </Tooltip>
          <DeleteBtn
            loading={loading.includes("delete")}
            onConfirm={async () => {
              await actions.deletePatentsRequsetFile(record?.id);
              eventManager.emit(EVENT_SUCCESS);
              await actions.getData(Number(id));
            }}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Button
          type="primary"
          onClick={() => {
            showFormModal();
          }}
        >
          {t("add_requset_file")}
        </Button>
      </Col>
      <Col span={24}>
        <>
          <Table<any>
            rowKey="id"
            showPagination={true}
            columns={columns}
            dataSource={list?.data ?? []}
            loading={loading.includes("list") || loading.includes("delete")}
            size="small"
            total={list?.total}
            pageSize={query.perPage}
            page={query.page}
            onPaginationChange={(page, pageSize) => {
              actions.setQuery({
                ...query,
                page,
                perPage: pageSize,
              });
            }}
            onChange={(_, tableFilters, tabelSorters) => {
              tableOnChange(
                tableFilters,
                tabelSorters,
                tableFiltersProps,
                setSorts,
                setStaticFilters
              );
            }}
          />
        </>
      </Col>
      <Modal
        destroyOnClose
        title={t("file_details")}
        open={isInfoModalVisible}
        onOk={handleCancelInfo}
        onCancel={handleCancelInfo}
        cancelText={t("cancel")}
        okText={t("ok")}
        centered={true}
        okButtonProps={{ style: { textAlign: "center" } }}
        bodyStyle={{ minHeight: 200 }}
        width={800}
      >
        <PatentsRequestFilesDetails />
      </Modal>
    </Row>
  );
};

export default PatentsFilesTable;
