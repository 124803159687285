import axios from "axios";
import { IDrawingQuery } from "../../../models/drawing-collection/drawing/query";
import {
  ICreateDrawing,
  IExportDrawingExcel,
  IUpdateDrawing,
} from "../../../models/drawing-collection/drawing/request";
import {
  IDrawing,
  IDrawingDetails,
} from "../../../models/drawing-collection/drawing/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class DrawingService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDrawing = (query: IDrawingQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDrawing>>({
      path: `/drawings`,
      method: "GET",
      query,
      ...params,
    });

  getDrawing = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingDetails>({
      path: `/drawings/${id}`,
      method: "GET",
      ...params,
    });

  createDrawing = (data: ICreateDrawing, params?: RequestParams) =>
    this.http.request<IDrawing>({
      path: "/drawings",
      method: "POST",
      body: data,
      ...params,
    });

  updateDrawing = (id: number, data: IUpdateDrawing, params?: RequestParams) =>
    this.http.request<IDrawing>({
      path: `/drawings/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDrawing = (id: number, params?: RequestParams) =>
    this.http.request<IDrawing>({
      path: `/drawings/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportDrawingExcel, query: IDrawingQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/drawings/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default DrawingService;
