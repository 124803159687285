import { Button, Divider, Form } from "antd";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { execute } from "../../../utils/helpers/execute";
import styles from "./style.module.scss";

interface IProps<T> {
  children?: React.ReactNode;
  title: string | React.ReactNode;
  onSubmit: (data: any) => void;
  defaultValues?: T;
}

function MainForm<T>(props: IProps<T>) {
  const { children, title, defaultValues } = props;

  const methods = useForm<any>({
    defaultValues: { ...defaultValues },
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { handleSubmit, reset } = methods;
  const [submittingForm, setSubmittingForm] = useState(false);
  useEffect(() => {
    if (defaultValues && !submittingForm) {
      reset({ ...methods.getValues(), ...(defaultValues as any) });
    }
  }, [defaultValues]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        await props.onSubmit(data);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  return (
    <FormProvider {...methods}>
      <Form
        className={styles.container}
        onSubmitCapture={() => {
          setSubmittingForm(true);
        }}
        onFinish={methods.handleSubmit(onSubmit)}
      >
        {/* Header */}
        <div>
          <span className={styles.title}>{title}</span>
          <Divider className={styles.divider} />
          <div className={styles.children}>{children}</div>
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
}

export default MainForm;
