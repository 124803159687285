import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import NodesNameTable from "../../../components/managment-collection/nodes-name/nodes-name-table";
import AppContext from "../../../context/app/context";
import NodesNameContext from "../../../context/management-collection/node-name/context";
import NodesNameContextProvider from "../../../context/management-collection/node-name/provider";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../../utils/hooks/is-mount";

interface IProps {
  children?: React.ReactNode;
}

const NodesName: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(NodesNameContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("nodes_name")}
        subTitle={t("nodes_name_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/nodes-name/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_node_name")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="node_name_filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {}}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      ></SearchFilterCard>

      <NodesNameTable />
    </div>
  );
};

const NodesNamePage = () => {
  return (
    // ContextProvider
    <NodesNameContextProvider>
      <NodesName />
    </NodesNameContextProvider>
  );
};
export default NodesNamePage;
