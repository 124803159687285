import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  ITrademarkReservation,
  ITrademarkReservationDetails,
} from "../../../models/trademark-collection/trademark-reservation/response";
import { ITrademarkReservationQuery } from "../../../models/trademark-collection/trademark-reservation/query";
import {
  ICreateTrademarkReservation,
  IUpdateTrademarkReservation,
} from "../../../models/trademark-collection/trademark-reservation/request";
import { IHistory } from "../../../models/trademark-collection/trademark-request/response";

export type TrademarkReservationLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "history"
  | "special";

export interface IInternalState {
  loading: TrademarkReservationLoading[];

  list?: IBaseListingResponse<ITrademarkReservation>;
  query: ITrademarkReservationQuery;

  details?: ITrademarkReservationDetails;
  history?: IBaseListingResponse<IHistory>;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number, country?: number) => void;
    getDetails: (id: number) => void;
    getHistory: (idReservation: number) => void;
    setDetails: (data?: ITrademarkReservationDetails) => void;

    createTrademarkReservation: (
      request: ICreateTrademarkReservation,
      idTrademark: number
    ) => void;
    updateTrademarkReservation: (
      id: number,
      idTrademark: number,
      request: IUpdateTrademarkReservation
    ) => void;

    deleteTrademarkReservation: (id: number, idTrademark: number) => void;
    specialTrademarkReservation: (id: number, data: any) => void;
    setSearch: (search?: string) => void;
    setQuery: (query: ITrademarkReservationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    getHistory: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createTrademarkReservation: DEFAULT_FUNCTION,
    updateTrademarkReservation: DEFAULT_FUNCTION,
    deleteTrademarkReservation: DEFAULT_FUNCTION,
    specialTrademarkReservation: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const TrademarkReservationContext = createContext(externalState);

export default TrademarkReservationContext;
