import { useReducer } from "react";
import { ITraderMarkOrderTeamplateQuery } from "../../models/workflow/query";
import {
  ICopyTrademarkOrderTemplate,
  ICreateTraderMarkOrderTeamplate,
  IUpdateTraderMarkOrderTeamplate,
} from "../../models/workflow/request";
import {
  INodeTree,
  ITraderMarkOrderTeamplate,
  ITraderMarkOrderTeamplateDetails,
} from "../../models/workflow/response";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import TraderMarkOrderTeamplateContext, { internalState } from "./context";
import reducer from "./reducer";
import moment from "moment";

export interface IProps {
  children: React.ReactNode;
}
const TraderMarkOrderTeamplateContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          ...state.query,
        });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.trademarkOrderTemplate.getTraderMarkOrderTeamplates(
            id
          );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ITraderMarkOrderTeamplateDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createTraderMarkOrderTeamplate = async (
    request: ICreateTraderMarkOrderTeamplate
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.trademarkOrderTemplate.createTraderMarkOrderTeamplates(
          request
        );

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const CopyTraderMarkOrderTeamplate = async (
    id: number,
    request: ICopyTrademarkOrderTemplate
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.trademarkOrderTemplate.copyTraderMarkOrderTeamplates(
          id,
          request
        );

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  const updateTraderMarkOrderTeamplate = async (
    id: number,
    request: IUpdateTraderMarkOrderTeamplate
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.trademarkOrderTemplate.updateTraderMarkOrderTeamplates(
          id,
          request
        );

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteTraderMarkOrderTeamplate = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.trademarkOrderTemplate.deleteTraderMarkOrderTeamplates(
          id
        );
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const getTree = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.trademarkOrderTemplate.getTree(id);

        dispatch({ type: "SET_TREE", payload: { tree: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.trademarkOrderTemplate.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `OrderTemplate-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setQuery = (query: ITraderMarkOrderTeamplateQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };
  const setTree = (data?: INodeTree) => {
    dispatch({ type: "SET_TREE", payload: { tree: data } });
  };

  return (
    <TraderMarkOrderTeamplateContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          exportExcel,

          getTree,
          setTree,
          CopyTraderMarkOrderTeamplate,

          createTraderMarkOrderTeamplate,
          updateTraderMarkOrderTeamplate,
          deleteTraderMarkOrderTeamplate,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </TraderMarkOrderTeamplateContext.Provider>
  );
};

export default TraderMarkOrderTeamplateContextProvider;
