import {
  Descriptions as AntdDescriptions,
  Collapse,
  Row,
  Space,
  Typography,
} from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TrademarkReservationContext from "../../../../../context/trademark-collection/trademark-reservation/context";
import TrademarkReservationContextProvider from "../../../../../context/trademark-collection/trademark-reservation/provider";
import TrademarkContext from "../../../../../context/trademark-collection/trademark/context";
import TrademarkContextProvider from "../../../../../context/trademark-collection/trademark/provider";
import {
  getBooleanTrademarkReservation,
  getTrademarkReservationColor,
} from "../../../../../models/trademark-collection/trademark-reservation/enum";
import { ITrademark } from "../../../../../models/trademark-collection/trademark/response";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import DetailsPage from "../../../../form-components/view-details";
import DeleteButton from "../../../../general/antd/delete-button";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";
import TrademarkReservationHistoryTabel from "../Trademark-reservation-history-table";
import TrademarkReservationTrademarkRequestTabel from "../trademark-reservation-order-table";
import styles from "./styles.module.scss";
import RecordTrademarkContextProvider from "../../../../../context/trademark-collection/trademark-record/provider";
import TrademarkRecordTabel from "../trademark-record/table";

interface Props {}
const { Panel } = Collapse;

const { Title } = Typography;

const TrademarkReservation: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(TrademarkReservationContext);
  const { details: TrademarkDetails } = useContext(TrademarkContext);

  const [trademark, setTrademark] = useState<ITrademark[]>([]);
  const [trademarkLoading, setTrademarkLoading] = useState(false);
  const { id } = useParams();
  const [Id, setId] = useState<number>();
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  return (
    <>
      <DetailsPage title={t(`${"trademark_reservation_details"}`)}>
        <div className={styles.container}>
          <Space direction="vertical">
            <Collapse>
              <Panel header={t("trademark_reservation_details")} key="1">
                <Row>
                  <DeleteButton
                    disabled={details?.status === 4 ? true : false}
                    onConfirm={async () => {
                      {
                        details
                          ? await actions.deleteTrademarkReservation(
                              details.id,
                              Id
                            )
                          : await actions.deleteTrademarkReservation(-1, Id);
                      }
                      eventManager.emit(EVENT_SUCCESS);
                      navigate(-1);
                    }}
                    loading={loading.includes("delete")}
                    className={styles.buttonDelete}
                  >
                    {t(`${"delete_trademark_reservation"}`)}
                  </DeleteButton>
                  {/* <CopyBtn
                    disabled={details?.status == 4 ? true : false}
                    className={styles.button}
                    onClick={async () => {
                      showFormModal();
                    }}
                  /> */}
                  {/* <CopyBtn
                    className={styles.button}
                    onClick={async () => {
                      showFormModal();
                    }}
                  /> */}
                </Row>
                {loading.includes("details") ? (
                  <Spin />
                ) : (
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("main_category")}>
                      {details?.mainCategory?.number
                        ? details.mainCategory.number
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("categories")}>
                      {details?.categories
                        ? details.categories.map((category) => {
                            return <Tag title={category.name}></Tag>;
                          })
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("country_name")}>
                      {details?.country?.name ? details?.country?.name : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("types")}>
                      <Tag
                        title={getBooleanTrademarkReservation(
                          Number(details?.status)
                        )}
                        color={getTrademarkReservationColor(
                          Number(details?.status)
                        )}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("trademark_number")}>
                      {details?.parentReservation ? (
                        <Tag title={details?.parentReservation} color="green" />
                      ) : (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.specialTrademark
                        ? details.specialTrademark.name
                        : "-"}
                    </AntdDescriptions.Item>

                    {/* <AntdDescriptions.Item label={t("trademark_number")}>
                      {details?.trademark?.id ? details.trademark.id : "-"}
                    </AntdDescriptions.Item> */}
                    <AntdDescriptions.Item label={t("deposit_date")}>
                      {details?.depositDate
                        ? moment(details.depositDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.depositNumber ? details.depositNumber : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_date")}>
                      {details?.registrationDate
                        ? moment(details.registrationDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_number")}>
                      {details?.registrationNumber
                        ? details.registrationNumber
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("extraWordsForCategories")}>
                      {details?.extraWordsForCategories ?? "--"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("countWords")}>
                      {details?.countWords ?? "--"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("renewal_counts")}>
                      {details?.renewalCounts ? details.renewalCounts : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("last_renewal_date")}>
                      {details?.lastRenewalDate
                        ? moment(details.lastRenewalDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("last_renewal_number")}>
                      {details?.lastRenewalNumber
                        ? details.lastRenewalNumber
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("next_renewal_date")}>
                      {details?.nextRenewalDate
                        ? moment(details.nextRenewalDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("basic_number")}>
                      {details?.basicNumber ? details?.depositNumber : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("basic_date")}>
                      {details?.basicDate
                        ? moment(details.basicDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("basic_date_existing")}>
                      {details?.basicDateExisting ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("right_priority")}>
                      {details?.rightPriority ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("trademark_reservations_change_status")} key="1">
                <TrademarkReservationHistoryTabel />
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("trademark_reservation_request")} key="1">
                <TrademarkReservationTrademarkRequestTabel />
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("record")} key="1">
                <TrademarkRecordTabel idTrademarkReservation={details?.id} />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </DetailsPage>
    </>
  );
};

const TrademarkReservationDetailesPage = () => {
  return (
    <TrademarkContextProvider>
      <TrademarkReservationContextProvider>
        <RecordTrademarkContextProvider>
          <TrademarkReservation />
        </RecordTrademarkContextProvider>
      </TrademarkReservationContextProvider>
    </TrademarkContextProvider>
  );
};
export default TrademarkReservationDetailesPage;
