import axios from "axios";

import { IIncidentsLicensingQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/query";
import {
  ICreateIncidentsLicensing,
  IExportIncidentsLicensingExcel,
  IUpdateIncidentsLicensing,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/request";
import {
  IIncidentsLicensing,
  IIncidentsLicensingDetails,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/response";
import { INodeCanMove } from "../../../../models/trademark-collection/trademark-request/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../../utils/costants";
import { LANGUAGE_CODE } from "../../../../utils/helpers/constants";

class LicensingIncidentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllLicensingIncident = (
    query: IIncidentsLicensingQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsLicensing>>({
      path: "/incident-licensing",
      method: "GET",
      query,
      ...params,
    });

  createLicensingIncident = (
    data: ICreateIncidentsLicensing,
    params?: RequestParams
  ) =>
    this.http.request<ICreateIncidentsLicensing>({
      path: "/incident-licensing",
      method: "POST",
      body: data,
      ...params,
    });

  deleteLicensingIncident = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/incident-licensing/${id}`,
      method: "DELETE",
      ...params,
    });

  updateLicensingIncident = (
    id: number,
    data: IUpdateIncidentsLicensing,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsLicensing>({
      path: `/incident-licensing/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getLicensingIncident = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsLicensingDetails>({
      path: `/incident-licensing/${id}`,
      method: "GET",
      ...params,
    });

  moveIncidentReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/incident-licensing/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });
  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/incident-licensing/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (
    data: IExportIncidentsLicensingExcel,
    query: IIncidentsLicensingQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/incident-licensing/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/incident-licensing/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

    exportWord = (id: number, type: number) =>
      axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/licensing-request/export-word/${id}/${type}`,
        {
          headers: {
            authorization: `Bearer ${
              localStorage.getItem(ACCESS_TOKEN) ||
              sessionStorage.getItem(ACCESS_TOKEN)
            }`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          responseType: "arraybuffer",
        }
      );
}

export default LicensingIncidentService;
