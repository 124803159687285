export enum TrademarkRequestStatus {
	OPEN = 1,
	SUCCESS = 2,
	FAILED = 3
}

export enum Attachments {
	NOTHING = 1,
	REGISTER = 2,
	AGENCY = 3,
	REGISTERANDAGENCY = 4,
}

export const getTrademarkAttachmentsonColor = (status: Attachments) => {
	switch (status) {
		case Attachments.NOTHING:
			return "blue";
		case Attachments.REGISTER:
			return "orange";
		case Attachments.AGENCY:
			return "green";
		case Attachments.REGISTERANDAGENCY:
			return "gray";
	}
};

export const getTrademarkAttachmentsString = (
	status: Attachments
): string => {
	switch (status) {
		case Attachments.NOTHING:
			return 'no_thing';
		case Attachments.REGISTER:
			return 'registet';
		case Attachments.AGENCY:
			return 'agency';
		case Attachments.REGISTERANDAGENCY:
			return "register_and_agency";
	}
}

export const getTrademarkRequestStatusColor = (
	status: TrademarkRequestStatus
): string => {
	switch (status) {
		case TrademarkRequestStatus.OPEN:
			return 'blue'
		case TrademarkRequestStatus.SUCCESS:
			return 'green'
		case TrademarkRequestStatus.FAILED:
			return 'red'
	}
}

export const getTrademarkRequestStatusString = (
	status: TrademarkRequestStatus
): string => {
	switch (status) {
		case TrademarkRequestStatus.OPEN:
			return 'open'
		case TrademarkRequestStatus.SUCCESS:
			return 'success'
		case TrademarkRequestStatus.FAILED:
			return 'failed'
	}
}
