import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import FieldBuilder from "../../../../form-components/field-builder";
import { languages } from "../../../../../utils/costants";
import CategoryContextProvider from "../../../../../context/management-collection/category/provider";

interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[6, 6]}>
      {languages?.map((lang, index) => {
        return (
          <Col span={24}>
            <FieldBuilder
              key={index}
              name={`name[${lang?.code}]`}
              label={`${t("name")} (${lang?.name})`}
              input={{ type: "text" }}
              rules={{ required: true }}
            />
          </Col>
        );
      })}
      <Col span={24}>
        <FieldBuilder
          label={`${t("is_breif")}`}
          name="isBreif"
          input={{ type: "checkBox" }}
        />
      </Col>
    </Row>
  );
};

const CategoryForm: React.FC<IProps> = (props) => {
  return (
    <CategoryContextProvider>
      <Form {...props} />
    </CategoryContextProvider>
  );
};
export default CategoryForm;
