import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import { internalState } from "./context";
import reducer from "./reducer";
import PatentsRequestFilesContext from "./context";
import { IPatentsRequesFilesDetails } from "../../../models/patents-collection/patents-requset-files/response";
import {
  ICreatePatentsRequestFiles,
  IUpdatePatentsRequestFiles,
} from "../../../models/patents-collection/patents-requset-files/request";
import { IPatentsRequestFilesQuery } from "../../../models/patents-collection/patents-requset-files/query";

export interface IProps {
  children: React.ReactNode;
}
const PatentsRequestFilesContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.patentsRequsetfile.getAllPatentsRequsetFiles({
            ...state.query,
            filters: [
              {
                name: "patentRequest",
                operation: "eq",
                value: id,
              },
            ],
          });
        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.patentsRequsetfile.getPatentsRequsetFile(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IPatentsRequesFilesDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createPatentsRequsetFile = async (
    request: ICreatePatentsRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.patentsRequsetfile.createPatentsRequsetFile(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updatePatentsRequsetFile = async (
    id: number,
    request: IUpdatePatentsRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.patentsRequsetfile.updatePatentsRequsetFile(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deletePatentsRequsetFile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.patentsRequsetfile.deletePatentsRequsetFile(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IPatentsRequestFilesQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <PatentsRequestFilesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deletePatentsRequsetFile,
          createPatentsRequsetFile,

          updatePatentsRequsetFile,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </PatentsRequestFilesContext.Provider>
  );
};

export default PatentsRequestFilesContextProvider;
