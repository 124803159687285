import axios from "axios";

import { ACCESS_TOKEN } from "../../../../utils/costants";
import { LANGUAGE_CODE } from "../../../../utils/helpers/constants";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";
import { IIncidentsWaiverQuery } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/query";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import {
  IIncidentsWaiver,
  IIncidentsWaiverDetails,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/response";
import {
  ICreateIncidentsWaiver,
  IExportIncidentsWaiverExcel,
  IUpdateIncidentsWaiver,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/request";
import { INodeCanMove } from "../../../../models/trademark-collection/trademark-request/response";

class WaiverIncidentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllWaiverIncident = (
    query: IIncidentsWaiverQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsWaiver>>({
      path: `/incident-waiver`,
      method: "GET",
      query,
      ...params,
    });

  createWaiverIncident = (
    data: ICreateIncidentsWaiver,
    params?: RequestParams
  ) =>
    this.http.request<ICreateIncidentsWaiver>({
      path: "/incident-waiver",
      method: "POST",
      body: data,
      ...params,
    });

  deleteWaiverIncident = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/incident-waiver/${id}`,
      method: "DELETE",
      ...params,
    });

  updateWaiverIncident = (
    id: number,
    data: IUpdateIncidentsWaiver,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsWaiver>({
      path: `/incident-waiver/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getWaiverIncident = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsWaiverDetails>({
      path: `/incident-waiver/${id}`,
      method: "GET",
      ...params,
    });

  moveIncidentReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/incident-waiver/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });
  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/incident-waiver/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (
    data: IExportIncidentsWaiverExcel,
    query: IIncidentsWaiverQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/incident-waiver/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/incident-waiver/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportWord = (id: number, type: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/waiver-request/export-word/${id}/${type}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
}

export default WaiverIncidentService;
