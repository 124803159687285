import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import { IAgentRegistrationQuery } from "../../../models/independent-requests-collection/agent-registration/query";
import {
  ICreateAgentRegistration,
  IUpdateAgentRegistration,
} from "../../../models/independent-requests-collection/agent-registration/request";
import {
  IAgentRegistration,
  IAgentRegistrationDetails,
} from "../../../models/independent-requests-collection/agent-registration/response";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";

export type AgentRegistrationLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: AgentRegistrationLoading[];

  list?: IBaseListingResponse<IAgentRegistration>;
  query: IAgentRegistrationQuery;

  details?: IAgentRegistrationDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IAgentRegistrationDetails) => void;

    createAgentRegistration: (request: ICreateAgentRegistration) => void;
    updateAgentRegistration: (
      id: number,
      request: IUpdateAgentRegistration
    ) => void;
    deleteAgentRegistration: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IAgentRegistrationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createAgentRegistration: DEFAULT_FUNCTION,
    updateAgentRegistration: DEFAULT_FUNCTION,
    deleteAgentRegistration: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const AgentRegistrationContext = createContext(externalState);

export default AgentRegistrationContext;
