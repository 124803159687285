import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  IDrawingReservation,
  IDrawingReservationDetails,
  IDrawingReservationHistory,
} from "../../../models/drawing-collection/drawing-reservation/response";
import { IDrawingReservationQuery } from "../../../models/drawing-collection/drawing-reservation/query";
import {
  ICreateDrawingReservation,
  IUpdateDrawingReservation,
} from "../../../models/drawing-collection/drawing-reservation/request";

export type DrawingReservationLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "history";

export interface IInternalState {
  loading: DrawingReservationLoading[];

  list?: IBaseListingResponse<IDrawingReservation>;
  query: IDrawingReservationQuery;

  details?: IDrawingReservationDetails;
  history?: IBaseListingResponse<IDrawingReservationHistory>;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    getHistory: (idReservation: number) => void;
    setDetails: (data?: IDrawingReservationDetails) => void;

    createDrawingReservation: (request: ICreateDrawingReservation) => void;
    updateDrawingReservation: (
      id: number,

      request: IUpdateDrawingReservation
    ) => void;
    deleteDrawingReservation: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingReservationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    getHistory: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createDrawingReservation: DEFAULT_FUNCTION,
    updateDrawingReservation: DEFAULT_FUNCTION,
    deleteDrawingReservation: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DrawingReservationContext = createContext(externalState);

export default DrawingReservationContext;
