import { Checkbox, Col, Divider, Row, Select, Transfer } from "antd";
import type { TransferDirection, TransferListProps } from "antd/es/transfer";
import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import TrademarkReservationContextProvider from "../../../../../context/trademark-collection/trademark-reservation/provider";
import { ICategory } from "../../../../../models/managent-collection/category/response";
import { ICountry } from "../../../../../models/managent-collection/user/response";
import { getBooleanTrademarkReservation } from "../../../../../models/trademark-collection/trademark-reservation/enum";
import EndPoints from "../../../../../services/end-points";
import Controller from "../../../../form-components/controller";
import FieldBuilder from "../../../../form-components/field-builder";
import FormItem from "../../../../general/form-item";

interface IProps {
  categoryId?: number;
  subCategories?: any[];
  setCategory?: (number) => void;
  SubCategory?: ([]) => void;
}

interface RecordType {
  key: string;
  name: string;
  id: number;
  isBrufe: boolean;
}

const Form: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [subCategory, setSubCategory] = useState<
    { id: number; name: string; isBreif: boolean }[]
  >([]);
  const [subCategoryLoading, setSubCategoryLoading] = useState(true);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const status = [1, 2, 3];
  const [mainCategory, setMainCategory] = useState<number>(undefined);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [data, setData] = useState<RecordType[]>([]);
  const [categoriesSelect, setCategoriesSelect] = useState<number[]>([]);
  const [dataBasi, setDatabasi] = useState<RecordType[]>([]);
  const [subLength, setSubLength] = useState<number>();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const { data } = await EndPoints.category.getAllCategories({
          perPage: -1,
          filters: [
            {
              name: "parentCategory",
              value: null,
              operation: "eq",
            },
          ],
        });
        setCategories(data.data);
        setCategoriesLoading(false);
      } catch (error) {
      } finally {
        setCategoriesLoading(false);
      }
    };
    getCategories();
    {
      mainCategory
        ? getsubCategory(mainCategory)
        : props.categoryId
        ? dataSelect()
        : setSubCategory([]);
    }
  }, [mainCategory, props.categoryId, props.subCategories]);

  const dataSelect = async () => {
    try {
      if (props.categoryId) {
        const { data } = await EndPoints.category.getCategory(props.categoryId);
        setSubCategory(data?.subCategories);
        const dataSubCategory = data?.subCategories?.map((sub, i) => ({
          key: i.toString(),
          id: sub?.id,
          name: sub?.name,
          isBrufe: sub?.isBreif,
        }));
        if (props.subCategories) {
          let dataSelect = [];
          let c = 0;
          for (let i = 0; props.subCategories.length > i; i++) {
            for (let j = 0; dataSubCategory.length > j; j++) {
              if (dataSubCategory[j].id == props.subCategories[i].id) {
                dataSelect[c] = dataSubCategory[j].key;
                c++;
              }
            }
          }
          setTargetKeys(dataSelect);
        }
        setData(dataSubCategory);
        setSubLength(data?.subCategories?.length);
        setDatabasi(dataSubCategory);
      }
    } catch (error) {
    } finally {
    }
  };

  const getsubCategory = async (id) => {
    try {
      const { data } = await EndPoints.category.getCategory(id);
      setSubCategory(data?.subCategories);
      const dataSubCategory = data?.subCategories?.map((sub, i) => ({
        key: i.toString(),
        id: sub?.id,
        name: sub?.name,
        isBrufe: sub?.isBreif,
      }));
      setData(dataSubCategory);
      setSubLength(data?.subCategories?.length);
      setDatabasi(dataSubCategory);
      setCategoriesLoading(false);
    } catch (error) {
    } finally {
      setCategoriesLoading(false);
    }
  };

  const handleSearch = (dir: TransferDirection, value: string) => {
    console.log(dir, value);
    if (value) {
      let dataSearch;
      if (dir == "left") {
        dataSearch = dataBasi?.filter(
          (el) =>
            !targetKeys.includes(el.key) &&
            el?.name?.toLowerCase().includes(value?.toLowerCase())
        );
        dataSearch = [
          ...dataSearch,
          ...dataBasi?.filter((el) => targetKeys.includes(el.key)),
        ];
      } else if (dir == "right") {
        dataSearch = dataBasi?.filter(
          (el) =>
            targetKeys.includes(el.key) &&
            el?.name?.toLowerCase().includes(value?.toLowerCase())
        );
        dataSearch = [
          ...dataSearch,
          ...dataBasi?.filter((el) => !targetKeys.includes(el.key)),
        ];
      }
      setData(dataSearch);
    } else {
      setData(dataBasi);
    }
  };

  const onSelectChange = (
    sourceSelectedKeys: string[],
    targetSelectedKeys: string[]
  ) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const { control, setValue } = useFormContext();
  const watchFields = useWatch({
    control,
    name: ["categories", "mainCategoryId"],
  });

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <FormItem label={`${t("main_category_id")}`} required={true}>
            <Controller
              name="mainCategoryId"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: `${t("field_is_required_message")}`,
                },
              }}
              render={({ field: { ...field } }) => {
                return (
                  <>
                    <Select
                      {...field}
                      // defaultValue={mainCategory}
                      placeholder={`${t("main_category_id")}`}
                      className="shadow"
                      //value={mainCategory}
                      style={{ width: "100%" }}
                      loading={categoriesLoading}
                      options={categories.map((category, index) => {
                        return {
                          label: category.number as number,
                          value: category.id as number,
                        };
                      })}
                      onChange={(e) => {
                        field.onChange(e);
                        setMainCategory(e);
                        props.setCategory(e);
                      }}
                    ></Select>
                  </>
                );
              }}
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("types")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              options: status.map((statu, index) => {
                return {
                  label: t(getBooleanTrademarkReservation(statu) as string),
                  value: statu as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="countryId"
            label={`${t("country")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              loading: countriesLoading,
              allowSearch: true,
              options: countries.map((country, index) => {
                return {
                  label: country.name as string,
                  value: country.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="depositDate"
            label={`${t("deposit_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="depositNumber"
            label={`${t("deposit_number")}`}
            input={{ type: "text" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="registrationDate"
            label={`${t("registration_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("renewal_counts")}`}
            name="renewalCounts"
            input={{ type: "number" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="registrationNumber"
            label={`${t("registration_number")}`}
            input={{ type: "text" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="lastRenewalDate"
            label={`${t("last_renewal_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("last_renewal_number")}`}
            name="lastRenewalNumber"
            input={{ type: "text" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="nextRenewalDate"
            label={`${t("next_renewal_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("basic_date")}`}
            name="basicDate"
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="basicNumber"
            label={`${t("basic_number")}`}
            input={{ type: "text" }}
          />
        </Col>

        <Col span={8} style={{ marginTop: "2rem" }}>
          <FieldBuilder
            label={`${t("basic_date_existing")}`}
            name="basicDateExisting"
            input={{ type: "checkBox" }}
          />
        </Col>
        <Col span={8} style={{ marginTop: "2rem" }}>
          <FieldBuilder
            label={`${t("right_priority")}`}
            name="rightPriority"
            input={{ type: "checkBox" }}
          />
        </Col>
      </Row>
      <Divider></Divider>
      <Row>
        <Col span={17}>
          <FieldBuilder
            name="extraWordsForCategories"
            label={`${t("extraWordsForCategories")}`}
            input={{ type: "text" }}
          />
        </Col>
        <Col>
          <FormItem label={`${t("sub_categories")}`}>
            <Controller
              name="categories"
              control={control}
              render={({ field: { ...field } }) => {
                return (
                  <>
                    <Transfer
                      {...field}
                      className="shadow"
                      filterOption={(inputValue, item) =>
                        item.key!?.indexOf(inputValue) == -1 ||
                        item.key?.indexOf(inputValue) == -1
                      }
                      dataSource={data}
                      showSearch
                      targetKeys={targetKeys}
                      selectedKeys={selectedKeys}
                      onSearch={handleSearch}
                      pagination
                      titles={[
                        <Checkbox
                          style={{ marginLeft: "10px" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              let c = 0;
                              let dataArray = [];
                              let dataKey = [];
                              for (
                                let i = 0;
                                data.filter(
                                  (el) => !targetKeys.includes(el.key)
                                ).length > i;
                                i++
                              ) {
                                if (
                                  data.filter(
                                    (el) => !targetKeys.includes(el.key)
                                  )[i].isBrufe
                                ) {
                                  dataArray[c] = data.filter(
                                    (el) => !targetKeys.includes(el.key)
                                  )[i];
                                  c++;
                                }
                              }
                              for (let i = 0; dataArray.length > i; i++) {
                                dataKey[i] = dataArray[i]?.key;
                              }
                              onSelectChange(dataKey, dataKey);
                            } else {
                              onSelectChange([], []);
                            }
                          }}
                        >{`${t("is_breif")}`}</Checkbox>,
                        <Checkbox
                          style={{ marginLeft: "10px" }}
                          onChange={(e) => {
                            if (e.target.checked) {
                              let c = 0;
                              let dataArray = [];
                              let dataKey = [];
                              for (
                                let i = 0;
                                data.filter((el) => targetKeys.includes(el.key))
                                  .length > i;
                                i++
                              ) {
                                if (
                                  data.filter((el) =>
                                    targetKeys.includes(el.key)
                                  )[i].isBrufe
                                ) {
                                  dataArray[c] = data.filter((el) =>
                                    targetKeys.includes(el.key)
                                  )[i];
                                  c++;
                                }
                              }
                              for (let i = 0; dataArray.length > i; i++) {
                                dataKey[i] = dataArray[i]?.key;
                              }
                              onSelectChange(dataKey, dataKey);
                            } else {
                              onSelectChange([], []);
                            }
                          }}
                        >{`${t("is_breif")}`}</Checkbox>,
                      ]}
                      onChange={(e) => {
                        field.onChange(e);
                        setTargetKeys(e);
                        let dataArray = [];
                        let c = 0;
                        for (let i = 0; e.length > i; i++) {
                          dataArray[c] = data?.filter(
                            (item) => item.key == e[i]
                          );
                          c++;
                        }
                        let dataId = [];
                        let datatall = [];
                        for (let i = 0; dataArray.length > i; i++) {
                          dataId[i] = dataArray[i][0]?.id;
                        }
                        props.SubCategory(dataId);
                        setValue("categories", dataId);
                        // if (selectedKeys.length >= subCategory.length) {
                        if (dataId.length >= subLength) {
                          setValue("allCategories", true);
                          setValue("categories", undefined);
                        } else {
                          setValue("allCategories", false);
                        }
                      }}
                      onSelectChange={onSelectChange}
                      render={(item) => item.name}
                    ></Transfer>
                  </>
                );
              }}
            />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};
const TrademarkReservationForm: React.FC<IProps> = (props) => {
  return (
    <TrademarkReservationContextProvider>
      <Form {...props} />
    </TrademarkReservationContextProvider>
  );
};
export default TrademarkReservationForm;
