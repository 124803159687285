import { Descriptions as AntdDescriptions, Collapse, Space, Spin } from "antd";
import {useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CommercialRecordContext from "../../../context/commercial-records/context";
import PageDetails from "../../general/details";
import Descriptions from "../../general/antd/descriptions";
import Image from "../../general/antd/image";
import Tag from "../../general/antd/tag";
import office from "../../../assets/images/icon-files/office.jpg";
import a from "../../../assets/images/icon-files/pdf.png";
import CommercialRecordContextProvider from "../../../context/commercial-records/provider";

interface Props {}
const { Panel } = Collapse;

const CommercialRecord: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CommercialRecordContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("commercial_record")}`}
        buttonName={`${t("update_commercial_record")}`}
        onClick={() => navigate(`/commercial-records/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("number")}>
              {details?.number}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("purpose")}>
              {details?.purpose ?? "---"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("main_center")}>
              {details?.mainCenter ?? '---'}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("client")}>
              {details?.client?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("country")}>
              {details?.country?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("file")}>
              {details?.file ? (
                <Image preview src={details.file?.extension == 'docx' ? office : details.file?.extension == 'pdf' ? a : details.file?.url} />
              ) : (
                <Tag title="not_found" color="red" />
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const CommercialRecordDetailsPage = () => {
  return (
    <CommercialRecordContextProvider>
        <CommercialRecord />
    </CommercialRecordContextProvider>
  );
};
export default CommercialRecordDetailsPage;
