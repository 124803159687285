import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../context/app/context";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../utils/hooks/is-mount";
import CommercialRecordContext from "../../context/commercial-records/context";
import CommercialRecordContextProvider from "../../context/commercial-records/provider";
import CommercialRecordTable from "../../components/commercial-records/commercial-record-table";
import CommercialRecordFilter from "../../components/commercial-records/commercial-records-filter";

interface IProps {
  children?: React.ReactNode;
}

const CommercialRecord: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const { direction } = useContext(AppContext);

  const { actions, loading, query } = useContext(CommercialRecordContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("commercial_records")}
        subTitle={t("commercial_records_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_commercial_record")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="commercial-record-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <CommercialRecordFilter />
      </SearchFilterCard>

      <CommercialRecordTable />
    </div>
  );
};

const CommercialRecordPage = () => {
  return (
    <CommercialRecordContextProvider>
      <CommercialRecord />
    </CommercialRecordContextProvider>
  );
};
export default CommercialRecordPage;
