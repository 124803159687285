import { useReducer } from "react";
import {
  ICreateTrademark,
  IUpdateTrademark,
} from "../../../models/trademark-collection/trademark/request";
import { ITrademarkDetails } from "../../../models/trademark-collection/trademark/response";
import EndPoints from "../../../services/end-points";
import { internalState } from "./context";
import { execute } from "../../../utils/helpers/execute";
import TrademarkContext from "./context";
import reducer from "./reducer";
import { ITrademarkQuery } from "../../../models/trademark-collection/trademark/query";
import moment from "moment";

export interface IProps {
  children: React.ReactNode;
}
const TrademarkContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.trademark.getAllTrademarks(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.trademark.getTrademark(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ITrademarkDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createTrademark = async (request: ICreateTrademark) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.trademark.createTrademark(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateTrademark = async (id: number, request: IUpdateTrademark) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.trademark.updateTrademark(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteTrademark = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.trademark.deleteTrademark(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ITrademarkQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.trademark.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <TrademarkContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          exportExcel,
          createTrademark,
          updateTrademark,
          deleteTrademark,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </TrademarkContext.Provider>
  );
};

export default TrademarkContextProvider;
