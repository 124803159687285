import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import IncidentLicensingRequestFilesContext, { internalState } from "./context";

import reducer from "./reducer";
import { IIncidentsRequesLicensingFilesDetails } from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/response";
import {
  ICreateIncidentsLicensingRequestFiles,
  IUpdateIncidentsLicensingRequestFiles,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/request";
import { IIncidentsRequestFilesQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/query";

export interface IProps {
  children: React.ReactNode;
}
const IncidentLicensingRequestFilesContextProvider: React.FC<IProps> = (
  props
) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.incidentLicensingRequsetfile.getAllIncidentRequsetFiles(
            {
              ...state.query,
              filters: [
                {
                  name: "requestId",
                  operation: "eq",
                  value: id,
                },
              ],
            }
          );
        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.incidentLicensingRequsetfile.getIncidentRequsetFile(
            id
          );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IIncidentsRequesLicensingFilesDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createIncidentRequsetFile = async (
    request: ICreateIncidentsLicensingRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.incidentLicensingRequsetfile.createIncidentRequsetFile(
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateIncidentRequsetFile = async (
    id: number,
    request: IUpdateIncidentsLicensingRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.incidentLicensingRequsetfile.updateIncidentRequsetFile(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteIncidentRequsetFile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.incidentLicensingRequsetfile.deleteIncidentRequsetFile(
          id
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IIncidentsRequestFilesQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <IncidentLicensingRequestFilesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteIncidentRequsetFile,
          createIncidentRequsetFile,

          updateIncidentRequsetFile,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IncidentLicensingRequestFilesContext.Provider>
  );
};

export default IncidentLicensingRequestFilesContextProvider;
