import { useReducer } from "react";
import {
  ICreateTrademark,
  IUpdateTrademark,
} from "../../../models/trademark-collection/trademark/request";
import { ITrademarkDetails } from "../../../models/trademark-collection/trademark/response";
import EndPoints from "../../../services/end-points";
import TrademarkRequestContext, { internalState } from "./context";
import { execute } from "../../../utils/helpers/execute";
import TrademarkContext from "./context";
import reducer from "./reducer";
import { ITrademarkQuery } from "../../../models/trademark-collection/trademark/query";
import {
  ICreateTrademarkRequest,
  IUpdateTrademarkRequest,
} from "../../../models/trademark-collection/trademark-request/request";
import { ITrademarkRequestDetails } from "../../../models/trademark-collection/trademark-request/response";
import fileDownload from "js-file-download";
import moment from "moment";

export interface IProps {
  children: React.ReactNode;
}
const TrademarkRequestContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getList = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.trademarkRequest.getAllRequests(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createTrademarkRequest = async (request: ICreateTrademarkRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.trademarkRequest.createTradermarkRequests(request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ITrademarkQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const deleteRequest = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.trademarkRequest.deleteRequest(id);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };
  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.trademarkRequest.getTreademarkRequest(
          id
        );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ITrademarkRequestDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const updateTrademarkRequest = async (
    id: number,
    request: IUpdateTrademarkRequest
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.trademarkRequest.updateTreademarkRequest(id, request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const addReservationToTrademark = async (id: number, request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.trademarkRequest.addTrademarkReservations(id, request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.trademarkRequest.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",

          `Trademark-Requsert-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  const exportPdf = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdf" },
        });
        const data = await EndPoints.trademarkRequest.exportPdf(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-Requsert-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdf" },
        });
      },
      throwException: true,
    });
  };

  const exportPdfExternal = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdfex" },
        });
        const data = await EndPoints.trademarkRequest.exportPdfExternal(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-Requsert-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdfex" },
        });
      },
      throwException: true,
    });
  };

  const exportDepositTemplatePdf = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdfde" },
        });
        const data = await EndPoints.trademarkRequest.exportDepositTemplatePdf(
          id
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DepositTemplatePdf-Requsert-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdfde" },
        });
      },
      throwException: true,
    });
  };

  const exportDepositTemplatePdfExternal = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdfdeex" },
        });
        const data =
          await EndPoints.trademarkRequest.exportDepositTemplatePdfExternal(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DepositTemplatePdf-Requsert-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "pdfdeex" },
        });
      },
      throwException: true,
    });
  };

  const exportWord = async (id: number, type: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
        const data = await EndPoints.trademarkRequest.exportWord(id, type);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-Requsert-${moment().format("yyyy-MM-d")}.docx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
      },
      throwException: true,
    });
  };
  const exportDepositWord = async (id: number, type: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
        const data = await EndPoints.trademarkRequest.exportDepositWord(id, type);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-exportDepositWord-${moment().format("yyyy-MM-d")}.docx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
      },
      throwException: true,
    });
  };

  return (
    <TrademarkRequestContext.Provider
      value={{
        ...state,
        actions: {
          getList,
          getDetails,
          setDetails,

          exportExcel,
          exportPdf,
          exportPdfExternal,
          exportDepositTemplatePdf,
          exportDepositTemplatePdfExternal,
          createTrademarkRequest,
          updateTrademarkRequest,
          exportWord,
          exportDepositWord,
          deleteRequest,

          addReservationToTrademark,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </TrademarkRequestContext.Provider>
  );
};

export default TrademarkRequestContextProvider;
