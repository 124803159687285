import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { IClientPaymentsQuery } from "../../../models/trademark-collection/client-payments/query";
import {
  ICreateClientPayment,
  IUpdateClientPayment,
} from "../../../models/trademark-collection/client-payments/request";
import {
  IClientPayment,
  IClientPaymentDetails,
} from "../../../models/trademark-collection/client-payments/response";

class ClientPaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClientPayments = (
    query: IClientPaymentsQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IClientPayment>>({
      path: `/trademark-request-client-payments`,
      method: "GET",
      query,
      ...params,
    });

  getClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<IClientPaymentDetails>({
      path: `/trademark-request-client-payments/${id}`,
      method: "GET",
      ...params,
    });

  createClientPayment = (data: ICreateClientPayment, params?: RequestParams) =>
    this.http.request<IClientPayment>({
      path: "/trademark-request-client-payments",
      method: "POST",
      body: data,
      ...params,
    });

  updateClientPayment = (
    id: number,
    data: IUpdateClientPayment,
    params?: RequestParams
  ) =>
    this.http.request<IClientPayment>({
      path: `/trademark-request-client-payments/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<IClientPayment>({
      path: `/trademark-request-client-payments/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default ClientPaymentService;
