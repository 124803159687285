export enum CopyrighyRequestStatus {
  OPEN = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export const CopyrighyRequestStatusColor = (
  status: CopyrighyRequestStatus
): string => {
  switch (status) {
    case CopyrighyRequestStatus.OPEN:
      return "blue";
    case CopyrighyRequestStatus.SUCCESS:
      return "green";
    case CopyrighyRequestStatus.FAILED:
      return "red";
  }
};

export const CopyrighyRequestStatusString = (
  status: CopyrighyRequestStatus
): string => {
  switch (status) {
    case CopyrighyRequestStatus.OPEN:
      return "open";
    case CopyrighyRequestStatus.SUCCESS:
      return "success";
    case CopyrighyRequestStatus.FAILED:
      return "failed";
  }
};
