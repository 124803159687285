import { Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../../../form-components/field-builder";

interface IProps {
  loading?: boolean;
}

const ClientPaymentForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Row gutter={[6, 6]}>
      <Col span={8}>
        <FieldBuilder
          name="value"
          label={`${t("value")}`}
          input={{ type: "number" }}
          rules={{ required: true }}
        />
      </Col>
      <Col span={8}>
        <FieldBuilder
          name="receiptNumber"
          label={`${t("receipt_number")}`}
          input={{ type: "text" }}
          rules={{ required: false }}
        />
      </Col>
      <Col span={8}>
        <FieldBuilder
          name="date"
          label={`${t("date_payment")}`}
          input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          rules={{ required: true }}
        />
      </Col>
      <Col span={24}>
        <FieldBuilder
          name="note"
          label={`${t("note")}`}
          input={{ type: "text-area" }}
          rules={{ required: false }}
        />
      </Col>
    </Row>
  );
};

export default ClientPaymentForm;
