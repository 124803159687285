import { Checkbox, Col, Divider, Row } from "antd";
import cn from "classnames";
import { isEmpty, snakeCase } from "lodash";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { namesOfColumnsTrademarkRequest } from "../../../../models/trademark-collection/trademark-request/request";
import styles from "./style.module.scss";
const CheckboxGroup = Checkbox.Group;

// const names = namesOfColumnsTrademarkRequest;
// const defaultColumns = [];
interface IProps {
  names?: any;
  defaultColumns?: any;
}
const TemplateExportExcel: React.FC<IProps> = ({
  names,
  defaultColumns = [],
}) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < names?.length);
    setCheckAll(list.length === names?.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? names : defaultColumns);
    setIndeterminate(e.target.checked ? false : !isEmpty(defaultColumns));
    setCheckAll(e.target.checked);
  };

  // Default Columns
  const isDefault: (name: string) => boolean = (name) => {
    return defaultColumns.includes(name);
  };

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          {t("check_all")}
        </Checkbox>
      </Col>
      <Col span={24}>
        <Divider style={{ margin: "10px 0" }} />
      </Col>
      <Col span={24}>
        <CheckboxGroup
          defaultValue={defaultColumns}
          value={checkedList}
          onChange={onChange}
        >
          <Row gutter={[14, 14]}>
            {names?.map((name, key) => {
              return (
                <Col span={8} style={{ minWidth: "10rem" }}>
                  <Checkbox key={key} value={name} disabled={isDefault(name)}>
                    {t(snakeCase(name))}
                  </Checkbox>
                </Col>
              );
            })}
          </Row>
        </CheckboxGroup>
      </Col>
      {checkedList?.map((column, index) => {
        setValue(checkedList[index], true);
        return (
          <Controller
            name={checkedList[index]}
            control={control}
            defaultValue={column}
            render={({ field: { ...field } }) => {
              return (
                <>
                  <Checkbox
                    {...field}
                    checked={field.value}
                    className={cn("shadow", styles.hiden)}
                  >
                    {t("aa")}
                  </Checkbox>
                </>
              );
            }}
          />
        );
      })}
    </Row>
  );
  // return (
  //   <>
  //     <Row gutter={[16, 18]}>
  //       <Col span={24}></Col>
  //       <Col span={8}>
  //         <Controller
  //           name="depositNumber"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("deposit_number")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="Country"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("country")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="typeWorkflow"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("type_workflow")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="mainCategories"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("main_categories")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="trademark"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("trademark")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="clients"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("clients")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="currentNode"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("current_node")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="status"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("status")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="CreatedDate"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("creation_date")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="lastUpdatedDate"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("last_updated_date")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //       <Col span={8}>
  //         <Controller
  //           name="officialDate"
  //           control={control}
  //           rules={{ required: false }}
  //           render={({ field: { ...field } }) => {
  //             return (
  //               <>
  //                 <Checkbox {...field} checked={field.value} className="shadow">
  //                   {t("official_date")}
  //                 </Checkbox>
  //               </>
  //             );
  //           }}
  //         />
  //       </Col>
  //     </Row>
  //   </>
  // );
};

export default TemplateExportExcel;
