import {
  Descriptions as AntdDescriptions,
  Button,
  Col,
  Collapse,
  Row,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import PageDetails from "../../../general/details";
import DisclosureTrademarkRequestContext from "../../../../context/independent_requests-collection/disclosure-trademark-request/context";
import DisclosureTrademarkRequestContextProvider from "../../../../context/independent_requests-collection/disclosure-trademark-request/provider";
import { IDisclosureTrademark } from "../../../../models/independent-requests-collection/disclosure-trademark/response";
import { ColumnProps } from "antd/es/table";
import styles from "./styles.module.scss";
import Table from "../../../general/table-components/table";
import Space from "../../../general/antd/space";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import DisclosureTrademarkContext from "../../../../context/independent_requests-collection/disclosure-trademark/context";
import FormHookModal from "../../../general/modals/form-hook-modal";
import { useModal } from "react-modal-hook";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import CategoryForm from "../../../managment-collection/category/category-form";
import { IUpdateDisclosureTrademark } from "../../../../models/independent-requests-collection/disclosure-trademark/request";
import DisclosureTrademarkContextProvider from "../../../../context/independent_requests-collection/disclosure-trademark/provider";
import DisclosureTrademarkForm from "../../disclosure-trademark/form";
import PaymentRequestTable from "../../clients-payments/client-payment-table";
import PaymentRequestContextProvider from "../../../../context/independent_requests-collection/request-payments/provider";
import CostRequesTable from "../../office-cost/office-cost-table";
import CostRequestContextProvider from "../../../../context/independent_requests-collection/request-costs/provider";
interface Props {}
const DisclosureTrademarkRequestDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(
    DisclosureTrademarkRequestContext
  );
  const {
    details: Det,
    actions: act,
    loading: lod,
    list: ls,
  } = useContext(DisclosureTrademarkContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const [infoId, setInfoioId] = useState<number>();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    const getAllDisclosureTrademark = async () => {
      id && (await act.getData(Number(id)));
    };
    getDetails();
    getAllDisclosureTrademark();
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      infoId && (await act.getDetails(infoId));
    };
    getDetails();
  }, [infoId]);
  const columns: ColumnProps<IDisclosureTrademark>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: t("category"),
      dataIndex: "category",
      align: "center",

      key: "name",
      render: (_, record) => {
        return <div>{record?.category?.name ?? "-"}</div>;
      },
    },
    {
      title: t("products"),
      dataIndex: "products",
      key: "products",

      align: "center",
    },
    {
      title: t("trademark"),
      dataIndex: "trademark",
      key: "trademark",

      align: "center",
    },

    {
      title: t("actions"),
      dataIndex: "",
      width: 200,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <EditBtn
            onClick={async () => {
              setInfoioId(record.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await act.deleteDisclosureTrademark(record?.id);
              act.getData(Number(id));
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];
  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ass"
        confirmButtonText={t("save")}
        loading={
          lod.includes("create") ||
          lod.includes("update") ||
          lod.includes("details")
        }
        title={
          !Det
            ? t("add_disclosure_trademark")
            : t("update_disclosure_trademark")
        }
        hideModal={() => {
          setInfoioId(undefined);
          act?.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "3rem" }}
        defaultValues={
          {
            requestId: Number(id),
            categoryId: Det?.category?.id,
            products: Det?.products,
            trademark: Det?.trademark,
          } as IUpdateDisclosureTrademark
        }
        width={"60vw"}
        onSubmit={async (data) => {
          Det
            ? await act.updateDisclosureTrademark(Det.id, data)
            : await act.createDisclosureTrademark(data);
          setInfoioId(undefined);
          act?.setDetails(undefined);
          act.getData(Number(id));
        }}
      >
        <DisclosureTrademarkForm />
      </FormHookModal>
    ),
    [Det, lod]
  );

  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"disclosure_trademark_request"}`}
        buttonName={`${"update_disclosure_trademark_request"}`}
        onClick={() => navigate(`/disclosure-trademark-request/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel
                    header={t("details_disclosure_trademark_request")}
                    key="1"
                  >
                    <Row gutter={[12, 12]}>
                      <Col span={24}>
                        <Descriptions size="small" column={1} bordered>
                          <AntdDescriptions.Item label={t("id")}>
                            {details?.id}
                          </AntdDescriptions.Item>
                          <AntdDescriptions.Item label={t("client")}>
                            {details?.client?.name}
                          </AntdDescriptions.Item>
                          <AntdDescriptions.Item label={t("country")}>
                            {details?.country?.name}
                          </AntdDescriptions.Item>

                          <AntdDescriptions.Item label={t("depositNumber")}>
                            {details?.depositNumber}
                          </AntdDescriptions.Item>

                          <AntdDescriptions.Item label={t("official_date")}>
                            {details?.officialDate}
                          </AntdDescriptions.Item>

                          <AntdDescriptions.Item
                            label={t("financialReceiptNumber")}
                          >
                            {details?.financialReceiptNumber}
                          </AntdDescriptions.Item>

                          <AntdDescriptions.Item
                            label={t("dateFinancialReceipt")}
                          >
                            {details?.dateFinancialReceipt}
                          </AntdDescriptions.Item>

                          <AntdDescriptions.Item label={t("disclosureResult")}>
                            {details?.disclosureResult
                              ? details?.disclosureResult
                              : "-"}
                          </AntdDescriptions.Item>
                          <AntdDescriptions.Item label={t("order_value")}>
                            {details?.orderValue}
                          </AntdDescriptions.Item>
                          <AntdDescriptions.Item label={t("client_payments")}>
                            {details?.sumPayments}
                          </AntdDescriptions.Item>
                          <AntdDescriptions.Item label={t("sum_costs")}>
                        {details?.sumCosts}
                      </AntdDescriptions.Item>
                          <AntdDescriptions.Item label={t("note")}>
                            {details?.note ? details?.note : "-"}
                          </AntdDescriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={24}>
                        <Button
                          type="primary"
                          onClick={() => {
                            showFormModal();
                          }}
                        >
                          {t("add_disclosure_trademark")}
                        </Button>
                      </Col>
                      <Col span={24} style={{ marginBottom: ".4rem" }}>
                        <Table<any>
                          rowKey="id"
                          style={{ height: "100%" }}
                          columns={columns}
                          size="small"
                          dataSource={ls?.data ?? []}
                          loading={
                            lod.includes("details") ||
                            lod.includes("create") ||
                            lod.includes("delete") ||
                            lod.includes("update")
                          }
                        />
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("client_payments")} key={1}>
                    <PaymentRequestTable requestType="App\Models\DisclosureTrademarkRequest" />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("office_cost")} key={1}>
                    <CostRequesTable requestType="App\Models\DisclosureTrademarkRequest" />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </PageDetails>
    </>
  );
};

const DisclosureTrademarkRequestDetailsPage = () => {
  return (
    <DisclosureTrademarkRequestContextProvider>
      <DisclosureTrademarkContextProvider>
        <PaymentRequestContextProvider>
          <CostRequestContextProvider>
          <DisclosureTrademarkRequestDetails />
          </CostRequestContextProvider>
        </PaymentRequestContextProvider>
      </DisclosureTrademarkContextProvider>
    </DisclosureTrademarkRequestContextProvider>
  );
};
export default DisclosureTrademarkRequestDetailsPage;
