export enum getAllStatusCommercial {
  beginning = 7,
  appeal = 8,
  veto = 9,
}

export const getBooleanCommercialTrademarkOrderTemplate = (
  status: getAllStatusCommercial
) => {
  switch (status) {
    case getAllStatusCommercial.appeal:
      return "appeal";
    case getAllStatusCommercial.beginning:
      return "beginning";
    case getAllStatusCommercial.veto:
      return "veto";
  }
};
