import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import {
  IDrawing,
  IDrawingDetails,
} from "../../../models/drawing-collection/drawing/response";
import { IDrawingQuery } from "../../../models/drawing-collection/drawing/query";
import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import {
  ICreateDrawing,
  IUpdateDrawing,
} from "../../../models/drawing-collection/drawing/request";
import {
  ICopyright,
  ICopyrightDetails,
} from "../../../models/copyright-collection/copyright/response";
import { ICopyrightQuery } from "../../../models/copyright-collection/copyright/query";
import {
  ICreateCopyright,
  IUpdateCopyright,
} from "../../../models/copyright-collection/copyright/request";

export type CopyrightLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CopyrightLoading[];

  list?: IBaseListingResponse<ICopyright>;
  query: ICopyrightQuery;

  details?: ICopyrightDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICopyrightDetails) => void;

    createCopyright: (request: ICreateCopyright) => void;
    updateCopyright: (id: number, request: IUpdateCopyright) => void;
    deleteCopyrihgt: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ICopyrightQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createCopyright: DEFAULT_FUNCTION,
    updateCopyright: DEFAULT_FUNCTION,
    deleteCopyrihgt: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CopyrightContext = createContext(externalState);

export default CopyrightContext;
