import { useReducer } from "react";

import moment from "moment";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import DataRequestContext, { internalState } from "./context";
import reducer from "./reducer";
import { IDataRequestDetails } from "../../../models/independent-requests-collection/data-request/response";
import {
  ICreateDataRequest,
  IUpdateDataRequest,
} from "../../../models/independent-requests-collection/data-request/request";
import { IDataRequestQuery } from "../../../models/independent-requests-collection/data-request/query";

export interface IProps {
  children: React.ReactNode;
}
const DataRequestContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.dataRequest.getAllDataRequest(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.dataRequest.getDataRequest(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDataRequestDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createDataRequest = async (request: ICreateDataRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.dataRequest.createDataRequest(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateDataRequest = async (id: number, request: IUpdateDataRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.dataRequest.updateDataRequest(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteDataRequest = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.dataRequest.deleteDataRequest(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDataRequestQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.dataRequest.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `DataRequest-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <DataRequestContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          exportExcel,
          createDataRequest,
          updateDataRequest,
          deleteDataRequest,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DataRequestContext.Provider>
  );
};

export default DataRequestContextProvider;
