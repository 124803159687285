export enum PatentsRequestStatus {
  OPEN = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export const PatentsRequestStatusColor = (
  status: PatentsRequestStatus
): string => {
  switch (status) {
    case PatentsRequestStatus.OPEN:
      return "blue";
    case PatentsRequestStatus.SUCCESS:
      return "green";
    case PatentsRequestStatus.FAILED:
      return "red";
  }
};

export const PatentsRequestStatusString = (
  status: PatentsRequestStatus
): string => {
  switch (status) {
    case PatentsRequestStatus.OPEN:
      return "open";
    case PatentsRequestStatus.SUCCESS:
      return "success";
    case PatentsRequestStatus.FAILED:
      return "failed";
  }
};
