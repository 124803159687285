import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import NodeContextProvider from "../../../context/node/provider";
import TraderMarkOrderTeamplateContext from "../../../context/workflow/context";
import TraderMarkOrderTeamplateContextProvider from "../../../context/workflow/provider";
import Spin from "../../general/antd/spin";
import Tree from "../../general/tree";
import CustomZoom from "../../general/zoom";

const DetailsTree: React.FC = () => {
  const { tree, actions, loading } = useContext(
    TraderMarkOrderTeamplateContext
  );
  const { id } = useParams();

  useEffect(() => {
    id && actions.getTree(Number(id));
  }, [id]);

  return (
    <>
      {loading.includes("details") ? (
        <Spin />
      ) : (
        <div
          style={{
            zoom: "0.6",
          }}
        >
          <CustomZoom>{tree && <Tree data={tree} />}</CustomZoom>
        </div>
      )}
    </>
  );
};
const TrademarkOrderTemplateTreePage = () => {
  return (
    <TraderMarkOrderTeamplateContextProvider>
      <NodeContextProvider>
        <DetailsTree />
      </NodeContextProvider>
    </TraderMarkOrderTeamplateContextProvider>
  );
};
export default TrademarkOrderTemplateTreePage;
