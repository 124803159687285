import { IRequestCost, IRequestCostDetails } from "../../../models/independent-requests-collection/request-cost/response";
import { IRequestPayment, IRequestPaymentDetails } from "../../../models/independent-requests-collection/request-payment/response";
import { IWiverRequestQuery } from "../../../models/independent-requests-collection/waiver-request/query";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";

import { CostRequestLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: {
        loading: CostRequestLoading | CostRequestLoading[];
      };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IRequestCost> };
    }
  | { type: "SET_DETAILS"; payload: { details?: IRequestCostDetails } }
  | QueryAction<IWiverRequestQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
