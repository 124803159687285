import { ITranslationRequest } from "../../base/translation";
export interface ICreateDrawing {
  name: ITranslationRequest;
  description: string;
  note: string;
  type: number;
  countryType: number;
  logoId: number;
  mainCountryId: number;
  clients: number[];
  photos: number[];
  materials: string;
}

export interface IUpdateDrawing extends ICreateDrawing {}

// to do
export interface IExportDrawingExcel {
  nameEn: boolean;
  nameAr: boolean;
  type: boolean;
  country: boolean;
}

export const DrawingCloumms = ["nameEn", "nameAr", "type", "country"];
