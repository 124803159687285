import { ITranslationRequest } from "../../base/translation";
export interface ICreateCopyright {
  name: ITranslationRequest;
  description: string;
  explanation: string;
  fileId: number;
  type: number;
  countryType: number;
  clients: number[];
  mainCountryId: number;
  authorsDeathDate: Date;
}

export interface IUpdateCopyright extends ICreateCopyright {}

// to do
export interface IExportCopyrightToExcel {
  nameEn: boolean;
  nameAr: boolean;
  type: boolean;
  country: boolean;
}

export const CopyrightCloumms = ["nameEn", "nameAr", "type", "country"];
