import { createContext } from "react";

import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import {
  IIIncidentsWaiverPayment,
  IIIncidentsWaiverPaymentDetails,
} from "../../../../models/incidents-managments/incidents-waiver-request/client-payments/response";
import { IIncidentsWaiverPaymentsQuery } from "../../../../models/incidents-managments/incidents-waiver-request/client-payments/query";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  ICreateIncidentsWaiverPayment,
  IUpdateIncidentsWaiverPayment,
} from "../../../../models/incidents-managments/incidents-waiver-request/client-payments/request";

export type ClientPaymentLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ClientPaymentLoading[];

  list?: IBaseListingResponse<IIIncidentsWaiverPayment>;
  query: IIncidentsWaiverPaymentsQuery;

  details?: IIIncidentsWaiverPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIIncidentsWaiverPaymentDetails) => void;

    createClientPayment: (request: ICreateIncidentsWaiverPayment) => void;
    updateClientPayment: (
      id: number,
      request: IUpdateIncidentsWaiverPayment
    ) => void;
    deleteCleintPayment: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsWaiverPaymentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClientPayment: DEFAULT_FUNCTION,
    updateClientPayment: DEFAULT_FUNCTION,
    deleteCleintPayment: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsWaiverClientsPaymentContext = createContext(externalState);

export default IncidentsWaiverClientsPaymentContext;
