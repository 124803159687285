import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AgentRegistrationContext from "../../../../context/independent_requests-collection/agent-registration/context";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import { ICertifiedCopy } from "../../../../models/independent-requests-collection/certified-copy/response";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import { IAgentRegistration } from "../../../../models/independent-requests-collection/agent-registration/response";

interface IProps {}

const AgentRegistrationRequestTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } = useContext(
    AgentRegistrationContext
  );

  const [infoId, setInfoId] = useState(undefined);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const columns: ColumnProps<IAgentRegistration>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      align: "center",
    },

    {
      title: t("country"),
      dataIndex: "country",
      align: "center",
      sorter: {
        multiple: 2,
      },
      key: "country",
      render: (_, record) => {
        return <div>{record?.country?.name ?? "-"}</div>;
      },
    },
    {
      title: t("client"),
      dataIndex: "clients",
      align: "center",
      sorter: {
        multiple: 3,
      },
      key: "country",
      render: (_, record) => {
        return (
          <div>
            {record?.client ? <Tag title={record?.client?.name} /> : "-"}
          </div>
        );
      },
    },
    {
      title: t("companyManagerName"),
      dataIndex: "companyManagerName",
      key: "companyManagerName",
      sorter: {
        multiple: 4,
      },
      align: "center",
    },

    {
      title: t("depositNumber"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      sorter: {
        multiple: 5,
      },
      align: "center",
    },
    {
      title: t("financialReceiptNumber"),
      dataIndex: "financialReceiptNumber",
      key: "financialReceiptNumber",
      sorter: {
        multiple: 6,
      },
      align: "center",
      render: (_, record) => {
        return record?.financialReceiptNumber ?? "-";
      },
    },

    {
      title: t("dateFinancialReceipt"),
      dataIndex: "dateFinancialReceipt",
      key: "dateFinancialReceipt",
      sorter: {
        multiple: 7,
      },
      align: "center",
      render: (dateFinancialReceipt: Date) =>
        dateFinancialReceipt
          ? moment(dateFinancialReceipt).format("DD/MM/YYYY")
          : "-",
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      key: "officialDate",
      sorter: {
        multiple: 8,
      },
      align: "center",
      render: (officialDate: Date) =>
        officialDate ? moment(officialDate).format("DD/MM/YYYY") : "-",
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: {
        multiple: 9,
      },
      align: "center",
      render: (createdAt: Date) => moment(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: t("legalFormPerson"),
      dataIndex: "legalFormPerson",
      key: "legalFormPerson",
      width: 250,
      sorter: {
        multiple: 10,
      },
      align: "center",
      render: (_, record) => {
        return record?.legalFormPerson ?? "-";
      },
    },
    {
      title: t("order_value"),
      dataIndex: "orderValue",
      align: "center",
      key: "orderValue",
    },
    {
      title: t("client_payments"),
      dataIndex: "sumPayments",
      align: "center",
      key: "country",
    },
    {
      title: t("sum_costs"),
      dataIndex: "sumCosts",
      align: "center",
      key: "sumCosts",
    },
    {
      title: t("actions"),
      width: 180,
      key: "operation",
      align: "center",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />

          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteAgentRegistration(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        scroll={{ x: 1800 }}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default AgentRegistrationRequestTable;
