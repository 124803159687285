import { createContext } from "react";
import { ICountryQuery } from "../../../models/managent-collection/country/query";
import {
  ICreateCountry,
  IUpdateCountry,
} from "../../../models/managent-collection/country/request";
import {
  ICountry,
  ICountryDetails,
} from "../../../models/managent-collection/country/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type CountryLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CountryLoading[];

  list?: IBaseListingResponse<ICountry>;
  query: ICountryQuery;
  light?: IBaseListingResponse<ICountry>;

  details?: ICountryDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDataLight: () => void;

    getDetails: (id: number) => void;
    setDetails: (data?: ICountryDetails) => void;
    exportExcel: (data: any) => any;

    createCountry: (request: ICreateCountry) => void;
    updateCountry: (id: number, request: IUpdateCountry) => void;
    deleteCountry: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ICountryQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getDataLight: DEFAULT_FUNCTION,
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createCountry: DEFAULT_FUNCTION,
    updateCountry: DEFAULT_FUNCTION,
    deleteCountry: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CountryContext = createContext(externalState);

export default CountryContext;
