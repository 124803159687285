import { IIncidentsLicensingQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/query";
import {
  IIncidentsLicensing,
  IIncidentsLicensingDetails,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../../base/base-reducer";
import { IInternalState, incidentsLicensingLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: {
        loading: incidentsLicensingLoading | incidentsLicensingLoading[];
      };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IIncidentsLicensing> };
    }
  | { type: "SET_DETAILS"; payload: { details?: IIncidentsLicensingDetails } }
  | QueryAction<IIncidentsLicensingQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }

    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
