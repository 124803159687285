import { Descriptions as AntdDescriptions, Divider } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../../general/antd/descriptions";

import moment from "moment";
import c from "../../../../../assets/images/icon-files/exel.png";
import a from "../../../../../assets/images/icon-files/pdf.png";
import TrademarkRequestFilesContext from "../../../../../context/trademark-collection/trademark-request-files/context";
import Image from "../../../../general/antd/image";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";
import styles from "./style.module.scss";
import { checkType } from "../../../../../utils/helpers/check-type-file";

interface IProps {}
const TrademarkRequestFilesDetails: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { details, loading } = useContext(TrademarkRequestFilesContext);

  return (
    <>
      {loading.includes("details") ? (
        <div
          style={{
            height: "270px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Descriptions size="small" column={1} bordered>
          <AntdDescriptions.Item label={t("id")}>
            {details?.id}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("name")}>
            {details?.name}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("note")}>
            {details?.note}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("date_upload_file")}>
            {moment(details?.createdAt).format("DD/MM/YYYY")}
          </AntdDescriptions.Item>

          <AntdDescriptions.Item
            label={t("files")}
            // style={{ padding: ".5rem" }}
          >
            {details?.file ? (
              <a href={details?.file?.url}>
                <div className={styles.fileCardContainer}>
                  <div style={{ textAlign: "center", padding: "2px" }}>
                    {details?.name}
                  </div>
                  {checkType(details?.file?.extension) ? (
                    <Image
                      src={details?.file?.url}
                      style={{
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    <Image
                      src={details?.file?.extension === "pdf" ? a : c}
                      style={{
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />
                  )}
                </div>
              </a>
            ) : (
              <Tag title="not_found" color="red" />
            )}
          </AntdDescriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default TrademarkRequestFilesDetails;
