export enum DrawingRequestStatus {
  OPEN = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum Attachments {
	NOTHING = 1,
	REGISTER = 2,
	AGENCY = 3,
	REGISTERANDAGENCY = 4,
}

export const getDrawingAttachmentsonColor = (status: Attachments) => {
	switch (status) {
		case Attachments.NOTHING:
			return "blue";
		case Attachments.REGISTER:
			return "orange";
		case Attachments.AGENCY:
			return "green";
		case Attachments.REGISTERANDAGENCY:
			return "gray";
	}
};

export const getDrawingAttachmentsString = (
	status: Attachments
): string => {
	switch (status) {
		case Attachments.NOTHING:
			return 'no_thing';
		case Attachments.REGISTER:
			return 'registet';
		case Attachments.AGENCY:
			return 'agency';
		case Attachments.REGISTERANDAGENCY:
			return "register_and_agency";
	}
}

export const DrawingRequestStatusColor = (
  status: DrawingRequestStatus
): string => {
  switch (status) {
    case DrawingRequestStatus.OPEN:
      return "blue";
    case DrawingRequestStatus.SUCCESS:
      return "green";
    case DrawingRequestStatus.FAILED:
      return "red";
  }
};

export const DrawingRequestStatusString = (
  status: DrawingRequestStatus
): string => {
  switch (status) {
    case DrawingRequestStatus.OPEN:
      return "open";
    case DrawingRequestStatus.SUCCESS:
      return "success";
    case DrawingRequestStatus.FAILED:
      return "failed";
  }
};
