import { ITrademarkReservationQuery } from "../../../models/trademark-collection/trademark-reservation/query";
import {
  IRecordTrademark,
  IRecordTrademarkDetails,
  ITrademarkReservation,
  ITrademarkReservationDetails,
} from "../../../models/trademark-collection/trademark-reservation/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { IInternalState, RecordDrawingLoading } from "./context";
import { IHistory } from "../../../models/trademark-collection/trademark-request/response";

type Action =
  | {
      type: "LOADING";
      payload: {
        loading: RecordDrawingLoading | RecordDrawingLoading[];
      };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IRecordTrademark> };
    }
  | {
      type: "SET_DETAILS";
      payload: { details?: IRecordTrademarkDetails };
    }
  | QueryAction<ITrademarkReservationQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }

    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }


    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
