import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { IAgentChangeQuery } from "../../../models/independent-requests-collection/agent-change/query";
import {
  IAgentChange,
  IAgentChangeDetails,
} from "../../../models/independent-requests-collection/agent-change/response";
import {
  ICreateAgentChange,
  IExportAgentChangeExcel,
  IUpdateAgentChange,
} from "../../../models/independent-requests-collection/agent-change/request";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class AgentChangeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllAgentChange = (query: IAgentChangeQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IAgentChange>>({
      path: `/agent-change-request`,
      method: "GET",
      query,
      ...params,
    });

  getAgentChange = (id: number, params?: RequestParams) =>
    this.http.request<IAgentChangeDetails>({
      path: `/agent-change-request/${id}`,
      method: "GET",
      ...params,
    });

  createAgentChange = (data: ICreateAgentChange, params?: RequestParams) =>
    this.http.request<IAgentChange>({
      path: "/agent-change-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateAgentChange = (
    id: number,
    data: IUpdateAgentChange,
    params?: RequestParams
  ) =>
    this.http.request<IAgentChange>({
      path: `/agent-change-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteAgentChange = (id: number, params?: RequestParams) =>
    this.http.request<IAgentChange>({
      path: `/agent-change-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportAgentChangeExcel, query: IAgentChangeQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/agent-change-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default AgentChangeService;
