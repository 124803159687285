import { ICopyrightReservationQuery } from "../../../models/copyright-collection/copyright-reservation/query";
import {
  ICreateCopyrightReservation,
  IUpdateCopyrightReservation,
} from "../../../models/copyright-collection/copyright-reservation/request";
import {
  ICopyrightReservation,
  ICopyrightReservationDetails,
  ICopyrightReservationHistory,
} from "../../../models/copyright-collection/copyright-reservation/response";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class CopyrightReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCopyrightsReservation = (
    query: ICopyrightReservationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ICopyrightReservation>>({
      path: `/copyright-reservations`,
      method: "GET",
      query,
      ...params,
    });

  getHistory = (query: ICopyrightReservationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICopyrightReservationHistory>>({
      path: `/copyright-reservations/history`,
      method: "GET",
      query,
      ...params,
    });

  getCopyrightReservation = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightReservationDetails>({
      path: `/copyright-reservations/${id}`,
      method: "GET",
      ...params,
    });

  createCopyrightReservation = (data: ICreateCopyrightReservation) =>
    this.http.request<ICopyrightReservationDetails>({
      path: "/copyright-reservations",
      method: "POST",
      body: data,
    });

  updateCopyrightReservation = (
    id: number,
    data: IUpdateCopyrightReservation,
    params?: RequestParams
  ) =>
    this.http.request<ICopyrightReservationDetails>({
      path: `/copyright-reservations/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCopyrightReservation = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightReservationDetails>({
      path: `/copyright-reservations/${id}`,
      method: "DELETE",
      ...params,
    });

  transferCopyrightReservation = (
    id: number,
    data: any,
    params?: RequestParams
  ) =>
    this.http.request<ICopyrightReservationDetails>({
      path: `/copyright-reservations/transfer/${id}`,
      method: "POST",
      body: data,
      ...params,
    });
}

export default CopyrightReservationService;
