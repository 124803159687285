import { ICreateJopTitile } from "../../../models/managent-collection/jop-title/request";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { IJopTitleQuery } from "../../../models/managent-collection/jop-title/query";
import { IJopTitle } from "../../../models/managent-collection/jop-title/response";
import { IUpdateJopTitle } from "../../../models/managent-collection/jop-title/request";

class JopTitleService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllJopTitles = (query: IJopTitleQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IJopTitle>>({
      path: `/job-titles`,
      method: "GET",
      query,
      ...params,
    });

  getJopTitle = (id: number, params?: RequestParams) =>
    this.http.request<IJopTitle>({
      path: `/job-titles/${id}`,
      method: "GET",
      ...params,
    });

  createJopTitle = (data: ICreateJopTitile, params?: RequestParams) =>
    this.http.request<IJopTitle>({
      path: "/job-titles",
      method: "POST",
      body: data,
      ...params,
    });

  updateJopTitle = (
    id: number,
    data: IUpdateJopTitle,
    params?: RequestParams
  ) =>
    this.http.request<IJopTitle>({
      path: `/job-titles/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteJopTitle = (id: number, params?: RequestParams) =>
    this.http.request<IJopTitle>({
      path: `/job-titles/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default JopTitleService;
