import { IIncidentsRequestCostQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/query";
import { IUpdateIncidentsRequestCosts } from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/request";
import {
  ICreateIncidentsWaiverRequestCosts,
  IUpdateIncidentsWaiverRequestCosts,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-request-costs/request";
import {
  IIncidentsWaiverRequestCosts,
  IIncidentsWaiverRequestCostsDetails,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-request-costs/response";
import { IOfficeCost } from "../../../../models/trademark-collection/office-cost/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class IncidentsWaivesOfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (
    query: IIncidentsRequestCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsWaiverRequestCosts>>({
      path: `/incident-waiver-cost`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsWaiverRequestCostsDetails>({
      path: `/incident-waiver-cost/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (
    data: ICreateIncidentsWaiverRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IOfficeCost>({
      path: "/incident-waiver-cost",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdateIncidentsWaiverRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsWaiverRequestCosts>({
      path: `/incident-waiver-cost/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsWaiverRequestCosts>({
      path: `/incident-waiver-cost/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default IncidentsWaivesOfficeCostService;
