import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../../form-components/view-details";
import Descriptions from "../../../../general/antd/descriptions";
import EditeButton from "../../../../general/antd/edite-button";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";

import type { TabsProps } from "antd";
import { Tabs } from "antd";
import moment from "moment";
import {
  getRequestStatus,
  getRequestStatusColor,
} from "../../../../../models/node/enum";

import IncidentsWaiversContext from "../../../../../context/incidents-collection/incidents-waiver-requests/waiver-incidents/context";
import IncidentsWaiversContextProvider from "../../../../../context/incidents-collection/incidents-waiver-requests/waiver-incidents/provider";
import IncidentFilesTable from "../../../incidents-client/files/table";
import IncidentsOfficeCostTable from "../../../incidents-client/office-cost/office-cost-table";

import IncidentsWaiverClientsPaymentContextProvider from "../../../../../context/incidents-collection/incidents-waiver-requests/client-payments/provider";
import IncidentWaiverRequestFilesContextProvider from "../../../../../context/incidents-collection/incidents-waiver-requests/incident-request-files/provider";
import IncidentsWaiverOfficeCostContextProvider from "../../../../../context/incidents-collection/incidents-waiver-requests/office-cost/provider";
import IncidentTimeLine from "../../../incidents-client/time-line";
import IncidentsWaiverClientsPaymentTable from "../../clients-payments/client-payment-table";
import CopyrightTable from "../tables/copyright-reservation";
import DrawingTable from "../tables/drawing-reservation";
import PatentsTable from "../tables/patents-reservation";
import TrademarkTable from "../tables/trademark-reservation";
import styles from "./styles.module.scss";
import IncidentsWaivesOfficeCostTable from "../../office-cost/office-cost-table";
import IncidentWaiverTimeLine from "../../time-line";
import IncidentWaiverFilesTable from "../../files/table";

interface Props {}
const { Panel } = Collapse;

const IncidentsWaiver: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(IncidentsWaiversContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("trademark_reservation"),
      children: (
        <>
          <TrademarkTable />
        </>
      ),
    },
    {
      key: "2",
      label: t("reservations_drawing"),
      children: (
        <>
          {" "}
          <DrawingTable />
        </>
      ),
    },

    {
      key: "3",
      label: t("patents_and_utility_models_reservation"),
      children: (
        <>
          <PatentsTable />
        </>
      ),
    },
    {
      key: "4",
      label: t("copyright_reservation"),
      children: (
        <>
          <CopyrightTable />
        </>
      ),
    },
  ];

  return (
    <>
      <DetailsPage title={t("details_incidents_waiver")}>
        {loading.includes("details") ? (
          <div style={{ height: "50vh" }}>
            <Spin />
          </div>
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("details_incidents_waiver")} key="1">
                    <EditeButton
                      onClick={() => navigate(`/incidents-waiver/update/${id}`)}
                      className={styles.button}
                    >
                      {t(`${"update_incidents_waiver"}`)}
                    </EditeButton>
                    {loading.includes("details") ? (
                      <Spin />
                    ) : (
                      <Descriptions size="small" column={1} bordered>
                        <AntdDescriptions.Item label={t("id")}>
                          {details?.id}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("request_status")}>
                          <Tag
                            title={getRequestStatus(Number(details?.status))}
                            color={getRequestStatusColor(
                              Number(details?.status)
                            )}
                          />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("country")}>
                          <Tag
                            title={details?.workflow?.country?.name ?? "-"}
                          />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("workflow")}>
                          <Tag title={details?.workflow?.name ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("assignor")}>
                          {details?.assignor?.length > 0
                            ? details?.assignor?.map((ass, index) => {
                                return <Tag key={index} title={ass?.name} />;
                              })
                            : "-"}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("assignee")}>
                          {details?.assignee?.length > 0
                            ? details?.assignee?.map((ass) => {
                                return <Tag title={ass?.name} />;
                              })
                            : "-"}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item
                          label={t("transferOwnershipDate")}
                        >
                          {moment(details?.transferOwnershipDate).format(
                            "DD/MM/YYYY"
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("address")}>
                          {details?.address ?? "-"}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_cost")}>
                          <Tag title={details?.clientCost ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_payment")}>
                          <Tag title={details?.clientPayment ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("office_costs")}>
                          <Tag title={details?.officeCosts ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("current_node")}>
                          {details?.currentNode?.name?.name ?? (
                            <Tag title="not_found" color="red" />
                          )}
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("subject")}>
                          {details?.subject ?? "-"}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item
                          label={t("document_proving_change")}
                        >
                          {details?.documentProvingChange ?? "-"}
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("created_at")}>
                          {moment(details?.createdAt).format("DD/MM/YYYY")}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item
                          label={t("request_submitting_date")}
                        >
                          {moment(details?.officialDate).format("DD/MM/YYYY")}
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("description")}>
                          {details?.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: details?.description,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item
                          label={t("second_description_incident")}
                        >
                          {details?.secondDescription ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: details?.secondDescription,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </AntdDescriptions.Item>
                      </Descriptions>
                    )}
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Tabs defaultActiveKey="1" items={items} />
              </Col>

              <Col span={8}>
                <Collapse>
                  <Panel header={t("time_line_request")} key={1}>
                    <IncidentWaiverTimeLine />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={16}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("client_payments")} key={1}>
                        <IncidentsWaiverClientsPaymentTable />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col />
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("office_cost")} key={1}>
                        <IncidentsWaivesOfficeCostTable />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("files")} key={1}>
                        <IncidentWaiverFilesTable />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </DetailsPage>
    </>
  );
};

const IncidentsWaiverDetailsPage = () => {
  return (
    <IncidentsWaiversContextProvider>
      <IncidentsWaiverClientsPaymentContextProvider>
        <IncidentsWaiverOfficeCostContextProvider>
          <IncidentWaiverRequestFilesContextProvider>
            <IncidentsWaiver />
          </IncidentWaiverRequestFilesContextProvider>
        </IncidentsWaiverOfficeCostContextProvider>
      </IncidentsWaiverClientsPaymentContextProvider>
    </IncidentsWaiversContextProvider>
  );
};
export default IncidentsWaiverDetailsPage;
