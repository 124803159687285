import { IIncidentsRequestFilesQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/query";
import {
  ICreateIncidentsRequestFiles,
  IUpdateIncidentsRequestFiles,
} from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/request";
import {
  IIncidentsRequesFiles,
  IIncidentsRequesFilesDetails,
} from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/response";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class IncidentRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllIncidentRequsetFiles = (
    query: IIncidentsRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsRequesFiles>>({
      path: `/incident-client-files`,
      method: "GET",
      query,
      ...params,
    });

  getIncidentRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsRequesFilesDetails>({
      path: `/incident-client-files/${id}`,
      method: "GET",
      ...params,
    });

  createIncidentRequsetFile = (
    data: ICreateIncidentsRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsRequesFiles>({
      path: "/incident-client-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateIncidentRequsetFile = (
    id: number,
    data: IUpdateIncidentsRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsRequesFiles>({
      path: `/incident-client-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteIncidentRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsRequesFiles>({
      path: `/incident-client-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default IncidentRequsetfileService;
