import { ICostTypesQuery } from "../../../models/managent-collection/cost-types/query";
import {
  ICostType,
  ICostTypeDetails,
} from "../../../models/managent-collection/cost-types/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { CostTypesLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: CostTypesLoading | CostTypesLoading[] };
    }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<ICostType> } }
  | { type: "SET_DETAILS"; payload: { details?: ICostTypeDetails } }
  | QueryAction<ICostTypesQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
