import { INotificationQuery } from "./../../models/notification/query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import { INotifications } from "../../models/notification/response";

class NotifcationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllNotifications = (query: INotificationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INotifications>>({
      path: `/notifications/my-notifications`,
      method: "POST",
      body: query,
      ...params,
    });

  readNotification = (id: number, params?: RequestParams) =>
    this.http.request<INotifications>({
      path: `/notifications/${id}/read`,
      method: "PUT",
      ...params,
    });

  unReadNotification = () =>
    this.http.request<number>({
      path: `/notifications/unread-notifications`,
      method: "GET",
    });
}

export default NotifcationService;
