import { Descriptions as AntdDescriptions } from "antd";
import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../../../context/management-collection/user/context";
import Descriptions from "../../../general/antd/descriptions";
import PageDetails from "../../../general/details";
import { ImCross, ImCheckmark } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../../general/antd/spin";
import UserContextProvider from "../../../../context/management-collection/user/provider";
import {
  getBooleanStatus,
  getUserStatusColor,
} from "../../../../models/managent-collection/user/enum";
import Tag from "../../../general/antd/tag";
import AppContext from "../../../../context/app/context";

interface Props {}

const UserDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("user")}`}
        buttonName={`${t("edite_user")}`}
        onClick={() => navigate(`/users/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="middle" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("name")}>
              {details?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("email")}>
              {details?.email}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("status")}>
              <Tag
                title={getBooleanStatus(Number(details?.status))}
                color={getUserStatusColor(Number(details?.status))}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("landline")}>
              {details?.landline ? details.landline : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("phone")}>
              {details?.gsm ? details?.gsm : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("job_title")}>
              {details?.jobTitle?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("address")}>
              {details?.address ? details?.address : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("countries")}>
              {details?.countries?.map((contry, index) => {
                return (
                  <Fragment key={index}>
                    <Tag title={contry.name} />
                  </Fragment>
                );
              })}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("roles")}>
              {details?.roles?.map((role, index) => {
                return (
                  <Fragment key={index}>
                    <Tag title={role.name} />
                  </Fragment>
                );
              })}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("all_countries")}>
              {details?.allCountries ? (
                <ImCheckmark color="green" />
              ) : (
                <ImCross color="red" />
              )}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("telegram")}>
              {details?.telegramId ? details?.telegramId : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ? details?.note : "-"}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const UserDetailsPage = () => {
  return (
    <UserContextProvider>
      <UserDetails />
    </UserContextProvider>
  );
};
export default UserDetailsPage;
