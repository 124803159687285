import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  ICopyrightRequesFiles,
  ICopyrightRequesFilesDetails,
} from "../../../models/copyright-collection/copyright-requset-files/response";
import { ICopyrightRequestFilesQuery } from "../../../models/copyright-collection/copyright-requset-files/query";
import {
  ICreateCopyrightRequestFiles,
  IUpdateCopyrightRequestFiles,
} from "../../../models/copyright-collection/copyright-requset-files/request";

export type CopyrightRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: CopyrightRequestFilesLoading[];

  list?: IBaseListingResponse<ICopyrightRequesFiles>;
  query: ICopyrightRequestFilesQuery;

  details?: ICopyrightRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICopyrightRequesFilesDetails) => void;

    createCopyrightRequsetFile: (request: ICreateCopyrightRequestFiles) => void;
    updateCopyrightRequsetFile: (
      id: number,
      request: IUpdateCopyrightRequestFiles
    ) => void;
    deleteCopyrightRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ICopyrightRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createCopyrightRequsetFile: DEFAULT_FUNCTION,
    updateCopyrightRequsetFile: DEFAULT_FUNCTION,
    deleteCopyrightRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CopyrightRequestFilesContext = createContext(externalState);

export default CopyrightRequestFilesContext;
