import { IIncidentsRequestCostQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/query";
import {
  IIncidentsRequestCosts,
  IIncidentsRequestCostsDetails,
} from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/response";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../../base/base-reducer";
import { OfficeCostLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: OfficeCostLoading | OfficeCostLoading[] };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<IIncidentsRequestCosts> };
    }
  | {
      type: "SET_DETAILS";
      payload: { details?: IIncidentsRequestCostsDetails };
    }
  | QueryAction<IIncidentsRequestCostQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
