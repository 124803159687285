import {
  Descriptions as AntdDescriptions,
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TrademarkRequestContext from "../../../../../context/trademark-collection/trademark-request/context";
import TrademarkRequestContextProvider from "../../../../../context/trademark-collection/trademark-request/provider";
import { getRequestStatus } from "../../../../../models/node/enum";
import { IUpdateTrademarkRequest } from "../../../../../models/trademark-collection/trademark-request/request";

import {
  ITrademark,
  ITrademarkDetails,
} from "../../../../../models/trademark-collection/trademark/response";
import { ITraderMarkOrderTeamplate } from "../../../../../models/workflow/response";
import { ICountry } from "../../../../../models/managent-collection/user/response";
import EndPoints from "../../../../../services/end-points";
import { execute } from "../../../../../utils/helpers/execute";
import Controller from "../../../../form-components/controller";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";
import FormItem from "../../../../general/form-item";
import styles from "./style.module.scss";
import { RangePickerProps } from "antd/es/date-picker";
import FieldBuilder from "../../../../form-components/field-builder";
import { getTrademarkAttachmentsString } from "../../../../../models/trademark-collection/trademark-request/enum";

const Form = () => {
  const { details, actions, loading } = useContext(TrademarkRequestContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [trademarkOrderTemplate, setTrademarkOrderTemplate] = useState<
    ITraderMarkOrderTeamplate[]
  >([]);
  const [trademarkOrderTemplateLoading, setTrademarkOrderTemplateLoading] =
    useState(true);

  const [trademarks, setTrademarks] = useState<ITrademark[]>([]);
  const [trademarkLoading, setTrademarkLoading] = useState(true);
  const [trademarkDetails, setTrademarkDetails] = useState<ITrademarkDetails>();
  const [trademarkDetailsLoading, setTrademarkDetailsLoading] = useState(false);
  // const [details, setDetails] = useState<ITrademarkRequestDetails>();
  // const [detailsLoading, setDetailsLoading] = useState(true);
  const attachments = [1, 2, 3, 4];
  const requestStatus = [1, 2, 3];

  const Option = Select.Option;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const methods = useForm<any>({
    defaultValues: {
      officialDate: details?.officialDate,
      clientCost: details?.clientCost,
      depositNumber: details?.depositNumber,
      status: details?.status,
      trademarkId: details?.trademark?.id,
      workflowId: details?.workflow?.id,
      country: details?.workflow?.country?.id ?? undefined,
      fine: details?.fine ?? undefined,
      note: details?.note,
      registrationDate: details?.registrationDate,
      registrationNumber: details?.registrationNumber,
      registrationForeignCountries: details?.registrationForeignCountries,
      trademarkIsUsed: details?.trademarkIsUsed,
      nameCountry: details?.nameCountry,
      numberAndDatePriorityRight: details?.numberAndDatePriorityRight,
      attachments: details?.attachments
    } as IUpdateTrademarkRequest,
  });
  const { handleSubmit, reset, control } = methods;

  const countryId = useWatch({
    control,
    name: "country",
  });
  const trademarkId = useWatch({
    control,
    name: "trademarkId",
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.trademarkRequest.updateTreademarkRequest(
            details?.id,
            data
          )
          : await EndPoints.trademarkRequest.createTradermarkRequests(data);
        navigate(-1);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  // get all countries in stystem
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  // get all  trademarkTemplate for trademark only by filter
  useEffect(() => {
    const getTrademarkOrderTemplates = async () => {
      try {
        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          filters: [
            {
              name: "countryId",
              operation: "eq",
              value: countryId,
            },
            {
              name: "for",
              operation: "eq",
              value: 1,
            },
          ],
        });
        setTrademarkOrderTemplate(data.data);
      } catch (error) {
      } finally {
        setTrademarkOrderTemplateLoading(false);
      }
    };
    getTrademarkOrderTemplates();
  }, [countryId]);

  // get trademarks
  useEffect(() => {
    const getTrademarks = async () => {
      try {
        const { data } = await EndPoints.trademark.getAllTrademarks({
          perPage: -1,
        });
        setTrademarks(data.data);
      } catch (error) {
      } finally {
        setTrademarkLoading(false);
      }
    };
    getTrademarks();
  }, []);

  useEffect(() => {
    const getTrademarkOrderTemplates = async () => {
      try {
        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          perPage: -1,
          filters: [
            {
              name: "countryId",
              operation: "eq",
              value: countryId,
            },
            {
              name: "for",
              operation: "eq",
              value: 1,
            },
          ],
        });
        setTrademarkOrderTemplate(data.data);
      } catch (error) {
      } finally {
        setTrademarkOrderTemplateLoading(false);
      }
    };
    getTrademarkOrderTemplates();
  }, [countryId]);

  useEffect(() => {
    const getTrademarkDetails = async () => {
      try {
        setTrademarkDetailsLoading(true);
        const { data } = await EndPoints.trademark.getTrademark(trademarkId);
        setTrademarkDetails(data);
      } catch (error) {
      } finally {
        setTrademarkDetailsLoading(false);
      }
    };
    trademarkId && getTrademarkDetails();
  }, [trademarkId]);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    reset({
      clientCost: details?.clientCost,
      country: details?.workflow?.country?.id,
      trademarkId: details?.trademark?.id,
      workflowId: details?.workflow?.id,
      depositNumber: details?.depositNumber,
      officialDate: details?.officialDate,
      status: details?.status,
      fine: details?.fine,
      note: details?.note,
      registrationDate: details?.registrationDate,
      registrationNumber: details?.registrationNumber,
      registrationForeignCountries: details?.registrationForeignCountries,
      trademarkIsUsed: details?.trademarkIsUsed,
      nameCountry: details?.nameCountry,
      numberAndDatePriorityRight: details?.numberAndDatePriorityRight,
      attachments: details?.attachments

    } as IUpdateTrademarkRequest);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div>
          <span className={styles.title}>
            {details
              ? t("update_trademark_request")
              : t("create_trademark_request")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <FormItem label={t("countries")} required>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            placeholder={t("countries")}
                            options={countries.map((country, index) => {
                              return {
                                label: country.name as string,
                                value: country.id as number,
                              };
                            })}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("trademark_orders_templates")} required>
                    <Controller
                      name="workflowId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={trademarkOrderTemplateLoading}
                            disabled={countryId ? false : true}
                            placeholder={t("trademark_orders_templates")}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                          >
                            {trademarkOrderTemplate?.map((ca, index) => {
                              return (
                                <Option key={index} value={ca.id}>
                                  {ca.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={`${t("trademark")} (${t("client")})`}>
                    <Controller
                      name="trademarkId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            loading={trademarkOrderTemplateLoading}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            placeholder={t("trademarks")}
                            options={trademarks?.map((tr, index) => {
                              return {
                                label: `${tr?.name} ( ${tr?.clients.length == 1 ? tr.clients[0]?.name : tr.clients[0]?.name} ${tr?.clients.length == 2 ? ',' : ''}  ${tr?.clients.length == 2 ? tr.clients[1]?.name : ''})` as string,
                                value: tr.id as number,
                              };
                            })}
                          />
                        )
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <Descriptions size="small" column={2} bordered>
                    <AntdDescriptions.Item label={t("clients")}>
                      {trademarkDetailsLoading ? (
                        <div>
                          <Spin notViewWordLoading={true} size="small" />
                        </div>
                      ) : (
                        <>
                          {trademarkDetails?.clients?.length > 0 ? (
                            trademarkDetails?.clients?.map((client, index) => {
                              return <Tag key={index} title={client?.name} />;
                            })
                          ) : (
                            <Tag title="not_found" color="red" />
                          )}
                        </>
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={8}>
                  <FormItem label={t("request_status")}>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={trademarkOrderTemplateLoading}
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("request_status")}
                          >
                            {requestStatus?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {t(getRequestStatus(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("deposit_number")}>
                    <Controller
                      name="depositNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("deposit_number")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("request_submitting_date")}>
                    <Controller
                      name="officialDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("registration_number")}>
                    <Controller
                      name="registrationNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("registration_number")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("registration_date")}>
                    <Controller
                      name="registrationDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="nameCountry"
                    label={`${t("name_country")} `}
                    input={{ type: "text" }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="numberAndDatePriorityRight"
                    label={`${t("number_and_date_priority_right")} `}
                    input={{ type: "text" }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="attachments"
                    label={`${t("attachments")} `}
                    width="large"
                    input={{
                      type: "select-type",
                      options: attachments.map((attachment, index) => {
                        return {
                          label: t(getTrademarkAttachmentsString(attachment)) as string,
                          value: attachment as number,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8} style={{ marginTop: "1.6rem" }}>
                  <FieldBuilder
                    label={`${t("registration_foreign_countries")} `}
                    name="registrationForeignCountries"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={8} style={{ marginTop: "1.6rem" }}>
                  <FieldBuilder
                    label={`${t("trademark_is_used")} `}
                    name="trademarkIsUsed"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={24}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="note"
                    label={`${t("note")} `}
                    input={{ type: "text-area" }}
                  />
                </Col>

                {details?.withFine && (
                  <Col span={8}>
                    <FormItem label={t("fine")}>
                      <Controller
                        name="fine"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              type="text"
                              placeholder={t("fine")}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                <Col span={24}>
                  {details && (
                    <>
                      <Descriptions size="middle" column={2} bordered>
                        <AntdDescriptions.Item label={t("current_node")}>
                          {details?.currentNode?.name?.name}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("clien_cost")}>
                          {details?.clientCost}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_payments")}>
                          {details?.clientPayment}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("office_cost")}>
                          {details?.officeCosts}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("total_cost")}>
                          {details?.totalCost}
                        </AntdDescriptions.Item>
                      </Descriptions>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const TrademarkRequestsForm = () => {
  return (
    <TrademarkRequestContextProvider>
      <Form />
    </TrademarkRequestContextProvider>
  );
};
export default TrademarkRequestsForm;
