import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TemplateExportExcel from "../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../components/general/excel/export-to-excel-modal";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import TradeMarkOrderTemplateFilter from "../../components/workflow/trademark-order-template-filter";
import TraderMarkOrderTeamplateTable from "../../components/workflow/tradermark-order-teamplate-table";
import AppContext from "../../context/app/context";
import TraderMarkOrderTeamplateContext from "../../context/workflow/context";
import TraderMarkOrderTeamplateContextProvider from "../../context/workflow/provider";
import {
  IExportTraderMarkOrderTeamplateExcel,
  IUpdateTraderMarkOrderTeamplate,
  trademarkOrderTemplateColoumn,
} from "../../models/workflow/request";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../utils/hooks/is-mount";
import { getTranslations } from "../../utils/helpers/translations";
import CreateWorkFlowForm from "../../components/workflow/workflow-form";
import FormHookModal from "../../components/general/modals/form-hook-modal";
import { useModal } from "react-modal-hook";

interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const TraderMarkOrderTeamplate: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { actions, details, loading, query } = useContext(
    TraderMarkOrderTeamplateContext
  );

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  // useEffect(() => {
  //   actions.getData();
  // }, [query, direction]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="as"
        confirmButtonText={t("save")}
        loading={loading.includes("create")}
        title={
          details
            ? t("update_trademark_orders_templates")
            : t("add_trademark_orders_templates")
        }
        hideModal={() => {
          hideFormodal();
        }}
        style={{ marginTop: "-2rem", height: "10vh" }}
        defaultValues={
          {
            name: getTranslations(details?.translations?.name),
            countryId: details?.country?.id,
            extraWordsCount: details?.extraWordsCount,
            extraWordsPrice: details?.extraWordsPrice,
            mainPrice: details?.mainPrice,
            mainWordsCount: details?.mainWordsCount,
            officeCost: details?.officeCost,
            type: details?.type,
            for: details?.for,
            // rootNodeId: null,
          } as IUpdateTraderMarkOrderTeamplate
        }
        width={"75vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updateTraderMarkOrderTeamplate(details?.id, data)
            : await actions.createTraderMarkOrderTeamplate(data);
        }}
      >
        <CreateWorkFlowForm />
      </FormHookModal>
    ),
    [details, loading]
  );

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("trademark_orders_templates")}
        subTitle={t("trademark_orders_templates_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                showFormModal();
              }}
              icon={<PlusOutlined />}
            >
              {t("add_trademark_orders_templates")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-trademark_order_template"
        title={t("export_trademark_order_template_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="40vw"
        defaultValues={
          {
            Country: false,
            nameAr: false,
            nameEn: false,
            type: false,
          } as IExportTraderMarkOrderTeamplateExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={trademarkOrderTemplateColoumn} />
      </ExportToExcelModal>

      <SearchFilterCard
        formId="order-template-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <TradeMarkOrderTemplateFilter />
      </SearchFilterCard>

      <TraderMarkOrderTeamplateTable />
    </div>
  );
};

const TraderMarkOrderTeamplatePage = () => {
  return (
    <TraderMarkOrderTeamplateContextProvider>
      <TraderMarkOrderTeamplate />
    </TraderMarkOrderTeamplateContextProvider>
  );
};
export default TraderMarkOrderTeamplatePage;
