import { ColumnProps } from "antd/es/table";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../../../general/table-components/table";

import moment from "moment";
import IncidentsLicensingsContext from "../../../../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/context";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../../../models/patents-collection/patents-reservation/enum";
import { IPatentsReservation } from "../../../../../../models/patents-collection/patents-reservation/response";
import Tag from "../../../../../general/antd/tag";
import {
  getPatentsColor,
  getPatentsType,
} from "../../../../../../models/patents-collection/patents/enum";
import GrievancesContext from "../../../../../../context/lawswits-collection/grievance/grievance/context";
interface Props {}
const PatentsTable: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details } = useContext(GrievancesContext);

  const columns: ColumnProps<IPatentsReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { patent }) => {
        return <p> {patent?.name ?? "-"} </p>;
      },
    },
    {
      title: t("patents_or_model"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record?.patent?.type ? (
              <Tag
                color={getPatentsColor(record?.patent.type)}
                title={t(getPatentsType(record.patent?.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getPatentsReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },

    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },
    {
      title: t("last_annual_fee_date"),
      dataIndex: "lastAnnualFeeDate",
      align: "center",
      key: "lastAnnualFeeDate",
      render: (_, { lastAnnualFeeDate }) => {
        return (
          <p>
            {lastAnnualFeeDate
              ? moment(lastAnnualFeeDate).format("DD/MM/YYYY")
              : "-"}
          </p>
        );
      },
    },
    {
      title: t("last_annual_fee_number"),
      dataIndex: "lastAnnualFeeNumber",
      align: "center",
      key: "lastAnnualFeeNumber",
    },

    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}
          </p>
        );
      },
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      align: "center",
      key: "registrationNumber",
    },
  ];
  return (
    <Table<any>
      rowKey="id"
      style={{ height: "100%" }}
      columns={columns}
      size="small"
      dataSource={details?.patentReservations ?? []}
    />
  );
};

export default PatentsTable;
