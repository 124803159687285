import {
  Popconfirm as AntdPopconfirm,
  PopconfirmProps as AntdPopconfirmProps,
} from 'antd'
import { useContext } from 'react'
import AppContext from '../../../../context/app/context'

export interface PopconfirmProps extends AntdPopconfirmProps {}

const Popconfirm: React.FC<PopconfirmProps> = (props) => {
  const { direction } = useContext(AppContext)

  return (
    <AntdPopconfirm
      placement={direction === 'ltr' ? 'leftBottom' : 'rightBottom'}
      {...props}
    ></AntdPopconfirm>
  )
}

export default Popconfirm
