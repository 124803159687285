import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DataRequestContext from "../../../../context/independent_requests-collection/data-request/context";
import DataRequestContextProvider from "../../../../context/independent_requests-collection/data-request/provider";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import PageDetails from "../../../general/details";
import PaymentRequestTable from "../../clients-payments/client-payment-table";
import PaymentRequestContextProvider from "../../../../context/independent_requests-collection/request-payments/provider";
import CostRequesTable from "../../office-cost/office-cost-table";
import CostRequestContextProvider from "../../../../context/independent_requests-collection/request-costs/provider";
import moment from "moment";
interface Props {}
const DataRequestDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(DataRequestContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"data_request"}`}
        buttonName={`${"update_data_request"}`}
        onClick={() => navigate(`/data-request/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            {" "}
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("details_data_request")} key="1">
                    <Descriptions size="small" column={1} bordered>
                      <AntdDescriptions.Item label={t("id")}>
                        {details?.id}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client")}>
                        {details?.client?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("country")}>
                        {details?.country?.name}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("depositNumber")}>
                        {details?.depositNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("official_date")}>
                        {details?.officialDate}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("financialReceiptNumber")}
                      >
                        {details?.financialReceiptNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("dateFinancialReceipt")}>
                        {details?.dateFinancialReceipt}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("order_value")}>
                        {details?.orderValue}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client_payments")}>
                        {details?.sumPayments}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("sum_costs")}>
                        {details?.sumCosts}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("created_at")}>
                        {moment(details?.createdAt).format("DD/MM/YYYY")}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("note")}>
                        {details?.note ? details?.note : "-"}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("data")}>
                        {details?.data ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: details?.data }}
                          />
                        ) : (
                          "-"
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("extractedFrom")}>
                        {details?.extractedFrom ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: details?.extractedFrom,
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("restrictionsOn")}>
                        {details?.restrictionsOn ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: details?.restrictionsOn,
                            }}
                          />
                        ) : (
                          "-"
                        )}
                      </AntdDescriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("client_payments")} key={1}>
                    <PaymentRequestTable requestType="App\Models\DataRequest" />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("office_cost")} key={1}>
                    <CostRequesTable requestType="App\Models\DataRequest" />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </PageDetails>
    </>
  );
};

const DataRequestDetailsPage = () => {
  return (
    <DataRequestContextProvider>
      <PaymentRequestContextProvider>
        <CostRequestContextProvider>
          <DataRequestDetails />
        </CostRequestContextProvider>
      </PaymentRequestContextProvider>
    </DataRequestContextProvider>
  );
};
export default DataRequestDetailsPage;
