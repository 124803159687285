import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import {
  ICreateDataRequest,
  IExportDataRequestExcel,
  IUpdateDataRequest,
} from "../../../models/independent-requests-collection/data-request/request";
import { IDataRequestQuery } from "../../../models/independent-requests-collection/data-request/query";
import {
  IDataRequest,
  IDataRequestDetails,
} from "../../../models/independent-requests-collection/data-request/response";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class DataRequestService<SecurityDataRequestType = unknown> {
  http: HttpClient<SecurityDataRequestType>;

  constructor(http: HttpClient<SecurityDataRequestType>) {
    this.http = http;
  }

  getAllDataRequest = (query: IDataRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDataRequest>>({
      path: `/data-request`,
      method: "GET",
      query,
      ...params,
    });

  getDataRequest = (id: number, params?: RequestParams) =>
    this.http.request<IDataRequestDetails>({
      path: `/data-request/${id}`,
      method: "GET",
      ...params,
    });

  createDataRequest = (data: ICreateDataRequest, params?: RequestParams) =>
    this.http.request<IDataRequest>({
      path: "/data-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateDataRequest = (
    id: number,
    data: IUpdateDataRequest,
    params?: RequestParams
  ) =>
    this.http.request<IDataRequest>({
      path: `/data-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDataRequest = (id: number, params?: RequestParams) =>
    this.http.request<IDataRequest>({
      path: `/data-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportDataRequestExcel, query: IDataRequestQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/data-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default DataRequestService;
