import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import PatentsFilter from "../../../components/patents-managment/patents/patents-filter";
import PatentsTable from "../../../components/patents-managment/patents/patents-table";
import AppContext from "../../../context/app/context";
import PatentsReservationContextProvider from "../../../context/patents-collection/patents-reservation/provider";
import PatentsContext from "../../../context/patents-collection/patents/context";
import PatentsContextProvider from "../../../context/patents-collection/patents/provider";
import {
  IExportPatentsExcel,
  PatentsCloumms,
} from "../../../models/patents-collection/patents/request";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
interface IProps {
  children?: React.ReactNode;
}

const Pations: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(PatentsContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("patents_and_utility_models")}
        subTitle={t("patents_and_utility_models_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/patents-and-utility-models/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_patents_and_utility_models")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="patents-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <PatentsFilter />
      </SearchFilterCard>
      <PatentsTable />

      <ExportToExcelModal
        id="export-users"
        title={t("export_users_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="40vw"
        defaultValues={
          {
            country: false,
            nameAr: false,
            nameEn: false,
            type: false,
          } as IExportPatentsExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={PatentsCloumms} />
      </ExportToExcelModal>
    </div>
  );
};

const PationsPage = () => {
  return (
    <PatentsContextProvider>
      <PatentsReservationContextProvider>
        <Pations />
      </PatentsReservationContextProvider>
    </PatentsContextProvider>
  );
};
export default PationsPage;
