import AuthService from "./auth";
import ClientService from "./client";
import CommercialRecordsService from "./commercial-records";
import CopyrightClientPaymentService from "./copyright-collection/client-payment";
import CopyrightService from "./copyright-collection/copyright";
import RecordCopyrightService from "./copyright-collection/copyright-record";
import CopyrightRequestService from "./copyright-collection/copyright-request";
import CopyrightReservationService from "./copyright-collection/copyright-reservation";
import CopyrightOfficeCostService from "./copyright-collection/office-cost";
import CopyrightRequsetfileService from "./copyright-collection/request-files";
import DrawingClientPaymentService from "./drawing-collection/client-payment";
import DrawingService from "./drawing-collection/drawing";
import RecordDrawingService from "./drawing-collection/drawing-record";
import DrawingRequestService from "./drawing-collection/drawing-request";
import DrawingReservationService from "./drawing-collection/drawing-reservation";
import DrawingOfficeCostService from "./drawing-collection/office-cost";
import DrawingRequsetfileService from "./drawing-collection/request-files";
import { httpclient } from "./http-client";
import ClientIncidentService from "./incidents-collection/incidents-client-requset/client-incident";
import IncidentsClientsPaymentService from "./incidents-collection/incidents-client-requset/client-payment";
import IncidentsOfficeCostService from "./incidents-collection/incidents-client-requset/office-cost";
import IncidentRequsetfileService from "./incidents-collection/incidents-client-requset/request-files";
import IncidentsLicensingPaymentService from "./incidents-collection/incidents-licensing/client-payment";
import LicensingIncidentService from "./incidents-collection/incidents-licensing/licensing-incident";
import IncidentsLicensingOfficeCostService from "./incidents-collection/incidents-licensing/office-cost";
import IncidentLicensingRequsetfileService from "./incidents-collection/incidents-licensing/request-files";
import IncidentsWaiverPaymentService from "./incidents-collection/incidents-waiver/client-payment";
import IncidentsWaivesOfficeCostService from "./incidents-collection/incidents-waiver/office-cost";
import IncidentWaiverRequsetfileService from "./incidents-collection/incidents-waiver/request-files";
import WaiverIncidentService from "./incidents-collection/incidents-waiver/waiver-incident";
import AgentChangeService from "./independent-requests-collection/agent-change";
import AgentRegistrationService from "./independent-requests-collection/agent-registration";
import DataRequestService from "./independent-requests-collection/data-request";
import DisclosureTrademarkService from "./independent-requests-collection/disclosure-trademark";
import DisclosureTrademarkRequestService from "./independent-requests-collection/disclosure-trademark-request";
import ObjectionRequestService from "./independent-requests-collection/objection-request";
import RequestCostService from "./independent-requests-collection/request-cost";
import CertifiedCopyService from "./independent-requests-collection/request-for-certified-copy";
import RequestPaymentService from "./independent-requests-collection/request-payments";
import WiverRequestService from "./independent-requests-collection/waiver-request";
import CommercialPaymentService from "./lawswits-collection/commercial/client-payment";
import CommercialService from "./lawswits-collection/commercial/commercial-request";
import CommercialOfficeCostService from "./lawswits-collection/commercial/office-cost";
import CommercialRequsetfileService from "./lawswits-collection/commercial/request-files";
import GrievancePaymentService from "./lawswits-collection/grievance/client-payment";
import GrievanceService from "./lawswits-collection/grievance/grievance-request";
import GrievanceOfficeCostService from "./lawswits-collection/grievance/office-cost";
import GrievanceRequsetfileService from "./lawswits-collection/grievance/request-files";
import CategoryService from "./managment-collection/categories";
import CostTypeService from "./managment-collection/cost-type";
import CountryService from "./managment-collection/countries";
import JopTitleService from "./managment-collection/jop-title";
import NationalityService from "./managment-collection/nationality";
import NodesNameService from "./managment-collection/nodes-name";
import RoleService from "./managment-collection/role";
import UserService from "./managment-collection/user";
import NodeService from "./node";
import NotifcationService from "./notifications";
import PantetsClientPaymentService from "./patents-collection/client-payment";
import PatentsOfficeCostService from "./patents-collection/office-cost";
import Patentservice from "./patents-collection/patents";
import RecordPatentsService from "./patents-collection/patents-record";
import PatentsRequestService from "./patents-collection/patents-request";
import PatentsReservationService from "./patents-collection/patents-reservation";
import PatentsRequsetfileService from "./patents-collection/request-files";
import ReportService from "./report";
import StatisticsService from "./statistics";
import ClientPaymentService from "./trademark-collection/client-payment";
import OfficeCostService from "./trademark-collection/office-cost";
import TrademarkService from "./trademark-collection/trademark";
import RecordTrademarkService from "./trademark-collection/trademark-record";
import TrademarkRequestService from "./trademark-collection/trademark-request";
import TrademarkRequsetfileService from "./trademark-collection/trademark-request-files";
import TrademarkReservationService from "./trademark-collection/trademark-reservation";
import TraderMarkOrderTeamplateService from "./trademark-collection/treadmark-order-template";

export default class EndPoints {
  public static auth = new AuthService(httpclient);
  public static user = new UserService(httpclient);
  public static jopTitle = new JopTitleService(httpclient);
  public static country = new CountryService(httpclient);
  public static role = new RoleService(httpclient);
  public static costType = new CostTypeService(httpclient);
  public static category = new CategoryService(httpclient);
  public static client = new ClientService(httpclient);
  public static trademark = new TrademarkService(httpclient);
  public static trademarkReservation = new TrademarkReservationService(
    httpclient
  );
  public static trademarkOrderTemplate = new TraderMarkOrderTeamplateService(
    httpclient
  );
  public static trademarkRecord = new RecordTrademarkService(httpclient);
  public static node = new NodeService(httpclient);
  public static trademarkRequest = new TrademarkRequestService(httpclient);
  public static nodeName = new NodesNameService(httpclient);
  public static nationality = new NationalityService(httpclient);
  public static clientPayment = new ClientPaymentService(httpclient);
  public static officeCost = new OfficeCostService(httpclient);

  public static trademarkRequestFile = new TrademarkRequsetfileService(
    httpclient
  );
  public static statistics = new StatisticsService(httpclient);
  public static notification = new NotifcationService(httpclient);

  // drawing
  public static drawing = new DrawingService(httpclient);
  public static drawingReservation = new DrawingReservationService(httpclient);
  public static drawingRequest = new DrawingRequestService(httpclient);
  public static drawingRequestFile = new DrawingRequsetfileService(httpclient);
  public static drawingOfficeCost = new DrawingOfficeCostService(httpclient);
  public static drawingClientPayment = new DrawingClientPaymentService(
    httpclient
  );
  public static drawingRecord = new RecordDrawingService(httpclient);

  // patents
  public static patents = new Patentservice(httpclient);
  public static patentsReservation = new PatentsReservationService(httpclient);
  public static patentRequest = new PatentsRequestService(httpclient);
  public static pantetsClientPayment = new PantetsClientPaymentService(
    httpclient
  );
  public static patentsOfficeCost = new PatentsOfficeCostService(httpclient);
  public static patentsRequsetfile = new PatentsRequsetfileService(httpclient);
  public static patentsRecord = new RecordPatentsService(httpclient);
  // copyright
  public static copyright = new CopyrightService(httpclient);
  public static copyrightReservation = new CopyrightReservationService(
    httpclient
  );
  public static copyrightClientPayment = new CopyrightClientPaymentService(
    httpclient
  );
  public static copyrightOfficeCost = new CopyrightOfficeCostService(
    httpclient
  );
  public static copyrightRequsetfile = new CopyrightRequsetfileService(
    httpclient
  );
  public static copyrightRequest = new CopyrightRequestService(httpclient);
  public static copyrightRecord = new RecordCopyrightService(httpclient);
  // independent requests
  public static certifiedCopy = new CertifiedCopyService(httpclient);
  public static agentRegistration = new AgentRegistrationService(httpclient);
  public static agentChange = new AgentChangeService(httpclient);
  public static dataRequest = new DataRequestService(httpclient);
  public static disclosureTrademarkRequest =
    new DisclosureTrademarkRequestService(httpclient);
  public static disclosureTrademark = new DisclosureTrademarkService(
    httpclient
  );
  public static wiverRequest = new WiverRequestService(httpclient);
  public static paymentRequest = new RequestPaymentService(httpclient);
  public static costRequest = new RequestCostService(httpclient);
  // incident clients
  public static clientIncident = new ClientIncidentService(httpclient);
  public static incidentsClientsPayment = new IncidentsClientsPaymentService(
    httpclient
  );
  public static incidentsOfficeCost = new IncidentsOfficeCostService(
    httpclient
  );
  public static incidentRequsetfile = new IncidentRequsetfileService(
    httpclient
  );

  // incidents waiver
  public static waiverIncident = new WaiverIncidentService(httpclient);
  public static incidentsWaiverClientsPayment =
    new IncidentsWaiverPaymentService(httpclient);
  public static incidentsWaivesOfficeCost =
    new IncidentsWaivesOfficeCostService(httpclient);

  public static incidentWaiverRequsetfile =
    new IncidentWaiverRequsetfileService(httpclient);

  // incidents Licensing
  public static licensingIncident = new LicensingIncidentService(httpclient);
  public static licensingsClientsPayment = new IncidentsLicensingPaymentService(
    httpclient
  );
  public static incidentsLicensingOfficeCost =
    new IncidentsLicensingOfficeCostService(httpclient);

  public static incidentLicensingRequsetfile =
    new IncidentLicensingRequsetfileService(httpclient);

  // lawsuites grievance

  public static grievance = new GrievanceService(httpclient);
  public static grievanceClientPayment = new GrievancePaymentService(
    httpclient
  );
  public static grievanceOfficeCost = new GrievanceOfficeCostService(
    httpclient
  );

  public static grievanceRequsetfile = new GrievanceRequsetfileService(
    httpclient
  );

  // lawsuites Commercial

  public static commercial = new CommercialService(httpclient);
  public static commercialClientPayment = new CommercialPaymentService(
    httpclient
  );
  public static commercialOfficeCost = new CommercialOfficeCostService(
    httpclient
  );

  public static commercialRequsetfile = new CommercialRequsetfileService(
    httpclient
  );
  public static objectionRequest = new ObjectionRequestService(httpclient);
  public static report = new ReportService(httpclient);
  public static commercialRecord = new CommercialRecordsService(httpclient);
}
