export enum MediumType {
  Image = 1,
  File = 2,
}

export enum MediumFor {
  UserProfile = "user-profile",
  CLIENT_COMMERCIAL = "client-commercial",
  CLIENT_AUTHORIZATION = "client-authorization",
  NODE_ICON = "node-icon",
  TRADEMARK_REQUEST_FILE = "trademark-request-file",
  TRADEMARK_ICON = "trademark-icon",
  DRAWING_REQUEST_FILE = "drawing-request-file",
  DRAWING_ICON = "drawing-icon",
  DRAWING_PHOTOS = "drawing-photos",
  PATENT_FILE = "patent-icon",
  PATENT_REQUEST_FILE = "patent-request-file",
  COPYRIGHT_FILE = "copyright-icon",
  COPYRIGHT_REQUEST_FILE = "copyright-request-file",
}
