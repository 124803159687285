import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../context/app/context";
import { IClient } from "../../../../models/client/response";
import { ICountry } from "../../../../models/managent-collection/user/response";
import EndPoints from "../../../../services/end-points";
import { INITIAL_PAGE } from "../../../../utils/helpers/constants";
import FieldBuilder from "../../../form-components/field-builder";
import Form from "../../../form-components/from";
import AgentChangeContext from "../../../../context/independent_requests-collection/agent-change/context";
import DataRequestContext from "../../../../context/independent_requests-collection/data-request/context";

interface IProps {}

const DataRequestFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(DataRequestContext);
  const { direction } = useContext(AppContext);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const type = [1, 2];

  // get all clients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, [direction]);

  // get all countries from api
  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountries({});
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, [direction]);
  return (
    <Form
      formId="agent-change-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.country
              ? {
                  name: "countryId",
                  value: data.country,
                  operation: "eq",
                }
              : undefined,
            data.OfficialDateBetween != undefined &&
            data.OfficialDateBetween[0] != "" &&
            data.OfficialDateBetween[1] != ""
              ? {
                  name: "OfficialDateBetween",
                  value: data?.OfficialDateBetween,
                }
              : undefined,
            data?.client
              ? {
                  name: "clientId",
                  value: data.client,
                  operation: "eq",
                }
              : undefined,
            data.dateFinancialReceiptBetween != undefined &&
            data.dateFinancialReceiptBetween[0] != "" &&
            data.dateFinancialReceiptBetween[1] != ""
              ? {
                  name: "dateFinancialReceiptBetween",
                  value: data?.dateFinancialReceiptBetween,
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="country"
            label={`${t("country")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: countriesLoading,
              options: countries?.map((country, index) => {
                return {
                  label: country.name,
                  value: country.id as number,
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="client"
            label={`${t("clients")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: clientsLoading,
              options: clients?.map((client, index) => {
                return {
                  label: client.name,
                  value: client.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="OfficialDateBetween"
            label={`${t("official_date")}`}
            input={{ type: "range-picker", format: "YYYY-MM-DD" }}
            rules={{ required: false }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="dateFinancialReceiptBetween"
            label={`${t("dateFinancialReceipt")}`}
            input={{ type: "range-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        {/* <Col span={8}>
          <FieldBuilder
            name="officialDate"
            label={`${t("official_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col> */}
        {/* <Col span={8}>
          <FieldBuilder
            name="dateFinancialReceipt"
            label={`${t("dateFinancialReceipt")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col> */}
      </Row>
    </Form>
  );
};

export default DataRequestFilter;
