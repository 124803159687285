export interface ICreateCommercial {
  clientCost: string;
  basisNumber: string;
  description: string;
  basisDate: string;
  lawsuitsType: number;
  claimType: number;
  status: number;
  workflowId: number;
  opponent: string;
  clients: number[];
  trademarkReservations: number[];
  drawingReservations: number[];
  patentReservations: number[];
  copyrightReservations: number[];
}

export interface IUpdateCommercial extends ICreateCommercial {
  country?: number;
}

// to do
export interface IExportCommercialExcel {
  basisNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  basisDate: boolean;
  clientCost: boolean;
  clients: boolean;
  description: boolean;
  clientPayment: boolean;
  claimType: boolean;
  lawsuitsType: boolean;
  opponent: boolean;
}

export const CommercialCloumms = [
  "basisNumber",
  "Country",
  "typeWorkflow",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "basisDate",
  "clientCost",
  "clients",
  "description",
  "clientPayment",
  "claimType",
  "lawsuitsType",
  "opponent",
];
