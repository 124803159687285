import { createContext } from "react";
import { ITrademarkRequestQuery } from "../../../models/trademark-collection/trademark-request/query";
import {
  ITrademarkRequest,
  ITrademarkRequestDetails,
} from "../../../models/trademark-collection/trademark-request/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  ICreateTrademarkRequest,
  IUpdateTrademarkRequest,
} from "../../../models/trademark-collection/trademark-request/request";

export type TrademarkRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "pdf"
  | "pdfex"
  | "pdfde"
  | "pdfdeex"
  | "word";

export interface IInternalState {
  loading: TrademarkRequestLoading[];

  list?: IBaseListingResponse<ITrademarkRequest>;
  query: ITrademarkRequestQuery;

  details?: ITrademarkRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ITrademarkRequestDetails) => void;

    createTrademarkRequest: (request: ICreateTrademarkRequest) => void;
    updateTrademarkRequest: (
      id: number,
      request: IUpdateTrademarkRequest
    ) => void;
    deleteRequest: (id: number) => void;
    addReservationToTrademark: (id: number, request: any) => void;
    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    exportPdfExternal: (id: number) => any;
    exportDepositTemplatePdf: (id: number) => any;
    exportDepositTemplatePdfExternal: (id: number) => any;
    exportWord: (id: number, type: number) => any;
    exportDepositWord: (id: number, type: number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: ITrademarkRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    exportPdfExternal: DEFAULT_FUNCTION,
    exportDepositTemplatePdf: DEFAULT_FUNCTION,
    exportDepositTemplatePdfExternal: DEFAULT_FUNCTION,
    exportWord: DEFAULT_FUNCTION,
    exportDepositWord: DEFAULT_FUNCTION,
    createTrademarkRequest: DEFAULT_FUNCTION,
    updateTrademarkRequest: DEFAULT_FUNCTION,
    deleteRequest: DEFAULT_FUNCTION,

    addReservationToTrademark: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const TrademarkRequestContext = createContext(externalState);

export default TrademarkRequestContext;
