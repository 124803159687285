import {
  CAN_VIEW_AGENT_CHANGE_REQUESTS,
  CAN_VIEW_AGENT_REGISTRATION_REQUESTS,
  CAN_VIEW_CATEGORY,
  CAN_VIEW_CERTIFIED_COPY_REQUESTS,
  CAN_VIEW_CLIENTS,
  CAN_VIEW_COMMERCIAL_LAWSUITS_REQUEST_CLIENT,
  CAN_VIEW_COPYRIGHT,
  CAN_VIEW_COPYRIGHT_REQUEST,
  CAN_VIEW_COST_TYPE,
  CAN_VIEW_COUNTRY,
  CAN_VIEW_DATA_REQUEST,
  CAN_VIEW_DISCLOSURE_TRADEMARK,
  CAN_VIEW_DRAWINGS,
  CAN_VIEW_DRAWING_REQUESTS,
  CAN_VIEW_GRIEVANCE_LAWSUITS_REQUEST,
  CAN_VIEW_HOME_PAGE,
  CAN_VIEW_INCIDENT_CLIENT_REQUEST,
  CAN_VIEW_INCIDENT_LICENSING_REQUEST,
  CAN_VIEW_INCIDENT_WAIVER_REQUEST,
  CAN_VIEW_JOB_TITLE,
  CAN_VIEW_NATIONALITY,
  CAN_VIEW_NODE_NAME,
  CAN_VIEW_PATENTS,
  CAN_VIEW_PATENT_REQUESTS,
  CAN_VIEW_ROLE,
  CAN_VIEW_TRADEMARKS,
  CAN_VIEW_TRADEMARK_REQUESTS,
  CAN_VIEW_USERS,
  CAN_VIEW_WAIVER_REQUEST,
  CAN_VIEW_WORKFLOWS,
  CAN_VIEW_OBJECTION_REQUEST,
  CAN_VIEW_REPORTS,
  CAN_VIEW_COMMERCIAL_RECORD
} from "./utils/rbac/permissions";

import { FaHome, FaUserEdit, FaUserTie } from "react-icons/fa";
import { GoArchive } from "react-icons/go";
import ClientDetailsPage from "./components/client/client-details/idex";
import ClientForm from "./components/client/client-form";
import CopyrightRequsetDetailsPage from "./components/copyright-managment/copyright-request/copyright-request-details";
import CopyrightRequestsForm from "./components/copyright-managment/copyright-request/copyright-request-form";
import CopyrightReservationDetailesPage from "./components/copyright-managment/copyright-reservation/copyright-reservation-detalis";
import CopyrightDetailsPage from "./components/copyright-managment/copyright/copyright-details";
import CopyrightForm from "./components/copyright-managment/copyright/copyright-form";
import DrawingRequsetDetailsPage from "./components/drawing-managmet/drawing-requests/drawing-request/drawing-request-details";
import DrawingRequestsForm from "./components/drawing-managmet/drawing-requests/drawing-request/drawing-request-form";
import DrawingReservationDetailesPage from "./components/drawing-managmet/drawing-reservation/drawing-reservation-detalis";
import DrawingDetailsPage from "./components/drawing-managmet/drawing/drawing-details";
import DrawingForm from "./components/drawing-managmet/drawing/drawing-form";
import AgentChangeRequestDetailsPage from "./components/independent-request-managment/agent-change-request/details";
import AgentChageRequestForm from "./components/independent-request-managment/agent-change-request/from";
import AgentRegistrationRequestDetailsPage from "./components/independent-request-managment/agent-registration-request/details";
import AgentRegistrationRequestForm from "./components/independent-request-managment/agent-registration-request/from";
import DataRequestDetailsPage from "./components/independent-request-managment/data-request/details";
import DataRequestForm from "./components/independent-request-managment/data-request/from";
import DisclosureTrademarkRequestDetailsPage from "./components/independent-request-managment/disclosure-trademark-request/details";
import DisclosureTrademarkRequestForm from "./components/independent-request-managment/disclosure-trademark-request/from";
import RequestCertifiedCopyDetailsPage from "./components/independent-request-managment/request-for-certified-copy/details";
import RequestCertifiedCopyForm from "./components/independent-request-managment/request-for-certified-copy/from";
import WiverRequestDetailsPage from "./components/independent-request-managment/waiver-request/details";
import WiverRequestForm from "./components/independent-request-managment/waiver-request/from";
import CategoryDetailsPage from "./components/managment-collection/category/category-details";
import CategoryForm from "./components/managment-collection/category/category-form";
import SubCategoryForm from "./components/managment-collection/category/sub-category/sub-category-index";
import CostTypeDetailesPage from "./components/managment-collection/cost-type/cost-type-details";
import CostTypeForm from "./components/managment-collection/cost-type/cost-type-form";
import CountryDetailsPage from "./components/managment-collection/countries/country-details";
import ContryForm from "./components/managment-collection/countries/country-form";
import JopTitleDetailesPage from "./components/managment-collection/jop-title/jop-title-details";
import JopTitleForm from "./components/managment-collection/jop-title/jop-title-form";
import NodesNameDetailsPage from "./components/managment-collection/nodes-name/nodes-name-details";
import NodesNameForm from "./components/managment-collection/nodes-name/nodes-name-form";
import RoleDetailesPage from "./components/managment-collection/role/role-details";
import RoleForm from "./components/managment-collection/role/role-form";
import UserDetailsPage from "./components/managment-collection/user/user-details";
import UserForm from "./components/managment-collection/user/user-form";
import PatentsRequsetDetailsPage from "./components/patents-managment/patents-requests/patents-request/patents-request-details";
import PatentsRequestsForm from "./components/patents-managment/patents-requests/patents-request/patents-request-form";
import PatentsReservationDetailesPage from "./components/patents-managment/patents-reservations/patents-reservation-detalis";
import PatentsDetailsPage from "./components/patents-managment/patents/patents-details";
import PationsForm from "./components/patents-managment/patents/patents-form";
import TrademarkRequsetDetailsPage from "./components/trademark-collection/trademark-request/trademark-requests/trademark-request-details";
import TrademarkRequestsForm from "./components/trademark-collection/trademark-request/trademark-requests/trademark-request-form";
import TrademarkDetailesPage from "./components/trademark-collection/trademark/trademark-details";
import TrademarkForm from "./components/trademark-collection/trademark/trademark-form";
import TrademarkReservationDetailesPage from "./components/trademark-collection/trademark/trademark-reservation/trademark-reservation-detalis";
import TrademarkOrderTemplateTreePage from "./components/workflow/trademark-order-template-tree";
import TraderMarkOrderTeamplateDetailsPage from "./components/workflow/tradermark-order-teamplate-details";
import TraderMarkOrderTeamplateForm from "./components/workflow/tradermark-order-teamplate-form";
import ClientPage from "./pages/clients";
import CopyrightPage from "./pages/copyright-managment/copyright";
import CopyrightRequestsPage from "./pages/copyright-managment/copyright-requests";
import DrawingPage from "./pages/drawing-collection/drawing";
import DrawingRequestsPage from "./pages/drawing-collection/drawing-requests";
import Home from "./pages/home";
import AgentChangeRequestPage from "./pages/independent-requests-managment/agent-change-request";
import AgentRegistrationRequestPage from "./pages/independent-requests-managment/agent-registration-request";
import DataRequestPage from "./pages/independent-requests-managment/data-request";
import DisclosureTrademarkRequestPage from "./pages/independent-requests-managment/disclosure-trademark-request";
import RequestCertifiedCopyPage from "./pages/independent-requests-managment/request-certified-copy";
import WiverRequestPage from "./pages/independent-requests-managment/waiver-request";
import CategoryPage from "./pages/managment-collection/categories";
import CostTypePage from "./pages/managment-collection/cost-type";
import CountryPage from "./pages/managment-collection/countries";
import JopTitlePage from "./pages/managment-collection/jop-title";
import NodesNamePage from "./pages/managment-collection/nodes-name";
import RolePage from "./pages/managment-collection/role-and-permissions";
import UsersPage from "./pages/managment-collection/users";
import {
  default as IncidentsClientsPage,
} from "./pages/incidents-managments/incidents-clients";
import PatentsRequestsPage from "./pages/patents-collection/patents-requests";
import PationsPage from "./pages/patents-collection/pations";
import TrademarkRequestsPage from "./pages/trademark-collection/trademark-requests";
import TrademarkPage from "./pages/trademark-collection/trademarks";
import TraderMarkOrderTeamplatePage from "./pages/workflow";
import IncidentsClientForm from "./components/incidents-managment/incidents-client/incidents-client-requset/incidents-clients-form";
import IncidentsClientsDetailsPage from "./components/incidents-managment/incidents-client/incidents-client-requset/incidents-clients-details";
import IncidentsWaiverDetailsPage from "./components/incidents-managment/incidents-waver/incidents-waiver-request/incidents-waiver-details";
import IncidentsWaiverForm from "./components/incidents-managment/incidents-waver/incidents-waiver-request/incidents-waiver-form";
import IncidentsWaiverPage from "./pages/incidents-managments/incidents-waiver";
import IncidentsLicensingPage from "./pages/incidents-managments/incidents-licensing";
import IncidentsLicensingDetailsPage from "./components/incidents-managment/incidents-licensing/inicidents-licensing-request/incidents-licensing-details";
import IncidentsLicensingForm from "./components/incidents-managment/incidents-licensing/inicidents-licensing-request/incidents-licensing-form";
import GrievancePage from "./pages/lawswits-managment/grievance";
import GrievanceForm from "./components/lawsuits-managents/grievance/grivance-request/grievances-form";
import GrievanceFormDetailsPage from "./components/lawsuits-managents/grievance/grivance-request/grievances-details";
import CommercialPage from "./pages/lawswits-managment/commercial";
import CommercialForm from "./components/lawsuits-managents/commercial/commercial-request/commercial-form";
import CommercialFormDetailsPage from "./components/lawsuits-managents/commercial/commercial-request/commercial-details";
import NationalityForm from "./components/managment-collection/nationality/nationality-form";
import NationalityDetailsPage from "./components/managment-collection/nationality/nationality-details";
import NationalityPage from "./pages/managment-collection/nationality";
import ObjectionRequestPage from "./pages/independent-requests-managment/objection-request";
import ObjectionRequestForm from "./components/independent-request-managment/objection-request/from";
import ObjectionRequestDetailsPage from "./components/independent-request-managment/objection-request/details";
import { FileTextOutlined } from "@ant-design/icons";
import ReportPage from "./pages/report";
import CommercialRecordForm from "./components/commercial-records/commercial-record-form";
import CommercialRecordDetailsPage from "./components/commercial-records/commercial-record-details";
import CommercialRecordPage from "./pages/commercial-records";
// import { GiPayMoney, GiSplitArrows, GiTable } from 'react-icons/gi'

// import { FiLogIn, FiLogOut, FiSettings, FiTrash } from 'react-icons/fi'

export interface ICrudRoutes {
  path: string;
  component: React.ReactNode;
}
export interface ISubRoute {
  labelKey: string;
  icon?: JSX.Element;
  path: string;
  permissions: string[];
  component: any;
  crudRoutes?: ICrudRoutes[];
  exact?: boolean;
}

export interface IRoute {
  labelKey: string;
  icon: JSX.Element;
  path: string;
  crudRoutes?: ICrudRoutes[];

  hasSubMenus: boolean;
  subMenus?: ISubRoute[];

  permissions: string[];
  component: any;
  exact?: boolean;
}

export const routes: IRoute[] = [
  {
    labelKey: "home",
    icon: <FaUserTie />,
    path: "/",
    hasSubMenus: false,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <Home />,
  },
  {
    labelKey: "reports",
    icon: <FileTextOutlined />,
    path: "/reports",
    hasSubMenus: false,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <ReportPage />,
  },

  {
    labelKey: "managment",
    icon: <FaHome />,
    path: "/",
    hasSubMenus: true,
    permissions: [],
    component: <Home />,
    subMenus: [
      {
        labelKey: "users",
        icon: <FaUserTie />,
        path: "/users",
        permissions: [CAN_VIEW_USERS],
        component: <UsersPage />,
        crudRoutes: [
          {
            path: "update/:id",
            component: <UserForm />,
          },
          {
            component: <UserForm />,
            path: "create",
          },
          {
            component: <UserDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "job_titles",
        icon: <FaUserTie />,
        path: "/job_titles",
        permissions: [CAN_VIEW_JOB_TITLE],
        component: <JopTitlePage />,
        crudRoutes: [
          {
            component: <JopTitleForm />,
            path: "update/:id",
          },
          {
            component: <JopTitleForm />,
            path: "create",
          },
          {
            component: <JopTitleDetailesPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "types_of_costs",
        icon: <FaUserTie />,
        permissions: [CAN_VIEW_COST_TYPE],
        path: "/types-of-costs",
        crudRoutes: [
          {
            component: <CostTypeForm />,
            path: "update/:id",
          },
          {
            component: <CostTypeForm />,
            path: "create",
          },
          {
            component: <CostTypeDetailesPage />,
            path: "details/:id",
          },
        ],
        component: <CostTypePage />,
      },
      {
        labelKey: "categories",
        icon: <FaUserTie />,
        path: "/categories",
        crudRoutes: [
          {
            component: <CategoryForm />,
            path: "update/:id",
          },
          {
            component: <CategoryForm />,
            path: "create",
          },
          {
            component: <SubCategoryForm />,
            path: "create-sub-category/:id",
          },
          {
            component: <CategoryDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_CATEGORY],
        component: <CategoryPage />,
      },
      {
        labelKey: "permissins_and_roles",
        icon: <FaUserTie />,
        path: "/roles",
        crudRoutes: [
          {
            component: <RoleForm />,
            path: "update/:id",
          },
          {
            component: <RoleForm />,
            path: "create",
          },
          {
            component: <RoleDetailesPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_ROLE],
        component: <RolePage />,
      },
      {
        labelKey: "countries",
        icon: <FaUserTie />,
        path: "/countries",
        crudRoutes: [
          {
            component: <ContryForm />,
            path: "update/:id",
          },
          {
            component: <ContryForm />,
            path: "create",
          },
          {
            component: <CountryDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_COUNTRY],
        component: <CountryPage />,
      },
      {
        labelKey: "nodes",
        icon: <FaUserTie />,
        path: "/nodes-name",
        crudRoutes: [
          {
            component: <NodesNameForm />,
            path: "update/:id",
          },
          {
            component: <NodesNameForm />,
            path: "create",
          },
          {
            component: <NodesNameDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_NODE_NAME],
        component: <NodesNamePage />,
      },
      {
        labelKey: "nationalities",
        icon: <FaUserTie />,
        path: "/nationalities",
        crudRoutes: [
          {
            component: <NationalityForm />,
            path: "update/:id",
          },
          {
            component: <NationalityForm />,
            path: "create",
          },
          {
            component: <NationalityDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_NATIONALITY],
        component: <NationalityPage />,
      },
    ],
  },

  {
    labelKey: "clients_and_rights_management",
    icon: <FaUserEdit />,
    path: "/client-and-rights-managing",
    hasSubMenus: true,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <></>,
    subMenus: [
      {
        labelKey: "clients",
        icon: <FaUserTie />,
        path: "/clients",
        crudRoutes: [
          {
            component: <ClientForm />,
            path: "update/:id",
          },
          {
            component: <ClientForm />,
            path: "create",
          },
          {
            component: <ClientDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_CLIENTS],
        component: <ClientPage />,
      },
      {
        labelKey: "trademarks",
        icon: <FaUserTie />,
        path: "/trademarks",
        crudRoutes: [
          {
            component: <TrademarkForm />,
            path: "update/:id",
          },
          {
            component: <TrademarkForm />,
            path: "create",
          },
          {
            component: <TrademarkDetailesPage />,
            path: "details/:id",
          },
          {
            component: <TrademarkReservationDetailesPage />,
            path: "details/:id/resevation/:id",
          },
          {
            component: <TrademarkReservationDetailesPage />,
            path: "update/:id/resevation/:id",
          },
        ],
        permissions: [CAN_VIEW_TRADEMARKS],
        component: <TrademarkPage />,
      },
      {
        labelKey: "industrial_models_and_drawings",
        icon: <FaUserTie />,
        path: "/industrial-models-and-drawings",
        crudRoutes: [
          {
            component: <DrawingForm />,
            path: "update/:id",
          },
          {
            component: <DrawingForm />,
            path: "create",
          },
          {
            component: <DrawingReservationDetailesPage />,
            path: "details/:id/resevation/:id",
          },
          {
            component: <DrawingDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_DRAWINGS],
        component: <DrawingPage />,
      },
      {
        labelKey: "patents_and_utility_models",
        icon: <FaUserTie />,
        path: "/patents-and-utility-models",
        crudRoutes: [
          {
            component: <PationsForm />,
            path: "update/:id",
          },
          {
            component: <PationsForm />,
            path: "create",
          },
          {
            component: <PatentsReservationDetailesPage />,
            path: "details/:id/resevation/:id",
          },
          {
            component: <PatentsDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_PATENTS],
        component: <PationsPage />,
      },
      {
        labelKey: "copyright",
        icon: <FaUserTie />,
        path: "/copyright",
        crudRoutes: [
          {
            component: <CopyrightForm />,
            path: "update/:id",
          },
          {
            component: <CopyrightForm />,
            path: "create",
          },
          {
            component: <CopyrightReservationDetailesPage />,
            path: "details/:id/resevation/:id",
          },
          {
            component: <CopyrightDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_COPYRIGHT],
        component: <CopyrightPage />,
      },
      {
        labelKey: "commercial_records",
        icon: <FaUserTie />,
        path: "/commercial-records",
        crudRoutes: [
          {
            component: <CommercialRecordForm />,
            path: "update/:id",
          },
          {
            component: <CommercialRecordForm />,
            path: "create",
          },
          {
            component: <CommercialRecordDetailsPage />,
            path: "details/:id",
          },
        ],
        permissions: [CAN_VIEW_COMMERCIAL_RECORD],
        component: <CommercialRecordPage />,
      },
    ],
  },
  {
    labelKey: "templates_orders_management",
    icon: <FaUserEdit />,
    path: "/templates-orders-managing",
    hasSubMenus: true,
    permissions: [CAN_VIEW_WORKFLOWS],
    component: <></>,
    subMenus: [
      {
        labelKey: "orders_templates",
        icon: <FaUserTie />,
        path: "/trademark-order-template",
        crudRoutes: [
          {
            component: <TraderMarkOrderTeamplateForm />,
            path: "update/:id",
          },
          {
            component: <TraderMarkOrderTeamplateForm />,
            path: "create",
          },
          {
            component: <TraderMarkOrderTeamplateDetailsPage />,
            path: "details/:id",
          },
          {
            component: <TrademarkOrderTemplateTreePage />,
            path: "tree/:id",
          },
        ],
        permissions: [CAN_VIEW_WORKFLOWS],
        component: <TraderMarkOrderTeamplatePage />,
      },
    ],
  },
  {
    labelKey: "requests_management",
    icon: <FaUserEdit />,
    path: "/requests",
    hasSubMenus: true,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <TrademarkRequestsPage />,
    subMenus: [
      {
        labelKey: "trademark_requests",
        icon: <FaUserTie />,
        path: "/trademark-requests",
        permissions: [CAN_VIEW_TRADEMARK_REQUESTS],
        component: <TrademarkRequestsPage />,
        crudRoutes: [
          {
            component: <TrademarkRequestsForm />,
            path: "update/:id",
          },
          {
            component: <TrademarkRequestsForm />,
            path: "create",
          },
          {
            component: <TrademarkRequsetDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "drawing_orders",
        icon: <FaUserTie />,
        path: "/drawing-requests",
        permissions: [CAN_VIEW_DRAWING_REQUESTS],
        component: <DrawingRequestsPage />,
        crudRoutes: [
          {
            component: <DrawingRequestsForm />,
            path: "update/:id",
          },
          {
            component: <DrawingRequestsForm />,
            path: "create",
          },
          {
            component: <DrawingRequsetDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "patents_order",
        icon: <FaUserTie />,
        path: "/patents-requests",
        permissions: [CAN_VIEW_PATENT_REQUESTS],
        component: <PatentsRequestsPage />,
        crudRoutes: [
          {
            component: <PatentsRequestsForm />,
            path: "update/:id",
          },
          {
            component: <PatentsRequestsForm />,
            path: "create",
          },
          {
            component: <PatentsRequsetDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "copyright_request",
        icon: <FaUserTie />,
        path: "/copyright-requests",
        permissions: [CAN_VIEW_COPYRIGHT_REQUEST],
        component: <CopyrightRequestsPage />,
        crudRoutes: [
          {
            component: <CopyrightRequestsForm />,
            path: "update/:id",
          },
          {
            component: <CopyrightRequestsForm />,
            path: "create",
          },
          {
            component: <CopyrightRequsetDetailsPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },

  {
    labelKey: "incidents_management",
    icon: <FaUserEdit />,
    path: "/incidents-management",
    hasSubMenus: true,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <></>,
    subMenus: [
      {
        labelKey: "clients",
        icon: <FaUserTie />,
        path: "/incidents-clients",
        permissions: [CAN_VIEW_INCIDENT_CLIENT_REQUEST],
        component: <IncidentsClientsPage />,
        crudRoutes: [
          {
            component: <IncidentsClientForm />,
            path: "update/:id",
          },
          {
            component: <IncidentsClientForm />,
            path: "create",
          },
          {
            component: <IncidentsClientsDetailsPage />,
            path: "details/:id",
          },
        ],
      },

      {
        labelKey: "move_ownership",
        icon: <FaUserTie />,
        path: "/incidents-waiver",
        permissions: [CAN_VIEW_INCIDENT_WAIVER_REQUEST],
        component: <IncidentsWaiverPage />,
        crudRoutes: [
          {
            component: <IncidentsWaiverForm />,
            path: "update/:id",
          },
          {
            component: <IncidentsWaiverForm />,
            path: "create",
          },
          {
            component: <IncidentsWaiverDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "licensing",
        icon: <FaUserTie />,
        path: "/incidents-licensing",
        permissions: [CAN_VIEW_INCIDENT_LICENSING_REQUEST],
        component: <IncidentsLicensingPage />,
        crudRoutes: [
          {
            component: <IncidentsLicensingForm />,
            path: "update/:id",
          },
          {
            component: <IncidentsLicensingForm />,
            path: "create",
          },
          {
            component: <IncidentsLicensingDetailsPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },
  {
    labelKey: "lawsuits_management",
    icon: <FaUserEdit />,
    path: "/lawsuits-managing",
    hasSubMenus: true,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <></>,
    subMenus: [
      {
        labelKey: "grievance",
        icon: <FaUserTie />,
        path: "/grievance",
        permissions: [CAN_VIEW_GRIEVANCE_LAWSUITS_REQUEST],
        component: <GrievancePage />,
        crudRoutes: [
          {
            component: <GrievanceForm />,
            path: "update/:id",
          },
          {
            component: <GrievanceForm />,
            path: "create",
          },
          {
            component: <GrievanceFormDetailsPage />,
            path: "details/:id",
          },
        ],
      },

      {
        labelKey: "commercial",
        icon: <FaUserTie />,
        path: "/commercial",
        permissions: [CAN_VIEW_COMMERCIAL_LAWSUITS_REQUEST_CLIENT],
        component: <CommercialPage />,
        crudRoutes: [
          {
            component: <CommercialForm />,
            path: "update/:id",
          },
          {
            component: <CommercialForm />,
            path: "create",
          },
          {
            component: <CommercialFormDetailsPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },
  {
    labelKey: "independent_requests",
    icon: <FaUserEdit />,
    path: "/independent-requests",
    hasSubMenus: true,
    permissions: [CAN_VIEW_HOME_PAGE],
    component: <></>,
    subMenus: [
      {
        labelKey: "request_for_certified_copy",
        icon: <FaUserTie />,
        path: "/request-for-certified-copy",
        permissions: [CAN_VIEW_CERTIFIED_COPY_REQUESTS],
        component: <RequestCertifiedCopyPage />,
        crudRoutes: [
          {
            component: <RequestCertifiedCopyForm />,
            path: "update/:id",
          },
          {
            component: <RequestCertifiedCopyForm />,
            path: "create",
          },
          {
            component: <RequestCertifiedCopyDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "agent_registration_request",
        icon: <FaUserTie />,
        path: "/agent-registration-request",
        permissions: [CAN_VIEW_AGENT_REGISTRATION_REQUESTS],
        component: <AgentRegistrationRequestPage />,
        crudRoutes: [
          {
            component: <AgentRegistrationRequestForm />,
            path: "update/:id",
          },
          {
            component: <AgentRegistrationRequestForm />,
            path: "create",
          },
          {
            component: <AgentRegistrationRequestDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "agent_change_request",
        icon: <FaUserTie />,
        path: "/agent-change-request",
        permissions: [CAN_VIEW_AGENT_CHANGE_REQUESTS],
        component: <AgentChangeRequestPage />,
        crudRoutes: [
          {
            component: <AgentChageRequestForm />,
            path: "update/:id",
          },
          {
            component: <AgentChageRequestForm />,
            path: "create",
          },
          {
            component: <AgentChangeRequestDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "data_request",
        icon: <GoArchive />,
        path: "/data-request",
        permissions: [CAN_VIEW_DATA_REQUEST],
        component: <DataRequestPage />,
        crudRoutes: [
          {
            component: <DataRequestForm />,
            path: "update/:id",
          },
          {
            component: <DataRequestForm />,
            path: "create",
          },
          {
            component: <DataRequestDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "disclosure_trademark_request",
        icon: <FaUserTie />,
        path: "/disclosure-trademark-request",
        permissions: [CAN_VIEW_DISCLOSURE_TRADEMARK],
        component: <DisclosureTrademarkRequestPage />,
        crudRoutes: [
          {
            component: <DisclosureTrademarkRequestForm />,
            path: "update/:id",
          },
          {
            component: <DisclosureTrademarkRequestForm />,
            path: "create",
          },
          {
            component: <DisclosureTrademarkRequestDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "waiver_request",
        icon: <FaUserTie />,
        path: "/waiver-request",
        permissions: [CAN_VIEW_WAIVER_REQUEST],
        component: <WiverRequestPage />,
        crudRoutes: [
          {
            component: <WiverRequestForm />,
            path: "update/:id",
          },
          {
            component: <WiverRequestForm />,
            path: "create",
          },
          {
            component: <WiverRequestDetailsPage />,
            path: "details/:id",
          },
        ],
      },
      {
        labelKey: "objection_request",
        icon: <FaUserTie />,
        path: "/objection-request",
        permissions: [CAN_VIEW_OBJECTION_REQUEST],
        component: <ObjectionRequestPage />,
        crudRoutes: [
          {
            component: <ObjectionRequestForm />,
            path: "update/:id",
          },
          {
            component: <ObjectionRequestForm />,
            path: "create",
          },
          {
            component: <ObjectionRequestDetailsPage />,
            path: "details/:id",
          },
        ],
      },
    ],
  },
];
