export enum types {
  LITERARY = 1,
  ARTISTIC = 2,
  COMPUTERIZED = 3,
}

export const getCopyrightType = (type: types) => {
  switch (type) {
    case types.LITERARY:
      return "LITERARY";
    case types.ARTISTIC:
      return "ARTISTIC";
    case types.COMPUTERIZED:
      return "COMPUTERIZED";
    default:
      return "LITERARY";
  }
};

export const getCopyrightColor = (type: types) => {
  switch (type) {
    case types.LITERARY:
      return "orange";
    case types.ARTISTIC:
      return "green";
    case types.COMPUTERIZED:
      return "yellow";
    default:
      return "brown";
  }
};
export enum typesCountry {
  LOCAL = 1,
  FOREIGN = 2,
}

export const getCopyrightCountryType = (type: typesCountry) => {
  switch (type) {
    case typesCountry.LOCAL:
      return "local";
    case typesCountry.FOREIGN:
      return "foreign";
    default:
      return "local";
  }
};

export const getCopyrightCountryColor = (type: typesCountry) => {
  switch (type) {
    case typesCountry.LOCAL:
      return "blue";
    case typesCountry.FOREIGN:
      return "red";
    default:
      return "red";
  }
};
