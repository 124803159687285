import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Descriptions from "../../../general/antd/descriptions";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import PageDetails from "../../../general/details";
import WiverRequestContext from "../../../../context/independent_requests-collection/waiver-request/context";
import WiverRequestContextProvider from "../../../../context/independent_requests-collection/waiver-request/provider";
import {
  getBooleanWaiverRequestType,
  getWaiverRequestTypeColor,
  getWaiverStatusColor,
  getWaiverStatusType,
} from "../../../../models/independent-requests-collection/waiver-request/enum";
import moment from "moment";
import PaymentRequestContextProvider from "../../../../context/independent_requests-collection/request-payments/provider";
import PaymentRequestTable from "../../clients-payments/client-payment-table";
import CostRequesTable from "../../office-cost/office-cost-table";
import CostRequestContextProvider from "../../../../context/independent_requests-collection/request-costs/provider";
interface Props {}
const WiverRequestRequestDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(WiverRequestContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"waiver_request"}`}
        buttonName={`${"update_waiver_request"}`}
        onClick={() => navigate(`/waiver-request/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("details_waiver_request")} key="1">
                    <Descriptions size="small" column={1} bordered>
                      <AntdDescriptions.Item label={t("id")}>
                        {details?.id}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client")}>
                        {details?.client?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("clientFor")}>
                        {details?.clientFor?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("country")}>
                        {details?.country?.name}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("depositNumber")}>
                        {details?.depositNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("official_date")}>
                        {details?.officialDate}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("created_at")}>
                        {moment(details?.createdAt).format("d-m-y")}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("type_opreation")}>
                        <Tag
                          title={getBooleanWaiverRequestType(
                            Number(details?.type)
                          )}
                          color={getWaiverRequestTypeColor(
                            Number(details?.type)
                          )}
                        />
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("type_waiver")}>
                        <Tag
                          title={getWaiverStatusType(details?.status)}
                          color={getWaiverStatusColor(details?.status)}
                        />
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("financialReceiptNumber")}
                      >
                        {details?.financialReceiptNumber}
                      </AntdDescriptions.Item>
                      {details?.request?.workflow?.for === 1 ? (
                        <AntdDescriptions.Item label={t("trademark_request")}>
                          {details?.request?.id}
                        </AntdDescriptions.Item>
                      ) : (
                        <AntdDescriptions.Item label={t("drawing_request")}>
                          {details?.request?.id}
                        </AntdDescriptions.Item>
                      )}
                      <AntdDescriptions.Item label={t("legalFormPerson")}>
                        {details?.legalFormPerson}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("mailing_address")}>
                        {details?.address}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("dateFinancialReceipt")}>
                        {details?.dateFinancialReceipt}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("order_value")}>
                        {details?.orderValue}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client_payments")}>
                        {details?.sumPayments}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("sum_costs")}>
                        {details?.sumCosts}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("details")}>
                        {details?.details}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("note")}>
                        {details?.note}
                      </AntdDescriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("client_payments")} key={1}>
                    <PaymentRequestTable requestType="App\Models\WaiverRequest" />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("office_cost")} key={1}>
                    <CostRequesTable requestType="App\Models\WaiverRequest" />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </PageDetails>
    </>
  );
};

const WiverRequestDetailsPage = () => {
  return (
    <WiverRequestContextProvider>
      <PaymentRequestContextProvider>
        <CostRequestContextProvider>
          <WiverRequestRequestDetails />
        </CostRequestContextProvider>
      </PaymentRequestContextProvider>
    </WiverRequestContextProvider>
  );
};
export default WiverRequestDetailsPage;
