import { Col, Row } from "antd";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";
import Form from "../../form-components/from";
import ReportContext from "../../../context/report/context";
import { getTypes } from "../../../models/reoprt/enum";
import { ICountry } from "../../../models/managent-collection/user/response";
import EndPoints from "../../../services/end-points";

interface IProps {}

const ReportFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(ReportContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const type = [1 , 2 ,3,4,5,6,7,8,9];
  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, []);


  return (
    <Form
      formId="report-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.createdAt != undefined && data.createdAt[0] != ""
              ? {
                  name: "createdAt",
                  value: data.createdAt[0],
                  operation: "gte",
                }
              : undefined,
            data?.createdAt != undefined && data.createdAt[1] != ""
              ? {
                  name: "createdAt",
                  value: data.createdAt[1],
                  operation: "lte",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type_report")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: type.map((el, index) => {
                return {
                  label: t(getTypes(el) as string),
                  value: el as number,
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="createdAt"
            label={`${t("created_at")}`}
            input={{ type: "range-picker" }}
            rules={{ required: false }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ReportFilter;
