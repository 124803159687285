import { createContext } from "react";

import { IDrawingRequestQuery } from "../../../models/drawing-collection/drawing-request/query";
import {
  ICreateDrawingRequest,
  IUpdateDrawingRequest,
} from "../../../models/drawing-collection/drawing-request/request";
import {
  IDrawingRequest,
  IDrawingRequestDetails,
} from "../../../models/drawing-collection/drawing-request/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type DrawingRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "pdf"
  | "word";

export interface IInternalState {
  loading: DrawingRequestLoading[];

  list?: IBaseListingResponse<IDrawingRequest>;
  query: IDrawingRequestQuery;

  details?: IDrawingRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDrawingRequestDetails) => void;

    createDrawingRequest: (request: ICreateDrawingRequest) => void;
    updateDrawingRequest: (id: number, request: IUpdateDrawingRequest) => void;
    deleteRequest: (id: number) => void;

    addReservationToDrawing: (id: number, request: any) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    exportDepositTemplatePdf: (id: number) => any;
    exportWord: (id: number , type:number) => any;
    exportDepositWord: (id: number, type:number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createDrawingRequest: DEFAULT_FUNCTION,
    updateDrawingRequest: DEFAULT_FUNCTION,
    deleteRequest: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    exportDepositTemplatePdf: DEFAULT_FUNCTION,
    exportWord: DEFAULT_FUNCTION,
    exportDepositWord: DEFAULT_FUNCTION,
    addReservationToDrawing: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DrawingRequestContext = createContext(externalState);

export default DrawingRequestContext;
