import { Col, Popover, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IClient } from "../../../../models/client/response";
import { IUpdateCertifiedCopy } from "../../../../models/independent-requests-collection/certified-copy/request";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { MediumFor } from "../../../../models/medium/enum";
import EndPoints from "../../../../services/end-points";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import FileUploader from "../../../form-components/file-uploader";
import GeneralForm from "../../../form-components/general-form";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import Image from "../../../general/antd/image";
import styles from "./style.module.scss";
import { FaTrash } from "react-icons/fa";
import { getUrlImageFile } from "../../../../utils/helpers/check-type-file";
import { IMedium } from "../../../../models/medium/response";
import DataRequestContext from "../../../../context/independent_requests-collection/data-request/context";
import DataRequestContextProvider from "../../../../context/independent_requests-collection/data-request/provider";
import { IUpdateDataRequest } from "../../../../models/independent-requests-collection/data-request/request";
import ReactQuill, { Quill, Value } from "react-quill";
import "react-quill/dist/quill.snow.css";
import RichEditor from "../../../form-components/rich-text-editor";
interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(DataRequestContext);

  const { t } = useTranslation();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all CLients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  return (
    <GeneralForm
      onSubmit={async (data) => {
        details
          ? await actions.updateDataRequest(details?.id, data)
          : await actions.createDataRequest(data);
      }}
      title={details ? t("update_data_request") : t("add_data_request")}
      defaultValues={
        {
          clientId: details?.client?.id,
          countryId: details?.country?.id,
          depositNumber: details?.depositNumber,
          financialReceiptNumber: details?.financialReceiptNumber,
          officialDate: details?.officialDate,
          note: details?.note,
          dateFinancialReceipt: details?.dateFinancialReceipt,
          restrictionsOn: details?.restrictionsOn,
          data: details?.data,
          extractedFrom: details?.extractedFrom,
          orderValue: details?.orderValue,
          // clientPayments: details?.clientPayments,
        } as IUpdateDataRequest
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="clientId"
                label={`${t("client")}`}
                width="large"
                input={{
                  type: "select",
                  loading: clientsLoading,
                  allowSearch: true,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryId"
                label={`${t("country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="depositNumber"
                label={`${t("depositNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="officialDate"
                label={`${t("official_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="financialReceiptNumber"
                label={`${t("financialReceiptNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="dateFinancialReceipt"
                label={`${t("dateFinancialReceipt")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="orderValue"
                label={`${t("order_value")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            {/*  <Col span={8}>
              <FieldBuilder
                name="clientPayments"
                label={`${t("client_payments")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>*/}
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={24}>
              <div>
                <FormItem label={t("data")}>
                  <Controller
                    name="data"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <RichEditor
                          value={field?.value ?? ""}
                          onChange={field?.onChange}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <FormItem label={t("extractedFrom")}>
                  <Controller
                    name="extractedFrom"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <RichEditor
                          value={field?.value ?? ""}
                          onChange={field?.onChange}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            <Col span={24}>
              <div>
                <FormItem label={t("restrictionsOn")}>
                  <Controller
                    name="restrictionsOn"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <RichEditor
                          value={field?.value ?? ""}
                          onChange={field?.onChange}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const DataRequestForm = () => {
  return (
    <DataRequestContextProvider>
      <Form />
    </DataRequestContextProvider>
  );
};

export default DataRequestForm;
