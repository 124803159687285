import { createContext } from "react";
import { IBaseQuery } from "../../models/base/base-query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../utils/costants";
import { DEFAULT_QUERY } from "../../utils/helpers/constants";
import { IReport } from "../../models/reoprt/response";
import { ICreateReport } from "../../models/reoprt/request";

export type ReportLoading = "list" | "create";

export interface IInternalState {
  loading: ReportLoading[];

  list?: IBaseListingResponse<IReport>;
  query: IBaseQuery;

  details?: IReport;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    createReport: (request: ICreateReport) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IBaseQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    createReport: DEFAULT_FUNCTION,
    
    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ReportContext = createContext(externalState);

export default ReportContext;
