import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { IWiverRequestQuery } from "../../../models/independent-requests-collection/waiver-request/query";
import {
  IWiverRequest,
  IWiverRequestDetails,
} from "../../../models/independent-requests-collection/waiver-request/response";
import {
  ICreateWiverRequest,
  IExportWiverRequestExcel,
  IUpdateWiverRequest,
} from "../../../models/independent-requests-collection/waiver-request/request";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class WiverRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllWiverRequest = (query: IWiverRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IWiverRequest>>({
      path: `/waiver-request`,
      method: "GET",
      query,
      ...params,
    });

  getWiverRequest = (id: number, params?: RequestParams) =>
    this.http.request<IWiverRequestDetails>({
      path: `/waiver-request/${id}`,
      method: "GET",
      ...params,
    });

  createWiverRequest = (data: ICreateWiverRequest, params?: RequestParams) =>
    this.http.request<IWiverRequest>({
      path: "/waiver-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateWiverRequest = (
    id: number,
    data: IUpdateWiverRequest,
    params?: RequestParams
  ) =>
    this.http.request<IWiverRequest>({
      path: `/waiver-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteWiverRequest = (id: number, params?: RequestParams) =>
    this.http.request<IWiverRequest>({
      path: `/waiver-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportWiverRequestExcel, query: IWiverRequestQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/waiver-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default WiverRequestService;
