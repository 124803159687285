import { createContext } from "react";
import { IUserQuery } from "../../../models/managent-collection/user/query";
import {
  ICreateUser,
  IUpdateUser,
} from "../../../models/managent-collection/user/request";
import {
  IUser,
  IUserDetails,
} from "../../../models/managent-collection/user/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type UserLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: UserLoading[];

  list?: IBaseListingResponse<IUser>;
  query: IUserQuery;

  details?: IUserDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IUser) => void;

    createUser: (request: ICreateUser) => void;
    updateUser: (id: number, request: IUpdateUser) => void;
    deleteUser: (id: number) => void;
    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IUserQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    createUser: DEFAULT_FUNCTION,
    updateUser: DEFAULT_FUNCTION,
    deleteUser: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const UserContext = createContext(externalState);

export default UserContext;
