export enum types {
  RELINQUISHMENT_OF_TRADEMARK = 1,
  RELINQUISHMENT_OF_PART_OF_TRADEMARK = 2,
  WITHDRAWAL_OF_HEIR = 3,
  DEATH_OF_OWNER_AND_WITHDRAWAL_OF_HEIR = 4,
  MERGER_OF_TWO_COMPANIES = 5,
  DEATH_OF_A_PARTNER = 6,
}

export const getIncidentsWaiverColor = (status: types) => {
  switch (status) {
    case types.RELINQUISHMENT_OF_TRADEMARK:
      return "blue";
    case types.RELINQUISHMENT_OF_PART_OF_TRADEMARK:
      return "orange";
    case types.WITHDRAWAL_OF_HEIR:
      return "red";
    case types.DEATH_OF_A_PARTNER:
      return "blue";
    case types.DEATH_OF_OWNER_AND_WITHDRAWAL_OF_HEIR:
      return "green";
    case types.MERGER_OF_TWO_COMPANIES:
      return "yellow";
  }
};

// Status Boolean stuts
export const getIncidentsWaiverName = (status: types) => {
  switch (status) {
    case types.DEATH_OF_OWNER_AND_WITHDRAWAL_OF_HEIR:
      return "death-of-owner-and-withdrawal-of-heir";
    case types.MERGER_OF_TWO_COMPANIES:
      return "merger-of-two-companies";
    case types.RELINQUISHMENT_OF_PART_OF_TRADEMARK:
      return "relinquishment-of-part-of-trademark";
    case types.DEATH_OF_A_PARTNER:
      return "death-of-a-partner";
    case types.RELINQUISHMENT_OF_TRADEMARK:
      return "relinquishment-of-trademark";
    case types.WITHDRAWAL_OF_HEIR:
      return "withdrawal-of-heir";
  }
};
