import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IBaseQuery } from "../../models/base/base-query";
import { ICommercialRecords, ICommercialRecordsDetails } from "../../models/commercial-records/response";
import { ICreateCommercialRecords, IUpdateCommercialRecords } from "../../models/commercial-records/request";

class CommercialRecordsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCommercialRecords = (query?: IBaseQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICommercialRecords>>({
      path: `/commercial-records`,
      method: "GET",
      ...params,
      query,
    });

  getCommercialRecord = (id: number, params?: RequestParams) =>
    this.http.request<ICommercialRecordsDetails>({
      path: `/commercial-records/${id}`,
      method: "GET",
      ...params,
    });

  createCommercialRecord = (data: ICreateCommercialRecords, params?: RequestParams) =>
    this.http.request<ICommercialRecordsDetails>({
      path: "/commercial-records",
      method: "POST",
      body: data,
      ...params,
    });

  updateCommercialRecord = (id: number, data: IUpdateCommercialRecords, params?: RequestParams) =>
    this.http.request<ICommercialRecordsDetails>({
      path: `/commercial-records/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCommercialRecord = (id: number, params?: RequestParams) =>
    this.http.request<ICommercialRecordsDetails>({
      path: `/commercial-records/${id}`,
      method: "DELETE",
      ...params,
    });

 
}

export default CommercialRecordsService;
