import { useReducer } from "react";

import { IIncidentsRequestFilesQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/query";
import {
  ICreateIncidentsRequestFiles,
  IUpdateIncidentsRequestFiles,
} from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/request";
import { IIncidentsRequesWaiverFilesDetails } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/response";
import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import IncidentWaiverRequestFilesContext, { internalState } from "./context";
import reducer from "./reducer";
import {
  ICreateIncidentsWaiverRequestFiles,
  IUpdateIncidentsWaiverRequestFiles,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/request";

export interface IProps {
  children: React.ReactNode;
}
const IncidentWaiverRequestFilesContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.incidentWaiverRequsetfile.getAllIncidentRequsetFiles({
            ...state.query,
            filters: [
              {
                name: "requestId",
                operation: "eq",
                value: id,
              },
            ],
          });
        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.incidentWaiverRequsetfile.getIncidentRequsetFile(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IIncidentsRequesWaiverFilesDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createIncidentRequsetFile = async (
    request: ICreateIncidentsWaiverRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.incidentWaiverRequsetfile.createIncidentRequsetFile(
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateIncidentRequsetFile = async (
    id: number,
    request: IUpdateIncidentsWaiverRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.incidentWaiverRequsetfile.updateIncidentRequsetFile(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteIncidentRequsetFile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.incidentWaiverRequsetfile.deleteIncidentRequsetFile(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IIncidentsRequestFilesQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <IncidentWaiverRequestFilesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteIncidentRequsetFile,
          createIncidentRequsetFile,

          updateIncidentRequsetFile,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IncidentWaiverRequestFilesContext.Provider>
  );
};

export default IncidentWaiverRequestFilesContextProvider;
