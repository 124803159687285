export enum types {
  CHANGE_OF_COMPANY_NAME = 1,
  CHANGE_OF_COMPANY_ADDRESS = 2,
  CHANGE_OF_COMPANY_STATUS = 3,
  WITHDRAWAL_OF_A_PARTNER_FROM_A_COMPANY = 4,
  ENTRY_OF_A_PARTNER_INTO_A_COMPANY = 5,
  DEATH_OF_A_PARTNER = 6,
}

export const getIncidentsClientsTypeTypeColor = (status: types) => {
  switch (status) {
    case types.CHANGE_OF_COMPANY_NAME:
      return "blue";
    case types.CHANGE_OF_COMPANY_ADDRESS:
      return "orange";
    case types.CHANGE_OF_COMPANY_STATUS:
      return "red";
    case types.DEATH_OF_A_PARTNER:
      return "blue";
    case types.ENTRY_OF_A_PARTNER_INTO_A_COMPANY:
      return "green";
    case types.WITHDRAWAL_OF_A_PARTNER_FROM_A_COMPANY:
      return "yellow";
  }
};

// Status Boolean stuts
export const getIncidentsClientsName = (status: types) => {
  switch (status) {
    case types.CHANGE_OF_COMPANY_ADDRESS:
      return "change-of-company-address";
    case types.CHANGE_OF_COMPANY_NAME:
      return "change-of-company-name";
    case types.CHANGE_OF_COMPANY_STATUS:
      return "change-of-company-status";
    case types.DEATH_OF_A_PARTNER:
      return "death-of-a-partner";
    case types.ENTRY_OF_A_PARTNER_INTO_A_COMPANY:
      return "entry-of-a-partner-into-a-company";
    case types.WITHDRAWAL_OF_A_PARTNER_FROM_A_COMPANY:
      return "withdrawal-of-a-partner-from-a-company";
  }
};
