import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import { IRequestPayment, IRequestPaymentDetails } from "../../../models/independent-requests-collection/request-payment/response";
import { IRequestPaymentQuery } from "../../../models/independent-requests-collection/request-payment/query";
import { ICreateRequestPayment, IUpdateRequestPayment } from "../../../models/independent-requests-collection/request-payment/request";

export type PaymentRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: PaymentRequestLoading[];

  list?: IBaseListingResponse<IRequestPayment>;
  query: IRequestPaymentQuery;

  details?: IRequestPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id?: number , type?:string) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IRequestPaymentDetails) => void;

    createPaymentRequest: (request: ICreateRequestPayment) => void;
    updatePaymentRequest: (id: number, request: IUpdateRequestPayment) => void;
    deletePaymentRequest: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: any) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPaymentRequest: DEFAULT_FUNCTION,
    updatePaymentRequest: DEFAULT_FUNCTION,
    deletePaymentRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PaymentRequestContext = createContext(externalState);

export default PaymentRequestContext;
