import TreeItem from "../tree-item";
import React from "react";
import { INodeTree } from "../../../../models/workflow/response";

interface IProps {
  items: INodeTree[];
}
const TreeList: React.FC<IProps> = ({ items }) => {
  if (!items) return <></>;
  if (items.length === 0) return <></>;

  return (
    <>
      {
        <ul>
          {items?.map((item: INodeTree) => {
            return (
              <li>
                <TreeItem
                  id={item?.id}
                  name={item?.name?.name}
                  icon={item?.icon?.url}
                  color={item?.color}
                  visited={item?.visited}
                  parentNode={item?.parentNode}
                />
                {item?.childNodes?.length > 0 && (
                  <ul>
                    {item.childNodes?.map((ch: INodeTree) => {
                      return (
                        <li>
                          <TreeItem
                            id={ch?.id}
                            name={ch?.name?.name}
                            icon={ch?.icon?.url}
                            color={ch?.color}
                            visited={ch?.visited}
                            parentNode={ch?.parentNode}
                          />
                          {ch.childNodes?.length !== 0 && (
                            <TreeList items={ch?.childNodes} />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      }
    </>
  );
};

export default TreeList;
