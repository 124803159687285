import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { ITrademarkReservationQuery } from "../../../models/trademark-collection/trademark-reservation/query";
import {
  ITrademarkReservation,
  ITrademarkReservationDetails,
} from "../../../models/trademark-collection/trademark-reservation/response";
import {
  ICreateTrademarkReservation,
  IUpdateTrademarkReservation,
} from "../../../models/trademark-collection/trademark-reservation/request";
import { IHistory } from "../../../models/trademark-collection/trademark-request/response";

class TrademarkReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllTrademarksReservation = (
    query: ITrademarkReservationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ITrademarkReservation>>({
      path: `/trademarks-reservations`,
      method: "GET",
      query,
      ...params,
    });

  getHistory = (query: ITrademarkReservationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHistory>>({
      path: `/reservations/history`,
      method: "GET",
      query,
      ...params,
    });

  getTrademarkReservation = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkReservationDetails>({
      path: `/trademarks-reservations/${id}`,
      method: "GET",
      ...params,
    });

  createTrademarkReservation = (
    data: ICreateTrademarkReservation,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkReservationDetails>({
      path: "/trademarks-reservations",
      method: "POST",
      body: data,
      ...params,
    });

  updateTrademarkReservation = (
    id: number,
    data: IUpdateTrademarkReservation,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkReservationDetails>({
      path: `/trademarks-reservations/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteTrademarkReservation = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkReservationDetails>({
      path: `/trademarks-reservations/${id}`,
      method: "DELETE",
      ...params,
    });

  copyTrademarkReservation = (id: number, data: any, params?: RequestParams) =>
    this.http.request<ITrademarkReservationDetails>({
      path: `/reservations/archive/${id}`,
      method: "POST",
      body: data,
      ...params,
    });
  transferTrademarkReservation = (
    id: number,
    data: any,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkReservationDetails>({
      path: `/reservations/transfer/${id}`,
      method: "POST",
      body: data,
      ...params,
    });

  specialTrademarkReservation = (id: number, data: any, params?: RequestParams) => {
    this.http.request<any>({
      path: `/reservations/make-special/${id}`,
      method: "POST",
      body: data,
      ...params,
    });

  }
}

export default TrademarkReservationService;
