import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import FieldBuilder from "../../../form-components/field-builder";
import { ICategory } from "../../../../models/managent-collection/category/response";
import EndPoints from "../../../../services/end-points";

interface IProps {}

const DisclosureTrademarkForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.category.getAllCategories({
          perPage: -1,
          filters: [
            {
              name: "parentCategory",
              value: null,
              operation: "eq",
            },
          ],
        });
        setCategories(data.data);
        setCategoriesLoading(false);
      } catch (error) {
      } finally {
        setCategoriesLoading(false);
      }
    };
    getCountryLight();
  }, []);
  // get all CLients from api

  return (
    <Row gutter={[6, 6]}>
      <Col span={24}>
        <FieldBuilder
          name="categoryId"
          label={`${t("category")}`}
          width="large"
          input={{
            type: "select",
            allowSearch: true,
            loading: categoriesLoading,
            options: categories?.map((cat, index) => {
              return {
                key: index,
                label: cat.name,
                value: cat.id,
              };
            }),
          }}
        />
      </Col>
      <Col span={24}>
        <FieldBuilder
          label={`${t("products")}`}
          name="products"
          input={{ type: "text" }}
        />
      </Col>
      <Col span={24}>
        <FieldBuilder
          label={`${t("trademark")}`}
          name="trademark"
          input={{ type: "text" }}
        />
      </Col>
    </Row>
  );
};

export default DisclosureTrademarkForm;
