import { createContext } from "react";
import { DEFAULT_FUNCTION } from "../../utils/costants";
import { CAN_VIEW_HOME_PAGE } from "./../../utils/rbac/permissions";

export type AuthLoading = "login" | "logout" | "change_password" | "roles" | "forget_password" | "reset_password";

export interface IInternalState {
  loading: AuthLoading[];

  isAuthenticated?: boolean;
  userDetails?: any;
  permissions: string[];
  grantedPermissions: string[];
}

export const internalState: IInternalState = {
  loading: [],
  isAuthenticated: true,
  permissions: [],
  grantedPermissions: [CAN_VIEW_HOME_PAGE],
};

export interface IExternalState extends IInternalState {
  actions: {
    login: (request: any) => void;
    logout: () => void;
    changePassword: (request: any) => void;
    resetCodeUser: (request: any) => void;
    resetPassword: (request: any) => void;
    forgetPassword: (request: any) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    login: DEFAULT_FUNCTION,
    changePassword: DEFAULT_FUNCTION,
    logout: DEFAULT_FUNCTION,
    resetCodeUser: DEFAULT_FUNCTION,
    resetPassword: DEFAULT_FUNCTION,
    forgetPassword: DEFAULT_FUNCTION,
  },
};

const AuthContext = createContext(externalState);

export default AuthContext;
