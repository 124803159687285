export enum PatentsStatus {
  registration = 1,
  annual_fee = 4,
}

export const getBooleanPatentsOrderTemplate = (status: PatentsStatus) => {
  switch (status) {
    case PatentsStatus.registration:
      return "registration";
    case PatentsStatus.annual_fee:
      return "annual_fee";
  }
};

export const getBooleanOrderTemplate = (status: PatentsStatus) => {
  switch (status) {
    case PatentsStatus.registration:
      return "registration";
    case PatentsStatus.annual_fee:
      return "annual_fee";
  }
};
