export enum BooleanStatus {
  PROTECTEDD = 1,
  NOTPROTECTED = 2,
}

export const getDrawingReservationColor = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.PROTECTEDD:
      return "green";
    case BooleanStatus.NOTPROTECTED:
      return "red";
  }
};

// Status Boolean stuts
export const getBooleanDrawingReservation = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.PROTECTEDD:
      return "protected";
    case BooleanStatus.NOTPROTECTED:
      return "not_protected";
  }
};
