import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import GrievancesContext, { internalState } from "./context";
import reducer from "./reducer";

import moment from "moment";
import {
  ICreateGrievance,
  IUpdateGrievance,
} from "../../../../models/lawswits-collection/grievance/grievance-requests/request";
import { IGrievanceQuery } from "../../../../models/lawswits-collection/grievance/grievance-requests/query";
import { IGrievanceDetails } from "../../../../models/lawswits-collection/grievance/grievance-requests/response";

export interface IProps {
  children: React.ReactNode;
}
const GrievanceContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getList = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.grievance.getAllGrievance(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createGrievance = async (request: ICreateGrievance) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.grievance.createGrievance(request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IGrievanceQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const deleteGrievance = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.grievance.deleteGrievance(id);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };
  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.grievance.getGrievance(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IGrievanceDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const updateGrievance = async (id: number, request: IUpdateGrievance) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.grievance.updateGrievance(id, request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.grievance.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",

          `Grievance-Lawswits-Requsert-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  // const exportPdf = async (id: number) => {
  //   await execute({
  //     callback: async () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //       const data = await EndPoints.trademarkRequest.exportPdf(id);
  //       const url = window.URL.createObjectURL(new Blob([data.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         `Trademark-Requsert-${moment().format("yyyy-MM-d")}.pdf`
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //     },
  //     fallback: (error) => {},
  //     finallyCallback: () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //     },
  //     throwException: true,
  //   });
  // };
  return (
    <GrievancesContext.Provider
      value={{
        ...state,
        actions: {
          getList,
          getDetails,
          setDetails,
          createGrievance,
          updateGrievance,
          deleteGrievance,

          exportExcel,
          // exportPdf,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </GrievancesContext.Provider>
  );
};

export default GrievanceContextProvider;
