import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { ColumnProps } from "antd/es/table";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PatnetsRequestContext from "../../../../../context/patents-collection/patents-request/context";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../../models/patents-collection/patents-reservation/enum";
import { IPatentsReservationDetails } from "../../../../../models/patents-collection/patents-reservation/response";
import EndPoints from "../../../../../services/end-points";
import Tag from "../../../../general/antd/tag";
import Table from "../../../../general/table-components/table";
import CopyrightRequestContext from "../../../../../context/copyright-collection/copyright-request/context";
interface IProps {
  id?: number;
}

const CopyrightsReservation: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [loadingData, setloadingData] = useState(true);
  const { details, actions, loading } = useContext(CopyrightRequestContext);
  const [selectedRow, setSelectedRow] = useState<any>(
    details?.Reservations?.map((a) => a.id)
  );

  useEffect(() => {
    const getTableData = async () => {
      try {
        const data = await EndPoints.copyrightRequest.getCopyrightReservations(
          props.id
        );
        if (data.data) {
          setTableData(data.data.data);
        } else {
          setTableData([]);
        }
      } catch (error) {
      } finally {
        setloadingData(false);
      }
    };

    getTableData();
  }, []);

  const columns: ColumnProps<IPatentsReservationDetails>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    // {
    //   title: t("name"),
    //   dataIndex: "s",
    //   align: "center",

    //   key: "s",
    //   render: (_, record) => {
    //     return <div>{record?.patent?.name}</div>;
    //   },
    // },

    {
      title: t("status"),
      dataIndex: "status",
      align: "center",

      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getPatentsReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, record) => {
        return (
          <p>
            {record?.depositDate
              ? moment(record?.depositDate).format("DD/MM/YYYY")
              : "-"}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, record) => {
        return (
          <p>
            {record?.registrationDate
              ? moment(record?.registrationDate).format("DD/MM/YYYY")
              : "-"}
          </p>
        );
      },
    },
  ];

  const OnClick = async () => {
    await actions.addReservationToCopyrights(props.id, {
      copyrightReservations: selectedRow,
    });

    setloadingData(true);
    await actions.getDetails(props.id);
    await EndPoints.copyrightRequest.getCopyrightReservations(props.id);

    setloadingData(false);
  };

  return (
    <>
      <Row gutter={[6, 6]}>
        <Col span={24}>
          <Table
            rowKey="id"
            loading={loadingData || loading.includes("create")}
            dataSource={tableData}
            columns={columns}
            size="small"
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedRow,
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedRow(
                  selectedRows?.map((selectedRow) => selectedRow.id)
                );
              },
              getCheckboxProps: (selectedRowKeys) => ({
                disabled: details?.Reservations?.some(
                  (a) => a.id === selectedRowKeys.id
                ),
                id: selectedRowKeys.id,
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <Button
            loading={loadingData}
            onClick={OnClick}
            type="primary"
            disabled={
              tableData.length === 0 || loading.includes("create")
                ? true
                : false
            }
            icon={<PlusOutlined />}
          >
            {t("add_reservations")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default CopyrightsReservation;
