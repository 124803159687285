import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import styles from './style.module.scss'

const Spinner: React.FC = () => {
  return (
    <div className={styles.loading}>
      <Spin spinning indicator={<LoadingOutlined />} />
    </div>
  )
}

export default Spinner
