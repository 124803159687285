import { useReducer } from "react";
import EndPoints from "../../services/end-points";
import { execute } from "../../utils/helpers/execute";
import reducer from "./reducer";
import { ICreateReport } from "../../models/reoprt/request";
import ReportContext, { internalState } from "./context";

export interface IProps {
  children: React.ReactNode;
}
const ReportContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.report.getAllReports(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createReport = async (request: ICreateReport) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.report.createReport(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: any) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

 
  return (
    <ReportContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          createReport,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};

export default ReportContextProvider;
