import { ITranslationRequest } from "../../base/translation";

export interface ICreateTrademark {
  name: ITranslationRequest;
  description: string;
  note?: string;
  type: number;
  logoId?: number;
  mainCountryId: number;
  meaning: string
}

export interface IUpdateTrademark extends ICreateTrademark {
  status: string;
}

export interface IExportTrademarkExcel {
  id: boolean;
  nameEn: boolean;
  nameAr: boolean;
  type: boolean;
  country: boolean;
}

export const trademarkColumns = ["nameEn", "nameAr", "type", "country", "id"];
