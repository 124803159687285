import { ITrademarkRequestFilesQuery } from "../../../models/trademark-collection/trademark-requset-files/query";
import {
  ICreateTrademarkRequestFiles,
  IUpdateTrademarkRequestFiles,
} from "../../../models/trademark-collection/trademark-requset-files/request";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import {
  ITrademarkRequesFiles,
  ITrademarkRequesFilesDetails,
} from "../../../models/trademark-collection/trademark-requset-files/response";

class TrademarkRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllTrademarkRequsetFiles = (
    query: ITrademarkRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ITrademarkRequesFiles>>({
      path: `/trademark-request-files`,
      method: "GET",
      query,
      ...params,
    });

  getTrademarkRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkRequesFilesDetails>({
      path: `/trademark-request-files/${id}`,
      method: "GET",
      ...params,
    });

  createTrademarkRequsetFile = (
    data: ICreateTrademarkRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkRequesFiles>({
      path: "/trademark-request-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateTrademarkRequsetFile = (
    id: number,
    data: IUpdateTrademarkRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<ITrademarkRequesFiles>({
      path: `/trademark-request-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteTrademarkRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<ITrademarkRequesFiles>({
      path: `/trademark-request-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default TrademarkRequsetfileService;
