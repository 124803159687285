export enum NodeType {
  TRANSITIONAL = 1,
  SUCCESS = 2,
  FAILURE = 3,
}

export enum ChangeRenewalCount {
  RESET = 1,
  INCREMENT = 2,
}

export enum RequestStatus {
  OPEN = 1,
  SUCCSESS = 2,
  FAILED = 3,
}
export enum ReservationStatus {
  PROTECTED = 1,
  NEGATIVELY_PROTECTED = 2,
  NOTPROTECTED = 3,
}

export const getNodeType = (status: NodeType) => {
  switch (status) {
    case NodeType.SUCCESS:
      return "SUCCESS";
    case NodeType.TRANSITIONAL:
      return "TRANSITIONAL";
    case NodeType.FAILURE:
      return "FAILURE";
    default:
      return "SUCCESS";
  }
};

export const getRenewalCount = (status: ChangeRenewalCount) => {
  switch (status) {
    case ChangeRenewalCount.RESET:
      return "RESET";
    case ChangeRenewalCount.INCREMENT:
      return "INCREMENT";
    default:
      return "INCREMENT";
  }
};

export const getRequestStatus = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.SUCCSESS:
      return "SUCCESS";
    case RequestStatus.OPEN:
      return "OPEN";
    case RequestStatus.FAILED:
      return "FAILED";
    default:
      return "SUCCESS";
  }
};

export const getRequestStatusColor = (status: RequestStatus) => {
  switch (status) {
    case RequestStatus.SUCCSESS:
      return "blue";
    case RequestStatus.OPEN:
      return "green";
    case RequestStatus.FAILED:
      return "red";
    default:
      return "red";
  }
};
export const getReservationStatus = (status: ReservationStatus) => {
  switch (status) {
    case ReservationStatus.NEGATIVELY_PROTECTED:
      return "NEGATIVELY_PROTECTED";
    case ReservationStatus.NOTPROTECTED:
      return "NOTPROTECTED";
    case ReservationStatus.PROTECTED:
      return "PROTECTED";
    default:
      return "PROTECTED";
  }
};
