import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

import {
  IPatentsRequesFiles,
  IPatentsRequesFilesDetails,
} from "../../../models/patents-collection/patents-requset-files/response";
import { IPatentsRequestFilesQuery } from "../../../models/patents-collection/patents-requset-files/query";
import {
  ICreatePatentsRequestFiles,
  IUpdatePatentsRequestFiles,
} from "../../../models/patents-collection/patents-requset-files/request";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<IPatentsRequesFiles>;
  query: IPatentsRequestFilesQuery;

  details?: IPatentsRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IPatentsRequesFilesDetails) => void;

    createPatentsRequsetFile: (request: ICreatePatentsRequestFiles) => void;
    updatePatentsRequsetFile: (
      id: number,
      request: IUpdatePatentsRequestFiles
    ) => void;
    deletePatentsRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IPatentsRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPatentsRequsetFile: DEFAULT_FUNCTION,
    updatePatentsRequsetFile: DEFAULT_FUNCTION,
    deletePatentsRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PatentsRequestFilesContext = createContext(externalState);

export default PatentsRequestFilesContext;
