import {
  Descriptions as AntdDescriptions,
  Avatar,
  Collapse,
  Space,
} from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useNavigate, useParams } from "react-router-dom";
import TrademarkReservationContext from "../../../../context/trademark-collection/trademark-reservation/context";
import TrademarkReservationContextProvider from "../../../../context/trademark-collection/trademark-reservation/provider";
import TrademarkContext from "../../../../context/trademark-collection/trademark/context";
import TrademarkContextProvider from "../../../../context/trademark-collection/trademark/provider";
import { ICreateTrademarkReservation } from "../../../../models/trademark-collection/trademark-reservation/request";
import {
  getBooleanTrademark,
  getTrademarkColor,
} from "../../../../models/trademark-collection/trademark/enum";
import DetailsPage from "../../../form-components/view-details";
import Descriptions from "../../../general/antd/descriptions";
import EditeButton from "../../../general/antd/edite-button";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import FormHookModal from "../../../general/modals/form-hook-modal";
import TrademarkReservationForm from "../trademark-reservation/trademark-reservation-form";
import TrademarkReservationTabel from "../trademark-reservation/trademark-resevation-tabel/index";
import styles from "./styles.module.scss";

interface Props { }
const { Panel } = Collapse;

const Trademark: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(TrademarkContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  return (
    <>
      <DetailsPage title={t(`${"trademark_details"}`)}>
        <div className={styles.container}>
          <Space direction="vertical">
            <Collapse>
              <Panel header={t("trademark_details")} key="1">
                {details?.logo ? (
                  <Image
                    preview
                    src={details.logo?.url}
                    style={{
                      marginBottom: "10px",
                      borderRadius: "50%",
                      maxWidth: 100,
                      minWidth: 24,
                    }}
                  />
                ) : (
                  <Avatar
                    size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                    style={{ marginBottom: "10px" }}
                  >
                    ICON
                  </Avatar>
                )}
                <br style={{ marginTop: "10px" }}></br>
                <EditeButton
                  onClick={() => navigate(`/trademarks/update/${id}`)}
                  className={styles.button}
                >
                  {t(`${"edit_trademark"}`)}
                </EditeButton>
                {loading.includes("details") ? (
                  <Spin />
                ) : (
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_ar")}`}>
                      {details?.translations?.name[0]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_en")}`}>
                      {details?.translations?.name[1]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("clients")}>
                      {details?.clients?.map((client, index) => (
                        <Fragment key={index}>
                          <Tag title={client.name} />
                        </Fragment>
                      ))}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type_trademark")}>
                      <Tag
                        title={getBooleanTrademark(Number(details?.type))}
                        color={getTrademarkColor(Number(details?.type))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("main_country")}>
                      {details?.mainCountry ? details.mainCountry.name : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note ? details.note : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("description_trademark")}>
                      {details?.description ? details.description : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("meaning_trademark")}>
                      {details?.meaning ? details.meaning : "- "}
                    </AntdDescriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header={t("trademark_reservations_details")} key="1">
                <TrademarkReservationTabel idTrademark={Number(id)} />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </DetailsPage>
    </>
  );
};

const TrademarkDetailesPage = () => {
  return (
    <TrademarkContextProvider>
      <TrademarkReservationContextProvider>
        <Trademark />
      </TrademarkReservationContextProvider>
    </TrademarkContextProvider>
  );
};
export default TrademarkDetailesPage;
