import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";
import {
  IExportUserExcel,
  IUpdateUser,
} from "../../../models/managent-collection/user/request";
import { IUserQuery } from "../../../models/managent-collection/user/query";
import { ICreateUser } from "../../../models/managent-collection/user/request";
import { IUser } from "../../../models/managent-collection/user/response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import axios from "axios";

class UserService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllUsers = (query: IUserQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IUser>>({
      path: `/users`,
      method: "GET",
      query,
      ...params,
    });

  getUser = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/users/${id}`,
      method: "GET",
      ...params,
    });

  createUser = (data: ICreateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: "/users",
      method: "POST",
      body: data,
      ...params,
    });

  updateUser = (id: number, data: IUpdateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/users/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteUser = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/users/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportUserExcel, query: IUserQuery) =>
    axios.post(` ${process.env.REACT_APP_BASE_API_URL}/users/export`, data, {
      headers: {
        authorization: `Bearer ${
          localStorage.getItem(ACCESS_TOKEN) ||
          sessionStorage.getItem(ACCESS_TOKEN)
        }`,
        "Content-Type": "application/json",
        "accept-language": `${
          localStorage.getItem(LANGUAGE_CODE) ??
          process.env.REACT_APP_DEFAULT_LANGUAGE
        }`,
      },
      params: query,
      responseType: "arraybuffer",
    });
}

export default UserService;
