import { useReducer } from "react";
import CommercialRecordContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../utils/helpers/execute";
import EndPoints from "../../services/end-points";
import { ICommercialRecordsDetails } from "../../models/commercial-records/response";
import { ICreateCommercialRecords, IUpdateCommercialRecords } from "../../models/commercial-records/request";
import { ICommercialQuery } from "../../models/lawswits-collection/commercial/commercial-requests/query";

export interface IProps {
  children: React.ReactNode;
}
const CommercialRecordContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.commercialRecord.getAllCommercialRecords(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.commercialRecord.getCommercialRecord(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICommercialRecordsDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createCommercialRecord = async (request: ICreateCommercialRecords) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.commercialRecord.createCommercialRecord(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateCommercialRecord = async (id: number, request: IUpdateCommercialRecords) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.commercialRecord.updateCommercialRecord(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCommercialRecord = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.commercialRecord.deleteCommercialRecord(id);
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ICommercialQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <CommercialRecordContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          createCommercialRecord,
          updateCommercialRecord,
          deleteCommercialRecord,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CommercialRecordContext.Provider>
  );
};

export default CommercialRecordContextProvider;
