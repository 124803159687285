import { ITranslationRequest } from "../../base/translation";
export interface ICreateCountry {
  name: ITranslationRequest;
  currencyName: ITranslationRequest;
  currencyCode: string;
  agents: string;
  trademarkMultipleReservationInRequest: boolean;
  trademarkRenewalProtectionPeriod: number;
  trademarkRegistrationProtectionPeriod: number;
  trademarkRenewalConsiderationDate: number;
  trademarkRenewalWithFinePeriod: number;
  trademarkNegativeProtectionPeriod: number;
  trademarkMaxReservationCountInRequest: number;
  trademarkRenewalPeriod: number;
  completionLastMonthRenewalPeriod: boolean;
  completionLastMonthRenewalWithFinePeriod: boolean;
  thereFineForRenewalWithFinePeriod: boolean;
  thereRenewalWithFinePeriod: boolean;
  thereNegativePeriod: boolean;
  users?: number[];
  roles: number[];

  // drawing
  drawingConsiderationDate: number;
  drawingConsiderationPeriod: number;
  drawingProtectionPeriod: number;
  drawingMaxRenewalCount: number;
  drawingMultipleReservationInRequest: boolean;
  drawingMaxReservationCountInRequest: number;
  drawingAdditionalRenewalPeriod: number;
  drawingRenewalPeriod: number;
  drawingCompletionLastMonthRenewalPeriod: boolean;
  drawingCompletionLastMonthAdditionalRenewalPeriod: boolean;
  drawingThereFineForAdditionalRenewalPeriod: boolean;
  drawingThereAdditionalRenewalPeriod: boolean;
  drawingAnnualFeePeriod: number;
  drawingAdditionalAnnualFeePeriod: number;
  drawingCompletionLastMonthAnnualFeePeriod: boolean;
  drawingCompletionLastMonthAdditionalAnnualFeePeriod: boolean;
  drawingThereFineForAdditionalAnnualFeePeriod: boolean;
  drawingThereAdditionalAnnualFeePeriod: boolean;
  drawingProtectionAnnualFeePeriod: number;

  // patents
  patentConsiderationDate: number;
  patentProtectionPeriod: number;
  utilityModelProtectionPeriod: number;
  patentProtectionAnnualFeePeriod: number;
  patentAnnualFeePeriod: number;
  patentAdditionalAnnualFeePeriod: number;
  patentCompletionLastMonthAnnualFeePeriod: boolean;
  patentCompletionLastMonthAdditionalAnnualFeePeriod: boolean;
  patentThereFineForAdditionalAnnualFeePeriod: boolean;
  patentThereAdditionalAnnualFeePeriod: boolean;
}

export interface IUpdateCountry extends ICreateCountry {}

export interface IExportCountryExcel {
  name: boolean;
  currencyName: boolean;
  currencyCode: boolean;
}

export const CountryCloumms = ["name", "currencyName", "currencyCode"];
