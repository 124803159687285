import { IPatentsReservationQuery } from "../../../models/patents-collection/patents-reservation/query";
import {
  ICreatePatentsReservation,
  IUpdatePatensReservation,
} from "../../../models/patents-collection/patents-reservation/request";
import {
  IPatentsReservation,
  IPatentsReservationDetails,
  IPatentsReservationHistory,
} from "../../../models/patents-collection/patents-reservation/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class PatentsReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDPatentsReservation = (
    query: IPatentsReservationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPatentsReservation>>({
      path: `/patent-reservations`,
      method: "GET",
      query,
      ...params,
    });

  getHistory = (query: IPatentsReservationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPatentsReservationHistory>>({
      path: `/patent-reservations/history`,
      method: "GET",
      query,
      ...params,
    });

  getPatentsReservation = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsReservationDetails>({
      path: `/patent-reservations/${id}`,
      method: "GET",
      ...params,
    });

  createPatentsReservation = (data: ICreatePatentsReservation) =>
    this.http.request<IPatentsReservationDetails>({
      path: "/patent-reservations",
      method: "POST",
      body: data,
    });

  updatePatentsReservation = (
    id: number,
    data: IUpdatePatensReservation,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsReservationDetails>({
      path: `/patent-reservations/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePatentsReservation = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsReservationDetails>({
      path: `/patent-reservations/${id}`,
      method: "DELETE",
      ...params,
    });
  transferPatentReservation = (id: number, data: any, params?: RequestParams) =>
    this.http.request<IPatentsReservationDetails>({
      path: `/patent-reservations/transfer/${id}`,
      method: "POST",
      body: data,
      ...params,
    });
}

export default PatentsReservationService;
