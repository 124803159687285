import { IPatentsRequestFilesQuery } from "../../../models/patents-collection/patents-requset-files/query";
import {
  ICreatePatentsRequestFiles,
  IUpdatePatentsRequestFiles,
} from "../../../models/patents-collection/patents-requset-files/request";
import {
  IPatentsRequesFiles,
  IPatentsRequesFilesDetails,
} from "../../../models/patents-collection/patents-requset-files/response";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class PatentsRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllPatentsRequsetFiles = (
    query: IPatentsRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPatentsRequesFiles>>({
      path: `/patent-request-files`,
      method: "GET",
      query,
      ...params,
    });

  getPatentsRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsRequesFilesDetails>({
      path: `/patent-request-files/${id}`,
      method: "GET",
      ...params,
    });

  createPatentsRequsetFile = (
    data: ICreatePatentsRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsRequesFiles>({
      path: "/patent-request-files",
      method: "POST",
      body: data,
      ...params,
    });

  updatePatentsRequsetFile = (
    id: number,
    data: IUpdatePatentsRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsRequesFiles>({
      path: `/patent-request-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deletePatentsRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsRequesFiles>({
      path: `/patent-request-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default PatentsRequsetfileService;
