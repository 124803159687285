import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../../context/app/context";
import TrademarkRequestContext from "../../../../../context/trademark-collection/trademark-request/context";
import { IClient } from "../../../../../models/client/response";
import { INodeName } from "../../../../../models/managent-collection/nodes-name/response";
import { ICountry } from "../../../../../models/managent-collection/user/response";
import { getTrademarkRequestStatusString } from "../../../../../models/trademark-collection/trademark-request/enum";
import EndPoints from "../../../../../services/end-points";
import { INITIAL_PAGE } from "../../../../../utils/helpers/constants";
import FieldBuilder from "../../../../form-components/field-builder";
import Form from "../../../../form-components/from";
import { getForStatus } from "../../../../../models/workflow/enms-workflow/all-enums";
import { getBooleanTrademarkOrderTemplate } from "../../../../../models/workflow/enms-workflow/trademark";

interface IProps {}

const TrademarkRequestFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(TrademarkRequestContext);
  const { direction } = useContext(AppContext);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [nodesLight, setNodesLight] = useState<INodeName[]>([]);
  const [nodesLightLoding, setNodesLightLoging] = useState(true);

  const type = [1, 2, 3];

  // get all clients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, [direction]);

  // get all countries from api
  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountries({});
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, [direction]);
  // get Nodes
  useEffect(() => {
    const getNodesLight = async () => {
      try {
        const { data } = await EndPoints.nodeName.getAllNodeName({
          perPage: -1,
        });

        setNodesLight(data?.data);
      } catch (error) {
      } finally {
        setNodesLightLoging(false);
      }
    };
    getNodesLight();
  }, [direction]);

  const workFlows = [1, 2, 3];
  return (
    <Form
      formId="trademark-request-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.WorkflowType
              ? {
                  name: "WorkflowType",
                  value: data.WorkflowType,
                  operation: "eq",
                }
              : undefined,
            data?.Country
              ? {
                  name: "Country",
                  value: data.Country,
                  operation: "eq",
                }
              : undefined,
            data?.Client
              ? {
                  name: "Client",
                  value: data.Client,
                  operation: "eq",
                }
              : undefined,
            data?.status
              ? {
                  name: "status",
                  value: data.status,
                  operation: "eq",
                }
              : undefined,
            data?.NodeName
              ? {
                  name: "NodeName",
                  value: data.NodeName,
                  operation: "eq",
                }
              : undefined,

            data.OfficialDateBetween != undefined &&
            data.OfficialDateBetween[0] != "" &&
            data.OfficialDateBetween[1] != ""
              ? {
                  name: "OfficialDateBetween",
                  value: data?.OfficialDateBetween,
                }
              : undefined,
          ].filter((f) => f),
        };

        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="Country"
            label={`${t("country")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: countriesLoading,
              options: countries?.map((country, index) => {
                return {
                  label: country.name,
                  value: country.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="Client"
            label={`${t("clients")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: clientsLoading,
              options: clients?.map((client, index) => {
                return {
                  label: client.name,
                  value: client.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="WorkflowType"
            label={`${t("type_workflow")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: false,
              options: workFlows?.map((workFlow, index) => {
                return {
                  label: t(getBooleanTrademarkOrderTemplate(workFlow)),
                  value: workFlow,
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("request_status")}`}
            width="large"
            input={{
              type: "select",
              options: type.map((status, index) => {
                return {
                  label: t(getTrademarkRequestStatusString(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="NodeName"
            label={`${t("current_node")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: nodesLightLoding,
              options: nodesLight?.map((node, index) => {
                return {
                  label: node?.name,
                  value: node?.id,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="OfficialDateBetween"
            label={`${t("official_date")}`}
            input={{ type: "range-picker" }}
            rules={{ required: false }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default TrademarkRequestFilter;
