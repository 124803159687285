import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TrademarkReservationContext from "../../../../../context/trademark-collection/trademark-reservation/context";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import {
  TrademarkRequestStatus,
  getTrademarkRequestStatusColor,
  getTrademarkRequestStatusString,
} from "../../../../../models/trademark-collection/trademark-request/enum";
import { ITrademarkRequestExport } from "../../../../../models/trademark-collection/trademark-reservation/response";

import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
} from "../../../../../models/workflow/enms-workflow/all-enums";
import EndPoints from "../../../../../services/end-points";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import Tag from "../../../../general/antd/tag";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import { request } from "http";

interface IProps {}
const TrademarkReservationTrademarkRequestTabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { actions, loading, query } = useContext(TrademarkReservationContext);
  const [detailsTrademarkReservation, setDetailsTrademarkReservation] =
    useState<any>();
  const [
    loadingdetailsTrademarkReservation,
    setLoadingDetailsTrademarkReservation,
  ] = useState(true);

  useEffect(() => {
    const getDetailsTrademarkReservation = async (id: number) => {
      try {
        const data =
          await EndPoints.trademarkReservation.getTrademarkReservation(id);
        setDetailsTrademarkReservation(data.data);

        setLoadingDetailsTrademarkReservation(false);
      } catch (error) {
      } finally {
        setLoadingDetailsTrademarkReservation(false);
      }
    };
    getDetailsTrademarkReservation(Number(id));
  }, []);
  let name = "";
  const columns: ColumnProps<ITrademarkRequestExport>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      align: "center",
      key: "id",
    },
    {
      title: t("name_order"),
      dataIndex: "workflow",
      key: "workflow",
      align: "center",
      render: (record) => {
        return <span>{record?.name}</span>;
      },
    },
    {
      title: t("incident"),
      dataIndex: "incident",
      key: "incident",
      align: "center",
      render: (_, record) =>
        record?.incident?.length > 0
          ? record?.incident?.map((ass, index) => {
              return <Tag key={index} title={ass} />;
            })
          : "-",
    },
    {
      title: t("type_requset"),
      dataIndex: "workflow",
      align: "center",
      key: "workflow",
      render: (record) => {
        return (
          <Tag
            title={getAllBooleanOrderTemplate(Number(record?.type))}
            color={getAllOrderTemplateColor(Number(record?.type))}
          />
        );
      },
    },
    {
      title: t("number_request"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },
    {
      title: t("request_status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status: TrademarkRequestStatus) => (
        <Tag
          color={getTrademarkRequestStatusColor(status)}
          title={getTrademarkRequestStatusString(status)}
        />
      ),
    },
    {
      title: t("date_create_order"),
      align: "center",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => {
        return <span>{moment(record).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: t("date_use_order"),
      dataIndex: "officialDate",
      align: "center",
      key: "officialDate",
      render: (record) => {
        return <span>{moment(record).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: t("actions"),
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              switch (record?.nameRequest) {
                case "trademark":
                  navigate(`/trademark-requests/details/${record?.id}`);
                  break;
                case "incident-client":
                  navigate(`/incidents-clients/details/${record?.id}`);
                  break;
                case "incident-waiver":
                  navigate(`/incidents-waiver/details/${record?.id}`);
                  break;

                default:
                  alert("hello");
                  navigate(`/trademark-requests/details/${record?.id}`);
                  break;
              }
            }}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        size="small"
        showPagination={false}
        columns={columns}
        dataSource={detailsTrademarkReservation?.TrademarkRequestExport ?? []}
        loading={loading.includes("details")}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default TrademarkReservationTrademarkRequestTabel;
