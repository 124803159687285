import { Descriptions as AntdDescriptions, Descriptions } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import moment from "moment";
import OfficeCostContext from "../../../../../context/trademark-collection/office-cost/context";
import Spin from "../../../../general/antd/spin";
import { ITrademarkReservation } from "../../../../../models/trademark-collection/trademark-reservation/response";
import { ImCheckmark, ImCross } from "react-icons/im";
import Tag from "../../../../general/antd/tag";
import {
  getBooleanTrademarkReservation,
  getTrademarkReservationColor,
} from "../../../../../models/trademark-collection/trademark-reservation/enum";

interface IProps {
  details: ITrademarkReservation;
}
const ReservationDetails: React.FC<IProps> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <>
      <Descriptions size="small" column={2} bordered>
        <AntdDescriptions.Item label={t("id")}>
          {details?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("country")}>
          {details?.country?.name ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("all_categories")}>
          {details?.allCategories ? (
            <ImCheckmark color="green" />
          ) : (
            <ImCross color="red" />
          )}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("main_category")}>
          {details?.mainCategory?.name ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("status")}>
          {
            <Tag
              title={getBooleanTrademarkReservation(Number(details?.status))}
              color={getTrademarkReservationColor(Number(details?.status))}
            />
          }
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("deposit_number")}>
          {details?.depositNumber ?? "-"}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("deposit_date")}>
          {details?.depositDate
            ? moment(details?.depositDate).format("DD/MM/YYYY ")
            : "-"}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("last_renewal_number")}>
          {details?.lastRenewalNumber ? details?.lastRenewalNumber : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("renewal_counts")}>
          {details?.renewalCounts ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("last_renewal_date")}>
          {details.lastRenewalDate ? details.lastRenewalDate.slice(0, 10) : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("next_renewal_date")}>
          {details.nextRenewalDate ? details.nextRenewalDate.slice(0, 10) : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("registration_number")}>
          {details?.registrationNumber ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("registration_date")}>
          {details?.registrationDate
            ? moment(details?.registrationDate).format("DD/MM/YYYY ")
            : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("renewal_counts")}>
          {details?.renewalCounts ?? "-"}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item
          contentStyle={{ overflow: "scroll" }}
          label={t("categories")}
        >
          {details?.categories?.map((cat) => {
            return <Tag title={cat.name} />;
          })}
        </AntdDescriptions.Item>
      </Descriptions>
    </>
  );
};

export default ReservationDetails;
