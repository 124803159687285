import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { BiTransfer } from "react-icons/bi";
import Button, { ButtonProps } from "../../../antd/button";
interface Props extends ButtonProps {
  title?: string;
  onClick?: () => void;
}

const TransferBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("transfer")}>
      <Button type="primary" shape="circle" icon={<BiTransfer />} {...props} />
    </Tooltip>
  );
};

export default TransferBtn;
