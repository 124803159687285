import axios from "axios";

import { ICertifiedCopyQuery } from "../../../models/independent-requests-collection/certified-copy/query";
import {
  ICreateCertifiedCopy,
  IExportCertifiedCopyExcel,
  IUpdateCertifiedCopy,
} from "../../../models/independent-requests-collection/certified-copy/request";
import {
  ICertifiedCopy,
  ICertifiedCopyDetails,
} from "../../../models/independent-requests-collection/certified-copy/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class CertifiedCopyService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCertifiedCopy = (query: ICertifiedCopyQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICertifiedCopy>>({
      path: `/certified-copy-request`,
      method: "GET",
      query,
      ...params,
    });

  getCertifiedCopy = (id: number, params?: RequestParams) =>
    this.http.request<ICertifiedCopyDetails>({
      path: `/certified-copy-request/${id}`,
      method: "GET",
      ...params,
    });

  createCertifiedCopy = (data: ICreateCertifiedCopy, params?: RequestParams) =>
    this.http.request<ICertifiedCopy>({
      path: "/certified-copy-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateCertifiedCopy = (
    id: number,
    data: IUpdateCertifiedCopy,
    params?: RequestParams
  ) =>
    this.http.request<ICertifiedCopy>({
      path: `/certified-copy-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCertifiedCopy = (id: number, params?: RequestParams) =>
    this.http.request<ICertifiedCopy>({
      path: `/certified-copy-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportCertifiedCopyExcel, query: ICertifiedCopyQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/certified-copy-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default CertifiedCopyService;
