import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import CommercialFilter from "../../../components/lawsuits-managents/commercial/commercial-request/commercial-filter";
import CommercialTable from "../../../components/lawsuits-managents/commercial/commercial-request/commercial-table";
import AppContext from "../../../context/app/context";
import CommercialsContext from "../../../context/lawswits-collection/commercial/commercial/context";
import CommerciaContextProvider from "../../../context/lawswits-collection/commercial/commercial/provider";
import {
  CommercialCloumms,
  IExportCommercialExcel,
} from "../../../models/lawswits-collection/commercial/commercial-requests/request";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";

interface IProps {
  children?: React.ReactNode;
}

const Commercial: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(CommercialsContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getList();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("commercial")}
        subTitle={t("commercial_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getList()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/commercial/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_commercial")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="commercial-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <CommercialFilter />
      </SearchFilterCard>
      <CommercialTable />
      <ExportToExcelModal
        id="export-users"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="60vw"
        defaultValues={
          {
            basisNumber: false,
            Country: false,
            typeWorkflow: false,
            currentNode: false,
            status: false,
            CreatedDate: false,
            lastUpdatedDate: false,
            basisDate: false,
            clientCost: false,
            clients: false,
            description: false,
            clientPayment: false,
          } as IExportCommercialExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={CommercialCloumms} />
      </ExportToExcelModal>
    </div>
  );
};

const CommercialPage = () => {
  return (
    <CommerciaContextProvider>
      <Commercial />
    </CommerciaContextProvider>
  );
};
export default CommercialPage;
