import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import { Col, Typography } from "antd";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import AppContext from "../../../../context/app/context";
import DrawingReservationContext from "../../../../context/drawing-collection/drawing-reservation/context";
import {
  getBooleanDrawingReservation,
  getDrawingReservationColor,
} from "../../../../models/drawing-collection/drawing-reservation/enum";
import { IUpdateDrawingReservation } from "../../../../models/drawing-collection/drawing-reservation/request";
import { IDrawingReservation } from "../../../../models/drawing-collection/drawing-reservation/response";
import FormHookModal from "../../../general/modals/form-hook-modal";
import DrawingReservationForm from "../drawing-reservation-form";
import TransferBtn from "../../../general/table-components/actions/archive-btn";
import { IDrawing } from "../../../../models/drawing-collection/drawing/response";
import EndPoints from "../../../../services/end-points";
import FieldBuilder from "../../../form-components/field-builder";

interface IProps {}
const { Title } = Typography;

const DrawingReservationTabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { list, details, actions, loading, query } = useContext(
    DrawingReservationContext
  );

  const [infoId, setInfId] = useState<number>();
  const { id } = useParams();

  const { direction } = useContext(AppContext);

  const [idReservetion, setIdReservetion] = useState<number>();

  useEffect(() => {
    actions.getData(Number(id));
  }, [direction, query]);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={
          loading.includes("create") ||
          loading.includes("details") ||
          loading.includes("update")
        }
        title={
          !details
            ? t("add_industrial_models_and_drawings_reservation")
            : t("update_industrial_models_and_drawings_reservation")
        }
        hideModal={() => {
          setInfId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        width={"60vw"}
        style={{ marginTop: "1px" }}
        onSubmit={async (data) => {
          details
            ? await actions.updateDrawingReservation(infoId, data)
            : await actions.createDrawingReservation(data);
          setInfId(undefined);
          actions.setDetails(undefined);
          actions.getData(Number(id));
        }}
        defaultValues={
          {
            drawingId: Number(id),
            depositNumber: details?.depositNumber,
            registrationDate: details?.registrationDate,
            depositDate: details?.depositDate,
            status: details?.status,
            registrationNumber: details?.registrationNumber,
            renewalCounts: details?.renewalCounts,
            lastRenewalNumber: details?.lastRenewalNumber,
            lastRenewalDate: details?.lastRenewalDate,
            nextRenewalDate: details?.nextRenewalDate,
            countryId: details?.country?.id,
          } as IUpdateDrawingReservation
        }
      >
        <DrawingReservationForm />
      </FormHookModal>
    ),
    [loading, details, infoId]
  );

  // archive reservation
  const [drawings, setDrawings] = useState<IDrawing[]>([]);
  const [drawingLoading, setDrawingLoading] = useState(false);
  useEffect(() => {
    const getDrawing = async () => {
      try {
        setDrawingLoading(true);

        const { data } = await EndPoints.drawing.getAllDrawing({ perPage: -1 });
        const drawings = data?.data?.filter((tr) => tr?.id !== Number(id));
        setDrawings(drawings);
        setDrawingLoading(false);
      } catch (error) {
      } finally {
        setDrawingLoading(false);
      }
    };
    getDrawing();
  }, [idReservetion]);

  const [showFormTransferModal, hideTransferFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={false}
        title={t("transfer_reservation_drawing")}
        style={{ marginTop: "2rem" }}
        hideModal={() => {
          hideTransferFormodal();
          setIdReservetion(undefined);
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          await EndPoints.drawingReservation.transferDrawingReservation(
            idReservetion,
            {
              drawingId: data?.drawingId,
            }
          );
          setIdReservetion(undefined);

          actions.getData(Number(id));
        }}
        defaultValues={{} as any}
      >
        <Title level={5}>
          {t(`${"transfer_reservation_drawing_content"}`)}:
        </Title>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="drawingId"
              label={`${t("drawings")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",

                loading: drawingLoading,
                options: drawings.map((draw, index) => {
                  return {
                    label: draw.name as string,
                    value: draw.id as number,
                  };
                }),
              }}
            />
          </Col>
        </Row>
      </FormHookModal>
    ),
    [idReservetion]
  );

  const columns: ColumnProps<IDrawingReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },

    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",
      key: "renewalCounts",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanDrawingReservation(Number(record))}
            color={getDrawingReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_date"),
      dataIndex: "lastRenewalDate",
      align: "center",

      key: "lastRenewalDate",
      render: (_, { lastRenewalDate }) => {
        return (
          <p>
            {" "}
            {lastRenewalDate
              ? moment(lastRenewalDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_number"),
      dataIndex: "lastRenewalNumber",
      align: "center",
      key: "lastRenewalNumber",
    },

    {
      title: t("actions"),
      dataIndex: "",

      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`resevation/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              setInfId(record?.id);
              showFormModal();
            }}
          />
          <TransferBtn
            onClick={async () => {
              setIdReservetion(record?.id);
              showFormTransferModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteDrawingReservation(record?.id);
              eventManager.emit(EVENT_SUCCESS);
              actions.getData(Number(id));
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Button
          onClick={() => {
            setInfId(undefined);
            showFormModal();
          }}
          icon={<PlusOutlined />}
          type="primary"
          title={`${t("add_industrial_models_and_drawings_reservation")}`}
        >
          {t("add_industrial_models_and_drawings_reservation")}
        </Button>
      </Row>
      <Table<any>
        rowKey="id"
        size="small"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
        scroll={{ x: 400 }}
      />
    </>
  );
};

export default DrawingReservationTabel;
