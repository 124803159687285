import { CaretRightOutlined, FilterOutlined } from "@ant-design/icons";
import { Col, Collapse, Row, Space } from "antd";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../../context/app/context";
import TrademarkReservationContext from "../../../../../context/trademark-collection/trademark-reservation/context";
import { ICountry } from "../../../../../models/managent-collection/user/response";
import EndPoints from "../../../../../services/end-points";
import { INITIAL_PAGE } from "../../../../../utils/helpers/constants";
import FieldBuilder from "../../../../form-components/field-builder";
import Form from "../../../../form-components/from";
import SubmitBtn from "../../../../form-components/submit-btn";
import styles from "./styles.module.scss";

const { Panel } = Collapse;

interface IProps {
  setCountr: (number) => void;
}

const TrademarkReservationFilter: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, query, loading } = useContext(TrademarkReservationContext);
  const { direction } = useContext(AppContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);

  // get all countries from api
  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountries({});
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, [direction]);

  return (
    <div className={styles.container}>
      <div className={styles.filterCard}>
        <Collapse
          bordered={true}
          className={styles.colls}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          onChange={(key) => {
            setShowFilters(key?.includes("1"));
          }}
          accordion
        >
          <Panel
            extra={
              <Fragment>
                {
                  <Space>
                    <SubmitBtn
                      icon={<FilterOutlined />}
                      loading={loading.includes("list")}
                      onClick={(e) => e.stopPropagation()}
                      form="trademark-reservation-filter"
                    >
                      {t("apply")}
                    </SubmitBtn>
                  </Space>
                }
              </Fragment>
            }
            header={t("filters")}
            key="1"
          >
            <Form
              formId="trademark-reservation-filter"
              onSubmit={(data) => {
                const filter: any = {
                  page: INITIAL_PAGE,
                  perPage: query?.perPage,
                  keyword: data?.keyword,
                  filters: [
                    data?.mainCountry
                      ? {
                          name: "country",
                          value: data.mainCountry,
                          operation: "eq",
                        }
                      : undefined,
                  ].filter((f) => f),
                };
                props.setCountr(filter.filters[0].value);
                actions.setQuery(filter);
              }}
            >
              <Row justify={"start"} gutter={[10, 0]}>
                <Col span={8}>
                  <FieldBuilder
                    name="mainCountry"
                    label={`${t("country")}`}
                    width="large"
                    input={{
                      type: "select",
                      allowSearch: true,
                      loading: countriesLoading,
                      options: countries?.map((country, index) => {
                        return {
                          label: country.name,
                          value: country.id as number,
                        };
                      }),
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default TrademarkReservationFilter;
