import { Descriptions as AntdDescriptions, Collapse, Space } from "antd";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../form-components/view-details";
import Descriptions from "../../../general/antd/descriptions";
import EditeButton from "../../../general/antd/edite-button";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";

import DrawingReservationContextProvider from "../../../../context/drawing-collection/drawing-reservation/provider";
import DrawingContext from "../../../../context/drawing-collection/drawing/context";
import DrawingContextProvider from "../../../../context/drawing-collection/drawing/provider";
import {
  getDrawingColor,
  getDrawingCountryColor,
  getDrawingCountryType,
  getDrawingType,
} from "../../../../models/drawing-collection/drawing/enum";
import DrawingReservationTabel from "../../drawing-reservation/drawing-resevation-tabel";
import styles from "./styles.module.scss";

interface Props {}
const { Panel } = Collapse;

const Drawing: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(DrawingContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  return (
    <>
      <DetailsPage title={t(`${"details_industrial_models_and_drawings"}`)}>
        <div className={styles.container}>
          <Space direction="vertical">
            <Collapse>
              <Panel
                header={t("details_industrial_models_and_drawings")}
                key="1"
              >
                <EditeButton
                  onClick={() =>
                    navigate(`/industrial-models-and-drawings/update/${id}`)
                  }
                  className={styles.button}
                >
                  {t(`${"update_industrial_models_and_drawings"}`)}
                </EditeButton>
                {loading.includes("details") ? (
                  <Spin />
                ) : (
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_ar")}`}>
                      {details?.translations?.name[0]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_en")}`}>
                      {details?.translations?.name[1]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type_drawing")}>
                      <Tag
                        color={getDrawingColor(Number(details?.type))}
                        title={getDrawingType(Number(details?.type))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("drawing_area")}>
                      <Tag
                        color={getDrawingCountryColor(
                          Number(details?.countryType)
                        )}
                        title={getDrawingCountryType(
                          Number(details?.countryType)
                        )}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("clients")}>
                      {details?.clients?.map((client, index) => (
                        <Fragment key={index}>
                          <Tag title={client.name} />
                        </Fragment>
                      ))}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note ? details.note : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("description")}>
                      {details?.description ? details.description : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("logo")}>
                      {details?.logo ? (
                        <Image
                          preview
                          height={80}
                          style={{
                            objectFit: "cover",
                          }}
                          width={80}
                          src={details?.logo?.url}
                          alt={details?.logo?.for}
                        />
                      ) : (
                        "- "
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("photos")}
                      style={{ overflow: "clip" }}
                    >
                      {details?.photos?.length > 0
                        ? details?.photos.map((photo, index) => {
                            return (
                              <div className={styles.containerImage}>
                                <Image
                                  key={index}
                                  preview
                                  height={80}
                                  style={{
                                    objectFit: "contain",
                                  }}
                                  width={80}
                                  src={photo?.url}
                                  alt={photo?.for}
                                />
                              </div>
                            );
                          })
                        : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("materials_drawing")}>
                      {details?.materials ? details.materials : "- "}
                    </AntdDescriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={1}>
              <Panel
                header={t("details_industrial_models_and_drawings_reservation")}
                key="1"
              >
                <DrawingReservationTabel />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </DetailsPage>
    </>
  );
};

const DrawingDetailsPage = () => {
  return (
    <DrawingContextProvider>
      <DrawingReservationContextProvider>
        <Drawing />
      </DrawingReservationContextProvider>
    </DrawingContextProvider>
  );
};
export default DrawingDetailsPage;
