import { Descriptions as AntdDescriptions, Button, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";
import PageDetails from "../../../../general/details";
import { ColumnProps } from "antd/es/table";
import Table from "../../../../general/table-components/table";

import IncidentsClientsContext from "../../../../../context/incidents-collection/incidents-clients-reqests/clients-incidents/context";
import { IDrawingReservation } from "../../../../../models/drawing-collection/drawing-reservation/response";
import moment from "moment";
import {
  getBooleanDrawingReservation,
  getDrawingReservationColor,
} from "../../../../../models/drawing-collection/drawing-reservation/enum";
import Tag from "../../../../general/antd/tag";
import { ITrademarkReservation } from "../../../../../models/trademark-collection/trademark-reservation/response";
import { ImCheckmark, ImCross } from "react-icons/im";
import {
  getBooleanTrademarkReservation,
  getTrademarkReservationColor,
} from "../../../../../models/trademark-collection/trademark-reservation/enum";
interface Props {}
const TrademarkTable: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(IncidentsClientsContext);

  const columns: ColumnProps<ITrademarkReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",

      align: "center",
      render: (_, { trademark }) => {
        return <p> {trademark?.name} </p>;
      },
    },
    {
      title: t("main_category"),
      dataIndex: "mainCategory",
      align: "center",
      key: "mainCategory",
      render: (_, { mainCategory }) => {
        return <p> {mainCategory?.number} </p>;
      },
    },
    {
      title: t("all_categories"),
      align: "center",
      dataIndex: "allCategories",
      key: "allCategories",
      render: (_, { allCategories }) => (
        <>
          {allCategories ? (
            <ImCheckmark color="green" />
          ) : (
            <ImCross color="red" />
          )}
        </>
      ),
    },
    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",
      key: "renewalCounts",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanTrademarkReservation(Number(record))}
            color={getTrademarkReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      key: "country",
      align: "center",
      render: (_, record) => {
        return <p> {record?.registrationNumber ?? "-"} </p>;
      },
    },
  ];

  return (
    <Table<any>
      rowKey="id"
      style={{ height: "100%" }}
      columns={columns}
      size="small"
      dataSource={details?.trademarkReservations ?? []}
    />
  );
};

export default TrademarkTable;
