import { createContext } from "react";

import { IIncidentsRequestFilesQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/query";
import {
  ICreateIncidentsRequestFiles,
  IUpdateIncidentsRequestFiles,
} from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/request";
import {
  IIncidentsRequesFiles,
  IIncidentsRequesFilesDetails,
} from "../../../../models/incidents-managments/incidents-clients/incidents-requset-files/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<IIncidentsRequesFiles>;
  query: IIncidentsRequestFilesQuery;

  details?: IIncidentsRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsRequesFilesDetails) => void;

    createIncidentRequsetFile: (request: ICreateIncidentsRequestFiles) => void;
    updateIncidentRequsetFile: (
      id: number,
      request: IUpdateIncidentsRequestFiles
    ) => void;
    deleteIncidentRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createIncidentRequsetFile: DEFAULT_FUNCTION,
    updateIncidentRequsetFile: DEFAULT_FUNCTION,
    deleteIncidentRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentRequestFilesContext = createContext(externalState);

export default IncidentRequestFilesContext;
