import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../../form-components/view-details";
import Descriptions from "../../../../general/antd/descriptions";
import EditeButton from "../../../../general/antd/edite-button";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";

import type { TabsProps } from "antd";
import { Tabs } from "antd";
import moment from "moment";
import { ImCheckmark, ImCross } from "react-icons/im";
import IncidentsClientsPaymentsContextProvider from "../../../../../context/incidents-collection/incidents-clients-reqests/client-payments/provider";
import IncidentsClientsContext from "../../../../../context/incidents-collection/incidents-clients-reqests/clients-incidents/context";
import IncidentsClientsContextProvider from "../../../../../context/incidents-collection/incidents-clients-reqests/clients-incidents/provider";
import IncidentsOfficeCostContextProvider from "../../../../../context/incidents-collection/incidents-clients-reqests/office-cost/provider";
import {
  getRequestStatus,
  getRequestStatusColor,
} from "../../../../../models/node/enum";
import DrawingFilesTable from "../../../../drawing-managmet/drawing-requests/files/table";
import DrawingTimeLine from "../../../../drawing-managmet/time-line";
import IncidentsClientsPaymentTable from "../../clients-payments/client-payment-table";
import IncidentsOfficeCostTable from "../../office-cost/office-cost-table";
import DrawingTable from "../../tables/drawing-reservation";
import PatentsTable from "../../tables/patents-reservation";
import TrademarkTable from "../../tables/trademark-reservation";
import styles from "./styles.module.scss";
import IncidentRequestFilesContextProvider from "../../../../../context/incidents-collection/incidents-clients-reqests/incident-request-files/provider";
import IncidentFilesTable from "../../files/table";
import IncidentTimeLine from "../../time-line";
import CopyrightTable from "../../tables/copyright-reservation";
interface Props {}
const { Panel } = Collapse;

const IncidentsClients: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(IncidentsClientsContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("reservations_drawing"),
      children: (
        <>
          {" "}
          <DrawingTable />
        </>
      ),
    },
    {
      key: "2",
      label: t("trademark_reservation"),
      children: (
        <>
          <TrademarkTable />
        </>
      ),
    },
    {
      key: "4",
      label: t("patents_and_utility_models_reservation"),
      children: (
        <>
          <PatentsTable />
        </>
      ),
    },
    {
      key: "3",
      label: t("copyright_reservation"),
      children: (
        <>
          <CopyrightTable />
        </>
      ),
    },
  ];

  return (
    <>
      <DetailsPage title={t("details_incidents_clients")}>
        {loading.includes("details") ? (
          <div style={{ height: "50vh" }}>
            <Spin />
          </div>
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("details_incidents_clients")} key="1">
                    <EditeButton
                      onClick={() =>
                        navigate(`/incidents-clients/update/${id}`)
                      }
                      className={styles.button}
                    >
                      {t(`${"update_incidents_clients"}`)}
                    </EditeButton>
                    {loading.includes("details") ? (
                      <Spin />
                    ) : (
                      <Descriptions size="small" column={1} bordered>
                        <AntdDescriptions.Item label={t("id")}>
                          {details?.id}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("request_status")}>
                          <Tag
                            title={getRequestStatus(Number(details?.status))}
                            color={getRequestStatusColor(
                              Number(details?.status)
                            )}
                          />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("country")}>
                          <Tag
                            title={details?.workflow?.country?.name ?? "-"}
                          />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("workflow")}>
                          <Tag title={details?.workflow?.name ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("applicantClient")}>
                          <Tag title={details?.applicantClient.name ?? "-"} />
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("newClient")}>
                          <Tag title={details?.newClient.name ?? "-"} />
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("oldClient")}>
                          <Tag title={details?.oldClient?.name ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("address")}>
                          {details?.address ?? "-"}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_cost")}>
                          <Tag title={details?.clientCost ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_payment")}>
                          <Tag title={details?.clientPayment ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("office_costs")}>
                          <Tag title={details?.officeCosts ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("current_node")}>
                          {details?.currentNode?.name?.name ?? (
                            <Tag title="not_found" color="red" />
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("created_at")}>
                          {moment(details?.createdAt).format("DD/MM/YYYY")}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item
                          label={t("request_submitting_date")}
                        >
                          {moment(details?.officialDate).format("DD/MM/YYYY")}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("subject")}>
                          {details?.subject ?? "---"}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={`${t("changeCompany")}`}>
                          {details?.changeCompany ? (
                            <ImCheckmark color="green" />
                          ) : (
                            <ImCross color="red" />
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={`${t("changeName")}`}>
                          {details?.changeName ? (
                            <ImCheckmark color="green" />
                          ) : (
                            <ImCross color="red" />
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={`${t("changeAddress")}`}>
                          {details?.changeAddress ? (
                            <ImCheckmark color="green" />
                          ) : (
                            <ImCross color="red" />
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("description")}>
                          {details?.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: details?.description,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("second_description_incident")}>
                          {details?.secondDescription ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: details?.secondDescription,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </AntdDescriptions.Item>
                      </Descriptions>
                    )}
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Tabs defaultActiveKey="1" items={items} />
              </Col>

              <Col span={8}>
                <Collapse>
                  <Panel header={t("time_line_request")} key={1}>
                    <IncidentTimeLine />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={16}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("client_payments")} key={1}>
                        <IncidentsClientsPaymentTable />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col />
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("office_cost")} key={1}>
                        <IncidentsOfficeCostTable />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("files")} key={1}>
                        <IncidentFilesTable />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </DetailsPage>
    </>
  );
};

const IncidentsClientsDetailsPage = () => {
  return (
    <IncidentsClientsContextProvider>
      <IncidentsClientsPaymentsContextProvider>
        <IncidentsOfficeCostContextProvider>
          <IncidentRequestFilesContextProvider>
            <IncidentsClients />
          </IncidentRequestFilesContextProvider>
        </IncidentsOfficeCostContextProvider>
      </IncidentsClientsPaymentsContextProvider>
    </IncidentsClientsContextProvider>
  );
};
export default IncidentsClientsDetailsPage;
