import { IIncidentsLicensingRequestFilesQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/query";
import {
  ICreateIncidentsLicensingRequestFiles,
  IUpdateIncidentsLicensingRequestFiles,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/request";
import {
  IIncidentsLicensingRequesFiles,
  IIncidentsRequesLicensingFilesDetails,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class IncidentLicensingRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllIncidentRequsetFiles = (
    query: IIncidentsLicensingRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsLicensingRequesFiles>>({
      path: `/incident-licensing-files`,
      method: "GET",
      query,
      ...params,
    });

  getIncidentRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsRequesLicensingFilesDetails>({
      path: `/incident-licensing-files/${id}`,
      method: "GET",
      ...params,
    });

  createIncidentRequsetFile = (
    data: ICreateIncidentsLicensingRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsRequesLicensingFilesDetails>({
      path: "/incident-licensing-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateIncidentRequsetFile = (
    id: number,
    data: IUpdateIncidentsLicensingRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsLicensingRequesFiles>({
      path: `/incident-licensing-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteIncidentRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsLicensingRequesFiles>({
      path: `/incident-licensing-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default IncidentLicensingRequsetfileService;
