import { Fragment, useContext } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import BaseLayout from "./components/general/layout/base-layout";
import Error404 from "./pages/404";
import Login from "./pages/login";
import RequireAuth from "./router/RequireAuth";
import { routes } from "./routes";
import AuthContext from "./context/auth/context";
import ResetPassword from "./pages/reset-password";

function App() {
  const protectedLayout = (
    <RequireAuth>
      <BaseLayout />
    </RequireAuth>
  );
  const { permissions } = useContext(AuthContext);

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={protectedLayout}>
          {routes.map((route, index) => {
            if (route.hasSubMenus === true) {
              return route.subMenus?.map((menu, index) => {
                if (
                  menu.permissions.find((sub) =>
                    JSON.parse(
                      localStorage.getItem("permisionStoroge")
                    )?.includes(sub)
                  )
                ) {
                  if (menu.crudRoutes) {
                    return (
                      <Route key={index} path={menu.path} element={<Outlet />}>
                        {menu?.crudRoutes?.map((crudRoute, indexRpute) => {
                          return (
                            <Fragment key={index}>
                              <Route
                                key={index}
                                index
                                element={menu.component}
                              />
                              <Route
                                key={index}
                                path={`${crudRoute.path}`}
                                element={crudRoute.component}
                              />
                            </Fragment>
                          );
                        })}
                      </Route>
                    );
                  } else {
                    return (
                      <Route
                        key={index}
                        path={menu.path}
                        element={menu.component}
                      />
                    );
                  }
                }
              });
            } else {
              if (
                route?.permissions?.find((sub) =>
                  JSON.parse(
                    localStorage.getItem("permisionStoroge")
                  )?.includes(sub)
                )
              ) {
                if (route.crudRoutes) {
                  return (
                    <Route key={index} path={route.path} element={<Outlet />}>
                      {route?.crudRoutes?.map((crudRoute, indexRpute) => {
                        return (
                          <Fragment key={index}>
                            <Route
                              key={index}
                              index
                              element={route.component}
                            />
                            <Route
                              key={indexRpute}
                              path={`${crudRoute.path}`}
                              element={crudRoute.component}
                            />
                          </Fragment>
                        );
                      })}
                    </Route>
                  );
                } else {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.component}
                    />
                  );
                }
              }
            }
          })}
        </Route>
        <Route
          path="*"
          element={
            <>
              <Error404 />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
