import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../../../context/app/context";
import DetailsPage from "../../../../form-components/view-details";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";

import moment from "moment";
import { ImCheckmark, ImCross } from "react-icons/im";
import PatentsClientPaymentContextProvider from "../../../../../context/patents-collection/client-payments/provider";
import PatentsOfficeCostContextProvider from "../../../../../context/patents-collection/office-cost/provider";
import PatnetsRequestContext from "../../../../../context/patents-collection/patents-request/context";
import PatnetsRequestContextProvider from "../../../../../context/patents-collection/patents-request/provider";
import PatentsRequestFilesContextProvider from "../../../../../context/patents-collection/patnets-request-files/provider";
import {
  getRequestStatus,
  getRequestStatusColor,
} from "../../../../../models/node/enum";

import Tag from "../../../../general/antd/tag";
import PatentsClientPaymentTable from "../../clients-payments/client-payment-table";
import PatentsFilesTable from "../../files/table";
import PatentsOfficeCostTable from "../../offiece-cost/office-cost-table";
import PatentsReservationTable from "../../reservations/table";
import PatentsTimeLine from "../../time-line";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
  getForColor,
  getForStatus,
} from "../../../../../models/workflow/enms-workflow/all-enums";

const Details = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(PatnetsRequestContext);
  const { direction } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  return (
    <DetailsPage title={t("patents_and_utility_models_details_request")}>
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel
                  header={t("patents_and_utility_models_details_request")}
                  key="1"
                >
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.depositNumber}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("patents_orders_templates")}
                    >
                      {details?.workflow?.name}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type_oreder_template")}>
                      {details?.workflow?.type ? (
                        <Tag
                          title={getAllBooleanOrderTemplate(
                            details?.workflow?.type
                          )}
                          color={getAllOrderTemplateColor(
                            details?.workflow?.type
                          )}
                        />
                      ) : (
                        "-"
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("patents_and_utility_models")}
                    >
                      {details?.patent ? (
                        <Tag title={details?.patent?.name} />
                      ) : (
                        "-"
                      )}
                      {/* {details?.drawings[0]?.name} */}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("request_status")}>
                      <Tag
                        title={getRequestStatus(Number(details?.status))}
                        color={getRequestStatusColor(Number(details?.status))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("country")}>
                      <Tag title={details?.workflow?.country?.name ?? "-"} />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("current_node")}>
                      {details?.currentNode?.name?.name ?? (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("created_at")}>
                      {moment(details?.createdAt).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("request_submitting_date")}>
                      {moment(details?.officialDate).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    {/* <AntdDescriptions.Item label={t("trademark")}>
                      {details?.trademark?.name}
                    </AntdDescriptions.Item> */}
                    <AntdDescriptions.Item label={t("first_publish_number")}>
                      {details?.firstPublishNumber ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("first_publish_date")}>
                      {details?.firstPublishDate
                        ? moment(details?.firstPublishDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("second_publish_number")}>
                      {details?.secondPublishNumber ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("second_publish_date")}>
                      {details?.secondPublishDate
                        ? moment(details?.secondPublishDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("client_order_cost")}>
                      {details?.clientCost ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("sum_client_payments")}>
                      {details?.clientPayment ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("sum_office_costs")}>
                      {details?.officeCosts ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("with_fine")}>
                      {details?.withFine ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("fine")}>
                      {details?.fine ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("total_request_cost")}>
                      {details?.totalCost}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_date")}>
                      {details?.registrationDate
                        ? moment(details?.registrationDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_number")}>
                      {details?.registrationNumber}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel
                  header={t("patents_and_utility_models_reservation")}
                  key={1}
                >
                  <PatentsReservationTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={8}>
              <Collapse>
                <Panel header={t("time_line_request")} key={1}>
                  <PatentsTimeLine />
                </Panel>
              </Collapse>
            </Col>
            <Col span={16}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("client_payments")} key={1}>
                      <PatentsClientPaymentTable />
                    </Panel>
                  </Collapse>
                </Col>
                <Col />
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("office_cost")} key={1}>
                      <PatentsOfficeCostTable />
                    </Panel>
                  </Collapse>
                </Col>
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("files")} key={1}>
                      <PatentsFilesTable />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </DetailsPage>
  );
};

const PatentsRequsetDetailsPage = () => {
  return (
    <PatnetsRequestContextProvider>
      <PatentsRequestFilesContextProvider>
        <PatentsOfficeCostContextProvider>
          <PatentsClientPaymentContextProvider>
            <Details />
          </PatentsClientPaymentContextProvider>
        </PatentsOfficeCostContextProvider>
      </PatentsRequestFilesContextProvider>
    </PatnetsRequestContextProvider>
  );
};
export default PatentsRequsetDetailsPage;
