import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillFileAdd } from "react-icons/ai";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import CopyrightReservationContext from "../../../../context/copyright-collection/copyright-reservation/context";
import CopyrightContext from "../../../../context/copyright-collection/copyright/context";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import { ICreateCopyrightReservation } from "../../../../models/copyright-collection/copyright-reservation/request";
import {
  getCopyrightColor,
  getCopyrightCountryColor,
  getCopyrightCountryType,
  getCopyrightType,
} from "../../../../models/copyright-collection/copyright/enum";
import { IUpdateCopyright } from "../../../../models/copyright-collection/copyright/request";
import { ICopyright } from "../../../../models/copyright-collection/copyright/response";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import { getTranslations } from "../../../../utils/helpers/translations";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import FormHookModal from "../../../general/modals/form-hook-modal";
import AddBtnTable from "../../../general/table-components/actions/add-table-btn";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import CopyrightReservationForm from "../../copyright-reservation/copyright-reservation-form";
import CopyrightFileForm from "../copyright-file-form";

interface IProps {}

const CopyrightTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } =
    useContext(CopyrightContext);

  const { actions: actionReservation, loading: loadingReservation } =
    useContext(CopyrightReservationContext);
  const [infoId, setInfoId] = useState(undefined);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={
          loadingReservation.includes("create") ||
          loadingReservation.includes("update")
        }
        title={t("add_copyright_reservation")}
        hideModal={() => {
          hideFormodal();
        }}
        width={"60vw"}
        onSubmit={async (data) => {
          await actionReservation.createCopyrightReservation(data);
          setInfoId(undefined);
        }}
        defaultValues={
          {
            copyrightId: infoId,
          } as ICreateCopyrightReservation
        }
      >
        <CopyrightReservationForm />
      </FormHookModal>
    ),
    [loading, infoId]
  );

  const [showFileFormModal, hideFileFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={loading.includes("create") || loading.includes("update")}
        title={t("add_file")}
        hideModal={() => {
          hideFileFormodal();
        }}
        width={"60vw"}
        style={{ marginTop: "4rem" }}
        onSubmit={async (data) => {
          await actions.updateCopyright(infoId, data);
          setInfoId(undefined);
        }}
        defaultValues={
          {
            name: getTranslations(details?.translations?.name),

            clients: details?.clients?.map((client) => client.id),
            countryType: details?.countryType,
            description: details?.description,
            mainCountryId: details?.mainCountry?.id,
            type: details?.type,
            explanation: details?.explanation,
            authorsDeathDate: details?.authorsDeathDate,
          } as IUpdateCopyright
        }
      >
        <CopyrightFileForm />
      </FormHookModal>
    ),
    [loading, infoId]
  );

  const columns: ColumnProps<ICopyright>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("name_ar"),
      dataIndex: "name",
      align: "center",
      key: "name",
      sorter: {
        multiple: 2,
      },
      render: (_, record) => {
        return <div>{record?.translations?.name[0]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("name_en"),
      dataIndex: "name",
      align: "center",
      sorter: {
        multiple: 3,
      },
      key: "name",
      render: (_, record) => {
        return <div>{record?.translations?.name[1]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("main_country"),
      dataIndex: "country",
      align: "center",
      width: 150,
      key: "mainCountry",
      render: (_, record) => {
        return <div>{record?.mainCountry?.name ?? "-"}</div>;
      },
    },
    {
      title: t("clients"),
      dataIndex: "clients",
      align: "center",
      key: "country",
      render: (_, record) => {
        return (
          <div>
            {record?.clients?.length > 0
              ? record?.clients?.map((a) => <Tag title={a.name ?? "-"} />)
              : "-"}
          </div>
        );
      },
    },
    {
      title: t("copyright_type"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.type ? (
              <Tag
                color={getCopyrightColor(record.type)}
                title={t(getCopyrightType(record.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("country_type"),
      dataIndex: "countryType",
      key: "status",
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.countryType ? (
              <Tag
                color={getCopyrightCountryColor(Number(record?.countryType))}
                title={t(getCopyrightCountryType(record?.countryType))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          {/* <AddBtnTable
            title="add_file"
            icon={<AiFillFileAdd />}
            onClick={async () => {
              setInfoId(record?.id);
              showFileFormModal();
            }}
          /> */}
          <AddBtnTable
            title="add_copyright_reservation"
            onClick={async () => {
              setInfoId(record?.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCopyrihgt(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default CopyrightTable;
