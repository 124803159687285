import { createContext } from "react";

import { IOfficeCostQuery } from "../../../models/trademark-collection/office-cost/query";
import {
  ICreateOfficeCost,
  IUpdateOfficeCost,
} from "../../../models/trademark-collection/office-cost/request";
import {
  IOfficeCost,
  IOfficeCostDetails,
} from "../../../models/trademark-collection/office-cost/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type OfficeCostLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OfficeCostLoading[];

  list?: IBaseListingResponse<IOfficeCost>;
  query: IOfficeCostQuery;

  details?: IOfficeCostDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IOfficeCostDetails) => void;

    createOfficeCost: (request: ICreateOfficeCost) => void;
    updateOfficeCost: (id: number, request: IUpdateOfficeCost) => void;
    deleteOfficeCost: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IOfficeCostQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createOfficeCost: DEFAULT_FUNCTION,
    updateOfficeCost: DEFAULT_FUNCTION,
    deleteOfficeCost: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const OfficeCostContext = createContext(externalState);

export default OfficeCostContext;
