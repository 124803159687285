import { useReducer } from "react";

import IncidentsClientsPaymentsContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../../../utils/helpers/execute";
import EndPoints from "../../../../services/end-points";
import { IIncidentsClientsPaymentsQuery } from "../../../../models/incidents-managments/incidents-clients/client-payments/query";
import {
  ICreateIncidentsClientsPayment,
  IUpdateIncidentsClientsPayment,
} from "../../../../models/incidents-managments/incidents-clients/client-payments/request";
import { IIIncidentsClientsPaymentDetails } from "../../../../models/incidents-managments/incidents-clients/client-payments/response";

export interface IProps {
  children: React.ReactNode;
}
const IncidentsClientsPaymentsContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.incidentsClientsPayment.getAllClientPayments({
            ...state.query,
            filters: [
              {
                name: "requestId",
                operation: "eq",
                value: id,
              },
            ],
          });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.incidentsClientsPayment.getClientPayment(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IIIncidentsClientsPaymentDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createClientPayment = async (
    request: ICreateIncidentsClientsPayment
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.incidentsClientsPayment.createClientPayment(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateClientPayment = async (
    id: number,
    request: IUpdateIncidentsClientsPayment
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.incidentsClientsPayment.updateClientPayment(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCleintPayment = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.incidentsClientsPayment.deleteClientPayment(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IIncidentsClientsPaymentsQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <IncidentsClientsPaymentsContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createClientPayment,
          updateClientPayment,
          deleteCleintPayment,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IncidentsClientsPaymentsContext.Provider>
  );
};

export default IncidentsClientsPaymentsContextProvider;
