import React, { useEffect, useReducer, useRef } from "react";
import { ConfigProvider, Empty, theme } from "antd";
import en from "../../utils/antd/en-locale";
import ar from "../../utils/antd/ar-locale";
import AppContext, { AppData, internalState, Themes } from "./context";
import reducer from "./reducer";
import ScreenSize from "../../utils/ui/screen-size";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../../utils/localization/i18n";

import {
  errorNotification,
  successNotification,
} from "../../utils/helpers/notification";
import { IconContext } from "react-icons";
import { ModalProvider } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import eventManager, {
  EVENT_ERORR,
  EVENT_FORBIDDEN,
  EVENT_SUCCESS,
  EVENT_UNAOUTHORIZED,
} from "../../utils/events";

interface IProps {
  children: React.ReactNode;
}
const AppContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);
  const effectCalled = useRef(false);

  /**
   * Events
   */
  const navigate = useNavigate();

  useEffect(() => {
    // default or light theme
    if (state.theme === "light") {
      document.documentElement.setAttribute("data-theme", "light");
    } else {
      document.documentElement.setAttribute("data-theme", "dark");
    }
  }, [state.theme]);

  useEffect(() => {
    if (!effectCalled.current) {
      effectCalled.current = true;
      eventManager.on(EVENT_SUCCESS, (message?: string) => {
        successNotification(message ?? t("operationDoneSuccessfully"));
      });
      eventManager.on(EVENT_ERORR, (message) => {
        errorNotification(message);
      });
      eventManager.on(EVENT_UNAOUTHORIZED, () => {
        navigate("/login", { replace: true });
      });
      eventManager.on(EVENT_FORBIDDEN, () => {
        navigate("/403", { replace: true });
      });
    }
  }, []);

  /**
   * Langauge
   */
  const {
    t,
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const direction = language === "ar" ? "rtl" : "ltr";
    dispatch({ type: "SET_DIRECTION", payload: { direction } });
    const locale = language === "ar" ? ar : en;
    dispatch({ type: "SET_LOCALE", payload: { locale } });
  }, [language]);

  /**
   * Media queries
   */
  const isLaptopOrDesktop = useMediaQuery({
    minWidth: 992,
  });
  const isMobileOrTablet = useMediaQuery({
    maxWidth: 992,
  });
  useEffect(() => {
    let screenSize: ScreenSize;

    if (isLaptopOrDesktop) screenSize = "laptopOrDesktop";
    else screenSize = "mobileOrTablet";

    dispatch({
      type: "SET_SCREEN_SIZE",
      payload: { screenSize },
    });
  }, [isLaptopOrDesktop, isMobileOrTablet]);

  const changeTheme = (theme: Themes) => {
    dispatch({ type: "SET_THEME", payload: { theme } });
  };

  return (
    <I18nextProvider i18n={i18n}>
      <ConfigProvider
        locale={state.locale}
        direction={state.direction}
        theme={{
          components: {
            Layout: {
              colorBgBody: state.theme === "light" ? "#F5F5F5" : "#1E2528",
            },
            Menu: {
              colorItemTextSelected:
                state.theme === "light" ? "#EB5E28" : "white",
            },
            Table: {},
            Notification: {
              colorErrorBg: state.theme === "light" ? "green" : "blue",
            },
          },

          token: {
            colorPrimary: "#EB5E28",
            colorBgBase: state.theme === "light" ? "white" : "#1e2528",
            controlOutlineWidth: 0,
            fontFamily: "Cairo",
          },

          algorithm:
            state.theme === "light"
              ? theme.defaultAlgorithm
              : theme.darkAlgorithm,
        }}
        renderEmpty={() => (
          <Empty
            image={
              state.theme === "light"
                ? Empty.PRESENTED_IMAGE_DEFAULT
                : Empty.PRESENTED_IMAGE_SIMPLE
            }
          />
        )}
      >
        <IconContext.Provider value={{ className: "react-icons-class" }}>
          <ModalProvider>
            <AppContext.Provider
              value={{
                ...state,
                actions: {
                  changeTheme,
                },
              }}
            >
              {props.children}
            </AppContext.Provider>
          </ModalProvider>
        </IconContext.Provider>
      </ConfigProvider>
    </I18nextProvider>
  );
};

export default AppContextProvider;
