import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ICustomeNode } from "../../../models/node/response";
import EndPoints from "../../../services/end-points";
import FieldBuilder from "../../form-components/field-builder";
import Spin from "../../general/antd/spin";
import styles from "./style.module.scss";
interface IProps {
  workFlowId: number;
  nodeId: number;
}

const AddExistingNode: React.FC<IProps> = ({ nodeId, workFlowId }) => {
  const [nodesLight, setNodesLight] = useState<any[]>([]);
  const [nodesLightLoding, setNodesLightLoging] = useState(true);
  const [details, setDetails] = useState<ICustomeNode[]>();
  const [detailsLoading, setDetailsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const getNodesLight = async () => {
      try {
        const { data } = await EndPoints.node.getAllNode({
          light: true,
          perPage: -1,
          filters: [
            {
              name: "workflow",
              value: workFlowId,
              operation: "eq",
            },
          ],
        });
        const filterParent = data?.data?.filter((a) => {
          return a.id !== nodeId;
        });
        setNodesLight(filterParent);
        setNodesLightLoging(false);
      } catch (error) {
      } finally {
        setNodesLightLoging(false);
      }
    };
    workFlowId && getNodesLight();
  }, [workFlowId]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const { data } = await EndPoints.node.getNode(nodeId);
        setDetails(data.childNodes);

        setDetailsLoading(false);
      } catch (error) {
      } finally {
        setDetailsLoading(false);
      }
    };
    nodeId && getDetails();
  }, [nodeId]);

  // results =  nodesLight.filter((a)=>{

  // })

  const results = nodesLight?.filter(
    ({ id: id1 }) => !details?.some(({ id: id2 }) => id2 === id1)
  );

  return (
    <>
      {nodesLightLoding && detailsLoading ? (
        <div style={{ height: "5rem" }}>
          <Spin />
        </div>
      ) : (
        <Row gutter={[6, 6]}>
          <Col span={24}>
            {results.length > 0 ? (
              <FieldBuilder
                name="nodeId"
                label={`${t("nodes")}`}
                width="large"
                disabled={nodesLightLoding}
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: nodesLightLoding,
                  options: results?.map((role, index) => {
                    return {
                      label: role.name.name,
                      value: role.id,
                    };
                  }),
                }}
              />
            ) : (
              <>
                <p className={styles.message}>
                  {t("there_is_no_nodes_to_add")}
                </p>
              </>
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default AddExistingNode;
