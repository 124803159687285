import { Button, Col, Modal, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import AppContext from "../../../../../context/app/context";
import OfficeCostContext from "../../../../../context/trademark-collection/office-cost/context";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import { IUpdateOfficeCost } from "../../../../../models/trademark-collection/office-cost/request";
import { IOfficeCost } from "../../../../../models/trademark-collection/office-cost/response";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import OfficeCostDetails from "../office-cost-details";
import OfficeCostForm from "../office-cost-form";

interface IProps {}

const OfficeCostTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [infoId, setInfoId] = useState(undefined);
  const { list, actions, loading, query, details } =
    useContext(OfficeCostContext);
  const { direction } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    actions.getData(Number(id));
  }, [direction, query]);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const showModalInfo = () => {
    setIsInfoModalVisible(true);
  };
  const handleCancelInfo = () => {
    setInfoId(undefined);
    actions.setDetails(undefined);
    setIsInfoModalVisible(false);
  };

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ass"
        confirmButtonText={t("save")}
        loading={
          loading.includes("details") ||
          loading.includes("update") ||
          loading.includes("create")
        }
        title={!details ? t("add_office_cost") : t("update_office_cost")}
        hideModal={() => {
          setInfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "3rem" }}
        defaultValues={
          {
            note: details?.note,
            value: details?.value,
            receiptNumber: details?.receiptNumber,
            trademarkRequestId: details?.trademarkRequest?.id ?? Number(id),
            date: details?.date,
            costTypeId: details?.costType?.id,
          } as IUpdateOfficeCost
        }
        width={"60vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updateOfficeCost(details?.id, data)
            : await actions.createOfficeCost(data);
          setInfoId(undefined);
          actions.getData(Number(id));
        }}
      >
        <OfficeCostForm />
      </FormHookModal>
    ),
    [details, loading]
  );
  const columns: ColumnProps<IOfficeCost>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 1,
      },
      width: 70,
      align: "center",
    },
    {
      title: t("value"),
      dataIndex: "value",
      align: "center",
      key: "value",

      render: (_, record) => {
        return <div>{record.value ?? "-"}</div>;
      },
    },
    {
      title: t("receipt_number"),
      dataIndex: "receiptNumber",
      align: "center",
      key: "receiptNumber",

      render: (_, record) => {
        return <div>{record.receiptNumber ?? "-"}</div>;
      },
    },
    {
      title: t("cost_type"),
      dataIndex: "costType",
      width: 150,
      align: "center",
      key: "costType",

      render: (_, record) => {
        return <div>{record.costType?.name ?? "-"}</div>;
      },
    },
    {
      title: t("date_payment"),
      dataIndex: "date",
      align: "center",
      key: "date",
      render: (date: Date) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: t("actions"),
      width: 100,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              setInfoId(record?.id);
              showModalInfo();
            }}
          />
          <EditBtn
            onClick={() => {
              setInfoId(record?.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteOfficeCost(record?.id);
              await actions.getData(Number(id));
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Button
          type="primary"
          onClick={() => {
            showFormModal();
          }}
        >
          {t("add_office_cost")}
        </Button>
      </Col>
      <Col span={24}>
        <>
          <Table<any>
            rowKey="id"
            showPagination={true}
            columns={columns}
            dataSource={list?.data ?? []}
            loading={loading.includes("list")}
            size="small"
            total={list?.total}
            pageSize={query.perPage}
            page={query.page}
            onPaginationChange={(page, pageSize) => {
              actions.setQuery({
                ...query,
                page,
                perPage: pageSize,
              });
            }}
            onChange={(_, tableFilters, tabelSorters) => {
              tableOnChange(
                tableFilters,
                tabelSorters,
                tableFiltersProps,
                setSorts,
                setStaticFilters
              );
            }}
          />
        </>
      </Col>
      <Modal
        destroyOnClose
        title={t("office_cost_details")}
        visible={isInfoModalVisible}
        onOk={handleCancelInfo}
        onCancel={handleCancelInfo}
        cancelText={t("cancel")}
        okText={t("ok")}
        centered={true}
        okButtonProps={{ style: { textAlign: "center" } }}
        bodyStyle={{ minHeight: 200 }}
        width={800}
      >
        <OfficeCostDetails />
      </Modal>
    </Row>
  );
};

export default OfficeCostTable;
