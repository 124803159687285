import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import reducer from "./reducer";
import AgentRegistrationContext, { internalState } from "./context";
import { IAgentRegistrationDetails } from "../../../models/independent-requests-collection/agent-registration/response";
import {
  ICreateAgentRegistration,
  IUpdateAgentRegistration,
} from "../../../models/independent-requests-collection/agent-registration/request";
import { IAgentRegistrationQuery } from "../../../models/independent-requests-collection/agent-registration/query";
import moment from "moment";

export interface IProps {
  children: React.ReactNode;
}
const AgentRegistrationContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.agentRegistration.getAllAgentRegistration(
            state.query
          );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.agentRegistration.getAgentRegistration(
          id
        );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IAgentRegistrationDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createAgentRegistration = async (request: ICreateAgentRegistration) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.agentRegistration.createAgentRegistration(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateAgentRegistration = async (
    id: number,
    request: IUpdateAgentRegistration
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.agentRegistration.updateAgentRegistration(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteAgentRegistration = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.agentRegistration.deleteAgentRegistration(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IAgentRegistrationQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.agentRegistration.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `AgentRegistration-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <AgentRegistrationContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          exportExcel,
          createAgentRegistration,
          updateAgentRegistration,
          deleteAgentRegistration,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </AgentRegistrationContext.Provider>
  );
};

export default AgentRegistrationContextProvider;
