import { createContext } from "react";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IIncidentsLicensingRequesFiles,
  IIncidentsRequesLicensingFilesDetails,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/response";
import { IIncidentsLicensingRequestFilesQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/query";
import {
  ICreateIncidentsLicensingRequestFiles,
  IUpdateIncidentsLicensingRequestFiles,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-requset-files/request";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<IIncidentsLicensingRequesFiles>;
  query: IIncidentsLicensingRequestFilesQuery;

  details?: IIncidentsRequesLicensingFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsRequesLicensingFilesDetails) => void;

    createIncidentRequsetFile: (
      request: ICreateIncidentsLicensingRequestFiles
    ) => void;
    updateIncidentRequsetFile: (
      id: number,
      request: IUpdateIncidentsLicensingRequestFiles
    ) => void;
    deleteIncidentRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsLicensingRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createIncidentRequsetFile: DEFAULT_FUNCTION,
    updateIncidentRequsetFile: DEFAULT_FUNCTION,
    deleteIncidentRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentLicensingRequestFilesContext = createContext(externalState);

export default IncidentLicensingRequestFilesContext;
