import {
  Descriptions as AntdDescriptions,
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CopyrightRequestContext from "../../../../context/copyright-collection/copyright-request/context";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { ITraderMarkOrderTeamplate } from "../../../../models/workflow/response";
import {
  ICopyright,
  ICopyrightDetails,
} from "../../../../models/copyright-collection/copyright/response";
import { IUpdateCopyrightRequest } from "../../../../models/copyright-collection/copyright-request/request";
import { execute } from "../../../../utils/helpers/execute";
import EndPoints from "../../../../services/end-points";
import { RangePickerProps } from "antd/es/date-picker";
import styles from "./style.module.scss";
import Spin from "../../../general/antd/spin";
import Controller from "../../../form-components/controller";
import Descriptions from "../../../general/antd/descriptions";
import Tag from "../../../general/antd/tag";
import { getRequestStatus } from "../../../../models/node/enum";
import CopyrightRequestContextProvider from "../../../../context/copyright-collection/copyright-request/provider";
import FormItem from "../../../general/form-item";
import FieldBuilder from "../../../form-components/field-builder";
const Form = () => {
  const { details, actions, loading } = useContext(CopyrightRequestContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [copyrightOrderTemplates, setCopyrightOrderTemplate] = useState<
    ITraderMarkOrderTeamplate[]
  >([]);
  const [copyrightOrderTemplateLoading, setCopyrightOrderTemplateLoading] =
    useState(true);

  const [copyrihgts, setCopyrights] = useState<ICopyright[]>([]);
  const [copyrightsLoading, setCopyrightsLoading] = useState(true);
  const [copyrightDetails, setCopyrightDetails] = useState<ICopyrightDetails>();
  const [copyrightsDetailsLoading, setCopyrightDetailsLoading] =
    useState(false);
  // const [details, setDetails] = useState<ITrademarkRequestDetails>();
  // const [detailsLoading, setDetailsLoading] = useState(true);

  const requestStatus = [1, 2, 3];
  const Option = Select.Option;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const methods = useForm<any>({
    defaultValues: {
      officialDate: details?.officialDate,
      clientCost: details?.clientCost,
      depositNumber: details?.depositNumber,
      status: details?.status,
      copyrightId: details?.copyright?.id,
      workflowId: details?.workflow?.id,
    } as IUpdateCopyrightRequest,
  });
  const { handleSubmit, reset, control } = methods;

  const countryId = useWatch({
    control,
    name: "country",
  });
  const copyrightId = useWatch({
    control,
    name: "copyrightId",
  });

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.copyrightRequest.updateCopyrightRequest(
              details?.id,
              data
            )
          : await EndPoints.copyrightRequest.createCopyrightRequests(data);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  // get all countries in stystem
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  // get copyrihgts
  useEffect(() => {
    const getCopyrights = async () => {
      try {
        const { data } = await EndPoints.copyright.getAllCopyright({
          perPage: -1,
        });
        setCopyrights(data.data);
      } catch (error) {
      } finally {
        setCopyrightsLoading(false);
      }
    };
    getCopyrights();
  }, []);

  useEffect(() => {
    const getPatentsOrderTemplates = async () => {
      try {
        setCopyrightOrderTemplateLoading(true);

        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          filters: [
            {
              name: "countryId",
              operation: "eq",
              value: countryId,
            },
            {
              name: "for",
              operation: "eq",
              value: 4,
            },
          ],
        });
        setCopyrightOrderTemplate(data.data);
      } catch (error) {
      } finally {
        setCopyrightOrderTemplateLoading(false);
      }
    };
    getPatentsOrderTemplates();
  }, [countryId]);

  useEffect(() => {
    const getCopyrightDetails = async () => {
      try {
        setCopyrightDetailsLoading(true);
        const { data } = await EndPoints.copyright.getCopyright(copyrightId);
        setCopyrightDetails(data);
      } catch (error) {
      } finally {
        setCopyrightDetailsLoading(false);
      }
    };
    copyrightId && getCopyrightDetails();
  }, [copyrightId]);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    reset({
      clientCost: details?.clientCost,
      country: details?.workflow?.country?.id,
      copyrightId: details?.copyright?.id,
      workflowId: details?.workflow?.id,
      depositNumber: details?.depositNumber,
      officialDate: details?.officialDate,
      status: details?.status,
      note: details?.note,
      registrationDate: details?.registrationDate,
      registrationNumber: details?.registrationNumber,
    } as IUpdateCopyrightRequest);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div>
          <span className={styles.title}>
            {details
              ? t("update_copyright_request")
              : t("add_copyright_request")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <FormItem label={t("countries")} required>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            placeholder={t("countries")}
                            options={countries.map((country, index) => {
                              return {
                                label: country.name as string,
                                value: country.id as number,
                              };
                            })}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("patents_orders_templates")} required>
                    <Controller
                      name="workflowId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={copyrightOrderTemplateLoading}
                            // disabled={countryId ? false : true}
                            placeholder={t("patents_orders_templates")}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                          >
                            {copyrightOrderTemplates?.map((ca, index) => {
                              return (
                                <Option key={index} value={ca.id}>
                                  {ca.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                {/* <Col span={8}>
                  <FormItem label={t("copyright_orders_templates")} required>
                    <Controller
                      name="workflowId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            loading={copyrightOrderTemplateLoading}
                            placeholder={t("copyright_orders_templates")}
                            style={{ width: "100%" }}
                          >
                            {copyrightOrderTemplates?.map((cr, index) => {
                              return (
                                <Option key={index} value={cr.id}>
                                  {cr.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col> */}
                <Col span={8}>
                  <FormItem label={t("copyright")} required>
                    <Controller
                      name="copyrightId"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={copyrightsLoading}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            // showSearch
                            placeholder={t("patent")}
                          >
                            {copyrihgts?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr.id}>
                                  {tr.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <Descriptions size="small" column={2} bordered>
                    <AntdDescriptions.Item label={t("clients")}>
                      {copyrightsDetailsLoading ? (
                        <div>
                          <Spin notViewWordLoading={true} size="small" />
                        </div>
                      ) : (
                        <>
                          {copyrightDetails?.clients?.length > 0 ? (
                            copyrightDetails?.clients?.map((client, index) => {
                              return <Tag key={index} title={client?.name} />;
                            })
                          ) : (
                            <Tag title="not_found" color="red" />
                          )}
                        </>
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={6}>
                  <FormItem label={t("request_status")}>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={copyrightOrderTemplateLoading}
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("request_status")}
                          >
                            {requestStatus?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {t(getRequestStatus(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={6}>
                  <FormItem label={t("deposit_number")}>
                    <Controller
                      name="depositNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("deposit_number")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("client_cost")}>
                    <Controller
                      name="clientCost"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("client_cost")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label={t("request_submitting_date")} required>
                    <Controller
                      name="officialDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("registration_number")}>
                    <Controller
                      name="registrationNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("registration_number")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("registration_date")}>
                    <Controller
                      name="registrationDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={24}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="note"
                    label={`${t("note")}`}
                    input={{ type: "text-area" }}
                  />
                </Col>
                <Col span={24}>
                  {details && (
                    <>
                      <Descriptions size="middle" column={2} bordered>
                        <AntdDescriptions.Item label={t("current_node")}>
                          {details?.currentNode?.name?.name}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("clien_cost")}>
                          {details?.clientCost}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_payments")}>
                          {details?.clientPayment}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("office_cost")}>
                          {details?.officeCosts}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("total_cost")}>
                          {details?.totalCost}
                        </AntdDescriptions.Item>
                      </Descriptions>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const CopyrightRequestsForm = () => {
  return (
    <CopyrightRequestContextProvider>
      <Form />
    </CopyrightRequestContextProvider>
  );
};
export default CopyrightRequestsForm;
