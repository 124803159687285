import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../../form-components/view-details";
import Descriptions from "../../../../general/antd/descriptions";
import EditeButton from "../../../../general/antd/edite-button";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";

import type { TabsProps } from "antd";
import { Tabs } from "antd";
import moment from "moment";

import {
  getRequestStatus,
  getRequestStatusColor,
} from "../../../../../models/node/enum";
import CommercialClientsPaymentTable from "../../clients-payments/client-payment-table";
import CommercialFilesTable from "../../files/table";
import CommercialOfficeCostTable from "../../office-cost/office-cost-table";
import CommercialTimeLine from "../../time-line";
import CopyrightTable from "../tables/copyright-reservation";
import DrawingTable from "../tables/drawing-reservation";
import PatentsTable from "../tables/patents-reservation";
import TrademarkTable from "../tables/trademark-reservation";
import styles from "./styles.module.scss";
import CommercialRequestFilesContextProvider from "../../../../../context/lawswits-collection/commercial/incident-request-files/provider";
import CommercialClientsaymentContextProvider from "../../../../../context/lawswits-collection/commercial/client-payments/provider";
import CommercialOfficeCostContextProvider from "../../../../../context/lawswits-collection/commercial/office-cost/provider";
import CommerciaContextProvider from "../../../../../context/lawswits-collection/commercial/commercial/provider";
import CommercialsContext from "../../../../../context/lawswits-collection/commercial/commercial/context";

interface Props {}
const { Panel } = Collapse;

const CommercialDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CommercialsContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("trademark_reservation"),
      children: (
        <>
          <TrademarkTable />
        </>
      ),
    },
    {
      key: "2",
      label: t("reservations_drawing"),
      children: (
        <>
          <DrawingTable />
        </>
      ),
    },

    {
      key: "3",
      label: t("patents_and_utility_models_reservation"),
      children: (
        <>
          <PatentsTable />
        </>
      ),
    },
    {
      key: "4",
      label: t("copyright_reservation"),
      children: (
        <>
          <CopyrightTable />
        </>
      ),
    },
  ];

  return (
    <>
      <DetailsPage title={t("commercial_details")}>
        {loading.includes("details") ? (
          <div style={{ height: "50vh" }}>
            <Spin />
          </div>
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("commercial_details")} key="1">
                    <EditeButton
                      onClick={() => navigate(`/commercial/update/${id}`)}
                      className={styles.button}
                    >
                      {t(`${"update_commercial"}`)}
                    </EditeButton>
                    {loading.includes("details") ? (
                      <Spin />
                    ) : (
                      <Descriptions size="small" column={1} bordered>
                        <AntdDescriptions.Item label={t("id")}>
                          {details?.id}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("request_status")}>
                          <Tag
                            title={getRequestStatus(Number(details?.status))}
                            color={getRequestStatusColor(
                              Number(details?.status)
                            )}
                          />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("country")}>
                          <Tag
                            title={details?.workflow?.country?.name ?? "-"}
                          />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("workflow")}>
                          <Tag title={details?.workflow?.name ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("licener")}>
                          {details?.clients?.length > 0
                            ? details?.clients?.map((ass, index) => {
                                return <Tag key={index} title={ass?.name} />;
                              })
                            : "-"}
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("client_cost")}>
                          <Tag title={details?.clientCost ?? "-"} />
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_payment")}>
                          <Tag title={details?.clientPayment ?? "-"} />
                        </AntdDescriptions.Item>
                        {/* <AntdDescriptions.Item label={t("office_costs")}>
                          <Tag title={details?.officeCosts ?? "-"} />
                        </AntdDescriptions.Item> */}
                        <AntdDescriptions.Item label={t("current_node")}>
                          {details?.currentNode?.name?.name ?? (
                            <Tag title="not_found" color="red" />
                          )}
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("created_at")}>
                          {moment(details?.createdAt).format("DD/MM/YYYY")}
                        </AntdDescriptions.Item>

                        <AntdDescriptions.Item label={t("description")}>
                          {details?.description ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: details?.description,
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </AntdDescriptions.Item>
                      </Descriptions>
                    )}
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Tabs defaultActiveKey="1" items={items} />
              </Col>

              <Col span={8}>
                <Collapse>
                  <Panel header={t("time_line_request")} key={1}>
                    <CommercialTimeLine />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={16}>
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("client_payments")} key={1}>
                        <CommercialClientsPaymentTable />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col />
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("office_cost")} key={1}>
                        <CommercialOfficeCostTable />
                      </Panel>
                    </Collapse>
                  </Col>
                  <Col span={24}>
                    <Collapse>
                      <Panel header={t("files")} key={1}>
                        <CommercialFilesTable />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </DetailsPage>
    </>
  );
};

const CommercialFormDetailsPage = () => {
  return (
    <CommercialRequestFilesContextProvider>
      <CommercialClientsaymentContextProvider>
        <CommercialOfficeCostContextProvider>
          <CommerciaContextProvider>
            <CommercialDetails />
          </CommerciaContextProvider>
        </CommercialOfficeCostContextProvider>
      </CommercialClientsaymentContextProvider>
    </CommercialRequestFilesContextProvider>
  );
};
export default CommercialFormDetailsPage;
