import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import {
  IWiverRequest,
  IWiverRequestDetails,
} from "../../../models/independent-requests-collection/waiver-request/response";
import { IWiverRequestQuery } from "../../../models/independent-requests-collection/waiver-request/query";
import {
  ICreateWiverRequest,
  IUpdateWiverRequest,
} from "../../../models/independent-requests-collection/waiver-request/request";

export type WiverRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: WiverRequestLoading[];

  list?: IBaseListingResponse<IWiverRequest>;
  query: IWiverRequestQuery;

  details?: IWiverRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IWiverRequestDetails) => void;

    createWiverRequest: (request: ICreateWiverRequest) => void;
    updateWiverRequest: (id: number, request: IUpdateWiverRequest) => void;
    deleteWiverRequest: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IWiverRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createWiverRequest: DEFAULT_FUNCTION,
    updateWiverRequest: DEFAULT_FUNCTION,
    deleteWiverRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const WiverRequestContext = createContext(externalState);

export default WiverRequestContext;
