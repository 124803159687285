import { ICountryQuery } from "../../../models/managent-collection/country/query";
import {
  ICountry,
  ICountryDetails,
} from "../../../models/managent-collection/country/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { CountryLoading, IInternalState } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: CountryLoading | CountryLoading[] } }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<ICountry> } }
  | {
      type: "SET_LIST_LIGHT";
      payload: { light: IBaseListingResponse<ICountry> };
    }
  | { type: "SET_DETAILS"; payload: { details?: ICountryDetails } }
  | QueryAction<ICountryQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }
    case "SET_LIST_LIGHT": {
      return {
        ...state,
        list: action.payload.light,
      };
    }
    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
