import { createContext } from "react";
import { INodeQuery } from "../../models/node/query";
import { ICreateNode, IUpdateNode } from "../../models/node/request";
import { INode, INodeDetails, INodeLight } from "../../models/node/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../utils/costants";
import { DEFAULT_QUERY } from "../../utils/helpers/constants";

export type NodeLoading = "list" | "details" | "create" | "update" | "delete";

export interface IInternalState {
  loading: NodeLoading[];

  list?: IBaseListingResponse<INode>;
  query: INodeQuery;

  hoverNodeId?: number;

  details?: INodeDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;

    getDetails: (id: number) => void;
    setDetails: (data?: INodeDetails) => void;

    createNode: (request: ICreateNode) => void;
    updateNode: (id: number, request: IUpdateNode) => void;
    deleteNode: (id: number, data: any) => void;

    setHoverNodeId: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: INodeQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    setHoverNodeId: DEFAULT_FUNCTION,

    createNode: DEFAULT_FUNCTION,
    updateNode: DEFAULT_FUNCTION,
    deleteNode: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const NodeContext = createContext(externalState);

export default NodeContext;
