import { ITraderMarkOrderTeamplateQuery } from "../../models/workflow/query";
import {
  INodeTree,
  ITraderMarkOrderTeamplate,
  ITraderMarkOrderTeamplateDetails,
} from "../../models/workflow/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { toggleLoading } from "../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../base/base-reducer";
import { IInternalState, trademarkOrderTemplateLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: {
        loading:
          | trademarkOrderTemplateLoading
          | trademarkOrderTemplateLoading[];
      };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<ITraderMarkOrderTeamplate> };
    }
  | {
      type: "SET_LIST_LIGHT";
      payload: { light: IBaseListingResponse<ITraderMarkOrderTeamplate> };
    }
  | {
      type: "SET_DETAILS";
      payload: { details?: ITraderMarkOrderTeamplateDetails };
    }
  | { type: "SET_TREE"; payload: { tree?: INodeTree } }
  | QueryAction<ITraderMarkOrderTeamplateQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }
    case "SET_LIST_LIGHT": {
      return {
        ...state,
        list: action.payload.light,
      };
    }
    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }
    case "SET_TREE": {
      return {
        ...state,
        tree: action.payload.tree,
      };
    }
    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
