import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import Button, { ButtonProps } from "../../../antd/button";
import { Tooltip } from "antd";
import { BiArchive } from "react-icons/bi";
interface Props extends ButtonProps {
  title?: string;
  onClick?: () => void;
}

const ArchiveBtn: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("archive")}>
      <Button type="primary" shape="circle" icon={<BiArchive />} {...props} />
    </Tooltip>
  );
};

export default ArchiveBtn;
