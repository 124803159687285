import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { IDisclosureTrademarkRequestQuery } from "../../../models/independent-requests-collection/disclosure-trademark-request/query";
import {
  IDisclosureTrademarkRequest,
  IDisclosureTrademarkRequestDetails,
} from "../../../models/independent-requests-collection/disclosure-trademark-request/response";
import {
  ICreateDisclosureTrademarkRequest,
  IExportDisclosureTrademarkRequestExcel,
  IUpdateDisclosureTrademarkRequest,
} from "../../../models/independent-requests-collection/disclosure-trademark-request/request";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class DisclosureTrademarkRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDisclosureTrademarkRequest = (
    query: IDisclosureTrademarkRequestQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDisclosureTrademarkRequest>>({
      path: `/disclosure-trademark-request`,
      method: "GET",
      query,
      ...params,
    });

  getDisclosureTrademarkRequest = (id: number, params?: RequestParams) =>
    this.http.request<IDisclosureTrademarkRequestDetails>({
      path: `/disclosure-trademark-request/${id}`,
      method: "GET",
      ...params,
    });

  createDisclosureTrademarkRequest = (
    data: ICreateDisclosureTrademarkRequest,
    params?: RequestParams
  ) =>
    this.http.request<IDisclosureTrademarkRequest>({
      path: "/disclosure-trademark-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateDisclosureTrademarkRequest = (
    id: number,
    data: IUpdateDisclosureTrademarkRequest,
    params?: RequestParams
  ) =>
    this.http.request<IDisclosureTrademarkRequest>({
      path: `/disclosure-trademark-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDisclosureTrademarkRequest = (id: number, params?: RequestParams) =>
    this.http.request<IDisclosureTrademarkRequest>({
      path: `/disclosure-trademark-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportDisclosureTrademarkRequestExcel,
    query: IDisclosureTrademarkRequestQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/disclosure-trademark-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default DisclosureTrademarkRequestService;
