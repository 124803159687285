import { Descriptions as AntdDescriptions, Descriptions } from "antd";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { IDrawingReservation } from "../../../../../models/drawing-collection/drawing-reservation/response";

interface IProps {
  details: IDrawingReservation;
}
const ReservationDetails: React.FC<IProps> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <>
      <Descriptions size="small" column={2} bordered>
        <AntdDescriptions.Item label={t("id")}>
          {details?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("country")}>
          {details?.country?.name ?? "-"}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("deposit_number")}>
          {details?.depositNumber ?? "-"}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("deposit_date")}>
          {details?.depositDate
            ? moment(details?.depositDate).format("DD/MM/YYYY ")
            : "-"}
        </AntdDescriptions.Item>

        <AntdDescriptions.Item label={t("last_renewal_number")}>
          {details?.lastRenewalNumber ? details?.lastRenewalNumber : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("renewal_counts")}>
          {details?.renewalCounts ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("last_renewal_date")}>
          {details.lastRenewalDate ? details.lastRenewalDate.slice(0, 10) : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("next_renewal_date")}>
          {details.nextRenewalDate ? details.nextRenewalDate.slice(0, 10) : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("registration_number")}>
          {details?.registrationNumber ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("registration_date")}>
          {details?.registrationDate
            ? moment(details?.registrationDate).format("DD/MM/YYYY ")
            : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("renewal_counts")}>
          {details?.renewalCounts ?? "-"}
        </AntdDescriptions.Item>
      </Descriptions>
    </>
  );
};

export default ReservationDetails;
