import { createContext } from "react";

import { ITrademarkRequestFilesQuery } from "../../../models/trademark-collection/trademark-requset-files/query";
import {
  ICreateTrademarkRequestFiles,
  IUpdateTrademarkRequestFiles,
} from "../../../models/trademark-collection/trademark-requset-files/request";
import {
  ITrademarkRequesFiles,
  ITrademarkRequesFilesDetails,
} from "../../../models/trademark-collection/trademark-requset-files/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<ITrademarkRequesFiles>;
  query: ITrademarkRequestFilesQuery;

  details?: ITrademarkRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ITrademarkRequesFilesDetails) => void;

    createTrademarkRequsetFile: (request: ICreateTrademarkRequestFiles) => void;
    updateTrademarkRequsetFile: (
      id: number,
      request: IUpdateTrademarkRequestFiles
    ) => void;
    deleteTrademarkRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ITrademarkRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createTrademarkRequsetFile: DEFAULT_FUNCTION,
    updateTrademarkRequsetFile: DEFAULT_FUNCTION,
    deleteTrademarkRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const TrademarkRequestFilesContext = createContext(externalState);

export default TrademarkRequestFilesContext;
