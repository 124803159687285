import { Button, Col, Modal, Row } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PatnetsRequestContext from "../../../../../context/patents-collection/patents-request/context";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../../models/patents-collection/patents-reservation/enum";
import { IPatentsReservation } from "../../../../../models/patents-collection/patents-reservation/response";
import EndPoints from "../../../../../services/end-points";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import Space from "../../../../general/antd/space";
import Tag from "../../../../general/antd/tag";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import ReservationDetails from "../details";
import PatentsReservation from "../form";
import CopyrightRequestContext from "../../../../../context/copyright-collection/copyright-request/context";
import CopyrightsReservation from "../form";

interface IProps {}

const CopyrightReservationTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isInfoReservationModalVisible, setIsReservationInfoModalVisible] =
    useState(false);
  const [reservationDetails, setReservationDetails] =
    useState<IPatentsReservation>();

  const { actions, loading, details } = useContext(CopyrightRequestContext);

  const showModalInfo = () => {
    setIsInfoModalVisible(true);
  };
  const handleCancelInfo = () => {
    setIsInfoModalVisible(false);
  };

  const showModalReservationInfo = () => {
    setIsReservationInfoModalVisible(true);
  };
  const handleCancelReservationInfo = () => {
    setIsReservationInfoModalVisible(false);
  };

  const { id } = useParams();
  const columns: ColumnProps<IPatentsReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "s",
      align: "center",

      key: "s",
      render: (_, record) => {
        return <div>{record?.patent?.name}</div>;
      },
    },

    {
      title: t("types"),
      dataIndex: "status",
      align: "center",

      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getPatentsReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("country"),
      dataIndex: "status",
      align: "center",

      key: "status",
      render: (_, record) => {
        return <Tag title={record?.country?.name} />;
      },
    },

    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>{depositDate ? moment(depositDate).format("DD/MM/YYYY ") : "-"}</p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY ")
              : "-"}{" "}
          </p>
        );
      },
    },
    // {
    //   title: t("last_renewal_date"),
    //   dataIndex: "lastRenewalDate",
    //   align: "center",

    //   key: "lastRenewalDate",
    //   render: (_, {  }) => {
    //     return <p> {lastRenewalDate ? lastRenewalDate.slice(0, 10) : "-"} </p>;
    //   },
    // },

    // {
    //   title: t("date"),
    //   dataIndex: "date",
    //   align: "center",
    //   key: "date",
    //   render: (date: Date) => moment(date).format("DD/MM/YYYY"),
    // },

    {
      title: t("actions"),
      width: 100,
      dataIndex: "",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={() => {
              setReservationDetails(record);
              showModalReservationInfo();
            }}
          />

          <DeleteBtn
            onConfirm={async () => {
              await EndPoints.copyrightRequest.deleteCopyrightReservations(
                details?.id,
                {
                  copyrightReservation: record?.id,
                }
              );
              await actions.getDetails(Number(id));
              await eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Button type="primary" onClick={() => showModalInfo()}>
          {t("add_copyright_reservation")}
        </Button>
      </Col>
      <Col span={24}>
        <Table<any>
          rowKey="id"
          style={{ height: "100%" }}
          columns={columns}
          size="small"
          dataSource={details?.Reservations ?? []}
          loading={loading.includes("details")}
        />
      </Col>
      <Modal
        destroyOnClose
        title={t("add_copyright_reservation")}
        open={isInfoModalVisible}
        onOk={handleCancelInfo}
        onCancel={handleCancelInfo}
        cancelText={t("cancel")}
        okText={t("ok")}
        centered={true}
        okButtonProps={{ style: { textAlign: "center" } }}
        bodyStyle={{ maxHeight: 300, overflowY: "scroll", overflowX: "hidden" }}
        width={1200}
      >
        <CopyrightsReservation id={details?.id} />
      </Modal>
      <Modal
        destroyOnClose
        title={t("details_reservation")}
        open={isInfoReservationModalVisible}
        onOk={handleCancelReservationInfo}
        onCancel={handleCancelReservationInfo}
        cancelText={t("cancel")}
        okText={t("ok")}
        centered={true}
        okButtonProps={{ style: { textAlign: "center" } }}
        bodyStyle={{ maxHeight: 500, overflowY: "scroll", overflowX: "hidden" }}
        width={1200}
      >
        <ReservationDetails details={reservationDetails} />
      </Modal>
    </Row>
  );
};

export default CopyrightReservationTable;
