export enum BooleanStatus {
  LOCAL = 1,
  FOREIGN = 2
  }
  
  export const getTrademarkColor = (status: BooleanStatus) => {
    switch (status) {
      case  BooleanStatus.LOCAL:
        return 'blue'
        case BooleanStatus.FOREIGN:
        return 'orange'
    }
  }
  
  // Status Boolean stuts
  export const getBooleanTrademark = (status: BooleanStatus) => {
    switch (status) {
      case  BooleanStatus.LOCAL:
        return "local"
      case BooleanStatus.FOREIGN:
        return "foreign"
    }
  }

