import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import DrawingReservationContext from "../../../../context/drawing-collection/drawing-reservation/context";
import DrawingContext from "../../../../context/drawing-collection/drawing/context";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import { ICreateDrawingReservation } from "../../../../models/drawing-collection/drawing-reservation/request";
import {
  getDrawingColor,
  getDrawingCountryColor,
  getDrawingCountryType,
  getDrawingType,
} from "../../../../models/drawing-collection/drawing/enum";
import { IDrawing } from "../../../../models/drawing-collection/drawing/response";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import FormHookModal from "../../../general/modals/form-hook-modal";
import AddBtnTable from "../../../general/table-components/actions/add-table-btn";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import DrawingReservationForm from "../../drawing-reservation/drawing-reservation-form";

interface IProps {}

const DrawingTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(DrawingContext);

  const { actions: actionReservation, loading: loadingReservation } =
    useContext(DrawingReservationContext);
  const [infoId, setInfoId] = useState(undefined);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={loadingReservation.includes("create")}
        title={t("add_drawing_reservation")}
        hideModal={() => {
          hideFormodal();
        }}
        width={"60vw"}
        onSubmit={async (data) => {
          await actionReservation.createDrawingReservation(data);
          setInfoId(undefined);
        }}
        defaultValues={
          {
            drawingId: infoId,
          } as ICreateDrawingReservation
        }
      >
        <DrawingReservationForm />
      </FormHookModal>
    ),
    [loading, infoId]
  );

  const columns: ColumnProps<IDrawing>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("name_ar"),
      dataIndex: "name",
      align: "center",
      key: "name",
      sorter: {
        multiple: 2,
      },
      render: (_, record) => {
        return <div>{record.translations?.name[0]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("name_en"),
      dataIndex: "name",
      sorter: {
        multiple: 3,
      },
      align: "center",
      key: "name",
      render: (_, record) => {
        return <div>{record.translations?.name[1]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("main_country"),
      dataIndex: "country",
      align: "center",
      width: 150,
      key: "mainCountry",
      render: (_, record) => {
        return <div>{record.mainCountry?.name ?? "-"}</div>;
      },
    },
    {
      title: t("clients"),
      dataIndex: "clients",
      align: "center",
      key: "country",
      render: (_, record) => {
        return (
          <div>
            {record.clients.length > 0
              ? record.clients?.map((a) => <Tag title={a.name ?? "-"} />)
              : "-"}
          </div>
        );
      },
    },
    {
      title: t("drawing_type"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.type ? (
              <Tag
                color={getDrawingColor(record?.type)}
                title={t(getDrawingType(record.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("country_type"),
      dataIndex: "countryType",
      key: "status",
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.countryType ? (
              <Tag
                color={getDrawingCountryColor(record?.countryType)}
                title={t(getDrawingCountryType(record.countryType))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <AddBtnTable
            title="add_drawing_reservation"
            onClick={async () => {
              setInfoId(record?.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteDrawing(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default DrawingTable;
