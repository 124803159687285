import { INode, INodeDetails, INodeLight } from "../../models/node/response";
import { ITraderMarkOrderTeamplateQuery } from "../../models/workflow/query";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { toggleLoading } from "../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../base/base-reducer";
import { IInternalState, NodeLoading } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: NodeLoading | NodeLoading[] } }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<INode> } }
  | { type: "SET_DETAILS"; payload: { details?: INodeDetails } }
  | { type: "SET_HOVER_NODE_ID"; payload: { hoverNodeId: number } }
  | QueryAction<ITraderMarkOrderTeamplateQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }
    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }
    case "SET_HOVER_NODE_ID": {
      return {
        ...state,
        hoverNodeId: action.payload.hoverNodeId,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
