import { createContext } from "react";
import { IBaseQuery } from "../../models/base/base-query";
import { IClient, IClientDetails } from "../../models/client/response";
import { ICreateClient, IUpdateClient } from "../../models/client/request";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../utils/costants";
import { DEFAULT_QUERY } from "../../utils/helpers/constants";

export type ClientleLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "copy"
  | "delete";

export interface IInternalState {
  loading: ClientleLoading[];

  list?: IBaseListingResponse<IClient>;
  query: IBaseQuery;

  details?: IClientDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IClientDetails) => void;

    exportExcel: (data: any) => any;

    createClient: (request: ICreateClient) => void;
    updateClient: (id: number, request: IUpdateClient) => void;
    deleteClient: (id: number) => void;
    copyClinet: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IBaseQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClient: DEFAULT_FUNCTION,
    updateClient: DEFAULT_FUNCTION,
    deleteClient: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,
    copyClinet: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ClientContext = createContext(externalState);

export default ClientContext;
