import { Col, Row, Collapse, Space } from "antd";
import { CaretRightOutlined, FilterOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../../context/app/context";
import { INITIAL_PAGE } from "../../../../../utils/costants";
import FieldBuilder from "../../../../form-components/field-builder"
import Form from "../../../../form-components/from"
import { getIRecordType } from "../../../../../models/trademark-collection/trademark-reservation/enum";
import SubmitBtn from "../../../../form-components/submit-btn";
import styles from "./styles.module.scss";
import IRecordDrawingContext from "../../../../../context/drawing-collection/drawing-record/context";
import IRecordCopyRightContext from "../../../../../context/copyright-collection/copyright-record/context";

const { Panel } = Collapse;
interface IProps { }

const RecordCopyrightFilter: React.FC<IProps> = () => {
    const { t } = useTranslation();
    const { actions, query, loading } = useContext(IRecordCopyRightContext);
    const { direction } = useContext(AppContext);
    const [showFilters, setShowFilters] = useState(false);

    const type = [1, 2, 3, 4, 5, 6];
    return (
        <div className={styles.container}>
            <div className={styles.filterCard}>
                <Collapse
                    bordered={true}
                    className={styles.colls}
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    onChange={(key) => {
                        setShowFilters(key?.includes("1"));
                    }}
                    accordion
                >
                    <Panel
                        extra={
                            <Fragment>
                                {
                                    <Space>
                                        <SubmitBtn
                                            icon={<FilterOutlined />}
                                            loading={loading.includes("list")}
                                            onClick={(e) => e.stopPropagation()}
                                            form="drawing-record"
                                        >
                                            {t("apply")}
                                        </SubmitBtn>
                                    </Space>
                                }
                            </Fragment>
                        }
                        header={t("filters")}
                        key="1"
                    >
                        <Form
                            formId="drawing-record"
                            onSubmit={(data) => {
                                const filter: any = {
                                    page: INITIAL_PAGE,
                                    perPage: query?.perPage,
                                    keyword: data?.keyword,
                                    filters: [
                                        data?.type
                                            ? {
                                                name: "type",
                                                value: data.type,
                                                operation: "eq",
                                            }
                                            : undefined,
                                        data?.officialDate != undefined &&
                                            data.officialDate[0] != ""
                                            ? {
                                                name: "officialDate",
                                                value: data.officialDate[0],
                                                operation: "gte",
                                            }
                                            : undefined,
                                        data?.officialDate != undefined &&
                                            data.officialDate[1] != ""
                                            ? {
                                                name: "officialDate",
                                                value: data.officialDate[1],
                                                operation: "lte",
                                            }
                                            : undefined,
                                    ].filter((f) => f),
                                };
                                actions.setQuery(filter);
                            }}
                        >
                            <Row justify={"start"} gutter={[10, 0]}>
                                <Col span={8}>
                                    <FieldBuilder
                                        name="type"
                                        label={`${t("type")}`}
                                        width="large"
                                        input={{
                                            type: "select",
                                            options: type.map((status, index) => {
                                                return {
                                                    label: t(getIRecordType(status) as string),
                                                    value: status as number,
                                                };
                                            }),
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <FieldBuilder
                                        name="officialDate"
                                        label={`${t("official_date")}`}
                                        input={{ type: "range-picker" }}
                                        rules={{ required: false }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                </Collapse>
            </div>
        </div>
    );
};

export default RecordCopyrightFilter;
