import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../context/app/context";
import DrawingContext from "../../../../context/drawing-collection/drawing/context";
import { IClient } from "../../../../models/client/response";
import { getDrawingType } from "../../../../models/drawing-collection/drawing/enum";
import { ICountry } from "../../../../models/managent-collection/user/response";
import EndPoints from "../../../../services/end-points";
import { INITIAL_PAGE } from "../../../../utils/helpers/constants";
import FieldBuilder from "../../../form-components/field-builder";
import Form from "../../../form-components/from";

interface IProps {}

const DrawingFilter: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(DrawingContext);
  const { direction } = useContext(AppContext);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const type = [1, 2];

  // get all clients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, [direction]);

  // get all countries from api
  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountries({});
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, [direction]);

  return (
    <Form
      formId="drawing-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.mainCountry
              ? {
                  name: "mainCountry",
                  value: data.mainCountry,
                  operation: "eq",
                }
              : undefined,
            data?.client
              ? {
                  name: "client",
                  value: data.client,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("drawing_type")}`}
            width="large"
            input={{
              type: "select",
              options: type.map((status, index) => {
                return {
                  label: t(getDrawingType(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="mainCountry"
            label={`${t("main_country")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: countriesLoading,
              options: countries?.map((country, index) => {
                return {
                  label: country.name,
                  value: country.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="client"
            label={`${t("clients")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: clientsLoading,
              options: clients?.map((client, index) => {
                return {
                  label: client.name,
                  value: client.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default DrawingFilter;
