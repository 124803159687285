import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import CertifiedCopyContext, { internalState } from "./context";
import reducer from "./reducer";

import moment from "moment";
import { IDrawingQuery } from "../../../models/drawing-collection/drawing/query";
import {
  ICreateCertifiedCopy,
  IUpdateCertifiedCopy,
} from "../../../models/independent-requests-collection/certified-copy/request";
import { ICertifiedCopyDetails } from "../../../models/independent-requests-collection/certified-copy/response";

export interface IProps {
  children: React.ReactNode;
}
const CertifiedCopyContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.certifiedCopy.getAllCertifiedCopy(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.certifiedCopy.getCertifiedCopy(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICertifiedCopyDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createCertifiedCopy = async (request: ICreateCertifiedCopy) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.certifiedCopy.createCertifiedCopy(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateCertifiedCopy = async (
    id: number,
    request: IUpdateCertifiedCopy
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.certifiedCopy.updateCertifiedCopy(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCertifiedCopy = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.certifiedCopy.deleteCertifiedCopy(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.certifiedCopy.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `RequestForCertified-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <CertifiedCopyContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          exportExcel,
          createCertifiedCopy,
          updateCertifiedCopy,
          deleteCertifiedCopy,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CertifiedCopyContext.Provider>
  );
};

export default CertifiedCopyContextProvider;
