import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { IDisclosureTrademarkQuery } from "../../../models/independent-requests-collection/disclosure-trademark/query";
import {
  IDisclosureTrademark,
  IDisclosureTrademarkDetails,
} from "../../../models/independent-requests-collection/disclosure-trademark/response";
import {
  ICreateDisclosureTrademark,
  IUpdateDisclosureTrademark,
} from "../../../models/independent-requests-collection/disclosure-trademark/request";
import { Params } from "react-router-dom";
import { IExportDisclosureTrademarkRequestExcel } from "../../../models/independent-requests-collection/disclosure-trademark-request/request";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class DisclosureTrademarkService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDisclosureTrademark = (
    query: IDisclosureTrademarkQuery,
    params?: Params
  ) =>
    this.http.request<IBaseListingResponse<IDisclosureTrademark>>({
      path: `/disclosure-trademark`,
      method: "GET",
      query,
      ...params,
    });

  getDisclosureTrademark = (id: number, params?: Params) =>
    this.http.request<IDisclosureTrademarkDetails>({
      path: `/disclosure-trademark/${id}`,
      method: "GET",
      ...params,
    });

  createDisclosureTrademark = (
    data: ICreateDisclosureTrademark,
    params?: Params
  ) =>
    this.http.request<IDisclosureTrademark>({
      path: "/disclosure-trademark",
      method: "POST",
      body: data,
      ...params,
    });

  updateDisclosureTrademark = (
    id: number,
    data: IUpdateDisclosureTrademark,
    params?: Params
  ) =>
    this.http.request<IDisclosureTrademark>({
      path: `/disclosure-trademark/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDisclosureTrademark = (id: number, params?: Params) =>
    this.http.request<IDisclosureTrademark>({
      path: `/disclosure-trademark/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportDisclosureTrademarkRequestExcel,
    query: IDisclosureTrademarkQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/disclosure-trademark/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default DisclosureTrademarkService;
