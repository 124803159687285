import { createContext } from "react";

import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";

import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IIIncidentsLicensingPayment,
  IIIncidentsLicensingPaymentDetails,
} from "../../../../models/incidents-managments/incidents-licensing/client-payments/response";
import { IIncidentsLicensingPaymentsQuery } from "../../../../models/incidents-managments/incidents-licensing/client-payments/query";
import {
  ICreateIncidentsLicensingPayment,
  IUpdateIncidentsLicensingPayment,
} from "../../../../models/incidents-managments/incidents-licensing/client-payments/request";

export type ClientPaymentLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ClientPaymentLoading[];

  list?: IBaseListingResponse<IIIncidentsLicensingPayment>;
  query: IIncidentsLicensingPaymentsQuery;

  details?: IIIncidentsLicensingPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIIncidentsLicensingPaymentDetails) => void;

    createClientPayment: (request: ICreateIncidentsLicensingPayment) => void;
    updateClientPayment: (
      id: number,
      request: IUpdateIncidentsLicensingPayment
    ) => void;
    deleteCleintPayment: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsLicensingPaymentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClientPayment: DEFAULT_FUNCTION,
    updateClientPayment: DEFAULT_FUNCTION,
    deleteCleintPayment: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsLicensingClientsPaymentContext = createContext(externalState);

export default IncidentsLicensingClientsPaymentContext;
