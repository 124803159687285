import { Row, Col, Divider } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CostTypeContext from "../../../../context/management-collection/cost-type/context";
import CostTypeContextProvider from "../../../../context/management-collection/cost-type/provider";
import JopTitleContext from "../../../../context/management-collection/jop-title/context";
import JopTitleContextProvider from "../../../../context/management-collection/jop-title/provider";
import { ICreateCostType } from "../../../../models/managent-collection/cost-types/request";
import { ICostType } from "../../../../models/managent-collection/cost-types/response";
import { IJopTitle } from "../../../../models/managent-collection/jop-title/response";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import FieldBuilder from "../../../form-components/field-builder";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CostTypeContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateCostType(details?.id, data)
          : actions.createCostType(data);
      }}
      title={details ? t("edite_cost_type") : t("add_cost_type")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          defaultValue: details?.defaultValue,
          note: details?.note,
        } as ICostType
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={12}>
              <FieldBuilder
                name="defaultValue"
                label={`${t("default_value")}`}
                input={{ type: "number" }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const CostTypeForm = () => {
  return (
    <CostTypeContextProvider>
      <Form />
    </CostTypeContextProvider>
  );
};
export default CostTypeForm;
