import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import CopyrightRequestFilesContext, { internalState } from "./context";
import reducer from "./reducer";
import { ICopyrightRequesFilesDetails } from "../../../models/copyright-collection/copyright-requset-files/response";
import {
  ICreateCopyrightRequestFiles,
  IUpdateCopyrightRequestFiles,
} from "../../../models/copyright-collection/copyright-requset-files/request";
import { ICopyrightRequestFilesQuery } from "../../../models/copyright-collection/copyright-requset-files/query";

export interface IProps {
  children: React.ReactNode;
}
const CopyrightRequestFilesContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.copyrightRequsetfile.getAllCopyrightRequsetFiles({
            ...state.query,
            filters: [
              {
                name: "copyrightRequest",
                operation: "eq",
                value: id,
              },
            ],
          });
        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.copyrightRequsetfile.getCopyrightRequsetFile(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICopyrightRequesFilesDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createCopyrightRequsetFile = async (
    request: ICreateCopyrightRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.copyrightRequsetfile.createCopyrightRequsetFile(
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateCopyrightRequsetFile = async (
    id: number,
    request: IUpdateCopyrightRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.copyrightRequsetfile.updateCopyrightRequsetFile(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCopyrightRequsetFile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.copyrightRequsetfile.deleteCopyrightRequsetFile(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ICopyrightRequestFilesQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <CopyrightRequestFilesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteCopyrightRequsetFile,
          createCopyrightRequsetFile,

          updateCopyrightRequsetFile,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CopyrightRequestFilesContext.Provider>
  );
};

export default CopyrightRequestFilesContextProvider;
