export enum getAllStatusGrievance {
  grievance = 6,
}

export const getBooleanGrievanceTrademarkOrderTemplate = (
  status: getAllStatusGrievance
) => {
  switch (status) {
    case getAllStatusGrievance.grievance:
      return "grievance";
  }
};
