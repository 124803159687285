import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import { IObjectionRequest, IObjectionRequestDetails } from "../../../models/independent-requests-collection/objection-request/response";
import { IObjectionRequestQuery } from "../../../models/independent-requests-collection/objection-request/query";
import { ICreateObjectionRequest, IUpdateObjectionRequest } from "../../../models/independent-requests-collection/objection-request/request";

export type ObjectionRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ObjectionRequestLoading[];

  list?: IBaseListingResponse<IObjectionRequest>;
  query: IObjectionRequestQuery;

  details?: IObjectionRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IObjectionRequestDetails) => void;

    createObjectionRequest: (request: ICreateObjectionRequest) => void;
    updateObjectionRequest: (id: number, request: IUpdateObjectionRequest) => void;
    deleteObjectionRequest: (id: number) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: IObjectionRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    
    createObjectionRequest: DEFAULT_FUNCTION,
    updateObjectionRequest: DEFAULT_FUNCTION,
    deleteObjectionRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ObjectionRequestContext = createContext(externalState);

export default ObjectionRequestContext;
