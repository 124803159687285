import { IIncidentsWaiverPaymentsQuery } from "../../../../models/incidents-managments/incidents-waiver-request/client-payments/query";
import {
  ICreateIncidentsWaiverPayment,
  IUpdateIncidentsWaiverPayment,
} from "../../../../models/incidents-managments/incidents-waiver-request/client-payments/request";
import {
  IIIncidentsWaiverPayment,
  IIIncidentsWaiverPaymentDetails,
} from "../../../../models/incidents-managments/incidents-waiver-request/client-payments/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class IncidentsWaiverPaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClientPayments = (
    query: IIncidentsWaiverPaymentsQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIIncidentsWaiverPayment>>({
      path: `/incident-waiver-client-payment`,
      method: "GET",
      query,
      ...params,
    });

  getClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<IIIncidentsWaiverPaymentDetails>({
      path: `/incident-waiver-client-payment/${id}`,
      method: "GET",
      ...params,
    });

  createClientPayment = (
    data: ICreateIncidentsWaiverPayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreateIncidentsWaiverPayment>({
      path: "/incident-waiver-client-payment",
      method: "POST",
      body: data,
      ...params,
    });

  updateClientPayment = (
    id: number,
    data: IUpdateIncidentsWaiverPayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreateIncidentsWaiverPayment>({
      path: `/incident-waiver-client-payment/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<ICreateIncidentsWaiverPayment>({
      path: `/incident-waiver-client-payment/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default IncidentsWaiverPaymentService;
