import { CopyOutlined, RetweetOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import Button, { ButtonProps } from "../../../antd/button";

interface Props extends ButtonProps {}

const CopyButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("copy")}>
      <Button type="dashed" shape="circle" icon={<CopyOutlined />} {...props} />
    </Tooltip>
  );
};

export default CopyButton;
