import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ClientFilters from "../../components/client/client-filter";
import { useModal } from "react-modal-hook";
import SearchFilterCard from "../../components/general/filter-card";
import PageHeader from "../../components/general/layout/page-header";
import RefreshBtn from "../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../context/app/context";
import Can from "../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../utils/hooks/is-mount";
import ReportContext from "../../context/report/context";
import ReportContextProvider from "../../context/report/provider";
import ReportTable from "../../components/report/report-table";
import FormHookModal from "../../components/general/modals/form-hook-modal";
import ReportForm from "../../components/report/report-form";
import ReportFilter from "../../components/report/report-filter";

interface IProps {
  children?: React.ReactNode;
}

const Report: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { direction } = useContext(AppContext);

  const { actions, loading, query } = useContext(ReportContext);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  const [showFormModal, hideForModal] = useModal(
    () => (
      <FormHookModal
        id="4"
        confirmButtonText={t("save")}
        loading={loading.includes("create")}
        onSubmit={async (data) => {
          await actions.createReport(data);
        }}
        hideModal={() => {
          hideForModal();
        }}
        width={"50vw"}
        style={{
          marginTop: "1.6rem",
          height: "59vh",
        }}
        title={t("add_report")}
      >
        <ReportForm />
      </FormHookModal>
    ),
    [loading]
  );

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("reports")}
        subTitle={t("reports_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                showFormModal();
              }}
              icon={<PlusOutlined />}
            >
              {t("add_report")}
            </Button>
          </Can>,
        ]}
      />

      <SearchFilterCard
        formId="report-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <ReportFilter />
      </SearchFilterCard>

      <ReportTable />
    </div>
  );
};

const ReportPage = () => {
  return (
    // ContextProvider
    <ReportContextProvider>
      <Report />
    </ReportContextProvider>
  );
};
export default ReportPage;
// export default User
