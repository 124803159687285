import { Descriptions as AntdDescriptions, Descriptions } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { IPatentsReservation } from "../../../../../models/patents-collection/patents-reservation/response";

interface IProps {
  details: IPatentsReservation;
}
const ReservationDetails: React.FC<IProps> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <>
      <Descriptions size="small" column={2} bordered>
        <AntdDescriptions.Item label={t("id")}>
          {details?.id}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("country")}>
          {details?.country?.name ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("deposit_number")}>
          {details?.depositNumber ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("deposit_date")}>
          {details?.depositDate
            ? moment(details?.depositDate).format("DD/MM/YYYY ")
            : "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("registration_number")}>
          {details?.registrationNumber ?? "-"}
        </AntdDescriptions.Item>
        <AntdDescriptions.Item label={t("registration_date")}>
          {details?.registrationDate
            ? moment(details?.registrationDate).format("DD/MM/YYYY ")
            : "-"}
        </AntdDescriptions.Item>
      </Descriptions>
    </>
  );
};

export default ReservationDetails;
