import { createContext } from "react";
import {
  IIncidentsRequestCosts,
  IIncidentsRequestCostsDetails,
} from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/response";
import { IPatnetsRequestCostQuery } from "../../../../models/patents-collection/patents-request-costs/query";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  ICreateIncidentsRequestCosts,
  IUpdateIncidentsRequestCosts,
} from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/request";
import { IDrawingOfficeCostQuery } from "../../../../models/drawing-collection/office-cost/query";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";

export type OfficeCostLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OfficeCostLoading[];

  list?: IBaseListingResponse<IIncidentsRequestCosts>;
  query: IPatnetsRequestCostQuery;

  details?: IIncidentsRequestCostsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsRequestCostsDetails) => void;

    createOfficeCost: (request: ICreateIncidentsRequestCosts) => void;
    updateOfficeCost: (
      id: number,
      request: IUpdateIncidentsRequestCosts
    ) => void;
    deleteOfficeCost: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingOfficeCostQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createOfficeCost: DEFAULT_FUNCTION,
    updateOfficeCost: DEFAULT_FUNCTION,
    deleteOfficeCost: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsOfficeCostContext = createContext(externalState);

export default IncidentsOfficeCostContext;
