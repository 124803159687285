import { INodesNameQuery } from "../../../models/managent-collection/nodes-name/query";
import {
  ICreateNodeName,
  IUpdateNodeName,
} from "../../../models/managent-collection/nodes-name/request";
import { INodeName } from "../../../models/managent-collection/nodes-name/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class NodesNameService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllNodeName = (query: INodesNameQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeName>>({
      path: `/nodes-name`,
      method: "GET",
      query,
      ...params,
    });

  getNodeName = (id: number, params?: RequestParams) =>
    this.http.request<INodeName>({
      path: `/nodes-name/${id}`,
      method: "GET",
      ...params,
    });

  createNodeName = (data: ICreateNodeName, params?: RequestParams) =>
    this.http.request<INodeName>({
      path: "/nodes-name",
      method: "POST",
      body: data,
      ...params,
    });

  updateNodeName = (
    id: number,
    data: IUpdateNodeName,
    params?: RequestParams
  ) =>
    this.http.request<INodeName>({
      path: `/nodes-name/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteNodeName = (id: number, params?: RequestParams) =>
    this.http.request<INodeName>({
      path: `/nodes-name/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default NodesNameService;
