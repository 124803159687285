export enum types {
  patent = 1,
  UTILITY_MODEL = 2,
}

export const getPatentsType = (type: types) => {
  switch (type) {
    case types.patent:
      return "patent";
    case types.UTILITY_MODEL:
      return "UTILITY_MODEL";
    default:
      return "patent";
  }
};

export const getPatentsColor = (type: types) => {
  switch (type) {
    case types.patent:
      return "orange";
    case types.UTILITY_MODEL:
      return "green";
    default:
      return "patent";
  }
};

export enum typesCountry {
  LOCAL = 1,
  FOREIGN = 2,
}

export const getPationsCountryType = (type: typesCountry) => {
  switch (type) {
    case typesCountry.LOCAL:
      return "local";
    case typesCountry.FOREIGN:
      return "foreign";
    default:
      return "local";
  }
};

export const getPationsCountryColor = (type: typesCountry) => {
  switch (type) {
    case typesCountry.LOCAL:
      return "blue";
    case typesCountry.FOREIGN:
      return "red";
    default:
      return "red";
  }
};
