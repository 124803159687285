import { Col, Row } from "antd";
import FormItem from "antd/es/form/FormItem";
import React from "react";
import { useTranslation } from "react-i18next";
import { MediumFor } from "../../../../models/medium/enum";
import Controller from "../../../form-components/controller";
import FileUploader from "../../../form-components/file-uploader";

interface IProps {}

const PatentFileForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={24}>
          <div>
            <FormItem>
              <Controller
                name="fileId"
                rules={{
                  required: {
                    value: false,
                    message: `${t("field_is_required_message")}`,
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FileUploader
                      {...field}
                      showUploadList
                      type="DRAGGER"
                      onChange={(response) => {
                        field.onChange(response?.id);
                      }}
                      fileFor={MediumFor.COPYRIGHT_FILE}
                    />
                  );
                }}
              />
            </FormItem>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PatentFileForm;
