import { createContext } from "react";
import { INodesNameQuery } from "../../../models/managent-collection/nodes-name/query";
import {
  ICreateNodeName,
  IUpdateNodeName,
} from "../../../models/managent-collection/nodes-name/request";
import {
  INodeName,
  INodeNameDetails,
} from "../../../models/managent-collection/nodes-name/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type NodesNameLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: NodesNameLoading[];

  list?: IBaseListingResponse<INodeName>;
  query: INodesNameQuery;

  details?: INodeNameDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: INodeNameDetails) => void;

    createNodeName: (request: ICreateNodeName) => void;
    updateNodeName: (id: number, request: IUpdateNodeName) => void;
    deleteNodeName: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: INodesNameQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createNodeName: DEFAULT_FUNCTION,
    updateNodeName: DEFAULT_FUNCTION,
    deleteNodeName: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const NodesNameContext = createContext(externalState);

export default NodesNameContext;
