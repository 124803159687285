import { Button, Col, Divider, Input, Row, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import WiverRequestContext from "../../../../context/independent_requests-collection/waiver-request/context";
import WiverRequestContextProvider from "../../../../context/independent_requests-collection/waiver-request/provider";
import { IClient } from "../../../../models/client/response";
import { IDrawing } from "../../../../models/drawing-collection/drawing/response";
import { IUpdateWiverRequest } from "../../../../models/independent-requests-collection/waiver-request/request";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { ITrademark } from "../../../../models/trademark-collection/trademark/response";
import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import Controller from "../../../form-components/controller";
import FormItem from "../../../general/form-item";
import styles from "./style.module.scss";
import { getBooleanWaiverRequestType } from "../../../../models/independent-requests-collection/waiver-request/enum";
import Spin from "../../../general/antd/spin";
import { ITrademarkRequest } from "../../../../models/trademark-collection/trademark-request/response";
import { IDrawingRequest } from "../../../../models/drawing-collection/drawing-request/response";
import FieldBuilder from "../../../form-components/field-builder";

const Form = () => {
  const { details, actions, loading } = useContext(WiverRequestContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const { state } = useLocation();
  const [drawings, setDrawings] = useState<IDrawingRequest[]>([]);
  const [drawingLoading, setDrawingLoading] = useState(true);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [trademarks, setTrademarks] = useState<ITrademarkRequest[]>([]);
  const [trademarksLoading, setTrademarksLoading] = useState(true);
  // filters state

  const requestStatus = [
    { id: 1, name: "trademark" },
    { id: 2, name: "drawing" },
  ];

  const type = [1, 2];
  const Option = Select.Option;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const methods = useForm<any>({
    defaultValues: {
      orderValue: details?.orderValue,
      //clientPayments: details?.clientPayments,
      clientId: details?.client?.id,
      countryId: details?.country?.id,
      depositNumber: details?.depositNumber,
      type: details?.type,
      address: details?.address,
      clientFor: details?.clientFor?.id,
      dateFinancialReceipt: details?.dateFinancialReceipt,
      details: details?.details,
      financialReceiptNumber: details?.financialReceiptNumber,
      legalFormPerson: details?.legalFormPerson,
      note: details?.note,
      officialDate: details?.officialDate,
      status: details?.request?.workflow?.for === 1 ? 1 : 2,
      drawingRequestId:
        details?.request?.workflow?.for === 2 ? details?.request?.id : null,
      trademarkRequestId:
        details?.request?.workflow?.for === 1 ? details?.request?.id : null,
    } as IUpdateWiverRequest,
  });
  const { handleSubmit, reset, control, setValue } = methods;

  const status = useWatch({
    control,
    name: "status",
  });

  useEffect(() => {
    if (status) {
      if (status == 1) {
        setValue("drawingRequestId", null);
      } else {
        setValue("trademarkRequestId", null);
      }
    } else {
      setValue("trademarkRequestId", null);
      setValue("drawingRequestId", null);
    }
  }, [status]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.wiverRequest.updateWiverRequest(details?.id, data)
          : await EndPoints.wiverRequest.createWiverRequest(data);
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  // get all countries in stystem
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all clients

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    const getDrawings = async () => {
      try {
        const { data } = await EndPoints.drawingRequest.getAllRequests({
          perPage: -1,
        });
        setDrawings(data.data);
      } catch (error) {
      } finally {
        setDrawingLoading(false);
      }
    };
    getDrawings();
  }, []);

  useEffect(() => {
    const getTrademarks = async () => {
      try {
        const { data } = await EndPoints.trademarkRequest.getAllRequests({
          perPage: -1,
        });
        setTrademarks(data.data);
      } catch (error) {
      } finally {
        setTrademarksLoading(false);
      }
    };
    getTrademarks();
  }, []);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    reset({
      clientId: details?.client?.id,
      countryId: details?.country?.id,
      depositNumber: details?.depositNumber,
      type: details?.type,
      address: details?.address,
      clientFor: details?.clientFor?.id,
      dateFinancialReceipt: details?.dateFinancialReceipt,
      details: details?.details,
      financialReceiptNumber: details?.financialReceiptNumber,
      legalFormPerson: details?.legalFormPerson,
      note: details?.note,
      officialDate: details?.officialDate,

      status: details?.request?.workflow?.for === 1 ? 1 : 2,

      drawingRequestId:
        details?.request?.workflow?.for === 2 ? details?.request?.id : null,
      trademarkRequestId:
        details?.request?.workflow?.for === 1 ? details?.request?.id : null,
    } as IUpdateWiverRequest);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div>
          <span className={styles.title}>
            {details ? t("update_waiver_request") : t("add_waiver_request")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <FieldBuilder
                    name="countryId"
                    label={`${t("country")}`}
                    width="large"
                    rules={{ required: true }}
                    input={{
                      type: "select",
                      loading: countriesLoading,
                      allowSearch: true,
                      options: countries.map((country, index) => {
                        return {
                          label: country.name as string,
                          value: country.id as number,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8}>
                  <FormItem label={t("client")} required>
                    <Controller
                      name="clientId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            loading={clientsLoading}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={t("clients")}
                          >
                            {clients?.map((client, index) => {
                              return (
                                <Option key={index} value={client.id}>
                                  {client.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("clientFor")}>
                    <Controller
                      name="clientFor"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            loading={clientsLoading}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={t("clients")}
                          >
                            {clients?.map((client, index) => {
                              return (
                                <Option key={index} value={client.id}>
                                  {client.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("type_opreation")} required>
                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("type_opreation")}
                          >
                            {type?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {}
                                  {t(getBooleanWaiverRequestType(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("type_waiver")} required>
                    <Controller
                      name="status"
                      control={control}
                      rules={{
                        required: { value: true, message: t("requiredField") },
                      }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            placeholder={t("type_waiver")}
                          >
                            {requestStatus?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr.id}>
                                  {t(tr.name)}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("industrial_models_and_drawings_request")}>
                    <Controller
                      name="drawingRequestId"
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: t("requiredField"),
                        },
                      }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={drawingLoading}
                            {...field}
                            style={{ width: "100%" }}
                            disabled={status === 2 ? false : true}
                            showSearch
                            optionFilterProp="label"
                            placeholder={t(
                              "industrial_models_and_drawings_request"
                            )}
                            options={drawings?.map((tr, index) => {
                              return {
                                label: `${tr?.id} ( ${
                                  tr?.depositNumber
                                } ${" "} ${tr?.drawings?.map((el, index) => {
                                  return `${el?.name}    ${
                                    tr?.drawings.length - 1 != index ? "," : ""
                                  }`;
                                })})` as string,
                                value: tr.id as number,
                              };
                            })}
                          ></Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("trademarks_request")}>
                    <Controller
                      name="trademarkRequestId"
                      control={control}
                      rules={{
                        required: {
                          value: false,
                          message: t("requiredField"),
                        },
                      }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={trademarksLoading}
                            {...field}
                            style={{ width: "100%" }}
                            disabled={status === 1 ? false : true}
                            placeholder={t("trademarks_request")}
                            showSearch
                            optionFilterProp="label"
                            options={trademarks?.map((tr, index) => {
                              return {
                                label: `${tr?.id} ( ${
                                  tr?.depositNumber
                                } ${" "} ${tr?.trademark?.name})` as string,
                                value: tr.id as number,
                              };
                            })}
                          ></Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="depositNumber"
                    label={`${t("depositNumber")}`}
                    input={{ type: "text" }}
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="officialDate"
                    label={`${t("official_date")}`}
                    input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                    rules={{ required: true }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="financialReceiptNumber"
                    label={`${t("financialReceiptNumber")}`}
                    input={{ type: "text" }}
                    rules={{ required: false }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="dateFinancialReceipt"
                    label={`${t("dateFinancialReceipt")}`}
                    input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                  />
                </Col>
                <Col span={8}>
                  <FormItem label={t("legalFormPerson")}>
                    <Controller
                      name="legalFormPerson"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("legalFormPerson")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("mailing_address")}>
                    <Controller
                      name="address"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("mailing_address")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("details")}>
                    <Controller
                      name="details"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("details")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="orderValue"
                    label={`${t("order_value")}`}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                  />
                </Col>
                {/* <Col span={8}>
                  <FieldBuilder
                    name="clientPayments"
                    label={`${t("client_payments")}`}
                    input={{ type: "number" }}
                    rules={{ required: false }}
                  />
                </Col>*/}
                <Col span={24}>
                  <FieldBuilder
                    name="note"
                    label={`${t("note")}`}
                    input={{ type: "text-area" }}
                    rules={{ required: false }}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const WiverRequestForm = () => {
  return (
    <WiverRequestContextProvider>
      <Form />
    </WiverRequestContextProvider>
  );
};
export default WiverRequestForm;
