import {
  IOfficeCost,
  IOfficeCostDetails,
} from "../../../models/trademark-collection/office-cost/response";
import { IOfficeCostQuery } from "../../../models/trademark-collection/office-cost/query";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import {
  ICreateOfficeCost,
  IUpdateOfficeCost,
} from "../../../models/trademark-collection/office-cost/request";

class OfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (query: IOfficeCostQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IOfficeCost>>({
      path: `/trademark-request-costs`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IOfficeCostDetails>({
      path: `/trademark-request-costs/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (data: ICreateOfficeCost, params?: RequestParams) =>
    this.http.request<IOfficeCost>({
      path: "/trademark-request-costs",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdateOfficeCost,
    params?: RequestParams
  ) =>
    this.http.request<IOfficeCost>({
      path: `/trademark-request-costs/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IOfficeCost>({
      path: `/trademark-request-costs/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default OfficeCostService;
