import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CertifiedCopyContext from "../../../../context/independent_requests-collection/request-for-certified-copy/context";
import CertifiedCopyContextProvider from "../../../../context/independent_requests-collection/request-for-certified-copy/provider";
import { IClient } from "../../../../models/client/response";
import { IUpdateCertifiedCopy } from "../../../../models/independent-requests-collection/certified-copy/request";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { IMedium } from "../../../../models/medium/response";
import EndPoints from "../../../../services/end-points";
import FieldBuilder from "../../../form-components/field-builder";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";
interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(CertifiedCopyContext);

  const { t } = useTranslation();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all CLients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateCertifiedCopy(details?.id, data)
          : await actions.createCertifiedCopy(data);
      }}
      title={
        details
          ? t("update_request_for_certified_copy")
          : t("add_request_for_certified_copy")
      }
      defaultValues={
        {
          countryId: details?.country?.id,
          clientId: details?.client?.id,
          dateFinancialReceipt: details?.dateFinancialReceipt,
          depositNumber: details?.depositNumber,
          details: details?.details,
          financialReceiptNumber: details?.financialReceiptNumber,
          note: details?.note,
          officialDate: details?.officialDate,
          orderValue: details?.orderValue,
          // clientPayments: details?.clientPayments,
        } as IUpdateCertifiedCopy
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="clientId"
                label={`${t("client")}`}
                width="large"
                input={{
                  type: "select",
                  loading: clientsLoading,
                  allowSearch: true,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryId"
                label={`${t("country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="depositNumber"
                label={`${t("depositNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="officialDate"
                label={`${t("official_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="financialReceiptNumber"
                label={`${t("financialReceiptNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="dateFinancialReceipt"
                label={`${t("dateFinancialReceipt")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="orderValue"
                label={`${t("order_value")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            {/*<Col span={8}>
              <FieldBuilder
                name="clientPayments"
                label={`${t("client_payments")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col> */}
            <Col span={24}>
              <FieldBuilder
                name="details"
                label={`${t("details")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const RequestCertifiedCopyForm = () => {
  return (
    <CertifiedCopyContextProvider>
      <Form />
    </CertifiedCopyContextProvider>
  );
};

export default RequestCertifiedCopyForm;
