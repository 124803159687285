import { useReducer } from "react";

import { IDrawingClientPaymentsQuery } from "../../../models/drawing-collection/client-payments/query";
import {
  ICreateDrawingClientPayment,
  IUpdateDrawingClientPayment,
} from "../../../models/drawing-collection/client-payments/request";
import { IDrawingClientPaymentDetails } from "../../../models/drawing-collection/client-payments/response";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import DrawingClientPaymentContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const DrawingClientPaymentContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.drawingClientPayment.getAllClientPayments({
            ...state.query,
            filters: [
              {
                name: "DrawingRequest",
                operation: "eq",
                value: id,
              },
            ],
          });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.drawingClientPayment.getClientPayment(
          id
        );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDrawingClientPaymentDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createClientPayment = async (request: ICreateDrawingClientPayment) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.drawingClientPayment.createClientPayment(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateClientPayment = async (
    id: number,
    request: IUpdateDrawingClientPayment
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.drawingClientPayment.updateClientPayment(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCleintPayment = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.drawingClientPayment.deleteClientPayment(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingClientPaymentsQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <DrawingClientPaymentContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createClientPayment,
          updateClientPayment,
          deleteCleintPayment,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DrawingClientPaymentContext.Provider>
  );
};

export default DrawingClientPaymentContextProvider;
