import {
  Descriptions as AntdDescriptions,
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { getRequestStatus } from "../../../../../models/node/enum";

import { RangePickerProps } from "antd/es/date-picker";
import moment from "moment";
import DrawingRequestContext from "../../../../../context/drawing-collection/drawing-request/context";
import DrawingRequestContextProvider from "../../../../../context/drawing-collection/drawing-request/provider";
import { IClient } from "../../../../../models/client/response";
import { IUpdateDrawingRequest } from "../../../../../models/drawing-collection/drawing-request/request";
import { IDrawing } from "../../../../../models/drawing-collection/drawing/response";
import { ICountry } from "../../../../../models/managent-collection/user/response";
import { ITraderMarkOrderTeamplate } from "../../../../../models/workflow/response";
import EndPoints from "../../../../../services/end-points";
import { execute } from "../../../../../utils/helpers/execute";
import Controller from "../../../../form-components/controller";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";
import FormItem from "../../../../general/form-item";
import styles from "./style.module.scss";
import FieldBuilder from "../../../../form-components/field-builder";
import { getDrawingType } from "../../../../../models/drawing-collection/drawing/enum";
import { getDrawingAttachmentsString } from "../../../../../models/drawing-collection/drawing-request/enum";

const Form = () => {
  const { details, actions, loading } = useContext(DrawingRequestContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [drawingOrderTemplate, setDrawingOrderTemplate] = useState<
    ITraderMarkOrderTeamplate[]
  >([]);
  const [drawingOrderTemplateLoading, setDrawingTemplateLoading] =
    useState(true);

  const [drawings, setDrawings] = useState<IDrawing[]>([]);
  const [drawingLoading, setDrawingLoading] = useState(true);
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);

  // filters state

  const requestStatus = [1, 2, 3];
  const drawingTypes = [1, 2];
  const Option = Select.Option;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const methods = useForm<any>({
    defaultValues: {
      officialDate:
        details?.officialDate &&
        moment(details?.officialDate).format("YYYY-MM-DD"),
      clientCost: details?.clientCost,
      depositNumber: details?.depositNumber,
      clients: details?.clients?.map((client) => client?.id),
      drawingId: details?.drawings?.map((draw) => draw.id),
      workflowId: details?.workflow?.id,
      country: details?.workflow?.country?.id ?? undefined,
      registrationForeignCountries: details?.registrationForeignCountries,
      drawingIsUsed: details?.drawingIsUsed,
      nameCountry: details?.nameCountry,
      numberAndDatePriorityRight: details?.numberAndDatePriorityRight,
      attachments: details?.attachments
    } as IUpdateDrawingRequest,
  });
  const { handleSubmit, reset, control } = methods;

  const countryId = useWatch({
    control,
    name: "country",
  });

  const type = useWatch({
    control,
    name: "type",
  });

  const clientsIds = useWatch({
    control,
    name: "clients",
  });
  const attachments = [1, 2, 3, 4];
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.drawingRequest.updateDrawingRequest(
            details?.id,
            data
          )
          : await EndPoints.drawingRequest.createDrawingRequests(data);
        navigate(-1);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  // get all countries in stystem
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all clients

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  // Can not select days before today and today
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // get all  trademarkTemplate for trademark only by filter
  useEffect(() => {
    const getDrawingOrderTemplates = async () => {
      try {
        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          perPage: -1,
          filters: [
            {
              name: "countryId",
              operation: "eq",
              value: countryId,
            },
            {
              name: "for",
              operation: "eq",
              value: 2,
            },
          ],
        });
        setDrawingOrderTemplate(data.data);
      } catch (error) {
      } finally {
        setDrawingTemplateLoading(false);
      }
    };
    getDrawingOrderTemplates();
  }, [countryId]);

  // get trademarks
  useEffect(() => {
    const getDrawings = async () => {
      try {
        const { data } = await EndPoints.drawing.getAllDrawing({
          perPage: -1,
          filters: [
            {
              name: "type",
              operation: "eq",
              value: type,
            },
            {
              name: "client",
              operation: "in",
              value: clientsIds,
            },
          ],
        });
        setDrawings(data.data);
      } catch (error) {
      } finally {
        setDrawingLoading(false);
      }
    };
    getDrawings();
  }, [clientsIds, type]);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    reset({
      clientCost: details?.clientCost,
      clients: details?.clients?.map((client) => client?.id),
      officialDate: details?.officialDate
        ? moment(details?.officialDate).format("YYYY-MM-DD")
        : undefined,
      drawingId: details?.drawings?.map((draw) => draw.id),
      status: details?.status,
      depositNumber: details?.depositNumber,
      type:
        details?.drawings.length > 0 ? details?.drawings[0]?.type : undefined,
      workflowId: details?.workflow?.id,
      country: details?.workflow?.country?.id,
      registrationForeignCountries: details?.registrationForeignCountries,
      drawingIsUsed: details?.drawingIsUsed,
      nameCountry: details?.nameCountry,
      numberAndDatePriorityRight: details?.numberAndDatePriorityRight,
      attachments: details?.attachments
    } as IUpdateDrawingRequest);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div>
          <span className={styles.title}>
            {details ? t("update_drawing_orders") : t("add_drawing_orders")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <FormItem label={t("countries")} required>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            placeholder={t("countries")}
                            options={countries.map((country, index) => {
                              return {
                                label: country.name as string,
                                value: country.id as number,
                              };
                            })}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("drawing_orders_templates")} required>
                    <Controller
                      name="workflowId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={countriesLoading}
                            disabled={countryId ? false : true}
                            placeholder={t("drawing_orders_templates")}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                          >
                            {drawingOrderTemplate?.map((ca, index) => {
                              return (
                                <Option key={index} value={ca.id}>
                                  {ca.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("clients")} required>
                    <Controller
                      name="clients"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            mode="multiple"
                            onSelect={() => {
                              reset((formValues) => ({
                                ...formValues,
                                drawingId: [],
                              }));
                            }}
                            onDeselect={() => {
                              reset((formValues) => ({
                                ...formValues,
                                drawingId: [],
                              }));
                            }}
                            loading={clientsLoading}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            placeholder={t("clients")}
                            options={clients.map((client, index) => {
                              return {
                                label: client.name as string,
                                value: client.id as number,
                              };
                            })}
                          >
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("type_drawing")}>
                    <Controller
                      name="type"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            onSelect={() => {
                              reset((formValues) => ({
                                ...formValues,
                                drawingId: [],
                              }));
                            }}
                            onDeselect={() => {
                              reset((formValues) => ({
                                ...formValues,
                                drawingId: [],
                              }));
                            }}
                            style={{ width: "100%" }}
                            placeholder={t("type_drawing")}
                          >
                            {drawingTypes?.map((drawing, index) => {
                              return (
                                <Option key={index} value={drawing}>
                                  {t(getDrawingType(drawing))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem
                    label={t("industrial_models_and_drawings")}
                    required
                  >
                    <Controller
                      name="drawingId"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t("requiredField"),
                        },
                      }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            mode="multiple"
                            loading={drawingLoading}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            placeholder={t("industrial_models_and_drawings")}
                          >
                            {drawings?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr.id}>
                                  {tr.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("request_status")}>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("request_status")}
                          >
                            {requestStatus?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {t(getRequestStatus(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("deposit_number")}>
                    <Controller
                      name="depositNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("deposit_number")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("request_submitting_date")}>
                    <Controller
                      name="officialDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("registration_number")}>
                    <Controller
                      name="registrationNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("registration_number")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("registration_date")}>
                    <Controller
                      name="registrationDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="nameCountry"
                    label={`${t("name_country")}`}
                    input={{ type: "text" }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="numberAndDatePriorityRight"
                    label={`${t("number_and_date_priority_right")}`}
                    input={{ type: "text" }}
                  />
                </Col>
                <Col span={8}>
                  <FieldBuilder
                    name="attachments"
                    label={`${t("attachments")}`}
                    width="large"
                    input={{
                      type: "select-type",
                      options: attachments.map((attachment, index) => {
                        return {
                          label: t(getDrawingAttachmentsString(attachment)) as string,
                          value: attachment as number,
                        };
                      }),
                    }}
                  />
                </Col>
                <Col span={8} style={{ marginTop: "1.6rem" }}>
                  <FieldBuilder
                    label={`${t("registration_foreign_countries")}`}
                    name="registrationForeignCountries"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={8} style={{ marginTop: "1.6rem" }}>
                  <FieldBuilder
                    label={`${t("drawing_is_used")}`}
                    name="drawingIsUsed"
                    input={{ type: "checkBox" }}
                  />
                </Col>
                <Col span={24}>
                  <FieldBuilder
                    rules={{ required: false }}
                    name="note"
                    label={`${t("note")}`}
                    input={{ type: "text-area" }}
                  />
                </Col>
                {details?.withFine && (
                  <Col span={8}>
                    <FormItem label={t("fine")}>
                      <Controller
                        name="fine"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => {
                          return (
                            <Input
                              {...field}
                              type="text"
                              placeholder={t("fine")}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                )}
                <Col span={24}>
                  {details && (
                    <>
                      <Descriptions size="middle" column={2} bordered>
                        <AntdDescriptions.Item label={t("current_node")}>
                          {details?.currentNode?.name?.name}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("clien_cost")}>
                          {details?.clientCost}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("client_payments")}>
                          {details?.clientPayment}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("office_cost")}>
                          {details?.officeCosts}
                        </AntdDescriptions.Item>
                        <AntdDescriptions.Item label={t("total_cost")}>
                          {details?.totalCost}
                        </AntdDescriptions.Item>
                      </Descriptions>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const DrawingRequestsForm = () => {
  return (
    <DrawingRequestContextProvider>
      <Form />
    </DrawingRequestContextProvider>
  );
};
export default DrawingRequestsForm;
