import { createContext } from "react";

import { IPatentsReservationQuery } from "../../../models/patents-collection/patents-reservation/query";
import {
  ICreatePatentsReservation,
  IUpdatePatensReservation,
} from "../../../models/patents-collection/patents-reservation/request";
import {
  IPatentsReservation,
  IPatentsReservationDetails,
  IPatentsReservationHistory,
} from "../../../models/patents-collection/patents-reservation/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type PatentsReservationLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "history";

export interface IInternalState {
  loading: PatentsReservationLoading[];

  list?: IBaseListingResponse<IPatentsReservation>;
  query: IPatentsReservationQuery;

  details?: IPatentsReservationDetails;
  history?: IBaseListingResponse<IPatentsReservationHistory>;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    getHistory: (idReservation: number) => void;
    setDetails: (data?: IPatentsReservationDetails) => void;

    createPatentsReservation: (request: ICreatePatentsReservation) => void;
    updatePatentsReservation: (
      id: number,

      request: IUpdatePatensReservation
    ) => void;
    deletePatentsReservation: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IPatentsReservationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    getHistory: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPatentsReservation: DEFAULT_FUNCTION,
    updatePatentsReservation: DEFAULT_FUNCTION,
    deletePatentsReservation: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PatentsReservationContext = createContext(externalState);

export default PatentsReservationContext;
