import { useEffect, useReducer } from "react";
import { ICategoryQuery } from "../../../models/managent-collection/category/query";
import {
  ICreateCategory,
  IUpdateCategory,
} from "../../../models/managent-collection/category/request";
import { ICategory } from "../../../models/managent-collection/category/response";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import { useIsMount } from "../../../utils/hooks/is-mount";
import CategoryContext, { internalState } from "./context";
import reducer from "./reducer";
import moment from "moment";

export interface IProps {
  children: React.ReactNode;
}
const CategoryContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.category.getAllCategories({
          ...state.query,
          filters: [
            {
              name: "parentCategory",
              value: null,
              operation: "eq",
            },
          ],
        });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getSubCategories = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.category.getAllCategories({
          ...state.query,
          filters: [
            {
              name: "parentCategory",
              value: id,
              operation: "eq",
            },
          ],
        });

        dispatch({
          type: "SET_SUB_CATEGORIES",
          payload: { subCategories: data },
        });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.category.getCategory(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICategory) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createCategory = async (request: ICreateCategory) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.category.createCategory(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateCategory = async (id: number, request: IUpdateCategory) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.category.updateCategory(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCategory = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.category.deleteCategory(id);
        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ICategoryQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.category.exportExcel(request, {
          ...state.query,
          filters: [
            {
              name: "parentCategory",
              value: null,
              operation: "eq",
            },
          ],
        });
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Categories-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <CategoryContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          getSubCategories,
          createCategory,
          updateCategory,
          deleteCategory,
          exportExcel,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryContextProvider;
