export interface ICreateCopyrightRequest {
  officialDate: string;
  clientCost: number;
  depositNumber: string;
  status: number;
  copyrightId: number;
  workflowId: number;
  registrationDate: string;
  registrationNumber: string;
  note: string;
}

export interface IUpdateCopyrightRequest extends ICreateCopyrightRequest {}

export interface ICopyrightRequestExportExcel {
  depositNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  mainCategories: boolean;
  copyright: boolean;
  clients: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  officialDate: boolean;
  clientCost: boolean;
  officeCosts: boolean;
  clientPayment: boolean;
  totalCost: boolean;
}

export const namesOfColumnsCopyrightRequest = [
  "depositNumber",
  "Country",
  "typeWorkflow",
  "mainCategories",
  "copyright",
  "clients",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "officialDate",
  "clientCost",
  "officeCosts",
  "clientPayment",
  "totalCost",
];
