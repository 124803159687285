import { IDrawingClientPaymentsQuery } from "../../../models/drawing-collection/client-payments/query";

import {
  ICreatePatentsClientPayment,
  IUpdatePatentsClientPayment,
} from "../../../models/patents-collection/client-payments/request";
import {
  IPatentsClientPayment,
  IPatentsClientPaymentDetails,
} from "../../../models/patents-collection/client-payments/response";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class PantetsClientPaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClientPayments = (
    query: IDrawingClientPaymentsQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPatentsClientPayment>>({
      path: `/patent-request-client-payments`,
      method: "GET",
      query,
      ...params,
    });

  getClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsClientPaymentDetails>({
      path: `/patent-request-client-payments/${id}`,
      method: "GET",
      ...params,
    });

  createClientPayment = (
    data: ICreatePatentsClientPayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreatePatentsClientPayment>({
      path: "/patent-request-client-payments",
      method: "POST",
      body: data,
      ...params,
    });

  updateClientPayment = (
    id: number,
    data: IUpdatePatentsClientPayment,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsClientPayment>({
      path: `/patent-request-client-payments/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsClientPayment>({
      path: `/patent-request-client-payments/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default PantetsClientPaymentService;
