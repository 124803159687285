import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import CommercialsContext, { internalState } from "./context";
import reducer from "./reducer";

import moment from "moment";
import {
  ICreateCommercial,
  IUpdateCommercial,
} from "../../../../models/lawswits-collection/commercial/commercial-requests/request";
import { ICommercialQuery } from "../../../../models/lawswits-collection/commercial/commercial-requests/query";
import { ICommercialDetails } from "../../../../models/lawswits-collection/commercial/commercial-requests/response";
interface IProps {
  children: React.ReactNode;
}
const CommerciaContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getList = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.commercial.getAllCommercial(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createCommercial = async (request: ICreateCommercial) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.commercial.createCommercial(request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ICommercialQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const deleteCommercial = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.commercial.deleteCommercial(id);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };
  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.commercial.getCommercial(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICommercialDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const updateCommercial = async (id: number, request: IUpdateCommercial) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.commercial.updateCommercial(id, request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.commercial.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",

          `Commercial-Lawswits-Requsert-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  // const exportPdf = async (id: number) => {
  //   await execute({
  //     callback: async () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //       const data = await EndPoints.trademarkRequest.exportPdf(id);
  //       const url = window.URL.createObjectURL(new Blob([data.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         `Trademark-Requsert-${moment().format("yyyy-MM-d")}.pdf`
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //     },
  //     fallback: (error) => {},
  //     finallyCallback: () => {
  //       dispatch({
  //         type: "LOADING",
  //         payload: { loading: "create" },
  //       });
  //     },
  //     throwException: true,
  //   });
  // };
  return (
    <CommercialsContext.Provider
      value={{
        ...state,
        actions: {
          getList,
          getDetails,
          setDetails,
          createCommercial,
          updateCommercial,
          deleteCommercial,

          exportExcel,
          // exportPdf,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CommercialsContext.Provider>
  );
};

export default CommerciaContextProvider;
