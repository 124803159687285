import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IncidentsLicensingOfficeCostContext from "../../../../../context/incidents-collection/incidents-licensing-requests/office-cost/context";
import { ICostType } from "../../../../../models/managent-collection/cost-types/response";
import EndPoints from "../../../../../services/end-points";
import FieldBuilder from "../../../../form-components/field-builder";
import Spin from "../../../../general/antd/spin";

interface IProps {
  loading?: boolean;
}

const OfficeCostForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { loading } = useContext(IncidentsLicensingOfficeCostContext);

  const [costTypes, setCostTypes] = useState<ICostType[]>([]);
  const [costTypeLoading, setCostTypeLoading] = useState(true);
  useEffect(() => {
    try {
      const getCostTypes = async () => {
        await EndPoints.costType.getAllCostTypes({}).then((res) => {
          setCostTypes(res?.data?.data);
        });
      };
      getCostTypes();
    } catch (error) {
    } finally {
      setCostTypeLoading(false);
    }
  }, []);

  return (
    <>
      {loading.includes("create") || loading.includes("details") ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <Row gutter={[6, 6]}>
            <Col span={8}>
              <FieldBuilder
                name="value"
                label={`${t("value")}`}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="receiptNumber"
                label={`${t("receipt_number")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="costTypeId"
                label={`${t("cost_type")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  loading: costTypeLoading,
                  options: costTypes?.map((ct, index) => {
                    return {
                      key: index,
                      label: ct.name,
                      value: ct.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="date"
                label={`${t("date_payment")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default OfficeCostForm;
