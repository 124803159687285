import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import reducer from "./reducer";
import { IOfficeCostDetails } from "../../../models/trademark-collection/office-cost/response";
import {
  ICreateOfficeCost,
  IUpdateOfficeCost,
} from "../../../models/trademark-collection/office-cost/request";
import { IOfficeCostQuery } from "../../../models/trademark-collection/office-cost/query";
import OfficeCostContext, { internalState } from "./context";
import DrawingOfficeCostContext from "./context";
import { IDrawingOfficeCostQuery } from "../../../models/drawing-collection/office-cost/query";
import {
  ICreateDrawingOfficeCost,
  IUpdateDrawingOfficeCost,
} from "../../../models/drawing-collection/office-cost/request";
import { IDrawingOfficeCostDetails } from "../../../models/drawing-collection/office-cost/response";

export interface IProps {
  children: React.ReactNode;
}
const DrawingOfficeCostContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.drawingOfficeCost.getAllOfficeCosts({
          ...state.query,
          filters: [
            {
              name: "DrawingRequest",
              operation: "eq",
              value: id,
            },
          ],
        });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.drawingOfficeCost.getOfficeCost(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDrawingOfficeCostDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createOfficeCost = async (request: ICreateDrawingOfficeCost) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.drawingOfficeCost.createOfficeCost(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateOfficeCost = async (
    id: number,
    request: IUpdateDrawingOfficeCost
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.drawingOfficeCost.updateOfficeCost(id, request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteOfficeCost = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.drawingOfficeCost.deleteOfficeCost(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingOfficeCostQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <DrawingOfficeCostContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteOfficeCost,
          createOfficeCost,

          updateOfficeCost,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DrawingOfficeCostContext.Provider>
  );
};

export default DrawingOfficeCostContextProvider;
