import { useEffect, useReducer } from "react";

import CostTypeContext, { internalState } from "./context";
import reducer from "./reducer";
import { execute } from "../../../utils/helpers/execute";
import EndPoints from "../../../services/end-points";
import { IDrawingDetails } from "../../../models/drawing-collection/drawing/response";
import {
  ICreateDrawing,
  IUpdateDrawing,
} from "../../../models/drawing-collection/drawing/request";
import { IDrawingQuery } from "../../../models/drawing-collection/drawing/query";
import DrawingContext from "./context";
import moment from "moment";

export interface IProps {
  children: React.ReactNode;
}
const DrawingContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.drawing.getAllDrawing(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.drawing.getDrawing(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDrawingDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createDrawing = async (request: ICreateDrawing) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.drawing.createDrawing(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateDrawing = async (id: number, request: IUpdateDrawing) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.drawing.updateDrawing(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteDrawing = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.drawing.deleteDrawing(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.drawing.exportExcel(request, state.query);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Drawing-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <DrawingContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          exportExcel,
          createDrawing,
          updateDrawing,
          deleteDrawing,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DrawingContext.Provider>
  );
};

export default DrawingContextProvider;
