import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import {
  IDataRequest,
  IDataRequestDetails,
} from "../../../models/independent-requests-collection/data-request/response";
import { IDataRequestQuery } from "../../../models/independent-requests-collection/data-request/query";
import {
  ICreateDataRequest,
  IUpdateDataRequest,
} from "../../../models/independent-requests-collection/data-request/request";

export type DataRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: DataRequestLoading[];

  list?: IBaseListingResponse<IDataRequest>;
  query: IDataRequestQuery;

  details?: IDataRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDataRequestDetails) => void;

    createDataRequest: (request: ICreateDataRequest) => void;
    updateDataRequest: (id: number, request: IUpdateDataRequest) => void;
    deleteDataRequest: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDataRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createDataRequest: DEFAULT_FUNCTION,
    updateDataRequest: DEFAULT_FUNCTION,
    deleteDataRequest: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DataRequestContext = createContext(externalState);

export default DataRequestContext;
