import { Descriptions as AntdDescriptions, Collapse, Space } from "antd";
import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../form-components/view-details";
import Descriptions from "../../../general/antd/descriptions";
import EditeButton from "../../../general/antd/edite-button";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";

import office from "../../../../assets/images/icon-files/office.jpg";
import pdf from "../../../../assets/images/icon-files/pdf.png";
import CopyrightReservationContextProvider from "../../../../context/copyright-collection/copyright-reservation/provider";
import CopyrightContext from "../../../../context/copyright-collection/copyright/context";
import CopyrightContextProvider from "../../../../context/copyright-collection/copyright/provider";
import {
  getCopyrightColor,
  getCopyrightCountryColor,
  getCopyrightCountryType,
  getCopyrightType,
} from "../../../../models/copyright-collection/copyright/enum";
import styles from "./styles.module.scss";
import CopyrightReservationTabel from "../../copyright-reservation/copyright-resevation-tabel";
import moment from "moment";
interface Props {}
const { Panel } = Collapse;

const Copyrihgt: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CopyrightContext);
  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  let srcImage = "";

  switch (details?.file?.extension) {
    case "pdf":
      srcImage = pdf;
      break;
    case "docx":
      srcImage = office;
      break;
    default:
      srcImage = office;
      break;
  }

  return (
    <>
      <DetailsPage title={t(`${"copyright_details"}`)}>
        <div className={styles.container}>
          <Space direction="vertical">
            <Collapse>
              <Panel header={t("copyright_details")} key="1">
                <EditeButton
                  onClick={() => navigate(`/copyright/update/${id}`)}
                  className={styles.button}
                >
                  {t(`${"update_copyright"}`)}
                </EditeButton>
                {loading.includes("details") ? (
                  <Spin />
                ) : (
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_ar")}`}>
                      {details?.translations?.name[0]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_en")}`}>
                      {details?.translations?.name[1]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("copyright_type")}>
                      <Tag
                        color={getCopyrightColor(Number(details?.type))}
                        title={getCopyrightType(Number(details?.type))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("copyright_area")}>
                      <Tag
                        color={getCopyrightCountryColor(
                          Number(details?.countryType)
                        )}
                        title={getCopyrightCountryType(
                          Number(details?.countryType)
                        )}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("authorsDeathDate")}>
                      {details?.authorsDeathDate
                        ? moment(details?.authorsDeathDate).format("DD/MM/YYYY")
                        : "-"}{" "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("clients")}>
                      {details?.clients
                        ? details?.clients?.map((client, index) => (
                            <Fragment key={index}>
                              <Tag title={client.name} />
                            </Fragment>
                          ))
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("explanation")}>
                      {details?.explanation ? details.explanation : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("description")}>
                      {details?.description ? details.description : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("files")}>
                      {details?.file ? (
                        <a href={details?.file?.url}>
                          <div className={styles.fileCardContainer}>
                            <Image src={srcImage} className={styles?.imgFile} />
                          </div>
                        </a>
                      ) : (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={1}>
              <Panel header={t("copyright_reservation")} key="1">
                <CopyrightReservationTabel />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </DetailsPage>
    </>
  );
};

const CopyrightDetailsPage = () => {
  return (
    <CopyrightContextProvider>
      <CopyrightReservationContextProvider>
        <Copyrihgt />
      </CopyrightReservationContextProvider>
    </CopyrightContextProvider>
  );
};
export default CopyrightDetailsPage;
