import { createContext } from "react";
import { ICopyrightRequestCostQuery } from "../../../models/copyright-collection/copyright-request-costs/query";
import {
  ICopyrightRequestCosts,
  ICopyrightRequestCostsDetails,
} from "../../../models/copyright-collection/copyright-request-costs/response";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  ICreateCopyrightRequestCosts,
  IUpdateCopyrightRequestCosts,
} from "../../../models/copyright-collection/copyright-request-costs/request";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

export type OfficeCostLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OfficeCostLoading[];

  list?: IBaseListingResponse<ICopyrightRequestCosts>;
  query: ICopyrightRequestCostQuery;

  details?: ICopyrightRequestCostsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICopyrightRequestCostsDetails) => void;

    createOfficeCost: (request: ICreateCopyrightRequestCosts) => void;
    updateOfficeCost: (
      id: number,
      request: IUpdateCopyrightRequestCosts
    ) => void;
    deleteOfficeCost: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ICopyrightRequestCostQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createOfficeCost: DEFAULT_FUNCTION,
    updateOfficeCost: DEFAULT_FUNCTION,
    deleteOfficeCost: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CopyrightOfficeCostContext = createContext(externalState);

export default CopyrightOfficeCostContext;
