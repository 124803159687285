import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import TrademarkReservationContext from "../../../../context/trademark-collection/trademark-reservation/context";
import TrademarkContext from "../../../../context/trademark-collection/trademark/context";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import { ICreateTrademarkReservation } from "../../../../models/trademark-collection/trademark-reservation/request";
import {
  getBooleanTrademark,
  getTrademarkColor,
} from "../../../../models/trademark-collection/trademark/enum";
import { ITrademark } from "../../../../models/trademark-collection/trademark/response";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import FormHookModal from "../../../general/modals/form-hook-modal";
import AddBtnTable from "../../../general/table-components/actions/add-table-btn";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import TrademarkReservationForm from "../trademark-reservation/trademark-reservation-form";

interface IProps {}

const TrademarkTabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(TrademarkContext);
  const { actions: actionReservation, loading: loadingReservation } =
    useContext(TrademarkReservationContext);
  const [infoId, setInfoioId] = useState<number>();
  const [Id, setId] = useState<number>();
  const [mainCategory, setMainCategory] = useState<number>();
  const [Category, setCategories] = useState<number[]>([]);
  const columns: ColumnProps<ITrademark>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("name_ar"),
      dataIndex: "name",
      align: "center",
      sorter: {
        multiple: 2,
      },
      key: "name",
      render: (_, record) => {
        return <div>{record.translations?.name[0]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("name_en"),
      dataIndex: "name",
      align: "center",
      sorter: {
        multiple: 3,
      },
      key: "name",
      render: (_, record) => {
        return <div>{record.translations?.name[1]?.value ?? "-"}</div>;
      },
    },
    {
      title: t("clients"),
      align: "center",
      width: 100,
      dataIndex: "clients",
      key: "clients",
      render: (_, { clients }) => (
        <>
          {clients?.map((client) => {
            return <Tag title={client?.name}></Tag>;
          }) ?? "-"}
        </>
      ),
    },
    {
      title: t("type_trademark"),
      dataIndex: "type",
      align: "center",
      key: "type",
      render: (record) => {
        return (
          <Tag
            title={getBooleanTrademark(Number(record))}
            color={getTrademarkColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("main_country"),
      dataIndex: "mainCountry.id",
      align: "center",
      key: "mainCountry",
      render: (_, { mainCountry }) => {
        return <p> {mainCountry?.name} </p>;
      },
    },

    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <AddBtnTable
            title="add_trademark_reservation"
            onClick={async () => {
              setId(record?.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteTrademark(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];
  const changeSubCategoryId = async (id: number[]) => {
    setCategories(id);
  };
  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={loadingReservation.includes("create")}
        title={t("add_reservation_trademark")}
        hideModal={() => {
          hideFormodal();
          setInfoioId(undefined);
          setMainCategory(undefined);
          setCategories(undefined);
        }}
        width={"60vw"}
        onSubmit={async (data) => {
          await actionReservation.createTrademarkReservation(data, Id);
          setInfoioId(undefined);
          setMainCategory(undefined);
          setCategories(undefined);
        }}
        defaultValues={
          {
            trademarkId: Id,
            allCategories: false,
          } as ICreateTrademarkReservation
        }
      >
        <TrademarkReservationForm SubCategory={changeSubCategoryId} />
      </FormHookModal>
    ),
    [loading, Id, infoId, mainCategory]
  );

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        size="small"
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default TrademarkTabel;
