import { ICopyrightClientPaymentsQuery } from "../../../models/copyright-collection/client-payments/query";
import {
  ICopyrightClientPayment,
  ICopyrightClientPaymentDetails,
} from "../../../models/copyright-collection/client-payments/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { ClientPaymentLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: ClientPaymentLoading | ClientPaymentLoading[] };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<ICopyrightClientPayment> };
    }
  | {
      type: "SET_DETAILS";
      payload: { details?: ICopyrightClientPaymentDetails };
    }
  | QueryAction<ICopyrightClientPaymentsQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
