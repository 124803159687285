import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import AgentRegistrationContext from "../../../../context/independent_requests-collection/agent-registration/context";
import AgentRegistrationContextProvider from "../../../../context/independent_requests-collection/agent-registration/provider";
import Descriptions from "../../../general/antd/descriptions";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";
import PageDetails from "../../../general/details";
import styles from "./styles.module.scss";
import {
  checkType,
  getUrlImageFile,
} from "../../../../utils/helpers/check-type-file";
import PaymentRequestTable from "../../clients-payments/client-payment-table";
import PaymentRequestContextProvider from "../../../../context/independent_requests-collection/request-payments/provider";
import CostRequesTable from "../../office-cost/office-cost-table";
import CostRequestContextProvider from "../../../../context/independent_requests-collection/request-costs/provider";
interface Props {}
const AgentRegistrationRequestDetails: React.FC<Props> = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(AgentRegistrationContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"agent_registration_request"}`}
        buttonName={`${"update_agent_registration_request"}`}
        onClick={() => navigate(`/agent-registration-request/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Collapse>
                  <Panel
                    header={t("details_agent_registration_request")}
                    key="1"
                  >
                    <Descriptions size="small" column={1} bordered>
                      <AntdDescriptions.Item label={t("id")}>
                        {details?.id}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client")}>
                        {details?.client?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("country")}>
                        {details?.country?.name}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("depositNumber")}>
                        {details?.depositNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("official_date")}>
                        {details?.officialDate}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("financialReceiptNumber")}
                      >
                        {details?.financialReceiptNumber}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("dateFinancialReceipt")}>
                        {details?.dateFinancialReceipt}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("mailing_address")}>
                        {details?.address ? (
                          details?.address
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("privateOfficeAddress")}>
                        {details?.privateOfficeAddress ? (
                          details?.privateOfficeAddress
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("companyManagerName")}>
                        {details?.companyManagerName ? (
                          details?.companyManagerName
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("legalFormPerson")}>
                        {details?.legalFormPerson ? (
                          details?.legalFormPerson
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("order_value")}>
                        {details?.orderValue}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("client_payments")}>
                        {details?.sumPayments}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("sum_costs")}>
                        {details?.sumCosts}
                      </AntdDescriptions.Item>
                      <AntdDescriptions.Item label={t("note")}>
                        {details?.note ? (
                          details?.note
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("universityQualification")}
                      >
                        {details?.universityQualification ? (
                          <a
                            target="_blank"
                            href={details?.universityQualification?.url}
                          >
                            <div className={styles.fileCardContainer}>
                              {checkType(
                                details?.universityQualification.extension
                              ) ? (
                                <Image
                                  src={details?.universityQualification?.url}
                                  className={styles?.imgFile}
                                  alt={
                                    details?.universityQualification?.extension
                                  }
                                />
                              ) : (
                                <Image
                                  src={getUrlImageFile(
                                    details?.universityQualification?.extension
                                  )}
                                  className={styles?.imgFile}
                                  alt={
                                    details?.universityQualification?.extension
                                  }
                                />
                              )}
                            </div>
                          </a>
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item label={t("commercialRegister")}>
                        {details?.commercialRegister ? (
                          <a
                            target="_blank"
                            href={details?.commercialRegister?.url}
                          >
                            <div className={styles.fileCardContainer}>
                              {checkType(
                                details?.commercialRegister.extension
                              ) ? (
                                <Image
                                  src={details?.commercialRegister?.url}
                                  className={styles?.imgFile}
                                  alt={details?.commercialRegister?.extension}
                                />
                              ) : (
                                <Image
                                  src={getUrlImageFile(
                                    details?.commercialRegister?.extension
                                  )}
                                  className={styles?.imgFile}
                                  alt={details?.commercialRegister?.extension}
                                />
                              )}
                            </div>
                          </a>
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("restrictionOrPersonalPhoto")}
                      >
                        {details?.restrictionOrPersonalPhoto ? (
                          <a
                            target="_blank"
                            href={details?.restrictionOrPersonalPhoto?.url}
                          >
                            <div className={styles.fileCardContainer}>
                              {checkType(
                                details?.restrictionOrPersonalPhoto.extension
                              ) ? (
                                <Image
                                  src={details?.restrictionOrPersonalPhoto?.url}
                                  className={styles?.imgFile}
                                  alt={
                                    details?.restrictionOrPersonalPhoto
                                      ?.extension
                                  }
                                />
                              ) : (
                                <Image
                                  src={getUrlImageFile(
                                    details?.restrictionOrPersonalPhoto
                                      ?.extension
                                  )}
                                  className={styles?.imgFile}
                                  alt={
                                    details?.restrictionOrPersonalPhoto
                                      ?.extension
                                  }
                                />
                              )}
                            </div>
                          </a>
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>

                      <AntdDescriptions.Item
                        label={t("criminalDocumentOrUnjudged")}
                      >
                        {details?.criminalDocumentOrUnjudged ? (
                          <a
                            target="_blank"
                            href={details?.criminalDocumentOrUnjudged?.url}
                          >
                            <div className={styles.fileCardContainer}>
                              {checkType(
                                details?.criminalDocumentOrUnjudged.extension
                              ) ? (
                                <Image
                                  src={details?.criminalDocumentOrUnjudged?.url}
                                  className={styles?.imgFile}
                                  alt={
                                    details?.criminalDocumentOrUnjudged
                                      ?.extension
                                  }
                                />
                              ) : (
                                <Image
                                  src={getUrlImageFile(
                                    details?.criminalDocumentOrUnjudged
                                      ?.extension
                                  )}
                                  className={styles?.imgFile}
                                  alt={
                                    details?.criminalDocumentOrUnjudged
                                      ?.extension
                                  }
                                />
                              )}
                            </div>
                          </a>
                        ) : (
                          <Tag title="not_found" color="red" />
                        )}
                      </AntdDescriptions.Item>
                    </Descriptions>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("client_payments")} key={1}>
                    <PaymentRequestTable requestType="App\Models\AgentRegistrationRequest" />
                  </Panel>
                </Collapse>
              </Col>
              <Col span={24}>
                <Collapse>
                  <Panel header={t("office_cost")} key={1}>
                    <CostRequesTable requestType="App\Models\AgentRegistrationRequest" />
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </PageDetails>
    </>
  );
};

const AgentRegistrationRequestDetailsPage = () => {
  return (
    <AgentRegistrationContextProvider>
      <PaymentRequestContextProvider>
        <CostRequestContextProvider>
        <AgentRegistrationRequestDetails />
        </CostRequestContextProvider>
      </PaymentRequestContextProvider>
    </AgentRegistrationContextProvider>
  );
};
export default AgentRegistrationRequestDetailsPage;
