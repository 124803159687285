import { IcommercialRequestFilesQuery } from "../../../../models/lawswits-collection/commercial/commercial-requset-files/query";
import {
  ICreateCommercialRequestFiles,
  IUpdateCommercialRequestFiles,
} from "../../../../models/lawswits-collection/commercial/commercial-requset-files/request";
import {
  ICommercialRequesFiles,
  ICommercialRequesFilesDetails,
} from "../../../../models/lawswits-collection/commercial/commercial-requset-files/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class CommercialRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCommercialRequsetFiles = (
    query: IcommercialRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ICommercialRequesFiles>>({
      path: `/commercial-lawsuits-files`,
      method: "GET",
      query,
      ...params,
    });

  getCommercialRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<ICommercialRequesFilesDetails>({
      path: `/commercial-lawsuits-files/${id}`,
      method: "GET",
      ...params,
    });

  createCommercialRequsetFile = (
    data: ICreateCommercialRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<ICommercialRequesFilesDetails>({
      path: "/commercial-lawsuits-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateCommercialRequsetFile = (
    id: number,
    data: IUpdateCommercialRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<ICommercialRequesFiles>({
      path: `/commercial-lawsuits-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCommercialRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<ICommercialRequesFiles>({
      path: `/commercial-lawsuits-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default CommercialRequsetfileService;
