import { Button, Dropdown, MenuProps, Tag, theme, Tooltip } from "antd";
import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useModal } from "react-modal-hook";
import DrawingRequestContext from "../../../../../context/drawing-collection/drawing-request/context";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import {
  Attachments,
  DrawingRequestStatus,
  DrawingRequestStatusColor,
  DrawingRequestStatusString,
  getDrawingAttachmentsString,
  getDrawingAttachmentsonColor,
} from "../../../../../models/drawing-collection/drawing-request/enum";
import { IDrawingRequest } from "../../../../../models/drawing-collection/drawing-request/response";
import { IMoveTrademrk } from "../../../../../models/trademark-collection/move-trademark-request/request";
import EndPoints from "../../../../../services/end-points";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import MoveBtn from "../../../../general/table-components/actions/move-btn";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import MoveDrawingRequest from "../../move-drawing/form";
import { IWorkflowLight } from "../../../../../models/workflow/response";
import {
  FilePdfOutlined,
  FileWordOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useToken } from "antd/es/theme/internal";

const DrawingRequestsTable: React.FC = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } = useContext(
    DrawingRequestContext
  );
  const [infoId, setInfoId] = useState<number>(undefined);
  const [loadingMove, setLoadingMove] = useState(false);
  const { useToken } = theme;
  const { token } = useToken();
  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    padding: "2px",
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ss"
        confirmButtonText={t("save")}
        loading={loadingMove}
        title={t("move_trademark_request_to_another_node")}
        hideModal={() => {
          setInfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "-4rem", maxHeight: "90vh", overflowY: "scroll" }}
        defaultValues={
          {
            officialDate: null,
          } as IMoveTrademrk
        }
        width={"90vw"}
        onSubmit={async (data) => {
          setLoadingMove(true);
          try {
            await EndPoints.drawingRequest.moveDrawingReseqestNode(
              infoId,
              data
            );
            setInfoId(undefined);
            actions.getList();
          } catch (err) {
          } finally {
            setLoadingMove(false);
          }
        }}
      >
        {infoId && <MoveDrawingRequest id={infoId} />}
      </FormHookModal>
    ),
    [details, loadingMove, infoId]
  );
  const items: MenuProps["items"] = [];

  const columns: ColumnProps<IDrawingRequest>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 70,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      width: 80,
      align: "center",
      render: (_, record) => record?.depositNumber ?? "-",
    },

    {
      title: t("order_template"),
      dataIndex: "workflow",
      width: 150,
      key: "requestTemplate",
      align: "center",
      render: (workflow: IWorkflowLight) => workflow?.name,
    },

    {
      title: t("drawings"),
      dataIndex: "dd",
      key: "d",
      width: 160,
      align: "center",
      render: (_, record) =>
        record?.drawings?.map((c) => (
          <div style={{ margin: "3px 0" }}>
            <Tag>{c?.name}</Tag>
          </div>
        )) ?? "-",
    },
    {
      title: t("country"),
      key: "country",
      align: "center",
      render: (_, record) => record?.workflow?.country?.name ?? "-",
    },
    {
      title: t("clients"),
      dataIndex: "aa",
      width: 130,
      key: "clients",
      align: "center",
      render: (_, record) =>
        record?.clients?.map((c) => <Tag>{c?.name}</Tag>) ?? "-",
    },
    {
      title: t("current_node"),
      dataIndex: "currentNode",
      key: "currentNode",
      width: 180,
      align: "center",
      render: (_, record) => record?.currentNode?.name?.name,
    },
    {
      title: t("request_status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status: DrawingRequestStatus) => (
        <Tag color={DrawingRequestStatusColor(status)}>
          {t(DrawingRequestStatusString(status))}
        </Tag>
      ),
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      key: "ss",
      sorter: {
        multiple: 2,
      },
      align: "center",
      render: (officialDate: Date) => moment(officialDate).format("DD/MM/YYYY"),
    },
    {
      title: t("creation_date"),
      dataIndex: "createdAt",
      key: "ش",
      sorter: {
        multiple: 3,
      },
      align: "center",
      render: (createdAt: Date) => moment(createdAt).format("DD/MM/YYYY"),
    },
    // {
    //   title: t("update_date"),
    //   dataIndex: "updatedAt",
    //   key: "updatedAt",
    //   sorter: {
    //     multiple: 4,
    //   },
    //   align: "center",
    //   render: (updatedAt: Date) => moment(updatedAt).format("DD/MM/YYYY"),
    // },
    {
      title: t("attachments"),
      dataIndex: "attachments",
      key: "attachments",
      align: "center",
      width: 130,
      render: (attachments: Attachments) => (
        <Tag color={getDrawingAttachmentsonColor(attachments)}>
          {t(getDrawingAttachmentsString(attachments))}
        </Tag>
      ),
    },
    {
      title: t("actions"),
      width: 250,
      align: "center",
      key: "x",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <Tooltip title={t("export_deposit_template")}>
            <Button
              type="default"
              shape="circle"
              loading={loading.includes("pdf")}
              disabled={
                record?.workflow?.type === 1 || record?.workflow?.type === 2
                  ? false
                  : true
              }
              onClick={() => actions.exportDepositTemplatePdf(record?.id)}
              icon={<FilePdfOutlined style={{ color: "#ff3f3f" }} />}
            />
          </Tooltip>
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />

          <Tooltip title={t("export_pdf")}>
            <Button
              type="dashed"
              shape="circle"
              loading={loading.includes("create")}
              onClick={() => actions.exportPdf(record?.id)}
              // onClick={() => download(record.id)}
              icon={<FilePdfOutlined style={{ color: "#ff3f3f" }} />}
            />
          </Tooltip>
          <MoveBtn
            onClick={async () => {
              await setInfoId(record.id);
              await showFormModal();
            }}
          />
          <Dropdown
            menu={{ items }}
            mouseLeaveDelay={0.3}
            placement="bottom"
            dropdownRender={(menu) => (
              <div style={contentStyle}>
                {React.cloneElement(menu as React.ReactElement, {
                  style: menuStyle,
                })}
                <Button
                  type="text"
                  style={{ color: "#0000ff", marginBottom: "2px" }}
                  loading={loading.includes("word")}
                  onClick={() => actions.exportWord(record?.id, 1)}
                  icon={<FileWordOutlined />}
                >
                  {t("export_word")}
                </Button>
                <br></br>
                <Button
                  type="text"
                  style={{ color: "#0000ff", marginBottom: "2px" }}
                  loading={loading.includes("word")}
                  onClick={() => actions.exportWord(record?.id, 2)}
                  icon={<FileWordOutlined />}
                >
                  {t("export_word_external")}
                </Button>
                <br></br>
                <Button
                  type="text"
                  style={{ color: "#0000ff", marginBottom: "2px" }}
                  loading={loading.includes("word")}
                  onClick={() => actions.exportDepositWord(record?.id, 1)}
                  icon={<FileWordOutlined />}
                >
                  {t("export_deposit_word")}
                </Button>
                <br></br>
                <Button
                  type="text"
                  style={{ color: "#0000ff", marginBottom: "2px" }}
                  loading={loading.includes("word")}
                  onClick={() => actions.exportDepositWord(record?.id, 2)}
                  icon={<FileWordOutlined />}
                >
                  {t("export_deposit_word_external")}
                </Button>
              </div>
            )}
          >
            <Button
              shape="circle"
              loading={loading.includes("word")}
              icon={<MoreOutlined />}
            />
          </Dropdown>
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteRequest(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        size="small"
        showSorterTooltip
        tableLayout="auto"
        total={list?.total}
        pageSize={query.perPage}
        scroll={{ x: 1600 }}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default DrawingRequestsTable;
