import { IUserQuery } from "../../../models/managent-collection/user/query";
import { IUser } from "../../../models/managent-collection/user/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { IInternalState, UserLoading } from "./context";

type Action =
  | { type: "LOADING"; payload: { loading: UserLoading | UserLoading[] } }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<IUser> } }
  | { type: "SET_DETAILS"; payload: { details?: IUser } }
  | QueryAction<IUserQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
