import { ICopyrightRequestCostQuery } from "../../../models/copyright-collection/copyright-request-costs/query";
import {
  ICreateCopyrightRequestCosts,
  IUpdateCopyrightRequestCosts,
} from "../../../models/copyright-collection/copyright-request-costs/request";
import {
  ICopyrightRequestCosts,
  ICopyrightRequestCostsDetails,
} from "../../../models/copyright-collection/copyright-request-costs/response";
import { IOfficeCost } from "../../../models/trademark-collection/office-cost/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class CopyrightOfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (
    query: ICopyrightRequestCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ICopyrightRequestCosts>>({
      path: `/copyright-request-costs`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightRequestCostsDetails>({
      path: `/copyright-request-costs/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (
    data: ICreateCopyrightRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IOfficeCost>({
      path: "/copyright-request-costs",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdateCopyrightRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<ICopyrightRequestCosts>({
      path: `/copyright-request-costs/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightRequestCosts>({
      path: `/copyright-request-costs/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default CopyrightOfficeCostService;
