import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../form-components/field-builder";
import { getTypes } from "../../../models/reoprt/enum";
import { useEffect, useState } from "react";
import EndPoints from "../../../services/end-points";
import { IClient } from "../../../models/client/response";
import { ICountry } from "../../../models/managent-collection/user/response";

const Form = () => {
  const { t } = useTranslation();

  const type = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [typeValue, setTypeValue] = useState<number>();

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, []);

  return (
    <Row gutter={[16, 8]}>
      <Col span={12}>
        <FieldBuilder
          name="type"
          label={`${t("type_report")}`}
          width="large"
          rules={{ required: true }}
          input={{
            setValue: setTypeValue,
            Value: typeValue,
            type: "selectValue",
            options: type?.map((el) => {
              return {
                label: t(getTypes(el) as string),
                value: el as number,
              };
            }),
          }}
        />
      </Col>
      {typeValue == 1 && (
        <Col span={12}>
          <FieldBuilder
            name="clientIds"
            label={`${t("clients")}`}
            width="large"
            input={{
              type: "multiSelect",
              loading: clientsLoading,
              options: clients?.map((client, index) => {
                return {
                  key: index,
                  label: client.name,
                  value: client.id,
                };
              }),
            }}
          />
        </Col>
      )}
      <Col span={12}>
        <FieldBuilder
          name="countryIds"
          label={`${t("country")}`}
          width="large"
          input={{
            type: "multiSelect",
            loading: countriesLoading,
            options: countries?.map((country, index) => {
              return {
                key: index,
                label: country.name,
                value: country.id,
              };
            }),
          }}
        />
      </Col>
      <Col span={12}>
        <FieldBuilder
          name="startDate"
          label={`${t("start_date")}`}
          rules={{ required: true }}
          input={{ type: "date-picker", format: "YYYY-MM-DD" }}
        />
      </Col>
      <Col span={12}>
        <FieldBuilder
          name="endDate"
          label={`${t("end_date")}`}
          rules={{ required: true }}
          input={{ type: "date-picker", format: "YYYY-MM-DD" }}
        />
      </Col>
    </Row>
  );
};

const ReportForm = () => {
  return <Form />;
};
export default ReportForm;
