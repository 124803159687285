import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import PatnetsRequestContext, { internalState } from "./context";
import reducer from "./reducer";

import moment from "moment";
import {
  ICreateCopyrightRequest,
  IUpdateCopyrightRequest,
} from "../../../models/copyright-collection/copyright-request/request";
import CopyrightRequestContext from "./context";
import { IDrawingRequestQuery } from "../../../models/drawing-collection/drawing-request/query";
import { ICopyrightRequestDetails } from "../../../models/copyright-collection/copyright-request/response";

export interface IProps {
  children: React.ReactNode;
}
const CopyrightRequestContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getList = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.copyrightRequest.getAllRequests(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createCopyrightsRequest = async (request: ICreateCopyrightRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.copyrightRequest.createCopyrightRequests(request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingRequestQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const deleteRequest = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.copyrightRequest.deleteRequest(id);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };
  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.copyrightRequest.getCopyrightRequest(
          id
        );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICopyrightRequestDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const updateCopyrightsRequest = async (
    id: number,
    request: IUpdateCopyrightRequest
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.copyrightRequest.updateCopyrightRequest(id, request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const addReservationToCopyrights = async (id: number, request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.copyrightRequest.addCopyrightReservations(id, request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.copyrightRequest.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",

          `Copyrights-Requsert-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  const exportPdf = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.copyrightRequest.exportPdf(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-Requsert-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  return (
    <CopyrightRequestContext.Provider
      value={{
        ...state,
        actions: {
          getList,
          getDetails,
          setDetails,
          createCopyrightsRequest,
          updateCopyrightsRequest,
          deleteRequest,

          exportExcel,
          exportPdf,

          addReservationToCopyrights,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CopyrightRequestContext.Provider>
  );
};

export default CopyrightRequestContextProvider;
