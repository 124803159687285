import React, { useContext, useState } from "react";
import { Avatar, Button, Divider, Row } from "antd";
import { PoweroffOutlined, LockOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import defaultUserImage from "../../../assets/images/user.jpg";
import styles from "./style.module.scss";
import AuthContext from "../../../context/auth/context";
import { useModal } from "react-modal-hook";
import FormHookModal from "../../general/modals/form-hook-modal";
import ChangePasswordForm from "../change-password-form";

interface IProps {}

const ProfileCard: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, loading, userDetails: user } = useContext(AuthContext);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ass"
        confirmButtonText={t("save")}
        loading={loading.includes("change_password")}
        title={t("change_password")}
        hideModal={() => {
          hideFormodal();
        }}
        style={{ marginTop: "3rem" }}
        defaultValues={{} as any}
        width={"40vw"}
        onSubmit={async (data) => {
          await actions.changePassword(data);
        }}
      >
        <ChangePasswordForm />
      </FormHookModal>
    ),
    []
  );

  return (
    <div>
      <div>
        <Row className={styles.contentContainer} align="middle">
          <Avatar
            size="large"
            src={user?.profileImage?.url ?? defaultUserImage}
            className={styles.avatar}
          />
          <div>
            <div>{user?.name ?? "Administrator"}</div>
            <small>{user?.jobTitle?.name ?? " "}</small>
          </div>
        </Row>
      </div>
      <Divider className={styles.divider} />
      <div>
        <div>
          <Button
            className={styles.button}
            type="text"
            onClick={() => {
              showFormModal();
            }}
            size="small"
            icon={<LockOutlined />}
          >
            {t("change_password")}
          </Button>
        </div>
        <div>
          <Button
            className={styles.button}
            type="text"
            onClick={() => {
              actions.logout();
            }}
            size="small"
            icon={<PoweroffOutlined />}
            loading={loading.includes("logout")}
          >
            {t("signout")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
