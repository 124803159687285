import { createContext } from "react";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IIncidentsWaiver,
  IIncidentsWaiverDetails,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/response";
import { IIncidentsWaiverQuery } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/query";
import { IIncidentsClientsDetails } from "../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/response";
import {
  ICreateIncidentsWaiver,
  IUpdateIncidentsWaiver,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/request";

export type incidentsWaiverLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete"
  | "word";

export interface IInternalState {
  loading: incidentsWaiverLoading[];

  list?: IBaseListingResponse<IIncidentsWaiver>;
  query: IIncidentsWaiverQuery;

  details?: IIncidentsWaiverDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsWaiverDetails) => void;

    createIncidentsWaiver: (request: ICreateIncidentsWaiver) => void;
    updateIncidentsWaiver: (
      id: number,
      request: IUpdateIncidentsWaiver
    ) => void;
    deleteIncidentsWaiver: (id: number) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    exportWord: (id: number , type:number) => any;
    
    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsWaiverQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createIncidentsWaiver: DEFAULT_FUNCTION,
    updateIncidentsWaiver: DEFAULT_FUNCTION,
    deleteIncidentsWaiver: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    exportWord: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsWaiversContext = createContext(externalState);

export default IncidentsWaiversContext;
