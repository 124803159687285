import axios from "axios";
import { INodeCanMove } from "../../../../models/trademark-collection/trademark-request/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../../utils/costants";
import { LANGUAGE_CODE } from "../../../../utils/helpers/constants";
import { ICommercialQuery } from "../../../../models/lawswits-collection/commercial/commercial-requests/query";
import {
  ICommercial,
  ICommercialDetails,
} from "../../../../models/lawswits-collection/commercial/commercial-requests/response";
import {
  ICreateCommercial,
  IExportCommercialExcel,
  IUpdateCommercial,
} from "../../../../models/lawswits-collection/commercial/commercial-requests/request";

class CommercialService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCommercial = (query: ICommercialQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICommercial>>({
      path: "/commercial-lawsuits",
      method: "GET",
      query,
      ...params,
    });

  createCommercial = (data: ICreateCommercial, params?: RequestParams) =>
    this.http.request<ICreateCommercial>({
      path: "/commercial-lawsuits",
      method: "POST",
      body: data,
      ...params,
    });

  deleteCommercial = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/commercial-lawsuits/${id}`,
      method: "DELETE",
      ...params,
    });

  updateCommercial = (
    id: number,
    data: IUpdateCommercial,
    params?: RequestParams
  ) =>
    this.http.request<ICommercial>({
      path: `/commercial-lawsuits/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getCommercial = (id: number, params?: RequestParams) =>
    this.http.request<ICommercialDetails>({
      path: `/commercial-lawsuits/${id}`,
      method: "GET",
      ...params,
    });

  moveCommercialReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/commercial-lawsuits/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });
  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/commercial-lawsuits/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (data: IExportCommercialExcel, query: ICommercialQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/commercial-lawsuits/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  // exportPdf = (id: number) =>
  //   axios.get(
  //     `${process.env.REACT_APP_BASE_API_URL}/commercial-lawsuits/export-template-PDF/${id}`,
  //     {
  //       headers: {
  //         authorization: `Bearer ${
  //           localStorage.getItem(ACCESS_TOKEN) ||
  //           sessionStorage.getItem(ACCESS_TOKEN)
  //         }`,
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Headers": "*",
  //         "Access-Control-Allow-Credentials": "true",
  //       },
  //       responseType: "arraybuffer",
  //     }
  //   );
}

export default CommercialService;
