export enum getAllStatusObjection {
  objection = 10,
}

export const getBooleanObjectionOrderTemplate = (
  status: getAllStatusObjection
) => {
  switch (status) {
    case getAllStatusObjection.objection:
      return "objection";
  }
};
