import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import EditeButton from "../../general/antd/edite-button";
import PageHeader from "../../general/layout/page-header";
import styles from "./style.module.scss";

interface Props {
  title?: string;
  subtitle?: string;
  buttonName?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const PageDetails: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.container}>
        <PageHeader
          title={t(`${props.title}`)}
          subTitle={t(`${props.subtitle}`)}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <EditeButton onClick={props.onClick} className={styles.button}>
            {t(`${props.buttonName}`)}
          </EditeButton>
          <Tooltip placement="topRight" title={t("go_to_back")}>
            <Button
              onClick={() => navigate(-1)}
              type="primary"
              style={{ width: "5rem" }}
              icon={
                <ArrowLeftOutlined spin={true} style={{ fontSize: "1.2rem" }} />
              }
            />
          </Tooltip>
        </div>

        {props.children}
      </div>
    </>
  );
};

export default PageDetails;
