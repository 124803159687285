import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import CommercialRequestFilesContext, { internalState } from "./context";

import reducer from "./reducer";
import { ICommercialRequesFilesDetails } from "../../../../models/lawswits-collection/commercial/commercial-requset-files/response";
import {
  ICreateCommercialRequestFiles,
  IUpdateCommercialRequestFiles,
} from "../../../../models/lawswits-collection/commercial/commercial-requset-files/request";
import { IcommercialRequestFilesQuery } from "../../../../models/lawswits-collection/commercial/commercial-requset-files/query";

export interface IProps {
  children: React.ReactNode;
}
const CommercialRequestFilesContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.commercialRequsetfile.getAllCommercialRequsetFiles({
            ...state.query,
            filters: [
              {
                name: "requestId",
                operation: "eq",
                value: id,
              },
            ],
          });
        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.commercialRequsetfile.getCommercialRequsetFile(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ICommercialRequesFilesDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createCommercialRequsetFile = async (
    request: ICreateCommercialRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.commercialRequsetfile.createCommercialRequsetFile(
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateCommercialRequsetFile = async (
    id: number,
    request: IUpdateCommercialRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.commercialRequsetfile.updateCommercialRequsetFile(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCommercialRequsetFile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.commercialRequsetfile.deleteCommercialRequsetFile(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IcommercialRequestFilesQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <CommercialRequestFilesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteCommercialRequsetFile,
          createCommercialRequsetFile,

          updateCommercialRequsetFile,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </CommercialRequestFilesContext.Provider>
  );
};

export default CommercialRequestFilesContextProvider;
