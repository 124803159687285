import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import NodesNameContext, { internalState } from "./context";
import reducer from "./reducer";
import { INodeNameDetails } from "../../../models/managent-collection/nodes-name/response";
import {
  ICreateNodeName,
  IUpdateNodeName,
} from "../../../models/managent-collection/nodes-name/request";
import { INodesNameQuery } from "../../../models/managent-collection/nodes-name/query";

export interface IProps {
  children: React.ReactNode;
}
const NodesNameContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.nodeName.getAllNodeName(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.nodeName.getNodeName(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
        //    navigate('update')
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: INodeNameDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createNodeName = async (request: ICreateNodeName) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.nodeName.createNodeName(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateNodeName = async (id: number, request: IUpdateNodeName) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.nodeName.updateNodeName(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteNodeName = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.nodeName.deleteNodeName(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: INodesNameQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <NodesNameContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createNodeName,
          updateNodeName,
          deleteNodeName,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </NodesNameContext.Provider>
  );
};

export default NodesNameContextProvider;
