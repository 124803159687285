import { ITranslationRequest } from "../../base/translation";
export interface ICreateObjectionRequest {
  objectingParty: string;
  objectingRequest: string;
  subject: string;
  description: string;
  clients: number[];
  orderValue: number;
  clientPayments: number;
  workflowId: number;
  officialDate:Date;
  status: number;
  depositNumber?: number
}

export interface IUpdateObjectionRequest extends ICreateObjectionRequest {}

// to do
export interface IExportObjectionRequestExcel {
  id: boolean;
  objectingParty: boolean;
  objectingRequest: boolean;
  subject: boolean;
  description: boolean;
  clients: boolean;
  createdAt: boolean;
}

export const ObjectionRequestCloumms = [
  "id",
  "objectingParty",
  "objectingRequest",
  "subject",
  "description",
  "clients",
  "createdAt",
];
