import axios from "axios";

import {
  ICreateAgentRegistration,
  IExportAgentRegistrationExcel,
  IUpdateAgentRegistration,
} from "../../../models/independent-requests-collection/agent-registration/request";
import {
  IAgentRegistration,
  IAgentRegistrationDetails,
} from "../../../models/independent-requests-collection/agent-registration/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { IAgentRegistrationQuery } from "../../../models/independent-requests-collection/agent-registration/query";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class AgentRegistrationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllAgentRegistration = (
    query: IAgentRegistrationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IAgentRegistration>>({
      path: `/agent-registration-request`,
      method: "GET",
      query,
      ...params,
    });

  getAgentRegistration = (id: number, params?: RequestParams) =>
    this.http.request<IAgentRegistrationDetails>({
      path: `/agent-registration-request/${id}`,
      method: "GET",
      ...params,
    });

  createAgentRegistration = (
    data: ICreateAgentRegistration,
    params?: RequestParams
  ) =>
    this.http.request<IAgentRegistration>({
      path: "/agent-registration-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateAgentRegistration = (
    id: number,
    data: IUpdateAgentRegistration,
    params?: RequestParams
  ) =>
    this.http.request<IAgentRegistration>({
      path: `/agent-registration-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteAgentRegistration = (id: number, params?: RequestParams) =>
    this.http.request<IAgentRegistration>({
      path: `/agent-registration-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportAgentRegistrationExcel,
    query: IAgentRegistrationQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/agent-registration-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default AgentRegistrationService;
