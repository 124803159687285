import { IIncidentsRequestCostQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/query";
import {
  ICreateIncidentsLicensingRequestCosts,
  IUpdateIncidentsLicensingRequestCosts,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-request-costs/request";

import {
  IIncidentsLicensingRequestCosts,
  IIncidentsLicensingRequestCostsDetails,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-request-costs/response";
import { IOfficeCost } from "../../../../models/trademark-collection/office-cost/response";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class IncidentsLicensingOfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (
    query: IIncidentsRequestCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsLicensingRequestCosts>>({
      path: `/incident-licensing-cost`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsLicensingRequestCostsDetails>({
      path: `/incident-licensing-cost/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (
    data: ICreateIncidentsLicensingRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IOfficeCost>({
      path: "/incident-licensing-cost",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdateIncidentsLicensingRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsLicensingRequestCosts>({
      path: `/incident-licensing-cost/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsLicensingRequestCosts>({
      path: `/incident-licensing-cost/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default IncidentsLicensingOfficeCostService;
