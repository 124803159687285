import { ITranslationRequest } from "../../base/translation";

export interface ICreateTrademarkRequest {
  officialDate: string;

  clientCost: number;
  depositNumber: string;
  status: number;
  trademarkId: number;
  workflowId: number;
  country: number;
  registrationDate: string;
  registrationNumber: string;
  note: string;
  registrationForeignCountries: boolean,
  trademarkIsUsed: boolean,
  nameCountry: string,
  numberAndDatePriorityRight: string,
  attachments: number
}

export interface IUpdateTrademarkRequest extends ICreateTrademarkRequest { }

export interface ITrademarkRequestExportExcel {
  depositNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  mainCategories: boolean;
  trademark: boolean;
  clients: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  officialDate: boolean;
  clientCost: boolean;
  fine: boolean;
  firstPublishDate: boolean;
  firstPublishNumber: boolean;
  secondPublishDate: boolean;
  secondPublishNumber: boolean;
  officeCosts: boolean;
  clientPayment: boolean;
  totalCostboolean: boolean;
  registrationForeignCountries:boolean,
  trademarkIsUsed:boolean,
  nameCountry:boolean,
  numberAndDatePriorityRight:boolean,
  attachments:boolean
}

export const namesOfColumnsTrademarkRequest = [
  "depositNumber",
  "Country",
  "typeWorkflow",
  "mainCategories",
  "trademark",
  "clients",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "officialDate",

  "clientCost",
  "fine",
  "firstPublishDate",
  "firstPublishNumber",
  "secondPublishDate",
  "secondPublishNumber",
  "officeCosts",
  "clientPayment",
  "totalCost",
  "registrationForeignCountries",
  "trademarkIsUsed",
  "nameCountry",
  "numberAndDatePriorityRight",
  "attachments"
];
