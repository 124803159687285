import { createContext } from "react";
import { IClientPaymentsQuery } from "../../../models/trademark-collection/client-payments/query";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  IClientPayment,
  IClientPaymentDetails,
} from "../../../models/trademark-collection/client-payments/response";
import {
  ICreateClientPayment,
  IUpdateClientPayment,
} from "../../../models/trademark-collection/client-payments/request";

export type ClientPaymentLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ClientPaymentLoading[];

  list?: IBaseListingResponse<IClientPayment>;
  query: IClientPaymentsQuery;

  details?: IClientPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IClientPaymentDetails) => void;

    createClientPayment: (request: ICreateClientPayment) => void;
    updateClientPayment: (id: number, request: IUpdateClientPayment) => void;
    deleteCleintPayment: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IClientPaymentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClientPayment: DEFAULT_FUNCTION,
    updateClientPayment: DEFAULT_FUNCTION,
    deleteCleintPayment: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const ClientPaymentContext = createContext(externalState);

export default ClientPaymentContext;
