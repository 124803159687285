import { Col, Row } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import FieldBuilder from "../../../../form-components/field-builder";
import { getIRecordType } from "../../../../../models/trademark-collection/trademark-reservation/enum";
import RecordPatentsContextProvider from "../../../../../context/patents-collection/patents-record/provider";

const Form = () => {
  const { t } = useTranslation();
  const types = [1, 2, 3, 4, 5, 6];
  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={12}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            rules={{ required: true }}
            width="large"
            input={{
              type: "select-type",
              options: types.map((type, index) => {
                return {
                  label: t(getIRecordType(type)) as string,
                  value: type as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name="officialDate"
            rules={{ required: true }}
            label={`${t("official_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name="depositNumber"
            rules={{ required: false }}
            label={`${t("depositNumber")}`}
            input={{ type: "text" }}
          />
        </Col>
        <Col span={12}>
          <FieldBuilder
            name="description"
            rules={{ required: false }}
            label={`${t("description")}`}
            input={{ type: "text" }}
          />
        </Col>
      </Row>
    </>
  );
};
const RecordPatentsForm = () => {
  return (
    <RecordPatentsContextProvider>
      <Form />
    </RecordPatentsContextProvider>
  );
};
export default RecordPatentsForm;
