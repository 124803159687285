import React, { useContext } from "react";
import TreeRoot from "./tree-root";
import "./style.css";
import { INodeTree } from "../../../models/workflow/response";
import AppContext from "../../../context/app/context";

interface IProps {
  data: INodeTree;
}
const Tree: React.FC<IProps> = (props) => {
  const { direction } = useContext(AppContext);
  return (
    <div className="tree-container">
      <div
        dir="ltr"
        style={{
          cursor: "grab",
          transform: `${direction === "rtl" ? "scaleX(-1)" : ""}`,
        }}
        className="tree"
      >
        <TreeRoot items={props.data} />
      </div>
    </div>
  );
};

export default Tree;
