import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserContext from "../../../context/management-collection/user/context";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import Form from "../../form-components/from";
import FieldBuilder from "../../form-components/field-builder";
import { getBooleanTypes } from "../../../models/client/enum";
import ClientContext from "../../../context/client/context";
import { INationality } from "../../../models/managent-collection/nationality/response";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";

interface IProps {}

const ClientFilters: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { actions, query } = useContext(ClientContext);
  const [nationalities, setNationalities] = useState<INationality[]>([]);
  const [nationalityLoading, setNationalityLoading] = useState(true);
  const { direction } = useContext(AppContext);

  const types = [1, 2];
  // get all Role from api
  useEffect(() => {
    const getNationalities = async () => {
      try {
        const { data } = await EndPoints.nationality.getAllNationality({
          perPage: -1,
        });
        setNationalities(data.data);
        setNationalityLoading(false);
      } catch (error) {
      } finally {
        setNationalityLoading(false);
      }
    };
    getNationalities();
  }, [direction]);
  return (
    <Form
      formId="client-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.nationalityId
              ? {
                  name: "nationalityId",
                  value: data.nationalityId,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type")}`}
            width="large"
            input={{
              type: "select",
              options: types.map((status, index) => {
                return {
                  label: t(getBooleanTypes(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="nationalityId"
            label={`${t("nationality")}`}
            width="large"
            input={{
              type: "select",
              allowSearch: true,
              loading: nationalityLoading,
              options: nationalities?.map((nationality, index) => {
                return {
                  label: nationality.name,
                  value: nationality.id,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default ClientFilters;
