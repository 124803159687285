import { Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./style.module.scss";
interface IProps {
  title?: string;
  subTitle?: string;
  count?: number;
  subCount?: number;
  icon?: React.ReactNode;
  toolTip?: string;
}
const Card: React.FC<IProps> = ({ title, count, icon, subTitle, toolTip }) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      overlayStyle={{ fontSize: "1.1rem" }}
      placement="top"
      title={t(toolTip)}
      color="#CB5325"
    >
      <div className={styles.cursor}>
        <div className={styles.statisticsCard}>
          <h2>
            {t(title)} {subTitle && <>/ {t(subTitle)} </>}
          </h2>
          <p className={styles.statisticNumber}>
            {count} <i>{icon}</i>
          </p>
        </div>
      </div>
    </Tooltip>
  );
};

export default Card;
