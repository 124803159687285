import { createContext } from "react";

import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";

import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  ICommercialPayment,
  ICommercialPaymentDetails,
} from "../../../../models/lawswits-collection/commercial/client-payments/response";
import {
  ICreateCommercialPayment,
  IUpdateCommercialPayment,
} from "../../../../models/lawswits-collection/commercial/client-payments/request";
import { ICommercialPaymentsQuery } from "../../../../models/lawswits-collection/commercial/client-payments/query";

export type ClientPaymentLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ClientPaymentLoading[];

  list?: IBaseListingResponse<ICommercialPayment>;
  query: ICommercialPaymentsQuery;

  details?: ICommercialPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: ICommercialPaymentDetails) => void;

    createClientPayment: (request: ICreateCommercialPayment) => void;
    updateClientPayment: (
      id: number,
      request: IUpdateCommercialPayment
    ) => void;
    deleteCleintPayment: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ICommercialPaymentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClientPayment: DEFAULT_FUNCTION,
    updateClientPayment: DEFAULT_FUNCTION,
    deleteCleintPayment: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const CommercialClientsPaymentContext = createContext(externalState);

export default CommercialClientsPaymentContext;
