import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import IncidentsWaiversContext, { internalState } from "./context";
import reducer from "./reducer";

import moment from "moment";

import { IIncidentsWaiverQuery } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/query";
import {
  ICreateIncidentsWaiver,
  IUpdateIncidentsWaiver,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/request";
import { IIncidentsWaiverDetails } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/response";

export interface IProps {
  children: React.ReactNode;
}
const IncidentsWaiversContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getList = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.waiverIncident.getAllWaiverIncident(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createIncidentsWaiver = async (request: ICreateIncidentsWaiver) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.waiverIncident.createWaiverIncident(request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IIncidentsWaiverQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const deleteIncidentsWaiver = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.waiverIncident.deleteWaiverIncident(id);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };
  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.waiverIncident.getWaiverIncident(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IIncidentsWaiverDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const updateIncidentsWaiver = async (
    id: number,
    request: IUpdateIncidentsWaiver
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.waiverIncident.updateWaiverIncident(id, request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.waiverIncident.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",

          `Waiver-Incident-Requsert-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  const exportPdf = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.waiverIncident.exportPdf(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Waiver-Incident-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const exportWord = async (id: number , type:number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
        const data = await EndPoints.waiverIncident.exportWord(id ,type );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Waiver-Incident-Requsert-${moment().format("yyyy-MM-d")}.docx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
      },
      throwException: true,
    });
  };

  return (
    <IncidentsWaiversContext.Provider
      value={{
        ...state,
        actions: {
          getList,
          getDetails,
          setDetails,
          createIncidentsWaiver,
          updateIncidentsWaiver,
          deleteIncidentsWaiver,

          exportExcel,
          exportPdf,
          exportWord,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IncidentsWaiversContext.Provider>
  );
};

export default IncidentsWaiversContextProvider;
