export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_MAXIMUM_PAGE_SIZE = 999999;
export const INITIAL_PAGE = 1;
export const DEFAULT_FUNCTION = () => null;
export const DEFAULT_PARAMS = {
  page: INITIAL_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
};
export const DATE_FORMATE = "DD-MM-YYYY, LT";
export const ACCESS_TOKEN = "access_token";
export const LANGUAGE_CODE = "i18nextLng";
export const DATE_FORMATE_FOR_MESSAGE = "DD-MM-YYYY, LT";
export const DATE_PICKER_FORMATE = "YYYY/MM/DD";
export const MAP_DEFAULT_ZOOM = 14;
export const MAP_DEFAULT_LOCATION = {
  lat: 33.513872588881014,
  lng: 36.27664584020132,
};

export const languages = [
  { name: "العربية", code: "ar" },
  { name: "الإنكليزية", code: "en" },
];

const getDefaultTranslations = () => {
  const obj: any = {};
  languages.forEach((l) => (obj[l.code] = null));
  return obj;
};

export const DEFAULT_TRANSLATIONS = getDefaultTranslations();

export const COLORS = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
  "#F44336",
  "#FFEBEE",
  "#FFCDD2",
  "#EF9A9A",
  "#E57373",
  "#EF5350",
  "#F44336",
  "#E53935",
  "#D32F2F",
  "#C62828",
  "#B71C1C",
  "#FF8A80",
  "#FF5252",
  "#FF1744",
  "#D50000",
  "#E91E63",
];
