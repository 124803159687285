import { useContext, useRef, useState } from "react";
import { PanZoom } from "react-easy-panzoom";
import AppContext from "../../../context/app/context";
interface Props {
  children?: React.ReactNode;
}
const CustomZoom: React.FC<Props> = ({ children }) => {
  const pageRef = useRef(null);
  const [pageStyle, setPageStyle] = useState({ width: "100%", height: "auto" });
  const { direction } = useContext(AppContext);
  const handleZoom = () => {
    const transform = pageRef.current.getTransform();
    const zoomLevel = transform.scale;

    // calculate the new width and height based on the zoom level
    const pageWidth = pageRef.current.offsetWidth * zoomLevel;
    const pageHeight = pageRef.current.offsetHeight * zoomLevel;

    // update the style of the page
    setPageStyle({
      width: `${pageWidth}px`,
      height: `${pageHeight}px`,
    });
  };

  return (
    <>
      <PanZoom ref={pageRef} onZoom={handleZoom} style={pageStyle}>
        <div
          style={{
            transformOrigin: "center",
            transform:
              direction === "ltr"
                ? "translate(118vh, 0%)"
                : "translate(-118vh, 0%)",
          }}
        >
          {children}
        </div>
      </PanZoom>
    </>
  );
};

export default CustomZoom;
