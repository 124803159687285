import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CategoryContext from "../../../../context/management-collection/category/context";
import CategoryContextProvider from "../../../../context/management-collection/category/provider";
import { ICreateCategory } from "../../../../models/managent-collection/category/request";
import { ICategory } from "../../../../models/managent-collection/category/response";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import FieldBuilder from "../../../form-components/field-builder";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";

const Form = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CategoryContext);
  const { id } = useParams();

  // get detailse depends On id (getting from url)
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  return (
    <MainForm
      onSubmit={async (data) => {
        details
          ? await actions.updateCategory(details?.id, data)
          : await actions.createCategory(data);
      }}
      title={details ? t("update_category") : t("add_category")}
      defaultValues={
        {
          name: getTranslations(details?.translations?.name),
          isBreif: details?.isBreif ?? false,
          number: details?.number,
          description: details?.description,
          parentId: details?.parentCategory?.id ?? null,
        } as ICreateCategory
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={12}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: true }}
                  />
                </Col>
              );
            })}
            <Col span={12}>
              <FieldBuilder
                name="number"
                label={`${t("number")}`}
                input={{ type: "number" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="description"
                label={`${t("description")}`}
                input={{ type: "text-area" }}
              />
            </Col>
          </Row>
        </>
      )}
    </MainForm>
  );
};

const CategoryForm = () => {
  return (
    <CategoryContextProvider>
      <Form />
    </CategoryContextProvider>
  );
};
export default CategoryForm;
