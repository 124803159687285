export enum WaiverRequestType {
  WAIVER = 1,
  CANCELLATION = 2,
}

export enum WaiverStatus {
  trademark = 1,
  drawing = 2,
}

export const getWaiverRequestTypeColor = (status: WaiverRequestType) => {
  switch (status) {
    case WaiverRequestType.CANCELLATION:
      return "blue";
    case WaiverRequestType.WAIVER:
      return "green";
  }
};

// Status Boolean stuts
export const getBooleanWaiverRequestType = (status: WaiverRequestType) => {
  switch (status) {
    case WaiverRequestType.CANCELLATION:
      return "cancellation";
    case WaiverRequestType.WAIVER:
      return "waiver";
  }
};

export const getWaiverStatusColor = (status: WaiverStatus) => {
  switch (status) {
    case WaiverStatus.trademark:
      return "red";
    case WaiverStatus.drawing:
      return "orange";
  }
};

// Status Boolean stuts
export const getWaiverStatusType = (status: WaiverStatus) => {
  switch (status) {
    case WaiverStatus.trademark:
      return "trademark";
    case WaiverStatus.drawing:
      return "drawing";
  }
};
