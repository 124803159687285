import { createContext } from "react";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IGrievanceRequesFiles,
  IGrievanceRequesFilesDetails,
} from "../../../../models/lawswits-collection/grievance/grievance-requset-files/response";
import { IGrievanceRequestFilesQuery } from "../../../../models/lawswits-collection/grievance/grievance-requset-files/query";
import {
  ICreateGrievanceRequestFiles,
  IUpdateGrievanceRequestFiles,
} from "../../../../models/lawswits-collection/grievance/grievance-requset-files/request";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<IGrievanceRequesFiles>;
  query: IGrievanceRequestFilesQuery;

  details?: IGrievanceRequesFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IGrievanceRequesFilesDetails) => void;

    createGrievanceRequsetFile: (request: ICreateGrievanceRequestFiles) => void;
    updateGrievanceRequsetFile: (
      id: number,
      request: IUpdateGrievanceRequestFiles
    ) => void;
    deleteGrievanceRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IGrievanceRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createGrievanceRequsetFile: DEFAULT_FUNCTION,
    updateGrievanceRequsetFile: DEFAULT_FUNCTION,
    deleteGrievanceRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const GrievanceRequestFilesContext = createContext(externalState);

export default GrievanceRequestFilesContext;
