import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import {
  DrawingRequestStatus,
  DrawingRequestStatusColor,
  DrawingRequestStatusString,
} from "../../../../../models/drawing-collection/drawing-request/enum";
import { getIncidentsClientsTypeTypeColor } from "../../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/enum";
import { IMoveTrademrk } from "../../../../../models/trademark-collection/move-trademark-request/request";
import EndPoints from "../../../../../services/end-points";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import Space from "../../../../general/antd/space";
import Tag from "../../../../general/antd/tag";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import MoveBtn from "../../../../general/table-components/actions/move-btn";
import ViewBtn from "../../../../general/table-components/actions/view-btn";
import Table from "../../../../general/table-components/table";
import MoveIncidentsLicensingRequest from "../move-node/form";
import IncidentsLicensingsContext from "../../../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/context";
import { IIncidentsLicensing } from "../../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/response";
import { Button, Tooltip } from "antd";
import { FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";

interface IProps {}

const IncidentsLicensingTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, details, loading, query } = useContext(
    IncidentsLicensingsContext
  );
  const [infoId, setInfoId] = useState<number>(undefined);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ss"
        confirmButtonText={t("save")}
        loading={loading.includes("details") || loading.includes("update")}
        title={t("move_request_to_another_node")}
        hideModal={() => {
          setInfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "-4rem", maxHeight: "90vh", overflowY: "scroll" }}
        defaultValues={
          {
            officialDate: null,
          } as IMoveTrademrk
        }
        width={"90vw"}
        onSubmit={async (data) => {
          await EndPoints.licensingIncident.moveIncidentReseqestNode(
            infoId,
            data
          );
          setInfoId(undefined);
          actions.getList();
        }}
      >
        {infoId && <MoveIncidentsLicensingRequest id={infoId} />}
      </FormHookModal>
    ),
    [details, loading, infoId]
  );

  const columns: ColumnProps<IIncidentsLicensing>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 1,
      },
      align: "center",
    },

    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      width: 80,
      align: "center",
      render: (_, record) => record?.depositNumber ?? "-",
    },
    {
      title: t("incident"),
      dataIndex: "incident",

      key: "incident",
      sorter: {
        multiple: 2,
      },
      align: "center",
      render: (record) =>
        (
          <Tag
            title={t(record)}
            color={getIncidentsClientsTypeTypeColor(record)}
          />
        ) ?? "-",
    },
    {
      title: t("workflow"),
      dataIndex: "workflow",
      key: "workflow",

      align: "center",
      render: (_, record) => record?.workflow?.name ?? "-",
    },
    {
      title: t("licener"),
      dataIndex: "licener",
      key: "licener",
      width: 200,
      align: "center",
      render: (_, record) =>
        record?.assignor.length > 0
          ? record?.assignor?.map((ass, index) => {
              return <Tag key={index} title={ass.name} color="red" />;
            })
          : "-",
    },
    {
      title: t("licensee"),
      dataIndex: "licensee",
      width: 200,
      key: "licensee",
      align: "center",
      render: (_, record) =>
        record?.assignee.length > 0
          ? record?.assignee?.map((ass, index) => {
              return <Tag key={index} title={ass.name} color="green" />;
            })
          : "-",
    },
    {
      title: t("current_node"),
      dataIndex: "currentNode",
      key: "currentNode",

      align: "center",
      render: (_, record) => record?.currentNode?.name?.name,
    },

    {
      title: t("request_status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status: DrawingRequestStatus) => (
        <Tag
          color={DrawingRequestStatusColor(status)}
          title={t(DrawingRequestStatusString(status))}
        />
      ),
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      key: "ss",
      sorter: {
        multiple: 2,
      },
      align: "center",
      render: (officialDate: Date) => moment(officialDate).format("DD/MM/YYYY"),
    },

    {
      title: t("creation_date"),
      dataIndex: "createdAt",
      key: "ش",
      sorter: {
        multiple: 3,
      },
      align: "center",
      render: (createdAt: Date) => moment(createdAt).format("DD/MM/YYYY"),
    },

    {
      title: t("actions"),
      dataIndex: "actions",
      width: 250,
      fixed: "right",
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />

          <MoveBtn
            onClick={async () => {
              await setInfoId(record.id);
              await showFormModal();
            }}
          />
          <Tooltip title={t("export_word")}>
            <Button
              type="dashed"
              shape="circle"
              loading={loading.includes("word")}
              onClick={() => actions.exportWord(record?.id , 1)}
              icon={<FileWordOutlined style={{ color: "#0000ff" }} />}
            />
          </Tooltip>
          <Tooltip title={t("export_word_ex")}>
            <Button
              type="dashed"
              shape="circle"
              loading={loading.includes("word")}
              onClick={() => actions.exportWord(record?.id , 2)}
              icon={<FileWordOutlined style={{ color: "#0000ff" }} />}
            />
          </Tooltip>
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteIncidentsLicensing(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        tableLayout="auto"
        scroll={{ x: 1600 }}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default IncidentsLicensingTable;
