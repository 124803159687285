import { useContext } from "react";
import ResetPasswordForm from "../../components/login/reset-password";
import ImageForm from "../../components/login/login-image";
import AppContext from "../../context/app/context";
import styles from "./style.module.scss";

const ResetPassword = () => {
  const { direction } = useContext(AppContext);
  return (
    <div className={styles.container} dir={direction}>
      <div className={styles.layer} />
      <div className={styles.content}>
        <ResetPasswordForm />
        <ImageForm />
      </div>
    </div >
  );
};

export default ResetPassword;
