import { useReducer } from "react";

import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import NationalityContext, { internalState } from "./context";
import reducer from "./reducer";
import { INationalityDetails } from "../../../models/managent-collection/nationality/response";
import {
  ICreateNationality,
  IUpdateNationality,
} from "../../../models/managent-collection/nationality/request";
import { INationalityQuery } from "../../../models/managent-collection/nationality/query";

export interface IProps {
  children: React.ReactNode;
}
const NationalityContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.nationality.getAllNationality(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.nationality.getNationality(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
        //    navigate('update')
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: INationalityDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createNationality = async (request: ICreateNationality) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.nationality.createNationality(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateNationality = async (id: number, request: IUpdateNationality) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.nationality.updateNationality(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteNationality = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.nationality.deleteNationality(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: INationalityQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <NationalityContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createNationality,
          updateNationality,
          deleteNationality,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </NationalityContext.Provider>
  );
};

export default NationalityContextProvider;
