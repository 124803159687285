import { useReducer } from "react";

import { IDrawingClientPaymentsQuery } from "../../../models/drawing-collection/client-payments/query";
import {
  ICreatePatentsClientPayment,
  IUpdatePatentsClientPayment,
} from "../../../models/patents-collection/client-payments/request";
import { IPatentsClientPaymentDetails } from "../../../models/patents-collection/client-payments/response";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import PatentsClientPaymentContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const PatentsClientPaymentContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.pantetsClientPayment.getAllClientPayments({
            ...state.query,
            filters: [
              {
                name: "patentRequest",
                operation: "eq",
                value: id,
              },
            ],
          });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.pantetsClientPayment.getClientPayment(
          id
        );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IPatentsClientPaymentDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createClientPayment = async (request: ICreatePatentsClientPayment) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.pantetsClientPayment.createClientPayment(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateClientPayment = async (
    id: number,
    request: IUpdatePatentsClientPayment
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.pantetsClientPayment.updateClientPayment(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteCleintPayment = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.pantetsClientPayment.deleteClientPayment(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingClientPaymentsQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <PatentsClientPaymentContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createClientPayment,
          updateClientPayment,
          deleteCleintPayment,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </PatentsClientPaymentContext.Provider>
  );
};

export default PatentsClientPaymentContextProvider;
