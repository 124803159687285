import { ITrademarkRequestFilesQuery } from "../../../models/trademark-collection/trademark-requset-files/query";
import {
  ITrademarkRequesFiles,
  ITrademarkRequesFilesDetails,
} from "../../../models/trademark-collection/trademark-requset-files/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { IInternalState, TrademarkRequestFilesLoading } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: {
        loading: TrademarkRequestFilesLoading | TrademarkRequestFilesLoading[];
      };
    }
  | {
      type: "SET_LIST";
      payload: { list: IBaseListingResponse<ITrademarkRequesFiles> };
    }
  | { type: "SET_DETAILS"; payload: { details?: ITrademarkRequesFilesDetails } }
  | QueryAction<ITrademarkRequestFilesQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
