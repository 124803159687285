import { Navigate } from 'react-router-dom'

import { ReactNode, useContext } from 'react'
import AuthContext from '../context/auth/context'
import { ACCESS_TOKEN } from '../utils/costants'
import { httpclient } from '../services/http-client'

const RequireAuth = ({ children }: { children: ReactNode }) => {

  const {isAuthenticated} = useContext(AuthContext)
  return isAuthenticated  ? <>{children}</> : <Navigate to={'/login'} replace />
}
export default RequireAuth
