import {
  DeleteOutlined,
  DownOutlined,
  EyeOutlined,
  PlusOutlined,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Divider, Dropdown, Popconfirm, Space } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEllipsisV } from "react-icons/fa";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import img from "../../../../assets/images/a.jpg";
import AppContext from "../../../../context/app/context";
import NodeContext from "../../../../context/node/context";
import TraderMarkOrderTeamplateContext from "../../../../context/workflow/context";
import { IUpdateNode } from "../../../../models/node/request";
import { parentNode } from "../../../../models/workflow/response";
import EndPoints from "../../../../services/end-points";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import AddExistingNode from "../../../workflow/form-add-existing-node";

import FormHookModal from "../../modals/form-hook-modal";
import styles from "./style.module.scss";
import CreateNodeForm from "../../../workflow/nodes/form-add-node";

interface IProps {
  id: number;
  name: string;
  icon?: string;
  color?: string;
  visited?: boolean;
  parentNode: parentNode;
}
const TreeItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { actions, details, loading, hoverNodeId } = useContext(NodeContext);
  const { actions: treeContext } = useContext(TraderMarkOrderTeamplateContext);
  const { id } = useParams();
  const workFlowId = Number(id);
  const [nodeId, setNodeId] = useState(undefined);
  const { direction } = useContext(AppContext);

  useEffect(() => {
    nodeId && actions.getDetails(nodeId);
  }, [nodeId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="as"
        confirmButtonText={t("save")}
        loading={loading.includes("create") || loading.includes("update")}
        title={details ? t("details_node") : t("add_new_node")}
        hideModal={() => {
          actions.setDetails(undefined);
          setNodeId(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "-5rem", height: "10vh" }}
        defaultValues={
          {
            parentNodeId: details ? undefined : props.id,
            workFlowId: workFlowId,
            // name: getTranslations(details?.translations?.name),
            NameId: details?.name?.id,
            authorizedRoles: details?.authorizedRoles.map((a) => a.id),
            notificationRoles: details?.notificationRoles?.map((a) => a.id),
            iconId: details?.icon?.id,
            canChangeReservationStatus:
              details?.canChangeReservationStatus ?? false,
            canFilter: details?.canFilter ?? false,
            changeDepositDate: details?.changeDepositDate ?? false,
            changeFirstPublishDateAndNumber:
              details?.changeFirstPublishDateAndNumber ?? false,
            changeLastRenewalDate: details?.changeLastRenewalDate ?? false,
            changeRegistrationDate: details?.changeRegistrationDate ?? false,
            clientMessage: details?.clientMessage ?? null,
            duration: details?.duration,
            isDurationalNode: details?.isDurationalNode ?? false,
            changeSecondPublishDateAndNumber:
              details?.changeSecondPublishDateAndNumber ?? false,
            changeRenewalsCount: details?.changeRenewalsCount ?? false,
            note: details?.note,
            reservationStatus: details?.reservationStatus ?? undefined,
            sendNotificationToClient: details?.sendNotificationToClient,
            type: details?.type,
            canChangeRequestStatus: details?.canChangeRequestStatus ?? false,
            changeNextRenewalDate: details?.changeNextRenewalDate ?? false,
            typeRenewalsCount: details?.typeRenewalsCount,
            RequestStatus: details?.RequestStatus,
            color: details?.color,
          } as IUpdateNode
        }
        width={"75vw"}
        onSubmit={async (data) => {
          details
            ? await actions.updateNode(props.id, data)
            : await actions.createNode(data);
          actions.setDetails(undefined);
          setNodeId(undefined);
          treeContext.getTree(workFlowId);
        }}
      >
        <CreateNodeForm icon={props.icon} orderTemplateID={workFlowId} />
      </FormHookModal>
    ),
    [details, loading]
  );

  const [showFormAddExistingNodeModal, hideFormAddExistingNodeModal] = useModal(
    () => (
      <FormHookModal
        id="add-existing_node"
        confirmButtonText={t("save")}
        loading={false}
        title={t("add_existing_node")}
        hideModal={() => {
          setNodeId(undefined);
          actions.setDetails(undefined);
          hideFormAddExistingNodeModal();
        }}
        style={{ marginTop: "3rem" }}
        width={"50vw"}
        onSubmit={async (data) => {
          EndPoints.node.addChild(props.id, data);
          treeContext.getTree(workFlowId);
        }}
      >
        <AddExistingNode workFlowId={workFlowId} nodeId={props.id} />
      </FormHookModal>
    ),
    []
  );

  const items: MenuProps["items"] = [
    !props.visited && {
      key: "1",
      onClick: () => {
        showFormModal();
      },
      label: (
        <Button style={{ all: "unset", width: "100%" }}>
          <span style={{ color: props.color }}>{t("add_new_node")}</span>
        </Button>
      ),
      icon: <PlusOutlined style={{ color: props.color }} />,
    },

    !props.visited && {
      key: "2",
      onClick: () => {
        setNodeId(props.id);
        showFormAddExistingNodeModal();
      },

      label: (
        <Button style={{ all: "unset" }}>
          <span style={{ color: props.color }}> {t("add_existing_node")}</span>
        </Button>
      ),
      icon: <PlusOutlined style={{ color: props.color }} />,
    },
    {
      key: "3",
      onClick: () => {
        setNodeId(props.id);
        showFormModal();
      },
      label: (
        <Button style={{ all: "unset", width: "100%" }}>
          <span style={{ color: props.color }}>{t("show_proprties_node")}</span>
        </Button>
      ),
      icon: <EyeOutlined style={{ color: props.color }} />,
    },
    props.parentNode && {
      key: "4",
      label: (
        <Popconfirm
          style={{ width: "100%" }}
          title={t("confirmDelete")}
          okButtonProps={{ danger: true, loading: loading.includes("delete") }}
          icon={<QuestionCircleTwoTone twoToneColor={props.color} />}
          onConfirm={async () => {
            await actions.deleteNode(props.id, {
              parentId: props.parentNode?.id,
            });
            await treeContext.getTree(workFlowId);
            eventManager.emit(EVENT_SUCCESS);
          }}
        >
          <Button
            style={{ all: "unset", color: props.color, width: "100%" }}
            icon={<DeleteOutlined style={{ color: props.color }} />}
            danger
          >
            {t("delete_node")}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <a
      onMouseOver={() => {
        actions.setHoverNodeId(props.id);
      }}
      onMouseLeave={() => {
        actions.setHoverNodeId(undefined);
      }}
      href="#"
      className={styles.container}
      style={{
        cursor: "default",
        borderColor: props.color,
        borderRadius: props.visited ? "50%" : "",
        aspectRatio: props.visited ? 1 : 1.2,
        width: props.visited ? 70 : "",
        height: props.visited ? 70 : "",
        backgroundColor: props.id === hoverNodeId ? "#6b97e85c" : "",
        transform: `${direction === "rtl" ? "scaleX(-1)" : ""}`,
      }}
    >
      <div className={styles.card}>
        {!props.visited && (
          <>
            <Dropdown
              overlayClassName={styles.drowpDownList}
              menu={{ items }}
              placement="bottomLeft"
            >
              <a onClick={(e) => e.preventDefault()} className={styles.removeAllStyle}>
                <FaEllipsisV
                  style={{ color: props.color, cursor: "pointer" }}
                />
              </a>
            </Dropdown>
            <div>
              <strong style={{ color: props.color }}>{props.id}</strong>
            </div>
          </>
        )}
        {props.visited && (
          <>
            <Dropdown
              overlayClassName={styles.drowpDownList}
              menu={{ items }}
              placement="bottomRight"
            >
              <div className={styles.visitedNumber}>
                <strong style={{ color: props.color }}>{props.id}</strong>
              </div>
            </Dropdown>
          </>
        )}
      </div>
      {!props.visited && (
        <Divider
          className={styles.divider}
          style={{ borderColor: props.color }}
        />
      )}
      {!props.visited && (
        <div className={styles.center}>
          <img
            src={props.icon ?? img}
            alt="node-icon"
            className={styles.icon}
          />
        </div>
      )}
      {!props.visited && (
        <span className={styles.span}>
          <p
            style={{
              color: props.color,
            }}
          >
            {props.name}
          </p>
        </span>
      )}
    </a>
  );
};

export default TreeItem;
