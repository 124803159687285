import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import TrademarkFilter from "../../../components/trademark-collection/trademark/trademark-filter";
import TrademarkTabel from "../../../components/trademark-collection/trademark/trademark-table";
import AppContext from "../../../context/app/context";
import TrademarkReservationContextProvider from "../../../context/trademark-collection/trademark-reservation/provider";
import TrademarkContext from "../../../context/trademark-collection/trademark/context";
import TrademarkContextProvider from "../../../context/trademark-collection/trademark/provider";
import {
  IExportTrademarkExcel,
  trademarkColumns,
} from "../../../models/trademark-collection/trademark/request";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../../utils/hooks/is-mount";

interface IProps {
  children?: React.ReactNode;
}

const Trademark: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const { direction } = useContext(AppContext);

  const { actions, loading, query } = useContext(TrademarkContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);
  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("trademarks")}
        subTitle={t("tradrmarks_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/trademarks/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_trademark")}
            </Button>
          </Can>,
        ]}
      />
      <ExportToExcelModal
        id="export-trademarks"
        title={t("export_trademarks_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="40vw"
        defaultValues={
          {
            country: false,
            nameAr: false,
            nameEn: false,
            type: false,
            id:false,
          } as IExportTrademarkExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          // await a(columnsNames);
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={trademarkColumns} />
      </ExportToExcelModal>
      <SearchFilterCard
        formId="trademark-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <TrademarkFilter />
      </SearchFilterCard>

      <TrademarkTabel />
    </div>
  );
};

const TrademarkPage = () => {
  return (
    <TrademarkContextProvider>
      <TrademarkReservationContextProvider>
        <Trademark />
      </TrademarkReservationContextProvider>
    </TrademarkContextProvider>
  );
};
export default TrademarkPage;
