import { INationalityQuery } from "../../../models/managent-collection/nationality/query";
import {
  ICreateNationality,
  IUpdateNationality,
} from "../../../models/managent-collection/nationality/request";
import { INationality } from "../../../models/managent-collection/nationality/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class NationalityService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllNationality = (query: INationalityQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INationality>>({
      path: `/nationalities`,
      method: "GET",
      query,
      ...params,
    });

  getNationality = (id: number, params?: RequestParams) =>
    this.http.request<INationality>({
      path: `/nationalities/${id}`,
      method: "GET",
      ...params,
    });

  createNationality = (data: ICreateNationality, params?: RequestParams) =>
    this.http.request<INationality>({
      path: "/nationalities",
      method: "POST",
      body: data,
      ...params,
    });

  updateNationality = (
    id: number,
    data: IUpdateNationality,
    params?: RequestParams
  ) =>
    this.http.request<INationality>({
      path: `/nationalities/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteNationality = (id: number, params?: RequestParams) =>
    this.http.request<INationality>({
      path: `/nationalities/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default NationalityService;
