import {
  Descriptions as AntdDescriptions,
  Col,
  Collapse,
  Divider,
  Image,
  Input,
  Row,
  Select,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  getRequestStatusColor,
  getReservationStatus,
} from "../../../../../models/node/enum";
import { getTrademarkRequestStatusString } from "../../../../../models/trademark-collection/trademark-request/enum";
import { INodeCanMove } from "../../../../../models/trademark-collection/trademark-request/response";
import DefoultImage from "../../../../../assets/images/user.jpg";
import CopyrightRequestContext from "../../../../../context/copyright-collection/copyright-request/context";
import CopyrightRequestContextProvider from "../../../../../context/copyright-collection/copyright-request/provider";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
  getForColor,
  getForStatus,
} from "../../../../../models/workflow/enms-workflow/all-enums";
import EndPoints from "../../../../../services/end-points";
import FieldBuilder from "../../../../form-components/field-builder";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";
import FormItem from "../../../../general/form-item";
import ObjectionRequestContextProvider from "../../../../../context/independent_requests-collection/objection-request/provider";
import ObjectionRequestContext from "../../../../../context/independent_requests-collection/objection-request/context";
interface IProps {
  id: number;
}
const { Panel } = Collapse;
const Option = Select.Option;
const A: React.FC<IProps> = (props) => {
  const { actions, loading, details } = useContext(ObjectionRequestContext);
  const [nodes, setNodes] = useState<INodeCanMove[]>([]);
  const [loadingNodes, setLoadingNodes] = useState(true);

  const { t } = useTranslation();
  useEffect(() => {
    props.id && actions.getDetails(props.id);
  }, [props.id]);

  useEffect(() => {
    const getNodesCanMoveRequest = async () => {
      try {
        const data =
          await EndPoints.objectionRequest.getNodesUserCanMoveRequest(props.id);
        setNodes(data?.data?.data);
      } catch (error) {
      } finally {
        setLoadingNodes(false);
      }
    };
    getNodesCanMoveRequest();
  }, []);

  const { control } = useFormContext();

  const NextNodeId = useWatch({
    control,
    name: "NextNodeId",
  });

  useEffect(() => {
    const nodesId = nodes?.map((a) => a.id);
    const aa = nodesId?.indexOf(NextNodeId);
    const cc = nodes[aa]?.RequestStatus;
  }, [NextNodeId]);

  return (
    <>
      {loading.includes("details") ? (
        <div style={{ height: "60vh" }}>
          <Spin />
        </div>
      ) : (
        <Row>
          <Col span={24}>
            <Collapse defaultActiveKey={["2"]}>
              <Panel header={t("curreont_node_details")} key="1">
                <Image
                  width={100}
                  height={100}
                  style={{ borderRadius: "50%", padding: "4px" }}
                  src={details?.currentNode?.icon?.url ?? DefoultImage}
                />
                <Descriptions
                  size="middle"
                  column={1}
                  bordered
                  style={{ marginTop: "5px" }}
                >
                  <AntdDescriptions.Item label={t("id")}>
                    {details?.id}
                  </AntdDescriptions.Item>
                  <AntdDescriptions.Item label={t("type_requset")}>
                    <Tag
                      title={getAllBooleanOrderTemplate(Number(details?.workflow?.type))}
                      color={getAllOrderTemplateColor(Number(details?.workflow?.type))}
                    />
                  </AntdDescriptions.Item>
                  <AntdDescriptions.Item label={t("current_node")}>
                    {details?.currentNode?.name?.name}
                  </AntdDescriptions.Item>
                  <AntdDescriptions.Item label={t("new_registration_status")}>
                    {details?.currentNode?.reservationStatus ? (
                      <Tag
                        title={getReservationStatus(
                          details?.currentNode?.reservationStatus
                        )}
                        color={getRequestStatusColor(
                          details?.currentNode?.reservationStatus
                        )}
                      />
                    ) : (
                      <Tag title="not_found" color="red" />
                    )}
                  </AntdDescriptions.Item>
                </Descriptions>
              </Panel>
              <Panel header={t("new_data")} key="2">
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <FormItem label={t("next_node")}>
                      <Controller
                        name="NextNodeId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field: { ...field } }) => {
                          return (
                            <Select
                              {...field}
                              loading={loadingNodes}
                              style={{ width: "100%" }}
                              allowClear
                              showSearch
                              placeholder={t("next_node")}
                            >
                              {nodes?.map((tr, index) => {
                                return (
                                  <Option key={index} value={tr.id}>
                                    {tr?.name?.name}
                                  </Option>
                                );
                              })}
                            </Select>
                          );
                        }}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}></Col>
                  <Col span={12}>
                    <FieldBuilder
                      name="clientMessage"
                      label={`${t("client_message")}`}
                      input={{ type: "text-area" }}
                      rules={{ required: false }}
                    />
                  </Col>
                  <Col span={12}>
                    <FieldBuilder
                      name="note"
                      label={`${t("note")}`}
                      input={{ type: "text-area" }}
                      rules={{ required: false }}
                    />
                  </Col>
                  <Col span={24}>
                    <Divider style={{ margin: "1px 0 1px 0" }} />
                  </Col>
                </Row>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      )}
    </>
  );
};

const MoveObjectionRequest: React.FC<IProps> = (props) => {
  return (
    <ObjectionRequestContextProvider>
      <A {...props} />
    </ObjectionRequestContextProvider>
  );
};
export default MoveObjectionRequest;
