import { useReducer } from "react";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import PaymentRequestContext, { internalState } from "./context";
import reducer from "./reducer";
import { IRequestPaymentDetails } from "../../../models/independent-requests-collection/request-payment/response";
import { ICreateRequestPayment, IUpdateRequestPayment } from "../../../models/independent-requests-collection/request-payment/request";
import { IRequestPaymentQuery } from "../../../models/independent-requests-collection/request-payment/query";

export interface IProps {
  children: React.ReactNode;
}
const PaymentRequestContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number, type?: string) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.paymentRequest.getAllPayments({
          ...state.query,
          filters: [
            {
              name: "requestId",
              operation: "eq",
              value: id,
            },
            {
              name: "requestType",
              operation: "eq",
              value: type,
            },
          ],
        });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.paymentRequest.getPayment(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IRequestPaymentDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createPaymentRequest = async (request: ICreateRequestPayment) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.paymentRequest.createPayment(request);

        getData(request?.requestId , request?.requestType);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updatePaymentRequest = async (id: number, request: IUpdateRequestPayment) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.paymentRequest.updatePayment(id, request);

        getData(request?.requestId , request?.requestType);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deletePaymentRequest = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.paymentRequest.deletePayment(id);

        //getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IRequestPaymentQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <PaymentRequestContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          createPaymentRequest,
          updatePaymentRequest,
          deletePaymentRequest,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </PaymentRequestContext.Provider>
  );
};

export default PaymentRequestContextProvider;
