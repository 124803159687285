import { Descriptions as AntdDescriptions, Button, Col, Row, Tag } from "antd";
import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useModal } from "react-modal-hook";
import { useParams } from "react-router-dom";
import AppContext from "../../../../../context/app/context";
import CategoryContext from "../../../../../context/management-collection/category/context";
import CategoryContextProvider from "../../../../../context/management-collection/category/provider";
import { ISort, IStaticFilter } from "../../../../../models/base/base-query";
import { ICreateCategory } from "../../../../../models/managent-collection/category/request";
import { ICategory } from "../../../../../models/managent-collection/category/response";
import EndPoints from "../../../../../services/end-points";
import eventManager, { EVENT_SUCCESS } from "../../../../../utils/events";
import { tableOnChange } from "../../../../../utils/helpers/table-sorts-filters";
import { getTranslations } from "../../../../../utils/helpers/translations";
import DetailsPage from "../../../../form-components/view-details";
import Descriptions from "../../../../general/antd/descriptions";
import Space from "../../../../general/antd/space";
import Spin from "../../../../general/antd/spin";
import SearchFilterCard from "../../../../general/filter-card";
import FormHookModal from "../../../../general/modals/form-hook-modal";
import DeleteBtn from "../../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../../general/table-components/actions/edit-btn";
import Table from "../../../../general/table-components/table";
import CategoryForm from "../form";
import styles from "./style.module.scss";
const Form = () => {
  const { t } = useTranslation();
  const { details, subCategories, actions, loading, query } =
    useContext(CategoryContext);
  const { direction } = useContext(AppContext);
  const { id } = useParams();
  const [infoId, setInfoioId] = useState<number>();
  const [detailsSubCategory, setDetailsSubCategory] = useState<any>();
  const [loadingDetailsSubCategory, setloadingDetailsSubCategory] =
    useState(false);
  const [search, setSearch] = useState<string | undefined>(undefined);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  useEffect(() => {
    const getSubCategories = async () => {
      id && (await actions.getSubCategories(Number(id)));
    };
    getSubCategories();
  }, [direction, query]);

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  useEffect(() => {
    const getDetailsSub = async (id: number) => {
      try {
        setloadingDetailsSubCategory(true);
        const data = await EndPoints.category.getCategory(id);
        setDetailsSubCategory(data.data);
      } catch (error) {
      } finally {
        setloadingDetailsSubCategory(false);
      }
    };
    infoId && getDetailsSub(infoId);
  }, [infoId]);

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  const columns: ColumnProps<ICategory>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: t("is_breif"),
      dataIndex: "isBreif",
      width: 200,
      align: "center",
      key: "isBreif",
      render: (_, record) => {
        return (
          <>
            {record.isBreif ? (
              <ImCheckmark color="green" />
            ) : (
              <ImCross color="red" />
            )}
          </>
        );
      },
    },

    {
      title: t("actions"),
      dataIndex: "",
      width: 200,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <EditBtn
            onClick={async () => {
              setInfoioId(record.id);
              showFormModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCategory(record?.id);
              actions.getSubCategories(Number(id));
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ass"
        confirmButtonText={t("save")}
        loading={loading.includes("create") || loadingDetailsSubCategory}
        title={
          !detailsSubCategory ? t("add_sub_category") : t("update_sub_category")
        }
        hideModal={() => {
          setInfoioId(undefined);
          setDetailsSubCategory(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "3rem" }}
        defaultValues={
          {
            parentId: Number(id),
            number: null,
            name: getTranslations(detailsSubCategory?.translations?.name) ?? "",
            isBreif: detailsSubCategory?.isBreif ?? false,
          } as ICreateCategory
        }
        width={"40vw"}
        onSubmit={async (data) => {
          detailsSubCategory
            ? await actions.updateCategory(detailsSubCategory.id, data)
            : await actions.createCategory(data);
          setInfoioId(undefined);
          setDetailsSubCategory(undefined);
          actions.getSubCategories(Number(id));
        }}
      >
        <CategoryForm />
      </FormHookModal>
    ),
    [detailsSubCategory, loadingDetailsSubCategory, loading]
  );

  return (
    <DetailsPage title={t("category_details")}>
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Descriptions size="middle" column={1} bordered>
                <AntdDescriptions.Item label={t("id")}>
                  {details?.id}
                </AntdDescriptions.Item>

                <AntdDescriptions.Item label={t("name")}>
                  {details?.name}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("number")}>
                  {details?.number ?? "-"}
                </AntdDescriptions.Item>
                <AntdDescriptions.Item label={t("note")}>
                  {details?.description ? (
                    details?.description
                  ) : (
                    <Tag title="not_found" color="red" />
                  )}
                </AntdDescriptions.Item>
              </Descriptions>
            </Col>

            <Col span={24}>
              <Button
                type="primary"
                onClick={() => {
                  showFormModal();
                }}
              >
                {t("add_sub_category")}
              </Button>
            </Col>
            <Col span={24}>
              <SearchFilterCard
                formId="category-filter"
                paddingStyle={true}
                searchValue={search}
                setSearchValue={setSearch}
                onReset={() => {}}
                resetLoading={loading.includes("list")}
                applyLoading={loading.includes("list")}
              />
            </Col>
            <Col span={24}>
              <>
                <Table<any>
                  rowKey="id"
                  showPagination={true}
                  columns={columns}
                  dataSource={subCategories?.data ?? []}
                  loading={loading.includes("list")}
                  size="small"
                  total={subCategories?.total}
                  pageSize={query.perPage}
                  page={query.page}
                  onPaginationChange={(page, pageSize) => {
                    actions.setQuery({
                      ...query,
                      page,
                      perPage: pageSize,
                    });
                  }}
                  onChange={(_, tableFilters, tabelSorters) => {
                    tableOnChange(
                      tableFilters,
                      tabelSorters,
                      tableFiltersProps,
                      setSorts,
                      setStaticFilters
                    );
                  }}
                />
              </>
            </Col>
          </Row>
        </>
      )}
    </DetailsPage>
  );
};

const SubCategoryForm = () => {
  return (
    <CategoryContextProvider>
      <Form />
    </CategoryContextProvider>
  );
};
export default SubCategoryForm;
