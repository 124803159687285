import { Col, Row, Select } from "antd";
import FormItem from "antd/es/form/FormItem";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import EndPoints from "../../../services/end-points";
import { ICountry } from "../../../models/managent-collection/user/response";
import { languages } from "../../../utils/helpers/constants";
import FieldBuilder from "../../form-components/field-builder";

import TraderMarkOrderTeamplateContext from "../../../context/workflow/context";
import { getForStatus } from "../../../models/workflow/enms-workflow/all-enums";
import { getBooleanTrademarkOrderTemplate } from "../../../models/workflow/enms-workflow/trademark";
import { getBooleanDrawinOrderTemplate } from "../../../models/workflow/enms-workflow/drawing";
import { getBooleanPatentsOrderTemplate } from "../../../models/workflow/enms-workflow/patents";
import { getBooleanIncidentClientTrademarkOrderTemplate } from "../../../models/workflow/enms-workflow/incidents";
import { getBooleanCommercialTrademarkOrderTemplate } from "../../../models/workflow/enms-workflow/commercial";
import { getBooleanGrievanceTrademarkOrderTemplate } from "../../../models/workflow/enms-workflow/grievance";
import { useIsMount } from "../../../utils/hooks/is-mount";
import { getBooleanObjectionOrderTemplate } from "../../../models/workflow/enms-workflow/objection";
interface IProps {
  infoId: number;
}
const Option = Select.Option;
const CopyTrademarkTeamplate: React.FC<IProps> = ({ infoId }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [contryLoading, setCountryLoading] = useState(true);
  const { actions } = useContext(TraderMarkOrderTeamplateContext);
  const fors = [1, 2, 3, 4, 5, 6, 7, 8];
  const { control, reset } = useFormContext();
  const typeFor = useWatch({
    control,
    name: "for",
  });

  const typesTrademaek = [1, 2, 3];
  const typesDrawing = [1, 2, 4];
  const typesPatents = [1, 4];
  const typesCopyright = [1];
  const typesIncidents = [5];
  const typeGrievance = [6];
  const typeCommercial = [7, 8, 9];
  const typeObjection = [10]
  // get all country from apis
  useEffect(() => {
    const getContrieslight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountryLoading(false);
      } catch (error) {
      } finally {
        setCountryLoading(false);
      }
    };
    getContrieslight();
  }, []);

  useEffect(() => {
    actions.getDetails(infoId);
  }, []);

  return (
    <>
      <Row gutter={[8, 8]}>
        {languages?.map((lang, index) => {
          return (
            <Col span={12}>
              <FieldBuilder
                key={index}
                name={`name[${lang?.code}]`}
                label={`${t("name")} (${lang?.name})`}
                input={{ type: "text" }}
                rules={{ required: true }}
              />
            </Col>
          );
        })}
        <Col span={8}>
          <FieldBuilder
            name="for"
            label={`${t("for")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              options: fors.map((type, index) => {
                return {
                  label: t(getForStatus(type) as string),
                  value: type as number,
                };
              }),
            }}
          />
        </Col>

        {typeFor === 1 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typesTrademaek.map((type, index) => {
                  return {
                    label: t(getBooleanTrademarkOrderTemplate(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}
        {typeFor === 2 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typesDrawing.map((type, index) => {
                  return {
                    label: t(getBooleanDrawinOrderTemplate(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}
        {typeFor === 3 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typesPatents.map((type, index) => {
                  return {
                    label: t(getBooleanPatentsOrderTemplate(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}
        {typeFor === 4 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typesCopyright.map((type, index) => {
                  return {
                    label: t(getBooleanPatentsOrderTemplate(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}
        {typeFor === 5 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typesIncidents.map((type, index) => {
                  return {
                    label: t(
                      getBooleanIncidentClientTrademarkOrderTemplate(
                        type
                      ) as string
                    ),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}
        {typeFor === 6 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typeGrievance.map((type, index) => {
                  return {
                    label: t(
                      getBooleanGrievanceTrademarkOrderTemplate(type) as string
                    ),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}
        {typeFor === 7 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typeCommercial.map((type, index) => {
                  return {
                    label: t(
                      getBooleanCommercialTrademarkOrderTemplate(type) as string
                    ),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}

        {typeFor === 8 && (
          <Col span={8}>
            <FieldBuilder
              name="type"
              label={`${t("type_requset")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                options: typeObjection.map((type, index) => {
                  return {
                    label: t(
                      getBooleanObjectionOrderTemplate(type) as string
                    ),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
        )}

        <Col span={8}>
          <div>
            <FieldBuilder
              name="countryId"
              label={`${t("country_order_template")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",
                allowSearch: true,
                loading: contryLoading,
                options: countries.map((contry, index) => {
                  return {
                    label: contry.name as string,
                    value: contry.id as number,
                  };
                }),
              }}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CopyTrademarkTeamplate;
