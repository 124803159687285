import React from 'react'
import styles from './style.module.scss'
import logo from './../../../assets/images/fullLogo.png'

const ImageForm = () => {
  return (
    <div className={styles.content}>
        <img className={styles.logo}  src={logo} alt="" />
        <div className={styles.layer}/>
    </div>
  )
}

export default ImageForm