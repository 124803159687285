import { Descriptions as AntdDescriptions } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../../general/antd/descriptions";

import moment from "moment";
import PatentsClientPaymentContext from "../../../../../context/patents-collection/client-payments/context";
import Spin from "../../../../general/antd/spin";

interface IProps {}
const ClientPaymentDetails: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { details, loading } = useContext(PatentsClientPaymentContext);

  return (
    <>
      {loading.includes("details") ? (
        <Spin />
      ) : (
        <Descriptions size="small" column={1} bordered>
          <AntdDescriptions.Item label={t("id")}>
            {details?.id}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("value")}>
            {details?.value}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("receipt_number")}>
            {details?.receiptNumber}
          </AntdDescriptions.Item>

          <AntdDescriptions.Item label={t("date_payment")}>
            {moment(details?.date).format("DD/MM/YYYY")}
          </AntdDescriptions.Item>

          <AntdDescriptions.Item label={t("note")}>
            {details?.note ?? "-"}
          </AntdDescriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default ClientPaymentDetails;
