import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getBooleanDrawingReservation } from "../../../../models/drawing-collection/drawing-reservation/enum";
import { ICountry } from "../../../../models/managent-collection/user/response";
import EndPoints from "../../../../services/end-points";
import FieldBuilder from "../../../form-components/field-builder";

interface IProps {
  categoryId?: number;
  subCategories?: any[];
  setCategory?: (number) => void;
  SubCategory?: ([]) => void;
}

const DrawingReservationForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const status = [1, 2];

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, []);

  return (
    <>
      <Row gutter={[16, 8]}>
        <Col span={8}>
          <FieldBuilder
            name="status"
            label={`${t("types")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              options: status.map((statu, index) => {
                return {
                  label: t(getBooleanDrawingReservation(statu) as string),
                  value: statu as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="countryId"
            label={`${t("country")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              loading: countriesLoading,
              allowSearch: true,
              options: countries.map((country, index) => {
                return {
                  label: country.name as string,
                  value: country.id as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="depositDate"
            label={`${t("deposit_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="depositNumber"
            label={`${t("deposit_number")}`}
            input={{ type: "text" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="registrationDate"
            label={`${t("registration_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("renewal_counts")}`}
            name="renewalCounts"
            input={{ type: "number" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="registrationNumber"
            label={`${t("registration_number")}`}
            input={{ type: "text" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="lastRenewalDate"
            label={`${t("last_renewal_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            label={`${t("last_renewal_number")}`}
            name="lastRenewalNumber"
            input={{ type: "text" }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="nextRenewalDate"
            label={`${t("next_renewal_date")}`}
            input={{ type: "date-picker", format: "YYYY-MM-DD" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default DrawingReservationForm;
