import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { ITrademarkQuery } from "../../../models/trademark-collection/trademark/query";
import axios from "axios";
import { IRecordTrademark, IRecordTrademarkDetails } from "../../../models/trademark-collection/trademark-reservation/response";
import { ICreateRecordTrademark, IUpdateRecordTrademark } from "../../../models/trademark-collection/trademark-reservation/request";

class RecordTrademarkService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRecord = (query: ITrademarkQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IRecordTrademark>>({
      path: `/trademark-reservation-record`,
      method: "GET",
      query,
      ...params,
    });

  getRecord = (id: number, params?: RequestParams) =>
    this.http.request<IRecordTrademarkDetails>({
      path: `/trademark-reservation-record/${id}`,
      method: "GET",
      ...params,
    });

  createRecord = (data: ICreateRecordTrademark, params?: RequestParams) =>
    this.http.request<IRecordTrademarkDetails>({
      path: "/trademark-reservation-record",
      method: "POST",
      body: data,
      ...params,
    });

  updateRecord = (
    id: number,
    data: IUpdateRecordTrademark,
    params?: RequestParams
  ) =>
    this.http.request<IRecordTrademarkDetails>({
      path: `/trademark-reservation-record/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteRecord = (id: number, params?: RequestParams) =>
    this.http.request<IRecordTrademarkDetails>({
      path: `/trademark-reservation-record/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default RecordTrademarkService;
