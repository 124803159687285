import { useReducer } from "react";
import EndPoints from "../../../services/end-points";
import DrawingReservationContext, { internalState } from "./context";
import { execute } from "../../../utils/helpers/execute";
import reducer from "./reducer";
import { IDrawingReservationQuery } from "../../../models/drawing-collection/drawing-reservation/query";
import {
  ICreateDrawingReservation,
  IUpdateDrawingReservation,
} from "../../../models/drawing-collection/drawing-reservation/request";
import { IDrawingReservationDetails } from "../../../models/drawing-collection/drawing-reservation/response";

export interface IProps {
  children: React.ReactNode;
}
const DrawingReservationContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.drawingReservation.getAllDrawingsReservation({
            ...state.query,
            filters: [
              {
                name: "drawingID",
                value: id,
                operation: "eq",
              },
            ],
          });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.drawingReservation.getDrawingReservation(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const getHistory = async (idReservation: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "history" } });

        const { data } = await EndPoints.drawingReservation.getHistory({
          ...state.query,
          filters: [
            {
              name: "drawingReservationID",
              value: idReservation,
              operation: "eq",
            },
          ],
        });

        dispatch({ type: "SET_HISTORY", payload: { history: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "history" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDrawingReservationDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createDrawingReservation = async (
    request: ICreateDrawingReservation
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.drawingReservation.createDrawingReservation(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateDrawingReservation = async (
    id: number,
    request: IUpdateDrawingReservation
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.drawingReservation.updateDrawingReservation(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteDrawingReservation = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.drawingReservation.deleteDrawingReservation(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IDrawingReservationQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <DrawingReservationContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          getHistory,
          setDetails,

          createDrawingReservation,
          updateDrawingReservation,
          deleteDrawingReservation,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DrawingReservationContext.Provider>
  );
};

export default DrawingReservationContextProvider;
