import { useReducer } from "react";
import EndPoints from "../../../services/end-points";
import { internalState } from "./context";
import { execute } from "../../../utils/helpers/execute";
import reducer from "./reducer";

import TrademarkReservationContext from "./context";
import { ITrademarkReservationDetails } from "../../../models/trademark-collection/trademark-reservation/response";
import {
  ICreateTrademarkReservation,
  IUpdateTrademarkReservation,
} from "../../../models/trademark-collection/trademark-reservation/request";
import { ITrademarkReservationQuery } from "../../../models/trademark-collection/trademark-reservation/query";

export interface IProps {
  children: React.ReactNode;
}
const TrademarkReservationContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id: number, country?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        if (country) {
          const { data } =
            await EndPoints.trademarkReservation.getAllTrademarksReservation({
              ...state.query,
              filters: [
                {
                  name: "trademarkID",
                  value: id,
                  operation: "eq",
                },
                {
                  name: "country",
                  value: country,
                  operation: "eq",
                },
              ],
            });
          dispatch({ type: "SET_LIST", payload: { list: data } });
        } else {
          const { data } =
            await EndPoints.trademarkReservation.getAllTrademarksReservation({
              ...state.query,
              filters: [
                {
                  name: "trademarkID",
                  value: id,
                  operation: "eq",
                },
              ],
            });
          dispatch({ type: "SET_LIST", payload: { list: data } });
        }
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.trademarkReservation.getTrademarkReservation(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const getHistory = async (idReservation: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "history" } });

        const { data } = await EndPoints.trademarkReservation.getHistory({
          ...state.query,
          filters: [
            {
              name: "trademarkReservationID",
              value: idReservation,
              operation: "eq",
            },
          ],
        });

        dispatch({ type: "SET_HISTORY", payload: { history: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "history" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: ITrademarkReservationDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createTrademarkReservation = async (
    request: ICreateTrademarkReservation,
    idTrademark: number
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.trademarkReservation.createTrademarkReservation(
          request
        );

        getData(idTrademark, null);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateTrademarkReservation = async (
    id: number,
    idTrademark: number,
    request: IUpdateTrademarkReservation
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.trademarkReservation.updateTrademarkReservation(
          id,
          request
        );

        getData(idTrademark, null);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const specialTrademarkReservation = async (id: number, data: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "special" },
        });
        await EndPoints.trademarkReservation.specialTrademarkReservation(
          id, data
        );

        getData(data?.trademarkId, null);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "special" },
        });
      },
      throwException: true,
    });
  }

  const deleteTrademarkReservation = async (
    id: number,
    idTrademark: number
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.trademarkReservation.deleteTrademarkReservation(id);

        getData(idTrademark, null);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ITrademarkReservationQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <TrademarkReservationContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          getHistory,
          setDetails,

          createTrademarkReservation,
          updateTrademarkReservation,
          deleteTrademarkReservation,
          specialTrademarkReservation,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </TrademarkReservationContext.Provider>
  );
};

export default TrademarkReservationContextProvider;
