import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import { internalState } from "./context";
import reducer from "./reducer";

import moment from "moment";

import IncidentsLicensingsContext from "./context";
import {
  ICreateIncidentsLicensing,
  IUpdateIncidentsLicensing,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/request";
import { IIncidentsLicensingQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/query";
import { IIncidentsLicensingDetails } from "../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/response";

export interface IProps {
  children: React.ReactNode;
}
const IncidentsLicensingContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getList = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.licensingIncident.getAllLicensingIncident(
            state.query
          );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const createIncidentsLicensing = async (
    request: ICreateIncidentsLicensing
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        await EndPoints.licensingIncident.createLicensingIncident(request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IIncidentsLicensingQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const deleteIncidentsLicensing = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.licensingIncident.deleteLicensingIncident(id);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };
  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.licensingIncident.getLicensingIncident(
          id
        );

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IIncidentsLicensingDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const updateIncidentsLicensing = async (
    id: number,
    request: IUpdateIncidentsLicensing
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.licensingIncident.updateLicensingIncident(id, request);

        getList();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.licensingIncident.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",

          `Patents-Requsert-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };
  const exportPdf = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.trademarkRequest.exportPdf(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Trademark-Requsert-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const exportWord = async (id: number , type:number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
        const data = await EndPoints.licensingIncident.exportWord(id ,type );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Licens-Incident-Requsert-${moment().format("yyyy-MM-d")}.docx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "word" },
        });
      },
      throwException: true,
    });
  };

  return (
    <IncidentsLicensingsContext.Provider
      value={{
        ...state,
        actions: {
          getList,
          getDetails,
          setDetails,
          createIncidentsLicensing,
          updateIncidentsLicensing,
          deleteIncidentsLicensing,

          exportExcel,
          exportPdf,
          exportWord,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IncidentsLicensingsContext.Provider>
  );
};

export default IncidentsLicensingContextProvider;
