import { createContext } from "react";
import { IJopTitleQuery } from "../../../models/managent-collection/jop-title/query";
import {
  ICreateJopTitile,
  IUpdateJopTitle,
} from "../../../models/managent-collection/jop-title/request";
import {
  IJopTitle,
  IJopTitleDetails,
} from "../../../models/managent-collection/jop-title/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type JopTitleLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: JopTitleLoading[];

  list?: IBaseListingResponse<IJopTitle>;
  query: IJopTitleQuery;

  details?: IJopTitleDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IJopTitleDetails) => void;

    createJopTitle: (request: ICreateJopTitile) => void;
    updateJopTitle: (id: number, request: IUpdateJopTitle) => void;
    deleteJopTitle: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IJopTitleQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createJopTitle: DEFAULT_FUNCTION,
    updateJopTitle: DEFAULT_FUNCTION,
    deleteJopTitle: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const JopTitleContext = createContext(externalState);

export default JopTitleContext;
