import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../general/antd/descriptions";
import PageDetails from "../../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../../general/antd/spin";
import AppContext from "../../../../context/app/context";
import NationalityContext from "../../../../context/management-collection/nationality/context";
import NationalityContextProvider from "../../../../context/management-collection/nationality/provider";

interface Props {}

const NationalityDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const { details, actions, loading } = useContext(NationalityContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"nationality"}`}
        buttonName={`${"update_nationality"}`}
        onClick={() => navigate(`/nationalities/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="default" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const NationalityDetailsPage = () => {
  return (
    <NationalityContextProvider>
      <NationalityDetails />
    </NationalityContextProvider>
  );
};
export default NationalityDetailsPage;
