import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";
import axios from "axios";
import {
  ICreateCountry,
  IExportCountryExcel,
  IUpdateCountry,
} from "../../../models/managent-collection/country/request";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { ICountry } from "../../../models/managent-collection/country/response";
import { ICountryQuery } from "../../../models/managent-collection/country/query";

class CountryService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCountries = (query: ICountryQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICountry>>({
      path: `/countries`,
      method: "GET",
      query,
      ...params,
    });

  getAllCountriesLight = (query: ICountryQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICountry>>({
      path: `countries?light=1`,
      method: "GET",
      query,
      ...params,
    });

  getCountry = (id: number, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: `/countries/${id}`,
      method: "GET",
      ...params,
    });

  createCountry = (data: ICreateCountry, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: "/countries",
      method: "POST",
      body: data,
      ...params,
    });

  updateCountry = (id: number, data: IUpdateCountry, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: `/countries/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCountry = (id: number, params?: RequestParams) =>
    this.http.request<ICountry>({
      path: `/countries/${id}`,
      method: "DELETE",
      ...params,
    });
  exportExcel = (data: IExportCountryExcel, query: ICountryQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/countries/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default CountryService;
