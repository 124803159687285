import { ITranslationRequest } from "../../base/translation";
export interface ICreateCategory {
  name: ITranslationRequest;
  isBreif: boolean;
  number: number | null;
  parentId: number;
  description: string;
}

export interface IUpdateCategory extends ICreateCategory {}

export interface IExportCategoryExcel {
  name: boolean;
  number: boolean;
  description: boolean;
  is_brief: boolean;
}
export const columnsCategory = ["name", "number", "description", "is_brief"];
