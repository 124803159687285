import { useReducer } from "react";

import moment from "moment";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import ObjectionRequestContext, { internalState } from "./context";
import reducer from "./reducer";
import { IObjectionRequestDetails } from "../../../models/independent-requests-collection/objection-request/response";
import { ICreateObjectionRequest, IUpdateObjectionRequest } from "../../../models/independent-requests-collection/objection-request/request";
import { IObjectionRequestQuery } from "../../../models/independent-requests-collection/objection-request/query";

export interface IProps {
  children: React.ReactNode;
}
const ObjectionRequestContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.objectionRequest.getAllObjectionRequest(
          state.query
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.objectionRequest.getObjectionRequest(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IObjectionRequestDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createObjectionRequest = async (request: ICreateObjectionRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.objectionRequest.createObjectionRequest(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateObjectionRequest = async (id: number, request: IUpdateObjectionRequest) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.objectionRequest.updateObjectionRequest(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteObjectionRequest = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.objectionRequest.deleteObjectionRequest(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IObjectionRequestQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  const exportExcel = async (request: any) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.objectionRequest.exportExcel(
          request,
          state.query
        );
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `ObjectionRequest-${moment().format("yyyy-MM-d")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const exportPdf = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
        const data = await EndPoints.objectionRequest.exportPdf(id);
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `ObjectionRequest-${moment().format("yyyy-MM-d")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  return (
    <ObjectionRequestContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          exportExcel,
          exportPdf,
          createObjectionRequest,
          updateObjectionRequest,
          deleteObjectionRequest,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </ObjectionRequestContext.Provider>
  );
};

export default ObjectionRequestContextProvider;
