import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { IReportQuery } from "../../models/reoprt/query";
import { IReport } from "../../models/reoprt/response";
import { ICreateReport } from "../../models/reoprt/request";

class ReportService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllReports = (query?: IReportQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IReport>>({
      path: `/report`,
      method: "GET",
      ...params,
      query,
    });

  createReport = (data: ICreateReport, params?: RequestParams) =>
    this.http.request<IReport>({
      path: "/report",
      method: "POST",
      body: data,
      ...params,
    });
}

export default ReportService;
