import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import { IPatentsRequest } from "../../../models/patents-collection/patents-reservation/response";
import { IPatentsRequestQuery } from "../../../models/patents-collection/patents-request/query";
import { IPatentsRequestDetails } from "../../../models/patents-collection/patents-request/response";
import {
  ICreatePatentRequest,
  IUpdatePatentRequest,
} from "../../../models/patents-collection/patents-request/request";

export type PatnetsRequestLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: PatnetsRequestLoading[];

  list?: IBaseListingResponse<IPatentsRequest>;
  query: IPatentsRequestQuery;

  details?: IPatentsRequestDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IPatentsRequestDetails) => void;

    createPatentsRequest: (request: ICreatePatentRequest) => void;
    updatePatentsRequest: (id: number, request: IUpdatePatentRequest) => void;
    deleteRequest: (id: number) => void;

    addReservationToPatents: (id: number, request: any) => void;

    exportExcel: (data: any) => any;
    exportPdf: (id: number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: IPatentsRequestQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createPatentsRequest: DEFAULT_FUNCTION,
    updatePatentsRequest: DEFAULT_FUNCTION,
    deleteRequest: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    exportPdf: DEFAULT_FUNCTION,
    addReservationToPatents: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const PatnetsRequestContext = createContext(externalState);

export default PatnetsRequestContext;
