import { Col, Popover, Row, Select } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IClient } from "../../../../models/client/response";
import EndPoints from "../../../../services/end-points";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import GeneralForm from "../../../form-components/general-form";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import "react-quill/dist/quill.snow.css";
import RichEditor from "../../../form-components/rich-text-editor";
import ObjectionRequestContextProvider from "../../../../context/independent_requests-collection/objection-request/provider";
import ObjectionRequestContext from "../../../../context/independent_requests-collection/objection-request/context";
import { IUpdateObjectionRequest } from "../../../../models/independent-requests-collection/objection-request/request";
import { ITraderMarkOrderTeamplate } from "../../../../models/workflow/response";
import { getRequestStatus } from "../../../../models/node/enum";
import { useForm, useWatch } from "react-hook-form";
import { ICountry } from "../../../../models/managent-collection/user/response";
interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(ObjectionRequestContext);
  const { t } = useTranslation();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [objectionTemplates, setObjectionTemplate] = useState<
    ITraderMarkOrderTeamplate[]
  >([]);
  const [objectionTemplatesLoading, setObjectionTemplatesLoading] =
    useState(true);
  const requestStatus = [1, 2, 3];

  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  const methods = useForm<any>();
  const { handleSubmit, reset, control } = methods;

  const countryId = useWatch({
    control,
    name: "country",
  });

  useEffect(() => {
    const getObjectionTemplates = async () => {
      try {
        setObjectionTemplatesLoading(true);

        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          filters: [
            {
              name: "countryId",
              operation: "eq",
              value: countryId,
            },
            {
              name: "for",
              operation: "eq",
              value: 8,
            },
          ],
        });
        setObjectionTemplate(data.data);
      } catch (error) {
      } finally {
        setObjectionTemplatesLoading(false);
      }
    };
    getObjectionTemplates();
  }, [countryId]);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all CLients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  return (
    <GeneralForm
      onSubmit={async (data) => {
        details
          ? await actions.updateObjectionRequest(details?.id, data)
          : await actions.createObjectionRequest(data);
      }}
      title={
        details ? t("update_objection_request") : t("add_objection_request")
      }
      defaultValues={
        {
          objectingParty: details?.objectingParty,
          objectingRequest: details?.objectingRequest,
          subject: details?.subject,
          description: details?.description,
          clients: details?.clients?.map((client) => client.id),
          orderValue: details?.orderValue,
          // clientPayments: details?.clientPayments,
          status: details?.status,
          workflowId: details?.workflow?.id,
          officialDate: details?.officialDate,
          depositNumber: details?.depositNumber,
        } as IUpdateObjectionRequest
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="clients"
                label={`${t("client_objecting")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "multiSelect",
                  loading: clientsLoading,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="objectingParty"
                label={`${t("objecting_party")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="depositNumber"
                label={`${t("deposit_number")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="objectingRequest"
                label={`${t("objecting_request")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="subject"
                label={`${t("subject")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="orderValue"
                label={`${t("order_value")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            {/*  <Col span={8}>
              <FieldBuilder
                name="clientPayments"
                label={`${t("client_payments")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>*/}
            <Col span={8}>
              <FormItem label={t("countries")} required>
                <Controller
                  name="country"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { ...field } }) => {
                    return (
                      <Select
                        {...field}
                        style={{ width: "100%" }}
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        placeholder={t("countries")}
                        options={countries.map((country, index) => {
                          return {
                            label: country.name as string,
                            value: country.id as number,
                          };
                        })}
                      />
                    );
                  }}
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="workflowId"
                label={`${t("orders_templates")}`}
                rules={{ required: true }}
                width="large"
                disabled={countryId ? false : true}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: objectionTemplatesLoading,
                  options: objectionTemplates.map((el, index) => {
                    return {
                      label: el?.name as string,
                      value: el.id as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="status"
                label={`${t("request_status")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  options: requestStatus.map((status, index) => {
                    return {
                      label: t(getRequestStatus(status) as string),
                      value: status as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="officialDate"
                label={`${t("official_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>
            <Col span={24}>
              <div>
                <FormItem label={t("description")}>
                  <Controller
                    name="description"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <RichEditor
                          value={field?.value ?? ""}
                          onChange={field?.onChange}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const ObjectionRequestForm = () => {
  return (
    <ObjectionRequestContextProvider>
      <Form />
    </ObjectionRequestContextProvider>
  );
};

export default ObjectionRequestForm;
