import { IIncidentsRequestCostQuery } from "../../../../models/incidents-managments/incidents-clients/incidents-request-costs/query";
import {
  ICreateGrievanceRequestCosts,
  IUpdateGrievanceRequestCosts,
} from "../../../../models/lawswits-collection/grievance/grievance-request-costs/request";
import {
  IGrievanceRequestCosts,
  IGrievanceRequestCostsDetails,
} from "../../../../models/lawswits-collection/grievance/grievance-request-costs/response";
import { IOfficeCost } from "../../../../models/trademark-collection/office-cost/response";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class GrievanceOfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (
    query: IIncidentsRequestCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IGrievanceRequestCosts>>({
      path: `/grievance-lawsuits-cost`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IGrievanceRequestCostsDetails>({
      path: `/grievance-lawsuits-cost/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (
    data: ICreateGrievanceRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IOfficeCost>({
      path: "/grievance-lawsuits-cost",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdateGrievanceRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IGrievanceRequestCosts>({
      path: `/grievance-lawsuits-cost/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IGrievanceRequestCosts>({
      path: `/grievance-lawsuits-cost/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default GrievanceOfficeCostService;
