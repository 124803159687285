import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import UserContext from '../../../contexts/user/context'
import { Col, Row, Typography } from "antd";
import { useModal } from "react-modal-hook";
import { useNavigate } from "react-router-dom";
import ClientContext from "../../../context/client/context";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { getBooleanTypes, getTypesColor } from "../../../models/client/enum";
import { IClient } from "../../../models/client/response";
import eventManager, { EVENT_SUCCESS } from "../../../utils/events";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Space from "../../general/antd/space";
import Tag from "../../general/antd/tag";
import FormHookModal from "../../general/modals/form-hook-modal";
import ClientBtn from "../../general/table-components/actions/client-btn";
import DeleteBtn from "../../general/table-components/actions/delete-btn";
import EditBtn from "../../general/table-components/actions/edit-btn";
import ViewBtn from "../../general/table-components/actions/view-btn";
import Table from "../../general/table-components/table";
import CommercialRecordContext from "../../../context/commercial-records/context";
import { ICommercialRecords } from "../../../models/commercial-records/response";
import moment from "moment";

const { Paragraph, Title } = Typography;

interface IProps {}

const CommercialRecordTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, actions, loading, query } = useContext(CommercialRecordContext);

  const columns: ColumnProps<ICommercialRecords>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      sorter: {
        multiple: 2,
      },
      align: "center",
    },
    {
      title: t("number"),
      dataIndex: "number",
      align: "center",
      key: "number",
    },
    {
      title: t("main_center"),
      align: "center",
      dataIndex: "mainCenter",
      key: "mainCenter",
    },
    {
      title: t("purpose"),
      align: "center",
      dataIndex: "purpose",
      key: "purpose",
    },
    {
      title: t("client"),
      dataIndex: "client",
      key: "client",
      align: "center",
      render: (val) => val?.name ?? "---",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (val) => val?.name  ?? "---",
    },
    {
      title: t("creation_date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (createdAt: Date) => moment(createdAt).format("DD/MM/YYYY"),
    },
    {
      title: t("actions"),
      dataIndex: "",
      width: 250,
      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];
              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;
              window.open(url, "_blank");
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          ></EditBtn>
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCommercialRecord(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        size="small"
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default CommercialRecordTable;
