import { Descriptions as AntdDescriptions, Collapse, Space } from "antd";
import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import DetailsPage from "../../../form-components/view-details";
import Descriptions from "../../../general/antd/descriptions";
import EditeButton from "../../../general/antd/edite-button";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import Tag from "../../../general/antd/tag";

import PatentsContext from "../../../../context/patents-collection/patents/context";
// import DrawingReservationTabel from "../../drawing-reservation/drawing-resevation-tabel";
import office from "../../../../assets/images/icon-files/office.jpg";
import pdf from "../../../../assets/images/icon-files/pdf.png";

import PatentsReservationContextProvider from "../../../../context/patents-collection/patents-reservation/provider";
import PatentsContextProvider from "../../../../context/patents-collection/patents/provider";
import {
  getPatentsColor,
  getPatentsType,
  getPationsCountryColor,
  getPationsCountryType,
} from "../../../../models/patents-collection/patents/enum";
import PatentsReservationTabel from "../../patents-reservations/patents-resevation-tabel";
import styles from "./styles.module.scss";
interface Props {}
const { Panel } = Collapse;

const Patents: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(PatentsContext);

  const { id } = useParams();

  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  let srcImage = "";

  switch (details?.file?.extension) {
    case "pdf":
      srcImage = pdf;
      break;
    case "docx":
      srcImage = office;
      break;
    default:
      srcImage = office;
      break;
  }
  return (
    <>
      <DetailsPage title={t(`${"details_patents_and_utility_models"}`)}>
        <div className={styles.container}>
          <Space direction="vertical">
            <Collapse>
              <Panel header={t("details_patents_and_utility_models")} key="1">
                <EditeButton
                  onClick={() =>
                    navigate(`/patents-and-utility-models/update/${id}`)
                  }
                  className={styles.button}
                >
                  {t(`${"update_patents_and_utility_models"}`)}
                </EditeButton>
                {loading.includes("details") ? (
                  <Spin />
                ) : (
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_ar")}`}>
                      {details?.translations?.name[0]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={`${t("name_en")}`}>
                      {details?.translations?.name[1]?.value}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("pation_type")}>
                      <Tag
                        color={getPatentsColor(Number(details?.type))}
                        title={getPatentsType(Number(details?.type))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("drawing_area")}>
                      <Tag
                        color={getPationsCountryColor(
                          Number(details?.countryType)
                        )}
                        title={getPationsCountryType(
                          Number(details?.countryType)
                        )}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("clients")}>
                      {details?.clients
                        ? details?.clients?.map((client, index) => (
                            <Fragment key={index}>
                              <Tag title={client.name} />
                            </Fragment>
                          ))
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("explanation")}>
                      {details?.explanation ? details.explanation : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("description")}>
                      {details?.description ? details.description : "- "}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("files")}>
                      {details?.file ? (
                        <a href={details?.file?.url}>
                          <div className={styles.fileCardContainer}>
                            <Image src={srcImage} className={styles?.imgFile} />
                          </div>
                        </a>
                      ) : (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>
                  </Descriptions>
                )}
              </Panel>
            </Collapse>
            <Collapse defaultActiveKey={1}>
              <Panel
                header={t("details_patents_and_utility_models_reservation")}
                key="1"
              >
                <PatentsReservationTabel />
              </Panel>
            </Collapse>
          </Space>
        </div>
      </DetailsPage>
    </>
  );
};

const PatentsDetailsPage = () => {
  return (
    <PatentsContextProvider>
      <PatentsReservationContextProvider>
        <Patents />
      </PatentsReservationContextProvider>
    </PatentsContextProvider>
  );
};
export default PatentsDetailsPage;
