export enum StatusFor {
  trademark = 1,
  drawing = 2,
  patent = 3,
  copyright = 4,
  incidentClient = 5,
  grievance = 6,
  commercial = 7,
  objection = 8,
}
export enum AllStutusForCopyWorkFlow {
  registration = 1,
  renewal = 2,
  reRegistration = 3,
  annual_fee = 4,
  incidentClient = 5,
  grievance = 6,
  beginning = 7,
  appeal = 8,
  veto = 9,
  objection = 10,
}

export const getAllBooleanOrderTemplate = (
  status: AllStutusForCopyWorkFlow
) => {
  switch (status) {
    case AllStutusForCopyWorkFlow.registration:
      return "registration";
    case AllStutusForCopyWorkFlow.annual_fee:
      return "annual_fee";
    case AllStutusForCopyWorkFlow.reRegistration:
      return "reRegistration";
    case AllStutusForCopyWorkFlow.renewal:
      return "renewal";
    case AllStutusForCopyWorkFlow.incidentClient:
      return "incident";
    case AllStutusForCopyWorkFlow.grievance:
      return "grievance";
    case AllStutusForCopyWorkFlow.appeal:
      return "appeal";
    case AllStutusForCopyWorkFlow.beginning:
      return "beginning";
    case AllStutusForCopyWorkFlow.veto:
      return "veto";
    case AllStutusForCopyWorkFlow.objection:
      return "objection";
  }
};

export const getAllOrderTemplateColor = (status: AllStutusForCopyWorkFlow) => {
  switch (status) {
    case AllStutusForCopyWorkFlow.registration:
      return "blue";
    case AllStutusForCopyWorkFlow.annual_fee:
      return "red";
    case AllStutusForCopyWorkFlow.renewal:
      return "green";
    case AllStutusForCopyWorkFlow.reRegistration:
      return "orange";
    case AllStutusForCopyWorkFlow.incidentClient:
      return "green";
    case AllStutusForCopyWorkFlow.grievance:
      return "green";
    case AllStutusForCopyWorkFlow.appeal:
      return "pink";
    case AllStutusForCopyWorkFlow.beginning:
      return "brown";
    case AllStutusForCopyWorkFlow.veto:
      return "yellow";
    case AllStutusForCopyWorkFlow.objection:
      return "red";
  }
};

export const getForStatus = (status: StatusFor) => {
  switch (status) {
    case StatusFor.trademark:
      return "trademark";
    case StatusFor.drawing:
      return "drawing";
    case StatusFor.patent:
      return "patent";
    case StatusFor.copyright:
      return "copyright";
    case StatusFor.incidentClient:
      return "incident";
    case StatusFor.grievance:
      return "grievance";
    case StatusFor.commercial:
      return "commercial";
    case StatusFor.objection:
      return "objection";
    default:
      return "any";
  }
};
export const getForColor = (status: StatusFor) => {
  switch (status) {
    case StatusFor.trademark:
      return "red";
    case StatusFor.drawing:
      return "yellow";
    case StatusFor.patent:
      return "green";
    case StatusFor.copyright:
      return "pink";
    case StatusFor.incidentClient:
      return "blue";
    case StatusFor.commercial:
      return "brown";
    case StatusFor.grievance:
      return "red";
    case StatusFor.objection:
      return "red";
    default:
      return "yellow";
  }
};
