import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../../general/antd/descriptions";
import PageDetails from "../../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../../general/antd/spin";
import CostTypeContextProvider from "../../../../context/management-collection/cost-type/provider";
import CostTypeContext from "../../../../context/management-collection/cost-type/context";
import Tag from "../../../general/antd/tag";

interface Props {}
const CostType: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CostTypeContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${"details"}`}
        subtitle={`${"cost_type"}`}
        buttonName={`${"edite_cost_type"}`}
        onClick={() => navigate(`/types-of-costs/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="default" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name")}>
              {details?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("defaultValue")}>
              {details?.defaultValue}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ? (
                details?.note
              ) : (
                <Tag title="not_found" color="red" />
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const CostTypeDetailesPage = () => {
  return (
    <CostTypeContextProvider>
      <CostType />
    </CostTypeContextProvider>
  );
};
export default CostTypeDetailesPage;
