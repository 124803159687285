import { Checkbox, Col, Divider, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DefoultImage from "../../../../assets/images/user.jpg";
import NodeContext from "../../../../context/node/context";
import { INodeName } from "../../../../models/managent-collection/nodes-name/response";
import { IRole } from "../../../../models/managent-collection/role/response";
import { MediumFor } from "../../../../models/medium/enum";
import {
  getRenewalCount,
  getRequestStatus,
  getReservationStatus,
} from "../../../../models/node/enum";
import EndPoints from "../../../../services/end-points";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import ImageUploader from "../../../form-components/image-uploader";
import Image from "../../../general/antd/image";
import FormItem from "../../../general/form-item";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import styles from "./style.module.scss";

interface IProps {
  icon: string;
  orderTemplateID?: number;
}

const CreateNodeForm: React.FC<IProps> = (props) => {
  const { details, loading } = useContext(NodeContext);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState<string>(props.icon);
  const [nodesName, setNodesName] = useState<INodeName[]>([]);
  const [nodesNameLoading, setNodesNameLoading] = useState(true);
  const [typeOrderTemplate, setTypeOrderTemplate] = useState(undefined);
  const RequestStatus = [1, 2, 3];
  const renewalTypes = [1, 2];

  useEffect(() => {
    setImageUrl(props.icon ?? DefoultImage);
  }, [details]);

  const { t } = useTranslation();
  const { control } = useFormContext();

  useEffect(() => {
    const getTrademarkOrderTemplate = async () => {
      try {
        const { data } =
          await EndPoints.trademarkOrderTemplate.getTraderMarkOrderTeamplates(
            Number(props.orderTemplateID)
          );
        setTypeOrderTemplate(data.for);
      } catch (error) {
      } finally {
      }
    };
    getTrademarkOrderTemplate();
  }, []);

  const watchFields = useWatch({
    control,
    name: [
      "isDurationalNode",
      "canChangeReservationStatus",
      "canChangeRequestStatus",
      "sendNotificationToClient",
      "changeRenewalsCount",
    ],
  });

  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await EndPoints.role.getAllroles({
          perPage: -1,
        });
        console.log("lllllength", data?.data?.length);

        setRoles(data.data);
        setRolesLoading(false);
      } catch (error) {
      } finally {
        setRolesLoading(false);
      }
    };
    getRoles();
  }, []);

  useEffect(() => {
    const getNodesName = async () => {
      try {
        const { data } = await EndPoints.nodeName.getAllNodeName({
          perPage: -1,
        });
        setNodesName(data.data);
      } catch (error) {
      } finally {
        setNodesNameLoading(false);
      }
    };
    getNodesName();
  }, []);

  let cols: any = "";
  switch (typeOrderTemplate) {
    case 1:
      cols = (
        <>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_deposit_date")}`}
              name="changeDepositDate"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_first_publish_date_and_number")}`}
              name="changeFirstPublishDateAndNumber"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8} style={{ marginTop: "8px" }}>
            <Controller
              name="changeRenewalsCount"
              control={control}
              rules={{ required: false }}
              render={({ field: { ...field } }) => {
                return (
                  <>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      className="shadow"
                    >
                      {t("change_renewals_count")}
                    </Checkbox>
                  </>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_registration_date")}`}
              name="changeRegistrationDate"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_second_publish_date_and_number")}`}
              name="changeSecondPublishDateAndNumber"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8} style={{ marginTop: "-8px", marginBottom: "-2rem" }}>
            <FieldBuilder
              name="typeRenewalsCount"
              label={`${t("type_renewals_count")}`}
              width="large"
              disabled={watchFields[4] ? false : true}
              rules={{ required: watchFields[4] ? true : false }}
              input={{
                type: "select",
                options: renewalTypes.map((type, index) => {
                  return {
                    label: t(getRenewalCount(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_next_renewal_date")} `}
              name="changeNextRenewalDate"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_last_renewal_date")}`}
              name="changeLastRenewalDate"
              input={{ type: "checkBox" }}
            />
          </Col>
        </>
      );
      break;
    case 2:
      cols = (
        <>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_deposit_date")}`}
              name="changeDepositDate"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_first_publish_date_and_number")}`}
              name="changeFirstPublishDateAndNumber"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8} style={{ marginTop: "8px" }}>
            <Controller
              name="changeRenewalsCount"
              control={control}
              rules={{ required: false }}
              render={({ field: { ...field } }) => {
                return (
                  <>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      className="shadow"
                    >
                      {`${t("change_renewals_count")}  / ${t("annual_fee")}`}
                    </Checkbox>
                  </>
                );
              }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_registration_date")}`}
              name="changeRegistrationDate"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_second_publish_date_and_number")}`}
              name="changeSecondPublishDateAndNumber"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8} style={{ marginTop: "-8px", marginBottom: "-2rem" }}>
            <FieldBuilder
              name="typeRenewalsCount"
              label={`${t("type_renewals_count")}`}
              width="large"
              disabled={watchFields[4] ? false : true}
              rules={{ required: watchFields[4] ? true : false }}
              input={{
                type: "select",
                options: renewalTypes.map((type, index) => {
                  return {
                    label: t(getRenewalCount(type) as string),
                    value: type as number,
                  };
                }),
              }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_next_renewal_date")}  / ${t("annual_fee")}`}
              name="changeNextRenewalDate"
              input={{ type: "checkBox" }}
            />
          </Col>
          <Col span={8}>
            <FieldBuilder
              label={`${t("change_last_renewal_date")}  / ${t("annual_fee")}`}
              name="changeLastRenewalDate"
              input={{ type: "checkBox" }}
            />
          </Col>
        </>
      );
      break;

    default:
      break;
  }

  return (
    <>
      <Row gutter={[6, 6]}>
        <Col span={24}>
          <Row gutter={[6, 6]}>
            <Col span={3}>
              <Image preview className={styles.img} src={imageUrl} />
            </Col>
            <Col span={17} style={{ margin: "-10px 0 -10px 0" }}>
              <Row gutter={[6, 6]}>
                <Col span={24} style={{ margin: "0 0 -15px 0" }}>
                  <div>
                    <FormItem>
                      <Controller
                        name="iconId"
                        rules={{
                          required: {
                            value: false,
                            message: `${t("field_is_required_message")}`,
                          },
                        }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <ImageUploader
                              {...field}
                              type="BUTTON"
                              onChange={(response) => {
                                setImageUrl(response?.url);
                                field.onChange(response?.id);
                              }}
                              imageFor={MediumFor.NODE_ICON}
                            >
                              <EditBtn
                                loading={
                                  loading.includes("update") ||
                                  loading.includes("create")
                                }
                                className={styles.buttonEdite}
                              >
                                {t(`${"edit_icon"}`)}
                              </EditBtn>
                            </ImageUploader>
                          );
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
                <Col span={5}>
                  <DeleteBtn
                    onConfirm={async () => { }}
                    className={styles.buttonDelete}
                  >
                    {t(`${"delete_icon"}`)}{" "}
                  </DeleteBtn>
                </Col>
                <Col span={18}></Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Divider style={{ margin: "10px 0 10px 0" }} />
        <Col span={8}>
          <FieldBuilder
            name="NameId"
            label={`${t("node_name")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "select",
              allowSearch: true,
              loading: nodesNameLoading,
              options: nodesName?.map((node, index) => {
                return {
                  label: node.name,
                  value: node.id,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="authorizedRoles"
            label={`${t("authorized_roles")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "multiSelect",
              loading: rolesLoading,
              options: roles?.map((role, index) => {
                return {
                  label: role.name,
                  value: role.id,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="notificationRoles"
            label={`${t("notification_roles")}`}
            width="large"
            rules={{ required: true }}
            input={{
              type: "multiSelect",
              loading: rolesLoading,
              options: roles?.map((role, index) => {
                return {
                  label: role.name,
                  value: role.id,
                };
              }),
            }}
          />
        </Col>

        <Col span={24}>
          <FieldBuilder
            name="note"
            label={`${t("note")}`}
            input={{ type: "text-area" }}
          />
        </Col>
        {/* <Col span={8}></Col> */}
        <Col span={8}>
          <Controller
            name="isDurationalNode"
            control={control}
            rules={{ required: false }}
            render={({ field }) => {
              return (
                <>
                  <Checkbox {...field} checked={field.value} className="shadow">
                    {t("is_durational_node")}
                  </Checkbox>
                </>
              );
            }}
          />
        </Col>
        <Col span={8}>
          <Controller
            name="canChangeReservationStatus"
            control={control}
            rules={{ required: false }}
            render={({ field: { ...field } }) => {
              return (
                <>
                  <>
                    <Checkbox
                      {...field}
                      checked={field.value}
                      className="shadow"
                    >
                      {t("can_change_reservation_status")}
                    </Checkbox>
                  </>
                </>
              );
            }}
          />
        </Col>

        <Col span={8}>
          <Controller
            name="canChangeRequestStatus"
            control={control}
            rules={{ required: false }}
            render={({ field: { ...field } }) => {
              return (
                <>
                  <Checkbox {...field} checked={field.value} className="shadow">
                    {t("can_change_request_status")}
                  </Checkbox>
                </>
              );
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="duration"
            label={`${t("duration")}`}
            input={{ type: "number" }}
            rules={{ required: watchFields[0] ? true : false }}
            disabled={watchFields[0] ? false : true}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="reservationStatus"
            label={`${t("reservation_status")}`}
            width="large"
            disabled={watchFields[1] ? false : true}
            rules={{ required: watchFields[1] ? true : false }}
            input={{
              type: "select",
              options: RequestStatus.map((status, index) => {
                return {
                  label: t(getReservationStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>

        <Col span={8}>
          <FieldBuilder
            name="RequestStatus"
            label={`${t("request_status")}`}
            width="large"
            disabled={watchFields[2] ? false : true}
            rules={{ required: watchFields[2] ? true : false }}
            input={{
              type: "select",
              options: RequestStatus.map((status, index) => {
                return {
                  label: t(getRequestStatus(status) as string),
                  value: status as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={16}>
              <Row>
                <Col span={24}>
                  <Controller
                    name="sendNotificationToClient"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { ...field } }) => {
                      return (
                        <>
                          <Checkbox
                            {...field}
                            checked={field.value}
                            className="shadow"
                          >
                            {t("send_notification_to_client")}
                          </Checkbox>
                        </>
                      );
                    }}
                  />
                </Col>
                <Col span={24} style={{ marginTop: "1rem" }}>
                  <FieldBuilder
                    name="clientMessage"
                    label={`${t("client_message")}`}
                    input={{ type: "text-area" }}
                    rules={{ required: watchFields[3] ? true : false }}
                    disabled={watchFields[3] ? false : true}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="color"
                label={t("pick_color")}
                input={{ type: "color-picker" }}
                rules={{ required: false }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider style={{ margin: "2px" }} />
      <Row>{cols}</Row>
    </>
  );
};

export default CreateNodeForm;
