import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../../context/app/context";
import PatentsReservationContext from "../../../../context/patents-collection/patents-reservation/context";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import { IPatentsReservationHistory } from "../../../../models/patents-collection/patents-reservation/response";
import {
  getBooleanTrademarkReservation,
  getTrademarkChangeType,
  getTrademarkChangeTypeColor,
  getTrademarkReservationColor,
} from "../../../../models/trademark-collection/trademark-reservation/enum";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Tag from "../../../general/antd/tag";
import Table from "../../../general/table-components/table";
import { getBooleanPatensReservation } from "../../../../models/patents-collection/patents-reservation/enum";

interface IProps {}
const PatentsReservationHistoryTabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { actions, loading, query, history } = useContext(
    PatentsReservationContext
  );

  const { direction } = useContext(AppContext);

  useEffect(() => {
    id && actions.getHistory(Number(id));
  }, [query, direction]);

  const columns: ColumnProps<IPatentsReservationHistory>[] = [
    {
      title: t("old_status"),
      dataIndex: "oldStatus",
      key: "oldStatus",
      align: "center",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getTrademarkReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("new_status"),
      dataIndex: "newStatus",
      align: "center",
      key: "newStatus",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getTrademarkReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("copy_status"),
      dataIndex: "changeType",
      align: "center",
      key: "changeType",
      render: (record) => {
        return (
          <Tag
            title={getTrademarkChangeType(Number(record))}
            color={getTrademarkChangeTypeColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("change_status_date"),
      dataIndex: "updatedAt",
      align: "center",
      key: "updatedAt",
      render: (_, record) => {
        return <span>{moment(record.createdAt).format("DD/MM/YYYY")}</span>;
      },
    },
    {
      title: t("user"),
      align: "center",
      dataIndex: "user",
      key: "user",
      render: (record) => {
        return <>{record ? <span>{record.name}</span> : <span> -- </span>}</>;
      },
    },
    {
      title: t("order_id"),
      align: "center",
      dataIndex: "requestID",
      key: "requestID",
      render: (record) => {
        return <> {record ? <span> {record} </span> : <span> -- </span>}</>;
      },
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        size="small"
        showPagination={true}
        columns={columns}
        dataSource={history?.data ?? []}
        loading={loading.includes("history")}
        total={history?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default PatentsReservationHistoryTabel;
