import { Descriptions as AntdDescriptions } from "antd";
import { Fragment, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import { getTypesColor, getBooleanTypes } from "../../../models/client/enum";
import Tag from "../../general/antd/tag";
import ClientContextProvider from "../../../context/client/provider";
import ClientContext from "../../../context/client/context";
import Image from "../../general/antd/image";
import office from "../../../assets/images/icon-files/office.jpg";
import a from "../../../assets/images/icon-files/pdf.png";

interface Props { }

const ClientDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(ClientContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);
  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("client")}`}
        buttonName={`${t("edit_client")}`}
        onClick={() => navigate(`/clients/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="small" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("name")}>
              {details?.name}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("type")}>
              <Tag
                title={getBooleanTypes(Number(details?.type))}
                color={getTypesColor(Number(details?.type))}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("email")}>
              {details?.email ? details.email : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("responsibl_person_name")}>
              {details?.responsiblPersonName}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("is_copied")}>
              <Tag
                title={details?.is_copied ? t("copied") : t("not_copied")}
                color={details?.is_copied ? "gray" : "orange"}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("landline")}>
              {details?.landline ? details.landline : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("mobile")}>
              {details?.mobile ? details?.mobile : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("note")}>
              {details?.note ? details?.note : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("commercial_record")}>
              {details?.commercialRecord ? details?.commercialRecord : "-"}
            </AntdDescriptions.Item>

            <AntdDescriptions.Item label={t("legal_form")}>
              {details?.legalForm ? details?.legalForm : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("father_name")}>
              {details?.fatherName ? details?.fatherName : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("mother_name")}>
              {details?.motherName ? details?.motherName : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("national_number")}>
              {details?.nationalNumber ? details?.nationalNumber : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("nationality")}>
              {details?.nationality ? details?.nationality?.name : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("country_of_residence")}>
              {details?.countryOfResidence ? details?.countryOfResidence : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("city_of_residence")}>
              {details?.cityOfResidence ? details?.cityOfResidence : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("address")}>
              {details?.address ? details?.address : "-"}
            </AntdDescriptions.Item>
           
            <AntdDescriptions.Item label={t("identity_of_number")}>
              {details?.identityOfNumber ? details?.identityOfNumber : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("honesty")}>
              {details?.honesty ? details?.honesty : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("date_of_birth")}>
              {details?.dateOfBirth ? details?.dateOfBirth.slice(0, 10) : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("date_of_identity")}>
              {details?.dateOfIdentity
                ? details?.dateOfIdentity.slice(0, 10)
                : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("authorization")}>
              {details?.authorization ? details?.authorization : "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("commercial_record_file")}>
              {details?.commercialRecordFile ? (
                <Image preview src={details.commercialRecordFile?.extension == 'docx' ? office : details.commercialRecordFile?.extension == 'pdf' ? a : details.commercialRecordFile?.url} />
              ) : (
                <Tag title="not_found" color="red" />
              )}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("authorization_file")}>
              {details?.authorizationFile ? (
                <Image preview src={details.authorizationFile?.extension == 'docx' ? office : details.authorizationFile?.extension == 'pdf' ? a : details.authorizationFile?.url} />
              ) : (
                <Tag title="not_found" color="red" />
              )}
            </AntdDescriptions.Item>
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};
const ClientDetailsPage = () => {
  return (
    <ClientContextProvider>
      <ClientDetails />
    </ClientContextProvider>
  );
};
export default ClientDetailsPage;
