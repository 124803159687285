import { IInternalState, AuthLoading } from "./context";
import { CAN_VIEW_HOME_PAGE } from "../../utils/rbac/permissions";
import toggleLoading from "../../utils/helpers/xor";

type Action =
  | { type: "LOADING"; payload: { loading: AuthLoading | AuthLoading[] } }
  | { type: "IS_AUTHENTICATED"; payload: { isAuthenticated: boolean } }
  | { type: "SET_USER_DETAILS"; payload: { user?: any } }
  | { type: "SET_PERMISSIONS"; payload: { permissions?: any } }
  | {
      type: "SET_GRANTED_PERMISSIONS";
      payload: { permissions: string[] };
    }
  | { type: "LOGOUT_SUCCESS" }
  | { type: "LOGIN_SUCCESS"; payload: { user?: any } };

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "SET_GRANTED_PERMISSIONS":
      return { ...state, grantedPermissions: action.payload.permissions };
    case "IS_AUTHENTICATED":
      return { ...state, isAuthenticated: action.payload.isAuthenticated };
    case "LOADING":
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        userDetails: undefined,
        isAuthenticated: false,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        userDetails: action.payload.user,
        isAuthenticated: true,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload.user,
      };
    case "SET_PERMISSIONS": {
      return {
        ...state,
        permissions: action.payload.permissions,
      };
    }
    default:
      return state;
  }
};

export default reducer;
