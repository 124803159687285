import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { IDrawingReservationQuery } from "../../../models/drawing-collection/drawing-reservation/query";
import {
  IDrawingReservation,
  IDrawingReservationDetails,
  IDrawingReservationHistory,
} from "../../../models/drawing-collection/drawing-reservation/response";
import {
  ICreateDrawingReservation,
  IUpdateDrawingReservation,
} from "../../../models/drawing-collection/drawing-reservation/request";

class DrawingReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllDrawingsReservation = (
    query: IDrawingReservationQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDrawingReservation>>({
      path: `/drawing-reservations`,
      method: "GET",
      query,
      ...params,
    });

  getHistory = (query: IDrawingReservationQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDrawingReservationHistory>>({
      path: `/drawing-reservations/history`,
      method: "GET",
      query,
      ...params,
    });

  getDrawingReservation = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingReservationDetails>({
      path: `/drawing-reservations/${id}`,
      method: "GET",
      ...params,
    });

  createDrawingReservation = (data: ICreateDrawingReservation) =>
    this.http.request<IDrawingReservationDetails>({
      path: "/drawing-reservations",
      method: "POST",
      body: data,
    });

  updateDrawingReservation = (
    id: number,
    data: IUpdateDrawingReservation,
    params?: RequestParams
  ) =>
    this.http.request<IDrawingReservationDetails>({
      path: `/drawing-reservations/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteDrawingReservation = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingReservationDetails>({
      path: `/drawing-reservations/${id}`,
      method: "DELETE",
      ...params,
    });

  transferDrawingReservation = (
    id: number,
    data: any,
    params?: RequestParams
  ) =>
    this.http.request<IDrawingReservationDetails>({
      path: `/drawing-reservations/transfer/${id}`,
      method: "POST",
      body: data,
      ...params,
    });
}

export default DrawingReservationService;
