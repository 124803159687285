import { IGrievancePaymentsQuery } from "../../../../models/lawswits-collection/grievance/client-payments/query";
import {
  ICreateGrievancePayment,
  IUpdateGrievancePayment,
} from "../../../../models/lawswits-collection/grievance/client-payments/request";
import {
  IGrievancePayment,
  IGrievancePaymentDetails,
} from "../../../../models/lawswits-collection/grievance/client-payments/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class GrievancePaymentService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClientPayments = (
    query: IGrievancePaymentsQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IGrievancePayment>>({
      path: `/grievance-suits-client-payment`,
      method: "GET",
      query,
      ...params,
    });

  getClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<IGrievancePaymentDetails>({
      path: `/grievance-suits-client-payment/${id}`,
      method: "GET",
      ...params,
    });

  createClientPayment = (
    data: ICreateGrievancePayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreateGrievancePayment>({
      path: "/grievance-suits-client-payment",
      method: "POST",
      body: data,
      ...params,
    });

  updateClientPayment = (
    id: number,
    data: IUpdateGrievancePayment,
    params?: RequestParams
  ) =>
    this.http.request<ICreateGrievancePayment>({
      path: `/grievance-suits-client-payment/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteClientPayment = (id: number, params?: RequestParams) =>
    this.http.request<ICreateGrievancePayment>({
      path: `/grievance-suits-client-payment/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default GrievancePaymentService;
