import { Descriptions as AntdDescriptions } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Descriptions from "../../general/antd/descriptions";
import PageDetails from "../../general/details";
import { useNavigate, useParams } from "react-router-dom";
import Spin from "../../general/antd/spin";
import AppContext from "../../../context/app/context";
import Tag from "../../general/antd/tag";
import TraderMarkOrderTeamplateContext from "../../../context/workflow/context";

import TraderMarkOrderTeamplateContextProvider from "../../../context/workflow/provider";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
  getForColor,
  getForStatus,
} from "../../../models/workflow/enms-workflow/all-enums";

interface Props {}
const TraderMarkOrderTeamplateDetails: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(
    TraderMarkOrderTeamplateContext
  );
  const { direction } = useContext(AppContext);

  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);
  return (
    <>
      <PageDetails
        title={`${t("details")}`}
        subtitle={`${t("trademark_orders_templates")}`}
        buttonName={`${t("update_trademark_orders_templates")}`}
        onClick={() => navigate(`/trademark-order-template/update/${id}`)}
      >
        {loading.includes("details") ? (
          <Spin />
        ) : (
          <Descriptions size="middle" column={1} bordered>
            <AntdDescriptions.Item label={t("id")}>
              {details?.id}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name_ar")}>
              {details?.translations?.name[0]?.value ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("name_en")}>
              {details?.translations?.name[1]?.value ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("country_order_template")}>
              {details?.country?.name ?? "-"}
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("request_template")}>
              <Tag
                title={getForStatus(Number(details?.for))}
                color={getForColor(Number(details?.for))}
              />
            </AntdDescriptions.Item>
            <AntdDescriptions.Item label={t("type_workflow")}>
              <Tag
                title={getAllBooleanOrderTemplate(Number(details?.type))}
                color={getAllOrderTemplateColor(Number(details?.type))}
              />
            </AntdDescriptions.Item>
            {details?.mainPrice && (
              <AntdDescriptions.Item label={t("main_price")}>
                {details?.mainPrice}
              </AntdDescriptions.Item>
            )}

            {details?.mainWordsCount && (
              <AntdDescriptions.Item label={t("main_words_count")}>
                {details?.mainWordsCount}
              </AntdDescriptions.Item>
            )}

            {details?.extraWordsCount && (
              <AntdDescriptions.Item label={t("extra_words_count")}>
                {details?.extraWordsCount}
              </AntdDescriptions.Item>
            )}
            {details?.extraWordsPrice && (
              <AntdDescriptions.Item label={t("extra_words_price")}>
                {details?.extraWordsPrice}
              </AntdDescriptions.Item>
            )}

            {details?.officeCost && (
              <AntdDescriptions.Item label={t("office_cost")}>
                {details?.officeCost}
              </AntdDescriptions.Item>
            )}
          </Descriptions>
        )}
      </PageDetails>
    </>
  );
};

const TraderMarkOrderTeamplateDetailsPage = () => {
  return (
    <TraderMarkOrderTeamplateContextProvider>
      <TraderMarkOrderTeamplateDetails />
    </TraderMarkOrderTeamplateContextProvider>
  );
};
export default TraderMarkOrderTeamplateDetailsPage;
