import {
  Avatar,
  Badge,
  Button,
  Divider,
  Layout,
  List,
  Popover,
  Switch,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FaBell, FaMoon, FaSun } from "react-icons/fa";
import { FiBell, FiGlobe } from "react-icons/fi";
import defaultUserImage from "../../../../assets/images/user.jpg";
import AppContext from "../../../../context/app/context";
import AuthContext from "../../../../context/auth/context";
import { INotifications } from "../../../../models/notification/response";
import EndPoints from "../../../../services/end-points";
import { INITIAL_PAGE, languages } from "../../../../utils/costants";
import ProfileCard from "../../../auth/profile-card";
import styles from "./style.module.scss";
const { Header } = Layout;

interface IProps {}
const NavBar: React.FC<IProps> = (props) => {
  const { direction, theme, actions } = useContext(AppContext);

  // Languages
  const [languagePopoverVisible, setLanguagePopoverVisible] = useState(false);
  const { userDetails: user } = useContext(AuthContext);

  // User Popover
  const [userPopoverVisible, setUserPopoverVisible] = useState(false);

  const { i18n, t } = useTranslation();

  const toggleDarkMode = () => {
    if (theme === "light") {
      actions.changeTheme("dark");
    } else {
      actions.changeTheme("light");
    }
  };

  const [notficationsPage, setNotificationsPage] = useState(INITIAL_PAGE);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [notificationsData, setNotificationsData] = useState<INotifications[]>(
    []
  );
  const [notificationVisiable, setNotificationVisiable] = useState(false);
  const [unReadNotification, setUnReadNotification] = useState(0);

  const getAllNotificationsData = async () => {
    try {
      setButtonLoading(true);
      const data = await EndPoints.notification.getAllNotifications({
        page: notficationsPage,
        perPage: 5,
      });
      setNotificationsData([...notificationsData, ...data.data.data]);

      // setNotificationsData(data.data.data);
      const unRead = await EndPoints.notification.unReadNotification();
      setUnReadNotification(unRead.data);
    } catch (error) {
    } finally {
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    user && getAllNotificationsData();
  }, []);

  return (
    <Header className={styles.header}>
      <div className={styles.actionsContainer}>
        {/* <LiveClock /> */}
        <div className={styles.actionContainer}>
          <div className={styles.themeSwitcher}>
            <Switch
              checkedChildren={
                <div className={styles.center}>
                  <FaSun />
                </div>
              }
              unCheckedChildren={
                <div className={styles.center}>
                  <FaMoon />
                </div>
              }
              defaultChecked={theme === "light"}
              onChange={toggleDarkMode}
            />
          </div>
          {/* Languages */}
          {languages?.length > 0 && (
            <Popover
              open={languagePopoverVisible}
              onOpenChange={setLanguagePopoverVisible}
              placement="bottom"
              content={
                <>
                  <List
                    size="small"
                    dataSource={languages}
                    renderItem={(language) => (
                      <List.Item
                        className={styles.cursorPointer}
                        onClick={() => {
                          i18n?.changeLanguage(language.code);
                          setLanguagePopoverVisible(false);
                        }}
                      >
                        <span className={styles.mStart05}>{language.name}</span>
                      </List.Item>
                    )}
                  />
                </>
              }
              trigger="click"
            >
              <Button
                className={styles.verticalMiddle}
                shape="circle"
                icon={<FiGlobe />}
                type="text"
                size="large"
              />
            </Popover>
          )}
          {/* Notifications */}

          <Popover
            open={notificationVisiable}
            onOpenChange={setNotificationVisiable}
            placement="bottomLeft"
            content={
              <>
                <List
                  size="small"
                  loading={buttonLoading}
                  dataSource={notificationsData}
                  style={{
                    maxHeight: "300px",
                    minHeight: "40px",
                    width: "300px",
                    overflowY: "scroll",
                  }}
                  renderItem={(notification) => (
                    <List.Item
                      onClick={() => {
                        if (notification.status === 2) {
                          EndPoints.notification.readNotification(
                            notification.id
                          );
                          notification.status = 3;
                          getAllNotificationsData();
                        }
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          // justifyContent: "space-between",
                          alignItems: "center",
                          margin: "0 -12px ",
                        }}
                        className={styles.cursorPointer}
                      >
                        <Badge dot={notification.status === 2 && true}>
                          <Avatar
                            icon={<FaBell />}
                            size="default"
                            shape="circle"
                          />
                        </Badge>
                        <div style={{ marginInlineStart: "10px" }}>
                          <div className={styles.title}>
                            {notification.title}
                          </div>
                          <div className={styles.body}>{notification.body}</div>
                        </div>
                      </span>
                    </List.Item>
                  )}
                />

                {notificationsData.length >= 5 && unReadNotification > 0 && (
                  <>
                    <Divider style={{ margin: "4px 0" }} />
                    <Button
                      style={{
                        display: "block",
                        margin: "auto",
                        paddingBottom: "5px",
                      }}
                      type="primary"
                      loading={buttonLoading}
                      onClick={() => {
                        setNotificationsPage((page) => page + 1);
                      }}
                    >
                      {t("show_more")}
                    </Button>
                  </>
                )}
              </>
            }
            trigger="click"
          >
            <Button
              className={styles.verticalMiddle}
              shape="circle"
              type="text"
              style={{}}
            >
              <Badge
                size="default"
                style={{
                  fontSize: "10px",
                }}
                count={unReadNotification}
              >
                <FiBell className={styles.billIcon} />
              </Badge>
            </Button>
          </Popover>
        </div>

        {/* User */}
        <Popover
          placement={direction === "rtl" ? "bottomLeft" : "bottomRight"}
          content={<ProfileCard />}
          trigger="click"
          open={userPopoverVisible}
          onOpenChange={setUserPopoverVisible}
        >
          <div className={styles.avatarContainer}>
            <div className={styles.userName}>
              <div>{user?.name ?? "Administrator"}</div>
              <small>{user?.jobTitle?.name ?? " "}</small>
            </div>
            <Avatar
              size="default"
              src={user?.profileImage?.url ?? defaultUserImage}
            />
          </div>
        </Popover>
      </div>
    </Header>
  );
};

export default NavBar;
