import { ColumnProps } from "antd/lib/table";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Popover, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../models/base/base-query";
import { tableOnChange } from "../../../utils/helpers/table-sorts-filters";
import Tag from "../../general/antd/tag";
import Table from "../../general/table-components/table";
import ReportContext from "../../../context/report/context";
import { IReport } from "../../../models/reoprt/response";
import { getTypes, getTypesColor } from "../../../models/reoprt/enum";
import moment from "moment";
import Image from "../../general/antd/image";
import srcImage from "../../../assets/images/icon-files/exel.png";
import styles from "./styles.module.scss";

interface IProps {}

const ReportTable: React.FC<IProps> = () => {
  const { t } = useTranslation();
  const { list, actions, loading, query } = useContext(ReportContext);

  const columns: ColumnProps<IReport>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      width: 100,
      align: "center",
    },
    {
      title: t("type_report"),
      dataIndex: "type",
      key: "type",
      align: "center",
      width: 100,
      render: (type) => {
        return (
          <Tag
            title={t(getTypes(type))}
            color={getTypesColor(type)}
          />
        );
      },
    },
    {
      title: t("start_date"),
      dataIndex: "startDate",
      key: "startDate",
      align: "center",
      width: 100,
      render: (startDate) => {
        return <>{moment(startDate).format("YYYY-MM-DD")}</>;
      },
    },
    {
      title: t("end_date"),
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      width: 100,
      render: (endDate) => {
        return <>{moment(endDate).format("YYYY-MM-DD")}</>;
      },
    },
    {
      title: t("report"),
      dataIndex: "report",
      key: "report",
      align: "center",
      width: 100,
      render: (report) => {
        return (
          <Popover content={<div>{t("click_here_to_download_file")}</div>}>
            <a
              href={report?.url.replace("http://", "https://")}
              download
              style={{ width: "50px" }}
            >
              <Row justify="center">
                <Col span={5}>
                  <div className={styles.fileCardContainer}>
                    <Image src={srcImage} className={styles?.imgFile} />
                  </div>
                </Col>
              </Row>
            </a>
          </Popover>
        );
      },
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        columns={columns}
        size="small"
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ReportTable;
