import { ICreateCategory } from "../../../models/managent-collection/category/request";
import { ICategory } from "../../../models/managent-collection/category/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ICategoryQuery } from "../../../models/managent-collection/category/query";
import {
  IExportCategoryExcel,
  IUpdateCategory,
} from "../../../models/managent-collection/category/request";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/helpers/constants";
import axios from "axios";

class CategoryService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCategories = (query: ICategoryQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICategory>>({
      path: `/categories`,
      method: "GET",
      query,
      ...params,
    });

  getCategory = (id: number, params?: RequestParams) =>
    this.http.request<ICategory>({
      path: `/categories/${id}`,
      method: "GET",
      ...params,
    });

  createCategory = (data: ICreateCategory, params?: RequestParams) =>
    this.http.request<ICategory>({
      path: "/categories",
      method: "POST",
      body: data,
      ...params,
    });

  updateCategory = (
    id: number,
    data: IUpdateCategory,
    params?: RequestParams
  ) =>
    this.http.request<ICategory>({
      path: `/categories/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCategory = (id: number, params?: RequestParams) =>
    this.http.request<ICategory>({
      path: `/categories/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (data: IExportCategoryExcel, query: ICategoryQuery) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/categories/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
}

export default CategoryService;
