import { Col, Popover, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTrash } from "react-icons/fa";
import { useParams } from "react-router-dom";
import file from "../../../../assets/images/icon-files/file.png";
import AgentRegistrationContext from "../../../../context/independent_requests-collection/agent-registration/context";
import AgentRegistrationContextProvider from "../../../../context/independent_requests-collection/agent-registration/provider";
import { IClient } from "../../../../models/client/response";
import { IUpdateAgentRegistration } from "../../../../models/independent-requests-collection/agent-registration/request";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { MediumFor } from "../../../../models/medium/enum";
import { IMedium } from "../../../../models/medium/response";
import EndPoints from "../../../../services/end-points";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import FileUploader from "../../../form-components/file-uploader";
import GeneralForm from "../../../form-components/general-form";
import Image from "../../../general/antd/image";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import styles from "./style.module.scss";
import {
  checkType,
  getUrlImageFile,
} from "../../../../utils/helpers/check-type-file";
interface IProps {}

const Form: React.FC<IProps> = (props) => {
  const { details, actions, loading } = useContext(AgentRegistrationContext);

  const { t } = useTranslation();
  const { id } = useParams();

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const [criminalDocumentOrUnjudged, setCriminalDocumentOrUnjudged] =
    useState<IMedium>(null);
  const [restrictionOrPersonalPhoto, setRestrictionOrPersonalPhoto] =
    useState<IMedium>(null);
  const [commercialRegister, setCommercialRegister] = useState<IMedium>(null);
  const [universityQualification, setUniversityQualification] =
    useState<IMedium>(null);
  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, []);

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  // get all CLients from api
  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    details &&
      details?.criminalDocumentOrUnjudged &&
      setCriminalDocumentOrUnjudged(details?.criminalDocumentOrUnjudged);

    details?.restrictionOrPersonalPhoto &&
      setRestrictionOrPersonalPhoto(details?.restrictionOrPersonalPhoto);
    details?.commercialRegister &&
      setCommercialRegister(details?.commercialRegister);
    details?.universityQualification &&
      setUniversityQualification(details?.universityQualification);
  }, [details]);

  return (
    <GeneralForm
      onSubmit={async (data) => {
        details
          ? await actions.updateAgentRegistration(details?.id, data)
          : await actions.createAgentRegistration(data);
      }}
      title={
        details
          ? t("update_agent_registration_request")
          : t("add_agent_registration_request")
      }
      values={[
        {
          key: "criminalDocumentOrUnjudged",
          value: criminalDocumentOrUnjudged?.id ?? null,
        },
        {
          key: "restrictionOrPersonalPhoto",
          value: restrictionOrPersonalPhoto?.id ?? null,
        },
        {
          key: "commercialRegister",
          value: commercialRegister?.id ?? null,
        },
        {
          key: "universityQualification",
          value: universityQualification?.id ?? null,
        },
      ]}
      defaultValues={
        {
          address: details?.address,
          clientId: details?.client?.id,
          companyManagerName: details?.companyManagerName,
          countryId: details?.country?.id,
          dateFinancialReceipt: details?.dateFinancialReceipt,
          depositNumber: details?.depositNumber,
          financialReceiptNumber: details?.financialReceiptNumber,
          legalFormPerson: details?.legalFormPerson,
          note: details?.note,
          officialDate: details?.officialDate,
          privateOfficeAddress: details?.privateOfficeAddress,
          restrictionOrPersonalPhoto: details?.restrictionOrPersonalPhoto?.id,
          universityQualification: details?.universityQualification?.id,
          criminalDocumentOrUnjudged: details?.criminalDocumentOrUnjudged?.id,
          commercialRegister: details?.commercialRegister?.id,
          orderValue: details?.orderValue,
          //clientPayments: details?.clientPayments,
        } as IUpdateAgentRegistration
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={8}>
              <FieldBuilder
                name="clientId"
                label={`${t("client")}`}
                width="large"
                input={{
                  type: "select",
                  loading: clientsLoading,
                  allowSearch: true,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="countryId"
                label={`${t("country")}`}
                width="large"
                rules={{ required: true }}
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((contry, index) => {
                    return {
                      key: index,
                      label: contry.name,
                      value: contry.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="depositNumber"
                label={`${t("depositNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="officialDate"
                label={`${t("official_date")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
                rules={{ required: true }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="financialReceiptNumber"
                label={`${t("financialReceiptNumber")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="dateFinancialReceipt"
                label={`${t("dateFinancialReceipt")}`}
                input={{ type: "date-picker", format: "YYYY-MM-DD" }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="companyManagerName"
                label={`${t("companyManagerName")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="orderValue"
                label={`${t("order_value")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>
            {/* <Col span={8}>
              <FieldBuilder
                name="clientPayments"
                label={`${t("client_payments")}`}
                input={{ type: "number" }}
                rules={{ required: false }}
              />
            </Col>*/}
            <Col span={12}>
              <FieldBuilder
                name="legalFormPerson"
                label={`${t("legalFormPerson")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="privateOfficeAddress"
                label={`${t("privateOfficeAddress")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="address"
                label={`${t("mailing_address")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={12}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
                rules={{ required: false }}
              />
            </Col>

            <Col span={24}>
              <div>
                <FormItem label={t("criminalDocumentOrUnjudged")}>
                  <Controller
                    name="criminalDocumentOrUnjudged"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          onChange={(response) => {
                            setCriminalDocumentOrUnjudged(response);
                          }}
                          fileFor={MediumFor.COPYRIGHT_FILE}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            {criminalDocumentOrUnjudged && (
              <Col span={5} style={{ marginBottom: "1rem" }}>
                <Popover
                  content={<div>{t("click_here_to_download_file")}</div>}
                >
                  <a
                    target="_blank"
                    href={details?.criminalDocumentOrUnjudged?.url}
                  >
                    <div className={styles.fileCardContainer}>
                      {checkType(criminalDocumentOrUnjudged.extension) ? (
                        <Image
                          src={criminalDocumentOrUnjudged?.url}
                          className={styles?.imgFile}
                          alt={criminalDocumentOrUnjudged?.extension}
                        />
                      ) : (
                        <Image
                          src={getUrlImageFile(
                            criminalDocumentOrUnjudged?.extension
                          )}
                          className={styles?.imgFile}
                          alt={universityQualification?.extension}
                        />
                      )}
                    </div>
                  </a>
                </Popover>
                <FaTrash
                  onClick={() => setCriminalDocumentOrUnjudged(null)}
                  className={styles.trash}
                />
              </Col>
            )}

            <Col span={24}>
              <div>
                <FormItem label={t("restrictionOrPersonalPhoto")}>
                  <Controller
                    name="restrictionOrPersonalPhoto"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          onChange={(response) => {
                            setRestrictionOrPersonalPhoto(response);
                          }}
                          fileFor={MediumFor.COPYRIGHT_FILE}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            {restrictionOrPersonalPhoto && (
              <Col span={5} style={{ marginBottom: "1rem" }}>
                <Popover
                  content={<div>{t("click_here_to_download_file")}</div>}
                >
                  <a target="_blank" href={restrictionOrPersonalPhoto?.url}>
                    <div className={styles.fileCardContainer}>
                      {checkType(restrictionOrPersonalPhoto.extension) ? (
                        <Image
                          src={restrictionOrPersonalPhoto?.url}
                          className={styles?.imgFile}
                          alt={restrictionOrPersonalPhoto?.extension}
                        />
                      ) : (
                        <Image
                          src={getUrlImageFile(
                            restrictionOrPersonalPhoto?.extension
                          )}
                          className={styles?.imgFile}
                          alt={universityQualification?.extension}
                        />
                      )}
                    </div>
                  </a>
                </Popover>
                <FaTrash
                  onClick={() => setRestrictionOrPersonalPhoto(null)}
                  className={styles.trash}
                />
              </Col>
            )}

            <Col span={24}>
              <div>
                <FormItem label={t("commercialRegister")}>
                  <Controller
                    name="commercialRegister"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          onChange={(response) => {
                            setCommercialRegister(response);
                          }}
                          fileFor={MediumFor.COPYRIGHT_FILE}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            {commercialRegister && (
              <Col span={5} style={{ marginBottom: "1rem" }}>
                <Popover
                  content={<div>{t("click_here_to_download_file")}</div>}
                >
                  <a target="_blank" href={commercialRegister?.url}>
                    <div className={styles.fileCardContainer}>
                      {checkType(commercialRegister.extension) ? (
                        <Image
                          src={commercialRegister?.url}
                          className={styles?.imgFile}
                          alt={commercialRegister?.extension}
                        />
                      ) : (
                        <Image
                          src={getUrlImageFile(commercialRegister?.extension)}
                          className={styles?.imgFile}
                          alt={universityQualification?.extension}
                        />
                      )}
                    </div>
                  </a>
                </Popover>
                <FaTrash
                  onClick={() => setCommercialRegister(null)}
                  className={styles.trash}
                />
              </Col>
            )}

            <Col span={24}>
              <div>
                <FormItem label={t("universityQualification")}>
                  <Controller
                    name="universityQualification"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          onChange={(response) => {
                            setUniversityQualification(response);
                          }}
                          fileFor={MediumFor.COPYRIGHT_FILE}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            {universityQualification && (
              <Col span={5} style={{ marginBottom: "1rem" }}>
                <Popover
                  content={<div>{t("click_here_to_download_file")}</div>}
                >
                  <a target="_blank" href={universityQualification?.url}>
                    <div className={styles.fileCardContainer}>
                      {checkType(universityQualification.extension) ? (
                        <Image
                          src={universityQualification?.url}
                          className={styles?.imgFile}
                          alt={universityQualification?.extension}
                        />
                      ) : (
                        <Image
                          src={getUrlImageFile(
                            universityQualification?.extension
                          )}
                          className={styles?.imgFile}
                          alt={universityQualification?.extension}
                        />
                      )}
                    </div>
                  </a>
                </Popover>
                <FaTrash
                  onClick={() => setUniversityQualification(null)}
                  className={styles.trash}
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </GeneralForm>
  );
};

const AgentRegistrationRequestForm = () => {
  return (
    <AgentRegistrationContextProvider>
      <Form />
    </AgentRegistrationContextProvider>
  );
};

export default AgentRegistrationRequestForm;
