import TreeItem from "../tree-item";
import TreeList from "../tree-list";
import React from "react";
import { INodeTree } from "../../../../models/workflow/response";

interface IProps {
  items: INodeTree;
}

const TreeRoot: React.FC<IProps> = ({ items }) => {
  if (!items) return <></>;

  return (
    <ul>
      <li dir="ltr">
        <TreeItem
          id={items?.id}
          name={items?.name?.name}
          icon={items?.icon?.url}
          color={items?.color}
          visited={items?.visited}
          parentNode={items?.parentNode}
        />
        {items?.childNodes?.length !== 0 && (
          <TreeList items={items?.childNodes} />
        )}
      </li>
    </ul>
  );
};

export default TreeRoot;
