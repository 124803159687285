import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../../context/app/context";
import DrawingContext from "../../../context/drawing-collection/drawing/context";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";

import CopyrightFilter from "../../../components/copyright-managment/copyright/copyright-filter";
import CopyrightTable from "../../../components/copyright-managment/copyright/copyright-table";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import CopyrightReservationContextProvider from "../../../context/copyright-collection/copyright-reservation/provider";
import CopyrightContextProvider from "../../../context/copyright-collection/copyright/provider";
import {
  CopyrightCloumms,
  IExportCopyrightToExcel,
} from "../../../models/copyright-collection/copyright/request";
import CopyrightContext from "../../../context/copyright-collection/copyright/context";
interface IProps {
  children?: React.ReactNode;
}

const Copyright: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(CopyrightContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("copyright")}
        subTitle={t("copyright_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/copyright/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("create_copyright")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="copyright-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <CopyrightFilter />
      </SearchFilterCard>
      <CopyrightTable />
      <ExportToExcelModal
        id="export-drawing"
        title={t("export_drawings_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="40vw"
        defaultValues={
          {
            country: false,
            nameAr: false,
            nameEn: false,
            type: false,
          } as IExportCopyrightToExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={CopyrightCloumms} />
      </ExportToExcelModal>
    </div>
  );
};

const CopyrightPage = () => {
  return (
    <CopyrightContextProvider>
      <CopyrightReservationContextProvider>
        <Copyright />
      </CopyrightReservationContextProvider>
    </CopyrightContextProvider>
  );
};
export default CopyrightPage;
