import { useReducer } from "react";
import EndPoints from "../../../services/end-points";
import IRecordTrademarkContext, { internalState } from "./context";
import { execute } from "../../../utils/helpers/execute";
import reducer from "./reducer";

import TrademarkReservationContext from "./context";
import { IRecordTrademarkDetails, ITrademarkReservationDetails } from "../../../models/trademark-collection/trademark-reservation/response";
import {
  ICreateRecordTrademark,
  ICreateTrademarkReservation,
  IUpdateRecordTrademark,
  IUpdateTrademarkReservation,
} from "../../../models/trademark-collection/trademark-reservation/request";
import { ITrademarkReservationQuery } from "../../../models/trademark-collection/trademark-reservation/query";

export interface IProps {
  children: React.ReactNode;
}
const RecordTrademarkContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (idReservation: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.trademarkRecord.getAllRecord(
          {
            ...state.query,
            filters: [
              ...(state.query?.filters ?? []),
              {
                name: "reservationId",
                value: idReservation,
                operation: "eq",
              },
            ],
          }
        );

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.trademarkRecord.getRecord(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };


  const setDetails = async (data?: IRecordTrademarkDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createRecord = async (request: ICreateRecordTrademark) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.trademarkRecord.createRecord(request);
        getData(request.reservationId);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateRecord = async (
    id: number,
    request: IUpdateRecordTrademark
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.trademarkRecord.updateRecord(
          id,
          request
        );

        getData(request.reservationId);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteRecord = async (
    id: number, idReservation: number
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.trademarkRecord.deleteRecord(id);
        getData(idReservation);
      },
      fallback: (error) => { },
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ITrademarkReservationQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <IRecordTrademarkContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createRecord,
          updateRecord,
          deleteRecord,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IRecordTrademarkContext.Provider>
  );
}; RecordTrademarkContextProvider;

export default RecordTrademarkContextProvider;
