import { ColumnProps } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import Tag from "../../../general/antd/tag";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useModal } from "react-modal-hook";
import AppContext from "../../../../context/app/context";

import { Col, Typography } from "antd";
import CopyrightReservationContext from "../../../../context/copyright-collection/copyright-reservation/context";
import { IUpdateCopyrightReservation } from "../../../../models/copyright-collection/copyright-reservation/request";
import { ICopyright } from "../../../../models/copyright-collection/copyright/response";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../models/patents-collection/patents-reservation/enum";
import { IPatentsReservation } from "../../../../models/patents-collection/patents-reservation/response";
import EndPoints from "../../../../services/end-points";
import FieldBuilder from "../../../form-components/field-builder";
import FormHookModal from "../../../general/modals/form-hook-modal";
import TransferBtn from "../../../general/table-components/actions/archive-btn";
import CopyrightReservationForm from "../copyright-reservation-form";
const { Title } = Typography;

interface IProps {}

const CopyrightReservationTabel: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { list, details, actions, loading, query } = useContext(
    CopyrightReservationContext
  );

  const [infoId, setInfId] = useState<number>();
  const { id } = useParams();
  const [idReservetion, setIdReservetion] = useState<number>();

  const { direction } = useContext(AppContext);

  useEffect(() => {
    actions.getData(Number(id));
  }, [direction, query]);

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={
          loading.includes("create") ||
          loading.includes("update") ||
          loading.includes("details")
        }
        title={
          !details
            ? t("add_copyright_reservation")
            : t("update_copyright_reservation")
        }
        hideModal={() => {
          setInfId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        width={"60vw"}
        style={{ marginTop: "1px" }}
        onSubmit={async (data) => {
          details
            ? await actions.updateCopyrightReservation(infoId, data)
            : await actions.createCopyrightReservation(data);
          setInfId(undefined);
          actions.setDetails(undefined);
          actions.getData(Number(id));
        }}
        defaultValues={
          {
            copyrightId: Number(id),
            certificateFee: details?.certificateFee,
            countryId: details?.country?.id,
            depositDate: details?.depositDate,
            depositNumber: details?.depositNumber,
            financialFee: details?.financialFee,
            registrationDate: details?.registrationDate,
            registrationNumber: details?.registrationNumber,
            status: details?.status,
          } as IUpdateCopyrightReservation
        }
      >
        <CopyrightReservationForm />
      </FormHookModal>
    ),
    [loading, details, infoId]
  );

  // archive reservation
  const [copyright, setCopyright] = useState<ICopyright[]>([]);
  const [copyrightLoading, setCopyrightLoading] = useState(false);
  useEffect(() => {
    const getDrawing = async () => {
      try {
        setCopyrightLoading(true);

        const { data } = await EndPoints.copyright.getAllCopyright({
          perPage: -1,
        });
        const copyright = data?.data?.filter((tr) => tr?.id !== Number(id));
        setCopyright(copyright);
        setCopyrightLoading(false);
      } catch (error) {
      } finally {
        setCopyrightLoading(false);
      }
    };
    getDrawing();
  }, [idReservetion]);

  const [showFormTransferModal, hideTransferFormodal] = useModal(
    () => (
      <FormHookModal
        id="1"
        confirmButtonText={t("save")}
        loading={false}
        title={t("transfer_reservation_copyright")}
        style={{ marginTop: "2rem" }}
        hideModal={() => {
          hideTransferFormodal();
          setIdReservetion(undefined);
        }}
        width={"40vw"}
        onSubmit={async (data) => {
          await EndPoints.copyrightReservation.transferCopyrightReservation(
            idReservetion,
            {
              copyrightId: data?.copyrightId,
            }
          );
          setIdReservetion(undefined);

          actions.getData(Number(id));
        }}
        defaultValues={{} as any}
      >
        <Title level={5}>
          {t(`${"transfer_reservation_copyright_content"}`)}:
        </Title>
        <Row gutter={[16, 8]}>
          <Col span={24}>
            <FieldBuilder
              name="copyrightId"
              label={`${t("copyright")}`}
              width="large"
              rules={{ required: true }}
              input={{
                type: "select",

                loading: copyrightLoading,
                options: copyright.map((draw, index) => {
                  return {
                    label: draw.name as string,
                    value: draw.id as number,
                  };
                }),
              }}
            />
          </Col>
        </Row>
      </FormHookModal>
    ),
    [idReservetion]
  );

  const columns: ColumnProps<IPatentsReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },

    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getPatentsReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },

    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },

    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      align: "center",
      key: "registrationNumber",
    },
    {
      title: t("actions"),
      dataIndex: "a",

      align: "center",
      key: "x",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              navigate(`resevation/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              setInfId(record?.id);
              showFormModal();
            }}
          />
          <TransferBtn
            onClick={async () => {
              setIdReservetion(record?.id);
              showFormTransferModal();
            }}
          />
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteCopyrightReservation(record?.id);
              eventManager.emit(EVENT_SUCCESS);
              actions.getData(Number(id));
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Row style={{ marginBottom: "15px" }}>
        <Button
          onClick={() => {
            setInfId(undefined);
            showFormModal();
          }}
          icon={<PlusOutlined />}
          type="primary"
          title={`${t("add_copyright_reservation")}`}
        >
          {t("add_copyright_reservation")}
        </Button>
      </Row>
      <Table<any>
        rowKey="id"
        size="small"
        showPagination={true}
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
        scroll={{ x: 400 }}
      />
    </>
  );
};

export default CopyrightReservationTabel;
