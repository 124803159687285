import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { INodeCanMove } from "../../../models/trademark-collection/trademark-request/response";
import { ICopyrightRequestQuery } from "../../../models/copyright-collection/copyright-request/query";
import {
  ICopyrightRequest,
  ICopyrightRequestDetails,
} from "../../../models/copyright-collection/copyright-request/response";
import {
  ICopyrightRequestExportExcel,
  ICreateCopyrightRequest,
  IUpdateCopyrightRequest,
} from "../../../models/copyright-collection/copyright-request/request";
import { ICopyrightReservationDetails } from "../../../models/copyright-collection/copyright-reservation/response";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class CopyrightRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRequests = (query: ICopyrightRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICopyrightRequest>>({
      path: "/copyright-requests",
      method: "GET",
      query,
      ...params,
    });

  createCopyrightRequests = (
    data: ICreateCopyrightRequest,
    params?: RequestParams
  ) =>
    this.http.request<ICreateCopyrightRequest>({
      path: "/copyright-requests",
      method: "POST",
      body: data,
      ...params,
    });

  deleteRequest = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/copyright-requests/${id}`,
      method: "DELETE",
      ...params,
    });

  updateCopyrightRequest = (
    id: number,
    data: IUpdateCopyrightRequest,
    params?: RequestParams
  ) =>
    this.http.request<ICopyrightRequest>({
      path: `/copyright-requests/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getCopyrightRequest = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightRequestDetails>({
      path: `/copyright-requests/${id}`,
      method: "GET",
      ...params,
    });

  getCopyrightReservations = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICopyrightReservationDetails>>({
      path: `/copyright-requests/get-Reservations/${id}`,
      method: "GET",
      ...params,
    });

  addCopyrightReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/copyright-requests/add-Reservations/${id}`,
      method: "PUT",
      body: data,
    });

  moveCopyrightReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/copyright-requests/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });

  deleteCopyrightReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/copyright-requests/deattach-Reservations/${id}`,
      method: "DELETE",
      body: data,
    });

  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/copyright-requests/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (
    data: ICopyrightRequestExportExcel,
    query: ICopyrightRequestQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/copyright-requests/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/copyright-requests/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
}

export default CopyrightRequestService;
