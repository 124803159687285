import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import AppContext from "../../../../../context/app/context";
import DetailsPage from "../../../../form-components/view-details";
import Descriptions from "../../../../general/antd/descriptions";
import Spin from "../../../../general/antd/spin";

import moment from "moment";
import { ImCheckmark, ImCross } from "react-icons/im";
import {
  getRequestStatus,
  getRequestStatusColor,
} from "../../../../../models/node/enum";

import Tag from "../../../../general/antd/tag";

import DrawingRequestContext from "../../../../../context/drawing-collection/drawing-request/context";
import DrawingRequestContextProvider from "../../../../../context/drawing-collection/drawing-request/provider";
import DrawingTimeLine from "../../../time-line";
import DrawingClientPaymentContextProvider from "../../../../../context/drawing-collection/client-payments/provider";
import DrawingClientPaymentTable from "../../clients-payments/client-payment-table";
import DrawingOfficeCostContextProvider from "../../../../../context/drawing-collection/office-cost/provider";
import DrawingOfficeCostTable from "../../office-cost/office-cost-table";
import DrawingFilesTable from "../../files/table";
import DrawingRequestFilesContextProvider from "../../../../../context/drawing-collection/drawing-request-files/provider";
import DrawingReservationTable from "../../reservations/table";
import {
  getBooleanDrawinOrderTemplate,
  getDrawingOrderTemplateColor,
} from "../../../../../models/workflow/enms-workflow/drawing";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
} from "../../../../../models/workflow/enms-workflow/all-enums";
import { getDrawingAttachmentsString } from "../../../../../models/drawing-collection/drawing-request/enum";

const Details = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(DrawingRequestContext);
  const { direction } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  return (
    <DetailsPage title={t("details_drawing_orders")}>
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("details_drawing_orders")} key="1">
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.depositNumber}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("drawing_orders_templates")}
                    >
                      {details?.workflow?.name ? details?.workflow?.name : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type_oreder_template")}>
                      {details?.workflow?.type ? (
                        <Tag
                          title={getAllBooleanOrderTemplate(
                            details?.workflow?.type
                          )}
                          color={getAllOrderTemplateColor(
                            details?.workflow?.type
                          )}
                        />
                      ) : (
                        "-"
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item
                      label={t("industrial_models_and_drawings")}
                    >
                      {details?.drawings.length > 0 ? (
                        details?.drawings?.map((drawing) => (
                          <Tag title={drawing.name} />
                        ))
                      ) : (
                        <Tag title="not_found" color="red" />
                      )}
                      {/* {details?.drawings[0]?.name} */}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("clients")}>
                      {details?.clients.length > 0 ? (
                        details?.clients?.map((client) => (
                          <Tag title={client.name} />
                        ))
                      ) : (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("request_status")}>
                      <Tag
                        title={getRequestStatus(Number(details?.status))}
                        color={getRequestStatusColor(Number(details?.status))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("country")}>
                      <Tag title={details?.workflow?.country?.name ?? "-"} />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("current_node")}>
                      {details?.currentNode?.name?.name ?? (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("created_at")}>
                      {moment(details?.createdAt).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("request_submitting_date")}>
                      {moment(details?.officialDate).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    {/* <AntdDescriptions.Item label={t("trademark")}>
                      {details?.trademark?.name}
                    </AntdDescriptions.Item> */}
                    <AntdDescriptions.Item label={t("first_publish_number")}>
                      {details?.firstPublishNumber ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("first_publish_date")}>
                      {details?.firstPublishDate
                        ? moment(details?.firstPublishDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("second_publish_number")}>
                      {details?.secondPublishNumber ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("second_publish_date")}>
                      {details?.secondPublishDate
                        ? moment(details?.secondPublishDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("client_order_cost")}>
                      {details?.clientCost ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("sum_client_payments")}>
                      {details?.clientPayment ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("sum_office_costs")}>
                      {details?.officeCosts ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("with_fine")}>
                      {details?.withFine ? (
                        <ImCheckmark color="green" />
                      ) : (
                        <ImCross color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("fine")}>
                      {details?.fine ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("total_request_cost")}>
                      {details?.totalCost}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_date")}>
                      {details?.registrationDate
                        ? moment(details?.registrationDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_number")}>
                      {details?.registrationNumber}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("name_country")}>
                      {details?.nameCountry}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("number_and_date_priority_right")}>
                      {details?.numberAndDatePriorityRight}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("attachments")}>
                      <Tag
                        title={getDrawingAttachmentsString(Number(details?.attachments))}
                        color={getDrawingOrderTemplateColor(Number(details?.attachments))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_foreign_countries")}>
                      {details?.registrationForeignCountries ? t("registration_foreign_countries") : t("not_registration_foreign_countries _not_is_used") }
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("drawing_is_used")}>
                      {details?.drawingIsUsed ? t("drawing_is_used") : t("drawing_not_used")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("reservations_drawing")} key={1}>
                  <DrawingReservationTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={8}>
              <Collapse>
                <Panel header={t("time_line_request")} key={1}>
                  <DrawingTimeLine />
                </Panel>
              </Collapse>
            </Col>
            <Col span={16}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("client_payments")} key={1}>
                      <DrawingClientPaymentTable />
                    </Panel>
                  </Collapse>
                </Col>
                <Col />
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("office_cost")} key={1}>
                      <DrawingOfficeCostTable />
                    </Panel>
                  </Collapse>
                </Col>
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("files")} key={1}>
                      <DrawingFilesTable />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </DetailsPage>
  );
};

const DrawingRequsetDetailsPage = () => {
  return (
    <DrawingRequestContextProvider>
      <DrawingRequestFilesContextProvider>
        <DrawingOfficeCostContextProvider>
          <DrawingClientPaymentContextProvider>
            <Details />
          </DrawingClientPaymentContextProvider>
        </DrawingOfficeCostContextProvider>
      </DrawingRequestFilesContextProvider>
    </DrawingRequestContextProvider>
  );
};
export default DrawingRequsetDetailsPage;
