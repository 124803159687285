import { useReducer } from "react";

import {
  ICreateDrawingRequestFiles,
  IUpdateDrawingRequestFiles,
} from "../../../models/drawing-collection/drawing-requset-files/request";
import { IDrawingRequesFilesDetails } from "../../../models/drawing-collection/drawing-requset-files/response";
import { ITrademarkRequestFilesQuery } from "../../../models/trademark-collection/trademark-requset-files/query";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import DrawingRequestFilesContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const DrawingRequestFilesContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.drawingRequestFile.getAllDrawingRequsetFiles({
            ...state.query,
            filters: [
              {
                name: "DrawingRequest",
                operation: "eq",
                value: id,
              },
            ],
          });
        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.drawingRequestFile.getDrawingRequsetFile(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IDrawingRequesFilesDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createDrawingRequsetFile = async (
    request: ICreateDrawingRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.drawingRequestFile.createDrawingRequsetFile(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateDrawingRequsetFile = async (
    id: number,
    request: IUpdateDrawingRequestFiles
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.drawingRequestFile.updateDrawingRequsetFile(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteDrawingRequsetFile = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.drawingRequestFile.deleteDrawingRequsetFile(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: ITrademarkRequestFilesQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <DrawingRequestFilesContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteDrawingRequsetFile,
          createDrawingRequsetFile,

          updateDrawingRequsetFile,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </DrawingRequestFilesContext.Provider>
  );
};

export default DrawingRequestFilesContextProvider;
