import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Input, List, Popover, Typography } from "antd";
import FormItem from "../../general/form-item";
import styles from "./style.module.scss";
import AppContext from "../../../context/app/context";
import Checkbox from "antd/lib/checkbox";
import { Link } from "react-router-dom";
import AuthContext from "../../../context/auth/context";
import { languages } from "../../../utils/costants";
import { FiGlobe } from "react-icons/fi";

const LoginForm = () => {
  const { direction } = useContext(AppContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues
  } = useForm();
  const { t, i18n } = useTranslation();
  const { Title } = Typography;
  const { actions, loading } = useContext(AuthContext);
  const [languagePopoverVisible, setLanguagePopoverVisible] = useState(false);
  
  const onSubmit = (data: any) => {
    actions.login(data);
  };

  const forgetPass = () => {
    actions.forgetPassword({ email: getValues('email') })
  }
  return (
    <div className={styles.content}>
      <form dir={direction} onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>
            <strong>{t("login")}</strong>{" "}
          </h2>
          {/* Languages */}
          {languages?.length > 0 && (
            <Popover
              open={languagePopoverVisible}
              onOpenChange={setLanguagePopoverVisible}
              placement="bottom"
              content={
                <>
                  <List
                    size="small"
                    dataSource={languages}
                    renderItem={(language) => (
                      <List.Item
                        className={styles.cursorPointer}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          i18n?.changeLanguage(language.code);
                          setLanguagePopoverVisible(false);
                        }}
                      >
                        <span className={styles.mStart05}>{language.name}</span>
                      </List.Item>
                    )}
                  />
                </>
              }
              trigger="click"
            >
              <Button
                className={styles.verticalMiddle}
                shape="circle"
                icon={<FiGlobe />}
                type="text"
                size="large"
              />
            </Popover>
          )}
        </div>
        <FormItem key={1} label={`${t("email")}`} required>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <>
                  <Input {...field} />
                </>
              );
            }}
          />
        </FormItem>
        <FormItem key={2} label={`${t("password")}`} required>
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <>
                  <Input.Password {...field} />
                </>
              );
            }}
          />
        </FormItem>
        <Controller
          name="remember"
          control={control}
          rules={{ required: false }}
          render={({ field }) => {
            return (
              <>
                <Checkbox className={styles.checkbox} {...field}>
                  {t("remember_me")}
                </Checkbox>
              </>
            );
          }}
        />
        <div className={styles.button}>
          <Button
            loading={loading.includes("login")}
            htmlType="submit"
            type="primary"
            style={{ width: "50%" }}
          >
            {t("login")}
          </Button>
        </div>
        <Link to="/reset-password" onClick={forgetPass} className={styles.forgetPass}>
          {t("forget_password")}
        </Link>
      </form>
    </div>
  );
};

export default LoginForm;
