import { ColumnProps } from "antd/lib/table";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ISort, IStaticFilter } from "../../../../models/base/base-query";
import eventManager, { EVENT_SUCCESS } from "../../../../utils/events";
import { tableOnChange } from "../../../../utils/helpers/table-sorts-filters";
import Space from "../../../general/antd/space";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import ViewBtn from "../../../general/table-components/actions/view-btn";
import Table from "../../../general/table-components/table";
import ObjectionRequestContext from "../../../../context/independent_requests-collection/objection-request/context";
import { IObjectionRequest } from "../../../../models/independent-requests-collection/objection-request/response";
import { Button, Tag, Tooltip } from "antd";
import { FilePdfOutlined, FileWordOutlined } from "@ant-design/icons";
import { useModal } from "react-modal-hook";
import FormHookModal from "../../../general/modals/form-hook-modal";
import { IMoveTrademrk } from "../../../../models/trademark-collection/move-trademark-request/request";
import EndPoints from "../../../../services/end-points";
import MoveObjectionRequest from "../move-patents/form";
import MoveBtn from "../../../general/table-components/actions/move-btn";

interface IProps {}

const ObjectionRequestTable: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { list, details, actions, loading, query } = useContext(
    ObjectionRequestContext
  );

  const [infoId, setInfoId] = useState<number>(undefined);

  const [showFormModal, hideFormodal] = useModal(
    () => (
      <FormHookModal
        id="ss"
        confirmButtonText={t("save")}
        loading={loading.includes("details") || loading.includes("update")}
        title={t("move_trademark_request_to_another_node")}
        hideModal={() => {
          setInfoId(undefined);
          actions.setDetails(undefined);
          hideFormodal();
        }}
        style={{ marginTop: "-4rem", maxHeight: "90vh", overflowY: "scroll" }}
        defaultValues={
          {
            officialDate: null,
          } as IMoveTrademrk
        }
        width={"90vw"}
        onSubmit={async (data) => {
          await EndPoints.objectionRequest.moveObjectionReseqestNode(
            infoId,
            data
          );
          setInfoId(undefined);
          actions.getData();
        }}
      >
        {infoId && <MoveObjectionRequest id={infoId} />}
      </FormHookModal>
    ),
    [details, loading, infoId]
  );

  useEffect(() => {
    infoId && actions.getDetails(infoId);
  }, [infoId]);

  const columns: ColumnProps<IObjectionRequest>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      sorter: {
        multiple: 32,
      },
      width: 100,
      align: "center",
    },
    {
      title: t("order_value"),
      dataIndex: "orderValue",
      align: "center",
      key: "orderValue",
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      key: "depositNumber",
      align: "center",
      render: (_, record) => record?.depositNumber ?? "-",
    },
    {
      title: t("country"),
      key: "country",
      align: "center",
      render: (_, record) => record?.workflow?.country?.name ?? "-",
    },
    {
      title: t("client_objecting"),
      dataIndex: "clients",
      align: "center",
      key: "clients",
      render: (_, record) => record?.clients?.map((c) => <>{c?.name}</>) ?? "-",
    },
    {
      title: t("client_payments"),
      dataIndex: "sumPayments",
      align: "center",
      key: "country",
    },
    {
      title: t("sum_costs"),
      dataIndex: "sumCosts",
      align: "center",
      key: "sumCosts",
    },
    {
      title: t("objecting_party"),
      dataIndex: "objectingParty",
      align: "center",
      key: "objectingParty",
    },
    {
      title: t("objecting_request"),
      dataIndex: "objectingRequest",
      align: "center",
      key: "objectingRequest",
      width: 150,
    },

    {
      title: t("subject"),
      dataIndex: "subject",
      key: "subject",
      sorter: {
        multiple: 1,
      },
      align: "center",
    },
    {
      title: t("official_date"),
      dataIndex: "officialDate",
      key: "officialDate",
      sorter: {
        multiple: 2,
      },
      align: "center",
      render: (officialDate: Date) =>
        officialDate ? moment(officialDate).format("DD/MM/YYYY") : "-",
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",

      key: "createdAt",
      sorter: {
        multiple: 8,
      },
      align: "center",
      render: (createdAt: Date) =>
        createdAt ? moment(createdAt).format("DD/MM/YYYY") : "-",
    },

    {
      title: t("actions"),
      width: "20%",
      align: "center",
      key: "x",
      fixed: "right",
      render: (_: any, record) => (
        <Space>
          <ViewBtn
            onClick={async () => {
              const currentPath = window.location.pathname.split("?")[0];

              const url = `${window.location.origin}${currentPath}/details/${record?.id}`;

              window.open(url, "_blank");
              // navigate(`details/${record?.id}`);
            }}
          />
          <EditBtn
            onClick={async () => {
              navigate(`update/${record?.id}`);
            }}
          />
          <MoveBtn
            onClick={async () => {
              await setInfoId(record.id);
              await showFormModal();
            }}
          />
          <Tooltip title={t("export_pdf")}>
            <Button
              type="dashed"
              shape="circle"
              loading={loading.includes("create")}
              onClick={() => actions.exportPdf(record?.id)}
              // onClick={() => download(record.id)}
              icon={<FilePdfOutlined style={{ color: "#ff3f3f" }} />}
            />
          </Tooltip>
          {/* <Tooltip title={t("export_word")}>
            <Button
              type="dashed"
              shape="circle"
              loading={loading.includes("create")}
              onClick={() => actions.exportPdf(record?.id)}
              icon={<FileWordOutlined style={{ color: "#0000ff" }} />}
            />
          </Tooltip> */}
          <DeleteBtn
            onConfirm={async () => {
              await actions.deleteObjectionRequest(record?.id);
              eventManager.emit(EVENT_SUCCESS);
            }}
            loading={loading.includes("delete")}
          />
        </Space>
      ),
    },
  ];

  // Filters && Sorts
  let tableFiltersProps = {};
  const [sorts, setSorts] = useState<ISort[]>([]);
  const [, setStaticFilters] = useState<IStaticFilter[]>(query?.filters ?? []);

  useEffect(() => {
    sorts.length > 0 &&
      actions.setQuery({
        ...query,
        orders: sorts,
      });
  }, [sorts]);

  return (
    <>
      <Table<any>
        rowKey="id"
        showPagination={true}
        size="small"
        columns={columns}
        dataSource={list?.data ?? []}
        loading={loading.includes("list")}
        total={list?.total}
        pageSize={query.perPage}
        scroll={{ x: 1700 }}
        page={query.page}
        onPaginationChange={(page, pageSize) => {
          actions.setQuery({
            ...query,
            page,
            perPage: pageSize,
          });
        }}
        onChange={(_, tableFilters, tabelSorters) => {
          tableOnChange(
            tableFilters,
            tabelSorters,
            tableFiltersProps,
            setSorts,
            setStaticFilters
          );
        }}
      />
    </>
  );
};

export default ObjectionRequestTable;
