import { ITranslationRequest } from "../../base/translation";
export interface ICreateCertifiedCopy {
  officialDate: string;
  dateFinancialReceipt: string;
  depositNumber: string;
  financialReceiptNumber: string;
  details: string;
  note: string;
  clientId: number;
  countryId: number;
  orderValue: number;
  clientPayments: number;
}

export interface IUpdateCertifiedCopy extends ICreateCertifiedCopy {}

// to do
export interface IExportCertifiedCopyExcel {
  depositNumber: boolean;
  country: boolean;
  client: boolean;
  createdAt: boolean;
  officialDate: boolean;
  dateFinancialReceipt: boolean;
  financialReceiptNumber: boolean;
}

export const CertifiedCopyCloumms = [
  "depositNumber",
  "country",
  "client",
  "createdAt",
  "officialDate",
  "dateFinancialReceipt",
  "financialReceiptNumber",
];
