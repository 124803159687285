import { createContext } from "react";

import { IIncidentsWaiverRequestFilesQuery } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/query";
import {
  ICreateIncidentsWaiverRequestFiles,
  IUpdateIncidentsWaiverRequestFiles,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/request";
import {
  IIncidentsRequesWaiverFilesDetails,
  IIncidentsWaiverRequesFiles,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";

export type TrademarkRequestFilesLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: TrademarkRequestFilesLoading[];

  list?: IBaseListingResponse<IIncidentsWaiverRequesFiles>;
  query: IIncidentsWaiverRequestFilesQuery;

  details?: IIncidentsRequesWaiverFilesDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIncidentsRequesWaiverFilesDetails) => void;

    createIncidentRequsetFile: (
      request: ICreateIncidentsWaiverRequestFiles
    ) => void;
    updateIncidentRequsetFile: (
      id: number,
      request: IUpdateIncidentsWaiverRequestFiles
    ) => void;
    deleteIncidentRequsetFile: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IIncidentsWaiverRequestFilesQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createIncidentRequsetFile: DEFAULT_FUNCTION,
    updateIncidentRequsetFile: DEFAULT_FUNCTION,
    deleteIncidentRequsetFile: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentWaiverRequestFilesContext = createContext(externalState);

export default IncidentWaiverRequestFilesContext;
