import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import CostTypeTable from "../../../components/managment-collection/cost-type/cost-type-table";
import AppContext from "../../../context/app/context";
import CostTypeContext from "../../../context/management-collection/cost-type/context";
import CostTypeContextProvider from "../../../context/management-collection/cost-type/provider";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
import { useIsMount } from "../../../utils/hooks/is-mount";

interface IProps {
  children?: React.ReactNode;
}

// sum array in js
const CostType: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { actions, loading, query } = useContext(CostTypeContext);


  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  useEffect(() => {
    actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("cost_type")}
        subTitle={t("cost_type_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/types-of-costs/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_cost_type")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="jop-title-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {}}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      ></SearchFilterCard>
      <CostTypeTable />
    </div>
  );
};

const CostTypePage = () => {
  return (
    // ContextProvider
    <CostTypeContextProvider>
      <CostType />
    </CostTypeContextProvider>
  );
};
export default CostTypePage;
