export enum BooleanStatus {
  PROTECTEDD = 1,
  NEGATIVELY_PROTECTED = 2,
  NOTPROTECTED = 3,
  ARCHIVED = 4,
}

export enum BooleanChangeType {
  AUTO = 1,
  MANUAL = 2,
  BY_REQUEST = 3,
}


export const getTrademarkReservationColor = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.PROTECTEDD:
      return "blue";
    case BooleanStatus.NEGATIVELY_PROTECTED:
      return "orange";
    case BooleanStatus.NOTPROTECTED:
      return "green";
    case BooleanStatus.ARCHIVED:
      return "gray";
  }
};

// Status Boolean stuts
export const getBooleanTrademarkReservation = (status: BooleanStatus) => {
  switch (status) {
    case BooleanStatus.PROTECTEDD:
      return "protected";
    case BooleanStatus.NEGATIVELY_PROTECTED:
      return "negatively_protected";
    case BooleanStatus.NOTPROTECTED:
      return "not_protected";
    case BooleanStatus.ARCHIVED:
      return "archived";
  }
};

export const getTrademarkChangeType = (status: BooleanChangeType) => {
  switch (status) {
    case BooleanChangeType.AUTO:
      return "auto";
    case BooleanChangeType.BY_REQUEST:
      return "by_request";
    case BooleanChangeType.MANUAL:
      return "manual";
  }
};

export const getTrademarkChangeTypeColor = (status: BooleanChangeType) => {
  switch (status) {
    case BooleanChangeType.AUTO:
      return "blue";
    case BooleanChangeType.BY_REQUEST:
      return "orange";
    case BooleanChangeType.MANUAL:
      return "green";
  }
};

export  enum IRecordType {
   DISCRIMINATION = 1,
   OBJECTION = 2,
   REGISTER = 3,
   RE_REGISTER = 4,
   RENEWAL = 5,
   REPORT = 6,
}

export const getIRecordTypeColor = (type: IRecordType) => {
  switch (type) {
    case IRecordType.DISCRIMINATION:
      return "blue";
    case IRecordType.OBJECTION:
      return "orange";
    case IRecordType.REGISTER:
      return "green";
    case IRecordType.RE_REGISTER:
      return "gray";
      case IRecordType.RENEWAL:
      return "brown";
      case IRecordType.REPORT:
      return "pink";
  }
};

// Status Boolean stuts
export const getIRecordType = (type: IRecordType) => {
  switch (type) {
    case IRecordType.DISCRIMINATION:
      return "discrimination";
    case IRecordType.OBJECTION:
      return "objection";
    case IRecordType.REGISTER:
      return "register";
    case IRecordType.RE_REGISTER:
      return "re_register";
      case IRecordType.RENEWAL:
      return "renewal";
      case IRecordType.REPORT:
      return "report";
  }
};