import { ICategoryQuery } from "../../../models/managent-collection/category/query";
import {
  ICategory,
  ICategoryDetails,
} from "../../../models/managent-collection/category/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";
import { CategoryLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: CategoryLoading | CategoryLoading[] };
    }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<ICategory> } }
  | {
      type: "SET_SUB_CATEGORIES";
      payload: { subCategories: IBaseListingResponse<ICategory> };
    }
  | {
      type: "SET_LIST_LIGHT";
      payload: { light: IBaseListingResponse<ICategory> };
    }
  | { type: "SET_DETAILS"; payload: { details?: ICategoryDetails } }
  | QueryAction<ICategoryQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }
    case "SET_LIST_LIGHT": {
      return {
        ...state,
        list: action.payload.light,
      };
    }
    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }
    case "SET_SUB_CATEGORIES": {
      return {
        ...state,
        subCategories: action.payload.subCategories,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
