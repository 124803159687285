import { createContext } from "react";

import {
  IIIncidentsClientsPayment,
  IIIncidentsClientsPaymentDetails,
} from "../../../../models/incidents-managments/incidents-clients/client-payments/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { IIncidentsClientsPaymentsQuery } from "../../../../models/incidents-managments/incidents-clients/client-payments/query";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  ICreateIncidentsClientsPayment,
  IUpdateIncidentsClientsPayment,
} from "../../../../models/incidents-managments/incidents-clients/client-payments/request";
import { IClientPaymentsQuery } from "../../../../models/trademark-collection/client-payments/query";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";

export type ClientPaymentLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: ClientPaymentLoading[];

  list?: IBaseListingResponse<IIIncidentsClientsPayment>;
  query: IIncidentsClientsPaymentsQuery;

  details?: IIIncidentsClientsPaymentDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IIIncidentsClientsPaymentDetails) => void;

    createClientPayment: (request: ICreateIncidentsClientsPayment) => void;
    updateClientPayment: (
      id: number,
      request: IUpdateIncidentsClientsPayment
    ) => void;
    deleteCleintPayment: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IClientPaymentsQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createClientPayment: DEFAULT_FUNCTION,
    updateClientPayment: DEFAULT_FUNCTION,
    deleteCleintPayment: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IncidentsClientsPaymentContext = createContext(externalState);

export default IncidentsClientsPaymentContext;
