import { IDrawingOfficeCostQuery } from "../../../models/drawing-collection/office-cost/query";
import {
  ICreateDrawingOfficeCost,
  IUpdateDrawingOfficeCost,
} from "../../../models/drawing-collection/office-cost/request";
import {
  IDrawingOfficeCost,
  IDrawingOfficeCostDetails,
} from "../../../models/drawing-collection/office-cost/response";
import { IUpdateOfficeCost } from "../../../models/trademark-collection/office-cost/request";
import { IOfficeCost } from "../../../models/trademark-collection/office-cost/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class DrawingOfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (
    query: IDrawingOfficeCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IDrawingOfficeCost>>({
      path: `/drawing-request-costs`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingOfficeCostDetails>({
      path: `/drawing-request-costs/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (data: ICreateDrawingOfficeCost, params?: RequestParams) =>
    this.http.request<IOfficeCost>({
      path: "/drawing-request-costs",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdateDrawingOfficeCost,
    params?: RequestParams
  ) =>
    this.http.request<IDrawingOfficeCost>({
      path: `/drawing-request-costs/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingOfficeCost>({
      path: `/drawing-request-costs/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default DrawingOfficeCostService;
