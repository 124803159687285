import type { TabsProps } from "antd";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Input,
  Row,
  Select,
  Tabs,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { ColumnProps } from "antd/es/table";
import dayjs from "dayjs";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import IncidentsLicensingsContext from "../../../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/context";
import IncidentsLicensingContextProvider from "../../../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/provider";
import { IClient } from "../../../../../models/client/response";
import { ICopyrightReservation } from "../../../../../models/copyright-collection/copyright-reservation/response";
import { IDrawingReservation } from "../../../../../models/drawing-collection/drawing-reservation/response";
import { IUpdateIncidentsClients } from "../../../../../models/incidents-managments/incidents-clients/incidents-clients-requests/request";
import { IUpdateIncidentsLicensing } from "../../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/request";
import { getIncidentsWaiverName } from "../../../../../models/incidents-managments/incidents-waiver-request/incidents-waiver-requests/enum";
import { ICountry } from "../../../../../models/managent-collection/user/response";
import { getRequestStatus } from "../../../../../models/node/enum";
import {
  getBooleanPatensReservation,
  getPatentsReservationColor,
} from "../../../../../models/patents-collection/patents-reservation/enum";
import {
  IPatentsReservation,
  IPatentsReservationDetails,
} from "../../../../../models/patents-collection/patents-reservation/response";
import {
  getBooleanTrademarkReservation,
  getTrademarkReservationColor,
} from "../../../../../models/trademark-collection/trademark-reservation/enum";
import { ITrademarkReservation } from "../../../../../models/trademark-collection/trademark-reservation/response";
import { ITraderMarkOrderTeamplate } from "../../../../../models/workflow/response";
import EndPoints from "../../../../../services/end-points";
import { execute } from "../../../../../utils/helpers/execute";
import Controller from "../../../../form-components/controller";
import RichEditor from "../../../../form-components/rich-text-editor";
import Spin from "../../../../general/antd/spin";
import Tag from "../../../../general/antd/tag";
import FormItem from "../../../../general/form-item";
import Table from "../../../../general/table-components/table";
import styles from "./style.module.scss";
import { getIncidentsLicensingName } from "../../../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/enum";
import {
  getDrawingColor,
  getDrawingType,
} from "../../../../../models/drawing-collection/drawing/enum";
import { ImCheckmark, ImCross } from "react-icons/im";
import {
  getBooleanDrawingReservation,
  getDrawingReservationColor,
} from "../../../../../models/drawing-collection/drawing-reservation/enum";
import {
  getCopyrightColor,
  getCopyrightType,
} from "../../../../../models/copyright-collection/copyright/enum";
import {
  getPatentsColor,
  getPatentsType,
} from "../../../../../models/patents-collection/patents/enum";
import { IUpdateGrievance } from "../../../../../models/lawswits-collection/grievance/grievance-requests/request";
import GrievanceContextProvider from "../../../../../context/lawswits-collection/grievance/grievance/provider";
import GrievancesContext from "../../../../../context/lawswits-collection/grievance/grievance/context";
const Form = () => {
  const { details, actions, loading } = useContext(GrievancesContext);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const types = [1];
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [patentsOrderTemplates, setPatensOrderTemplate] = useState<
    ITraderMarkOrderTeamplate[]
  >([]);
  const [trademarkOrderTemplateLoading, setPatentsOrderTemplateLoading] =
    useState(true);

  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  const requestStatus = [1, 2, 3];
  const Option = Select.Option;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const methods = useForm<any>({
    defaultValues: {} as IUpdateIncidentsClients,
  });
  const { handleSubmit, reset, control } = methods;

  const countryId = useWatch({
    control,
    name: "country",
  });

  //////////////////////////  reservations ///////////////////////////
  const [trademarkReservation, setTrademarkReservation] = useState<
    ITrademarkReservation[]
  >([]);
  const [loadingtrademarkReservation, setLoadingtrademarkReservation] =
    useState(true);

  const [drawingReservation, setDrawingReservation] = useState<
    IDrawingReservation[]
  >([]);
  const [loadingDrawingReservation, setLoadingDrawingReservation] =
    useState(true);

  const [copyrightReservation, setCopyrightReservation] = useState<
    ICopyrightReservation[]
  >([]);
  const [loadingCopyrightReservation, setLoadingCopyrightReservation] =
    useState(true);
  const [patentsReservation, setPatentsReservation] = useState<
    IPatentsReservation[]
  >([]);
  const [loadingPatentsReservation, setLoadingPatentsReservation] =
    useState(true);

  const selectedClients = useWatch({
    control,
    name: "clients",
  });

  useEffect(() => {
    const getTrademarkReservation = async () => {
      try {
        setLoadingtrademarkReservation(true);
        const { data } =
          await EndPoints.trademarkReservation.getAllTrademarksReservation({
            perPage: -1,
            filters: [
              {
                name: "status",
                operation: "in",
                value: [1, 2],
              },
              {
                name: "client",
                operation: "in",
                value: selectedClients,
              },
              {
                name: "country",
                operation: "eq",
                value: countryId,
              },
            ],
          });
        setTrademarkReservation(data.data);
      } catch (error) {
      } finally {
        setLoadingtrademarkReservation(false);
      }
    };
    selectedClients?.length > 0 && countryId && getTrademarkReservation();
  }, [selectedClients]);

  useEffect(() => {
    const getDrawingReservation = async () => {
      try {
        setLoadingDrawingReservation(true);
        const { data } =
          await EndPoints.drawingReservation.getAllDrawingsReservation({
            perPage: -1,
            filters: [
              {
                name: "client",
                operation: "in",
                value: selectedClients,
              },
              {
                name: "status",
                operation: "eq",
                value: 1,
              },
              {
                name: "country",
                operation: "eq",
                value: countryId,
              },
            ],
          });
        setDrawingReservation(data.data);
      } catch (error) {
      } finally {
        setLoadingDrawingReservation(false);
      }
    };
    selectedClients?.length > 0 && countryId && getDrawingReservation();
  }, [selectedClients]);

  useEffect(() => {
    const getCopyrightReservation = async () => {
      try {
        setLoadingCopyrightReservation(true);
        const { data } =
          await EndPoints.copyrightReservation.getAllCopyrightsReservation({
            perPage: -1,
            filters: [
              {
                name: "client",
                operation: "in",
                value: selectedClients,
              },
              {
                name: "status",
                operation: "eq",
                value: 1,
              },
              {
                name: "country",
                operation: "eq",
                value: countryId,
              },
            ],
          });
        setCopyrightReservation(data.data);
      } catch (error) {
      } finally {
        setLoadingCopyrightReservation(false);
      }
    };
    selectedClients?.length > 0 && countryId && getCopyrightReservation();
  }, [selectedClients]);

  useEffect(() => {
    const getPatentsReservation = async () => {
      setLoadingPatentsReservation(true);

      try {
        const { data } =
          await EndPoints.patentsReservation.getAllDPatentsReservation({
            perPage: -1,
            filters: [
              {
                name: "client",
                operation: "in",
                value: selectedClients,
              },
              {
                name: "status",
                operation: "eq",
                value: 1,
              },
              {
                name: "country",
                operation: "eq",
                value: countryId,
              },
            ],
          });
        setPatentsReservation(data.data);
      } catch (error) {
      } finally {
        setLoadingPatentsReservation(false);
      }
    };
    selectedClients?.length > 0 && countryId && getPatentsReservation();
  }, [selectedClients]);

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const onSubmit = async (data: any) => {
    await execute({
      callback: async () => {
        setLoadingSubmit(true);
        details
          ? await EndPoints.grievance.updateGrievance(details?.id, {
              ...data,
              patentReservations:
                selectedPatentsReservationRow.length > 0
                  ? selectedPatentsReservationRow
                  : [],
              trademarkReservations:
                selectedTrademarkReservationRow.length > 0
                  ? selectedTrademarkReservationRow
                  : [],
              drawingReservations:
                selectedDrawingReservationRow.length > 0
                  ? selectedDrawingReservationRow
                  : [],
              copyrightReservations:
                selectedCopyrightReservationRow.length > 0
                  ? selectedCopyrightReservationRow
                  : [],
            })
          : await EndPoints.grievance.createGrievance({
              ...data,
              patentReservations:
                selectedPatentsReservationRow.length > 0
                  ? selectedPatentsReservationRow
                  : [],
              trademarkReservations:
                selectedTrademarkReservationRow.length > 0
                  ? selectedTrademarkReservationRow
                  : [],
              drawingReservations:
                selectedDrawingReservationRow.length > 0
                  ? selectedDrawingReservationRow
                  : [],
              copyrightReservations:
                selectedCopyrightReservationRow.length > 0
                  ? selectedCopyrightReservationRow
                  : [],
            });
        navigate(-1);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        setLoadingSubmit(false);
      },
      throwException: false,
    });
  };

  // get all countries in stystem
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    const getPatentsOrderTemplates = async () => {
      try {
        setPatentsOrderTemplateLoading(true);

        const { data } = await EndPoints.trademarkOrderTemplate.getAllWorkFlow({
          perPage: -1,
          filters: [
            {
              name: "countryId",
              operation: "eq",
              value: countryId,
            },
            {
              name: "for",
              operation: "eq",
              value: 6,
            },
          ],
        });
        setPatensOrderTemplate(data.data);
      } catch (error) {
      } finally {
        setPatentsOrderTemplateLoading(false);
      }
    };
    countryId && getPatentsOrderTemplates();
  }, [countryId]);

  useEffect(() => {
    id && actions.getDetails(Number(id));
  }, [id]);

  useEffect(() => {
    details &&
      (setSelectedCopyrightReservationRow(
        details?.copyrightReservations?.map((a) => a.id)
      ),
      setSelectedTrademarkReservationRow(
        details?.trademarkReservations?.map((tr) => tr.id)
      ),
      setSelectedPatentsReservationRow(
        details?.patentReservations?.map((tr) => tr.id)
      ),
      setSelectedDrawingReservationRow(
        details?.drawingReservations?.map((tr) => tr.id)
      ));
  }, [details]);

  // columas of tables reservation
  const columnsPatents: ColumnProps<IPatentsReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { patent }) => {
        return <p> {patent?.name ?? "-"} </p>;
      },
    },
    {
      title: t("patents_or_model"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record?.patent?.type ? (
              <Tag
                color={getPatentsColor(record?.patent.type)}
                title={t(getPatentsType(record.patent?.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanPatensReservation(Number(record))}
            color={getPatentsReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },

    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",
      key: "depositNumber",
    },
    {
      title: t("last_annual_fee_date"),
      dataIndex: "lastAnnualFeeDate",
      align: "center",
      key: "lastAnnualFeeDate",
      render: (_, { lastAnnualFeeDate }) => {
        return (
          <p>
            {lastAnnualFeeDate
              ? moment(lastAnnualFeeDate).format("DD/MM/YYYY")
              : "-"}
          </p>
        );
      },
    },
    {
      title: t("last_annual_fee_number"),
      dataIndex: "lastAnnualFeeNumber",
      align: "center",
      key: "lastAnnualFeeNumber",
    },

    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}
          </p>
        );
      },
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      align: "center",
      key: "registrationNumber",
    },
  ];

  const columnsTrademarkReservaton: ColumnProps<ITrademarkReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",

      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",

      align: "center",
      render: (_, { trademark }) => {
        return <p> {trademark?.name} </p>;
      },
    },
    {
      title: t("main_category"),
      dataIndex: "mainCategory",
      align: "center",
      key: "mainCategory",
      render: (_, { mainCategory }) => {
        return <p> {mainCategory?.number} </p>;
      },
    },
    {
      title: t("all_categories"),
      align: "center",
      dataIndex: "allCategories",
      key: "allCategories",
      render: (_, { allCategories }) => (
        <>
          {allCategories ? (
            <ImCheckmark color="green" />
          ) : (
            <ImCross color="red" />
          )}
        </>
      ),
    },
    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",
      key: "renewalCounts",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanTrademarkReservation(Number(record))}
            color={getTrademarkReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_number"),
      dataIndex: "registrationNumber",
      key: "country",
      align: "center",
      render: (_, record) => {
        return <p> {record?.registrationNumber ?? "-"} </p>;
      },
    },
  ];

  const columnsDrawing: ColumnProps<IDrawingReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, record) => {
        return <p> {record?.drawing?.name} </p>;
      },
    },
    {
      title: t("drawing_type"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.drawing ? (
              <Tag
                color={getDrawingColor(record?.drawing?.type)}
                title={t(getDrawingType(record?.drawing.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("renewal_counts"),
      dataIndex: "renewalCounts",
      align: "center",
      key: "renewalCounts",
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },
    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanDrawingReservation(Number(record))}
            color={getDrawingReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_date"),
      dataIndex: "lastRenewalDate",
      align: "center",

      key: "lastRenewalDate",
      render: (_, { lastRenewalDate }) => {
        return (
          <p>
            {" "}
            {lastRenewalDate
              ? moment(lastRenewalDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_number"),
      dataIndex: "lastRenewalNumber",
      align: "center",
      key: "lastRenewalNumber",
    },
  ];

  const columnsCopyright: ColumnProps<ICopyrightReservation>[] = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_, { copyright }) => {
        return <p> {copyright?.name} </p>;
      },
    },
    {
      title: t("copyright_type"),
      dataIndex: "type",
      key: "status",
      width: 100,
      align: "center",
      render: (_, record) => {
        return (
          <>
            {record.copyright ? (
              <Tag
                color={getCopyrightColor(record.copyright?.type)}
                title={t(getCopyrightType(record?.copyright.type))}
              />
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: t("country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (_, { country }) => {
        return <p> {country?.name} </p>;
      },
    },

    {
      title: t("types"),
      dataIndex: "status",
      align: "center",
      key: "status",
      render: (record) => {
        return (
          <Tag
            title={getBooleanDrawingReservation(Number(record))}
            color={getDrawingReservationColor(Number(record))}
          />
        );
      },
    },
    {
      title: t("deposit_date"),
      dataIndex: "depositDate",
      align: "center",

      key: "depositDate",
      render: (_, { depositDate }) => {
        return (
          <p>
            {" "}
            {depositDate ? moment(depositDate).format("DD/MM/YYYY") : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("deposit_number"),
      dataIndex: "depositNumber",
      align: "center",

      key: "depositNumber",
    },
    {
      title: t("registration_date"),
      dataIndex: "registrationDate",
      align: "center",

      key: "registrationDate",
      render: (_, { registrationDate }) => {
        return (
          <p>
            {" "}
            {registrationDate
              ? moment(registrationDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_date"),
      dataIndex: "lastRenewalDate",
      align: "center",

      key: "lastRenewalDate",
      render: (_, { lastRenewalDate }) => {
        return (
          <p>
            {" "}
            {lastRenewalDate
              ? moment(lastRenewalDate).format("DD/MM/YYYY")
              : "-"}{" "}
          </p>
        );
      },
    },
    {
      title: t("last_renewal_number"),
      dataIndex: "lastRenewalNumber",
      align: "center",
      key: "lastRenewalNumber",
    },
  ];

  // init state for reservations
  const [selectedPatentsReservationRow, setSelectedPatentsReservationRow] =
    useState<number[]>([]);
  const [selectedTrademarkReservationRow, setSelectedTrademarkReservationRow] =
    useState<number[]>([]);
  const [selectedCopyrightReservationRow, setSelectedCopyrightReservationRow] =
    useState<number[]>([]);
  const [selectedDrawingReservationRow, setSelectedDrawingReservationRow] =
    useState<number[]>([]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t("trademark_reservation"),
      children: (
        <>
          <Table
            rowKey="id"
            loading={loadingtrademarkReservation}
            dataSource={trademarkReservation}
            columns={columnsTrademarkReservaton}
            size="small"
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedTrademarkReservationRow,
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedTrademarkReservationRow(
                  selectedRows?.map((selectedRow) => selectedRow.id)
                );
              },
            }}
          />
        </>
      ),
    },
    {
      key: "2",
      label: t("reservations_drawing"),
      children: (
        <>
          <Table
            rowKey="id"
            loading={loadingDrawingReservation}
            dataSource={drawingReservation}
            columns={columnsDrawing}
            size="small"
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedDrawingReservationRow,
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedDrawingReservationRow(
                  selectedRows?.map((selectedRow) => selectedRow.id)
                );
              },
            }}
          />
        </>
      ),
    },

    {
      key: "3",
      label: t("patents_and_utility_models_reservation"),
      children: (
        <>
          <Table
            rowKey="id"
            loading={loadingPatentsReservation}
            dataSource={patentsReservation}
            columns={columnsPatents}
            size="small"
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedPatentsReservationRow,
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedPatentsReservationRow(
                  selectedRows?.map((selectedRow) => selectedRow.id)
                );
              },
            }}
          />
        </>
      ),
    },
    {
      key: "4",
      label: t("copyright_reservation"),
      children: (
        <>
          <Table
            rowKey="id"
            loading={loadingCopyrightReservation}
            dataSource={copyrightReservation}
            columns={columnsCopyright}
            size="small"
            pagination={false}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: selectedCopyrightReservationRow,
              onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                setSelectedCopyrightReservationRow(
                  selectedRows?.map((selectedRow) => selectedRow.id)
                );
              },
            }}
          />
        </>
      ),
    },
  ];

  // set reservation coming from back to satate
  useEffect(() => {
    reset({
      country: details?.workflow?.country?.id,
      workflowId: details?.workflow?.id,
      trademarkReservations: selectedTrademarkReservationRow,
      clients: details?.clients?.map((ass) => ass.id),
      clientCost: details?.clientCost,
      copyrightReservations: selectedCopyrightReservationRow,
      description: details?.description,
      drawingReservations: selectedDrawingReservationRow,
      patentReservations: selectedPatentsReservationRow,
      status: details?.status,
      basisDate: details?.basisDate,
      basisNumber: details?.basisNumber,
    } as IUpdateGrievance);
  }, [details]);

  return (
    <FormProvider {...methods}>
      <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
        {/* Header */}
        <div>
          <span className={styles.title}>
            {details ? t("update_grievance") : t("add_grievance")}
          </span>
          <Divider className={styles.divider} />
          {loading.includes("details") ? (
            <div style={{ height: "60vh" }}>
              <Spin />
            </div>
          ) : (
            <div className={styles.children}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <FormItem label={t("countries")} required>
                    <Controller
                      name="country"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            onDeselect={() => {
                              reset((formValues) => ({
                                ...formValues,
                                workflowId: null,
                              }));
                            }}
                            onSelect={() => {
                              reset((formValues) => ({
                                ...formValues,
                                workflowId: null,
                              }));
                            }}
                            loading={countriesLoading}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("countries")}
                          >
                            {countries?.map((ca, index) => {
                              return (
                                <Option key={index} value={ca.id}>
                                  {ca.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("workflow")} required>
                    <Controller
                      name="workflowId"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            loading={trademarkOrderTemplateLoading}
                            placeholder={t("workflow")}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                          >
                            {patentsOrderTemplates?.map((ca, index) => {
                              return (
                                <Option key={index} value={ca.id}>
                                  {ca.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("clients")} required>
                    <Controller
                      name="clients"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            {...field}
                            mode="multiple"
                            loading={clientsLoading}
                            style={{ width: "100%" }}
                            allowClear
                            showSearch
                            filterOption={(
                              input,
                              option // Custom filter function
                            ) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={t("clients")}
                          >
                            {clients?.map((client, index) => {
                              return (
                                <Option key={index} value={client.id}>
                                  {client.name}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("request_status")}>
                    <Controller
                      name="status"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Select
                            // disabled={countryId ? false : true}
                            {...field}
                            style={{ width: "100%" }}
                            allowClear
                            placeholder={t("request_status")}
                          >
                            {requestStatus?.map((tr, index) => {
                              return (
                                <Option key={index} value={tr}>
                                  {t(getRequestStatus(tr))}
                                </Option>
                              );
                            })}
                          </Select>
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("client_cost")}>
                    <Controller
                      name="clientCost"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("client_cost")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={8}>
                  <FormItem label={t("basic_number_lawsuits")}>
                    <Controller
                      name="basisNumber"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="text"
                            placeholder={t("basic_number_lawsuits")}
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label={t("basic_date_lawsuits")}>
                    <Controller
                      name="basisDate"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { ...field } }) => {
                        return (
                          <DatePicker
                            {...field}
                            style={{ width: "100%" }}
                            className="shadow"
                            // disabledDate={disabledDate}
                            value={field.value ? dayjs(field.value) : undefined}
                            onChange={(date, dateString) => {
                              field.onChange(dateString);
                            }}
                            format="YYYY-MM-DD"
                            showTime
                          />
                        );
                      }}
                    />
                  </FormItem>
                </Col>

                <Col span={24}>
                  <div>
                    <FormItem label={t("description")}>
                      <Controller
                        name="description"
                        rules={{
                          required: {
                            value: false,
                            message: `${t("field_is_required_message")}`,
                          },
                        }}
                        render={({ field: { ref, ...field } }) => {
                          return (
                            <RichEditor
                              value={field?.value ?? ""}
                              onChange={field?.onChange}
                            />
                          );
                        }}
                      />
                    </FormItem>
                  </div>
                </Col>
              </Row>

              <Tabs defaultActiveKey="1" items={items} />
            </div>
          )}
        </div>
        <div>
          <Divider className={styles.divider} />
          <div className={styles.formFooter}>
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              {t("cancel")}
            </Button>
            <Button loading={loadingSubmit} htmlType="submit" type="primary">
              {t("save")}
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const GrievanceForm = () => {
  return (
    <GrievanceContextProvider>
      <Form />
    </GrievanceContextProvider>
  );
};
export default GrievanceForm;
