import { INodeLight, IRemove } from "./../../models/node/response";
import { ICreateNode, IUpdateNode } from "./../../models/node/request";
import {
  ICreateCountry,
  IUpdateCountry,
} from "../../models/managent-collection/country/request";
import { HttpClient, RequestParams } from "../../utils/api/http-client";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { INodeQuery } from "../../models/node/query";
import { INode } from "../../models/node/response";

class NodeService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllNode = (query: INodeQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INode>>({
      path: `/nodes`,
      method: "GET",
      query,
      ...params,
    });

  getNode = (id: number, params?: RequestParams) =>
    this.http.request<INode>({
      path: `/nodes/${id}`,
      method: "GET",
      ...params,
    });

  createNode = (data: ICreateNode, params?: RequestParams) =>
    this.http.request<INode>({
      path: "/nodes",
      method: "POST",
      body: data,
      ...params,
    });

  updateNode = (id: number, data: IUpdateNode, params?: RequestParams) =>
    this.http.request<INode>({
      path: `/nodes/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  addChild = (id: number, data: IUpdateNode, params?: RequestParams) =>
    this.http.request<INode>({
      path: `/nodes/add-child/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteNode = (id: number, data: any, params?: RequestParams) =>
    this.http.request<any>({
      path: `/nodes/remove-node/${id}`,
      method: "DELETE",
      body: data,
      ...params,
    });
}

export default NodeService;
