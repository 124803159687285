export enum Type {
  FinancialReport = 1,
  TrademarkReservationInrenewal = 2,
  TrademarkReservationInRenewalWithFee = 3,
  TrademarkReservationInReRegistration = 4,
  TrademarkReservationEnteredAdditionalRenewal = 5,
  TrademarkReservationEnteredRenewal = 6,
  TrademarkReservationFromProtectedToNotProtected = 7,
  TrademarkReservationFromNegativeToNotProtected = 8,
  TrademarkReservationFromProtectedToNegative = 9,
}

export const getTypesColor = (status: Type) => {
  switch (status) {
    case Type.FinancialReport:
      return "blue";
    case Type.TrademarkReservationEnteredAdditionalRenewal:
      return "red";
    case Type.TrademarkReservationFromNegativeToNotProtected:
      return "green";
    case Type.TrademarkReservationFromProtectedToNotProtected:
      return "orange";
    case Type.TrademarkReservationInRenewalWithFee:
      return "pink";
    case Type.TrademarkReservationEnteredRenewal:
      return "brown";
    case Type.TrademarkReservationFromProtectedToNegative:
      return "yellow";
    case Type.TrademarkReservationInReRegistration:
      return "cyan";
    case Type.TrademarkReservationInrenewal:
      return "gray";
    default:
      return "blue";
  }
};

export const getTypes = (status: Type) => {
  switch (status) {
    case Type.FinancialReport:
      return "financial_report";
      case Type.TrademarkReservationEnteredAdditionalRenewal:
        return "TrademarkReservationEnteredAdditionalRenewal";
      case Type.TrademarkReservationFromNegativeToNotProtected:
        return "TrademarkReservationFromNegativeToNotProtected";
      case Type.TrademarkReservationFromProtectedToNotProtected:
        return "TrademarkReservationFromProtectedToNotProtected";
      case Type.TrademarkReservationInRenewalWithFee:
        return "TrademarkReservationInRenewalWithFee";
      case Type.TrademarkReservationEnteredRenewal:
        return "TrademarkReservationEnteredRenewal";
      case Type.TrademarkReservationFromProtectedToNegative:
        return "TrademarkReservationFromProtectedToNegative";
      case Type.TrademarkReservationInReRegistration:
        return "TrademarkReservationInReRegistration";
      case Type.TrademarkReservationInrenewal:
        return "TrademarkReservationInrenewal";
    default:
      return "personal";
  }
};
