import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import c from "../../../../../assets/images/icon-files/exel.png";
import b from "../../../../../assets/images/user.jpg";
import a from "../../../../../assets/images/icon-files/pdf.png";
import TrademarkRequestFilesContext from "../../../../../context/trademark-collection/trademark-request-files/context";
import { MediumFor } from "../../../../../models/medium/enum";
import { IMediumCustom } from "../../../../../models/medium/response";
import Controller from "../../../../form-components/controller";
import FieldBuilder from "../../../../form-components/field-builder";
import FileUploader from "../../../../form-components/file-uploader";
import Image from "../../../../general/antd/image";
import Spin from "../../../../general/antd/spin";
import FormItem from "../../../../general/form-item";
import styles from "./style.module.scss";

interface IProps {
  file?: IMediumCustom;
  name?: string;
}

const TrademarkRequsetFilesForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { loading, details } = useContext(TrademarkRequestFilesContext);
  const [typeFile, settypeFile] = useState(undefined);
  useEffect(() => {
    switch (props.file?.extension) {
      case "pdf":
        settypeFile(a);
        break;
      case "docx":
        settypeFile(c);
        break;

      default:
        settypeFile(b);
        break;
    }
  }, [props.name]);

  return (
    <>
      {loading.includes("create") || loading.includes("details") ? (
        <>
          <Spin />
        </>
      ) : (
        <>
          <Row gutter={[6, 6]}>
            <Col span={8}>
              <FieldBuilder
                name="name"
                label={`${t("name")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>

            <Col span={16}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text" }}
                rules={{ required: false }}
              />
            </Col>
            <Col span={24}>
              <div>
                <FormItem label={t("file")}>
                  <Controller
                    name="fileId"
                    rules={{
                      required: {
                        value: false,
                        message: `${t("field_is_required_message")}`,
                      },
                    }}
                    render={({ field: { ref, ...field } }) => {
                      return (
                        <FileUploader
                          {...field}
                          type="DRAGGER"
                          onChange={(response) => {
                            field.onChange(response?.id);
                          }}
                          fileFor={MediumFor.TRADEMARK_REQUEST_FILE}
                        />
                      );
                    }}
                  />
                </FormItem>
              </div>
            </Col>
            {props.file && (
              <Col span={5}>
                <a href={props?.file?.url}>
                  <div className={styles.fileCardContainer}>
                    <div style={{ textAlign: "center", padding: "2px" }}>
                      {props.name}
                    </div>
                    <Image
                      src={typeFile}
                      style={{
                        objectFit: "cover",
                        height: "100%",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </a>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default TrademarkRequsetFilesForm;
