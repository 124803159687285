import { useReducer } from "react";
import { IJopTitleQuery } from "../../../models/managent-collection/jop-title/query";
import {
  ICreateJopTitile,
  IUpdateJopTitle,
} from "../../../models/managent-collection/jop-title/request";
import { IJopTitleDetails } from "../../../models/managent-collection/jop-title/response";
import EndPoints from "../../../services/end-points";
import { execute } from "../../../utils/helpers/execute";
import JopTitleContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const JopTitleContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async () => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.jopTitle.getAllJopTitles(state.query);

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.jopTitle.getJopTitle(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
        //    navigate('update')
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IJopTitleDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createJopTitle = async (request: ICreateJopTitile) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.jopTitle.createJopTitle(request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateJopTitle = async (id: number, request: IUpdateJopTitle) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.jopTitle.updateJopTitle(id, request);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteJopTitle = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.jopTitle.deleteJopTitle(id);

        getData();
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IJopTitleQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <JopTitleContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,

          createJopTitle,
          updateJopTitle,
          deleteJopTitle,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </JopTitleContext.Provider>
  );
};

export default JopTitleContextProvider;
