import { createContext } from "react";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IGrievance,
  IGrievanceDetails,
} from "../../../../models/lawswits-collection/grievance/grievance-requests/response";
import { IGrievanceQuery } from "../../../../models/lawswits-collection/grievance/grievance-requests/query";
import {
  ICreateGrievance,
  IUpdateGrievance,
} from "../../../../models/lawswits-collection/grievance/grievance-requests/request";
export type grievanceLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: grievanceLoading[];

  list?: IBaseListingResponse<IGrievance>;
  query: IGrievanceQuery;

  details?: IGrievanceDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getList: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IGrievanceDetails) => void;

    createGrievance: (request: ICreateGrievance) => void;
    updateGrievance: (id: number, request: IUpdateGrievance) => void;
    deleteGrievance: (id: number) => void;

    exportExcel: (data: any) => any;
    // exportPdf: (id: number) => any;
    setSearch: (search?: string) => void;
    setQuery: (query: IGrievanceQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getList: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createGrievance: DEFAULT_FUNCTION,
    updateGrievance: DEFAULT_FUNCTION,
    deleteGrievance: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,
    // exportPdf: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const GrievancesContext = createContext(externalState);

export default GrievancesContext;
