import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../../context/app/context";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";

import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";

import ObjectionRequestContextProvider from "../../../context/independent_requests-collection/objection-request/provider";
import ObjectionRequestContext from "../../../context/independent_requests-collection/objection-request/context";
import ObjectionRequestTable from "../../../components/independent-request-managment/objection-request/table";
import {
  IExportObjectionRequestExcel,
  ObjectionRequestCloumms,
} from "../../../models/independent-requests-collection/objection-request/request";
import ObjectionRequestFilter from "../../../components/independent-request-managment/objection-request/filter";
interface IProps {
  children?: React.ReactNode;
}

const ObjectionRequest: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(ObjectionRequestContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("objection_request")}
        subTitle={t("objection_request_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/objection-request/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_objection_request")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="objection-request-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <ObjectionRequestFilter />
      </SearchFilterCard>
      <ObjectionRequestTable />
      <ExportToExcelModal
        id="export-users"
        title={t("export_users_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="60vw"
        defaultValues={
          {
            id: false,
            objectingParty: false,
            objectingRequest: false,
            subject: false,
            description: false,
            clients: false,
            createdAt: false,
          } as IExportObjectionRequestExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={ObjectionRequestCloumms} />
      </ExportToExcelModal>
    </div>
  );
};

const ObjectionRequestPage = () => {
  return (
    <ObjectionRequestContextProvider>
      <ObjectionRequest />
    </ObjectionRequestContextProvider>
  );
};
export default ObjectionRequestPage;
