import { useReducer } from "react";
import { IIncidentsLicensingRequestCostQuery } from "../../../../models/incidents-managments/incidents-licensing/incidents-request-costs/query";
import {
  ICreateIncidentsLicensingRequestCosts,
  IUpdateIncidentsLicensingRequestCosts,
} from "../../../../models/incidents-managments/incidents-licensing/incidents-request-costs/request";
import { IIncidentsLicensingRequestCostsDetails } from "../../../../models/incidents-managments/incidents-licensing/incidents-request-costs/response";
import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import IncidentsLicensingOfficeCostContext, { internalState } from "./context";
import reducer from "./reducer";

export interface IProps {
  children: React.ReactNode;
}
const IncidentsLicensingOfficeCostContextProvider: React.FC<IProps> = (
  props
) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } =
          await EndPoints.incidentsLicensingOfficeCost.getAllOfficeCosts({
            ...state.query,
            filters: [
              {
                name: "requestId",
                operation: "eq",
                value: id,
              },
            ],
          });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } =
          await EndPoints.incidentsLicensingOfficeCost.getOfficeCost(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IIncidentsLicensingRequestCostsDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createOfficeCost = async (
    request: ICreateIncidentsLicensingRequestCosts
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.incidentsLicensingOfficeCost.createOfficeCost(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateOfficeCost = async (
    id: number,
    request: IUpdateIncidentsLicensingRequestCosts
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.incidentsLicensingOfficeCost.updateOfficeCost(
          id,
          request
        );
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteOfficeCost = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.incidentsLicensingOfficeCost.deleteOfficeCost(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IIncidentsLicensingRequestCostQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <IncidentsLicensingOfficeCostContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteOfficeCost,
          createOfficeCost,

          updateOfficeCost,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </IncidentsLicensingOfficeCostContext.Provider>
  );
};

export default IncidentsLicensingOfficeCostContextProvider;
