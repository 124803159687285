export interface ICreateIncidentsWaiver {
  officialDate: string;
  clientCost: number;
  depositNumber: string;
  description: string;
  address: string;
  incident: string;
  status: number;
  workflowId: number;
  assignor: number[];
  transferOwnershipDate: string;
  assignee: number[];
  trademarkReservations: number[];
  drawingReservations: number[];
  patentReservations: number[];
  copyrightReservations: number[];
  documentProvingChange: string;
  subject: string;
  secondDescription?: string;
}

export interface IUpdateIncidentsWaiver extends ICreateIncidentsWaiver {
  country?: number;
}

// to do
export interface IExportIncidentsWaiverExcel {
  depositNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  officialDate: boolean;
  clientCost: boolean;
  assignor: boolean;
  assignee: boolean;
  incident: boolean;
  transferOwnershipDate: boolean;
  officeCosts: boolean;
  clientPayment: boolean;
}

export const IncidentsWaiverCloumms = [
  "depositNumber",
  "Country",
  "typeWorkflow",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "officialDate",
  "clientCost",
  "assignor",
  "assignee",
  "incident",
  "transferOwnershipDate",
  "officeCosts",
  "clientPayment",
];
