import { IIncidentsWaiverRequestFilesQuery } from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/query";
import {
  ICreateIncidentsWaiverRequestFiles,
  IUpdateIncidentsWaiverRequestFiles,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/request";
import {
  IIncidentsRequesWaiverFilesDetails,
  IIncidentsWaiverRequesFiles,
} from "../../../../models/incidents-managments/incidents-waiver-request/incidents-requset-files/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class IncidentWaiverRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllIncidentRequsetFiles = (
    query: IIncidentsWaiverRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IIncidentsWaiverRequesFiles>>({
      path: `/incident-waiver-files`,
      method: "GET",
      query,
      ...params,
    });

  getIncidentRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsRequesWaiverFilesDetails>({
      path: `/incident-waiver-files/${id}`,
      method: "GET",
      ...params,
    });

  createIncidentRequsetFile = (
    data: ICreateIncidentsWaiverRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsRequesWaiverFilesDetails>({
      path: "/incident-waiver-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateIncidentRequsetFile = (
    id: number,
    data: IUpdateIncidentsWaiverRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IIncidentsWaiverRequesFiles>({
      path: `/incident-waiver-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteIncidentRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IIncidentsWaiverRequesFiles>({
      path: `/incident-waiver-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default IncidentWaiverRequsetfileService;
