export enum types {
  LICENSE_FOR_USE = 1,
}

export const getIncidentsLicensingColor = (status: types) => {
  switch (status) {
    case types.LICENSE_FOR_USE:
      return "blue";
  }
};

// Status Boolean stuts
export const getIncidentsLicensingName = (status: types) => {
  switch (status) {
    case types.LICENSE_FOR_USE:
      return "license-for-use";
  }
};
