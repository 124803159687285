import { IDrawingQuery } from "../../../models/drawing-collection/drawing/query";
import {
  IDrawing,
  IDrawingDetails,
} from "../../../models/drawing-collection/drawing/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { toggleLoading } from "../../../utils/helpers/functions";
import paramsReducer, { QueryAction } from "../../base/base-reducer";

import { DrawingLoading, IInternalState } from "./context";

type Action =
  | {
      type: "LOADING";
      payload: { loading: DrawingLoading | DrawingLoading[] };
    }
  | { type: "SET_LIST"; payload: { list: IBaseListingResponse<IDrawing> } }
  | { type: "SET_DETAILS"; payload: { details?: IDrawingDetails } }
  | QueryAction<IDrawingQuery>;

const reducer = (state: IInternalState, action: Action): IInternalState => {
  switch (action.type) {
    case "LOADING": {
      return {
        ...state,
        loading: toggleLoading(state.loading, action.payload.loading),
      };
    }
    case "SET_LIST": {
      return {
        ...state,
        list: action.payload.list,
      };
    }

    case "SET_DETAILS": {
      return {
        ...state,
        details: action.payload.details,
      };
    }

    default: {
      return paramsReducer(state, action);
    }
  }
};

export default reducer;
