import { createContext } from "react";
import IBaseListingResponse from "../../../utils/api/base-listing-response";

import {
  DEFAULT_FUNCTION,
  DEFAULT_QUERY,
} from "../../../utils/helpers/constants";
import {
  IDisclosureTrademark,
  IDisclosureTrademarkDetails,
} from "../../../models/independent-requests-collection/disclosure-trademark/response";
import { IDisclosureTrademarkQuery } from "../../../models/independent-requests-collection/disclosure-trademark/query";
import {
  ICreateDisclosureTrademark,
  IUpdateDisclosureTrademark,
} from "../../../models/independent-requests-collection/disclosure-trademark/request";

export type DisclosureTrademarkLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: DisclosureTrademarkLoading[];

  list?: IBaseListingResponse<IDisclosureTrademark>;
  query: IDisclosureTrademarkQuery;

  details?: IDisclosureTrademarkDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IDisclosureTrademarkDetails) => void;

    createDisclosureTrademark: (request: ICreateDisclosureTrademark) => void;
    updateDisclosureTrademark: (
      id: number,
      request: IUpdateDisclosureTrademark
    ) => void;
    deleteDisclosureTrademark: (id: number) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: IDisclosureTrademarkQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    exportExcel: DEFAULT_FUNCTION,

    createDisclosureTrademark: DEFAULT_FUNCTION,
    updateDisclosureTrademark: DEFAULT_FUNCTION,
    deleteDisclosureTrademark: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const DisclosureTrademarkContext = createContext(externalState);

export default DisclosureTrademarkContext;
