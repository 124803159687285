import { useContext } from "react";
import LoginForm from "../../components/login/login-form";
import ImageForm from "../../components/login/login-image";
import AppContext from "../../context/app/context";
import styles from "./style.module.scss";

const Login = () => {
  const { direction } = useContext(AppContext);
  return (
    <div className={styles.container} dir={direction}>
      <div className={styles.layer} />
      <div className={styles.content}>
        <LoginForm />
        <ImageForm />
      </div>
    </div>
  );
};

export default Login;
