export interface ICreateGrievance {
  clientCost: number;
  basisNumber: string;
  description: string;
  basisDate: string;
  status: number;
  workflowId: number;
  clients: number[];
  trademarkReservations: number[];
  drawingReservations: number[];
  patentReservations: number[];
  copyrightReservations: number[];
}

export interface IUpdateGrievance extends ICreateGrievance {
  country?: number;
}

// to do
export interface IExportGrievanceExcel {
  basisNumber: boolean;
  Country: boolean;
  typeWorkflow: boolean;
  currentNode: boolean;
  status: boolean;
  CreatedDate: boolean;
  lastUpdatedDate: boolean;
  basisDate: boolean;
  clientCost: boolean;
  clients: boolean;
  description: boolean;
  clientPayment: boolean;
}

export const GrievanceCloumms = [
  "basisNumber",
  "Country",
  "typeWorkflow",
  "currentNode",
  "status",
  "CreatedDate",
  "lastUpdatedDate",
  "basisDate",
  "clientCost",
  "clients",
  "description",
  "clientPayment",
];
