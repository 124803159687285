import { createContext } from "react";
import { IDrawingOfficeCostQuery } from "../../../../models/drawing-collection/office-cost/query";

import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../../utils/costants";
import { DEFAULT_QUERY } from "../../../../utils/helpers/constants";
import {
  IGrievanceRequestCosts,
  IGrievanceRequestCostsDetails,
} from "../../../../models/lawswits-collection/grievance/grievance-request-costs/response";
import { IGrievanceRequestCostQuery } from "../../../../models/lawswits-collection/grievance/grievance-request-costs/query";
import {
  ICreateGrievanceRequestCosts,
  IUpdateGrievanceRequestCosts,
} from "../../../../models/lawswits-collection/grievance/grievance-request-costs/request";

export type OfficeCostLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: OfficeCostLoading[];

  list?: IBaseListingResponse<IGrievanceRequestCosts>;
  query: IGrievanceRequestCostQuery;

  details?: IGrievanceRequestCostsDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (id: number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IGrievanceRequestCostsDetails) => void;

    createOfficeCost: (request: ICreateGrievanceRequestCosts) => void;
    updateOfficeCost: (
      id: number,
      request: IUpdateGrievanceRequestCosts
    ) => void;
    deleteOfficeCost: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: IDrawingOfficeCostQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createOfficeCost: DEFAULT_FUNCTION,
    updateOfficeCost: DEFAULT_FUNCTION,
    deleteOfficeCost: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const GrievanceOfficeCostContext = createContext(externalState);

export default GrievanceOfficeCostContext;
