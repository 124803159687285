import axios from "axios";

import { IDrawingRequestQuery } from "../../../models/drawing-collection/drawing-request/query";
import {
  ICreateDrawingRequest,
  IDrawingRequestExportExcel,
  IUpdateDrawingRequest,
} from "../../../models/drawing-collection/drawing-request/request";
import {
  IDrawingRequest,
  IDrawingRequestDetails,
} from "../../../models/drawing-collection/drawing-request/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { INodeCanMove } from "../../../models/trademark-collection/trademark-request/response";
import { IDrawingReservationDetails } from "../../../models/drawing-collection/drawing-reservation/response";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";

class DrawingRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRequests = (query: IDrawingRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDrawingRequest>>({
      path: `/drawing-requests`,
      method: "GET",
      query,
      ...params,
    });

  createDrawingRequests = (
    data: ICreateDrawingRequest,
    params?: RequestParams
  ) =>
    this.http.request<ICreateDrawingRequest>({
      path: "/drawing-requests",
      method: "POST",
      body: data,
      ...params,
    });

  deleteRequest = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/drawing-requests/${id}`,
      method: "DELETE",
      ...params,
    });

  updateDrawingRequest = (
    id: number,
    data: IUpdateDrawingRequest,
    params?: RequestParams
  ) =>
    this.http.request<IDrawingRequest>({
      path: `/drawing-requests/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getDrawingRequest = (id: number, params?: RequestParams) =>
    this.http.request<IDrawingRequestDetails>({
      path: `/drawing-requests/${id}`,
      method: "GET",
      ...params,
    });

  getDrawingReservations = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IDrawingReservationDetails>>({
      path: `/drawing-requests/get-Reservations/${id}`,
      method: "GET",
      ...params,
    });

  addDrawingReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/drawing-requests/add-Reservations/${id}`,
      method: "PUT",
      body: data,
    });

  moveDrawingReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/drawing-requests/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });

  deleteDrawingReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/drawing-requests/deattach-Reservations/${id}`,
      method: "DELETE",
      body: data,
    });

  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/drawing-requests/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (
    data: IDrawingRequestExportExcel,
    query: IDrawingRequestQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/drawing-requests/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/drawing-requests/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
  exportDepositTemplatePdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/drawing-requests/deposit/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportWord = (id: number, type: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/drawing-requests/export-word/${id}/${type}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );

  exportDepositWord = (id: number, type: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/drawing-requests/export-deposit-word/${id}/${type}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
}

export default DrawingRequestService;
