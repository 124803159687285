export enum TrademarkStatus {
  renewal = 2,
  registration = 1,
  reRegistration = 3,
}

// trademark orderTemplate enum

export const getTrademarkOrderTemplateColor = (status: TrademarkStatus) => {
  switch (status) {
    case TrademarkStatus.registration:
      return "blue";
    case TrademarkStatus.reRegistration:
      return "orange";
    case TrademarkStatus.renewal:
      return "green";
  }
};

export const getBooleanTrademarkOrderTemplate = (status: TrademarkStatus) => {
  switch (status) {
    case TrademarkStatus.registration:
      return "registration";
    case TrademarkStatus.reRegistration:
      return "reRegistration";
    case TrademarkStatus.renewal:
      return "renewal";
  }
};
