import { createContext } from "react";
import { INationalityQuery } from "../../../models/managent-collection/nationality/query";
import {
  ICreateNationality,
  IUpdateNationality,
} from "../../../models/managent-collection/nationality/request";
import {
  INationality,
  INationalityDetails,
} from "../../../models/managent-collection/nationality/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";

export type NationalityLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: NationalityLoading[];

  list?: IBaseListingResponse<INationality>;
  query: INationalityQuery;

  details?: INationalityDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;
    getDetails: (id: number) => void;
    setDetails: (data?: INationalityDetails) => void;

    createNationality: (request: ICreateNationality) => void;
    updateNationality: (id: number, request: IUpdateNationality) => void;
    deleteNationality: (id: number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: INationalityQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createNationality: DEFAULT_FUNCTION,
    updateNationality: DEFAULT_FUNCTION,
    deleteNationality: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const NationalityContext = createContext(externalState);

export default NationalityContext;
