import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import UserFilters from "../../../components/managment-collection/user/user-filter";
import UserTable from "../../../components/managment-collection/user/user-table";
import AppContext from "../../../context/app/context";
import UserContext from "../../../context/management-collection/user/context";
import UserContextProvider from "../../../context/management-collection/user/provider";
import {
  IExportUserExcel,
  colomnsExportUserExcel,
} from "../../../models/managent-collection/user/request";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
interface IProps {
  children?: React.ReactNode;
}

const User: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(UserContext);

  useEffect(() => {
    search && actions.setSearch(search);
  }, [search]);

  useEffect(() => {
    actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("users")}
        subTitle={t("users_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/users/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_user")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="user-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <UserFilters />
      </SearchFilterCard>
      <UserTable />
      <ExportToExcelModal
        id="export-users"
        title={t("export_users_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="40vw"
        defaultValues={
          {
            job: false,
            mobile: false,
            name: false,
            role: false,
            status: false,
          } as IExportUserExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={colomnsExportUserExcel} />
      </ExportToExcelModal>
    </div>
  );
};

const UsersPage = () => {
  return (
    <UserContextProvider>
      <User />
    </UserContextProvider>
  );
};
export default UsersPage;
