import { Col, Row } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { INITIAL_PAGE } from "../../../utils/helpers/constants";
import Form from "../../form-components/from";
import FieldBuilder from "../../form-components/field-builder";
import EndPoints from "../../../services/end-points";
import AppContext from "../../../context/app/context";

import TraderMarkOrderTeamplateContext from "../../../context/workflow/context";
import { ICountry } from "../../../models/managent-collection/country/response";
import {
  getAllBooleanOrderTemplate,
  getForStatus,
} from "../../../models/workflow/enms-workflow/all-enums";

interface IProps {}

const TradeMarkOrderTemplateFilter: React.FC<IProps> = () => {
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);

  const { t } = useTranslation();
  const { actions, query } = useContext(TraderMarkOrderTeamplateContext);
  const { direction } = useContext(AppContext);
  const types = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  const fors = [1, 2, 3, 4, 5, 6, 7];

  // get all Countries from api
  useEffect(() => {
    const getCountryLight = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setCountriesLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountryLight();
  }, [direction]);
  return (
    <Form
      formId="order-template-filter"
      onSubmit={(data) => {
        const filter: any = {
          page: INITIAL_PAGE,
          perPage: query?.perPage,
          keyword: data?.keyword,
          filters: [
            data?.type
              ? {
                  name: "type",
                  value: data.type,
                  operation: "eq",
                }
              : undefined,
            data?.country
              ? {
                  name: "countryId",
                  value: data.country,
                  operation: "eq",
                }
              : undefined,
            data?.for
              ? {
                  name: "for",
                  value: data.for,
                  operation: "eq",
                }
              : undefined,
          ].filter((f) => f),
        };
        actions.setQuery(filter);
      }}
    >
      <Row justify={"start"} gutter={[10, 0]}>
        <Col span={8}>
          <FieldBuilder
            name="type"
            label={`${t("type_workflow")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: types.map((type, index) => {
                return {
                  label: t(getAllBooleanOrderTemplate(type) as string),
                  value: type as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="for"
            label={`${t("for")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              options: fors.map((type, index) => {
                return {
                  label: t(getForStatus(type) as string),
                  value: type as number,
                };
              }),
            }}
          />
        </Col>
        <Col span={8}>
          <FieldBuilder
            name="country"
            label={`${t("country")}`}
            width="large"
            rules={{ required: false }}
            input={{
              type: "select",
              allowSearch: true,
              loading: countriesLoading,
              options: countries?.map((country, index) => {
                return {
                  label: country?.name as string,
                  value: country?.id as number,
                };
              }),
            }}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default TradeMarkOrderTemplateFilter;
