import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../../context/app/context";
import IncidentsLicensingsContext from "../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/context";
import IncidentsLicensingContextProvider from "../../../context/incidents-collection/incidents-licensing-requests/licensing-incidents/provider";
import {
  IExportPatentsExcel,
  PatentsCloumms,
} from "../../../models/patents-collection/patents/request";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";
import IncidentsClientsFilter from "../../../components/incidents-managment/incidents-client/incidents-client-requset/incidents-clients-filter";
import IncidentsLicensingFilter from "../../../components/incidents-managment/incidents-licensing/inicidents-licensing-request/incidents-licensing-filter";
import {
  IExportIncidentsLicensingExcel,
  IncidentsLicensingCloumms,
} from "../../../models/incidents-managments/incidents-licensing/incidents-licensing-requests/request";
import IncidentsLicensingTable from "../../../components/incidents-managment/incidents-licensing/inicidents-licensing-request/incidents-licensing-table";

interface IProps {
  children?: React.ReactNode;
}

const IncidentLicensing: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(IncidentsLicensingsContext);
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getList();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("incidents_licensing")}
        subTitle={t("incidents_licensing_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getList()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/incidents-licensing/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_incidents_licensing")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="incidents-Licensing-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <IncidentsLicensingFilter />
      </SearchFilterCard>
      <IncidentsLicensingTable />
      <ExportToExcelModal
        id="export-users"
        title={t("export_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="60vw"
        defaultValues={
          {
            depositNumber: false,
            Country: false,
            typeWorkflow: false,
            currentNode: false,
            status: false,
            CreatedDate: false,
            lastUpdatedDate: false,
            officialDate: false,
            clientCost: false,
            assignor: false,
            assignee: false,
            incident: false,
            licenseStartDate: false,
            licenseEndDate: false,
            officeCosts: false,
            clientPayment: false,
          } as IExportIncidentsLicensingExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={IncidentsLicensingCloumms} />
      </ExportToExcelModal>
    </div>
  );
};

const IncidentsLicensingPage = () => {
  return (
    <IncidentsLicensingContextProvider>
      <IncidentLicensing />
    </IncidentsLicensingContextProvider>
  );
};
export default IncidentsLicensingPage;
