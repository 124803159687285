import { ITranslationRequest } from "../../base/translation";
export interface ICreateDataRequest {
  officialDate: string;
  dateFinancialReceipt: string;
  depositNumber: string;
  financialReceiptNumber: string;
  data: string;
  restrictionsOn: string;
  extractedFrom: string;
  note: string;
  clientId: number;
  countryId: number;
  orderValue: number;
  clientPayments: number;
}

export interface IUpdateDataRequest extends ICreateDataRequest {}

// to do
export interface IExportDataRequestExcel {
  depositNumber: boolean;
  country: boolean;
  client: boolean;
  createdAt: boolean;
  officialDate: boolean;
  dateFinancialReceipt: boolean;
  financialReceiptNumber: boolean;
}

export const DataRequestCloumms = [
  "depositNumber",
  "country",
  "client",
  "createdAt",
  "officialDate",
  "dateFinancialReceipt",
  "financialReceiptNumber",
];
