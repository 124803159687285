import { IRequestCostQuery } from "../../../models/independent-requests-collection/request-cost/query";
import { ICreateRequestCost, IUpdateRequestCost } from "../../../models/independent-requests-collection/request-cost/request";
import { IRequestCost, IRequestCostDetails } from "../../../models/independent-requests-collection/request-cost/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class RequestCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCosts = (
    query: IRequestCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IRequestCost>>({
      path: `/independent-request-costs`,
      method: "GET",
      query,
      ...params,
    });

  getCost = (id: number, params?: RequestParams) =>
    this.http.request<IRequestCostDetails>({
      path: `/independent-request-costs/${id}`,
      method: "GET",
      ...params,
    });

  createCost = (data: ICreateRequestCost, params?: RequestParams) =>
    this.http.request<IRequestCost>({
      path: "/independent-request-costs",
      method: "POST",
      body: data,
      ...params,
    });

  updateCost = (
    id: number,
    data: IUpdateRequestCost,
    params?: RequestParams
  ) =>
    this.http.request<IRequestCost>({
      path: `/independent-request-costs/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCost = (id: number, params?: RequestParams) =>
    this.http.request<IRequestCost>({
      path: `/independent-request-costs/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default RequestCostService;
