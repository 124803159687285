import { Col, Divider, Image, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DefoultImage from "../../../../assets/images/user.jpg";
import TrademarkReservationContextProvider from "../../../../context/trademark-collection/trademark-reservation/provider";
import TrademarkContext from "../../../../context/trademark-collection/trademark/context";
import TrademarkContextProvider from "../../../../context/trademark-collection/trademark/provider";
import { IClient } from "../../../../models/client/response";
import { ICountry } from "../../../../models/managent-collection/user/response";
import { MediumFor } from "../../../../models/medium/enum";
import { getBooleanTrademark } from "../../../../models/trademark-collection/trademark/enum";
import { ICreateTrademark } from "../../../../models/trademark-collection/trademark/request";
import EndPoints from "../../../../services/end-points";
import { languages } from "../../../../utils/helpers/constants";
import { getTranslations } from "../../../../utils/helpers/translations";
import Controller from "../../../form-components/controller";
import FieldBuilder from "../../../form-components/field-builder";
import ImageUploader from "../../../form-components/image-uploader";
import MainForm from "../../../form-components/main-form";
import Spin from "../../../general/antd/spin";
import FormItem from "../../../general/form-item";
import DeleteBtn from "../../../general/table-components/actions/delete-btn";
import EditBtn from "../../../general/table-components/actions/edit-btn";
import TrademarkReservationTabel from "../trademark-reservation/trademark-resevation-tabel/index";
import styles from "./styles.module.scss";

const Form = () => {
  const { t } = useTranslation();

  const { details, actions, loading } = useContext(TrademarkContext);
  const { id } = useParams();
  const [clients, setClients] = useState<IClient[]>([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(true);
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, []);

  useEffect(() => {
    setImageUrl(details?.logo?.url ?? DefoultImage);
  }, [details]);

  useEffect(() => {
    const getClients = async () => {
      try {
        const { data } = await EndPoints.client.getAllClients({
          perPage: -1,
          filters: [
            {
              name: "isCopied",
              operation: "eq",
              value: 0,
            },
          ],
        });
        setClients(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setClientsLoading(false);
      }
    };
    getClients();
  }, []);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const { data } = await EndPoints.country.getAllCountriesLight({
          perPage: -1,
        });
        setCountries(data.data);
        setClientsLoading(false);
      } catch (error) {
      } finally {
        setCountriesLoading(false);
      }
    };
    getCountries();
  }, []);

  const types = [1, 2];
  return (
    <MainForm
      onSubmit={(data) => {
        details
          ? actions.updateTrademark(details?.id, data)
          : actions.createTrademark(data);
      }}
      title={details ? t("update_trademark") : t("add_trademark")}
      defaultValues={
        imageUrl == "" || imageUrl == DefoultImage
          ? {
            name: getTranslations(details?.translations?.name) ?? null,
            description: details?.description,
            note: details?.note,
            type: details?.type,
            logoId: details?.logo?.id ?? null,
            mainCountryId: details?.mainCountry?.id,
            clients: details?.clients.map((client) => client.id),
            meaning :  details?.meaning
          }
          : (null as ICreateTrademark)
      }
    >
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[16, 8]}>
            <Col span={2}>
              <Image preview className={styles.img} src={imageUrl} />
            </Col>
            <Col span={3} style={{ width: "100%" }}>
              <Row gutter={[6, 6]} style={{ width: "100%" }}>
                <FormItem>
                  <Controller
                    name="logoId"
                    render={({ field }) => {
                      return (
                        <ImageUploader
                          {...field}
                          type="BUTTON"
                          onChange={(response) => {
                            field.onChange(response?.id);
                            setImageUrl(response?.url);
                          }}
                          imageFor={MediumFor.UserProfile}
                        >
                          <EditBtn
                            className={styles.buttonEdite}
                            loading={
                              loading.includes("update") ||
                              loading.includes("create")
                            }
                          >
                            {t(`${"edit"}`)}
                          </EditBtn>
                        </ImageUploader>
                      );
                    }}
                  />
                </FormItem>
              </Row>
              <Row gutter={[6, 6]} style={{ width: "100%" }}>
                <DeleteBtn
                  onConfirm={async () => { }}
                  className={styles.buttonDelete}
                >
                  {t(`${"delete"}`)}{" "}
                </DeleteBtn>
              </Row>
            </Col>
            <Col span={19}>
              <FieldBuilder
                name="description"
                label={`${t("description_trademark")}`}
                input={{ type: "text-area" }}
              />
            </Col>
          </Row>
          <Divider></Divider>
          <Row gutter={[16, 8]}>
            {languages?.map((lang, index) => {
              return (
                <Col span={8}>
                  <FieldBuilder
                    key={index}
                    name={`name[${lang?.code}]`}
                    label={`${t("name")} (${lang?.name})`}
                    input={{ type: "text" }}
                    rules={{ required: lang?.code == "ar" ? true : false }}
                  />
                </Col>
              );
            })}
            <Col span={8}>
              <FieldBuilder
                name="clients"
                label={`${t("clients")}`}
                width="large"
                input={{
                  type: "multiSelect",
                  loading: clientsLoading,
                  options: clients?.map((client, index) => {
                    return {
                      key: index,
                      label: client.name,
                      value: client.id,
                    };
                  }),
                }}
              />
            </Col>

            <Col span={8}>
              <FieldBuilder
                name="type"
                label={`${t("type_trademark")}`}
                rules={{ required: true }}
                width="large"
                input={{
                  type: "select-type",
                  options: types.map((type, index) => {
                    return {
                      label: t(getBooleanTrademark(type)) as string,
                      value: type as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="mainCountryId"
                label={`${t("main_country")}`}
                rules={{ required: true }}
                width="large"
                input={{
                  type: "select",
                  allowSearch: true,
                  loading: countriesLoading,
                  options: countries.map((country, index) => {
                    return {
                      label: country.name as string,
                      value: country.id as number,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <FieldBuilder
                name="meaning"
                label={`${t("meaning_trademark")}`}
                input={{ type: "text" }}
              />
            </Col>
            <Col span={24}>
              <FieldBuilder
                name="note"
                label={`${t("note")}`}
                input={{ type: "text-area" }}
              />
            </Col>
          </Row>
          {details ? (
            <Row>
              <Col span={24}>
                <Divider></Divider>
                <TrademarkReservationTabel idTrademark={details?.id} />
              </Col>
            </Row>
          ) : (
            ""
          )}
        </>
      )}
    </MainForm>
  );
};
const TrademarkForm = () => {
  return (
    <TrademarkContextProvider>
      <TrademarkReservationContextProvider>
        <Form />
      </TrademarkReservationContextProvider>
    </TrademarkContextProvider>
  );
};
export default TrademarkForm;
