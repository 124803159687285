import { createContext } from "react";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../../utils/costants";
import { DEFAULT_QUERY } from "../../../utils/helpers/constants";
import {
  IRecordTrademark,
  IRecordTrademarkDetails,
} from "../../../models/trademark-collection/trademark-reservation/response";
import { ITrademarkReservationQuery } from "../../../models/trademark-collection/trademark-reservation/query";
import {
  ICreateRecordTrademark,
  IUpdateRecordTrademark,
} from "../../../models/trademark-collection/trademark-reservation/request";

export type RecordTrademarkLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: RecordTrademarkLoading[];

  list?: IBaseListingResponse<IRecordTrademark>;
  query: ITrademarkReservationQuery;

  details?: IRecordTrademarkDetails;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: (idReservation:number) => void;
    getDetails: (id: number) => void;
    setDetails: (data?: IRecordTrademarkDetails) => void;

    createRecord: (request: ICreateRecordTrademark) => void;
    updateRecord: (id: number,request: IUpdateRecordTrademark) => void;
    deleteRecord: (id: number,idReservation:number) => void;

    setSearch: (search?: string) => void;
    setQuery: (query: ITrademarkReservationQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,

    createRecord: DEFAULT_FUNCTION,
    updateRecord: DEFAULT_FUNCTION,
    deleteRecord: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const IRecordTrademarkContext = createContext(externalState);

export default IRecordTrademarkContext;
