import { FileExcelOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SearchFilterCard from "../../../components/general/filter-card";
import PageHeader from "../../../components/general/layout/page-header";
import RefreshBtn from "../../../components/general/table-components/actions/refresh-btn";
import AppContext from "../../../context/app/context";
import { useIsMount } from "../../../utils/hooks/is-mount";
import Can from "../../../utils/rbac/can";
import { CAN_VIEW_HOME_PAGE } from "../../../utils/rbac/permissions";
import styles from "./style.module.scss";

import TemplateExportExcel from "../../../components/general/excel/expor-excel";
import ExportToExcelModal from "../../../components/general/excel/export-to-excel-modal";
import DisclosureTrademarkRequestTable from "../../../components/independent-request-managment/disclosure-trademark-request/table";
import {
  DisclosureTrademarkRequestCloumms,
  IExportDisclosureTrademarkRequestExcel,
} from "../../../models/independent-requests-collection/disclosure-trademark-request/request";
import DisclosureTrademarkRequestFilter from "../../../components/independent-request-managment/disclosure-trademark-request/filter";
import DisclosureTrademarkRequestContext from "../../../context/independent_requests-collection/disclosure-trademark-request/context";
import DisclosureTrademarkRequestContextProvider from "../../../context/independent_requests-collection/disclosure-trademark-request/provider";
interface IProps {
  children?: React.ReactNode;
}

const DisclosureTrademarkRequest: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string | undefined>(undefined);

  const { direction } = useContext(AppContext);
  const { actions, loading, query } = useContext(
    DisclosureTrademarkRequestContext
  );
  const [exportModalVisible, setExportModalVisible] = useState(false);

  useEffect(() => {
    actions.setSearch(search);
  }, [search]);

  const isMounted = useIsMount();
  useEffect(() => {
    !isMounted && actions.getData();
  }, [query, direction]);

  return (
    <div dir={direction} className={styles.container}>
      <PageHeader
        title={t("disclosure_trademark_request")}
        subTitle={t("disclosure_trademark_request_managment")}
        extra={[
          <RefreshBtn
            key={0}
            loading={loading.includes("list")}
            onClick={() => actions.getData()}
          >
            {t("refresh")}
          </RefreshBtn>,
          <Divider key={1} type="vertical" />,
          <Button
            type="primary"
            onClick={() => {
              setExportModalVisible(true);
            }}
            icon={<FileExcelOutlined />}
          >
            {t("export_to_excel")}
          </Button>,
          <Can key={2} perform={CAN_VIEW_HOME_PAGE}>
            <Button
              type="primary"
              onClick={() => {
                navigate(`/disclosure-trademark-request/create`);
              }}
              icon={<PlusOutlined />}
            >
              {t("add_disclosure_trademark_request")}
            </Button>
          </Can>,
        ]}
      />
      <SearchFilterCard
        formId="disclosure_trademark_request-filter"
        searchValue={search}
        setSearchValue={setSearch}
        onReset={() => {
          actions.setQuery({
            page: query?.page,
            perPage: query?.perPage,
          });
        }}
        resetLoading={loading.includes("list")}
        applyLoading={loading.includes("list")}
      >
        <DisclosureTrademarkRequestFilter />
      </SearchFilterCard>
      <DisclosureTrademarkRequestTable />
      <ExportToExcelModal
        id="export-users"
        title={t("export_users_to_excel")}
        visible={exportModalVisible}
        toggleVisible={setExportModalVisible}
        width="60vw"
        defaultValues={
          {
            depositNumber: false,
            country: false,
            client: false,
            disclosureResult: false,
            createdAt: false,
            officialDate: false,
            dateFinancialReceipt: false,
            financialReceiptNumber: false,
          } as IExportDisclosureTrademarkRequestExcel
        }
        loading={loading.includes("create")}
        onSubmit={async (columnsNames) => {
          await actions.exportExcel(columnsNames);
          setExportModalVisible(false);
        }}
      >
        <TemplateExportExcel names={DisclosureTrademarkRequestCloumms} />
      </ExportToExcelModal>
    </div>
  );
};

const DisclosureTrademarkRequestPage = () => {
  return (
    <DisclosureTrademarkRequestContextProvider>
      <DisclosureTrademarkRequest />
    </DisclosureTrademarkRequestContextProvider>
  );
};
export default DisclosureTrademarkRequestPage;
