import { IGrievanceRequestFilesQuery } from "../../../../models/lawswits-collection/grievance/grievance-requset-files/query";
import {
  ICreateGrievanceRequestFiles,
  IUpdateGrievanceRequestFiles,
} from "../../../../models/lawswits-collection/grievance/grievance-requset-files/request";
import {
  IGrievanceRequesFiles,
  IGrievanceRequesFilesDetails,
} from "../../../../models/lawswits-collection/grievance/grievance-requset-files/response";
import IBaseListingResponse from "../../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../../utils/api/http-client";

class GrievanceRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllGrievanceRequsetFiles = (
    query: IGrievanceRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IGrievanceRequesFiles>>({
      path: `/grievance-lawsuits-files`,
      method: "GET",
      query,
      ...params,
    });

  getGrievanceRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IGrievanceRequesFilesDetails>({
      path: `/grievance-lawsuits-files/${id}`,
      method: "GET",
      ...params,
    });

  createGrievanceRequsetFile = (
    data: ICreateGrievanceRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IGrievanceRequesFilesDetails>({
      path: "/grievance-lawsuits-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateGrievanceRequsetFile = (
    id: number,
    data: IUpdateGrievanceRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<IGrievanceRequesFiles>({
      path: `/grievance-lawsuits-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteGrievanceRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<IGrievanceRequesFiles>({
      path: `/grievance-lawsuits-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default GrievanceRequsetfileService;
