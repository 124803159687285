import React from "react";
import { Layout } from "antd";
import Footer from "../footer";
import styles from "./style.module.scss";
import CustomZoom from "../../zoom";

const { Content } = Layout;
interface IProps {
  children: React.ReactNode;
}

const LayoutContent: React.FC<IProps> = ({ children }) => {
  return (
    <Content className={styles.layoutOuterContent}>
      <div className={styles.layoutInnerContent}>{children}</div>
      <Footer />
    </Content>
  );
};

export default LayoutContent;
