import { Descriptions as AntdDescriptions } from "antd";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PaymentRequestContext from "../../../../context/independent_requests-collection/request-payments/context";
import Spin from "../../../general/antd/spin";
import Descriptions from "../../../general/antd/descriptions";

interface IProps {}
const ClientPaymentDetails: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { details, loading } = useContext(PaymentRequestContext);

  return (
    <>
      {loading.includes("details") ? (
        <Spin />
      ) : (
        <Descriptions size="small" column={1} bordered>
          <AntdDescriptions.Item label={t("id")}>
            {details?.id}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("value")}>
            {details?.value}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("receipt_number")}>
            {details?.receiptNumber}
          </AntdDescriptions.Item>
          <AntdDescriptions.Item label={t("note")}>
            {details?.note ?? "-"}
          </AntdDescriptions.Item>
        </Descriptions>
      )}
    </>
  );
};

export default ClientPaymentDetails;
