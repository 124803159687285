import { createContext } from "react";
import { ITraderMarkOrderTeamplateQuery } from "../../models/workflow/query";
import {
  ICopyTrademarkOrderTemplate,
  ICreateTraderMarkOrderTeamplate,
  IUpdateTraderMarkOrderTeamplate,
} from "../../models/workflow/request";
import {
  INodeTree,
  ITraderMarkOrderTeamplate,
  ITraderMarkOrderTeamplateDetails,
} from "../../models/workflow/response";
import IBaseListingResponse from "../../utils/api/base-listing-response";
import { DEFAULT_FUNCTION } from "../../utils/costants";
import { DEFAULT_QUERY } from "../../utils/helpers/constants";

export type trademarkOrderTemplateLoading =
  | "list"
  | "details"
  | "create"
  | "update"
  | "delete";

export interface IInternalState {
  loading: trademarkOrderTemplateLoading[];

  list?: IBaseListingResponse<ITraderMarkOrderTeamplate>;
  query: ITraderMarkOrderTeamplateQuery;
  light?: IBaseListingResponse<ITraderMarkOrderTeamplate>;

  details?: ITraderMarkOrderTeamplateDetails;
  tree?: INodeTree;
}

export const internalState: IInternalState = {
  loading: [],
  query: DEFAULT_QUERY,
};

export interface IExternalState extends IInternalState {
  actions: {
    getData: () => void;

    getDetails: (id: number) => void;
    setDetails: (data?: ITraderMarkOrderTeamplateDetails) => void;

    getTree: (id: number) => void;
    setTree: (data: INodeTree) => void;

    createTraderMarkOrderTeamplate: (
      request: ICreateTraderMarkOrderTeamplate
    ) => void;
    updateTraderMarkOrderTeamplate: (
      id: number,
      request: IUpdateTraderMarkOrderTeamplate
    ) => void;
    deleteTraderMarkOrderTeamplate: (id: number) => void;

    CopyTraderMarkOrderTeamplate: (
      id: number,
      request: ICopyTrademarkOrderTemplate
    ) => void;

    exportExcel: (data: any) => any;

    setSearch: (search?: string) => void;
    setQuery: (query: ITraderMarkOrderTeamplateQuery) => void;
  };
}

export const externalState: IExternalState = {
  ...internalState,
  actions: {
    getData: DEFAULT_FUNCTION,
    getDetails: DEFAULT_FUNCTION,
    setDetails: DEFAULT_FUNCTION,
    exportExcel: DEFAULT_FUNCTION,

    getTree: DEFAULT_FUNCTION,
    setTree: DEFAULT_FUNCTION,
    createTraderMarkOrderTeamplate: DEFAULT_FUNCTION,
    updateTraderMarkOrderTeamplate: DEFAULT_FUNCTION,
    deleteTraderMarkOrderTeamplate: DEFAULT_FUNCTION,

    CopyTraderMarkOrderTeamplate: DEFAULT_FUNCTION,

    setSearch: DEFAULT_FUNCTION,
    setQuery: DEFAULT_FUNCTION,
  },
};

const TraderMarkOrderTeamplateContext = createContext(externalState);

export default TraderMarkOrderTeamplateContext;
