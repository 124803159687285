import { Tooltip } from 'antd'
import { DeleteOutlined, QuestionCircleTwoTone } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import Popconfirm from '../../antd/pop-confirm'
import Button, { ButtonProps } from '../../antd/button'
import { StrictMode } from 'react'

interface Props extends ButtonProps {
  onConfirm: () => void
}

const DeleteButton: React.FC<Props> = ({ onConfirm, ...props }) => {
  const { t } = useTranslation()

  return (
      <Popconfirm
        title={t('confirmDelete')}
        okButtonProps={{ danger: true, loading: props.loading }}
        icon={<QuestionCircleTwoTone twoToneColor='red' />}
        onConfirm={onConfirm}
      >
          <Button
            type='primary'
            icon={<DeleteOutlined />}
            danger
            {...props}
          />
      </Popconfirm>
  )
}

export default DeleteButton