import { floor, isArray, xor } from "lodash";
import moment from "moment";
import { DATE_FORMATE } from "../costants";

export const toggleLoading = (loading: any[], toggle: any[] | any) => {
  return xor(loading, isArray(toggle) ? toggle : [toggle]);
};

export const formatDate = (date?: Date, format: string = DATE_FORMATE) => {
  return date && moment(date).format(format);
};

export const durationBetweenDates = (
  start: moment.Moment,
  end: moment.Moment
) => {
  // Calculate total duration
  var duration = moment.duration(end.diff(start));

  // Duration in hours
  var hours = floor(duration.asHours());

  // Duration in minutes
  var minutes = duration.asMinutes() % 60;

  return `${hours} H, ${minutes} Min`;
};

export const a2e = (s: string) =>
  s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d).toString());

export const CheckPermisions = (array1, array2) => {
  for (const item of array1) {
    if (!array2?.includes(item)) {
      return false;
    }
  }
  return true;
};
