import { ICopyrightRequestFilesQuery } from "../../../models/copyright-collection/copyright-requset-files/query";
import {
  ICreateCopyrightRequestFiles,
  IUpdateCopyrightRequestFiles,
} from "../../../models/copyright-collection/copyright-requset-files/request";
import {
  ICopyrightRequesFiles,
  ICopyrightRequesFilesDetails,
} from "../../../models/copyright-collection/copyright-requset-files/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class CopyrightRequsetfileService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCopyrightRequsetFiles = (
    query: ICopyrightRequestFilesQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<ICopyrightRequesFiles>>({
      path: `/copyright-request-files`,
      method: "GET",
      query,
      ...params,
    });

  getCopyrightRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightRequesFilesDetails>({
      path: `/copyright-request-files/${id}`,
      method: "GET",
      ...params,
    });

  createCopyrightRequsetFile = (
    data: ICreateCopyrightRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<ICopyrightRequesFiles>({
      path: "/copyright-request-files",
      method: "POST",
      body: data,
      ...params,
    });

  updateCopyrightRequsetFile = (
    id: number,
    data: IUpdateCopyrightRequestFiles,
    params?: RequestParams
  ) =>
    this.http.request<ICopyrightRequesFiles>({
      path: `/copyright-request-files/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteCopyrightRequsetFile = (id: number, params?: RequestParams) =>
    this.http.request<ICopyrightRequesFiles>({
      path: `/copyright-request-files/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default CopyrightRequsetfileService;
