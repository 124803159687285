import {
  ICreatePatentRequest,
  IPatentsRequestExportExcel,
  IUpdatePatentRequest,
} from "./../../../models/patents-collection/patents-request/request";

import { ICreateDrawingRequest } from "../../../models/drawing-collection/drawing-request/request";
import { IPatentsRequestQuery } from "../../../models/patents-collection/patents-request/query";
import { IPatentsRequestDetails } from "../../../models/patents-collection/patents-request/response";
import {
  IPatentsRequest,
  IPatentsReservationDetails,
} from "../../../models/patents-collection/patents-reservation/response";
import { INodeCanMove } from "../../../models/trademark-collection/trademark-request/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import axios from "axios";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../../../utils/costants";

class PatentsRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllRequests = (query: IPatentsRequestQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPatentsRequest>>({
      path: `/patent-requests`,
      method: "GET",
      query,
      ...params,
    });

  createPatentsRequests = (
    data: ICreatePatentRequest,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsRequest>({
      path: "/patent-requests",
      method: "POST",
      body: data,
      ...params,
    });

  deleteRequest = (id: number, params?: RequestParams) =>
    this.http.request({
      path: `/patent-requests/${id}`,
      method: "DELETE",
      ...params,
    });

  updatePatentsRequest = (
    id: number,
    data: IUpdatePatentRequest,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsRequest>({
      path: `/patent-requests/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  getPatentsRequest = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsRequestDetails>({
      path: `/patent-requests/${id}`,
      method: "GET",
      ...params,
    });

  getPatentsReservations = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IPatentsReservationDetails>>({
      path: `/patent-requests/get-Reservations/${id}`,
      method: "GET",
      ...params,
    });

  addPatentsReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/patent-requests/add-Reservations/${id}`,
      method: "PUT",
      body: data,
    });

  movePatentsReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/patent-requests/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });

  deletePatentsReservations = (id: number, data: any) =>
    this.http.request<any>({
      path: `/patent-requests/deattach-Reservations/${id}`,
      method: "DELETE",
      body: data,
    });

  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/patent-requests/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  exportExcel = (
    data: IPatentsRequestExportExcel,
    query: IPatentsRequestQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/patent-requests/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );

  // exportPdf = (id: number) =>
  //   axios.get(
  //     `${process.env.REACT_APP_BASE_API_URL}/Drawing-requests/export-template-PDF/${id}`,
  //     {
  //       headers: {
  //         authorization: `Bearer ${
  //           localStorage.getItem(ACCESS_TOKEN) ||
  //           sessionStorage.getItem(ACCESS_TOKEN)
  //         }`,
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Headers": "*",
  //         "Access-Control-Allow-Credentials": "true",
  //       },
  //       responseType: "arraybuffer",
  //     }
  //   );
}

export default PatentsRequestService;
