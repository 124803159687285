import { IPatnetsRequestCostQuery } from "../../../models/patents-collection/patents-request-costs/query";
import {
  ICreatePatentsRequestCosts,
  IUpdatePatentsRequestCosts,
} from "../../../models/patents-collection/patents-request-costs/request";
import {
  IPatentsRequestCosts,
  IPatentsRequestCostsDetails,
} from "../../../models/patents-collection/patents-request-costs/response";
import { IOfficeCost } from "../../../models/trademark-collection/office-cost/response";
import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";

class PatentsOfficeCostService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllOfficeCosts = (
    query: IPatnetsRequestCostQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IPatentsRequestCosts>>({
      path: `/patent-request-costs`,
      method: "GET",
      query,
      ...params,
    });

  getOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsRequestCostsDetails>({
      path: `/patent-request-costs/${id}`,
      method: "GET",
      ...params,
    });

  createOfficeCost = (
    data: ICreatePatentsRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IOfficeCost>({
      path: "/patent-request-costs",
      method: "POST",
      body: data,
      ...params,
    });

  updateOfficeCost = (
    id: number,
    data: IUpdatePatentsRequestCosts,
    params?: RequestParams
  ) =>
    this.http.request<IPatentsRequestCosts>({
      path: `/patent-request-costs/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  deleteOfficeCost = (id: number, params?: RequestParams) =>
    this.http.request<IPatentsRequestCosts>({
      path: `/patent-request-costs/${id}`,
      method: "DELETE",
      ...params,
    });
}

export default PatentsOfficeCostService;
