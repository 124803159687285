import TrademarkRequestContext from "../../../../context/trademark-collection/trademark-request/context";
import { useContext, useEffect, useState } from "react";
import { Timeline, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

// import DefoultImage from "../../../../../assets/images/user.jpg";
import DefoultImage from "../../../../assets/images/user.jpg";
import Image from "../../../general/antd/image";
import AppContext from "../../../../context/app/context";
import moment from "moment";

function TimeLine() {
  const { details } = useContext(TrademarkRequestContext);
  const { t } = useTranslation();
  const { direction } = useContext(AppContext);

  return (
    <>
      <Timeline mode="alternate">
        {details?.history.map(function (e, index) {
          return (
            <Timeline.Item
              key={index}
              dot={
                <Image
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginInlineStart: direction === "rtl" ? "-14px" : "",
                  }}
                  src={e.node?.icon?.url ?? DefoultImage}
                />
              }
            >
              <div style={{ color: e.node.color }}>
                <Tooltip
                  title={
                    e.changes ? (
                      <>
                        <div>
                          {e?.changes?.deposit_date && (
                            <>
                              <span className={styles.span}>
                                {t("deposit_date")} :
                              </span>
                              {e?.changes?.deposit_date}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.deposit_number && (
                            <>
                              {" "}
                              <span className={styles.span}>
                                {t("deposit_number")}:
                              </span>
                              {e?.changes?.deposit_number}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.last_renewal_date && (
                            <>
                              <span className={styles.span}>
                                {t("last_renewal_date")} :
                              </span>
                              {e?.changes?.last_renewal_date}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.next_renewal_date && (
                            <>
                              <span className={styles.span}>
                                {t("next_renewal_date")} :
                              </span>
                              {e?.changes?.next_renewal_date}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.registration_date && (
                            <>
                              <span className={styles.span}>
                                {t("registration_date")} :
                              </span>

                              {e?.changes?.registration_date}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.first_publish_date && (
                            <>
                              <span className={styles.span}>
                                {t("first_publish_date")}:
                              </span>

                              {e?.changes?.first_publish_date}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.last_renewal_number && (
                            <>
                              <span className={styles.span}>
                                {t("last_renewal_number")} :
                              </span>

                              {e?.changes?.last_renewal_number}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.registration_number && (
                            <>
                              <span className={styles.span}>
                                {t("registration_number")} :
                              </span>
                              {e?.changes?.registration_number}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.second_publish_date && (
                            <>
                              <span className={styles.span}>
                                {t("second_publish_date")} :
                              </span>{" "}
                              {e?.changes?.second_publish_date}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.first_publish_number && (
                            <>
                              <span className={styles.span}>
                                {t("first_publish_number")} :
                              </span>
                              {e?.changes?.first_publish_number}
                            </>
                          )}
                        </div>
                        <div>
                          {e?.changes?.second_publish_number && (
                            <>
                              <span className={styles.span}>
                                {t("second_publish_number")} :
                              </span>
                              {e?.changes?.second_publish_number}
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      t("not_found_changes")
                    )
                  }
                  mouseEnterDelay={0.5}
                >
                  <span>
                    <strong>{e.node.id} - </strong>
                  </span>
                  <span className={styles.title}>
                    <strong> {e.node?.name?.name} </strong>
                  </span>

                  <div className={styles.title}>
                    <strong>{t("official_date")} : </strong>
                    {e.officialDate
                      ? moment(e?.officialDate).format("DD/MM/YYYY")
                      : "-"}
                  </div>

                  <div className={styles.title}>
                    <strong>{t("created_at")} : </strong>
                    {e.createdAt
                      ? moment(e?.createdAt).format("DD/MM/YYYY")
                      : "-"}
                  </div>

                  {/* <div className={styles.title}>
                    {e.createdAt && (
                      <>
                        <strong>{t("created_at")} : </strong>
                        {moment(e?.createdAt).format("DD/MM/YYYY")}
                      </>
                    )}
                  </div> */}
                  <div className={styles.title}>
                    <strong>{t("client_message")} : </strong>
                    {e.clientMessage ? e.clientMessage : "-"}
                  </div>
                  <div className={styles.title}>
                    <strong>{t("note")} : </strong>
                    {e.note ? e.note : "-"}
                  </div>
                </Tooltip>
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    </>
  );
}

export default TimeLine;
