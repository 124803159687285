import axios from "axios";

import IBaseListingResponse from "../../../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "../../../utils/api/http-client";
import { ACCESS_TOKEN } from "../../../utils/costants";
import { LANGUAGE_CODE } from "../../../utils/helpers/constants";
import { IObjectionRequestQuery } from "../../../models/independent-requests-collection/objection-request/query";
import {
  IObjectionRequest,
  IObjectionRequestDetails,
} from "../../../models/independent-requests-collection/objection-request/response";
import {
  ICreateObjectionRequest,
  IExportObjectionRequestExcel,
  IUpdateObjectionRequest,
} from "../../../models/independent-requests-collection/objection-request/request";
import { INodeCanMove } from "../../../models/trademark-collection/trademark-request/response";

class ObjectionRequestService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllObjectionRequest = (
    query: IObjectionRequestQuery,
    params?: RequestParams
  ) =>
    this.http.request<IBaseListingResponse<IObjectionRequest>>({
      path: `/objection-request`,
      method: "GET",
      query,
      ...params,
    });

  getObjectionRequest = (id: number, params?: RequestParams) =>
    this.http.request<IObjectionRequestDetails>({
      path: `/objection-request/${id}`,
      method: "GET",
      ...params,
    });

  createObjectionRequest = (
    data: ICreateObjectionRequest,
    params?: RequestParams
  ) =>
    this.http.request<IObjectionRequest>({
      path: "/objection-request",
      method: "POST",
      body: data,
      ...params,
    });

  updateObjectionRequest = (
    id: number,
    data: IUpdateObjectionRequest,
    params?: RequestParams
  ) =>
    this.http.request<IObjectionRequest>({
      path: `/objection-request/${id}`,
      method: "PUT",
      body: data,
      ...params,
    });

  moveObjectionReseqestNode = (id: number, data: any) =>
    this.http.request<any>({
      path: `/objection-request/move-to-next-node/${id}`,
      method: "POST",
      body: data,
    });

  getNodesUserCanMoveRequest = (id: number, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<INodeCanMove>>({
      path: `/objection-request/get-nodes-user-can-move/${id}`,
      method: "GET",
      ...params,
    });

  deleteObjectionRequest = (id: number, params?: RequestParams) =>
    this.http.request<IObjectionRequest>({
      path: `/objection-request/${id}`,
      method: "DELETE",
      ...params,
    });

  exportExcel = (
    data: IExportObjectionRequestExcel,
    query: IObjectionRequestQuery
  ) =>
    axios.post(
      ` ${process.env.REACT_APP_BASE_API_URL}/objection-request/export`,
      data,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Content-Type": "application/json",
          "accept-language": `${
            localStorage.getItem(LANGUAGE_CODE) ??
            process.env.REACT_APP_DEFAULT_LANGUAGE
          }`,
        },
        params: query,
        responseType: "arraybuffer",
      }
    );
  exportPdf = (id: number) =>
    axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/objection-request/export-template-PDF/${id}`,
      {
        headers: {
          authorization: `Bearer ${
            localStorage.getItem(ACCESS_TOKEN) ||
            sessionStorage.getItem(ACCESS_TOKEN)
          }`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "arraybuffer",
      }
    );
}

export default ObjectionRequestService;
