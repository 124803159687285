import { Descriptions as AntdDescriptions, Col, Collapse, Row } from "antd";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import moment from "moment";
import AppContext from "../../../../context/app/context";
import CopyrightClientPaymentContextProvider from "../../../../context/copyright-collection/client-payments/provider";
import CopyrightRequestFilesContextProvider from "../../../../context/copyright-collection/copyright-request-files/provider";
import CopyrightRequestContext from "../../../../context/copyright-collection/copyright-request/context";
import CopyrightRequestContextProvider from "../../../../context/copyright-collection/copyright-request/provider";
import CopyrightOfficeCostContextProvider from "../../../../context/copyright-collection/office-cost/provider";
import {
  getRequestStatus,
  getRequestStatusColor,
} from "../../../../models/node/enum";

import Descriptions from "../../../general/antd/descriptions";
import Tag from "../../../general/antd/tag";
import CopyrightClientPaymentTable from "../clients-payments/client-payment-table";
import CopyrightOfficeCostTable from "../offiece-cost/office-cost-table";
import CopyrightReservationTable from "../reservations/table";
import CopyrightTimeLine from "../time-line";
import CopyrightFilesTable from "../files/table";
import DetailsPage from "../../../form-components/view-details";
import Spin from "../../../general/antd/spin";
import {
  getBooleanDrawinOrderTemplate,
  getDrawingOrderTemplateColor,
} from "../../../../models/workflow/enms-workflow/drawing";
import {
  getAllBooleanOrderTemplate,
  getAllOrderTemplateColor,
} from "../../../../models/workflow/enms-workflow/all-enums";

const Details = () => {
  const { Panel } = Collapse;
  const { t } = useTranslation();
  const { details, actions, loading } = useContext(CopyrightRequestContext);
  const { direction } = useContext(AppContext);
  const { id } = useParams();

  useEffect(() => {
    const getDetails = async () => {
      id && (await actions.getDetails(Number(id)));
    };
    getDetails();
  }, [direction]);

  return (
    <DetailsPage title={t("copyright_details_request")}>
      {loading.includes("details") ? (
        <div style={{ height: "50vh" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Collapse>
                <Panel header={t("copyright_details_request")} key="1">
                  <Descriptions size="small" column={1} bordered>
                    <AntdDescriptions.Item label={t("id")}>
                      {details?.id}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("deposit_number")}>
                      {details?.depositNumber}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("copyright")}>
                      {details?.workflow?.name}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("type_oreder_template")}>
                      {details?.workflow?.type ? (
                        <Tag
                          title={getAllBooleanOrderTemplate(
                            details?.workflow?.type
                          )}
                          color={getAllOrderTemplateColor(
                            details?.workflow?.type
                          )}
                        />
                      ) : (
                        "-"
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("copyright")}>
                      {details?.copyright ? (
                        <Tag title={details?.copyright?.name} />
                      ) : (
                        "-"
                      )}
                      {/* {details?.drawings[0]?.name} */}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("request_status")}>
                      <Tag
                        title={getRequestStatus(Number(details?.status))}
                        color={getRequestStatusColor(Number(details?.status))}
                      />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("country")}>
                      <Tag title={details?.workflow?.country?.name ?? "-"} />
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("current_node")}>
                      {details?.currentNode?.name?.name ?? (
                        <Tag title="not_found" color="red" />
                      )}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("created_at")}>
                      {moment(details?.createdAt).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("update_at")}>
                      {moment(details?.updatedAt).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("request_submitting_date")}>
                      {moment(details?.officialDate).format("DD/MM/YYYY")}
                    </AntdDescriptions.Item>
                    {/* <AntdDescriptions.Item label={t("trademark")}>
                      {details?.trademark?.name}
                    </AntdDescriptions.Item> */}

                    <AntdDescriptions.Item label={t("client_order_cost")}>
                      {details?.clientCost ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("sum_client_payments")}>
                      {details?.clientPayment ?? "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("sum_office_costs")}>
                      {details?.officeCosts ?? "-"}
                    </AntdDescriptions.Item>

                    <AntdDescriptions.Item label={t("total_request_cost")}>
                      {details?.totalCost}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_date")}>
                      {details?.registrationDate
                        ? moment(details?.registrationDate).format("DD/MM/YYYY")
                        : "-"}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("registration_number")}>
                      {details?.registrationNumber}
                    </AntdDescriptions.Item>
                    <AntdDescriptions.Item label={t("note")}>
                      {details?.note}
                    </AntdDescriptions.Item>
                  </Descriptions>
                </Panel>
              </Collapse>
            </Col>
            <Col span={24}>
              <Collapse>
                <Panel header={t("copyright_reservation")} key={1}>
                  <CopyrightReservationTable />
                </Panel>
              </Collapse>
            </Col>
            <Col span={8}>
              <Collapse>
                <Panel header={t("time_line_request")} key={1}>
                  <CopyrightTimeLine />
                </Panel>
              </Collapse>
            </Col>
            <Col span={16}>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("client_payments")} key={1}>
                      <CopyrightClientPaymentTable />
                    </Panel>
                  </Collapse>
                </Col>
                <Col />
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("office_cost")} key={1}>
                      <CopyrightOfficeCostTable />
                    </Panel>
                  </Collapse>
                </Col>
                <Col span={24}>
                  <Collapse>
                    <Panel header={t("files")} key={1}>
                      <CopyrightFilesTable />
                    </Panel>
                  </Collapse>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </DetailsPage>
  );
};

const CopyrightRequsetDetailsPage = () => {
  return (
    <CopyrightRequestContextProvider>
      <CopyrightRequestFilesContextProvider>
        <CopyrightOfficeCostContextProvider>
          <CopyrightClientPaymentContextProvider>
            <Details />
          </CopyrightClientPaymentContextProvider>
        </CopyrightOfficeCostContextProvider>
      </CopyrightRequestFilesContextProvider>
    </CopyrightRequestContextProvider>
  );
};
export default CopyrightRequsetDetailsPage;
