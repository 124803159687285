import { useReducer } from "react";

import EndPoints from "../../../../services/end-points";
import { execute } from "../../../../utils/helpers/execute";
import GrievanceOfficeCostContext, { internalState } from "./context";
import reducer from "./reducer";
import { IGrievanceRequestCostsDetails } from "../../../../models/lawswits-collection/grievance/grievance-request-costs/response";
import {
  ICreateGrievanceRequestCosts,
  IUpdateGrievanceRequestCosts,
} from "../../../../models/lawswits-collection/grievance/grievance-request-costs/request";
import { IGrievanceRequestCostQuery } from "../../../../models/lawswits-collection/grievance/grievance-request-costs/query";

export interface IProps {
  children: React.ReactNode;
}
const GrievanceOfficeCostContextProvider: React.FC<IProps> = (props) => {
  const [state, dispatch] = useReducer(reducer, internalState);

  const getData = async (id?: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });

        const { data } = await EndPoints.grievanceOfficeCost.getAllOfficeCosts({
          ...state.query,
          filters: [
            {
              name: "requestId",
              operation: "eq",
              value: id,
            },
          ],
        });

        dispatch({ type: "SET_LIST", payload: { list: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "list" } });
      },
      throwException: false,
    });
  };

  const getDetails = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });

        const { data } = await EndPoints.grievanceOfficeCost.getOfficeCost(id);

        dispatch({ type: "SET_DETAILS", payload: { details: data } });
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({ type: "LOADING", payload: { loading: "details" } });
      },
      throwException: false,
    });
  };

  const setDetails = async (data?: IGrievanceRequestCostsDetails) => {
    dispatch({ type: "SET_DETAILS", payload: { details: data } });
  };

  const createOfficeCost = async (request: ICreateGrievanceRequestCosts) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });

        await EndPoints.grievanceOfficeCost.createOfficeCost(request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "create" },
        });
      },
      throwException: true,
    });
  };

  const updateOfficeCost = async (
    id: number,
    request: IUpdateGrievanceRequestCosts
  ) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
        await EndPoints.grievanceOfficeCost.updateOfficeCost(id, request);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "update" },
        });
      },
      throwException: true,
    });
  };

  const deleteOfficeCost = async (id: number) => {
    await execute({
      callback: async () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });

        await EndPoints.grievanceOfficeCost.deleteOfficeCost(id);
      },
      fallback: (error) => {},
      finallyCallback: () => {
        dispatch({
          type: "LOADING",
          payload: { loading: "delete" },
        });
      },
      throwException: true,
    });
  };

  const setSearch = (search?: string) => {
    dispatch({ type: "SET_SEARCH", payload: { search } });
  };

  const setQuery = (query: IGrievanceRequestCostQuery) => {
    dispatch({ type: "SET_QUERY", payload: { query } });
  };

  return (
    <GrievanceOfficeCostContext.Provider
      value={{
        ...state,
        actions: {
          getData,
          getDetails,
          setDetails,
          deleteOfficeCost,
          createOfficeCost,

          updateOfficeCost,

          setSearch,
          setQuery,
        },
      }}
    >
      {props.children}
    </GrievanceOfficeCostContext.Provider>
  );
};

export default GrievanceOfficeCostContextProvider;
